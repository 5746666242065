import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import { CustomReportTable, CustomReportTableCell, CustomReportTableRow } from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
const ReportNoShowTable = ({ departureReport }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const extractedData = departureReport?.map(item => ({
    unique_booking_id: item && item?.unique_booking_id ? item?.unique_booking_id : 'N/A',
    guest_name: item  && item?.guest[0] && item?.guest[0]?.guest_name ? item?.guest[0]?.guest_name : 'N/A',
    check_in: item  && item?.check_in ? item?.check_in : 'N/A',
    check_out: item  && item?.check_out ? item?.check_out : 'N/A',
    room_type_name: item  && item?.items[0] && item?.items[0]?.room_type_name ? item?.items[0]?.room_type_name : 'N/A',
    booking_source: item  && item?.booking_source ? item?.booking_source?.replace(/_/g, " ") : 'N/A',
    status: item  && item?.status ? item?.status?.replace(/_/g, " ") : 'N/A',
  }));
  console.log(extractedData);
  const names = [
    {
      unique_booking_id: "Booking ID",
      guest_name: "Name",
      updated_at:"",
      check_in: "Arrival Date",
      check_out: "Departure Date",
      room_type_name: "Room Category",
      booking_source: "Booking Source",
      status: "Status",
    },
  ];

  //   Reservation/Confirmation Number d
  // Guest Name d
  // Arrival Date d
  // Departure Date d
  // Room Type Booked d
  // Reservation Source d
  // Source Reservation ID (for OTA bookings) d

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>{order.unique_booking_id}</CustomReportTableCell>
        <CustomReportTableCell>{order?.guest[0]?.guest_name}</CustomReportTableCell>
        <CustomReportTableCell>
          <TextDateFormatter
            as={"span"}
            showYear={true}
            date={order.check_in}
          />
        </CustomReportTableCell>

        <CustomReportTableCell>
          <TextDateFormatter
            as={"span"}
            showYear={true}
            date={order.check_out}
          />
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order?.items.map((item,index) => (
            <span key={index}>{item.room_type_name} {index < order?.items.length - 1 ? "," : ""} &nbsp;</span>
          ))}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.items[0]?.no_nights
          }
        </CustomReportTableCell>
        <CustomReportTableCell>
         
        </CustomReportTableCell>
        <CustomReportTableCell>
         {order.balance_amount}
        </CustomReportTableCell>


        <CustomReportTableCell>
          <StatusUpdate status={order.status.replace(/_/g, " ")} /> {}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));

  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div className="room-type-heading">No Show Report</div>
          {departureReport?.length > 0 && (
              <div className="flex flex_gap_15">
                <DownloadPdfTables
                  tableData={extractedData}
                  tableNames={names}
                  downloadFileName={"no_show_report"}
                />
                <DownloadCSVButton  downloadFileName={"no_show_report"} itemToDownloadID={"receipt"}/>
              </div>
            )}
        </div>

        {departureReport && departureReport.length > 0 ? (
          <>
            {/* <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={departureReport.length}
                selectable={false}
                headings={[
                  { title: "Booking ID" },
                  { title: "Guest Name" },
                  { title: "Arrival Date" },
                  { title: "Departure Date" },
                  { title: "Room Type" },
                  { title: "Source" },
                  { title: "Status" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard> */}

<CustomReportTable
              headings={[
                "Booking ID",
                "Guest Name",
                "Check In Date",
                "Check Out Date",
                "Room Type",
                "No Of Nights",
                "	No Of Guests",
                "Balance",
                "Status",
              ]}
              // commonHeadings={[
              //   "",
              //   "",
              //   "",
              //   "",
              //   "Quantity",
              //   "Quantity",
              //   "Quantity",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              // ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "center",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {/* {calculateTotals} */}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};
export default ReportNoShowTable;
