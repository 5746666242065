import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoaderSmall from "../UI/Loader/LoaderSmall";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { CancelMajor } from "@shopify/polaris-icons";
import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Icon,
} from "@shopify/polaris";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";

const FolioSeries = () => {
  const [addFoloiSeriesPopup, setAddFolioSeriesPopup] = useState(false);
  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const property_id = useSelector((state) => state.property.value);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const [orders, setOrders] = useState([]);

  const fetchInfo = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/properties/folio/series/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setOrders(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const [addFolioData, setAddFolioData] = useState({
    key: "",
    value: "",
    prefix: '',
    active: '',
  });

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
    }
  }, [property_id]);

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(orders);

  // useEffect(() => {
  //     if (selectedResources.length === 1) {
  //         setShowLowerMenuSingleSelect(true);
  //         setShowLowerMenuMultipleSelect(false);
  //     } else if (selectedResources.length > 1) {
  //         setShowLowerMenuMultipleSelect(true);
  //         setShowLowerMenuSingleSelect(false);
  //     } else {
  //         setShowLowerMenuSingleSelect(false);
  //         setShowLowerMenuMultipleSelect(false);
  //     }
  // }, [selectedResources]);

  const rowMarkup = orders.map(({ id, key, value, active, index }) => (
    <IndexTable.Row
      id={id}
      key={id}
      selected={selectedResources.includes(id)}
      position={index}
    >
      <IndexTable.Cell>{ <UnderscoreRemoveCapitalize text={key}/>  }</IndexTable.Cell>
      <IndexTable.Cell>{value}</IndexTable.Cell>
      <IndexTable.Cell>
        {" "}
        {Number(active) === 1 ? (
          <StatusUpdate status={active} />
        ) : (
          <StatusUpdate status={active} />
        )}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const addFolioHandleChange = (event) => {
    setAddFolioData({
      ...addFolioData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmitAddFolio = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/properties/folio/series/store/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addFolioData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddFolioSeriesPopup(false);
          fetchInfo(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const keyOptions = [
    { label: "Select", value: "" },
    { label: "Food Bill", value: "food_bill" },
    { label: "Room Bill", value: "room_bill" },
    { label: "Folio", value: "folio" },
  ];

  const statusOptions = [
    { label: "Select", value: "" },
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ]

  return (
    <>
      <div className="setting_location_sec">
        <div className="flex justify_content_between align_items_center mb-10">
          <div class="room-type-heading">Folio Series</div>
          <BgOutlineThemeButton
            children={"Add Series"}
            onClick={() => setAddFolioSeriesPopup(!addFoloiSeriesPopup)}
          />
        </div>
        {loader ? (
          <LoaderSmall />
        ) : (
          <>
            {orders && orders.length > 0 ? (
              <LegacyCard>
                <IndexTable
                  selectable={false}
                  resourceName={resourceName}
                  itemCount={orders.length}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: "Key" },
                    { title: "Value" },
                    { title: "Active" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
              </LegacyCard>
            ) : (
              <NoDataFoundSmall />
            )}
          </>
        )}
      </div>

      {addFoloiSeriesPopup && (
        <div className="bg-backdrop">
          <form
            onSubmit={handleSubmitAddFolio}
            style={{ width: "600px" }}
            className="stock-add-model-container"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div className="add-ota-model-container-heading">
                Add Folio Series
              </div>
              <div
                onClick={() => setAddFolioSeriesPopup(!addFoloiSeriesPopup)}
                style={{ cursor: "pointer" }}
              >
                <Icon source={CancelMajor} color="base" />
              </div>
            </div>

            <div className="form-container-div-half">
              <div className="form-container-div">
                {/* <VerticalInputFieldCustom
                  titleName="Key"
                  name="key"
                  value={addFolioData.key}
                  onChange={addFolioHandleChange}
                /> */}
                <VerticalInputSelectCustomCopy
                  titleName={"Key"}
                  name={"key"}
                  value={addFolioData.key}
                  onChange={addFolioHandleChange}
                  options={keyOptions}
                />
              </div>
              <div className="form-container-div">
                {/* <VerticalInputFieldCustom
                  titleName="Key"
                  name="key"
                  value={addFolioData.key}
                  onChange={addFolioHandleChange}
                /> */}
                <VerticalInputFieldCustom
                  titleName={"Value"}
                  name={"value"}
                  value={addFolioData.value}
                  onChange={addFolioHandleChange}
                />
              </div>

            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  titleName={"Prefix"}
                  name={"prefix"}
                  value={addFolioData.prefix}
                  onChange={addFolioHandleChange}
                />
              </div>
              <div className="form-container-div">
                <VerticalInputSelectCustomCopy
                  titleName={"Status"}
                  name={"active"}
                  value={addFolioData.active}
                  onChange={addFolioHandleChange}
                  options={statusOptions}
                />
              </div>

            </div>
            <div className="flex flex_gap_10">
              <BgThemeButton children={"Add"} type={"submit"} loader={loader}/>
              <BgOutlineThemeButton
                children={"Cancel"}
                type={"button"}
                onClick={() => setAddFolioSeriesPopup(!addFoloiSeriesPopup)}
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
};
export default FolioSeries;