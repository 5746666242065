import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CreateBookingTableComponent from "../CreateBookingNewPage/createBookingTableComponent/CreateBookingTableComponent";
import CreateBookingTableComponentTwo from "../CreateBookingNewPage/CreateBookingTableComponenTwo/CreateBookingTableComponenTwo";
import { BgOutlineThemeButton, BgThemeButton } from "../../UI/Buttons";

import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import { useNavigate, useParams } from "react-router";
import Loader from "../../UI/Loader/Loader";
import "./CreateBookingNewPageEdit.css";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { calculateToDatePlus } from "../../../utils/utils";

import { formatIndianCurrency } from "../../../utils/utils";
import ModifyReservationPopup from "../ModifyReservationPopup/ModifyReservationPopup";
import BookingStatusUpdate from "../BookingStatusUpdate";

const CreateBookingNewEdit = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();

  const bookingID = params?.bookingID;

  const property_id = useSelector((state) => state.property.value);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const [bookingData, setBookingData] = useState({});
  const [guestName, setGuestName] = useState({
    name: "",
    salutation: "",
  });
  const [addOnsFromParentApi, setAddOnsFromParentApi] = useState([]);
  const [paymentsFromParentApi, setPaymentsFromParentApi] = useState([]);
  const [addOns, setAddons] = useState([]);

  const fetchBookingDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit-booking/${bookingID}/${property_id.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setBookingData(data.booking);

        // Extract salutation and name from the guest's name
        const fullName = data.booking.guest.guest_name;
        const parts = fullName.split(" ");
        const salutation = parts[0];
        const name = parts.slice(1).join(" ");
        setGuestName({
          salutation: salutation,
          name: name,
        });
        setAddons(data.booking.addons);

        setLoading(false);
        setAddOnsFromParentApi(data.booking.addons);
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch booking details:", data.message);
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  useEffect(() => {
    fetchBookingDetails();
  }, [bookingID, property_id]);

  useEffect(() => {
    fetchSalutations();
  }, []);

  const styles = {
    topBar: {
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px 0",
      background: "#fff",
      display: "flex",
    },
    topBarChild: {
      height: "60px",
      width: "16.6%",
      borderRight: "1px solid black",
      display: "flex",
      alignItems: "center",
      paddingLeft: "20px",
    },
    topBarChildSvgContainer: {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },
    textTransform: {
      textTransform: "capitalize",
    },
  };
  const [addOnsPaymentData, setAddOnsPaymentData] = useState({
    addOn: [],
    payments: [],
  });
  const [guestDetails, setGuestDetails] = useState({
    name: "",
    email: "",
    phone: "",
    remarks: "",
  });
  const [showSalutationPopup, setShowSalutationPopup] = useState(false);

  const [salutations, setSalutations] = useState([]);
  const [popUpSalutation, setPopUpSalutation] = useState("");

  const handleGuestDetails = (e) => {
    setGuestDetails({
      ...guestDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleGuestNameSalutationChange = (e) => {
    const { value } = e.target;

    if (value === "other") {
      setShowSalutationPopup(true);
    } else {
      setGuestName({
        ...guestName,
        salutation: e.target.value,
      });
    }
  };

  const getPaymentData = (data) => {
    setAddOnsPaymentData((prev) => {
      return {
        ...prev,
        payments: data,
      };
    });
  };
  const handlePopupAddButtonClick = async (e) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/add`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            name: popUpSalutation,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setPopUpSalutation(false);
        setPopUpSalutation("");
        fetchSalutations();
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch salutations:", data.message);
      }
    } catch (error) {
      console.error("Error fetching salutations:", error);
    }
    setGuestName({
      ...guestName,
      salutation: e.target.value,
    });
    setShowSalutationPopup(false);
  };
  const fetchSalutations = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSalutations(data.data);
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch salutations:", data.message);
      }
    } catch (error) {
      console.error("Error fetching salutations:", error);
    }
  };

  function calculateTotal(array, property) {
    return array?.reduce((total, item) => {
      const value = parseFloat(item[property]) || 0;
      return total + value;
    }, 0);
  }
  function calculateNumberOfNights(checkInDate, checkOutDate) {
    const checkInTime = checkInDate?.getTime();
    const checkOutTime = checkOutDate?.getTime();

    const differenceMs = checkOutTime - checkInTime;
    const differenceDays = differenceMs / (1000 * 3600 * 24);
    const numberOfNights = Math.floor(differenceDays);

    return numberOfNights;
  }

  //----------------------------modify reservation--------------------

  const [showModifyPopup, setShowModifyPopup] = useState(false);

  const handleModifyButtonClick = () => {
    setShowModifyPopup(!showModifyPopup);
  };
  //----------------------Modify reservation Pop options------------------------------

  const modifyReservationPopupButtonOptions = [
    {
      label: "Change Room Category",
      value: "change_room_category",
    },
    // {
    //   label: "Transfer Room",
    //   value: "transfer_room",
    // },
    {
      label: "Change Meal Plan",
      value: "change_meal_plan",
    },
    {
      label: "Amend Stay",
      value: "amend_stay",
    },
    {
      label: "Guest Details",
      value: "guest_details",
    },
    {
      label: "Pax Details",
      value: "pax_details",
    },
  ];
  const [selectedOption, setSelectedOption] = useState("change_room_category");

  const [showEditNewTotal, setShowNewEditTotal] = useState(false);

  // ----------------------modify reservation Pop Guest Details------------------------------
  const [modifyGuestDetails, setModifyGuestDetails] = useState({
    guestName: "",
    guestPhone: "",
    guestEmail: "",
    guestCity: "",
    guestState: "",
    guestCountry: "",
    guestPinCode: "",
    guestDOB: "",
    guestAddress: "",
  });
  useEffect(() => {
    if (bookingData.guest) {
      setModifyGuestDetails((prevState) => ({
        ...prevState,
        guestName: bookingData.guest.guest_name || "",
        guestPhone: bookingData.guest.guest_phone || "",
        guestEmail: bookingData.guest.guest_email || "",
        guestCity: bookingData.guest.guest_city || "",
        guestState: bookingData.guest.guest_state || "",
        guestCountry: bookingData.guest.guest_country || "",
        guestPinCode: bookingData.guest.guest_pincode || "",
        guestDOB: bookingData.guest.guest_dob || "",
        guestAddress: bookingData.guest.guest_address || "",
      }));
    }
  }, [bookingData]);

  const handleModifyGuestDetails = (e) => {
    setModifyGuestDetails({
      ...modifyGuestDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleModifyReservationGuestSubmit = async () => {
    const formData = new FormData();
    formData.append("reservationId", bookingData.unique_booking_id);

    if (modifyGuestDetails.guestName.trim() !== "") {
      formData.append("guestName", modifyGuestDetails.guestName);
    }

    if (modifyGuestDetails.guestPhone.trim() !== "") {
      formData.append("guestPhone", modifyGuestDetails.guestPhone);
    }

    if (modifyGuestDetails.guestEmail.trim() !== "") {
      formData.append("guestEmail", modifyGuestDetails.guestEmail);
    }

    if (modifyGuestDetails.guestCity.trim() !== "") {
      formData.append("guestCity", modifyGuestDetails.guestCity);
    }

    if (modifyGuestDetails.guestState.trim() !== "") {
      formData.append("guestState", modifyGuestDetails.guestState);
    }

    if (modifyGuestDetails.guestCountry.trim() !== "") {
      formData.append("guestCountry", modifyGuestDetails.guestCountry);
    }

    if (modifyGuestDetails.guestPinCode.trim() !== "") {
      formData.append("guestPinCode", modifyGuestDetails.guestPinCode);
    }

    if (modifyGuestDetails.guestAddress.trim() !== "") {
      formData.append("guestAddress", modifyGuestDetails.guestAddress);
    }
    if (reasons.guestDetailsReason !== "") {
      formData.append("reason", modifyGuestDetails.guestAddress);
    }

    const myHeaders = new Headers();
    myHeaders.append("key", `Bearer ${userToken}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modify/guestDetails/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      showSuccessToast("Guest Details updated Successfully");
      setModifyGuestDetails({
        guestName: "",
        guestPhone: "",
        guestEmail: "",
        guestCity: "",
        guestState: "",
        guestCountry: "",
        guestPinCode: "",
        guestDOB: "",
        guestAddress: "",
      });
      setShowModifyPopup(false);
      fetchBookingDetails();
      return data;
    } catch (error) {
      console.error(error);
      showErrorToast(error.message);
      return null;
    }
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  function formatDateMMDDYYYY(date) {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  // functions for calculating the subtotal and taxAmount and grand total for Exclusive
  function calculateSubtotalForExclusive(
    roomRatePerNight,
    nights,
    room,
    child,
    childCost,
    extraAdult,
    extraAdultCost,
    discountAmount
  ) {
    const total =
      roomRatePerNight * nights * room +
      (child * childCost + extraAdult * extraAdultCost) * nights -
      discountAmount;
    return total;
  }
  function taxAmountForExclusive(
    roomRatePerNight,
    nights,
    room,
    child,
    childCost,
    extraAdult,
    extraAdultCost,
    taxRate,
    discountAmount
  ) {
    const total =
      roomRatePerNight * nights * room +
      (child * childCost + extraAdult * extraAdultCost) -
      discountAmount;
    const taxAmount = (total * taxRate) / 100;
    return taxAmount;
  }
  function grandTotalForExclusive(
    roomRatePerNight,
    nights,
    room,
    child,
    childCost,
    extraAdult,
    extraAdultCost,
    taxRate,
    discountAmount
  ) {
    const total =
      roomRatePerNight * nights * room +
      (child * childCost + extraAdult * extraAdultCost) * nights -
      discountAmount;
    const taxAmount = (total * taxRate) / 100;
    return total + taxAmount;
  }
  //----------------------------------------------------------------------------

  // --------functions for calculating the subtotal and taxAmount and grand total for inclusive

  function calculateSubtotalForInclusive(
    roomRatePerNight,
    nights,
    room,
    child,
    childCost,
    extraAdult,
    extraAdultCost,
    taxRate,
    discountAmount
  ) {
    const total =
      roomRatePerNight * nights * room +
      (child * childCost + extraAdult * extraAdultCost) * nights -
      discountAmount;
    const subTotal = (total * 100) / (100 + taxRate);
    return subTotal;
  }
  function taxAmountForInclusive(
    roomRatePerNight,
    nights,
    room,
    child,
    childCost,
    extraAdult,
    extraAdultCost,
    taxRate,
    discountAmount
  ) {
    const total =
      roomRatePerNight * nights * room +
      (child * childCost + extraAdult * extraAdultCost) * nights -
      discountAmount;
    const taxAmount = (total * taxRate) / (100 + taxRate);

    return taxAmount;
  }
  function grandTotalForInclusive(
    roomRatePerNight,
    nights,
    room,
    child,
    childCost,
    extraAdult,
    extraAdultCost,
    discountAmount
  ) {
    const total =
      roomRatePerNight * nights * room +
      (child * childCost + extraAdult * extraAdultCost) * nights -
      discountAmount;

    return total;
  }
  //--------------------reasons for modification common for all popups-------------
  const [reasons, setReasons] = useState({
    changeRoomCategoryReason: "",
    changeMealPlanReason: "",
    amendStayReason: "",
    paxDetailReason: "",
  });

  const handleReasonChange = (event) => {
    const { name, value } = event.target;
    setReasons((prevReasons) => ({
      ...prevReasons,
      [name]: value,
    }));
  };

  // ============amend stay===========================
  const [amendStayDiscount, setAmendStayDiscount] = useState(0);
  const handleAmendStayDiscountChange = (event) => {
    setAmendStayDiscount(event.target.value);

    setShowCheckOutCalculations(false);
    setAmendStayTotal(0);
  };
  useEffect(() => {
    setAmendStayDiscount(bookingData.total_discount_amount);
  }, [bookingData.total_discount_amount]);

  //-----early check in check out
  const [showCheckOutCalculations, setShowCheckOutCalculations] =
    useState(false);
  const [amendStayTotal, setAmendStayTotal] = useState(0);

  const [earlyCheckInDate, setEarlyCheckInDate] = useState("");

  const handleEarlyCheckOutChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const checkInDate = new Date(bookingData.check_in);
    const checkOutDate = new Date(bookingData.check_out);

    if (selectedDate < checkInDate || selectedDate > checkOutDate) {
      showErrorToast(
        "Please select a date between booking check-in and check-out dates."
      );
      setEarlyCheckInDate("");
    } else {
      setEarlyCheckInDate(selectedDate);
    }
  };
  const handleEarlyCheckOutCalculate = (e) => {
    if (!earlyCheckInDate) {
      showErrorToast("Please select early check-out date");
      return;
    }
    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };

    const no_of_night = calculateNumberOfNights(
      new Date(bookingData?.check_in),
      new Date(earlyCheckInDate)
    );

    const amendStayForRoom = amendStayDiscount / bookingData?.items?.length;

    const rooms = bookingData.items.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      // const discountedRatePerNight = +item.discount_amount / +item.no_nights;
      // const totalDiscountAmount = discountedRatePerNight * no_of_night;

      const totalDiscountAmount = amendStayForRoom;
      return {
        BookingItemId: item.id,
        NumberOfNights: no_of_night,
        Rate: item.room_rate,
        TaxRate: item.tax_rate,
        DiscountAmount: totalDiscountAmount,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +totalDiscountAmount
            )
            : grandTotalForExclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            )
            : taxAmountForExclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            )
            : calculateSubtotalForExclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +totalDiscountAmount
            ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      folioId: 123,
      requestType: "early_checkout",
      CheckOutDate: formatDate(earlyCheckInDate),
      bookingNetTotal: calculateTotal(rooms, "NetTotal"),
      bookingTaxableAmount: calculateTotal(rooms, "TaxbleTotal"),
      bookingTaxAmount: calculateTotal(rooms, "TaxAmount"),
      Room: rooms,
    };

    setAmendStayTotal(requestBody.bookingNetTotal);
    setShowCheckOutCalculations(true);
  };
  const handleEarlyCheckOutSubmit = async (e) => {
    if (reasons.amendStayReason === "") {
      showErrorToast("Please enter the reason for early checkout");
      return;
    }

    // this function will perform the addition of all the child paxes and adult paxes so no need to multiply with no of child and no of adults
    if (!earlyCheckInDate) {
      showErrorToast("Please select early check-out date");
      return;
    }
    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };

    const no_of_night = calculateNumberOfNights(
      new Date(bookingData?.check_in),
      new Date(earlyCheckInDate)
    );
    const amendStayForRoom = amendStayDiscount / bookingData?.items?.length;
    const rooms = bookingData.items.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      // const discountedRatePerNight = +item.discount_amount / +item.no_nights;
      // const totalDiscountAmount = discountedRatePerNight * no_of_night;
      const totalDiscountAmount = amendStayForRoom;
      return {
        BookingItemId: item.id,
        NumberOfNights: no_of_night,
        Rate: item.room_rate,
        TaxRate: item.tax_rate,
        DiscountAmount: totalDiscountAmount,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +totalDiscountAmount
            )
            : grandTotalForExclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            )
            : taxAmountForExclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            )
            : calculateSubtotalForExclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +totalDiscountAmount
            ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      folioId: 123,
      requestType: "early_checkout",
      CheckOutDate: formatDate(earlyCheckInDate),
      bookingNetTotal: calculateTotal(rooms, "NetTotal"),
      bookingTaxableAmount: calculateTotal(rooms, "TaxbleTotal"),
      bookingTaxAmount: calculateTotal(rooms, "TaxAmount"),
      bookingDueAmount:
        +calculateTotal(rooms, "NetTotal") - +bookingData.paid_amount,
      Room: rooms,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      showSuccessToast("Stay has been reduced successfully");
      setAmendStayTotal(0);
      setShowCheckOutCalculations(false);
      fetchBookingDetails();
      setShowModifyPopup(false);
      setEarlyCheckInDate("");
      setReasons({});

      return data;
    } catch (error) {
      const errorMessage = error.message;
      showErrorToast(errorMessage);
      return null;
    }
  };
  // -----------late check out----------------------
  const [showExtendStay, setShowExtendStay] = useState(false);
  const [lateCheckOutDate, setLateCheckOutDate] = useState("");

  const handleLateCheckOutChange = async (e) => {
    const selectedDate = new Date(e.target.value);
    const checkOutDate = new Date(bookingData.check_out);

    if (selectedDate < checkOutDate) {
      showErrorToast(
        "Please select a date later than the current checkout date"
      );
      setLateCheckOutDate("");
    } else {
      const requestBody = {
        reservationId: bookingData.unique_booking_id,
        requestType: "check_availability",
        CheckInDate: formatDate(selectedDate),
        CheckOutDate: formatDate(checkOutDate),
        requestReason: "late_checkout",
        Room: bookingData.items.map((room) => ({
          BookingItemId: room.id,
          RoomID: room.room_id,
          NumberOfRooms: room.no_of_rooms,
        })),
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id?.id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        const data = await response.json();

        if (data.success) {
          showSuccessToast("Selected date is available for late checkout");
          setLateCheckOutDate(selectedDate);
        } else {
          showErrorToast("Selected date is not available for late checkout");
          setLateCheckOutDate("");
        }
      } catch (error) {
        // Handle any network or server errors
        console.error("Error while checking availability:", error);
        showErrorToast(
          "An error occurred while checking availability. Please try again later."
        );
        setLateCheckOutDate("");
      }
    }
  };

  const handleLateCheckOutCalculate = (e) => {
    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      // Check if paxes is an array before iterating over it
      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice = parseFloat(pax.price);
          } else if (pax.pax_type === "adult") {
            extraAdultPrice = parseFloat(pax.price);
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };

    const no_of_night = calculateNumberOfNights(
      new Date(bookingData?.check_in),
      new Date(lateCheckOutDate)
    );
    const amendStayForRoom = amendStayDiscount / bookingData?.items?.length;

    const rooms = bookingData.items.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      // const discountedRatePerNight = +item.discount_amount / +item.no_nights;
      // const totalDiscountAmount = discountedRatePerNight * no_of_night;
      const totalDiscountAmount = amendStayForRoom;
      return {
        BookingItemId: item.id,
        NumberOfNights: no_of_night,
        Rate: item.room_rate,
        TaxRate: item.tax_rate,
        RoomID: item.room_id,
        DiscountAmount: totalDiscountAmount,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +totalDiscountAmount
            )
            : grandTotalForExclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            )
            : taxAmountForExclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            )
            : calculateSubtotalForExclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +totalDiscountAmount
            ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      bookingDueAmount:
        +calculateTotal(rooms, "NetTotal") - +bookingData.paid_amount,
      folioId: 123,
      requestType: "extend_stay",
      CheckInDate: bookingData.check_out,
      CheckOutDate: formatDate(lateCheckOutDate),
      bookingNetTotal: calculateTotal(rooms, "NetTotal"),
      bookingTaxableAmount: calculateTotal(rooms, "TaxbleTotal"),
      bookingTaxAmount: calculateTotal(rooms, "TaxAmount"),
      Room: rooms,
    };

    setAmendStayTotal(requestBody.bookingNetTotal);
    setShowCheckOutCalculations(true);
  };
  const handleLateCheckOutSubmit = async (e) => {
    if (reasons.amendStayReason === "") {
      showErrorToast("Please enter the reason for late checkout");
      return;
    }
    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      // Check if paxes is an array before iterating over it
      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice = parseFloat(pax.price);
          } else if (pax.pax_type === "adult") {
            extraAdultPrice = parseFloat(pax.price);
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };

    const no_of_night = calculateNumberOfNights(
      new Date(bookingData?.check_in),
      new Date(lateCheckOutDate)
    );
    const amendStayForRoom = amendStayDiscount / bookingData?.items?.length;
    const rooms = bookingData.items.map((item, index) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      // const discountedRatePerNight = +item.discount_amount / +item.no_nights;
      // const totalDiscountAmount = discountedRatePerNight * no_of_night;

      const totalDiscountAmount = amendStayForRoom;
      return {
        BookingItemId: item.id,
        NumberOfNights: no_of_night,
        Rate: item.room_rate,
        TaxRate: item.tax_rate,
        RoomID: item.room_id,
        DiscountAmount: totalDiscountAmount,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +totalDiscountAmount
            )
            : grandTotalForExclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            )
            : taxAmountForExclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +item.tax_rate,
              +totalDiscountAmount
            )
            : calculateSubtotalForExclusive(
              +item.room_rate,
              +no_of_night,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +totalDiscountAmount
            ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      folioId: 123,
      requestType: "extend_stay",
      CheckInDate: bookingData.check_out,
      CheckOutDate: formatDate(lateCheckOutDate),
      bookingNetTotal: calculateTotal(rooms, "NetTotal"),
      bookingTaxableAmount: calculateTotal(rooms, "TaxbleTotal"),
      bookingTaxAmount: calculateTotal(rooms, "TaxAmount"),
      bookingDueAmount:
        +calculateTotal(rooms, "NetTotal") - +bookingData.paid_amount,
      Room: rooms,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      showSuccessToast("Stay has been extended successfully");
      setAmendStayTotal(0);
      setShowCheckOutCalculations(false);
      setShowModifyPopup(false);
      fetchBookingDetails();
      setLateCheckOutDate("");
      setReasons({});
      return data;
    } catch (error) {
      console.error(error);
      showErrorToast(error.message);
      return null;
    }
  };
  // ============amend stay end=======================

  //===========  change room category===================
  const [editedRoomCategory, setEditedRoomCategory] = useState([]);

  useEffect(() => {
    if (bookingData && bookingData.items) {
      const newEditedRoomCategory = bookingData.items.map((item) => ({
        room_category: item.room_type_name,
        changed_room_category: "",
        room_id: item.room_id,
        rate_plan_id: item.rate_plan_id,
        meal_plan: item.room_plan,
        room_rate_per_night: item.room_rate,
        ad_Hoc: item.ad_Hoc || "",
        room_rate: [],
        paxes: item.paxes,
        tax_type: item.tax_type,
        no_of_rooms: item.no_of_rooms,
        no_of_nights: item.no_nights,
        booking_id: item.id,
        discount_rate: item.discount_rate,
        id: item.rate_plan_id,
        new_room_rate: "",
        old_tax_rate: item.tax_rate,
        old_tax_amount: item.tax_amount,
        old_discount_amount: item.discount_amount,
        old_grand_total: item.room_grand_total,
        old_sub_total: item.room_total_amount,
      }));

      setEditedRoomCategory(newEditedRoomCategory);
    }
  }, [bookingData]);

  const [roomCategoryOptions, setRoomCategoryOptions] = useState([]);

  const fetchRoomCategory = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setRoomCategoryOptions(data?.rooms);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchRoomCategory();
    }
  }, [property_id]);

  const handleRoomCategoryChange = async (e, index, rooms) => {
    const room_id = e.target.value;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/check/availability/${property_id?.id}?check_in=${bookingData.check_in}&check_out=${bookingData.check_out}&room_id=${room_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      if (data.message === "Unauthorised.") {
        navigate("/");
      }

      // Check if rooms is greater than available rooms
      if (rooms >= data.available_rooms) {
        // Show error toast
        showErrorToast(
          `Failed to change room category. Rooms available for this category is ${data.available_rooms}. Please choose another category.`
        );
        return; // Stop executing the function
      }
    } catch (error) {
      console.error(error);
    }

    const changedRoomCategoryObject = roomCategoryOptions.find(
      (category) => category.id === parseInt(room_id)
    );
    const changedRoomCategory = changedRoomCategoryObject.room_name;
    setEditedRoomCategory((prevState) => {
      const updatedEditedRoomCategory = [...prevState];
      updatedEditedRoomCategory[index] = {
        ...updatedEditedRoomCategory[index],
        room_id: room_id,
        changed_room_category: changedRoomCategory,
      };
      return updatedEditedRoomCategory;
    });

    setShowRoomCategoryTotal(false);
    setNewRoomCategoryTotal(0);
  };

  const [showRoomRateField, setShowRoomRateField] = useState(
    Array(editedRoomCategory.length).fill(false)
  );
  const handleShowRoomRateField = (index) => {
    const updatedShowRoomRateField = [...showRoomRateField];
    updatedShowRoomRateField[index] = !updatedShowRoomRateField[index];
    setShowRoomRateField(updatedShowRoomRateField);
  };

  const [showRoomCategoryTotal, setShowRoomCategoryTotal] = useState(false);
  const [newRoomCategoryTotal, setNewRoomCategoryTotal] = useState(0);

  const handleRoomCategoryTotal = () => {
    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };
    const rooms = editedRoomCategory.map((item) => {
      const rate_per_night = item.new_room_rate_per_night
        ? item.new_room_rate_per_night
        : item.room_rate_per_night;

      const taxRate = +rate_per_night > 7500 ? 18 : 12;
      const discountAmount = item.old_discount_amount;

      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const modifiedItem = {
        // RoomID: item.singleRoomRate.room_id,
        Rate: rate_per_night,
        RoomTypeName: item.changed_room_category,
        // MealPlan: item.singleRoomRate.rate_plan_type,
        BookingItemId: item.booking_id,
        discountAmount: discountAmount,
        TaxRate: taxRate,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
              +rate_per_night,
              +item.no_of_nights,

              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +discountAmount
            )
            : grandTotalForExclusive(
              +rate_per_night,
              +item.no_of_nights,

              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            )
            : taxAmountForExclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            )
            : calculateSubtotalForExclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +discountAmount
            ),
      };
      return modifiedItem;
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "change_room_category",
      bookingNetTotal: calculateTotal(rooms, "NetTotal"),
      bookingTaxableAmount: calculateTotal(rooms, "TaxbleTotal"),
      bookingTaxAmount: calculateTotal(rooms, "TaxAmount"),
      Room: rooms,
    };
    setNewRoomCategoryTotal(requestBody.bookingNetTotal);
    setShowRoomCategoryTotal(true);
  };

  const handleRoomCategorySubmit = async (e) => {
    if (reasons.changeRoomCategoryReason === "") {
      showErrorToast("Please enter a reason for changing room category");
      return;
    }

    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };
    const rooms = editedRoomCategory.map((item) => {
      const rate_per_night = item.new_room_rate_per_night
        ? item.new_room_rate_per_night
        : item.room_rate_per_night;

      const taxRate = +rate_per_night > 7500 ? 18 : 12;
      const discountAmount = item.old_discount_amount;

      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const modifiedItem = {
        RoomID: item.room_id,
        Rate: rate_per_night,
        RoomTypeName: item.changed_room_category
          ? item.changed_room_category
          : item.room_category,
        // MealPlan: item.singleRoomRate.rate_plan_type,
        BookingItemId: item.booking_id,
        RoomDiscount: discountAmount,
        TaxRate: taxRate,
        RoomID: 12,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
              +rate_per_night,
              +item.no_of_nights,

              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +discountAmount
            )
            : grandTotalForExclusive(
              +rate_per_night,
              +item.no_of_nights,

              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            )
            : taxAmountForExclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            )
            : calculateSubtotalForExclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +discountAmount
            ),
      };
      return modifiedItem;
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "change_room_category",
      bookingNetTotal: calculateTotal(rooms, "NetTotal"),
      bookingTaxableAmount: calculateTotal(rooms, "TaxbleTotal"),
      bookingTaxAmount: calculateTotal(rooms, "TaxAmount"),
      Room: rooms,
      requestReason: reasons.changeRoomCategoryReason,
      bookingDiscountAmount: calculateTotal(rooms, "RoomDiscount"),
      bookingDueAmount:
        +calculateTotal(rooms, "NetTotal") - +bookingData.paid_amount,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      showSuccessToast("Room category changed successfully");
      setShowRoomCategoryTotal(false);
      setNewRoomCategoryTotal(0);
      setShowModifyPopup(false);
      setReasons({});
      fetchBookingDetails();

      return data;
    } catch (error) {
      console.error(error);
      showErrorToast(error.message);
      return null;
    }
  };

  const handleDiscountAmountChangeRoomCategory = (
    event,
    index,
    oldRoomRate,
    newRoomRate,
    noOfRooms
  ) => {
    const { name, value } = event.target;
    if (
      +value > +newRoomRate * +noOfRooms ||
      +value > +oldRoomRate * +noOfRooms
    ) {
      showErrorToast("enter valid discount amount");
      return;
    } else {
      setEditedRoomCategory((prevState) => {
        const updatedEditedRoomCategory = [...prevState];
        updatedEditedRoomCategory[index] = {
          ...updatedEditedRoomCategory[index],
          [name]: value,
        };
        return updatedEditedRoomCategory;
      });
      setShowRoomCategoryTotal(false);
      setNewRoomCategoryTotal(0);
    }
  };

  const handleNewRoomRateChangeRoomCategory = (event, index) => {
    const { name, value } = event.target;
    setEditedRoomCategory((prevState) => {
      const updatedEditedRoomCategory = [...prevState];
      updatedEditedRoomCategory[index] = {
        ...updatedEditedRoomCategory[index],
        [name]: value,
      };
      return updatedEditedRoomCategory;
    });
    setShowRoomCategoryTotal(false);
    setNewRoomCategoryTotal(0);
  };

  //================ change meal plans ===================
  const [editMealPlans, setEditMealPlans] = useState([]);
  useEffect(() => {
    if (bookingData && bookingData.items) {
      const newEditedRoomCategory = bookingData.items.map((item) => ({
        room_id: item.room_id,
        meal_plan: item.room_plan,
        room_rate_per_night: item.room_rate,
        paxes: item.paxes,
        tax_type: item.tax_type,
        no_of_rooms: item.no_of_rooms,
        no_of_nights: item.no_nights,
        booking_id: item.id,
        discount_rate: item.discount_rate,
        id: item.rate_plan_id,
        new_room_rate: "",
        old_tax_rate: item.tax_rate,
        old_tax_amount: item.tax_amount,
        old_discount_amount: item.discount_amount,
        old_grand_total: item.room_grand_total,
        old_sub_total: item.room_total_amount,
        meal_plan_options: [],
        rate_plan_id: item.rate_plan_id,
      }));

      setEditMealPlans(newEditedRoomCategory);
    }
  }, [bookingData]);

  const [enabledMealPlanChange, setEnabledMealPlanChange] = useState([]);

  const handleMealPlanChangeDisableEnable = async (e, index, room_id) => {
    const isChecked = e.target.checked;

    try {
      if (isChecked) {
        const response = await fetchMealPlans(room_id);
        const data = await response.json();
        if (data.message === "Unauthorised.") {
          navigate("/");
          return;
        }

        setEditMealPlans((prevEditMealPlans) => {
          const updatedEditMealPlans = [...prevEditMealPlans];
          updatedEditMealPlans[index] = {
            ...updatedEditMealPlans[index],
            meal_plan_options: data.roomRates, // Assuming roomRates contains the fetched meal plans
          };
          return updatedEditMealPlans;
        });

        // Add index to enabledMealPlanChange array
        setEnabledMealPlanChange((prevEnabledMealPlanChange) => [
          ...prevEnabledMealPlanChange,
          index,
        ]);
      } else {
        setEditMealPlans((prevEditMealPlans) => {
          const updatedEditMealPlans = [...prevEditMealPlans];
          updatedEditMealPlans[index] = {
            ...updatedEditMealPlans[index],
            meal_plan_options: [],
          };
          return updatedEditMealPlans;
        });

        // Remove index from enabledMealPlanChange array
        setEnabledMealPlanChange((prevEnabledMealPlanChange) =>
          prevEnabledMealPlanChange.filter((i) => i !== index)
        );

        fetchBookingDetails(); // Assuming this function fetches booking details when a checkbox is unchecked
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleMealPlanRateChange = (e, index) => {
    setShowTotalMealPlanCost(false);
    setCalculatedMealPlanCost(0);
    const { name, value } = e.target;
    const updatedEditMealPlans = [...editMealPlans];
    updatedEditMealPlans[index] = {
      ...updatedEditMealPlans[index],
      [name]: value,
    };
    setEditMealPlans(updatedEditMealPlans);
  };
  const handleMealPlanOptionChange = (e, index) => {
    setShowTotalMealPlanCost(false);
    setCalculatedMealPlanCost(0);
    const { name, value } = e.target;
    const updatedEditMealPlans = [...editMealPlans];
    updatedEditMealPlans[index] = {
      ...updatedEditMealPlans[index],
      rate_plan_id: value,
      meal_plan: updatedEditMealPlans[index].meal_plan_options.find(
        (option) => option.id === parseInt(value)
      ).rate_plan_type,
    };
    setEditMealPlans(updatedEditMealPlans);
  };

  const fetchMealPlans = async (room_id) => {
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/rooms/rates/${room_id}/4`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
  };
  const handleMealPlanDiscountChange = (e, index, roomRate, noOfRooms) => {
    const { name, value } = e.target;

    if (+value > +roomRate * +noOfRooms) {
      showErrorToast("please enter valid discount amount");
      return;
    } else {
      const updatedEditMealPlans = [...editMealPlans];
      updatedEditMealPlans[index] = {
        ...updatedEditMealPlans[index],
        [name]: value,
      };
      setEditMealPlans(updatedEditMealPlans);
      setShowTotalMealPlanCost(false);
      setCalculatedMealPlanCost(0);
    }
  };

  const [showTotalMealPlanCost, setShowTotalMealPlanCost] = useState(false);
  const [calculatedMealPlanCost, setCalculatedMealPlanCost] = useState(0);

  const handleChangeMealPlanCalculate = async (e) => {
    e.preventDefault();

    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };
    const rooms = editMealPlans.map((item) => {
      const rate_per_night = item.room_rate_per_night;

      const taxRate = +item.new_room_rate > 7500 ? 18 : 12;
      const discountAmount = item.old_discount_amount;

      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const modifiedItem = {
        BookingItemId: item.booking_id,
        RoomID: item.room_id,
        RatePlanName: "",
        RatePlanId: "",
        RoomDiscount: discountAmount,
        Rate: rate_per_night,

        TaxRate: taxRate,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
              +rate_per_night,
              +item.no_of_nights,

              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +discountAmount
            )
            : grandTotalForExclusive(
              +rate_per_night,
              +item.no_of_nights,

              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            )
            : taxAmountForExclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            )
            : calculateSubtotalForExclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +discountAmount
            ),
      };
      return modifiedItem;
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "change_mealplan",
      bookingNetTotal: calculateTotal(rooms, "NetTotal"),
      bookingTaxableAmount: calculateTotal(rooms, "TaxbleTotal"),
      bookingTaxAmount: calculateTotal(rooms, "TaxAmount"),
      bookingDiscountAmount: calculateTotal(rooms, "RoomDiscount"),
      requestReason: reasons.changeMealPlanReason,
      dueAmount: +calculateTotal(rooms, "NetTotal") - +bookingData.paid_amount,
      Room: rooms,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };
    setCalculatedMealPlanCost(requestBody.bookingNetTotal);
    setShowTotalMealPlanCost(true);
  };

  const handleChangeMealPlanSubmit = async (e) => {
    e.preventDefault();
    if (reasons.changeMealPlanReason === "") {
      showErrorToast("Please enter a reason for changing room category");
      return;
    }

    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };
    const rooms = editMealPlans.map((item) => {
      const rate_per_night = item.room_rate_per_night;

      const taxRate = +item.new_room_rate > 7500 ? 18 : 12;
      const discountAmount = item.old_discount_amount;

      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const modifiedItem = {
        BookingItemId: item.booking_id,
        RoomID: item.room_id,
        RatePlanName: item.meal_plan,
        RatePlanID: item.rate_plan_id,
        RoomDiscount: discountAmount,
        Rate: rate_per_night,
        TaxRate: taxRate,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
              +rate_per_night,
              +item.no_of_nights,

              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +discountAmount
            )
            : grandTotalForExclusive(
              +rate_per_night,
              +item.no_of_nights,

              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            )
            : taxAmountForExclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +taxRate,
              +discountAmount
            )
            : calculateSubtotalForExclusive(
              +rate_per_night,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +1,
              +extraAdultPrice,
              +discountAmount
            ),
      };
      return modifiedItem;
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "change_mealplan",
      bookingNetTotal: calculateTotal(rooms, "NetTotal"),
      bookingTaxableAmount: calculateTotal(rooms, "TaxbleTotal"),
      bookingTaxAmount: calculateTotal(rooms, "TaxAmount"),
      bookingDiscountAmount: calculateTotal(rooms, "RoomDiscount"),
      requestReason: reasons.changeMealPlanReason,
      bookingDueAmount:
        +calculateTotal(rooms, "NetTotal") - +bookingData.paid_amount,
      Room: rooms,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      showSuccessToast("Meal Plan changed successfully");
      setShowTotalMealPlanCost(false);
      setCalculatedMealPlanCost(0);
      setShowModifyPopup(false);
      setEnabledMealPlanChange([]);
      setReasons({});
      fetchBookingDetails();

      return data;
    } catch (error) {
      console.error(error);
      showErrorToast(error.message);
      return null;
    }
  };

  //==============END change room category=change meal plan=========

  //====================pax change==========================

  const [paxes, setPaxes] = useState([]);

  useEffect(() => {
    if (bookingData && bookingData.items) {
      const newEditedRoomCategory = bookingData?.items?.map((item) => ({
        booking_id: item.id,
        room_id: item.room_id,
        rate_plan_id: item.rate_plan_id,
        adults: item.adults,
        children: item.children,
        no_child: item.no_child,
        no_adult: item.no_adult,
        tax_type: item.tax_type,
        tax_rate: item.tax_rate,
        no_of_rooms: item.no_of_rooms,
        no_of_nights: item.no_nights,
        room_rate_per_night: item.room_rate,
        category: item.room_type_name,
        extraAdult: item.extra_adult,
        paxes: item.paxes,
        newExtraAdult: "",
        newExtraAdultCost: "",
        discount_amount: item.discount_amount,
      }));

      setPaxes(newEditedRoomCategory);
    }
  }, [bookingData]);

  const handleModifyPaxExtraAdult = (e, index) => {
    const { name, value } = e.target;
    const extraAdultPerRoom = paxes[index]?.limits?.allowedExtraAdult;
    const NumberOfRooms = paxes[index]?.no_of_rooms;

    if (+value > +extraAdultPerRoom * +NumberOfRooms) {
      showErrorToast("Not allowed please increase number of rooms");
    } else {
      setPaxes((prevPaxes) => {
        const updatedPaxes = [...prevPaxes];
        updatedPaxes[index] = {
          ...updatedPaxes[index],
          [name]: value,
        };
        return updatedPaxes;
      });
      setShowPaxCost(false);
      setPaxTotal(0);
      return; // Exit the function early
    }
  };

  const handleModifyPaxExtraAdultCost = (e, index) => {
    const { name, value } = e.target;
    setPaxes((prevPaxes) => {
      const updatedPaxes = [...prevPaxes];
      updatedPaxes[index] = {
        ...updatedPaxes[index],
        [name]: value,
      };
      return updatedPaxes;
    });
    setShowPaxCost(false);
    setPaxTotal(0);
    return; // Exit the function early
  };
  const [showPaxCost, setShowPaxCost] = useState(false);
  const [paxTotal, setPaxTotal] = useState(0);
  const [enabledPaxes, setEnabledPaxes] = useState([]);

  const handlePaxCalculation = () => {
    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };

    const room = paxes.map((item) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      const taxType = item.tax_type;
      const taxRate = item.tax_rate;
      const ratePerNight = item.room_rate_per_night;
      const extraAdult = item.newExtraAdult
        ? item.newExtraAdult
        : item.extraAdult;

      const extraAdultCost = item.newExtraAdult
        ? item.newExtraAdultCost
        : extraAdultPrice;

      return {
        BookingItemId: item.booking_id,
        RoomID: item.room_id,
        RatePlanID: item.rate_plan_id,
        baseAdult: item.no_adult,
        Child: item.no_child,
        extraAdult: item.newExtraAdult ? item.newExtraAdult : item.extraAdult,
        Rate: ratePerNight,
        TaxRate: item.tax_rate,
        discountAmount: item.discount_amount,
        NetTotal:
          taxType === "inclusive"
            ? grandTotalForInclusive(
              +ratePerNight,
              +item.no_of_nights,

              +item.no_of_rooms,
              +1,
              +childPrice,
              +extraAdult,
              +extraAdultCost,
              +item.discount_amount
            )
            : grandTotalForExclusive(
              +ratePerNight,
              +item.no_of_nights,

              +item.no_of_rooms,
              +1,
              +childPrice,
              +extraAdult,
              +extraAdultCost,
              +taxRate,
              +item.discount_amount
            ),
        TaxAmount:
          taxType === "inclusive"
            ? taxAmountForInclusive(
              +ratePerNight,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +extraAdult,
              +extraAdultCost,
              +taxRate,
              +item.discount_amount
            )
            : taxAmountForExclusive(
              +ratePerNight,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +extraAdult,
              +extraAdultCost,
              +taxRate,
              +item.discount_amount
            ),
        TaxbleTotal:
          taxType === "inclusive"
            ? calculateSubtotalForInclusive(
              +ratePerNight,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +extraAdult,
              +extraAdultCost,
              +taxRate,
              +item.discount_amount
            )
            : calculateSubtotalForExclusive(
              +ratePerNight,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +extraAdult,
              +extraAdultCost,
              +item.discount_amount
            ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "update_adult",
      bookingNetTotal: calculateTotal(room, "NetTotal"),
      bookingTaxableAmount: calculateTotal(room, "TaxbleTotal"),
      bookingTaxAmount: calculateTotal(room, "TaxAmount"),
      Room: room,
    };
    setPaxTotal(requestBody.bookingNetTotal);
    setShowPaxCost(true);
  };

  const handlePaxCalculationSubmit = async () => {
    if (reasons.paxDetailReason === "") {
      showErrorToast("Please enter a reason for changing pax details");
      return;
    }
    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price);
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price);
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };
    const room = paxes.map((item) => {
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);
      const taxType = item.tax_type;
      const taxRate = item.tax_rate;
      const ratePerNight = item.room_rate_per_night;
      const extraAdult = item.newExtraAdult ? item.newExtraAdult : 1;

      const extraAdultCost = item.newExtraAdult
        ? item.newExtraAdultCost
        : extraAdultPrice;

      return {
        BookingItemId: item.booking_id,
        RoomID: item.room_id,
        RatePlanID: item.rate_plan_id,
        RoomDiscount: item.room_discount,
        baseAdult: item.no_adult,
        Child: item.no_child,
        extraAdult: item.newExtraAdult ? item.newExtraAdult : item.extraAdult,
        Rate: ratePerNight,
        TaxRate: item.tax_rate,
        RoomDiscount: item.discount_amount,
        NetTotal:
          taxType === "inclusive"
            ? grandTotalForInclusive(
              +ratePerNight,
              +item.no_of_nights,

              +item.no_of_rooms,
              +1,
              +childPrice,
              +extraAdult,
              +extraAdultCost,
              +item.discount_amount
            )
            : grandTotalForExclusive(
              +ratePerNight,
              +item.no_of_nights,

              +item.no_of_rooms,
              +1,
              +childPrice,
              +extraAdult,
              +extraAdultCost,
              +taxRate,
              +item.discount_amount
            ),
        TaxAmount:
          taxType === "inclusive"
            ? taxAmountForInclusive(
              +ratePerNight,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +extraAdult,
              +extraAdultCost,
              +taxRate,
              +item.discount_amount
            )
            : taxAmountForExclusive(
              +ratePerNight,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +extraAdult,
              +extraAdultCost,
              +taxRate,
              +item.discount_amount
            ),
        TaxbleTotal:
          taxType === "inclusive"
            ? calculateSubtotalForInclusive(
              +ratePerNight,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +extraAdult,
              +extraAdultCost,
              +taxRate,
              +item.discount_amount
            )
            : calculateSubtotalForExclusive(
              +ratePerNight,
              +item.no_of_nights,
              +item.no_of_rooms,
              +1,
              +childPrice,
              +extraAdult,
              +extraAdultCost,
              +item.discount_amount
            ),
      };
    });

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "update_adult",
      bookingNetTotal: calculateTotal(room, "NetTotal"),
      bookingTaxableAmount: calculateTotal(room, "TaxbleTotal"),
      bookingTaxAmount: calculateTotal(room, "TaxAmount"),
      bookingDiscountAmount: bookingData.total_discount_amount,
      bookingDueAmount:
        +calculateTotal(room, "NetTotal") - +bookingData.paid_amount,
      paidAmount: bookingData.paid_amount,
      Room: room,
      requestReason: reasons.paxDetailReason,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modify/adult/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      showSuccessToast("Guest Details updated Successfully");
      setShowPaxCost(false);
      setPaxTotal(0);
      fetchBookingDetails();
      setShowModifyPopup(false);
      return data;
    } catch (error) {
      console.error(error);
      showErrorToast(error.message); // Pass the error message to showErrorToast
      return null;
    }
  };

  const handlePaxChangeDisableEnable = async (e, index, room_id) => {
    const checked = e.target.checked;
    if (checked) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/check/availability/${property_id?.id}?check_in=${bookingData.check_in}&check_out=${bookingData.check_out}&room_id=${room_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setPaxes((prevPaxes) => {
          const updatedPaxes = [...prevPaxes];
          updatedPaxes[index] = {
            ...prevPaxes[index],
            limits: data,
          };
          return updatedPaxes;
        });

        if (data.message === "Unauthorised.") {
          navigate("/");
        }
      } catch (error) {
        console.error(error);
      }

      setEnabledPaxes((prevState) => [...prevState, index]);
    } else {
      setEnabledPaxes((prevState) =>
        prevState.filter((item) => item !== index)
      );
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <div className="flex" style={styles.topBar}>
              <div style={styles.topBarChild}>
                <div style={styles.topBarChildSvgContainer}>
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                  >
                    <path
                      d="M2.62505 1.75C2.62505 1.28587 2.80942 0.840752 3.13761 0.512563C3.4658 0.184374 3.91092 0 4.37505 0C4.83917 0 5.28429 0.184374 5.61248 0.512563C5.94067 0.840752 6.12505 1.28587 6.12505 1.75C6.12505 2.21413 5.94067 2.65925 5.61248 2.98744C5.28429 3.31563 4.83917 3.5 4.37505 3.5C3.91092 3.5 3.4658 3.31563 3.13761 2.98744C2.80942 2.65925 2.62505 2.21413 2.62505 1.75ZM3.93755 10.5V13.125C3.93755 13.609 3.54653 14 3.06255 14C2.57856 14 2.18755 13.609 2.18755 13.125V7.86953L1.61606 8.77734C1.35903 9.1875 0.817624 9.30781 0.410202 9.05078C0.00277983 8.79375 -0.123001 8.25508 0.13403 7.84766L1.22505 6.1168C1.9059 5.03125 3.09536 4.375 4.37505 4.375C5.65473 4.375 6.84419 5.03125 7.52505 6.11406L8.61606 7.84766C8.87309 8.25781 8.75005 8.79648 8.34262 9.05352C7.9352 9.31055 7.3938 9.1875 7.13676 8.78008L6.56255 7.86953V13.125C6.56255 13.609 6.17153 14 5.68755 14C5.20356 14 4.81255 13.609 4.81255 13.125V10.5H3.93755Z"
                      fill="#888888"
                    />
                  </svg>
                  <p>{calculateTotal(bookingData?.items, "no_child")}</p>
                </div>

                <div
                  className="flex"
                  style={{
                    ...styles.topBarChildSvgContainer,
                    marginLeft: "10px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <path
                      d="M4.9998 4.29998C6.1596 4.29998 7.0998 3.35977 7.0998 2.19998C7.0998 1.04018 6.1596 0.0999756 4.9998 0.0999756C3.84001 0.0999756 2.8998 1.04018 2.8998 2.19998C2.8998 3.35977 3.84001 4.29998 4.9998 4.29998Z"
                      fill="#888888"
                    />
                    <path
                      d="M2.5498 9.90011C2.10194 9.90011 1.65407 9.72925 1.31237 9.38755C0.62895 8.70413 0.62895 7.59609 1.31237 6.91267C1.72485 6.50844 3.0398 5.69998 4.9998 5.69998C6.9598 5.69998 8.27476 6.50844 8.68723 6.91267C9.37065 7.59609 9.37065 8.70413 8.68723 9.38755C8.34553 9.72925 7.89767 9.90011 7.4498 9.90011H2.5498Z"
                      fill="#888888"
                    />
                  </svg>

                  <p>
                    {calculateTotal(bookingData?.items, "no_adult") +
                      calculateTotal(bookingData?.items, "extra_adult")}
                  </p>
                </div>
              </div>
              <div style={styles.topBarChild}>
                <div>
                  <p style={{ fontWeight: "500" }}>Arrival Date</p>
                  <p>{bookingData?.check_in}</p>
                </div>
              </div>
              <div style={styles.topBarChild}>
                <div>
                  <p style={{ fontWeight: "500" }}>Departure Date</p>
                  <p>{bookingData?.check_out}</p>
                </div>
              </div>
              <div style={styles.topBarChild}>
                <p className="flex" style={styles.topBarChildSvgContainer}>
                  <div>
                    <p style={{ fontWeight: "500" }}>Nights</p>

                    <div
                      className="flex"
                      style={styles.topBarChildSvgContainer}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                      >
                        <path
                          d="M3.54167 0.666626C4.34861 0.666626 5.10694 0.819848 5.81667 1.12629C6.52639 1.43274 7.14375 1.84826 7.66875 2.37288C8.19375 2.89788 8.60947 3.51524 8.91592 4.22496C9.22236 4.93468 9.37539 5.69301 9.375 6.49996C9.375 7.3069 9.22178 8.06524 8.91533 8.77496C8.60889 9.48468 8.19336 10.102 7.66875 10.627C7.14375 11.152 6.52639 11.5678 5.81667 11.8742C5.10694 12.1807 4.34861 12.3337 3.54167 12.3333C3.02639 12.3333 2.52317 12.2678 2.032 12.1367C1.54083 12.0057 1.07183 11.8087 0.625 11.5458C1.52917 11.0208 2.24142 10.3111 2.76175 9.41663C3.28208 8.52218 3.54206 7.54996 3.54167 6.49996C3.54167 5.44996 3.2815 4.47774 2.76117 3.58329C2.24083 2.68885 1.52878 1.97913 0.625 1.45413C1.07222 1.19163 1.54142 0.994848 2.03258 0.863793C2.52375 0.732737 3.02678 0.667015 3.54167 0.666626Z"
                          fill="#888888"
                        />
                      </svg>
                      <p>{bookingData?.items[0].no_nights}</p>
                    </div>
                  </div>
                </p>
              </div>
              <div style={{ ...styles.topBarChild }}>
                <div>
                  <p style={{ fontWeight: "500" }}>Rooms</p>
                  <div className="flex" style={styles.topBarChildSvgContainer}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 12 9"
                      fill="none"
                    >
                      <path
                        d="M11.2498 3.78829V2.16663C11.2498 1.20413 10.4623 0.416626 9.49984 0.416626H7.1665C6.71734 0.416626 6.309 0.591626 5.99984 0.871626C5.69067 0.591626 5.28234 0.416626 4.83317 0.416626H2.49984C1.53734 0.416626 0.749837 1.20413 0.749837 2.16663V3.78829C0.394004 4.10913 0.166504 4.56996 0.166504 5.08329V8.58329H1.33317V7.41663H10.6665V8.58329H11.8332V5.08329C11.8332 4.56996 11.6057 4.10913 11.2498 3.78829ZM7.1665 1.58329H9.49984C9.82067 1.58329 10.0832 1.84579 10.0832 2.16663V3.33329H6.58317V2.16663C6.58317 1.84579 6.84567 1.58329 7.1665 1.58329ZM1.9165 2.16663C1.9165 1.84579 2.179 1.58329 2.49984 1.58329H4.83317C5.154 1.58329 5.4165 1.84579 5.4165 2.16663V3.33329H1.9165V2.16663Z"
                        fill="#888888"
                      />
                    </svg>
                    <p>{calculateTotal(bookingData?.items, "no_of_rooms")}</p>
                  </div>
                </div>
              </div>
              <div style={{ ...styles.topBarChild }}>
                <div>
                  <p style={{ fontWeight: "500" }}>Status</p>
                  <div className="flex" style={styles.topBarChildSvgContainer}>
                    {/* <p>{bookingData?.status}</p> */}
                    <BookingStatusUpdate
                      status={bookingData?.status}
                    />
                  </div>

                </div>
              </div>
              <div style={{ ...styles.topBarChild }}>
                <div>
                  <p style={{ fontWeight: "500" }}>Booking ID</p>
                  <div className="flex" style={styles.topBarChildSvgContainer}>
                    <p>{bookingData?.unique_booking_id}</p>
                  </div>
                </div>
              </div>
              <div style={{ ...styles.topBarChild, borderRight: "none" }}>
                <div>
                  <div className="flex" style={styles.topBarChildSvgContainer}>
                    <BgThemeButton onClick={handleModifyButtonClick}>
                      Modify Reservation
                    </BgThemeButton>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="flex"
              style={{
                marginTop: "20px",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "70%" }}>
                <CreateBookingTableComponent
                  heading="Add Ons"
                  bookingID={bookingID}
                  TableHeaders={[
                    "Name",
                    "Amt",
                    "Dic. %",
                    "Tax Rate",
                    "Total Amt.",
                  ]}
                  addOnsFromParentApi={addOnsFromParentApi}
                  fetchBookingDetailsForAddons={fetchBookingDetails}
                  grandTotal={bookingData.grand_total}
                  totalTaxAmount={bookingData.total_tax_amount}
                  totalDiscountAmount={bookingData.total_discount_amount}
                  subtotal={bookingData.taxable_amount}
                  paidAmount={bookingData.paid_amount}
                />
                <CreateBookingTableComponentTwo
                  heading="Payments"
                  TableHeaders={[
                    "Payment Type",
                    "Payment Mode",
                    "Remarks/Reason",
                    "Paid Amount",
                  ]}
                  getPaymentData={getPaymentData}
                  paymentFromParentApi={paymentsFromParentApi}
                  fetchBookingDetailsForAddons={fetchBookingDetails}
                  bookingID={bookingID}
                  bookingData={bookingData}
                />

                {/* ========================guest details========================= */}

                <div className="create_booking_primary_guest_details_container_wrapper">
                  <div className="create_booking_primary_guest_details_container">
                    <h4>Primary Guest Details</h4>
                    <div className="create_booking_primary_guest_details">
                      <div className="create_booking_primary_guest_details_input_container">
                        <label className="standard-select">
                          Name <br />
                        </label>
                        <div className="flex gap_5">
                          <input
                            type="text"
                            required
                            className="create_booking_primary_guest_details_input"
                            name="name"
                            value={bookingData.guest.guest_name}
                            disabled
                            onChange={handleGuestNameSalutationChange}
                          />
                        </div>
                      </div>
                      <div className="create_booking_primary_guest_details_input_container">
                        <label className="standard-select">Phone </label> <br />
                        <input
                          type="number"
                          required
                          className="create_booking_primary_guest_details_input"
                          value={bookingData.guest.guest_phone}
                          disabled
                          name="phone"
                          onChange={handleGuestDetails}
                        />
                      </div>

                      <div className="create_booking_primary_guest_details_input_container">
                        <label className="standard-select">Email </label> <br />
                        <input
                          type="email"
                          className="create_booking_primary_guest_details_input"
                          value={bookingData.guest.guest_email}
                          name="email"
                          disabled
                          onChange={handleGuestDetails}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="create_booking_primary_guest_details_container">
                    <h4>Remarks</h4>
                    <div className="create_booking_primary_guest_details">
                      <textarea
                        type="text"
                        required
                        className="create_booking_primary_guest_details_input_remarks"
                        name="remarks"
                        value={bookingData.remarks}
                        disabled
                        onChange={handleGuestDetails}
                        style={{
                          width: "100%",
                          resize: "none",
                          padding: "5px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* ===================guest details end======================= */}
              </div>

              <div
                className="create_booking_page_total_amount_card"
                style={{ width: "29%" }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    padding: "20px",
                    borderBottom: "0.5px solid #333",
                  }}
                >
                  <div className="flex flex_gap_15">
                    <p>
                      {" "}
                      <b>Tax Type :</b>{" "}
                      <span style={styles.textTransform}>
                        {bookingData?.items[0]?.tax_type}
                      </span>
                    </p>
                  </div>
                </div>
                <div style={{ padding: "20px", position: "relative" }}>
                  <div className="crate_booking_page_total_amount_heading">
                    <span>Subtotal</span>
                    <span>
                      {formatIndianCurrency(bookingData?.taxable_amount)}
                    </span>
                  </div>
                  <div className="crate_booking_page_total_amount_heading">
                    <span>Discount Amt.</span>
                    <span>
                      {formatIndianCurrency(bookingData?.total_discount_amount)}
                    </span>
                  </div>

                  <div className="crate_booking_page_total_amount_heading">
                    <span>Tax Amt.</span>
                    <span>
                      {formatIndianCurrency(bookingData?.total_tax_amount)}
                    </span>
                  </div>
                  <div
                    className="crate_booking_page_total_amount_heading"
                    style={{ color: "#3968ED" }}
                  >
                    <span>Grand Total</span>
                    <span>
                      {formatIndianCurrency(bookingData?.grand_total)}
                    </span>
                  </div>

                  <div
                    className="crate_booking_page_total_amount_heading"
                    style={{ color: "#15AA12" }}
                  >
                    <h4>Paid Amt.</h4>
                    <h3>
                      {/* {formatIndianCurrency(sumPaidAmount)} */}
                      {formatIndianCurrency(bookingData?.paid_amount)}
                    </h3>
                  </div>
                  <div
                    className="crate_booking_page_total_amount_heading"
                    style={{ color: "#BD941B" }}
                  >
                    <h4>Due Amt.</h4>
                    <h3>{formatIndianCurrency(bookingData?.balance_amount)}</h3>
                  </div>
                </div>

                <div className="flex gap_7" style={{ padding: "20px" }}>
                  {/* <BgThemeButton
                    //   onClick={handleConfirmButtonClick}
                    type={"submit"}
                    children={"Confirm"}
                  /> */}
                </div>
              </div>
            </div>

            {showSalutationPopup && (
              <>
                <div className="global_popup_container_background">
                  <div className="global_popup_container ">
                    <div className="GoBackButtonCustom">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => setShowSalutationPopup(false)}
                      >
                        <rect
                          x="29.75"
                          y="29.75"
                          width="29.5"
                          height="29.5"
                          rx="5.75"
                          transform="rotate(180 29.75 29.75)"
                          stroke="#666666"
                          strokeWidth="0.5"
                        ></rect>
                        <line
                          x1="21"
                          y1="15.043"
                          x2="9"
                          y2="15.043"
                          stroke="#666666"
                        ></line>
                        <path
                          d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
                          stroke="#666666"
                          strokeWidth="1.2"
                        ></path>
                      </svg>
                      <span>Add </span>
                    </div>

                    <div>
                      <VerticalInputFieldCustom
                        titleName={"Salutation"}
                        name={"name"}
                        type={"text"}
                        value={popUpSalutation}
                        onChange={(e) => setPopUpSalutation(e.target.value)}
                      />

                      <BgThemeButton
                        children={"Add"}
                        onClick={handlePopupAddButtonClick}
                        style={{ margin: "10px 0" }}
                      />
                    </div>
                    {/* <div>
                    {
                      salutations?.map((item,index)=>{
                        return(
                          <div key={index}>
                            <span>{item?.name}</span>  <button onClick={()=>handleSalutationDelete(item.id)}>del</button><button>edit</button>
                          </div>
                        )
                      })
                    }
                   </div> */}
                  </div>
                </div>
              </>
            )}

            {/* -----------------------------Modify Reservation Popup-------------------------------- */}

            {showModifyPopup && (
              <ModifyReservationPopup
                setShowModifyPopup={setShowModifyPopup} // state passed from parent to close popup
                bookingID={bookingID} // booking id
                getBookingData={fetchBookingDetails} // fxn call api pass unique booking id
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CreateBookingNewEdit;
