import React, { useEffect, useState } from "react";

// import CreateBookingSelectBox from "../CreateBookingSelectBox/CreateBookingSelectBox";
import { useSelector } from "react-redux";
import "./SentMessageCard.css";
import { showErrorToast } from "../../../../assets/toastUtils";
import { useNavigate } from "react-router-dom";
import CreateBookingInputBox from "../../CreateBookingNewPage/CreateBookingInputBox/CreateBookingInputBox";
import CreateBookingEditableInput from "../../CreateBookingNewPage/CreateBookingEditableInput/CreateBookingEditableInput";

const SentMessageCard = ({
  data: datas,
  BookingType,
  toggleHoldPopup,
  RoomCategoryOptions,
  checkIn,
  checkOut,
  index,
  handleDeleteBookingCard,
  allowedRooms,
  getDataFromChild,
  setAvailableRooms,
  allowedChild,
  tax_type,
  availableRooms,
  discount_type,
  setShowDiscountSelect,
  showDiscountSelect,
}) => {
  useEffect(() => {
    setsingleMessageData((prevData) => ({
      ...prevData,
      discount_type: discount_type,
    }));
  }, [discount_type]);

  const [cardAvailableRooms] = useState(availableRooms);
  const [showDiscount, setShowDiscount] = useState(true);

  const [singleMessageData, setsingleMessageData] = useState(datas);

  useEffect(() => {
    setsingleMessageData((prevData) => ({
      ...prevData,
      tax_type: tax_type,
    }));
  }, [tax_type]);

  const [additionalBookingDetails, setAdditionalBookingDetails] = useState([]);

  const [taxRate, setTaxRate] = useState(0);
  const [isRoomPlanSelected, setIsRoomPlanSelected] = useState(false);

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);

  const handleRoomPlanChange = (e) => {
    fetchRoomPlans(e.target.value);
    // singleMessageData?.discount_type?.discount_amount
    setsingleMessageData((prevData) => ({
      ...prevData,
      discount_type: 0,
    }));
    setIsRoomPlanSelected(true);
  };

  const handleRoomCategory = (e) => {
    const id = RoomCategoryOptions.find(
      (item) => item?.room_name === e.target.value
    ).id;
    setsingleMessageData({
      ...singleMessageData,
      room_category: e.target.value,
      room_id: id,
    });
  };

  const navigate = useNavigate();

  const fetchRoomPlans = async (mealplan_id) => {
    try {
      const payload = {
        check_in: checkIn,
        check_out: checkOut,
        room_id: singleMessageData.room_id,
        mealplan_id: mealplan_id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/rooms/rates/details/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      setAdditionalBookingDetails(data?.rate);
      setTaxRate(0);
      setsingleMessageData((prev) => ({
        ...prev,
        room_rate: data.rate.rate_bar_price,
      }));

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setsingleMessageData((prevData) => ({
      ...prevData,
      room_details: additionalBookingDetails,
    }));
  }, [additionalBookingDetails, setsingleMessageData]);

  // fetch room plans and  updating the states for extra adult and child with bed
  useEffect(() => {
    setExtraAdultData((prevData) =>
      prevData.map((item) => ({
        ...item,
        rate: additionalBookingDetails.extra_adult,
      }))
    );
  }, [additionalBookingDetails.extra_adult]);

  useEffect(() => {
    setChildData((prevData) =>
      prevData.map((item) => ({
        ...item,
        rate: additionalBookingDetails.child_with_Bed,
      }))
    );
  }, [additionalBookingDetails.child_with_Bed]);

  const [showAddHocPrice, setShowAddHocPrice] = useState(false);

  //--show add hoc for extra  adults--

  const [activeIndexAdults, setActiveIndexAdults] = useState(
    Array.from({ length: 3 }, () => false)
  );
  const handleShowAddHocAdult = (index) => {
    setActiveIndexAdults((prevActiveIndexAdults) => {
      const updatedActiveIndexAdults = [...prevActiveIndexAdults];
      updatedActiveIndexAdults[index] = !updatedActiveIndexAdults[index];
      return updatedActiveIndexAdults;
    });
  };

  //-- show add for child --

  // const [activeIndexChild, setActiveIndexChild] = useState(null);

  const [activeIndexChild, setActiveIndexChild] = useState(
    Array.from({ length: +singleMessageData.no_child }, () => false)
  );
  const handleShowAddHocChild = (index) => {
    setActiveIndexChild((prevActiveIndexAdults) => {
      const updatedActiveIndexAdults = [...prevActiveIndexAdults];
      updatedActiveIndexAdults[index] = !updatedActiveIndexAdults[index]; // Toggle the active state
      return updatedActiveIndexAdults;
    });
  };

  const initialChildData = Array.from(
    { length: +singleMessageData.no_child },
    (_, index) => ({
      age: singleMessageData?.booking_paxs[index]
        ? singleMessageData?.booking_paxs[index].age
        : "0",
      rate: singleMessageData.booking_paxs[index]
        ? singleMessageData?.booking_paxs[index].price
        : "0",
      ad_hoc_rate: singleMessageData.booking_paxs[index]
        ? singleMessageData?.booking_paxs[index].adhoc_price
        : "0",
      is_extra_bed_occupied: singleMessageData.booking_paxs[index]
        ? singleMessageData?.booking_paxs[index].is_extra_bed_occupied
        : "0",
    })
  );

  const [childData, setChildData] = useState(
    singleMessageData.booking_paxs.filter((item) => item.pax_type === "child")
  );

  const [subTotal, setSubTotal] = useState("");
  const [grandTotal, setGrandTotal] = useState("");

  const handleChildAgeChange = (e, index) => {
    const age = parseInt(e.target.value);

    setChildData((prevData) => {
      const newData = [...prevData];
      if (isNaN(age)) {
        newData[index].age = "";
      } else if (age > 12) {
        newData[index].age = "";

        showErrorToast("Age should be less than 12");
      } else if (age < 6) {
        newData[index].age = age;
        newData[index].rate = 0;
      } else {
        newData[index].age = age;
        newData[index].rate = additionalBookingDetails.child_with_Bed;
      }
      return newData;
    });
  };

  const handleChildAdHocRate = (e, index) => {
    const { name, value } = e.target;
    setChildData((prevData) => {
      const newData = [...prevData];
      newData[index][name] = value;
      return newData;
    });
  };

  // -- for extra adults --
  const [extraAdultData, setExtraAdultData] = useState(
    singleMessageData.booking_paxs.filter((item) => item.pax_type === "adult")
  );

  const handleExtraAdultChange = (e, index) => {
    const { name, value } = e.target;
    setExtraAdultData((prevData) => {
      const newData = [...prevData];
      newData[index]["rate"] = additionalBookingDetails.extra_adult;
      newData[index][name] = value;
      return newData;
    });
  };

  const [adHocRoom, setAddHocRooms] = useState("");

  useEffect(() => {
    // ---------------------calculating total amount for extra adult with condition for ad-hoc---------------
    const totalExtraAdultRate = extraAdultData.reduce((acc, item) => {
      return acc + (item.ad_hoc_rate ? +item.ad_hoc_rate : +item.price);
    }, 0);

    // ----------calculating total amount for extra adult with condition for ad-hoc-----------------------
    const totalChidRate = childData.reduce((acc, item) => {
      const cost =
        Number(acc) +
        (Number(item.ad_hoc_rate)
          ? +Number(item.ad_hoc_rate)
          : Number(item.price));
      //  if(isNaN(cost)){
      //   return cost
      // }else{
      //   return 0
      // }
      return cost ? cost : 0;
    }, 0);
    const roomPerNightConditionForAdhoc =
      additionalBookingDetails.rate_bar_price
        ? +additionalBookingDetails.rate_bar_price
        : +singleMessageData.room_rate;

    // ---------------------calculation for discount amount------------------
    const discountPercent = singleMessageData.discount_type
    let discountAmount =
      (+roomPerNightConditionForAdhoc * +discountPercent) / 100;

    if (isNaN(discountAmount)) {
      discountAmount = 0;
    }


    // ---------------------calculate discount Amount tac----------------------
    let tacDiscountAmount =
      ((+additionalBookingDetails.rate_bar_price - discountAmount) *
        +singleMessageData?.tac_discount) /
      100;

    if (isNaN(tacDiscountAmount)) {
      tacDiscountAmount = 0;
    }
    // console.log(tacDiscountAmount, "tacDiscountAmount")

    // console.log(tacDiscountAmount,"tacDiscountAmount")

    if (adHocRoom !== "") {
      discountAmount = 0;
    }

    // ---------- Total Discount Amount -------------------------------
    const totalDiscountAmount = discountAmount + tacDiscountAmount;



    // ------Total Room Rate Per Night Condition for ad----

    // console.log(roomPerNightConditionForAdhoc, "lshdklqahcqewh ");

    if (singleMessageData.tax_type === "exclusive") {
      // -----------condition for setting total room rate per night starts here------------
      let totalRoomRatePerNight =
        roomPerNightConditionForAdhoc - totalDiscountAmount;

      // console.log(totalRoomRatePerNight, "totalRoomRatePerNight")

      // -------condition for setting total room rate per night Ends here---------------

      // const totalRomRatePerNightWithExtraAdultAndChild = totalRoomRatePerNight + totalExtraAdultRate + totalChidRate

      const subTotalForExclusive =
        totalRoomRatePerNight *
        +singleMessageData.no_nights *
        +singleMessageData.no_of_rooms +
        (totalExtraAdultRate + totalChidRate) * +singleMessageData.no_nights;

      // console.log(subTotalForExclusive, "subTotalForExclusive");
      setSubTotal(subTotalForExclusive);
      const taxAmount = (subTotalForExclusive * +taxRate) / 100;
      // console.log(taxAmount, "tax anount");
      const grandTotalForExclusive = subTotalForExclusive + taxAmount;
      setGrandTotal(grandTotalForExclusive);

      // console.log(subTotalForExclusive, "subTotalForExclusive")

      setsingleMessageData((prevData) => {
        return {
          ...prevData,
          grandTotal: grandTotalForExclusive,
          taxAmount: taxAmount,
          tax_rate: taxRate,
          subTotal: subTotal,
          // room_total_amount:subTotalForExclusive,
          discount_amount:
            totalDiscountAmount *
            +singleMessageData.no_of_rooms *
            +singleMessageData.no_nights,
        };
      });
    } else if ((singleMessageData.tax_type = "inclusive")) {
      // -----------condition for setting total room rate per night starts here------------

      let totalRoomRatePerNightInclusive;
      if (
        adHocRoom !== "" &&
        +adHocRoom < +additionalBookingDetails.rate_bar_price
      ) {
        totalRoomRatePerNightInclusive =
          roomPerNightConditionForAdhoc - tacDiscountAmount;
      } else {
        totalRoomRatePerNightInclusive =
          roomPerNightConditionForAdhoc - totalDiscountAmount;
      }

      // -------condition for setting total room rate per night Ends here---------------

      const grandTotalForInclusive =
        totalRoomRatePerNightInclusive *
        +singleMessageData.no_nights *
        +singleMessageData.no_of_rooms +
        (totalExtraAdultRate + totalChidRate) * +singleMessageData.no_nights;

      // console.log(grandTotalForInclusive ,"grandTotalForInclusive")

      const taxAmountInclusive =
        (grandTotalForInclusive * +taxRate) / (100 + +taxRate);

      const subtotalInclusive =
        (grandTotalForInclusive * 100) / (100 + +taxRate);
      setSubTotal(subtotalInclusive);
      setGrandTotal(grandTotalForInclusive);
      setsingleMessageData((prevData) => {
        return {
          ...prevData,
          grandTotal: grandTotalForInclusive,
          taxAmount: taxAmountInclusive,
          tax_rate: taxRate,
          subTotal: subtotalInclusive,
          // room_total_amount: subtotalInclusive,
          discount_amount:
            totalDiscountAmount *
            +singleMessageData.no_of_rooms *
            +singleMessageData.no_nights,
        };
      });
    } else {
      return;
    }
  }, [
    extraAdultData,
    childData,
    adHocRoom,
    additionalBookingDetails,
    singleMessageData.tax_type,
    singleMessageData.discount_type?.discount_amount,
    singleMessageData.ad_hoc_rate,
    singleMessageData.rate_bar_price,
    singleMessageData.tac_discount,
    singleMessageData.no_of_rooms,
    singleMessageData.no_of_nights,
    singleMessageData.tax_rate,
    taxRate,
    setSubTotal,
    setsingleMessageData,
    adHocRoom,
    singleMessageData.discount_amount,
    singleMessageData.discount_type
  ])

  // deciding the tax rate 12 or 18

  useEffect(() => {
    if (+singleMessageData.room_rate < 7500) {
      setTaxRate(12);
    } else if (+singleMessageData.room_rate >= 7500) {
      setTaxRate(18);
    } else {
      return;
    }

    if (adHocRoom !== "" && +adHocRoom <= 7500) {
      setTaxRate(12);
    } else if (adHocRoom > 7500) {
      setTaxRate(18);
    } else {
      return;
    }
    setsingleMessageData((prevData) => {
      return {
        ...prevData,
        tax_rate: taxRate,
      };
    });
  }, [
    additionalBookingDetails.rate_bar_price,
    setTaxRate,
    adHocRoom,
    singleMessageData.tax_rate,
  ]);

  const handleAdoRatePerNightChange = (e) => {
    setsingleMessageData((prevData) => {
      return {
        ...prevData,
        add_hoc_room: value,
      };
    });
    setAddHocRooms(e.target.value);
    const { name, value } = e.target;

    if (+value >= +additionalBookingDetails?.rate_bar_price) {
      setsingleMessageData((prevData) => {
        return {
          ...prevData,
          [name]: value,
          discount_type: {
            ...prevData.discount_type,
            discount_amount: 0,
          },
        };
      });
    } else {
      setsingleMessageData((prevData) => {
        return {
          ...prevData,

          [name]: value,
          discount_type: {
            ...prevData.discount_type,
            discount_amount: "",
          },
        };
      });
    }
    if (value === "") {
      setsingleMessageData((prevData) => {
        return {
          ...prevData,
          [name]: value,
          discount_type: {
            ...prevData.discount_type,
            discount_amount: 0,
          },
        };
      });
    }
  };

  const handleCommonInputsChange = (e) => {
    const { name, value } = e.target;

    if (name === "no_of_rooms") {
      const numberOfRooms = +value;
      if (numberOfRooms > allowedRooms) {
        alert("test");
        showErrorToast(`Maximum number of rooms allowed is ${allowedRooms}`);
        return;
      } else {
        setsingleMessageData((prevData) => {
          return {
            ...prevData,
            [name]: value,
          };
        });

        setAvailableRooms((prevData) => {
          return {
            ...prevData,
            available_rooms: allowedRooms - +value,
          };
        });
      }
    }
  };
  const handleChildRadioButtons = (e, index) => {
    const { name, value } = e.target;
    setChildData((prevData) => {
      return prevData.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            rate: value,
            extra_bed_occupied:
              value === additionalBookingDetails.child_with_Bed ? true : false,
          };
        }
        return item;
      });
    });
  };

  useEffect(() => {
    getDataFromChild(index, singleMessageData);
  }, [singleMessageData]);

  // calculating discount percentage for backend in ad hoc rates
  function calculateDiscountPercentage(originalPrice, adhocPrice) {
    if (adhocPrice !== "") {
      if (+originalPrice > 0 + adhocPrice) {
        return ((+originalPrice - +adhocPrice) / +originalPrice) * 100;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  useEffect(() => {
    const childDataWithType = childData.map((obj) => ({
      price: obj.ad_hoc_rate ? obj.ad_hoc_rate : obj.rate,
      child_age: obj.age,
      adhoc_price: obj.ad_hoc_rate,
      pax_type: "child",
      // discount_rate: +obj.ad_hoc_rate > +obj.rate ? 0 : calculateDiscountPercentage(obj.rate, obj.ad_hoc_rate),
      // discount_amount: obj.ad_hoc_rate !== "" && +obj.ad_hoc_rate < +obj.rate ? +obj.rate - +obj.ad_hoc_rate : 0,
      rate: obj.rate,
    }));
    const extraAdultDataWithType = extraAdultData.map((obj) => ({
      price: obj.ad_hoc_rate ? obj.ad_hoc_rate : obj.rate,
      adhoc_price: obj.ad_hoc_rate,
      pax_type: "adult",
      // discount_rate: +obj.ad_hoc_rate > +obj.rate ? 0 : calculateDiscountPercentage(obj.rate, obj.ad_hoc_rate),
      // discount_amount:
      //   obj.ad_hoc_rate !== "" && +obj.ad_hoc_rate < +obj.rate ? +obj.rate - +obj.ad_hoc_rate : 0,

      rate: obj.rate,
    }));
    const mergedArray = childDataWithType.concat(extraAdultDataWithType);

    setsingleMessageData((prevData) => ({
      ...prevData,
      booking_paxes: mergedArray,
    }));
  }, [childData, extraAdultData]);

  //number of adult change handler it checks the number of max adult per room and if it exceeds it shows error toast
  const handleNumberOfAdultsChange = (e) => {
    const { name, value } = e.target;
    const numberOfRooms = +singleMessageData.no_of_rooms;
    if (!numberOfRooms) {
      return showErrorToast("Please enter number of rooms");
    }

    const maxAdultsPerRoom = cardAvailableRooms["0"]?.max_adult;
    if (+value > +maxAdultsPerRoom * numberOfRooms) {
      showErrorToast(`Maximum number of adults allowed reached`);
      setsingleMessageData((prevData) => ({
        ...prevData,
        [name]: "",
      }));
    } else {
      setsingleMessageData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // number of child change handler
  const handleChildInputChange = (e) => {
    const { name, value } = e.target;
    const allowedChild = +cardAvailableRooms["0"]?.max_child;
    const numberOfRooms = +singleMessageData.no_of_rooms;
    const numberOfChildren = +value;
    if (numberOfChildren > allowedChild * numberOfRooms) {
      showErrorToast(`No of child cannot exceed ${allowedChild}`);
    } else {
      setsingleMessageData((prevData) => {
        return {
          ...prevData,
          [name]: value,
        };
      });
      setChildData(
        Array.from({ length: numberOfChildren }, () => ({
          age: "",
          rate: additionalBookingDetails.child_with_Bed || 0,
          price: "",
          ad_hoc_rate: "",
        }))
      );
      setActiveIndexChild(
        Array.from({ length: numberOfChildren }, () => false)
      );
    }
  };

  // number of extra adult handler

  const handleCommonExtraAdultChange = (e) => {
    const { name, value } = e.target;
    const perRoomExtraAdult = +cardAvailableRooms["0"].allowedExtraAdult;

    const noOfRooms = +singleMessageData.no_of_rooms;
    if (!noOfRooms) {
      return showErrorToast("Please enter number of rooms");
    }

    if (+value > perRoomExtraAdult * noOfRooms) {
      showErrorToast("max limit reached please increase number of rooms");
    } else {
      setsingleMessageData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setExtraAdultData(
        Array.from({ length: +value }, () => ({
          rate: additionalBookingDetails.extra_adult,
          ad_hoc_rate: "",
        }))
      );
      setActiveIndexAdults(Array.from({ length: +value }, () => false));
    }
  };

  return (
    <>
      <div
        className="create_booking_page_bottom align_center"
        style={{ marginLeft: "0px", width: "100" }}
      >
        <div style={{ paddingLeft: "13px" }}>
          <div className="flex align_center create_booking_page_bottom_head">
            <h5 style={{ margin: "10px 10px" }}>
              {singleMessageData.room_type_name}
            </h5>

            {BookingType === "Multiple" ? (
              <button
                className="create_booking_page_card_bottom"
                onClick={() => toggleHoldPopup(index)}
              >
                <div
                  style={{
                    marginRight: "10px",
                    marginTop: "9px",
                    cursor: "pointer",
                    position: "relative",
                    right: "20px",
                    top: "43px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="23"
                    viewBox="0 0 22 23"
                    fill="none"
                  >
                    <path
                      d="M0 2.15C0 1.23873 0.73873 0.5 1.65 0.5H20.35C21.2613 0.5 22 1.23873 22 2.15V8.75C22 9.66127 21.2613 10.4 20.35 10.4H13.8722C12.961 10.4 12.2222 9.66127 12.2222 8.75V6.12222C12.2222 5.44721 11.675 4.9 11 4.9C10.325 4.9 9.77778 5.44721 9.77778 6.12222V8.75C9.77778 9.66127 9.03905 10.4 8.12778 10.4H1.65C0.738731 10.4 0 9.66127 0 8.75V2.15Z"
                      fill="#3968ED"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.7 6C7.7 4.17746 9.17746 2.7 11 2.7C12.8225 2.7 14.3 4.17746 14.3 6V10.8223C14.3 11.0652 14.4593 11.2794 14.692 11.3492L17.656 12.2384C18.9591 12.6293 19.7805 13.9144 19.5881 15.2613L18.8913 20.1389C18.6978 21.4937 17.5375 22.5 16.169 22.5H7.65572C6.58308 22.5 5.60833 21.8763 5.15883 20.9024L2.89269 15.9924C2.61299 15.3864 2.5573 14.6706 2.77536 14.0373C3.00529 13.3695 3.56588 12.7627 4.44251 12.6375C5.58104 12.4748 6.66039 12.848 7.7 13.5387V6ZM11 4.9C10.3925 4.9 9.9 5.39249 9.9 6V14.5722C9.9 16.1012 8.13048 16.6857 7.12045 15.8493C6.07831 14.9863 5.37071 14.7629 4.84452 14.8052C4.84197 14.8267 4.84084 14.8529 4.84281 14.8836C4.84684 14.9461 4.86293 15.0114 4.8902 15.0705L7.15635 19.9805C7.24625 20.1753 7.4412 20.3 7.65572 20.3H16.169C16.4427 20.3 16.6747 20.0987 16.7134 19.8278L17.4102 14.9502C17.4487 14.6808 17.2844 14.4238 17.0238 14.3456L14.0598 13.4564C12.8966 13.1074 12.1 12.0368 12.1 10.8223V6C12.1 5.39249 11.6075 4.9 11 4.9Z"
                      fill="#3968ED"
                    ></path>
                  </svg>
                </div>
              </button>
            ) : null}
          </div>

          <div
            className="flex gap create_booking_page_room_plan smsg_room_plan "
            style={{
              alignItems: "flex-end",
              padding: "10px ",
              flexWrap: "wrap",
            }}
          >
            <div className="create_booking_selectBox_container">
              <label
                className="Create_booking_label "
                style={{ fontSize: "13px" }}
              >
                Meal Plan
              </label>
              <select
                className="Create_booking_selectBox"
                onChange={handleRoomPlanChange}
              >
                <option>Select</option>
                {singleMessageData?.room_plans?.length > 0 &&
                  singleMessageData?.room_plans?.map((item, index) => {
                    let isSelected = false;

                    if (
                      datas.room_plan === "CP" &&
                      item.rate_plan_type === "CP"
                    ) {
                      isSelected = true;
                    }

                    if (
                      datas.room_plan === "MAP" &&
                      item.rate_plan_type === "MAP"
                    ) {
                      isSelected = true;
                    }

                    if (
                      datas.room_plan === "EP" &&
                      item.rate_plan_type === "EP"
                    ) {
                      isSelected = true;
                    }

                    return (
                      <option key={index} value={item.id} selected={isSelected}>
                        {item.rate_plan_name
                          .replace(/Complimentary/g, "")
                          .replace(/\+/g, "with ")}
                      </option>
                    );
                  })}
              </select>
            </div>
            {/* <CreateBookingSelectBox
                    disabled={
                      singleMessageData.check_in && singleMessageData.check_out
                        ? false
                        : true
                    }
                    label="Room Category"
                    value={singleMessageData.room_category}
                    onChange={handleRoomCategory}
                    options={RoomCategoryOptions}
                  /> */}

            {/* input for room per night   */}

            {/* <div className="create_booking_editable_input_container">
            <label className="Create_booking_label_small">Rate/Night</label>
            <div className="common_min_input d_flex gap_7">
              <input
                defaultValue={additionalBookingDetails?.rate_bar_price}
                disabled
              ></input>
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  setShowAddHocPrice(
                    (prevShowAddHocPrice) => !prevShowAddHocPrice
                  );
                  setShowDiscountSelect(
                    (prevShowAddHocPrice) => !prevShowAddHocPrice
                  );
                  setShowDiscount(!showDiscount);
                  if (showDiscountSelect === true) {
                    setAddHocRooms("");
                    setsingleMessageData((prevData) => {
                      return {
                        ...prevData,
                        ad_hoc_rate: "",
                      };
                    });
                  }
                  // if (!setShowDiscount){
                  //   setsingleMessageData((prevData) => {
                  //     return {
                  //       ...prevData,
                  //       discount_type: {
                  //         ...prevData.discount_type,
                  //         discount_amount: "",
                  //       }
                  //     };
                  //   })
                  // }else{
                  //   setsingleMessageData((prevData) => {
                  //     return {
                  //       ...prevData,
                  //       discount_type: {
                  //         ...prevData.discount_type,
                  //         discount_amount: "",
                  //       }
                  //     };
                  //   })
                  // }
                }}
                style={{ cursor: "pointer" }}
              >
                <g clip-path="url(#clip0_5205_24822)">
                  <path
                    d="M8.40292 1.02256L10.2788 2.97704L10.5917 2.65107C11.1096 2.11144 11.1096 1.23666 10.5917 0.697025C10.0736 0.157225 9.23346 0.157225 8.71536 0.697025L8.40292 1.02256Z"
                    fill="#888888"
                  />
                  <path
                    d="M9.41751 3.87236L7.54162 1.91788L1.58902 8.11881L0.980103 10.7077L3.46491 10.0733L9.41751 3.87236Z"
                    fill="#888888"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5205_24822">
                    <rect
                      width="10"
                      height="10.4167"
                      fill="white"
                      transform="translate(0.980072 0.291687)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div> */}

            {/* input for add hoc price per night   */}

            {!showDiscountSelect && (
              <>
                <div className="create_booking_selectBox_container">
                  <label className="Create_booking_label_small">
                    Ad hoc Rate
                  </label>
                  <input
                    style={{ width: "70px" }}
                    className="common_input"
                    name="add_hoc_rate"
                    onChange={handleAdoRatePerNightChange}
                  ></input>
                </div>
              </>
            )}
            {/* <CreateBookingEditableInput
            scale="large"
            value={singleMessageData.no_of_rooms}
            label="No. of Rooms"
            name="no_of_rooms"
            onChange={handleCommonInputsChange}
          /> */}

            <div>
              <label className="common_label fs-13 ">No. of Rooms</label>
              <div className="common_min_input flex">
                <input
                  name="no_of_rooms"
                  value={singleMessageData.no_of_rooms}
                  disabled
                ></input>
              </div>
            </div>
            <div>
              <label className="common_label fs-13 ">No. of Adults</label>
              <div className="common_min_input flex">
                <input
                  name="no_adult"
                  value={singleMessageData.no_adult}
                  disabled
                ></input>
              </div>
            </div>
            <div>
              <label className="common_label fs-13 ">Extra Adults</label>
              <div className="common_min_input flex">
                <input
                  name={"extra_adult"}
                  value={singleMessageData.extra_adult}
                  disabled
                ></input>
              </div>
            </div>
            <div>
              <label className="common_label fs-13 ">No. of Child</label>
              <div className="common_min_input flex">
                <input
                  name={"no_child"}
                  value={singleMessageData.no_child}
                  disabled
                ></input>
              </div>
            </div>

            {/* <CreateBookingEditableInput
            scale="large"
            value={singleMessageData.no_adult}
            label="No. of Adults"
            name="no_adult"
            onChange={handleNumberOfAdultsChange}
          /> */}
            {/* <CreateBookingEditableInput
            scale="large"
            value={singleMessageData.extra_adult}
            label="Extra Adults"
            disabled={singleMessageData.extra_adult}
            name={"extra_adult"}
            onChange={handleCommonExtraAdultChange}
          /> */}
            {/* <CreateBookingEditableInput
            scale="large"
            value={singleMessageData.no_child}
            label="No. of Child"
            name={"no_child"}
            onChange={handleChildInputChange}
          /> */}
            <div>
              {showDiscountSelect && (
                <>
                  <label className="common_label fs-13">
                    Disc. %{" "}
                    {/* {singleMessageData?.discount_type?.discount_type === "percent"
                  ? "%"
                  : ""} */}
                  </label>
                  <div className="common_min_input flex">
                    <input
                      disabled
                      value={
                        singleMessageData.discount_type
                      }
                    />
                  </div>
                </>
              )}
            </div>
            <div>
              <label className="common_label fs-13">Subtotal</label>
              <div className="common_min_input flex">
                <input value={singleMessageData?.subTotal || 0} disabled></input>
              </div>
            </div>
            <div className="create_booking_editable_input_container ">
              <label
                className="Create_booking_label"
                style={{ fontSize: "13px" }}
              >
                Tax Rate
              </label>
              <div className="common_min_input d_flex gap_7">
                <input
                  value={taxRate || singleMessageData.tax_rate}
                  disabled={true}
                ></input>
              </div>
            </div>

            <div>
              <label className="common_label fs-13">Grand Total</label>
              <div className="common_min_input flex">
                <input value={singleMessageData?.grandTotal || 0} disabled></input>
              </div>
            </div>
          </div>
        </div>

        <div
          className="create_booking_page_adult_details_row p-0  flex gap_7 align_center  new_createBooking_card_extra_adult_child_container_scroll"
          style={{
            paddingLeft: "13px",
            // paddingBottom: "10px",
            // marginBottom: "10px",
            borderTop: "1px solid #ddd",
          }}
        >
          {/* extra adult */}

          {extraAdultData?.map((item, index) => (
            <React.Fragment key={index}>



              <div
                className="flex gap_7 create_sendmessage_page_adult_details_box2"
                style={{
                  marginTop: "11px",

                }}
              >
                <div
                  className="adlut_label"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "4px",
                  }}
                >
                  Extra Adult
                </div>
                <div className="d_flex gap_7" style={{ alignItems: "center" }}>
                  <label className="Create_booking_label_small">Rate</label>
                  <div className="rate_input_small d_flex extra_adults_new_input">
                    <input
                      value={item.price}
                      type="number"
                      name="rate"
                      className="common_input extra_adult_new_input"
                      disabled={true}
                    ></input>

                    {/* <svg
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handleShowAddHocAdult(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <g clip-path="url(#clip0_5205_24822)">
                        <path
                          d="M8.40292 1.02256L10.2788 2.97704L10.5917 2.65107C11.1096 2.11144 11.1096 1.23666 10.5917 0.697025C10.0736 0.157225 9.23346 0.157225 8.71536 0.697025L8.40292 1.02256Z"
                          fill="#888888"
                        />
                        <path
                          d="M9.41751 3.87236L7.54162 1.91788L1.58902 8.11881L0.980103 10.7077L3.46491 10.0733L9.41751 3.87236Z"
                          fill="#888888"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5205_24822">
                          <rect
                            width="10"
                            height="10.4167"
                            fill="white"
                            transform="translate(0.980072 0.291687)"
                          />
                        </clipPath>
                      </defs>
                    </svg> */}
                  </div>
                </div>
                {/* {activeIndexAdults[index] && ( */}
                {/* <CreateBookingInputBox
                    alignment="horizontal"
                    label="Ad hoc Rate"
                    name={"ad_hoc_rate"}
                    value={item.adhoc_price}
                    onChange={(e) => handleExtraAdultChange(e, index)}
                  /> */}
                {/* )} */}
              </div>

            </React.Fragment>
          ))}

          {/* extra child */}
          {childData.map((item, index) => (
            <React.Fragment key={index}>
              <div className="create_sendmessage_page_adult_details_box2 b-none mt-10">
                <div
                  className={`sent-message-wrap${index !== childData.length - 1 ? " border-right" : ""
                    }`}
                >
                  <div
                    className="flex gap_7 adult_deatils_top"
                    style={{ gap: "7px" }}
                  >
                    <label className="adlut_label">Child {index + 1}</label>
                    <div className="gap_7 flex extra_adults_new_input">
                      <label className="Create_booking_label_small">Age</label>
                      <input
                        style={{ padding: "0px 4px" }}
                        onChange={(e) => handleChildAgeChange(e, index)}
                        value={item.age}
                        disabled
                        type="number"
                        name="age"
                        className="adlut_box_input"
                      ></input>
                    </div>
                    <div className="d_flex gap_7">
                      <label className="Create_booking_label_small">Rate</label>
                      <div className="rate_input_small d_flex extra_adults_new_input">
                        <input
                          value={item.price}
                          type="number"
                          name="rate"
                          className="adlut_box_input"
                          disabled
                        ></input>
                        {/* <svg
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleShowAddHocChild(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <g clip-path="url(#clip0_5205_24822)">
                          <path
                            d="M8.40292 1.02256L10.2788 2.97704L10.5917 2.65107C11.1096 2.11144 11.1096 1.23666 10.5917 0.697025C10.0736 0.157225 9.23346 0.157225 8.71536 0.697025L8.40292 1.02256Z"
                            fill="#888888"
                          />
                          <path
                            d="M9.41751 3.87236L7.54162 1.91788L1.58902 8.11881L0.980103 10.7077L3.46491 10.0733L9.41751 3.87236Z"
                            fill="#888888"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_5205_24822">
                            <rect
                              width="10"
                              height="10.4167"
                              fill="white"
                              transform="translate(0.980072 0.291687)"
                            />
                          </clipPath>
                        </defs>
                      </svg> */}
                      </div>
                    </div>

                    {/* {item.adhoc_price ? (
                      <div className="gap_7 flex extra_adults_new_input">
                        <label className="Create_booking_label_small">
                          Ad hoc. rate
                        </label>
                        <input
                          style={{ padding: "0px 4px" }}
                          // onChange={(e) => handleChildAgeChange(e, index)}
                          value={item.adhoc_price || ""}
                          disabled
                          type="number"
                          name={"ad_hoc_rate"}
                          className="adlut_box_input"
                        ></input>
                      </div>
                    ) : null} */}

                    {/* {activeIndexChild[index] && (
                    <div
                      className="create_reservation_vertical_inputs_container d_flex extra_adults_new_input"
                      style={{ display: "flex", gap: "5px", width: "130px" }}
                    >
                      <label className="Create_booking_label_small">
                        {" "}
                        Ad.hoc. Rate
                      </label>
                      <input
                        onChange={(e) => handleChildAdHocRate(e, index)}
                        value={item.ad_hoc_rate}
                        name={"ad_hoc_rate"}
                        type="number"
                      />
                    </div>
                  )} */}
                  </div>

                  {/* Radio buttons conditionally rendered based on child's age */}
                  {/* {!(item.age < 6) && item.age !== "" && (
                  <div className="flex gap_7 mt-10">
                    <label className="Create_booking_label_small flex gap_7">
                      <input
                        type="radio"
                        name="rate"
                        value={additionalBookingDetails.child_with_Bed}
                        checked={
                          childData[index].rate ===
                          additionalBookingDetails.child_with_Bed
                        }
                        onChange={(e) => handleChildRadioButtons(e, index)}
                      />
                      With Bed
                    </label>
                    <label className="Create_booking_label_small flex gap_7">
                      <input
                        type="radio"
                        name="rate"
                        value={additionalBookingDetails.child_without_Bed}
                        checked={
                          childData[index].rate ===
                          additionalBookingDetails.child_without_Bed
                        }
                        onChange={(e) => handleChildRadioButtons(e, index)}
                      />
                      Without Bed
                    </label>
                  </div>
                )} */}
                  {isRoomPlanSelected && !(item.age < 6) && item.age !== "" && (
                    <div className="flex gap_7">
                      <label className="Create_booking_label_small flex gap_7">
                        <input
                          type="radio"
                          name={`childBed_${index}`} // Unique name for each child's radio button group
                          value={additionalBookingDetails.child_with_Bed}
                          onChange={(e) => handleChildRadioButtons(e, index)}
                          checked={
                            +additionalBookingDetails.child_with_Bed ===
                            +item.rate
                          }
                        />
                        With Bed
                      </label>
                      <label className="Create_booking_label_small flex gap_7">
                        <input
                          type="radio"
                          name={`childBed_${index}`} // Unique name for each child's radio button group
                          value={additionalBookingDetails.child_without_Bed}
                          onChange={(e) => handleChildRadioButtons(e, index)}
                          checked={
                            +additionalBookingDetails.child_without_Bed ===
                            +item.rate
                          }
                        />
                        Without Bed
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default SentMessageCard;
