import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { calculateTotalWithReduce, formatIndianCurrency } from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";

const ReportIndividualVendor = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkupAllFoliosCustom = departureReport?.map(
    ({ id, count, order_no, order_type, net_total, date, index }) => {
      return (
        <CustomIndexTableRow
          id={id}
          key={id}
          // selectedItems={selectedArray} // send for checking the check box is checked
          // setSelectedItems={setSelectedArray}
          showCheckbox={false} // show the check box
        >
          <CustomIndexTableCell>{id}</CustomIndexTableCell>

          <CustomIndexTableCell>{count}</CustomIndexTableCell>
          <CustomIndexTableCell>
            {formatIndianCurrency(id)}
          </CustomIndexTableCell>
        </CustomIndexTableRow>
      );
    }
  );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>{calculateTotalWithReduce(departureReport, "item_qty")}</CustomReportTableCell>
      <CustomReportTableCell>{formatIndianCurrency(calculateTotalWithReduce(departureReport, "item_price"))}</CustomReportTableCell>
      <CustomReportTableCell>{formatIndianCurrency(calculateTotalWithReduce(departureReport, "total_amount"))}</CustomReportTableCell>
    </CustomReportTableRow>
  );

  
  const calculateTotal = (items, key) => {
    return 0;
  };
  const handleDownload = () => {
    const html2pdf = require("html2pdf.js");
    const originalTable = document.getElementById("receipt");
    const pdf = document.getElementById("pdf");
    const clonedTable = originalTable.cloneNode(true);

    clonedTable.style.fontSize = "12px";
    clonedTable.style.fontFamily = "Arial, sans-serif";
    clonedTable.style.lineHeight = "1.5";
    clonedTable.style.margin = "auto";
    pdf.style.display = "none";

    const tempContainer = document.createElement("div");
    tempContainer.appendChild(clonedTable);

    html2pdf()
      .from(tempContainer)
      .set({
        margin: [0.5, 0.5, 0.5, 0.5], // Adjust margin as needed
        filename: "receipt.pdf",
        image: { type: "jpeg", quality: 5 }, // Use higher quality for images
        html2canvas: { scale: 1 }, // Increase scale for higher resolution
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" }, // Adjust PDF format and orientation as needed
      })
      .save();
  };

  const downloadCSV = () => {
    // Prepare CSV content
    const csvHeaders = "Category,Item,Count,Sale";
    const csvContent = `${csvHeaders}\n${departureReport
      .map((category) =>
        category.items
          .map((item) =>
            [category.category, item.item, item.count, item.sale].join(",")
          )
          .join("\n")
      )
      .join("\n")}`;

    // Create a blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element to trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // console.log(departureReport);

  const rowMarkup = departureReport?.map(
    ({
      id,
      Bill_Date,
      Bill_No,
      Folio_No,
      Guest_Name,
      Meal_Plan,
      Room_Rent_Taxable_Amt,
      Room_Rent_CGST,
      Room_Rent_SGST,
      Room_GST_Rate,

      Food_Bill_Amt,
      Food_GST,
      Food_CGST,
      Food_SGST,

      count,
      item,
      sale,
      net_total,
      date,
      index,
      purchase_item_date,
      invoice_no,
      item_name,
      unit,
      item_category,
      unique_purchase_id,
      quantity,
      price_unit,
      purchase_amount,
      unit_name,
      item_qty,
      item_price,
      total_amount,
    }) => (
      <CustomReportTableRow
        CustomReportTableRow
        id={id}
        key={id}
        // bodyStyles={{
        //   backgroundColor: "red",
        //   fontSize: "10px",
        // }}
      >
        <CustomReportTableCell>
          {
            <TextDateFormatter
              date={purchase_item_date}
              as={"div"}
              showYear={true}
            />
          }
        </CustomReportTableCell>
        <CustomReportTableCell>{invoice_no}</CustomReportTableCell>
        <CustomReportTableCell>{item_name}</CustomReportTableCell>
        <CustomReportTableCell>{unit_name}</CustomReportTableCell>

        <CustomReportTableCell>{item_category}</CustomReportTableCell>
        <CustomReportTableCell>#{unique_purchase_id}</CustomReportTableCell>
        <CustomReportTableCell>{item_qty}</CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(item_price)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(total_amount)}
        </CustomReportTableCell>
      </CustomReportTableRow>
    )
  );

const extractedData = departureReport?.map(item => ({
  purchase_item_date:item &&  item?.purchase_item_date ? item?.purchase_item_date : 'N/A',
  invoice_no:item && item?.invoice_no ? item?.invoice_no : 'N/A',
  item_name:item && item?.item_name ?item?.item_name : 'N/A',
  unit_name: item && item?.unit_name ? item?.unit_name  : 'N/A',
  item_category:item && item?.item_category ? item?.item_category : 'N/A',
  unique_purchase_id:item && item?.unique_purchase_id ? item?.unique_purchase_id : 'N/A',
  item_qty:item && item?.paid_amount ? item?.paid_amount : 'N/A',
  item_price:item && item?.item_price ? item?.item_price : 'N/A',
  total_amount:item && item?.total_amount ? item?.total_amount : 'N/A',
}));

const names = [
  {
    purchase_item_date: "Date of Purchase",
    invoice_no: "Invoice Number",
    item_name: "Item Name	",
    unit_name: "Unit",
    item_category: "Item Category	",
    unique_purchase_id: "Purchase ID	",
    item_qty: "Quantity",
    item_price: "Price/Unit	",
    total_amount :"Purchase Amount",
  },
];

// console.log(extractedData);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div id="receipt" className="room-types-full-container reports-new-background">
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="room-type-heading">
                Individual Vendor Finance Report{" "}
              </div>
              {departureReport.length > 0 && (
                <div className="flex flex_gap_15 align_items_center" id="pdf">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Individual Vendor Finance Report"}
                    ignoreFromPrint={"pdf"}
                    // wrapper={'singh'}
                    // pdfStyles={

                    // }
                  />
                  {/* <DownloadCsvTables tableData={extractedData} downloadCSV={downloadCSV} 
                   names={names}/> */}

                   <DownloadCSVButton itemToDownloadID={"receipt"}
                    downloadFileName={"Individual_Vendor_Finance_Report"}/>
                </div>
              )}
            </div>
          </div>
          <div >
            {departureReport && departureReport.length > 0 ? (
              <>
                {/* {departureReport &&
                  departureReport.length > 0 &&
                  departureReport.map((order) => {
                    return (
                      <div className="mt-20">
                        <div
                          className="category_name"
                          style={{ marginBottom: "10px" }}
                        >
                          {order.category}
                        </div> */}

                <CustomReportTable
                  headings={[
                    "Date Of Purchase",
                    "Invoice Number",
                    "Item Name",
                    "Unit",
                    "Item Category",
                    "Purchase ID",
                    "Quantity",
                    "Price/Unit",
                    "Purchase Amount",
                  ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    // textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ReportIndividualVendor;
