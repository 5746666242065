import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./SingleRoomGallery.css";

const SingleRoomGallery = () => {
    const { roomId, propertyId, roomName } = useParams();
    const userToken = JSON.parse(localStorage.getItem("userToken"));

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const fetchInfo = async () => {
        try {
            setLoader(true);

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/booking-engine/get-room-image/${roomId}/${propertyId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            const data = await response.json();
            if (data.success) {
                setData(data?.data);
            } else {
                console.error("An error occurred:", error);
            }
        } catch (error) {
            console.error("An error occurred:", error);
            setError(error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchInfo();
    }, [roomId, propertyId]);

    if (loader) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error loading images: {error.message}</p>;
    }

    const handleNext = () => {
        setPhotoIndex((photoIndex + 1) % data.length);
    };

    const handlePrev = () => {
        setPhotoIndex((photoIndex + data.length - 1) % data.length);
    };

    return (
        <>
            <section className="mt-20 mb-20">
                <div className="container">
                    <h1 style={{ fontSize: '30px',marginBottom: '20px'  }}>{roomName && roomName}</h1>


                    {data && data.length > 0 ?
                        <>
                            <p style={{ fontSize: '18px', marginTop: '20px' }}>Gallery</p>

                            <div className="single_hotel_room_image">
                                {data.map((item, index) => (
                                    <div
                                        key={index}
                                        className="single_hotel_room_image_child"
                                        onClick={() => {
                                            setPhotoIndex(index);
                                            setIsOpen(true);
                                        }}
                                    >
                                        <img src={item} height="100%" width="100%" alt="websiteimg" />
                                    </div>
                                ))}
                            </div>
                        </>
                        : <p>No Images found </p>
                    }
                </div>
            </section>
            {isOpen && (
                <div className="lightbox">
                    <span className="close" onClick={() => setIsOpen(false)}>
                        &times;
                    </span>
                    <img className="lightbox-content" src={data[photoIndex]} alt="large view" />
                    <div className="controls">
                        <button className="prev" onClick={handlePrev}>
                            &#10094;
                        </button>
                        <button className="next" onClick={handleNext}>
                            &#10095;
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default SingleRoomGallery;



