import NoDataFound from "../CustomComponents/NoDataFound";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";

import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";

const ReportArrivals = ({ departureReport, loader, propertyName }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkup = departureReport?.map((order) => (
    <CustomReportTableRow
      id={order.id}
      key={order.id}
      // selected={selectedResources.includes(order.id)}
      position={order.index}
    >
      <CustomReportTableCell>{order.unique_booking_id}</CustomReportTableCell>
      <CustomReportTableCell>
        {order.guest && order.guest.length > 0 && order.guest[0].guest_name}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {" "}
        <TextDateFormatter as={"span"} showYear={true} date={order.check_in} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        <TextDateFormatter as={"span"} showYear={true} date={order.check_out} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order.guest &&
          order.guest.length > 0 &&
          order?.items.map((item) => <div>{item.room_type_name}</div>)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order.guest &&
          order.guest.length > 0 &&
          order?.items.map((item) => <div>{item.no_nights}</div>)}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order.guest &&
          order.guest.length > 0 &&
          order?.items.map((item) => <div>{item.no_adult}</div>)}
      </CustomReportTableCell>
      <CustomReportTableCell>₹{order.balance_amount}</CustomReportTableCell>
      <CustomReportTableCell>
        <StatusUpdate status={order.status} /> {}
      </CustomReportTableCell>
    </CustomReportTableRow>
  ));

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {calculateTotalWithReduce(departureReport, "MTD_quantity")}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {calculateTotalWithReduce(departureReport, "YTD_quantity")}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {calculateTotalWithReduce(departureReport, "YTD_quantity")}
      </CustomReportTableCell>

      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "MTD_purchase_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "QTD_purchase_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "YTD_purchase_amount")
        )}
      </CustomReportTableCell>

      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "MTD_average_purchase_cost")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "QTD_average_purchase_cost")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "YTD_average_purchase_cost")
        )}
      </CustomReportTableCell>
    </CustomReportTableRow>
  );
  const extractedData = departureReport?.map((item) => ({
    item_name: item?.item_name,
    supplier_name: item?.supplier_name,
    supplier_gst_no: item?.supplier_gst_no,
    unit_name: item?.unit_name,
    MTD_quantity: item?.MTD_quantity,
    QTD_quantity: item?.QTD_quantity,
    YTD_quantity: item?.YTD_quantity,
    MTD_purchase_amount: item?.MTD_purchase_amount,
    QTD_purchase_amount: item?.QTD_purchase_amount,
    YTD_purchase_amount: item?.YTD_purchase_amount,
    MTD_average_purchase_cost: item?.MTD_average_purchase_cost,
    QTD_average_purchase_cost: item?.QTD_average_purchase_cost,
    YTD_average_purchase_cost: item?.YTD_average_purchase_cost,
  }));

  const names = [
    {
      item_name: "Item Name	",
      supplier_name: "Supplier Name",
      supplier_gst_no: "GST No",
      unit_name: "Unit",
      MTD_quantity: "MTD	Quantity",
      QTD_quantity: " QTD	Quantity",
      YTD_quantity: "YTD Quantity",
      MTD_purchase_amount: "MTD Purchase Amount",
      QTD_purchase_amount: "QTD Purchase Amount",
      YTD_purchase_amount: "YTD Purchase Amount",
      MTD_average_purchase_cost: "MTD Average Purchase Cost",
      QTD_average_purchase_cost: "QTD Average Purchase Cost",
      YTD_average_purchase_cost: "YTD Average Purchase Cost",
    },
  ];
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div
          id="receipt"
          className="room-types-full-container reports-new-background"
        >
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="room-type-heading">Detailed Arrival Report</div>
              {departureReport.length > 0 && (
                <div className="flex flex_gap_15 align_center" id="pdf">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={`detail_arrival_report`}
                    ignoreFromPrint={"pdf"}
                    pdfOrientation={"landscape"}
                  />
                  {/* <DownloadPdfTables  tableData={extractedData} tableNames={names} downloadFileName={`detail_arrival_report`}/> */}
                  <DownloadCSVButton
                    reportName={"detail_arrival_report"}
                    itemToDownloadID={"receipt"}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            {departureReport && departureReport.length > 0 ? (
              <>
                <CustomReportTable
                  headings={[
                    "Booking ID",
                    "Guest Name",
                    "Check In Date",
                    "Check Out Date",
                    "Room Type",
                    "No Of Nights",
                    "	No Of Guests",
                    "Balance",
                    "Status",
                  ]}
                  // commonHeadings={[
                  //   "",
                  //   "",
                  //   "",
                  //   "",
                  //   "Quantity",
                  //   "Quantity",
                  //   "Quantity",
                  //   "Total Purchase Amount  (₹)",
                  //   "Total Purchase Amount  (₹)",
                  //   "Total Purchase Amount  (₹)",
                  //   "Average Purchase Cost per unit (₹)",
                  //   "Average Purchase Cost per unit (₹)",
                  //   "Average Purchase Cost per unit (₹)",
                  // ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {/* {calculateTotals} */}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ReportArrivals;
