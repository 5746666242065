import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import React from "react";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
const ReportCancelTable = ({ departureReport }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const extractedData = departureReport?.map((item) => ({
    unique_booking_id: item?.unique_booking_id,
    guest_name: item?.guest[0]?.guest_name,
    updated_at: item?.updated_at,
    check_in: item?.check_in,
    check_out: item?.check_out,
    room_type_name: item?.items[0]?.room_type_name,
    no_nights: item?.items[0]?.no_nights,
    status: item?.status?.replace(/_/g, " "),
  }));

  const names = [
    {
      unique_booking_id: "Booking ID",
      guest_name: "Name",
      updated_at: "Cancel Date",
      check_in: "Arrival Date",
      check_out: "Departure Date",
      room_type_name: "Room category",
      no_nights: "No. Of Nights",
      status: "Status",
    },
  ];
  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>{order.unique_booking_id}</CustomReportTableCell>
        <CustomReportTableCell>
          {order?.guest[0]?.guest_name}
        </CustomReportTableCell>
        <CustomReportTableCell>
          <TextDateFormatter
            as={"span"}
            showYear={true}
            date={order.check_in}
          />
        </CustomReportTableCell>
        <CustomReportTableCell>
          <TextDateFormatter
            as={"span"}
            showYear={true}
            date={order?.updated_at?.slice(0, 10)}
          />
        </CustomReportTableCell>
        <CustomReportTableCell>
          <TextDateFormatter
            as={"span"}
            showYear={true}
            date={order.check_out}
          />
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order?.items.map((item,index) => (
            <React.Fragment key={index}>{item.room_type_name} {index < order?.items.length - 1 && ", "}</React.Fragment>
          ))}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order?.items[0]?.no_nights}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order?.balance_amount}
        </CustomReportTableCell>
        

        <CustomReportTableCell>
          <StatusUpdate status={order.status} /> {}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));

  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div" id="receipt">
          <div className="room-type-heading">Cancellation Report</div>
          {departureReport?.length > 0 && (
            <div className="flex flex_gap_15">
              <DownloadPdfTables tableData={extractedData} tableNames={names} downloadFileName={"cancellation_report"}  />
              {/* <DownloadCsvTables tableData={extractedData} names={names} />
               */}
               <DownloadCSVButton  downloadFileName={"cancellation_report"} itemToDownloadID={"receipt"} />
            </div>
          )}
        </div>

        {departureReport && departureReport.length > 0 ? (
          <>
            {/* <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={departureReport.length}
                selectable={false}
                headings={[
                  { title: "Booking ID" },
                  { title: "Guest Name" },
                  { title: "Cancel Date" },
                  { title: "Arrival Date" },
                  { title: "Departure Date" },
                  { title: "Room Type" },
                  { title: "No Of Nights" },
                  { title: "Status" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard> */}

            <CustomReportTable
              headings={[
                "Booking ID",
                "Guest Name",
                "Check In Date",
                "Check Out Date",
                "Room Type",
                "No Of Nights",
                "	No Of Guests",
                "Balance",
                "Status",
              ]}
              // commonHeadings={[
              //   "",
              //   "",
              //   "",
              //   "",
              //   "Quantity",
              //   "Quantity",
              //   "Quantity",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              // ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "center",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {/* {calculateTotals} */}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};
export default ReportCancelTable;
