import { useState, useRef, useEffect } from "react";
import "./customCountryStateSelector.css";

const CustomCountryStateSelector = ({
  defaultCountry,
  defaultState,
  getCountryState,
  style
}) => {
  

  const [searchValue, setSearchValue] = useState({
    country: "",
    state: "",
  });
  const [option, setOptions] = useState({
    countryList: [],
    stateList: [],
  });

  const [showDropDown, setShowDropDown] = useState({
    countryDropDown: false,
    stateDropDown: false,
  });

  const [filterList, setFilterList] = useState({
    country: [],
    state: [],
  });

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/country/list`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const responseData = await response.json();
        setOptions({
          countryList: responseData.data,
          stateList: [],
        });
        setFilterList({
          country: responseData.data,
          state: "",
        });
        if (defaultCountry || defaultState) {
          setSearchValue({
            country: defaultCountry,
            state: defaultState,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCountries();
  }, []);

  const customCountryInputContainerParentRef = useRef(null);
  const customStateInputContainerParentRef = useRef(null);
  const countryInputRef = useRef(null);
  const stateInputRef = useRef(null);

  const handleCountryInputFocus = () => {
    const parentContainer = customCountryInputContainerParentRef.current;
    parentContainer?.classList?.add("custom-search-input-container-focused");
    setShowDropDown((prev) => ({
      ...prev,
      countryDropDown: true,
    }));
    setFilterList({
      country: option.countryList,
      state: option.stateList,
    });
  };

  const handleStateInputFocus = () => {
    const parentContainer = customStateInputContainerParentRef.current;
    parentContainer?.classList?.add("custom-search-input-container-focused");
    setShowDropDown((prev) => ({
      ...prev,
      stateDropDown: true,
    }));
    setFilterList((prev) => ({
      ...prev,
      state: option.stateList,
    }));
  };

  const handleInputBlur = () => {
    customCountryInputContainerParentRef?.current?.classList?.remove(
      "custom-search-input-container-focused"
    );
    customStateInputContainerParentRef?.current?.classList?.remove(
      "custom-search-input-container-focused"
    );
  };

  const handleStateInputBlur = () => {
    customCountryInputContainerParentRef?.current?.classList?.remove(
      "custom-search-input-container-focused"
    );
    customStateInputContainerParentRef?.current?.classList?.remove(
      "custom-search-input-container-focused"
    );
  };
  useEffect(() => {
    const handleInputBlurUse = () => {
      setTimeout(() => {
        if (
          document.activeElement !== countryInputRef?.current &&
          document.activeElement !== stateInputRef?.current
        ) {
          customCountryInputContainerParentRef?.current?.classList?.remove(
            "custom-search-input-container-focused"
          );
          customStateInputContainerParentRef?.current?.classList?.remove(
            "custom-search-input-container-focused"
          );

          if (
            !searchValue.country ||
            !option.countryList.some(
              (country) =>
                country.name.toLowerCase().replaceAll(" ", "") ===
                searchValue.country.toLowerCase().replaceAll(" ", "")
            )
          ) {
            setSearchValue((prev) => ({
              ...prev,
              country: "",
            }));
          }

          if (
            !searchValue.state ||
            !option.stateList.some(
              (state) =>
                state.name.toLowerCase().replaceAll(" ", "") ===
                searchValue.state.toLowerCase().replaceAll(" ", "")
            )
          ) {
            setSearchValue((prev) => ({
              ...prev,
              state: "",
            }));
          }
        }
      }, 0);
    };

    document.addEventListener("mousedown", handleInputBlurUse);

    return () => {
      document.removeEventListener("mousedown", handleInputBlurUse);
    };
  }, [
    searchValue.country,
    searchValue.state,
    option.countryList,
    option.stateList,
  ]);

  const handleCountryInputChange = (event) => {
     
    



    setShowDropDown({
      countryDropDown: true,
      stateDropDown: false,
    });
    const { value } = event.target;
    setFilterList((prev) => ({
      ...prev,
      country: option?.countryList?.filter((country) =>
        country?.name?.toLowerCase()?.includes(value?.toLowerCase())
      ),
    }));

    setSearchValue((prev) => ({
      country: value,
      state: prev.state,
    }));

    setOptions((prev) => ({
      ...prev,
      stateList: [],
    }));
  };

  const handleStateInputChange = (e) => {
    const { value } = e.target;
    setShowDropDown({
      countryDropDown: false,
      stateDropDown: true,
    });
    setFilterList((prev) => ({
      ...prev,
      state: option?.stateList?.filter((state) =>
        state?.name?.toLowerCase()?.includes(value?.toLowerCase())
      ),
    }));

    setSearchValue((prev) => ({
      country: prev.country,
      state: value,
    }));
  };

  const fetchStates = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/country/states/${id}?countryName=${searchValue.country}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch states data");
      }
      const data = await response.json();

      setOptions((prev) => ({
        ...prev,
        stateList: data.data,
      }));
      setShowDropDown({
        countryDropDown: false,
        stateDropDown: false,
      });
      setFilterList((prev) => ({
        ...prev,
        state: data.data,
      }));
    } catch (error) {
      console.error("Error fetching states data:", error);
    }
  };

  const handleSingleCountryClick = async (e, item) => {
    e.stopPropagation();
    if (!item) {
      return;
    }
    fetchStates(item.id);
    setSearchValue({ country: item.name, state: "" });
    setFilterList((prev) => ({
      countryList: option.countryList,
      stateList: "",
    }));
    setShowDropDown({ countryDropDown: false, stateDropDown: false });
  };
  useEffect(() => {
    getCountryState(searchValue);
  }, [searchValue.country, searchValue.state]);

  useEffect(() => {
    if (defaultCountry && option.countryList.length > 0) {
      const countryID = option.countryList.find(
        (item) =>
          item?.name?.toLowerCase()?.replaceAll(" ", "") ===
          defaultCountry?.toLowerCase()?.replaceAll(" ", "")
      )?.id;

      if (countryID) {
        fetchStates(countryID);
        setSearchValue({
          country: defaultCountry,
          state: defaultState ? defaultState : "",
        });
      }
    }
  }, [defaultCountry, option.countryList]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        customCountryInputContainerParentRef?.current &&
        !customCountryInputContainerParentRef?.current?.contains(e.target) &&
        customStateInputContainerParentRef?.current &&
        !customStateInputContainerParentRef?.current?.contains(e.target)
      ) {
        setShowDropDown({
          countryDropDown: false,
          stateDropDown: false,
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [focusedIndex, setFocusedIndex] = useState({ country: -1, state: -1 });

  const handleKeyDown = (e, type) => {
    const list = filterList[type];
    if (list.length === 0) return;

    if (e.key === "ArrowDown") {
      setFocusedIndex((prev) => {
        const newIndex = (prev[type] + 1) % list.length;
        return { ...prev, [type]: newIndex };
      });
    } else if (e.key === "ArrowUp") {
      setFocusedIndex((prev) => {
        const newIndex =
          (prev[type] - 1 + list.length) % list.length;
        return { ...prev, [type]: newIndex };
      });
    } else if (e.key === "Enter") {
      const selectedItem = list[focusedIndex[type]];
      if (type === "country") {
        handleSingleCountryClick(e, selectedItem);
      } else if (type === "state") {
        setSearchValue({ ...searchValue, state: selectedItem.name });
        setShowDropDown({ countryDropDown: false, stateDropDown: false });
      }
    }

    if (type === "country" && e.key === "Backspace") {
     
                setSearchValue((prev) => ({ ...prev, country: "" }));
                setFilterList((prev) => ({
                  ...prev,
                  country: option.countryList,
                }));
          
    }
    if(type === "state" && e.key === "Backspace"){
      setSearchValue((prev) => ({ ...prev, state: "" }));
      setFilterList((prev) => ({
        ...prev,
        state: option.stateList,
      }));
    }
  };

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{color: "red"}}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };


  return (
    <div className="flex flex_gap_10" style={style}>
    <div className="form-container-div">
      <div>Country</div>
      <div
        className="customSearchInputContainerParentRef"
        style={{ position: "relative" }}
        ref={customCountryInputContainerParentRef}
      >
        <div className="customSearchInputContainer">
          <input
            ref={countryInputRef}
            value={searchValue.country}
            onChange={handleCountryInputChange}
            placeholder="Search Country ..."
            onBlur={handleInputBlur}
            onFocus={handleCountryInputFocus}
            className="customSearchInputCountryState"
            onKeyDown={(e) => handleKeyDown(e, "country")}
            
          />
        </div>
        {showDropDown.countryDropDown && (
          <>
            {option.countryList.length > 0 && (
              <ul className="overflowContainer customSearchSuggestion">
                {filterList.country.map((item, index) => (
                  <li
                    key={index}
                    className={`customSearchSuggestionList ${
                      index === focusedIndex.country ? "customSearchSuggestionListFocused" : ""
                    }`}
                  >
                    <button
                      onClick={(e) => handleSingleCountryClick(e, item)}
                      className="searchSuggestionButton"
                      ref={(el) => {
                        if (index === focusedIndex.country && el) {
                          el.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                          });
                        }
                      }}
                    >
                      {/* {item.name} */}

                      {getHighlightedText(item.name, searchValue.country)}
                    </button>
                  </li>
                ))}
                {filterList.country.length === 0 && (
                  <li className="customSearchSuggestionList customSearchSuggestionListNoDataFound">
                    No data found
                  </li>
                )}
              </ul>
            )}
          </>
        )}
      </div>
    </div>

    {/* State Input */}
    <div className="form-container-div">
      <div>State</div>
      <div
        className="customSearchInputContainerParentRef"
        style={{ position: "relative" }}
        ref={customStateInputContainerParentRef}
      >
        <div className="customSearchInputContainer ">
          <input
            disabled={option.stateList.length === 0 ? true : false}
            ref={stateInputRef}
            value={searchValue.state}
            onChange={handleStateInputChange}
            placeholder="Search State ..."
            onBlur={handleStateInputBlur}
            onFocus={handleStateInputFocus}
            className="customSearchInputCountryState"
            onKeyDown={(e) => handleKeyDown(e, "state")}
           
          />
        </div>
        {showDropDown.stateDropDown && (
          <>
            {option.stateList.length > 0 && (
              <ul className="overflowContainer customSearchSuggestion">
                {filterList.state.map((item, index) => (
                  <li
                    key={index}
                    className={`customSearchSuggestionList ${
                      index === focusedIndex.state ? "customSearchSuggestionListFocused" : ""
                    }`}
                  >
                    <button
                      onClick={(e) => {
                        setSearchValue({
                          ...searchValue,
                          state: item.name,
                        });
                        setShowDropDown({
                          countryDropDown: false,
                          stateDropDown: false,
                        });
                        e.stopPropagation();
                      }}
                      className="searchSuggestionButton"
                      ref={(el) => {
                        if (index === focusedIndex.state && el) {
                          el.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                          });
                        }
                      }}
                    >
                      {/* {item.name} */}
                      {getHighlightedText(item.name, searchValue.state)}
                    </button>
                  </li>
                ))}
                {filterList.state.length === 0 && (
                  <li className="customSearchSuggestionList customSearchSuggestionListNoDataFound">
                    No data found
                  </li>
                )}
              </ul>
            )}
          </>
        )}
      </div>
    </div>
  </div>
  );
};

export default CustomCountryStateSelector;
