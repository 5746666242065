
import React, { useEffect, useState } from "react";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import CustomPagination from "../CustomComponents/CustomPagination/CustomPagination";
import CustomPaginationArrow from "../CustomComponents/CustomPagination/CustomPaginationArrows";
import { Button } from "@shopify/polaris";

import { Link, useNavigate } from "react-router-dom";
import LoaderSmall from "../UI/Loader/LoaderSmall";

const AdOnsSetting = () => {
  const property_id = useSelector((state) => state.property.value);
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const styles = {
    parent: {
      position: "relative",
      width: "60%",
      margin: "20px auto",
    },
    child: {
      position: "absolute",
      right: "20px",
      top: "20px",
      display: "flex",
      gap: "10px",
    },
    button: {
      border: "none",
      backgroundColor: "transparent",
      cursor: "pointer",
      color: "#2B65EC",
      fontSize: "14px",
      fontWeight: "600",
    },
    addDistancePopupContainer: {
      position: "fixed",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: "999",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    addDistancePopupCard: {
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "10px",
      width: "500px",
    },
  };

  // -------------fetch add on if any-----------------------------
  const [addOnsData, setAddOnsData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const fetchAddonsList = async () => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/property/addon/${property_id.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      setAddOnsData(data.propertyAddonItem.data);
      setTotalItems(data.propertyAddonItem.total);
      setItemsPerPage(data.propertyAddonItem.per_page);
      setLoader(false);
      setTotalPages(data.propertyAddonItem.last_page);

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };
  useEffect(() => {
    if (property_id) {
      fetchAddonsList();
    }
  }, [property_id]);

  //-----------------------------------------------------

  //-----------------------------------add popup ----------------------------
  const [showAddOnPopup, setAddOnPopup] = useState(false);
  const [addOnNamePrice, setAddOnNamePrice] = useState({
    add_on: "",
    price: "",
    tax: "",
    hsncode: "",
  });
  const handleAddonDataChange = (e) => {
    setAddOnNamePrice({
      ...addOnNamePrice,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddButtonClickInPopup = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create/property/addon/${property_id.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(addOnNamePrice),
        }
      );
      const data = await response.json();
      if (response.ok) {
        showSuccessToast(data.message);
        setAddOnPopup(false);
        setAddOnNamePrice({
          add_on: "",
          price: "",
          tax: "",
          hsncode: "",
        });
        fetchAddonsList();
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch salutations:", data.message);
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error fetching salutations:", error);
    }
  };
  const handleAddAddonCancel = () => {
    setAddOnPopup(false);
    setAddOnNamePrice({
      add_on: "",
      price: "",
      tax: "",
      hsncode: "",
    });
  };
  //------------------------------------------------------------

  //-----------------------------delete add on ----------------------------
  const [deletePopup, setDeletePopup] = useState(false);
  const [deletedIds, setDeletedIds] = useState("");
  const handleAddonDelete = async (id) => {
    if (deletePopup && deletedIds) {
      const obj = {
        ids: [deletedIds],
      };
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/delete/property/addon/${property_id.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            method: "DELETE",
            body: JSON.stringify(obj),
          }
        );
        const data = await response.json();
        if (response.ok) {
          showSuccessToast(data.message);
          fetchAddonsList();
          setDeletedIds("");
          setDeletePopup(false);
        } else if (data.message === "Unauthorised.") {
          navigate("/");
        } else {
          console.error("Failed to fetch salutations:", data.message);
          showErrorToast(data.message);
        }
      } catch (error) {
        console.error("Error fetching salutations:", error);
      }
    } else {
      setDeletedIds(id);
      setDeletePopup(true);
    }
  };
  //--------------------------------------------------------------------

  //---------------------------------edit popup ------------------------------
  const [editPopup, setEditPopup] = useState(false);
  const [editPopupData, setEditPopupData] = useState({});

  const handleEditClick = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/property/addon/${id}/${property_id.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setEditPopupData(data.data);
        setEditPopup(true);
      } else if (data.message === "Unauthorised.") {
      } else {
        console.error("Failed to fetch salutations:", data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditAddonDataChange = (e) => {
    setEditPopupData({
      ...editPopupData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateButtonClickInPopup = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/update/property/addon/${editPopupData.id}/${property_id.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(editPopupData),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setEditPopup(false);
        fetchAddonsList();
      } else if (data.message === "Unauthorised.") {
      } else {
        console.error("Failed to fetch salutations:", data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleAddAddonEditCancel = () => {
    setEditPopup(false);
    setEditPopupData({});
  };
  //----------------------------------------------------------------------------

  const taxOptions = [
    {
      label: "select",
      value: "",
    },
    {
      label: " 5",
      value: "5",
    },
    {
      label: "12",
      value: "12",
    },
    {
      label: "18",
      value: "18",
    },
  ];
  ///------------------------- pagination-----------------------

  // const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Handle page change

  return (
    <div>
      <div>
        <div className="profile_tab_form " style={styles.parent}>
          <h3>Add-Ons</h3>
          <div style={styles.child}>
            <button onClick={() => setAddOnPopup(true)} style={styles.button}>
              Add
            </button>
          </div>

          <div className="form-container-div">
            {/* ----------------------show add ons and edit and delete --------------------- */}
            {loader ? (
              <LoaderSmall />
            ) : (
              <>
                {addOnsData && addOnsData.length > 0 ? (
                  <>
                    {addOnsData?.map((addOn, index) => (
                      <div
                        className="flex justify_content_between"
                        key={index}
                        style={{
                          borderBottom:
                            index === addOnsData.length - 1
                              ? "none"
                              : "1px solid #ccc",
                          padding: "10px 0",
                        }}
                      >
                        <p> {addOn.add_on}</p>
                        <div className="flex flex_gap_10">
                          <span
                            style={styles.button}
                            onClick={() => handleEditClick(addOn.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M11.3919 1.98174L14.0182 4.60884L14.4562 4.1707C15.1813 3.44536 15.1813 2.26952 14.4562 1.54418C13.7308 0.818608 12.5547 0.818608 11.8294 1.54418L11.3919 1.98174Z"
                                fill="#3968ED"
                              ></path>
                              <path
                                d="M12.8124 5.81229L10.1861 3.18518L1.85249 11.5201L1 15L4.47873 14.1472L12.8124 5.81229Z"
                                fill="#3968ED"
                              ></path>
                            </svg>
                          </span>
                          <span
                            onClick={() => handleAddonDelete(addOn.id)}
                            style={styles.button}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                                fill="#E03838"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                                fill="#E03838"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <NoDataFoundSmall />
                )}
              </>
            )}
          </div>
        </div>
        {/* ---------------------------------- --------------------- */}

        {/*------------------ Add On Popup---------------------- */}

        {showAddOnPopup && (
          <>
            <div style={styles.addDistancePopupContainer}>
              <div style={styles.addDistancePopupCard}>
                <div className="flex justify_content_between flex_gap_10 ">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      titleName={"Add-On"}
                      type={"text"}
                      name={"add_on"}
                      value={addOnNamePrice.name}
                      onChange={handleAddonDataChange}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      titleName={"Price"}
                      type={"number "}
                      name={"price"}
                      value={addOnNamePrice.price}
                      onChange={handleAddonDataChange}
                    />
                  </div>
                </div>
                <div className="flex justify_content_between flex_gap_10 ">
                  <div className="form-container-div">
                    {/* <VerticalInputFieldCustom
                      titleName={"tax"}
                      type={"number"}
                      name={"tax"}
                      value={addOnNamePrice.tax}
                      onChange={handleAddonDataChange}
                    /> */}
                    <VerticalInputSelectCustomCopy
                      titleName={"Tax"}
                      type={"number"}
                      name={"tax"}
                      value={addOnNamePrice.tax}
                      onChange={handleAddonDataChange}
                      options={taxOptions}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      titleName={"HSN Code"}
                      type={"number "}
                      name={"hsncode"}
                      value={addOnNamePrice.hsncode}
                      onChange={handleAddonDataChange}
                    />
                  </div>
                </div>

                <div className="flex flex_gap_10" style={{ marginTop: "10px" }}>
                  <BgThemeButton
                    children={"Add"}
                    loader={loader}
                    onClick={handleAddButtonClickInPopup}
                  />
                  <BgOutlineThemeButton
                    children={"Cancel"}
                    onClick={handleAddAddonCancel}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {/*-------------------------------------------------------------- */}

        {/*------------------ edit On Popup---------------------- */}
        {editPopup && (
          <>
            <div style={styles.addDistancePopupContainer}>
              <div style={styles.addDistancePopupCard}>
                <div className="flex justify_content_between flex_gap_10 ">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      titleName={"Add-On"}
                      type={"text"}
                      name={"add_on"}
                      value={editPopupData.add_on}
                      onChange={handleEditAddonDataChange}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      titleName={"Price"}
                      type={"number "}
                      name={"price"}
                      value={editPopupData.price}
                      onChange={handleEditAddonDataChange}
                    />
                  </div>
                </div>
                <div
                  className="flex justify_content_between flex_gap_10 "
                  style={{ marginTop: "10px" }}
                >
                  <div className="form-container-div">
                    {/* <VerticalInputFieldCustom
                      titleName={"Tax"}
                      type={"number"}
                      name={"tax"}
                      value={editPopupData.tax}
                      onChange={handleAddonDataChange}
                    /> */}
                    <VerticalInputSelectCustomCopy
                      options={taxOptions}
                      titleName={"Tax"}
                      type={"number"}
                      name={"tax"}
                      value={editPopupData.tax}
                      onChange={handleEditAddonDataChange}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      titleName={"HSN Code"}
                      type={"number "}
                      name={"hsncode"}
                      value={editPopupData.hsncode}
                      onChange={handleEditAddonDataChange}
                    />
                  </div>
                </div>

                <div className="flex flex_gap_10" style={{ marginTop: "10px" }}>
                  <BgThemeButton
                    children={"Update"}
                    onClick={handleUpdateButtonClickInPopup}
                  />
                  <BgOutlineThemeButton
                    children={"Cancel"}
                    onClick={handleAddAddonEditCancel}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {/*------------------ ------------------------------------- */}

        <div
          className="flex flex_gap_10"
          style={{ background: "#fff", width: "fit-content", margin: "auto" }}
        >
          <CustomPaginationArrow
            currentPage={1}
            totalPages={totalPages}
            // onPageChange={handlePageChange}
          />
        </div>

        {deletePopup && (
          <>
            <div className="bg-backdrop">
              <section className="room-type-model-container ">
                <h4>Are You Sure?</h4>
                <p>You Want To Remove?</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button onClick={() => setDeletePopup(!deletePopup)}>
                    Discard
                  </Button>
                  <Button onClick={handleAddonDelete} destructive>
                    Yes, Remove
                  </Button>
                </div>
              </section>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdOnsSetting;








