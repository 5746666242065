import React, { useState, useRef, useEffect } from "react";
import { showSuccessToast, showErrorToast } from "../../assets/toastUtils";
import { useSelector } from "react-redux";

const CommunicationDraftStatus = ({ messageId, fetchInfo, clearSelection }) => {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);
  const [showOptions, setShowOptions] = useState(false);

  console.log(messageId, "messageId");

  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // const renderDraftStatusOptions = () => {
  //   return (
  //     <div className="DraftStatusOptionsContainer">
  //       <p
  //         onClick={(e) =>
  //           handleChangeStatus("sent", messageId, property_id?.id, e)
  //         }
  //       >
  //         Sent
  //       </p>
  //     </div>
  //   );
  // };

  // const handleChangeStatus = async (lead_status, messageId, propertyId, e) => {
  //   e.stopPropagation();
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/api/v2/update/lead-send-message/${messageId}/${propertyId}`,
  //       {
  //         method: "POST",
  //         body: JSON.stringify({
  //           status: lead_status,
  //         }),
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );
  //     const data = await response.json();
  //     if (data.success) {
  //       showSuccessToast(data.message);
  //       fetchInfo(property_id?.id);
  //       clearSelection();
  //     } else {
  //       showErrorToast(data.message);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <div style={{ position: "relative" }}>
      <div
        onClick={(e) => [setShowOptions(true), e.stopPropagation()]}
        style={{
          color: "#FFFFFF",
          padding: "5px 12px",
          backgroundColor: "#888888",
          display: "inline-flex",
          borderRadius: "15px",
          fontWeight: "500",
          fontSize: "12px",
          cursor: "not-allowed", 
        }}
      >
        Draft{" "}
        {/* <span style={{ marginLeft: "5px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
          >
            <path d="M1 0.5L5 4.5L9 0.5" stroke="#fff"></path>
          </svg>
        </span> */}
      </div>
      {/* 
      {showOptions && (
        <div
          ref={popupRef}
          style={{
            backgroundColor: "#fff",
            position: "absolute",
            borderRadius: "4px",
            fontSize: "6px",
            padding: "4px",
            zIndex: "999",
            width: "80px",
            textAlign: 'start',
            boxShadow:
              "0 4px 4px 0px rgba(127, 155, 179, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10)",
          }}
        >
          {renderDraftStatusOptions()}
        </div>
      )} 
      */}
    </div>
  );
};

export default CommunicationDraftStatus;
