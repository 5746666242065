import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import { useState, useEffect } from "react";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
const ReportDepartureTable = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const extractedData = departureReport?.map((item) => ({
    unique_booking_id: item?.unique_booking_id,
    guest_name: item?.guest[0]?.guest_name,
    check_in: item?.check_in,
    check_out: item?.check_out,
    room_type_name:
      item?.items.length > 0 ? item?.items[0]?.room_type_name : "N/A",
    no_nights: item?.items[0]?.no_nights,
    no_adult: item?.items[0]?.no_adult,
    assigned_room: item?.assignedrooms[0]?.assigned_room,
    status: item?.status?.replace(/_/g, " "),
  }));

  const names = [
    {
      unique_booking_id: "Booking ID",
      guest_name: "Name",
      check_in: "Arrival Date",
      check_out: "Departure Date",
      room_type_name: "Room Category",
      no_nights: "No of Nights",
      no_adult: "No. of Guests",
      assigned_room: "Assigned Rooms",
      status: "Status",
    },
  ];

  const rowMarkup = departureReport?.map((order) => (
    <IndexTable.Row id={order.id} key={order.id} position={order.index}>
      <IndexTable.Cell>{order.unique_booking_id}</IndexTable.Cell>
      <IndexTable.Cell>{order.guest[0].guest_name}</IndexTable.Cell>
      <IndexTable.Cell>
        <TextDateFormatter as={"span"} showYear={true} date={order.check_in} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <TextDateFormatter as={"span"} showYear={true} date={order.check_out} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        {order?.items.map((item) => (
          <div>{item.room_type_name}</div>
        ))}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {order?.items.map((item) => item.no_nights)}dsadsa
      </IndexTable.Cell>
      <IndexTable.Cell>
        {order?.items.map((item) => (
          <div>{item.no_adult}</div>
        ))}
      </IndexTable.Cell>

      <IndexTable.Cell>
        <>
          {order && order.assignedrooms && order.assignedrooms.length > 0 ? (
            order.assignedrooms.map((room) => {
              return <div>{room.assigned_room}</div>;
            })
          ) : (
            <div>N/A</div>
          )}
        </>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StatusUpdate status={order.status} /> {}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const rowMarkupOne = departureReport?.map((order) => (
    <CustomReportTableRow
      id={order.id}
      key={order.id}
      // selected={selectedResources.includes(order.id)}
      position={order.index}
    >
      <CustomReportTableCell>{order.unique_booking_id}</CustomReportTableCell>
      <CustomReportTableCell>
        {order?.guest && order?.guest?.length > 0 && order?.guest[0].guest_name}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {" "}
        <TextDateFormatter as={"span"} showYear={true} date={order.check_in} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        <TextDateFormatter as={"span"} showYear={true} date={order.check_out} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.guest &&
          order?.guest?.length > 0 &&
          order?.items.map((item, index) => (
            <span key={index}>
              {item.room_type_name}
              {index < order.items.length - 1 && ","} &nbsp;
            </span>
          ))}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.guest && order?.guest?.length > 0 && order?.items[0]?.no_nights}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.guest && order?.guest?.length > 0 && order?.items[0]?.no_adult}
      </CustomReportTableCell>
      <CustomReportTableCell>₹{order?.balance_amount}</CustomReportTableCell>
      <CustomReportTableCell>
        <StatusUpdate status={order?.status} /> {}
      </CustomReportTableCell>
    </CustomReportTableRow>
  ));

  return (
    <div id="receipt">
      <div className="room-types-full-container reports-new-background">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Departure </div>
          {departureReport?.length > 0 && (
            <div
              className="flex flex_gap_15 align_center"
              id={"ignoreFromPrint"}
            >
              {/* <DownloadPdfTables tableData={extractedData} tableNames={names} />
              <DownloadCsvTables tableData={extractedData} names={names} /> */}
              {/* <DownloadCsvTables tableData={extractedData} names={names} /> */}
              <ButtonToDownloadTablePDF
                itemToDownloadID={"receipt"}
                downloadFileName={"departure_report"}
                pdfOrientation={"landscape"}
                pageFormat={"A4"}
                ignoreFromPrint={"ignoreFromPrint"}
              />
              <DownloadCSVButton
                itemToDownloadID={"receipt"}
                downloadFileName={"departure_report"}
              />
            </div>
          )}
        </div>
        {loader && <div>Loader</div>}

        {departureReport && departureReport.length > 0 ? (
          <>
            <CustomReportTable
              headings={[
                "Booking ID",
                "Guest Name",
                "Check In Date",
                "Check Out Date",
                "Room Type",
                "No Of Nights",
                "	No Of Guests",
                "Balance",
                "Status",
              ]}
              // commonHeadings={[
              //   "",
              //   "",
              //   "",
              //   "",
              //   "Quantity",
              //   "Quantity",
              //   "Quantity",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              // ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "center",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {/* {rowMarkup} */}
              {rowMarkupOne}
              {/* {calculateTotals} */}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>
    </div>
  );
};
export default ReportDepartureTable;
