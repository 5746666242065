import React, { useState, useEffect } from "react";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { useSelector } from "react-redux";

const CustomerSelectAddBox = ({ onCompetitorsIdsChange }) => {
  const [customers, setCustomers] = useState();
  const [label, setLabel] = useState([]);

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);
  const handleDestinationSelectChange = (e) => {};
  const handleSelectChange = (e) => {
    const selectedid =
      e.target.options[e.target.selectedIndex].getAttribute("data-id");

    if (label.some((item) => item.id === selectedid)) {
      showErrorToast("Duplicate Entry");
    } else {
      setLabel((prevValue) => [
        ...prevValue,
        { name: e.target.value, id: selectedid },
      ]);
    }
  };

  const handlelistRemove = (id) => {
    setLabel((prevValue) => prevValue.filter((item) => item.id !== id));
  };

  const [competitorsIdsArray, setCompetitorsIdsArray] = useState([]);

  //saving customers ids in array
  useEffect(() => {
    function getIdsFromArray(customersSelectedIdArray) {
      return customersSelectedIdArray.map((item) => item.id);
    }

    const customersSelectedIdArray = getIdsFromArray(label);
    setCompetitorsIdsArray(customersSelectedIdArray);

    if (typeof onCompetitorsIdsChange === "function") {
      onCompetitorsIdsChange(customersSelectedIdArray);
    }
  }, [label]);

  const limit = 2;

  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/competitors/list/${property_id?.property_city}/${property_id?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        const data = await resp.json();
        setCustomers(
          data.data.data.map((item) => ({
            name: item.competitor_name,
            id: item.id,
            destination: item.destination,
          }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const uniqueDestinations = {};

  customers?.forEach((item) => {
    const destination = item.destination;
    const id = item.id;

    if (!uniqueDestinations[destination]) {
      uniqueDestinations[destination] = id;
    }
  });

  const uniqueAllDestinations = Object.keys(uniqueDestinations).map(
    (destination) => ({
      destination,
      id: uniqueDestinations[destination],
    })
  );

  return (
    <>
      <div
        // style={{ display: "flex", gap: "10px" }}
        className="customeraddbox_container"
      >
        <select
          onChange={(e) => handleSelectChange(e, customers.id)}
          className="customeraddbox_select"
        >
          <option disabled selected>
            Select Option
          </option>
          {customers?.map((customer) => (
            <option
              id={customer.id}
              value={customer.name}
              data-id={customer.id}
            >
              {customer.name}
            </option>
          ))}
        </select>
        <ul
          style={{ marginTop: "10px" }}
          className="customeraddbox_unorderedlist"
        >
          {label.map((qwer, index) => (
            <li key={index} className="customeraddboxlist">
              {qwer.name}
              <button
                onClick={() => handlelistRemove(qwer.id)}
                className="customeraddboxButton"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                >
                  <path d="M1 6L6 1" stroke="#888888" />
                  <path d="M1 1L6 6" stroke="#888888" />
                </svg>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default CustomerSelectAddBox;
