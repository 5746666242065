import { Button, DataTable, LegacyCard, Tooltip } from "@shopify/polaris";
import { useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "../UI/Buttons";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../CustomComponents/NoDataFound";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import Loader from "../UI/Loader/Loader";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";

const ToggleActiveDisable = (props) => {
  const [isToggled, setIsToggled] = useState(props.status === "enable");
  const [beddingFinalValue, setBeddingFinalValue] = useState("");
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const user = JSON.parse(localStorage.getItem("user"));
  const property_id = useSelector((state) => state.property.value);

  const handleToggle = useCallback(() => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  }, []);

  const handleToggleStatus = async () => {
    if (isToggled) {
      // setBeddingFinalValue("enabled");
      await handleDisable();
    } else {
      await handleEnable();
      // setBeddingFinalValue("enabled");
    }
  };
  const [loader, setLoader] = useState(true);
  const currentDate = new Date().toISOString().split("T")[0];
  const handleDisable = async () => {
    const userId = props.userId;
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/account/disable/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ user_id: userId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setIsToggled(false);
        // window.location.reload();
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const handleEnable = async () => {
    const userId = props.userId;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/account/enable/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ user_id: userId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
        setIsToggled(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  useEffect(() => {
    if (isToggled === false) {
      setBeddingFinalValue("Disabled");
    } else {
      setBeddingFinalValue("Enabled");
    }
  }, [isToggled]);

  return (
    <div className="flex align_items_center">
      <span className="toggle_text">{beddingFinalValue}</span>
      <label className="toggle-container">
        <input
          name="extra_bedding"
          type="checkbox"
          checked={isToggled}
          onChange={handleToggle}
          className="toggle-checkbox-custom"
        />
        <span className="toggle-slider" onClick={handleToggleStatus}></span>
      </label>
    </div>
  );
};

const RolesCrudComponent = () => {
  const [staffs, setStaffs] = useState([]);
  const navigate = useNavigate();
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);
  const [showAddStaffPopup, setShowAddStaffPopup] = useState(false);

  const [staffLimit, setStaffLimit] = useState("");
  const [features, setFeatures] = useState("");

  useEffect(() => {
    if (property_id) {
      setFeatures(property_id.subscriptions.plan.features);
    }
    const comp =
      features &&
      features.find((feature) => feature.plan_name === "Staff Account");
    setStaffLimit(comp?.plan_limit || 0);
  }, [property_id, staffLimit]);

  const handleshowAddStaffPopup = () => {
    setShowAddStaffPopup(!showAddStaffPopup);
  };

  const [addTeamData, setAddTeamData] = useState({
    name: "",
    email: "",
    property_id: property_id?.id,
  });

  const setAddTeamDataHandleChange = (event) => {
    setAddTeamData({
      ...addTeamData,
      [event.target.name]: event.target.value,
    });
  };

  const fetchInfo = async (id) => {
    if (!id) {
      navigate("/login");
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/property/roles/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setStaffs(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [deleteStaffId, setDeleteStaffId] = useState(false);

  const deleteData = async (staffId) => {
    // "http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/assign/rooms/delete"
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/property/roles/delete/${staffId}/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // clearSelection();
          fetchInfo(property_id?.id);
          // clearSelection();
          // setShowLowerMenuMultipleSelect(false);
          // setShowModelMenuActive(false);
          setShowModelMenuActive(!showModelMenuActive);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
          setShowModelMenuActive(!showModelMenuActive);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
    }
  }, [property_id]);

  const combinedTableRows =
    staffs &&
    staffs?.map((staff) => [
      <UnderscoreRemoveCapitalize text={staff.name} />,
      <div style={{ display: "flex", justifyContent: "end" }}>
        <BgOutlineThemeButton
          children={"Edit Permissions"}
          onClick={() => navigate(`/staffpermissions/${staff.id}`)}
        />
      </div>,
      <div
        style={{ cursor: "pointer" }}
        onClick={() => [
          setDeleteStaffId(staff.id),
          setShowModelMenuActive(true),
        ]}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
            fill="#E03838"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
            fill="#E03838"
          />
        </svg>
      </div>,
    ]);

  const [options, setOptions] = useState([
    {
      id: 1,
      name: "Reservations",
      permission: [
        {
          id: "create_reservations",
          label: "Create reservations",
          value: "create_reservations",
        },
        {
          id: "update_reservations",
          label: "Update reservations",
          value: "update_reservations",
        },
        // {
        //   id: "delete_reservations",
        //   label: "Delete reservations",
        //   value: "delete_reservations",
        // },
        {
          id: "read_reservations",
          label: "Read reservations",
          value: "read_reservations",
        },
        {
          id: "show_single_booking",
          label: "Show single booking",
          value: "show_single_booking",
        },
        {
          id: "show_preview_bill",
          label: "Show preview booking",
          value: "show_preview_bill",
        },
        {
          id: "cancel_reservation",
          label: "Cancel Reservation",
          value: "cancel_reservation",
        },
      ],
    },
    {
      id: 2,
      name: "Dashboard",
      permission: [
        // {
        //   id: "create_dashboard",
        //   label: "Create dashboard",
        //   value: "create_dashboard",
        // },
        {
          id: "assign_rooms",
          label: "Assign Rooms",
          value: "assign_rooms",
        },
        {
          id: "transfer_rooms",
          label: "Transfer Rooms",
          value: "transfer_rooms",
        },
        // {
        //   id: "update_dashboard",
        //   label: "Update dashboard",
        //   value: "update_dashboard",
        // },
        // {
        //   id: "delete_dashboard",
        //   label: "Delete dashboard",
        //   value: "delete_dashboard",
        // },
        {
          id: "read_dashboard",
          label: "Read dashboard",
          value: "read_dashboard",
        },
      ],
    },
    {
      id: 3,
      name: "Check In",
      permission: [
        {
          id: "check_in",
          label: "Check In",
          value: "check_in",
        },
      ],
    },
    {
      id: 4,
      name: "Check Out",
      permission: [
        {
          id: "check_out",
          label: "Check Out",
          value: "check_out",
        },
      ],
    },
    {
      id: 11,
      name: "Low Inventory Dates",
      permission: [
        {
          id: "create_low_inventory_dates",
          label: "Create Low Inventory Dates",
          value: "create_low_inventory_dates",
        },
        {
          id: "update_low_inventory_dates",
          label: "Update Low Inventory Dates",
          value: "update_low_inventory_dates",
        },
        {
          id: "delete_low_inventory_dates",
          label: "Delete Low Inventory Dates",
          value: "delete_low_inventory_dates",
        },
        {
          id: "read_low_inventory_dates",
          label: "Read Low Inventory Dates",
          value: "read_low_inventory_dates",
        },
      ],
    },
    {
      id: 12,
      name: "Rooms",
      permission: [
        { id: "create_rooms", label: "Create rooms", value: "create_rooms" },
        { id: "update_rooms", label: "Update rooms", value: "update_rooms" },
        { id: "delete_rooms", label: "Delete rooms", value: "delete_rooms" },
        { id: "read_rooms", label: "Read rooms", value: "read_rooms" },
      ],
    },
    {
      id: 13,
      name: "Rooms Type",
      permission: [
        {
          id: "read_room_types",
          label: "Read room types",
          value: "read_room_types",
        },
        {
          id: "update_room_types",
          label: "Update room types",
          value: "update_room_types",
        },
        {
          id: "delete_room_types",
          label: "Delete room types",
          value: "delete_room_types",
        },
        {
          id: "create_room_types",
          label: "Create room types",
          value: "create_room_types",
        },
      ],
    },
    {
      id: 14,
      name: "Meal Plans",
      permission: [
        {
          id: "add_meal_plans",
          label: "Add Meal Plans",
          value: "create_meal_plans",
        },
        {
          id: "update_meal_plans",
          label: "Update Meal Plans",
          value: "update_meal_plans",
        },
        {
          id: "delete_meal_plans",
          label: "Delete Meal Plans",
          value: "delete_meal_plans",
        },
        {
          id: "read_meal_plans",
          label: "Read Meal Plans",
          value: "read_meal_plans",
        },
      ],
    },
    {
      id: 15,
      name: "Room Rate Plans",
      permission: [
        {
          id: "create_room_rate_plan",
          label: "Create Room Rate Plans",
          value: "create_room_rate_plan",
        },
        {
          id: "update_room_rate_plan",
          label: "Update Room Rate Plans",
          value: "update_room_rate_plan",
        },
        {
          id: "delete_room_rate_plan",
          label: "Delete Room Rate Plans",
          value: "delete_room_rate_plan",
        },
        {
          id: "read_room_rate_plan",
          label: "Read Room Rate Plans",
          value: "read_room_rate_plan",
        },
      ],
    },
    {
      id: 16,
      name: "Property Targets",
      permission: [
        // {
        //   id: "create_property_targets",
        //   label: "Create Property Targets",
        //   value: "create_property_targets",
        // },
        // {
        //   id: "update_property_targets",
        //   label: "Update Property Targets",
        //   value: "update_property_targets",
        // },
        // {
        //   id: "delete_property_targets",
        //   label: "Delete Property Targets",
        //   value: "delete_property_targets",
        // },
        {
          id: "read_property_targets",
          label: "Read Property Targets",
          value: "read_property_targets",
        },
      ],
    },
    {
      id: 17,
      name: "Inventory",
      permission: [
        {
          id: "read_inventory",
          label: "Read inventory",
          value: "read_inventory",
        },
        {
          id: "read_bulk_inventory",
          label: "Read bulk_inventory",
          value: "read_bulk_inventory",
        },
        {
          id: "read_block_inventory",
          label: "Read block inventory",
          value: "read_block_inventory",
        },
        {
          id: "read_unhold_inventory",
          label: "Read unhold inventory",
          value: "read_unhold_inventory",
        },
        {
          id: "read_unblock_inventory",
          label: "Read unblock inventory",
          value: "read_unblock_inventory",
        },
        {
          id: "read_hold_inventory",
          label: "Read hold inventory",
          value: "read_hold_inventory",
        },
        {
          id: "update_hold_inventory",
          label: "Update hold inventory",
          value: "update_hold_inventory",
        },
        {
          id: "update_bulk_inventory",
          label: "Update bulk inventory",
          value: "update_bulk_inventory",
        },
        {
          id: "update_unhold_inventory",
          label: "Update unhold inventory",
          value: "update_unhold_inventory",
        },
        {
          id: "update_unblock_inventory",
          label: "Update unblock inventory",
          value: "update_unblock_inventory",
        },
        {
          id: "update_block_inventory",
          label: "Update block inventory",
          value: "update_block_inventory",
        },
      ],
    },
    {
      id: 18,
      name: "Channel Inventory",
      permission: [
        {
          id: "create_channel_inventory",
          label: "Create Channel Inventory",
          value: "create_channel_inventory",
        },
        {
          id: "update_channel_inventory",
          label: "Update Channel Inventory",
          value: "update_channel_inventory",
        },
        {
          id: "delete_channel_inventory",
          label: "Delete Channel Inventory",
          value: "delete_channel_inventory",
        },
        {
          id: "read_channel_inventory",
          label: "Read Channel Inventory",
          value: "read_channel_inventory",
        },
      ],
    },
    {
      id: 19,
      name: "More Details",
      permission: [
        {
          id: "update_more_details",
          label: "Update More Details",
          value: "update_more_details",
        },
        {
          id: "read_more_details",
          label: "Read More Details",
          value: "read_more_details",
        },
      ],
    },
    {
      id: 20,
      name: "Cancel Booking",
      permission: [
        {
          id: "update_cancel_booking",
          label: "Update Cancel Booking",
          value: "update_cancel_booking",
        },
        {
          id: "read_cancel_booking",
          label: "Read Cancel Booking",
          value: "read_cancel_booking",
        },
      ],
    },
    {
      id: 21,
      name: "View Voucher",
      permission: [
        {
          id: "update_view_voucher",
          label: "Update View Voucher",
          value: "update_view_voucher",
        },
        {
          id: "read_view_voucher",
          label: "Read View Voucher",
          value: "read_view_voucher",
        },
      ],
    },
    {
      id: 22,
      name: "Generate Bill",
      permission: [
        {
          id: "update_generate_bill",
          label: "Update Generate Bill",
          value: "update_generate_bill",
        },
        {
          id: "read_generate_bill",
          label: "Read Generate Bill",
          value: "read_generate_bill",
        },
      ],
    },
    {
      id: 23,
      name: "Analytics",
      permission: [
        {
          id: "create_analytics",
          label: "Create Analytics",
          value: "create_analytics",
        },
        {
          id: "update_analytics",
          label: "Update Analytics",
          value: "update_analytics",
        },
        {
          id: "delete_analytics",
          label: "Delete Analytics",
          value: "delete_analytics",
        },
        {
          id: "read_analytics",
          label: "Read Analytics",
          value: "read_analytics",
        },
        {
          id: "edit_analytics",
          label: "Edit Analytics",
          value: "edit_analytics",
        },
      ],
    },
    {
      id: 24,
      name: "Competitor Analysis",
      permission: [
        {
          id: "create_competitors_analysis",
          label: "Create Competitor Analysis",
          value: "create_competitors_analysis",
        },
        {
          id: "update_competitors_analysis",
          label: "Update Competitor Analysis",
          value: "update_competitors_analysis",
        },
        {
          id: "delete_competitors_analysis",
          label: "Delete Competitor Analysis",
          value: "delete_competitors_analysis",
        },
        {
          id: "read_competitors_analysis",
          label: "Read Competitor Analysis",
          value: "read_competitors_analysis",
        },
        {
          id: "edit_competitors_analysis",
          label: "Edit Competitor Analysis",
          value: "edit_competitors_analysis",
        },
      ],
    },
    {
      id: 25,
      name: "Competitors",
      permission: [
        {
          id: "create_competitors",
          label: "Create Competitors",
          value: "create_competitors",
        },
        {
          id: "update_competitors",
          label: "Update Competitors",
          value: "update_competitors",
        },
        {
          id: "delete_competitors",
          label: "Delete Competitors",
          value: "delete_competitors",
        },
        {
          id: "read_competitors",
          label: "Read Competitors",
          value: "read_competitors",
        },
        {
          id: "edit_competitors",
          label: "Edit Competitors",
          value: "edit_competitors",
        },
      ],
    },
    {
      id: 26,
      name: "Customers",
      permission: [
        {
          id: "create_customers",
          label: "Create Customers",
          value: "create_customers",
        },
        {
          id: "update_customers",
          label: "Update Customers",
          value: "update_customers",
        },
        {
          id: "delete_customers",
          label: "Delete Customers",
          value: "delete_customers",
        },
        {
          id: "read_customers",
          label: "Read Customers",
          value: "read_customers",
        },
        {
          id: "edit_customers",
          label: "Edit Customers",
          value: "edit_customers",
        },
      ],
    },
    {
      id: 27,
      name: "Agent",
      permission: [
        // { id: "create_agent", label: "Create Agent", value: "create_agent" },
        // { id: "update_agent", label: "Update Agent", value: "update_agent" },
        // { id: "delete_agent", label: "Delete Agent", value: "delete_agent" },
        { id: "read_agent", label: "Read Agent", value: "read_agent" },
      ],
    },
    {
      id: 28,
      name: "Agents",
      permission: [
        { id: "create_agents", label: "Create Agents", value: "create_agents" },
        { id: "update_agents", label: "Update Agents", value: "update_agents" },
        { id: "delete_agents", label: "Delete Agents", value: "delete_agents" },
        { id: "read_agents", label: "Read Agents", value: "read_agents" },
        { id: "edit_agents", label: "Edit Agents", value: "edit_agents" },
      ],
    },
    {
      id: 29,
      name: "Agent Categories",
      permission: [
        {
          id: "create_agent_categories",
          label: "Create Agent Categories",
          value: "create_agent_categories",
        },
        {
          id: "update_agent_categories",
          label: "Update Agent Categories",
          value: "update_agent_categories",
        },
        {
          id: "delete_agent_categories",
          label: "Delete Agent Categories",
          value: "delete_agent_categories",
        },
        {
          id: "read_agent_categories",
          label: "Read Agent Categories",
          value: "read_agent_categories",
        },
        {
          id: "edit_agent_categories",
          label: "Edit Agent Categories",
          value: "edit_agent_categories",
        },
      ],
    },
    {
      id: 30,
      name: "Discounts",
      permission: [
        {
          id: "create_discounts",
          label: "Create Discounts",
          value: "create_discounts",
        },
        {
          id: "update_discounts",
          label: "Update Discounts",
          value: "update_discounts",
        },
        {
          id: "delete_discounts",
          label: "Delete Discounts",
          value: "delete_discounts",
        },
        {
          id: "read_discounts",
          label: "Read Discounts",
          value: "read_discounts",
        },
        {
          id: "edit_discounts",
          label: "Edit Discounts",
          value: "edit_discounts",
        },
      ],
    },
    {
      id: 31,
      name: "Website",
      permission: [
        {
          id: "create_website",
          label: "Create Website",
          value: "create_website",
        },
        {
          id: "update_website",
          label: "Update Website",
          value: "update_website",
        },
        {
          id: "delete_website",
          label: "Delete Website",
          value: "delete_website",
        },
        { id: "read_website", label: "Read Website", value: "read_website" },
        { id: "edit_website", label: "Edit Website", value: "edit_website" },
      ],
    },
    {
      id: 32,
      name: "Blog Posts",
      permission: [
        {
          id: "create_blog_posts",
          label: "Create Blog Posts",
          value: "create_blog_posts",
        },
        {
          id: "update_blog_posts",
          label: "Update Blog Posts",
          value: "update_blog_posts",
        },
        {
          id: "delete_blog_posts",
          label: "Delete Blog Posts",
          value: "delete_blog_posts",
        },
        {
          id: "read_blog_posts",
          label: "Read Blog Posts",
          value: "read_blog_posts",
        },
        {
          id: "edit_blog_posts",
          label: "Edit Blog Posts",
          value: "edit_blog_posts",
        },
      ],
    },
    {
      id: 33,
      name: "Pages",
      permission: [
        { id: "create_pages", label: "Create Pages", value: "create_pages" },
        { id: "update_pages", label: "Update Pages", value: "update_pages" },
        { id: "delete_pages", label: "Delete Pages", value: "delete_pages" },
        { id: "read_pages", label: "Read Blog Pages", value: "read_pages" },
        { id: "edit_pages", label: "Edit Blog Pages", value: "edit_pages" },
      ],
    },
    {
      id: 34,
      name: "Suppliers",
      permission: [
        {
          id: "create_suppliers",
          label: "Create suppliers",
          value: "create_suppliers",
        },
        {
          id: "update_suppliers",
          label: "Update suppliers",
          value: "update_suppliers",
        },
        {
          id: "delete_suppliers",
          label: "Delete suppliers",
          value: "delete_suppliers",
        },
        {
          id: "read_suppliers",
          label: "Read suppliers",
          value: "read_suppliers",
        },
      ],
    },
    {
      id: 35,
      name: "Items",
      permission: [
        // { id: "create_agent", label: "Create Agent", value: "create_agent" },
        // { id: "update_agent", label: "Update Agent", value: "update_agent" },
        // { id: "delete_agent", label: "Delete Agent", value: "delete_agent" },
        { id: "read_items", label: "Read Items", value: "read_items" },
      ],
    },
    {
      id: 36,
      name: "Purchases",
      permission: [
        {
          id: "create_purchases",
          label: "Create purchases",
          value: "create_purchases",
        },
        {
          id: "update_purchases",
          label: "Update purchases",
          value: "update_purchases",
        },
        {
          id: "delete_purchases",
          label: "Delete purchases",
          value: "delete_purchases",
        },
        {
          id: "read_purchases",
          label: "Read purchases",
          value: "read_purchases",
        },
        {
          id: "issue_item_purchases",
          label: "Issue item purchases",
          value: "issue_item_purchases",
        },
      ],
    },
    {
      id: 37,
      name: "Add stock",
      permission: [
        {
          id: "create_add_stock",
          label: "Create Add stock",
          value: "create_add_stock",
        },
        {
          id: "update_add_stock",
          label: "Update Add stock",
          value: "update_add_stock",
        },
        {
          id: "delete_add_stock",
          label: "Delete Add stock",
          value: "delete_add_stock",
        },
        {
          id: "read_add_stock",
          label: "Read Add stock",
          value: "read_add_stock",
        },
        {
          id: "edit_add_stock",
          label: "Edit Add stock",
          value: "edit_add_stock",
        },
      ],
    },
    {
      id: 38,
      name: "Issue Items",
      permission: [
        {
          id: "create_issue_items",
          label: "Create Issue Items",
          value: "create_issue_items",
        },
        {
          id: "update_issue_items",
          label: "Update Issue Items",
          value: "update_issue_items",
        },
        {
          id: "delete_issue_items",
          label: "Delete Issue Items",
          value: "delete_issue_items",
        },
        {
          id: "read_issue_items",
          label: "Read Issue Items",
          value: "read_issue_items",
        },
        {
          id: "edit_issue_items",
          label: "Edit Issue Items",
          value: "edit_issue_items",
        },
      ],
    },
    {
      id: 39,
      name: "Expenses",
      permission: [
        {
          id: "create_expenses",
          label: "Create expenses",
          value: "create_expenses",
        },
        {
          id: "update_expenses",
          label: "Update expenses",
          value: "update_expenses",
        },
        {
          id: "delete_expenses",
          label: "Delete expenses",
          value: "delete_expenses",
        },
        { id: "read_expenses", label: "Read expenses", value: "read_expenses" },
      ],
    },
    {
      id: 40,
      name: "KOT",
      permission: [
        { id: "create_kot", label: "Create KOT", value: "create_kot" },
        { id: "update_kot", label: "Update KOT", value: "update_kot" },
        { id: "delete_kot", label: "Delete KOT", value: "delete_kot" },
        { id: "read_kot", label: "Read KOT", value: "read_kot" },
        { id: "view_kot_bill", label: "View KOT Bill", value: "view_kot_bill" },
        {
          id: "generate_kot_bill",
          label: "Generate KOT Bill",
          value: "generate_kot_bill",
        },
        {
          id: "cancel_kot_bill",
          label: "Cancel KOT Bill",
          value: "cancel_kot_bill",
        },
      ],
    },
    {
      id: 41,
      name: "KOT Bill",
      permission: [
        {
          id: "generate_kot_bill",
          label: "Generate KOT Bill",
          value: "generate_kot_bill",
        },
        {
          id: "cancel_kot_bill",
          label: "Cancel KOT Bill",
          value: "cancel_kot_bill",
        },
      ],
    },
    {
      id: 42,
      name: "Food Sale",
      permission: [
        {
          id: "read_food_sale",
          label: "Read Food Sale",
          value: "read_food_sale",
        },
        {
          id: "create_food_sale",
          label: "Create Food Sale",
          value: "create_food_sale",
        },
        {
          id: "settle_bill_food_sale",
          label: "Settle bill food sale",
          value: "settle_bill_food_sale",
        },
        {
          id: "view_bill_food_sale",
          label: "View bill food sale",
          value: "view_bill_food_sale",
        },
        {
          id: "generate_bill_food_sale",
          label: "Generate bill food sale",
          value: "generate_bill_food_sale",
        },
      ],
    },
    {
      id: 43,
      name: "Events",
      permission: [
        { id: "create_events", label: "Create Events", value: "create_events" },
        { id: "update_events", label: "Update Events", value: "update_events" },
        { id: "delete_events", label: "Delete Events", value: "delete_events" },
        { id: "read_events", label: "Read Events", value: "read_events" },
        { id: "edit_events", label: "Edit Events", value: "edit_events" },
      ],
    },
    {
      id: 44,
      name: "Event Categories",
      permission: [
        {
          id: "create_event_categories",
          label: "Create Event Categories",
          value: "create_event_categories",
        },
        {
          id: "update_event_categories",
          label: "Update Event Categories",
          value: "update_event_categories",
        },
        {
          id: "delete_event_categories",
          label: "Delete Event Categories",
          value: "delete_event_categories",
        },
        {
          id: "read_event_categories",
          label: "Read Event Categories",
          value: "read_event_categories",
        },
        {
          id: "edit_event_categories",
          label: "Edit Event Categories",
          value: "edit_event_categories",
        },
      ],
    },
    {
      id: 45,
      name: "Settle Bill",
      permission: [
        {
          id: "create_settle_bill",
          label: "Create Settle Bill",
          value: "create_settle_bill",
        },
        {
          id: "update_settle_bill",
          label: "Update Settle Bill",
          value: "update_settle_bill",
        },
        {
          id: "delete_settle_bill",
          label: "Delete Settle Bill",
          value: "delete_settle_bill",
        },
        {
          id: "read_settle_bill",
          label: "Read Settle Bill",
          value: "read_settle_bill",
        },
        {
          id: "edit_settle_bill",
          label: "Edit Settle Bill",
          value: "edit_settle_bill",
        },
      ],
    },
    {
      id: 46,
      name: "Preview Voucher",
      permission: [
        {
          id: "read_preview_voucher",
          label: "Read Preview Voucher",
          value: "read_preview_voucher",
        },
      ],
    },
    {
      id: 47,
      name: "Banquet Menus",
      permission: [
        {
          id: "create_banquet_menus",
          label: "Create Banquet Menus",
          value: "create_banquet_menus",
        },
        {
          id: "update_banquet_menus",
          label: "Update Banquet Menus",
          value: "update_banquet_menus",
        },
        {
          id: "delete_banquet_menus",
          label: "Delete Banquet Menus",
          value: "delete_banquet_menus",
        },
        {
          id: "read_banquet_menus",
          label: "Read Banquet Menus",
          value: "read_banquet_menus",
        },
        {
          id: "edit_banquet_menus",
          label: "Edit Banquet Menus",
          value: "edit_banquet_menus",
        },
      ],
    },
    {
      id: 48,
      name: "Banquet Menu Items",
      permission: [
        {
          id: "create_banquet_menu_Items",
          label: "Create Banquet Menu Items",
          value: "create_banquet_menu_Items",
        },
        {
          id: "update_banquet_menu_Items",
          label: "Update Banquet Menu Items",
          value: "update_banquet_menu_Items",
        },
        {
          id: "delete_banquet_menu_Items",
          label: "Delete Banquet Menu Items",
          value: "delete_banquet_menu_Items",
        },
        {
          id: "read_banquet_menu_Items",
          label: "Read Banquet Menu Items",
          value: "read_banquet_menu_Items",
        },
        {
          id: "edit_banquet_menu_Items",
          label: "Edit Banquet Menu Items",
          value: "edit_banquet_menu_Items",
        },
      ],
    },
    {
      id: 49,
      name: "Banquet Menu Categories",
      permission: [
        {
          id: "create_banquet_menu_categories",
          label: "Create Banquet Menu Categories",
          value: "create_banquet_menu_categories",
        },
        {
          id: "update_banquet_menu_categories",
          label: "Update Banquet Menu Categories",
          value: "update_banquet_menu_categories",
        },
        {
          id: "delete_banquet_menu_categories",
          label: "Delete Banquet Menu Categories",
          value: "delete_banquet_menu_categories",
        },
        {
          id: "read_banquet_menu_categories",
          label: "Read Banquet Menu Categories",
          value: "read_banquet_menu_categories",
        },
        {
          id: "edit_banquet_menu_categories",
          label: "Edit Banquet Menu Categories",
          value: "edit_banquet_menu_categories",
        },
      ],
    },
    {
      id: 50,
      name: "Communications",
      permission: [
        {
          id: "create_communications",
          label: "Create Communications",
          value: "create_communications",
        },
        {
          id: "send_communications",
          label: "Send Communications",
          value: "send_communications",
        },
        {
          id: "delete_communications",
          label: "Delete Communications",
          value: "delete_communications",
        },
        {
          id: "read_communications",
          label: "Read Communications",
          value: "read_communications",
        },
        {
          id: "edit_communications",
          label: "Edit Communications",
          value: "edit_communications",
        },
      ],
    },
    {
      id: 51,
      name: "Settings",
      permission: [
        {
          id: "assign_staff_permissions",
          label: "Assign Staff Permissions",
          value: "assign_staff_permissions",
        },
        {
          id: "create_settings",
          label: "Create Settings",
          value: "create_settings",
        },
        {
          id: "update_settings",
          label: "Update Settings",
          value: "update_settings",
        },
        {
          id: "delete_settings",
          label: "Delete Settings",
          value: "delete_settings",
        },
        { id: "read_settings", label: "Read Settings", value: "read_settings" },
        { id: "edit_settings", label: "Edit Settings", value: "edit_settings" },
      ],
    },

    {
      id: 52,
      name: "Housekeeping Dashboard",
      permission: [
        {
          id: "create_housekeeping_dashboard",
          label: "Create Housekeeping Dashboard",
          value: "create_housekeeping_dashboard",
        },
        {
          id: "update_housekeeping_dashboard",
          label: "Update Housekeeping Dashboard",
          value: "update_housekeeping_dashboard",
        },
        {
          id: "delete_housekeeping_dashboard",
          label: "Delete Housekeeping Dashboard",
          value: "delete_housekeeping_dashboard",
        },
        {
          id: "read_housekeeping_dashboard",
          label: "Read Housekeeping Dashboard",
          value: "read_housekeeping_dashboard",
        },
      ],
    },
    // {
    //   id: 53,
    //   name: "Assign Room",
    //   permission: [
    //     {
    //       id: "create_assign_room",
    //       label: "Create Assign Room",
    //       value: "create_assign_room",
    //     },
    //     {
    //       id: "update_assign_room ",
    //       label: "Update Assign Room ",
    //       value: "update_assign_room ",
    //     },
    //     {
    //       id: "delete_assign_room ",
    //       label: "Delete Assign Room ",
    //       value: "delete_assign_room ",
    //     },
    //     {
    //       id: "read_assign_room ",
    //       label: "Read Assign Room ",
    //       value: "read_assign_room ",
    //     },
    //   ],
    // },
    {
      id: 54,
      name: "Housekeeping Staff",
      permission: [
        {
          id: "create_housekeeping_staff",
          label: "Create Housekeeping Staff",
          value: "create_housekeeping_staff",
        },
        {
          id: "update_housekeeping_staff",
          label: "Update Housekeeping Staff",
          value: "update_housekeeping_staff",
        },
        {
          id: "delete_housekeeping_staff",
          label: "Delete Housekeeping Staff",
          value: "delete_housekeeping_staff",
        },
        {
          id: "read_housekeeping_staff",
          label: "Read Housekeeping Staff",
          value: "read_housekeeping_staff",
        },
        {
          id: "active_housekeeping_staff",
          label: "Active Housekeeping Staff",
          value: "active_housekeeping_staff",
        },
        {
          id: "inactive_housekeeping_staff",
          label: "Inactive Housekeeping Staff",
          value: "inactive_housekeeping_staff",
        },
      ],
    },
  ]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (id) => {
    setActiveAccordion((prevId) => (prevId === id ? null : id));
    toggleIconRotation(id);
  };

  const toggleIconRotation = (id) => {
    const icon = document.getElementById(`icon-${id}`);
    if (icon) {
      icon.classList.toggle("rotateicon");
    }
  };

  const handleCheckboxChange = (optionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      const optionSelectedPermissions = prevSelectedOptions[optionId] || [];

      // Check if all permission are already selected
      const allPermissionsSelected =
        optionSelectedPermissions.length ===
        options.find((opt) => opt.id === optionId).permission.length;

      const updatedPermissions = allPermissionsSelected
        ? [] // If all permission are already selected, clear the array
        : options
            .find((opt) => opt.id === optionId)
            .permission.map((permission) => permission.value);

      return {
        ...prevSelectedOptions,
        [optionId]: updatedPermissions,
      };
    });
  };

  const handlePermissionChange = (optionId, permissionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      const optionSelectedPermissions = prevSelectedOptions[optionId] || [];
      const updatedPermissions = optionSelectedPermissions.includes(
        permissionId
      )
        ? optionSelectedPermissions.filter((perm) => perm !== permissionId)
        : [...optionSelectedPermissions, permissionId];

      return {
        ...prevSelectedOptions,
        [optionId]: updatedPermissions,
      };
    });
  };

  const CustomRolesArray = [
    { label: "Select Option", value: "" },
    // { label: "Admin", value: "owner" },
    { label: "Front Desk", value: "front_desk" },
    { label: "Housekeeping Manager", value: "housekeeping_manager" },
    { label: "Housekeeping Staff", value: "housekeeping_staff" },
    { label: "Food and Beverage (F&B)", value: "f_and_b" },
    { label: "Chef", value: "chef_f_and_b" },
    { label: "Steward", value: "steward_f_and_b" },
    { label: "Store", value: "store" },
    { label: "Account & Finance", value: "account_finance" },
  ];

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedOptions({});
    } else {
      const allSelected = {};
      options.forEach((option) => {
        allSelected[option.id] = option.permission.map(
          (permission) => permission.value
        );
      });
      setSelectedOptions(allSelected);
    }
    setSelectAll(!selectAll);
  };

  const sendDataToAPI = () => {
    const sendData = {};
    options.forEach((option) => {
      if (selectedOptions[option.id] && selectedOptions[option.id].length > 0) {
        sendData[option.name] = selectedOptions[option.id];
      }
    });

    // Now you can send the sendData object to your API
    // You can use fetch or any other method to send the data to your API here
  };

  const [staffPermissions, setStaffPermissions] = useState();

  const sendDataInDesiredFormat = (selectedOptions) => {
    const permissionValues = [];

    for (const optionId in selectedOptions) {
      if (selectedOptions.hasOwnProperty(optionId)) {
        if (optionId !== "create_reservation") {
          const selectedPermissions = selectedOptions[optionId];
          if (Array.isArray(selectedPermissions)) {
            selectedPermissions.forEach((permission) => {
              permissionValues.push(permission);
            });
          }
        }
      }
    }

    return permissionValues;
  };

  const selectedPermissions = sendDataInDesiredFormat(selectedOptions);

  // Calculate totalItems as the sum of all permission across options
  const totalItems = options.reduce(
    (total, option) => total + option.permission.length,
    0
  );

  // Calculate totalItemsSelected as the sum of selected permission across options
  const totalItemsSelected = Object.values(selectedOptions).reduce(
    (total, selectedPermissions) => total + selectedPermissions.length,
    0
  );

  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  //
  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const [selectedRole, setSelectedRole] = useState(null);

  const handleRoleChange = (role) => {
    setSelectedRole(role);
  };

  const handleSubmitAddTeam = (e) => {
    e.preventDefault();

    if (addTeamData.name === "") {
      showErrorToast("Please select a role first...");
      return;
    }
    // property/roles/create/{property_id}
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/property/roles/create/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addTeamData,
          permission: selectedPermissions,
          // role: selectedRole,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchInfo(property_id?.id);
          showSuccessToast(data.message);
          setAddTeamData({
            name: "",
            email: "",
          });
          setShowAddStaffPopup(false);
        } else {
          const firstErrorKey = Object.keys(data.data)[0];
          const firstErrorMessage = data.data[firstErrorKey][0];
          showErrorToast(firstErrorMessage);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  return (
    <>
      <div className="setting_location_sec">
        <LegacyCard>
          <div
            style={{ paddingLeft: "15px", paddingRight: "15px" }}
            className="room-types-full-container-div"
          >
            <div style={{ marginTop: "20px" }} className="room-type-heading">
              Roles
            </div>

            <div style={{ marginTop: "20px" }}>
              <BgOutlineThemeButton
                children={"Add Role"}
                onClick={handleshowAddStaffPopup}
              />
            </div>
          </div>
          {combinedTableRows && combinedTableRows.length > 0 ? (
            <DataTable
              columnContentTypes={["text", "text", "text"]}
              headings={["Role Name", " ", " "]}
              rows={combinedTableRows}
              verticalAlign="center"
            />
          ) : (
            <NoDataFound />
          )}
        </LegacyCard>
      </div>

      {showAddStaffPopup && (
        <div className="bg-backdrop">
          {/* <div
            style={{ width: "700px" }}
            className="dashboard_checkin_modal_container"
          > */}
          <form
            onSubmit={(e) => e.preventDefault()}
            style={{ width: "700px" }}
            className="stock-add-model-container"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <div className="add-ota-model-container-heading">Add Role</div>
              <div style={{ cursor: "pointer" }}></div>
            </div>

            <div className="form-container-div-half">
              <div className="form-container-div">
                <VerticalInputSelectCustomCopy
                  options={CustomRolesArray}
                  required={true}
                  titleName="Role Name"
                  name="name"
                  type="text"
                  value={addTeamData.name}
                  onChange={setAddTeamDataHandleChange}
                />
              </div>
              {/* <div className="form-container-div">
                <VerticalInputFieldCustom
                  required={true}
                  titleName="Email"
                  name="email"
                  type="email"
                  value={addTeamData.email}
                  onChange={setAddTeamDataHandleChange}
                />
              </div> */}
            </div>

            {/* <div>
              <h1>Select Options</h1>
              <label>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />{" "}
                Select All
              </label>
              <br />
              <br />
              <div style={{ display: "flex", flexDirection: "column" }}>
                {options.map((option) => (
                  <div
                    key={option.id}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>
                      <input
                        type="checkbox"
                        checked={
                          selectedOptions[option.id] &&
                          selectedOptions[option.id].length ===
                            option.permission.length
                        }
                        onChange={() => handleCheckboxChange(option.id)}
                      />
                      {option.name}
                    </label>

                    {option.permission.map((permission) => (
                      <label key={permission.id}>
                        <input
                          type="checkbox"
                          checked={
                            selectedOptions[option.id] &&
                            selectedOptions[option.id].includes(
                              permission.value
                            )
                          }
                          onChange={() =>
                            handlePermissionChange(option.id, permission.value)
                          }
                        />{" "}
                        {permission.label}
                      </label>
                    ))}
                  </div>
                ))}
              </div>
              <button onClick={sendDataToAPI}>Send Data to API</button>
            </div> */}

            {/* {permissions && permissions.includes("assign_permissions") && (
              <>
                <div
                  className="permission_container"
                  style={{ borderBottom: "0.5px solid #ccc" }}
                >
                  <h1 className="permission_heading" style={{ margin: "10px" }}>
                    Roles
                  </h1>
                  <div
                    className="permission_checkBox_container"
                    style={{ paddingBottom: "0" }}
                  ></div>
                  <div
                    style={{ display: "flex", gap: "15px", padding: "10px" }}
                  >
                    <label className="flex" style={{ gap: "3px" }}>
                      <input
                        type="radio"
                        name="role"
                        value="front_desk"
                        checked={selectedRole === "front_desk"}
                        onChange={() => handleRoleChange("front_desk")}
                      />
                      <div>Front Desk</div>
                    </label>
                    <label className="flex" style={{ gap: "3px" }}>
                      <input
                        type="radio"
                        name="role"
                        value="housekeeping_manager"
                        checked={selectedRole === "housekeeping_manager"}
                        onChange={() => handleRoleChange("housekeeping_manager")}
                      />
                      <div>Housekeeping Manager</div>
                    </label>
                    <label className="flex" style={{ gap: "3px" }}>
                      <input
                        type="radio"
                        name="role"
                        value="f_and_b"
                        checked={selectedRole === "f_and_b"}
                        onChange={() => handleRoleChange("f_and_b")}
                      />
                      <div>F & B</div>
                    </label>
                    <label className="flex" style={{ gap: "3px" }}>
                      <input
                        type="radio"
                        name="role"
                        value="store"
                        checked={selectedRole === "store"}
                        onChange={() => handleRoleChange("store")}
                      />
                      Store
                    </label>
                    <label className="flex" style={{ gap: "3px" }}>
                      <input
                        type="radio"
                        name="role"
                        value="account_finance"
                        checked={selectedRole === "account_finance"}
                        onChange={() => handleRoleChange("account_finance")}
                      />
                      Acc & Finance
                    </label>
                    <label className="flex" style={{ gap: "3px" }}>
                      <input
                        type="radio"
                        name="role"
                        value="agent"
                        checked={selectedRole === "agent"}
                        onChange={() => handleRoleChange("agent")}
                      />
                      Agent
                    </label>
                  </div>
                </div>
              </>
            )} */}

            {/* {permissions && permissions.includes("assign_permissions") && ( */}
            {/* <>
              <div className="permission_container">
                <h1 className="permission_heading">Role Permissions</h1>
                <div className="permission_checkBox_container  ">
                  <label>
                    <input
                      style={{ marginRight: "10px", marginLeft: "10px" }}
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                    Select All
                  </label>
                </div>
                <div className="permission_item-accords">
                  {options.map((option) => (
                    <div
                      // onClick={() => toggleAccordion(option.id)}
                      style={{ padding: "10px" }}
                      key={option.id}
                      className={`accordion-item ${
                        activeAccordion === option.id ? "active" : ""
                      }`}
                    >
                      <div
                        // style={{ cursor: "pointer" }}
                        className="addstaff_accordion-title"
                        onClick={() => toggleAccordion(option.id)}
                        style={{
                          backgroundColor: "rgba(235, 236, 239, 1)",
                          cursor: "pointer",
                          width: "100%",
                          padding: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={
                              selectedOptions[option.id] &&
                              selectedOptions[option.id].length ===
                                option.permission.length
                            }
                            onChange={() => handleCheckboxChange(option.id)}
                          />
                          <span
                            style={{ marginLeft: "10px", fontSize: "14px" }}
                          >
                            {option.name}
                          </span>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <div>
                            (
                            {selectedOptions[option.id]
                              ? selectedOptions[option.id].length
                              : 0}
                            /{option.permission.length})
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                          >
                            <path
                              d="M11 1.5L6 6.5L0.999999 1.5"
                              stroke="#888888"
                              strokeWidth="1.5"
                            />
                          </svg>
                        </div>
                      
                      </div>
                      <div className="addstaff_accordion-content">
                        {activeAccordion === option.id && (
                          <div>
                            {option.permission.map((permission) => (
                              <div
                                key={permission.id}
                                className="addstaff_secondCkeckbox_container"
                              >
                                <label
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    width: "fit-content",
                                  }}
                                  key={permission.id}
                                >
                                  <input
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    type="checkbox"
                                    checked={
                                      selectedOptions[option.id] &&
                                      selectedOptions[option.id].includes(
                                        permission.value
                                      )
                                    }
                                    onChange={() =>
                                      handlePermissionChange(
                                        option.id,
                                        permission.value
                                      )
                                    }
                                    className="addstaff_secondCkeckbox"
                                  />
                                  <div>{permission.label}</div>
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </> */}
            {/* )} */}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  gap: "10px",
                }}
              >
                <BgThemeButton
                  onClick={(e) => handleSubmitAddTeam(e)}
                  type="submit"
                  children={"Add"}
                />
                <Button onClick={handleshowAddStaffPopup}>Cancel</Button>
              </div>
            </div>
          </form>
        </div>
      )}

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                >
                  Discard
                </Button>
                <Button onClick={() => deleteData(deleteStaffId)} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default RolesCrudComponent;
