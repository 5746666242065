import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
const ReportAccountBalanceTable = ({ departureReport }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",

    //   { title: "Booking ID" },d
    //   { title: "Guest Name" },d
    //   { title: "Arrival Date" },d
    //   { title: "Departure Date" },d
    //   { title: "Source" },
    //   { title: "Grand Total" },
    //   { title: "Amount Paid" },
    //   { title: "Balance" },
    //   { title: "Status" },
  };
  const extractedData = departureReport?.map((item) => ({
    unique_booking_id: item?.unique_booking_id,
    guest_name: item?.guest[0]?.guest_name,
    check_in: item?.check_in,
    check_out: item?.check_out,
    booking_source: item?.booking_source?.replace(/_/g, " "),
    grand_total: item?.grand_total,
    paid_amount: item?.paid_amount,
    balance_amount: item?.balance_amount,
    status: item?.status?.replace(/_/g, " "),
  }));

  console.log(extractedData);

  const names = [
    {
      unique_booking_id: "Booking ID",
      guest_name: "Guest Name",
      check_in: "Arrival Date",
      check_out: "Departure Date",
      booking_source: "Booking Source",
      grand_total: "Grand Total",
      paid_amount: "Paid Amount",
      grand_total: "Balance",
      status: "Status",
    },
  ];
  const rowMarkup = departureReport?.map((order) => (
    <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
      <CustomReportTableCell>{order.unique_booking_id}</CustomReportTableCell>
      <CustomReportTableCell>
        {order?.guest[0]?.guest_name}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {" "}
        <TextDateFormatter as={"span"} showYear={true} date={order.check_in} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        <TextDateFormatter as={"span"} showYear={true} date={order.check_out} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        <div style={{ textTransform: "capitalize" }}>
          {order &&
            order.booking_source &&
            order?.booking_source.replace(/_/g, " ")}
        </div>
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.items?.map((item, index) => (
          <span key={index}>
            {item.room_type_name}
            {index < order.items.length - 1 && ', '}
          </span>
        ))}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.items?.map((item, index) => (
          <span key={index}>
            {item.no_nights}
            {index < order.items.length - 1 && ', '}
          </span>
        ))}
      </CustomReportTableCell>
      <CustomReportTableCell>{order?.guest?.length}</CustomReportTableCell>

      <CustomReportTableCell>{order?.paid_amount}</CustomReportTableCell>
      <CustomReportTableCell>{order.balance_amount}</CustomReportTableCell>
      <CustomReportTableCell>{order?.grand_total}</CustomReportTableCell>
      <CustomReportTableCell>
        <StatusUpdate status={order.status} /> { }
      </CustomReportTableCell>
    </CustomReportTableRow>
  ));

  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Account Balance Report</div>
          {departureReport?.length > 0 && (
            <div className="flex flex_gap_15" id="doNotPrint">
              <DownloadPdfTables tableData={extractedData} tableNames={names} downloadFileName="AccountBalanceReport" />

              {/* <ButtonToDownloadTablePDF  downloadFileName="AccountBalanceReport" itemToDownloadID={"receipt"} ignoreFromPrint={"doNotPrint"}/> */}
              <DownloadCSVButton
                downloadFileName="AccountBalanceReport" itemToDownloadID={"receipt"}

              />
            </div>
          )}
        </div>

        {departureReport && departureReport.length > 0 ? (
          <>
            {/* <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={departureReport.length}
                selectable={false}
                headings={[
                  { title: "Booking ID" },
                  { title: "Guest Name" },
                  { title: "Arrival Date" },
                  { title: "Departure Date" },
                  { title: "Source" },
                  { title: "Grand Total" },
                  { title: "Amount Paid" },
                  { title: "Balance" },
                  { title: "Status" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard> */}

            <CustomReportTable
              headings={[
                "Booking ID",
                "Guest Name",
                "Check In Date",
                "Check Out Date",
                "Booking Source",
                "Room Type",
                "No Of Nights",
                "No Of Guests",
                "Paid Amount",
                "Balance",
                "Grand Total",
                "Status",
              ]}
              // commonHeadings={[
              //   "",
              //   "",
              //   "",
              //   "",
              //   "Quantity",
              //   "Quantity",
              //   "Quantity",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              // ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "left",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {/* {calculateTotals} */}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};
export default ReportAccountBalanceTable;