import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../CustomComponents/NoDataFound";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import LoaderSmall from "../UI/Loader/LoaderSmall";

const ActivityLogs = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const [selected, setSelected] = useState(0);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const property_id = useSelector((state) => state.property.value);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const [orders, setOrders] = useState([]);

  const fetchInfo = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/activity/logs/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setOrders(data.activity);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
    }
  }, [property_id]);

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const rowMarkup = orders.map(
    ({
      id,
      change_done_by,
      created_at,
      updated_at,
      description,
      event,
      subject_type,
      index,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{change_done_by}</IndexTable.Cell>

        <IndexTable.Cell>
          <TextDateFormatter as={"span"} showYear={true} date={created_at} />
        </IndexTable.Cell>

        <IndexTable.Cell>
          <TextDateFormatter as={"span"} showYear={true} date={updated_at} />
        </IndexTable.Cell>

        <IndexTable.Cell>
          {description}
          {/* <CustomCssComponentTableText
                        innerText={guest_state}
                    ></CustomCssComponentTableText> */}
        </IndexTable.Cell>

        <IndexTable.Cell>{event}</IndexTable.Cell>
        <IndexTable.Cell>{subject_type}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  return (
    <>
      <div className="setting_location_sec">
        <div className="room-type-heading">Activity Logs</div>
        {loader ? (
          <LoaderSmall />
        ) : (
          <>
            {orders && orders.length > 0 ? (
              <LegacyCard>
                <IndexTable
                  selectable={false}
                  resourceName={resourceName}
                  itemCount={orders.length}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: "Change Done By" },
                    { title: "Created At" },
                    { title: "Updated At" },
                    { title: "Description" },
                    { title: "Event" },
                    { title: "Subject Type" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
              </LegacyCard>
            ) : (
              <NoDataFound />
            )}
          </>
        )}
      </div>
    </>
  );
};
export default ActivityLogs;
