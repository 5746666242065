import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";

const ReportInvoiceSettlement = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkupAllFoliosCustom = departureReport?.map(
    ({ id, count, order_no, order_type, net_total, date, index }) => {
      return (
        <CustomIndexTableRow
          id={id}
          key={id}
          // selectedItems={selectedArray} // send for checking the check box is checked
          // setSelectedItems={setSelectedArray}
          showCheckbox={false} // show the check box
        >
          <CustomIndexTableCell>{id}</CustomIndexTableCell>

          <CustomIndexTableCell>{count}</CustomIndexTableCell>
          <CustomIndexTableCell>
            {formatIndianCurrency(id)}
          </CustomIndexTableCell>
        </CustomIndexTableRow>
      );
    }
  );


  const calculateTotal = (items, key) => {
    return 0;
  };
  const handleDownload = () => {
    const html2pdf = require("html2pdf.js");
    const originalTable = document.getElementById("receipt");
    const clonedTable = originalTable.cloneNode(true);

    clonedTable.style.fontSize = "12px";
    clonedTable.style.fontFamily = "Arial, sans-serif";
    clonedTable.style.lineHeight = "1.5";
    clonedTable.style.margin = "auto";

    const tempContainer = document.createElement("div");
    tempContainer.appendChild(clonedTable);

    html2pdf()
      .from(tempContainer)
      .set({
        margin: [0.5, 0.5, 0.5, 0.5], // Adjust margin as needed
        filename: "receipt.pdf",
        image: { type: "jpeg", quality: 5 }, // Use higher quality for images
        html2canvas: { scale: 1 }, // Increase scale for higher resolution
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" }, // Adjust PDF format and orientation as needed
      })
      .save();
  };

  const downloadCSV = () => {
    // Prepare CSV content
    const csvHeaders = "Category,Item,Count,Sale";
    const csvContent = `${csvHeaders}\n${departureReport
      .map((category) =>
        category.items
          .map((item) =>
            [category.category, item.item, item.count, item.sale].join(",")
          )
          .join("\n")
      )
      .join("\n")}`;

    // Create a blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element to trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // console.log(departureReport);

  const rowMarkup = departureReport?.map(
    ({
      id,

      MTD_due_amount,
      MTD_paid_amount,
      MTD_purchase_amount,
      QTD_due_amount,
      QTD_paid_amount,
      QTD_purchase_amount,
      YTD_due_amount,
      YTD_paid_amount,
      YTD_purchase_amount,
      supplier_gst_no,
      supplier_name,
      invoice_no,
      purchase_date,
      grand_total,
      paid_amount,
      due_amount,
      payment_status,
    }) => (
      <CustomReportTableRow
        CustomReportTableRow
        id={id}
        key={id}
        // bodyStyles={{
        //   backgroundColor: "red",
        //   fontSize: "10px",
        // }}
      >
        <CustomReportTableCell>{invoice_no}</CustomReportTableCell>
        <CustomReportTableCell>{supplier_name}</CustomReportTableCell>
        <CustomReportTableCell>{supplier_gst_no}</CustomReportTableCell>
        <CustomReportTableCell>
          {
            <TextDateFormatter
              date={purchase_date}
              as={"div"}
              showYear={true}
            />
          }
        </CustomReportTableCell>

        <CustomReportTableCell>
          {formatIndianCurrency(grand_total)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(paid_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(due_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {<StatusUpdate status={payment_status} />}
        </CustomReportTableCell>
      </CustomReportTableRow>
    )
  );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "grand_total")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "paid_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "due_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
    </CustomReportTableRow>
  );
// ........................
const extractedData = departureReport?.map(item => ({
  invoice_no: item && item?.invoice_no ? item?.invoice_no : 'N/A',
  supplier_name: item &&  item?.supplier_name ? item?.supplier_name : 'N/A',
  supplier_gst_no: item &&  item?.supplier_gst_no ? item?.supplier_gst_no  : 'N/A',
  purchase_date: item &&  item?.purchase_date ? item?.purchase_date : 'N/A',
  grand_total: item &&  item?.grand_total ?  item?.grand_total : 'N/A',
  paid_amount: item && item?.paid_amount ? item?.paid_amount : 'N/A',
  due_amount: item && item?.due_amount ? item?.due_amount : 'N/A',
  payment_status: item && item?.payment_status ? item?.payment_status : 'N/A',
  
}));

const names = [
  {
    invoice_no: "Invoice No.",
    supplier_name: "Supplier Name",
    supplier_gst_no: "GST No",
    purchase_date: "Date Of Purchase	",
    grand_total: "Purchase Amount (₹)	",
    paid_amount:"Paid Amount (₹)	",
    due_amount:"Due Amount (₹)	",
    payment_status:"Settlement Status",
  },
];
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div  id="receipt" className="room-types-full-container reports-new-background">
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="room-type-heading">
                Invoice Settlement Report{" "}
              </div>
              {departureReport.length > 0 && (
                <div className="flex flex_gap_15 align_center" id="pdf">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Invoice Settlement Report"}
                    ignoreFromPrint={"pdf"}
                    // pageFormat={'A3'}
                    // pdfStyles={

                    // }
                  />
                  {/* <DownloadCsvTables tableData={extractedData} downloadCSV={downloadCSV} names={names} /> */}
                  <DownloadCSVButton itemToDownloadID={"receipt"}
                    downloadFileName={"Invoice_Settlement_Report"}/>
                </div>
              )}
            </div>
          </div>
          <div>
            {departureReport && departureReport.length > 0 ? (
              <>
                {/* {departureReport &&
                  departureReport.length > 0 &&
                  departureReport.map((order) => {
                    return (
                      <div className="mt-20">
                        <div
                          className="category_name"
                          style={{ marginBottom: "10px" }}
                        >
                          {order.category}
                        </div> */}

                <CustomReportTable
                  headings={[
                    "Invoice Number",
                    "Supplier Name",
                    "GST No",
                    "Date Of Purchase",
                    "Purchase Amount (₹)",
                    "Paid Amount (₹)",
                    "Due Amount (₹)",
                    "Settlement Status",
                  ]}
                  // commonHeadings={[
                  //   "",
                  //   "",
                  //   "Purchase Amount (₹)",
                  //   "Purchase Amount (₹)",
                  //   "Purchase Amount (₹)",
                  //   "Paid Amount (₹)",
                  //   "Paid Amount (₹)",
                  //   "Paid Amount (₹)",
                  //   "Due Amount (₹)",
                  //   "Due Amount (₹)",
                  //   "Due Amount (₹)",
                  // ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ReportInvoiceSettlement;
