import React, { useState, useRef, useEffect } from "react";
import { showSuccessToast, showErrorToast } from "../../assets/toastUtils";
import { useSelector } from "react-redux";

const KOTMultiStatusCustomComp = ({
  status,
  onClick,
  kotId,
  getOrderListData,
  lastFive,
  clearSelection,
}) => {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);
  const [showOptions, setShowOptions] = useState(false);

  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const renderKotMultiStatusOptions = () => {
    switch (status) {
      case "new_kot":
        return (
          <div
            className="kotMultiStatusOptionsContainer"
            style={{ padding: "5px", fontSize: "12px" }}
          >
            <p
              onClick={(e) =>
                handleChangeStatus("preparing", kotId, property_id?.id, e)
              }
            >
              Preparing
            </p>
            <p
              onClick={(e) =>
                handleChangeStatus("prepared", kotId, property_id?.id, e)
              }
            >
              Ready
            </p>
            <p
              onClick={(e) =>
                handleChangeStatus("delivered", kotId, property_id?.id, e)
              }
            >
              Delivered
            </p>
            <p
              onClick={(e) =>
                handleChangeStatus("undelivered", kotId, property_id?.id, e)
              }
            >
              Un Delivered
            </p>
          </div>
        );
      case "preparing":
        return (
          <div
            className="kotMultiStatusOptionsContainer"
            style={{ padding: "5px", fontSize: "12px" }}
          >
            <p
              onClick={(e) =>
                handleChangeStatus("prepared", kotId, property_id?.id, e)
              }
            >
              Ready
            </p>
            <p
              onClick={(e) =>
                handleChangeStatus("delivered", kotId, property_id?.id, e)
              }
            >
              Delivered
            </p>
            <p
              onClick={(e) =>
                handleChangeStatus("undelivered", kotId, property_id?.id, e)
              }
            >
              Un Delivered
            </p>
          </div>
        );
      case "prepared":
        return (
          <div
            className="kotMultiStatusOptionsContainer"
            style={{ padding: "5px", fontSize: "12px" }}
          >
            <p
              onClick={(e) =>
                handleChangeStatus("delivered", kotId, property_id?.id, e)
              }
            >
              Delivered
            </p>
            <p
              onClick={(e) =>
                handleChangeStatus("undelivered", kotId, property_id?.id, e)
              }
            >
              Un Delivered
            </p>
          </div>
        );
      case "undelivered":
        return (
          <div
            className="kotMultiStatusOptionsContainer"
            style={{ padding: "5px", fontSize: "12px" }}
          >
            <p
              onClick={(e) =>
                handleChangeStatus("delivered", kotId, property_id?.id, e)
              }
            >
              Delivered
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  const getStatusDisplayText = (status) => {
    return status
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "new_kot":
        return "#888888";
      case "preparing":
        return "#BD941B";
      case "delivered":
        return "#15AA12";
      case "cancelled":
        return "#E03838";
      case "ready":
        return "#3968ED";
      case "prepared":
        return "#3968ED";
      case "undelivered":
        return "#888888";
      default:
        return "";
    }
  };
  const showIcon = (status) => {
    switch (status) {
      case "new_kot":
        return (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path d="M1 0.5L5 4.5L9 0.5" stroke="#fff" />
            </svg>
          </span>
        );
      case "preparing":
        return (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path d="M1 0.5L5 4.5L9 0.5" stroke="#fff" />
            </svg>
          </span>
        );
      case "delivered":
        return null;
      case "cancelled":
        return null;
      case "ready":
        return (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path d="M1 0.5L5 4.5L9 0.5" stroke="#fff" />
            </svg>
          </span>
        );
      case "prepared":
        return (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path d="M1 0.5L5 4.5L9 0.5" stroke="#fff" />
            </svg>
          </span>
        );
      case "undelivered":
        return (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path d="M1 0.5L5 4.5L9 0.5" stroke="#fff" />
            </svg>
          </span>
        );
      default:
        return "";
    }
  };

  const handleChangeStatus = async (status, kotId, propertyId, e) => {
    e.stopPropagation();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/kitchen/kots/update/status/${kotId}/${propertyId}`,
        {
          method: "POST",
          body: JSON.stringify({
            status: status,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        getOrderListData(property_id?.id);
        clearSelection();
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error(error);
    }
    // window.location.reload();
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        onClick={(e) => [setShowOptions(true), e.stopPropagation()]}
        style={{
          cursor: "pointer",
          padding: "3px 12px",
          fontSize: "12px",
          width: "fit-content",
          borderRadius: "16px",
          color: "#ffff",
          backgroundColor: getStatusBackgroundColor(status),
        }}
      >
        {status === "prepared" ? "Ready" : getStatusDisplayText(status)}
        <span style={{ marginLeft: "5px" }}>{showIcon(status)}</span>
      </div>
      {showOptions && (
        <div
          ref={popupRef}
          style={{
            backgroundColor: "#fff",
            position: "absolute",
            borderRadius: "4px",
            zIndex: "999",
            // padding:'5px',
            width: "100px",
            boxShadow:
              "0 4px 4px 0px rgba(127, 155, 179, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10)",
            bottom: lastFive ? "27px" : "auto",
          }}
        >
          {renderKotMultiStatusOptions()}
        </div>
      )}
    </div>
  );
};
export default KOTMultiStatusCustomComp;
