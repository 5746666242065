import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "./NoDataFound";
import CustomCssComponentTableText from "./CustomCssComponentTableText";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadPdfTables from "./DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "./DownloadCsvTables/DownloadCsvTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "./CustomReportComponent/CustomReportComponent";
import ButtonToDownloadTablePDF from "./DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "./DownloadCsvTables/DownloadCSVButton";

const RegionReportComp = ({ regionReportsData }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const names = [
    {
      guest_state: "Guest State",
      booking_count: "Booking Count",
      total_sale: "Total Sale",
    },
  ];
  const rowMarkup =
    regionReportsData &&
    regionReportsData?.map(
      ({ booking_count, guest_state, total_sale, state }, index) => {
        return (
          <CustomReportTableRow id={index} key={index} position={guest_state}>
            <CustomReportTableCell>{guest_state}</CustomReportTableCell>
            <CustomReportTableCell>{booking_count}</CustomReportTableCell>

            <CustomReportTableCell>
              {total_sale ? formatIndianCurrency(total_sale) : "0"}
            </CustomReportTableCell>
          </CustomReportTableRow>
        );
      }
    );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>

      <CustomReportTableCell>
        {calculateTotalWithReduce(regionReportsData, "booking_count")}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(regionReportsData, "total_sale")
        )}
      </CustomReportTableCell>
    </CustomReportTableRow>
  );

  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Reservation By Region</div>
          {regionReportsData?.length > 0 && (
            <div className="flex flex_gap_15" id="pdf">
              <ButtonToDownloadTablePDF
                itemToDownloadID={"receipt"}
                downloadFileName={"Reservation By Region"}
                ignoreFromPrint={"pdf"}
                // pdfStyles={

                // }
              />
              <DownloadCSVButton  itemToDownloadID={"receipt"}
                downloadFileName={"Reservation By Region"}/>
            </div>
          )}
        </div>

        {regionReportsData && regionReportsData.length > 0 ? (
          <>
            {/* <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={regionReportsData.length}
                selectable={false}
                headings={[
                  { title: "Guest State" },
                  { title: "Booking Count" },
                  { title: "Total Sale" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard> */}

            <CustomReportTable
              headings={["Guest State", "Booking Count", "Total Sale"]}
              // commonHeadings={[
              //   "",
              //   "",
              //   "",
              //   "Purchased",
              //   "Purchased",
              //   "Issued",
              //   "Issued",
              //   "Stock",
              //   "Stock",
              // ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "center",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {calculateTotals}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};
export default RegionReportComp;
