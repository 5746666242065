import { useState, useEffect, useRef } from "react";
import "./BookingDetailsSlider.css";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  WithoutBgButtonBlue,
} from "../../UI/Buttons";
import UnderscoreRemoveCapitalize from "../UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import { useSelector } from "react-redux";
import { showErrorToast } from "../../../assets/toastUtils";
import { showSuccessToast } from "../../../assets/toastUtils";
import VerticalTextAreaCustom from "../VerticalTextAreaCustom";
import { Button } from "@shopify/polaris";
import DeletePopupWithReasonBooking from "../DeletePopup/DeletePopupWithReasonBooking";

const BookingDetailSliderNew = ({
  data,
  setShowBookingDetails,
  chargesDetails,
}) => {
  const [openPrintPopup, setOpenPrintPopup] = useState(false);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const [openMoreOptionsPopup, setOpenMoreOptionsPopup] = useState(false);
  const [openCheckedInMoreOptionsPopup, setOpenCheckedInMoreOptionsPopup] =
    useState(false);

  function handlePrintPopup() {
    setOpenPrintPopup(!openPrintPopup);
  }
  function handleEmailPopup() {
    setOpenEmailPopup(!openEmailPopup);
  }
  function handleMoreOptionsPopup() {
    setOpenMoreOptionsPopup(!openMoreOptionsPopup);
  }
  function handleCheckedInMoreOptionsPopup() {
    setOpenCheckedInMoreOptionsPopup(!openCheckedInMoreOptionsPopup);
  }
  const popupsRef = useRef(null);
  const bookingDetailsPopupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupsRef.current && !popupsRef.current.contains(event.target)) {
        setOpenPrintPopup(false);
        setOpenEmailPopup(false);
        setOpenMoreOptionsPopup(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        bookingDetailsPopupRef.current &&
        !bookingDetailsPopupRef.current.contains(event.target)
      ) {
        setShowBookingDetails(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const formattedDate = new Date(data?.created_at).toLocaleString("en-US", {
    dateStyle: "medium",
    timeStyle: "short",
  });
  // ............Cancel Booking.............
  const [loader, setLoader] = useState(true);
  const [cancelReason, setCancelReason] = useState("");
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [bookings, setBookings] = useState([]);
  const [bookingOuterDetails, setBookingOuterDetails] = useState([]);
  const [cancelBookingId, setCancelBookingId] = useState("");
  const [dayTypeFilter, setDayTypeFilter] = useState("-1");
  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: "",
    to_date: "",
  });
  const [selectedBookingStatus, setSelectedBookingStatus] = useState([]);
  const [selectedDateFilter, setSelectedDateFilter] = useState([]);
  const property_id = useSelector((state) => state.property.value);
  const fetchInfo = async (PropertyId, from_date, to_date) => {
    setLoader(true);
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${PropertyId}`,
      {
        method: "POST",
        body: JSON.stringify({
          booking_status: selectedBookingStatus,
          date_filters: selectedDateFilter,
          booking_id: bookingId,
          dayTypeFilter: dayTypeFilter,
          from_date: from_date,
          to_date: to_date,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.status) {
          setBookings(data.Bookings);
          setBookingOuterDetails(data);
          showSuccessToast(data.message);
          setLoader(false);
          // navigate("/bookings");
        } else {
          showErrorToast(data.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
        setLoader(false);
      });
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(
        property_id.id,
        selectedDatesRange.from_date,
        selectedDatesRange.to_date,
        selectedDateFilter,
        selectedBookingStatus,
        dayTypeFilter
      );
    }
  }, [property_id, selectedDateFilter, selectedBookingStatus, dayTypeFilter]);

  const cancelBookingHandle = (bookingId) => {
    // e.preventDefault();
    // const credentials = { email: email, password: password };
    // http://127.0.0.1:8000/api/v1/get/booking/9a0783fb-213b-4f31-ad9d-a5605d8672a4
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/booking/cancelled/${bookingId}/${property_id?.id}?cancelReason=${cancelReason}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setLoader(false);
          fetchInfo(property_id.id);
          setShowModelMenuActive(false);
        } else {
          // setSingleBookingDetail(data.booking);
          // setShowBookingDetails(true);
          // showSuccessToast("Data Fetched Successfully");
          showSuccessToast(data.message);
          setShowModelMenuActive(false);
          // setOpenSingleBookingDetails(!openSingleBookingDetails);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
    setShowBookingDetails(false);
    window.location.reload();
  };

  function handleCancelBooking() {
    setShowModelMenuActive(true);
    // setCancelBookingId(item.id)
  }

  // ...................Remove Reservation..........
  const [reasonFromChild, setReasonFromChild] = useState("");
  const [uniqueBookingId, setUniqueBookingId] = useState(false);
  const [deleteWithReasonPopup, setDeleteWithReasonPopup] = useState(false);
  const [deleteKotId, setDeleteKotId] = useState("");
  function handleRemoveReservation() {
    setDeleteWithReasonPopup(true);
  }
  const handleDeleteWithReason = (reason) => {
    setReasonFromChild(reason);
  };
  const handleCancelStatusButton = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/delete/booking/${cancelBookingId}/${property_id?.id}`,
        {
          method: "DELETE",
          body: JSON.stringify({
            type: reasonFromChild.reason,
            duplicate_booking_id: uniqueBookingId,
            original_booking_id: reasonFromChild.originalBookingId,
            remarks: reasonFromChild.otherReason,
            property_id: property_id?.id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        setDeleteWithReasonPopup(false);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
    setShowBookingDetails(false);
    window.location.reload();
  };
  // .........No show.........
  const [noShowData, setNoShowData] = useState();

  const handleNoShowData = (status, bookingId) => {
    // setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/update/booking_status/${bookingId}/${status}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          window.location.reload();
        } else {
          showErrorToast(data.message);
        }
      });
  };
  return (
    <div className="bg-backdrop">
      <div
        id="booking_details_slider_container_new"
        ref={bookingDetailsPopupRef}
      >
        <div class="popup" id="popup">
          <div className="d_flex booking_detail_popup_head">
            <div
              onClick={() => setShowBookingDetails(false)}
              className="close_popup"
            >
              <svg
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 12.5L13 0.5" stroke="#AAAAAA" stroke-width="1.2" />
                <path
                  d="M13 12.5L0.999999 0.500001"
                  stroke="#AAAAAA"
                  stroke-width="1.2"
                />
              </svg>
            </div>
            <div className="d_flex booking_id">
              <h3>
                Folio ID <strong>#1234</strong>
              </h3>
              <h3>
                Booking ID <strong>#1234</strong>
              </h3>
            </div>
          </div>

          {data?.status === "confirmed" ? (
            <div className="">
              <div className="d_flex common_padding booking_details_options">
                <button className="booking_popup_btns view_folio_btn">
                  Assign Room
                </button>
                <button
                  className="booking_details_options_btns print_btn items_center"
                  onClick={handlePrintPopup}
                >
                  {" "}
                  <svg
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9998 3.33333H3.99984V0H13.9998V3.33333ZM13.9998 7.91667C14.2359 7.91667 14.434 7.83667 14.594 7.67667C14.754 7.51667 14.8337 7.31889 14.8332 7.08333C14.8332 6.84722 14.7532 6.64917 14.5932 6.48917C14.4332 6.32917 14.2354 6.24944 13.9998 6.25C13.7637 6.25 13.5657 6.33 13.4057 6.49C13.2457 6.65 13.1659 6.84778 13.1665 7.08333C13.1665 7.31944 13.2465 7.5175 13.4065 7.6775C13.5665 7.8375 13.7643 7.91722 13.9998 7.91667ZM12.3332 13.3333V10H5.6665V13.3333H12.3332ZM13.9998 15H3.99984V11.6667H0.666504V6.66667C0.666504 5.95833 0.909559 5.36444 1.39567 4.885C1.88178 4.40556 2.47206 4.16611 3.1665 4.16667H14.8332C15.5415 4.16667 16.1354 4.40639 16.6148 4.88583C17.0943 5.36528 17.3337 5.95889 17.3332 6.66667V11.6667H13.9998V15Z"
                      fill="#3968ED"
                    />
                  </svg>
                  Print{" "}
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L11 1"
                      stroke="#3968ED"
                      stroke-width="1.5"
                    />
                  </svg>
                </button>

                <button
                  className="booking_details_options_btns items_center email_btn"
                  onClick={handleEmailPopup}
                >
                  <svg
                    width="19"
                    height="13"
                    viewBox="0 0 19 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 2.1584V11.6071C0 12.3764 0.623603 13 1.39286 13H17.1786C17.9478 13 18.5714 12.3764 18.5714 11.6071V2.15831L9.75352 7.30209C9.4644 7.47075 9.10688 7.47075 8.81776 7.30209L0 2.1584Z"
                      fill="#3968ED"
                    />
                    <path
                      d="M18.0545 0.309833C17.8152 0.116071 17.5104 0 17.1786 0H1.39286C1.06095 0 0.756158 0.116092 0.516872 0.309885L9.28564 5.425L18.0545 0.309833Z"
                      fill="#3968ED"
                    />
                  </svg>
                  Email{" "}
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L11 1"
                      stroke="#3968ED"
                      stroke-width="1.5"
                    />
                  </svg>
                </button>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Overview</h3>
              </div>
              <div className="booking_details d_flex common_padding">
                <div className="booking_details_col">
                  <div>
                    <h3>Guest Name</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_name) || 0}
                    </p>
                  </div>
                  <div>
                    <h3>Booking Date</h3>
                    <p>{formattedDate}</p>
                  </div>
                  <div>
                    <h3>Meal Plan</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items
                          .map((meal_item) => meal_item?.room_plan)
                          .join(" ")}
                    </p>
                  </div>
                  {/* <div>
                                    <h3>Child w/o Bed</h3>
                                    <p>0</p>
                                </div> */}
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Phone</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_phone) || 0}
                    </p>
                  </div>
                  <div>
                    <h3>Check-In Date</h3>
                    <p>{data?.check_in}</p>
                  </div>
                  <div>
                    <h3>No of Adults</h3>
                    {/* <p> {(data &&
                                        data.items &&
                                        data?.items[0]?.no_adult) ||
                                        0}</p> */}
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) => Number(acc) + Number(item?.no_adult),
                          0
                        )}
                    </p>
                  </div>
                  {/* <div>
                                    <h3>Extra Adults</h3>
                                    <p>0</p>
                                </div> */}
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Email</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_email) || 0}
                    </p>
                  </div>
                  <div>
                    <h3>Check-Out Date</h3>
                    <p>{data?.check_out}</p>
                  </div>
                  {/* <div>
                                    <h3>Child with Bed</h3>
                                    <p>1</p>
                                </div> */}
                  <div>
                    <h3>Booking Status</h3>
                    <p style={{ color: "#3A68ED" }}>
                      {<UnderscoreRemoveCapitalize text={data?.status} />}
                    </p>
                  </div>
                </div>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Inclusion List</h3>
              </div>
              <div className="common_padding">
                <div>
                  {data.addons.length < 1 ? (
                    <>No addons added.</>
                  ) : (
                    <>
                      {data?.addons?.map((addon, index) => (
                        <div className="d_flex booking_detail_popup_inclusion_list">
                          <p>{addon?.item_name}</p>
                          {/* <div>
                                      <svg
                                          width="11"
                                          height="11"
                                          viewBox="0 0 11 11"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <g clip-path="url(#clip0_4924_7597)">
                                              <path
                                                  d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                                  fill="#888888"
                                              />
                                              <path
                                                  d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                                  fill="#888888"
                                              />
                                          </g>
                                          <defs>
                                              <clipPath id="clip0_4924_7597">
                                                  <rect width="11" height="11" fill="white" />
                                              </clipPath>
                                          </defs>
                                      </svg>
                                      <svg
                                          width="11"
                                          height="11"
                                          viewBox="0 0 11 11"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                              fill="#888888"
                                          />
                                          <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                              fill="#888888"
                                          />
                                      </svg>
                                  </div> */}
                        </div>
                      ))}
                    </>
                  )}

                  {/* <div>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_4924_7597)">
                                        <path
                                            d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                            fill="#888888"
                                        />
                                        <path
                                            d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                            fill="#888888"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4924_7597">
                                            <rect width="11" height="11" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                        fill="#888888"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                        fill="#888888"
                                    />
                                </svg>
                            </div> */}
                </div>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Price Breakup </h3>
              </div>
              {/* <div className="booking_details d_flex common_padding">
                            <div className="booking_details_col">

                                {data &&
                                    <div>
                                        <h3>Room</h3>
                                        <p> {chargesDetails && chargesDetails["Room Charge"] && chargesDetails["Room Charge"]?.net_total || 0}</p>
                                    </div>}


                                {chargesDetails && chargesDetails["housekeeping"] &&
                                    <div>
                                        <h3>Houekeeping</h3>
                                        <p> {chargesDetails && chargesDetails["housekeeping"] && chargesDetails["housekeeping"]?.net_total || 0}</p>
                                    </div>}

                            </div>
                            <div className="booking_details_col">
                                {chargesDetails && chargesDetails["f_and_b"] &&
                                    <div>
                                        <h3>Food & Beverages</h3>
                                        <p> {chargesDetails && chargesDetails["f_and_b"] && chargesDetails["f_and_b"]?.net_total || 0}</p>
                                    </div>}

                                {chargesDetails && chargesDetails["misc"] &&
                                    <div>
                                        <h3>Misc.</h3>
                                        <p> {chargesDetails && chargesDetails["misc"] && chargesDetails["misc"]?.net_total || 0}</p>
                                    </div>}
                            </div>
                            <div className="booking_details_col">
                                {chargesDetails && chargesDetails["room_service"] &&
                                    <div>
                                        <h3>Room Services</h3>
                                        <p> {chargesDetails && chargesDetails["room_service"] && chargesDetails["room_service"]?.net_total || 0}</p>
                                    </div>}
                                {chargesDetails && chargesDetails["add_ons"] &&
                                    <div>
                                        <h3>Add Ons</h3>
                                        <p> {chargesDetails && chargesDetails["add_ons"] && chargesDetails["add_ons"]?.net_total || 0}</p>
                                    </div>}
                            </div>
                        </div> */}
              <div className="booking_details price_breakup d_flex">
                <div className="booking_details_col">
                  <div className="total_amt">
                    <h3>Total Amt.</h3>
                    <p> {data && data?.grand_total}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="paid_amt">
                    <h3>Paid Amt.</h3>
                    <p>{data && data?.paid_amount}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="due_amt">
                    <h3>Due Amt.</h3>
                    <p>{data && data?.balance_amount}</p>
                  </div>
                </div>
              </div>
              <div className="d_flex common_padding booking_details_options booking_details_options_bottom_container">
                {/* <BgThemeButton
                  children={"Modify Reservation"}
                  style={{ height: "35px" }}
                /> */}
                <BgOutlineThemeButton
                  style={{ height: "35px" }}
                  onClick={handleMoreOptionsPopup}
                  icon={
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        stroke="#3968ED"
                        stroke-width="1.5"
                      />
                    </svg>
                  }
                  children={"More Options"}
                />

                <button
                  className="booking_cancel_btn items_center"
                  onClick={handleCancelBooking}
                >
                  Cancel Booking{" "}
                </button>
              </div>
            </div>
          ) : data?.status === "checked_in" ? (
            <div className="">
              <div className="d_flex common_padding booking_details_options">
                <button className="booking_popup_btns view_folio_btn">
                  Assign Room
                </button>
                <button
                  className="booking_details_options_btns print_btn items_center"
                  onClick={handlePrintPopup}
                >
                  {" "}
                  <svg
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9998 3.33333H3.99984V0H13.9998V3.33333ZM13.9998 7.91667C14.2359 7.91667 14.434 7.83667 14.594 7.67667C14.754 7.51667 14.8337 7.31889 14.8332 7.08333C14.8332 6.84722 14.7532 6.64917 14.5932 6.48917C14.4332 6.32917 14.2354 6.24944 13.9998 6.25C13.7637 6.25 13.5657 6.33 13.4057 6.49C13.2457 6.65 13.1659 6.84778 13.1665 7.08333C13.1665 7.31944 13.2465 7.5175 13.4065 7.6775C13.5665 7.8375 13.7643 7.91722 13.9998 7.91667ZM12.3332 13.3333V10H5.6665V13.3333H12.3332ZM13.9998 15H3.99984V11.6667H0.666504V6.66667C0.666504 5.95833 0.909559 5.36444 1.39567 4.885C1.88178 4.40556 2.47206 4.16611 3.1665 4.16667H14.8332C15.5415 4.16667 16.1354 4.40639 16.6148 4.88583C17.0943 5.36528 17.3337 5.95889 17.3332 6.66667V11.6667H13.9998V15Z"
                      fill="#3968ED"
                    />
                  </svg>
                  Print{" "}
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L11 1"
                      stroke="#3968ED"
                      stroke-width="1.5"
                    />
                  </svg>
                </button>

                <button
                  className="booking_details_options_btns items_center email_btn"
                  onClick={handleEmailPopup}
                >
                  <svg
                    width="19"
                    height="13"
                    viewBox="0 0 19 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 2.1584V11.6071C0 12.3764 0.623603 13 1.39286 13H17.1786C17.9478 13 18.5714 12.3764 18.5714 11.6071V2.15831L9.75352 7.30209C9.4644 7.47075 9.10688 7.47075 8.81776 7.30209L0 2.1584Z"
                      fill="#3968ED"
                    />
                    <path
                      d="M18.0545 0.309833C17.8152 0.116071 17.5104 0 17.1786 0H1.39286C1.06095 0 0.756158 0.116092 0.516872 0.309885L9.28564 5.425L18.0545 0.309833Z"
                      fill="#3968ED"
                    />
                  </svg>
                  Email{" "}
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L11 1"
                      stroke="#3968ED"
                      stroke-width="1.5"
                    />
                  </svg>
                </button>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Overview</h3>
              </div>
              <div className="booking_details d_flex common_padding">
                <div className="booking_details_col">
                  <div>
                    <h3>Guest Name</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_name) || 0}
                    </p>
                  </div>
                  <div>
                    <h3>Booking Date</h3>
                    <p>{formattedDate}</p>
                  </div>
                  <div>
                    <h3>Meal Plan</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.map((meal_item) => meal_item?.room_plan)}
                    </p>

                    {/* <p>{data && data.items && data.items.reduce((acc, item) => acc + (item.room_plan || 0), 0)) || 0}</p> */}
                  </div>
                  {/* <div>
                                    <h3>Child w/o Bed</h3>
                                    <p>0</p>
                                </div> */}
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Phone</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_phone) || 0}
                    </p>
                  </div>
                  <div>
                    <h3>Check-In Date</h3>
                    <p>{data?.check_in}</p>
                  </div>
                  <div>
                    <h3>No of Adults</h3>
                    {/* <p> {(data &&
                                        data.items &&
                                        data?.items[0]?.no_adult) ||
                                        0}</p> */}
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) => Number(acc) + Number(item?.no_adult),
                          0
                        )}
                    </p>
                  </div>
                  {/* <div>
                                    <h3>Extra Adults</h3>
                                    <p>0</p>
                                </div> */}
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Email</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_email) || 0}
                    </p>
                  </div>
                  <div>
                    <h3>Check-Out Date</h3>
                    <p>{data?.check_out}</p>
                  </div>
                  {/* <div>
                                    <h3>Child with Bed</h3>
                                    <p>1</p>
                                </div> */}
                  <div>
                    <h3>Booking Status</h3>
                    <p style={{ color: "#15AA14" }}>
                      {<UnderscoreRemoveCapitalize text={data?.status} />}
                    </p>
                  </div>
                </div>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Inclusion List</h3>
              </div>
              <div className="common_padding">
                <div>
                  {data.addons.length < 1 ? (
                    <>No addons added.</>
                  ) : (
                    <>
                      {data?.addons?.map((addon, index) => (
                        <div className="d_flex booking_detail_popup_inclusion_list">
                          <p>{addon?.item_name}</p>
                          {/* <div>
                                      <svg
                                          width="11"
                                          height="11"
                                          viewBox="0 0 11 11"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <g clip-path="url(#clip0_4924_7597)">
                                              <path
                                                  d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                                  fill="#888888"
                                              />
                                              <path
                                                  d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                                  fill="#888888"
                                              />
                                          </g>
                                          <defs>
                                              <clipPath id="clip0_4924_7597">
                                                  <rect width="11" height="11" fill="white" />
                                              </clipPath>
                                          </defs>
                                      </svg>
                                      <svg
                                          width="11"
                                          height="11"
                                          viewBox="0 0 11 11"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                              fill="#888888"
                                          />
                                          <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                              fill="#888888"
                                          />
                                      </svg>
                                  </div> */}
                        </div>
                      ))}
                    </>
                  )}

                  {/* <div>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_4924_7597)">
                                        <path
                                            d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                            fill="#888888"
                                        />
                                        <path
                                            d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                            fill="#888888"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4924_7597">
                                            <rect width="11" height="11" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                        fill="#888888"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                        fill="#888888"
                                    />
                                </svg>
                            </div> */}
                </div>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Charge Breakup </h3>
              </div>
              <div className="booking_details d_flex common_padding">
                <div className="booking_details_col">
                  {data && (
                    <div>
                      <h3>Room</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["Room Charge"] &&
                          chargesDetails["Room Charge"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}

                  {chargesDetails && chargesDetails["housekeeping"] && (
                    <div>
                      <h3>Houekeeping</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["housekeeping"] &&
                          chargesDetails["housekeeping"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}
                </div>
                <div className="booking_details_col">
                  {chargesDetails && chargesDetails["f_and_b"] && (
                    <div>
                      <h3>Food & Beverages</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["f_and_b"] &&
                          chargesDetails["f_and_b"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}

                  {chargesDetails && chargesDetails["misc"] && (
                    <div>
                      <h3>Misc.</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["misc"] &&
                          chargesDetails["misc"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}
                </div>
                <div className="booking_details_col">
                  {chargesDetails && chargesDetails["room_service"] && (
                    <div>
                      <h3>Room Services</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["room_service"] &&
                          chargesDetails["room_service"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}
                  {chargesDetails && chargesDetails["add_ons"] && (
                    <div>
                      <h3>Add Ons</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["add_ons"] &&
                          chargesDetails["add_ons"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="booking_details price_breakup d_flex">
                <div className="booking_details_col">
                  <div className="total_amt">
                    <h3>Total Amt.</h3>
                    <p> {data && data?.grand_total}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="paid_amt">
                    <h3>Paid Amt.</h3>
                    <p>{data && data?.paid_amount}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="due_amt">
                    <h3>Due Amt.</h3>
                    <p>{data && data?.balance_amount}</p>
                  </div>
                </div>
              </div>
              <div className="d_flex common_padding booking_details_options booking_details_options_bottom_container">
                {/* <BgThemeButton
                  children={"Modify Reservation"}
                  style={{ height: "35px" }}
                /> */}
                <BgOutlineThemeButton
                  style={{ height: "35px" }}
                  onClick={handleCheckedInMoreOptionsPopup}
                  icon={
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L11 1"
                        stroke="#3968ED"
                        stroke-width="1.5"
                      />
                    </svg>
                  }
                  children={"More Options"}
                />
              </div>
            </div>
          ) : data?.status === "hold" || data?.status === "pending" ? (
            <div className="">
              <div className="d_flex common_padding booking_details_options">
                <button className="booking_popup_btns view_folio_btn">
                  Assign Room
                </button>
                <button
                  className="booking_details_options_btns print_btn items_center"
                  onClick={handlePrintPopup}
                >
                  {" "}
                  <svg
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9998 3.33333H3.99984V0H13.9998V3.33333ZM13.9998 7.91667C14.2359 7.91667 14.434 7.83667 14.594 7.67667C14.754 7.51667 14.8337 7.31889 14.8332 7.08333C14.8332 6.84722 14.7532 6.64917 14.5932 6.48917C14.4332 6.32917 14.2354 6.24944 13.9998 6.25C13.7637 6.25 13.5657 6.33 13.4057 6.49C13.2457 6.65 13.1659 6.84778 13.1665 7.08333C13.1665 7.31944 13.2465 7.5175 13.4065 7.6775C13.5665 7.8375 13.7643 7.91722 13.9998 7.91667ZM12.3332 13.3333V10H5.6665V13.3333H12.3332ZM13.9998 15H3.99984V11.6667H0.666504V6.66667C0.666504 5.95833 0.909559 5.36444 1.39567 4.885C1.88178 4.40556 2.47206 4.16611 3.1665 4.16667H14.8332C15.5415 4.16667 16.1354 4.40639 16.6148 4.88583C17.0943 5.36528 17.3337 5.95889 17.3332 6.66667V11.6667H13.9998V15Z"
                      fill="#3968ED"
                    />
                  </svg>
                  Print{" "}
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L11 1"
                      stroke="#3968ED"
                      stroke-width="1.5"
                    />
                  </svg>
                </button>

                <button
                  className="booking_details_options_btns items_center email_btn"
                  onClick={handleEmailPopup}
                >
                  <svg
                    width="19"
                    height="13"
                    viewBox="0 0 19 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 2.1584V11.6071C0 12.3764 0.623603 13 1.39286 13H17.1786C17.9478 13 18.5714 12.3764 18.5714 11.6071V2.15831L9.75352 7.30209C9.4644 7.47075 9.10688 7.47075 8.81776 7.30209L0 2.1584Z"
                      fill="#3968ED"
                    />
                    <path
                      d="M18.0545 0.309833C17.8152 0.116071 17.5104 0 17.1786 0H1.39286C1.06095 0 0.756158 0.116092 0.516872 0.309885L9.28564 5.425L18.0545 0.309833Z"
                      fill="#3968ED"
                    />
                  </svg>
                  Email{" "}
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L11 1"
                      stroke="#3968ED"
                      stroke-width="1.5"
                    />
                  </svg>
                </button>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Overview</h3>
              </div>
              <div className="booking_details d_flex common_padding">
                <div className="booking_details_col">
                  <div>
                    <h3>Guest Name</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_name) || 0}
                    </p>
                  </div>
                  <div>
                    <h3>Booking Date</h3>
                    <p>{formattedDate}</p>
                  </div>
                  <div>
                    <h3>Meal Plan</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.map((meal_item) => meal_item?.room_plan)}
                    </p>

                    {/* <p>{data && data.items && data.items.reduce((acc, item) => acc + (item.room_plan || 0), 0)) || 0}</p> */}
                  </div>
                  {/* <div>
                                    <h3>Child w/o Bed</h3>
                                    <p>0</p>
                                </div> */}
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Phone</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_phone) || 0}
                    </p>
                  </div>
                  <div>
                    <h3>Check-In Date</h3>
                    <p>{data?.check_in}</p>
                  </div>
                  <div>
                    <h3>No of Adults</h3>
                    {/* <p> {(data &&
                                        data.items &&
                                        data?.items[0]?.no_adult) ||
                                        0}</p> */}
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) => Number(acc) + Number(item?.no_adult),
                          0
                        )}
                    </p>
                  </div>
                  {/* <div>
                                    <h3>Extra Adults</h3>
                                    <p>0</p>
                                </div> */}
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Email</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_email) || 0}
                    </p>
                  </div>
                  <div>
                    <h3>Check-Out Date</h3>
                    <p>{data?.check_out}</p>
                  </div>
                  {/* <div>
                                    <h3>Child with Bed</h3>
                                    <p>1</p>
                                </div> */}
                  <div>
                    <h3>Booking Status</h3>
                    <p style={{ color: "#BD941B" }}>
                      {<UnderscoreRemoveCapitalize text={data?.status} />}
                    </p>
                  </div>
                </div>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Inclusion List</h3>
              </div>
              <div className="common_padding">
                <div>
                  {data.addons.length < 1 ? (
                    <>No addons added.</>
                  ) : (
                    <>
                      {data?.addons?.map((addon, index) => (
                        <div className="d_flex booking_detail_popup_inclusion_list">
                          <p>{addon?.item_name}</p>
                          {/* <div>
                                      <svg
                                          width="11"
                                          height="11"
                                          viewBox="0 0 11 11"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <g clip-path="url(#clip0_4924_7597)">
                                              <path
                                                  d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                                  fill="#888888"
                                              />
                                              <path
                                                  d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                                  fill="#888888"
                                              />
                                          </g>
                                          <defs>
                                              <clipPath id="clip0_4924_7597">
                                                  <rect width="11" height="11" fill="white" />
                                              </clipPath>
                                          </defs>
                                      </svg>
                                      <svg
                                          width="11"
                                          height="11"
                                          viewBox="0 0 11 11"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                              fill="#888888"
                                          />
                                          <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                              fill="#888888"
                                          />
                                      </svg>
                                  </div> */}
                        </div>
                      ))}
                    </>
                  )}

                  {/* <div>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_4924_7597)">
                                        <path
                                            d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                            fill="#888888"
                                        />
                                        <path
                                            d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                            fill="#888888"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4924_7597">
                                            <rect width="11" height="11" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                        fill="#888888"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                        fill="#888888"
                                    />
                                </svg>
                            </div> */}
                </div>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Price Breakup </h3>
              </div>
              {/* <div className="booking_details d_flex common_padding">
                                <div className="booking_details_col">

                                    {data &&
                                        <div>
                                            <h3>Room</h3>
                                            <p> {chargesDetails && chargesDetails["Room Charge"] && chargesDetails["Room Charge"]?.net_total || 0}</p>
                                        </div>}


                                    {chargesDetails && chargesDetails["housekeeping"] &&
                                        <div>
                                            <h3>Houekeeping</h3>
                                            <p> {chargesDetails && chargesDetails["housekeeping"] && chargesDetails["housekeeping"]?.net_total || 0}</p>
                                        </div>}

                                </div>
                                <div className="booking_details_col">
                                    {chargesDetails && chargesDetails["f_and_b"] &&
                                        <div>
                                            <h3>Food & Beverages</h3>
                                            <p> {chargesDetails && chargesDetails["f_and_b"] && chargesDetails["f_and_b"]?.net_total || 0}</p>
                                        </div>}

                                    {chargesDetails && chargesDetails["misc"] &&
                                        <div>
                                            <h3>Misc.</h3>
                                            <p> {chargesDetails && chargesDetails["misc"] && chargesDetails["misc"]?.net_total || 0}</p>
                                        </div>}
                                </div>
                                <div className="booking_details_col">
                                    {chargesDetails && chargesDetails["room_service"] &&
                                        <div>
                                            <h3>Room Services</h3>
                                            <p> {chargesDetails && chargesDetails["room_service"] && chargesDetails["room_service"]?.net_total || 0}</p>
                                        </div>}
                                    {chargesDetails && chargesDetails["add_ons"] &&
                                        <div>
                                            <h3>Add Ons</h3>
                                            <p> {chargesDetails && chargesDetails["add_ons"] && chargesDetails["add_ons"]?.net_total || 0}</p>
                                        </div>}
                                </div>
                            </div> */}
              <div className="booking_details price_breakup d_flex">
                <div className="booking_details_col">
                  <div className="total_amt">
                    <h3>Total Amt.</h3>
                    <p> {data && data?.grand_total}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="paid_amt">
                    <h3>Paid Amt.</h3>
                    <p>{data && data.paid_amount}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="due_amt">
                    <h3>Due Amt.</h3>
                    <p>{data && data.balance_amount}</p>
                  </div>
                </div>
              </div>
              <div className="d_flex common_padding booking_details_options booking_details_options_bottom booking_details_options_bottom_container">
                {/* <button className="booking_popup_btns view_folio_btn ">
                                Modify Reservation
                            </button> */}
                <div className="d_flex" style={{ gap: "20px" }}>
                  <BgThemeButton
                    children={"Confirm Booking"}
                    className="booking_detail_bottom_btn"
                  />
                  <button
                    className="booking_cancel_btn items_center"
                    onClick={handleCancelBooking}
                  >
                    Cancel Booking{" "}
                  </button>
                </div>

                <div style={{ textAlign: "end" }}>
                  <p>Hold Till</p>
                  <span>2 March 2024.10:30AM</span>
                  {/* <div style={{ cursor: 'pointer' }}>
                                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_4924_4830)">
                                                <path d="M6.68054 0.631537L8.36884 2.32021L8.6504 2.03858C9.11654 1.57234 9.11654 0.816519 8.6504 0.350278C8.18412 -0.116108 7.42802 -0.116108 6.96173 0.350278L6.68054 0.631537Z" fill="#3968ED" />
                                                <path d="M7.59366 3.09377L5.90536 1.4051L0.548029 6.7627L0 8.99952L2.23633 8.45137L7.59366 3.09377Z" fill="#3968ED" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4924_4830">
                                                    <rect width="9" height="9" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <span style={{ color: 'blue', paddingLeft: '5px' }}>Modify</span>
                                    </div> */}
                </div>
              </div>
            </div>
          ) : data?.status === "checked_out" ? (
            <div className="">
              <div className="d_flex common_padding booking_details_options">
                <button className="booking_popup_btns view_folio_btn">
                  Assign Room
                </button>
                <button
                  className="booking_details_options_btns print_btn items_center"
                  onClick={handlePrintPopup}
                >
                  {" "}
                  <svg
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9998 3.33333H3.99984V0H13.9998V3.33333ZM13.9998 7.91667C14.2359 7.91667 14.434 7.83667 14.594 7.67667C14.754 7.51667 14.8337 7.31889 14.8332 7.08333C14.8332 6.84722 14.7532 6.64917 14.5932 6.48917C14.4332 6.32917 14.2354 6.24944 13.9998 6.25C13.7637 6.25 13.5657 6.33 13.4057 6.49C13.2457 6.65 13.1659 6.84778 13.1665 7.08333C13.1665 7.31944 13.2465 7.5175 13.4065 7.6775C13.5665 7.8375 13.7643 7.91722 13.9998 7.91667ZM12.3332 13.3333V10H5.6665V13.3333H12.3332ZM13.9998 15H3.99984V11.6667H0.666504V6.66667C0.666504 5.95833 0.909559 5.36444 1.39567 4.885C1.88178 4.40556 2.47206 4.16611 3.1665 4.16667H14.8332C15.5415 4.16667 16.1354 4.40639 16.6148 4.88583C17.0943 5.36528 17.3337 5.95889 17.3332 6.66667V11.6667H13.9998V15Z"
                      fill="#3968ED"
                    />
                  </svg>
                  Print{" "}
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L11 1"
                      stroke="#3968ED"
                      stroke-width="1.5"
                    />
                  </svg>
                </button>

                <button
                  className="booking_details_options_btns items_center email_btn"
                  onClick={handleEmailPopup}
                >
                  <svg
                    width="19"
                    height="13"
                    viewBox="0 0 19 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 2.1584V11.6071C0 12.3764 0.623603 13 1.39286 13H17.1786C17.9478 13 18.5714 12.3764 18.5714 11.6071V2.15831L9.75352 7.30209C9.4644 7.47075 9.10688 7.47075 8.81776 7.30209L0 2.1584Z"
                      fill="#3968ED"
                    />
                    <path
                      d="M18.0545 0.309833C17.8152 0.116071 17.5104 0 17.1786 0H1.39286C1.06095 0 0.756158 0.116092 0.516872 0.309885L9.28564 5.425L18.0545 0.309833Z"
                      fill="#3968ED"
                    />
                  </svg>
                  Email{" "}
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L11 1"
                      stroke="#3968ED"
                      stroke-width="1.5"
                    />
                  </svg>
                </button>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Overview</h3>
              </div>
              <div className="booking_details d_flex common_padding">
                <div className="booking_details_col">
                  <div>
                    <h3>Guest Name</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_name) || 0}
                    </p>
                  </div>
                  <div>
                    <h3>Booking Date</h3>
                    <p>{formattedDate}</p>
                  </div>
                  <div>
                    <h3>Meal Plan</h3>
                    <p>
                      {data &&
                        data.items &&
                        data.items.map((meal_item) => meal_item.room_plan)}
                    </p>

                    {/* <p>{data && data.items && data.items.reduce((acc, item) => acc + (item.room_plan || 0), 0)) || 0}</p> */}
                  </div>
                  {/* <div>
                                        <h3>Child w/o Bed</h3>
                                        <p>0</p>
                                    </div> */}
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Phone</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_phone) || 0}
                    </p>
                  </div>
                  <div>
                    <h3>Check-In Date</h3>
                    <p>{data.check_in}</p>
                  </div>
                  <div>
                    <h3>No of Adults</h3>
                    {/* <p> {(data &&
                                            data.items &&
                                            data?.items[0]?.no_adult) ||
                                            0}</p> */}
                    <p>
                      {data &&
                        data.items &&
                        data.items.reduce(
                          (acc, item) => Number(acc) + Number(item?.no_adult),
                          0
                        )}
                    </p>
                  </div>
                  {/* <div>
                                        <h3>Extra Adults</h3>
                                        <p>0</p>
                                    </div> */}
                </div>
                <div className="booking_details_col">
                  <div>
                    <h3>Email</h3>
                    <p>
                      {(data && data.guest && data?.guest[0]?.guest_email) || 0}
                    </p>
                  </div>
                  <div>
                    <h3>Check-Out Date</h3>
                    <p>{data.check_out}</p>
                  </div>
                  {/* <div>
                                        <h3>Child with Bed</h3>
                                        <p>1</p>
                                    </div> */}
                  <div>
                    <h3>Booking Status</h3>
                    <p style={{ color: "#fe8952" }}>
                      {<UnderscoreRemoveCapitalize text={data?.status} />}
                    </p>
                  </div>
                </div>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Inclusion List</h3>
              </div>
              <div className="common_padding">
                <div>
                  {data.addons.length < 1 ? (
                    <>No addons added.</>
                  ) : (
                    <>
                      {data?.addons?.map((addon, index) => (
                        <div className="d_flex booking_detail_popup_inclusion_list">
                          <p>{addon.item_name}</p>
                          {/* <div>
                                          <svg
                                              width="11"
                                              height="11"
                                              viewBox="0 0 11 11"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                          >
                                              <g clip-path="url(#clip0_4924_7597)">
                                                  <path
                                                      d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                                      fill="#888888"
                                                  />
                                                  <path
                                                      d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                                      fill="#888888"
                                                  />
                                              </g>
                                              <defs>
                                                  <clipPath id="clip0_4924_7597">
                                                      <rect width="11" height="11" fill="white" />
                                                  </clipPath>
                                              </defs>
                                          </svg>
                                          <svg
                                              width="11"
                                              height="11"
                                              viewBox="0 0 11 11"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                          >
                                              <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                                  fill="#888888"
                                              />
                                              <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                                  fill="#888888"
                                              />
                                          </svg>
                                      </div> */}
                        </div>
                      ))}
                    </>
                  )}

                  {/* <div>
                                    <svg
                                        width="11"
                                        height="11"
                                        viewBox="0 0 11 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clip-path="url(#clip0_4924_7597)">
                                            <path
                                                d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                                fill="#888888"
                                            />
                                            <path
                                                d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                                fill="#888888"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4924_7597">
                                                <rect width="11" height="11" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <svg
                                        width="11"
                                        height="11"
                                        viewBox="0 0 11 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                            fill="#888888"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                            fill="#888888"
                                        />
                                    </svg>
                                </div> */}
                </div>
              </div>
              <div className="boking_details_popup_overview ">
                <h3>Charge Breakup </h3>
              </div>
              <div className="booking_details d_flex common_padding">
                <div className="booking_details_col">
                  {data && (
                    <div>
                      <h3>Room</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["Room Charge"] &&
                          chargesDetails["Room Charge"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}

                  {chargesDetails && chargesDetails["housekeeping"] && (
                    <div>
                      <h3>Houekeeping</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["housekeeping"] &&
                          chargesDetails["housekeeping"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}
                </div>
                <div className="booking_details_col">
                  {chargesDetails && chargesDetails["f_and_b"] && (
                    <div>
                      <h3>Food & Beverages</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["f_and_b"] &&
                          chargesDetails["f_and_b"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}

                  {chargesDetails && chargesDetails["misc"] && (
                    <div>
                      <h3>Misc.</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["misc"] &&
                          chargesDetails["misc"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}
                </div>
                <div className="booking_details_col">
                  {chargesDetails && chargesDetails["room_service"] && (
                    <div>
                      <h3>Room Services</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["room_service"] &&
                          chargesDetails["room_service"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}
                  {chargesDetails && chargesDetails["add_ons"] && (
                    <div>
                      <h3>Add Ons</h3>
                      <p>
                        {" "}
                        {(chargesDetails &&
                          chargesDetails["add_ons"] &&
                          chargesDetails["add_ons"]?.net_total) ||
                          0}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="booking_details price_breakup d_flex">
                <div className="booking_details_col">
                  <div className="total_amt">
                    <h3>Total Amt.</h3>
                    <p> {data && data?.grand_total}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="paid_amt">
                    <h3>Paid Amt.</h3>
                    <p>{data && data?.paid_amount}</p>
                  </div>
                </div>
                <div className="booking_details_col">
                  <div className="due_amt">
                    <h3>Due Amt.</h3>
                    <p>{data && data?.balance_amount}</p>
                  </div>
                </div>
              </div>
              {/* <div className="d_flex common_padding booking_details_options booking_details_options_bottom_container">
                             
                                <BgThemeButton children={'Modify Reservation'} style={{ height: '35px' }} />
                                <BgOutlineThemeButton style={{ height: '35px' }}
                                    onClick={handleMoreOptionsPopup}
                                    icon={
                                        <svg
                                            width="12"
                                            height="8"
                                            viewBox="0 0 12 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1 1L6 6L11 1"
                                                stroke="#3968ED"
                                                stroke-width="1.5"
                                            />
                                        </svg>}
                                    children={'More Options'}

                                />

                              {openMoreOptionsPopup && (
                                    <div className="more_options_popup" ref={popupsRef}>
                                        <p>Remove Reservation</p>
                                        <p>No Show</p>
                                        <p>Add Add-on</p>
                                    </div>
                                )}
                                <button className="booking_cancel_btn items_center">
                                    Cancel Booking{" "}
                                </button>
                            </div> */}
            </div>
          ) : (
            ""
          )}
        </div>
        {showModelMenuActive && (
          <>
            <div className="bg-backdrop">
              <section className="room-type-model-container ">
                <h4>Are You Sure?</h4>
                <p style={{ marginBottom: "0" }}>
                  Do You Want To Cancel Booking?
                </p>
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <div className="reason-input-button">Enter Reason</div>
                    <div className="reason-input-button">
                      {/* {reason?.reason?.trim()?.length}/{wordLimit} */}
                    </div>
                  </div>
                  <div style={{ width: "100%", marginBottom: "10px" }}>
                    <VerticalTextAreaCustom
                      name={"cancelReason"}
                      className="textfield"
                      focused={false}
                      type="text"
                      value={cancelReason}
                      autoComplete="off"
                      onChange={(e) => setCancelReason(e.target.value)}
                      cols={9}
                    />
                  </div>
                </>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                  >
                    Discard
                  </Button>
                  <Button
                    onClick={() => cancelBookingHandle(data?.id)}
                    destructive
                  >
                    Yes, Cancel
                  </Button>
                </div>
              </section>
            </div>
          </>
        )}
        {openMoreOptionsPopup && (
          <div className="more_options_popup" ref={popupsRef}>
            <button onClick={handleRemoveReservation}>
              Remove Reservation
            </button>
            {/* <p>No Show</p>
                        <p>Add Add-on</p> */}
          </div>
        )}
        {openCheckedInMoreOptionsPopup && (
          <div className="more_options_popup" ref={popupsRef}>
            <button onClick={() => handleNoShowData("no_show", data?.id)}>
              No Show
            </button>
            <p>Add Add-on</p>
          </div>
        )}
        {openPrintPopup && (
          <div className="print_popup" ref={popupsRef}>
            <p>Vuchers</p>
            <p>GRC</p>
            <p>Bill</p>
          </div>
        )}
        {openEmailPopup && (
          <div className="email_popup" ref={popupsRef}>
            <p>Vuchers</p>
            <p>GRC</p>
            <p>Bill</p>
          </div>
        )}
        {deleteWithReasonPopup && (
          <DeletePopupWithReasonBooking
            title={"Booking"}
            textAreaName={"reason_of_cancelation"}
            onDeleteWithReason={handleDeleteWithReason}
            wordLimit={50}
            deleteWithReasonPopup={deleteWithReasonPopup}
            setDeleteWithReasonPopup={setDeleteWithReasonPopup}
            deleteFunction={(data) => handleCancelStatusButton(data)}
            id={deleteKotId}
            btnText={"Yes, Delete"}
            cancelText={"You Want To Delete This Booking?"}
          />
        )}
      </div>
    </div>
  );
};
export default BookingDetailSliderNew;
