import React, { useEffect, useState } from "react";
import { BgThemeButton, BgThemeButtonFullWidth } from "../../../UI/Buttons";
import VerticalInputFieldCustom from "../../../CustomComponents/VerticalInputFieldCustom";
import {
  Icon,
  IndexTable,
  LegacyCard,
  useIndexResourceState,
} from "@shopify/polaris";

import { CancelMajor } from "@shopify/polaris-icons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../assets/toastUtils";
import { useSelector } from "react-redux";
import NoDataFoundSmall from "../../../CustomComponents/NoDataFoundSmall";
import UnderscoreRemoveCapitalize from "../../../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import { formatIndianCurrency } from "../../../../utils/utils";
import VerticalInputSelectCustomCopy from "../../../CustomComponents/VerticalInputSelectCustomCopy";
import CustomIndexTable from "../../CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../../CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../../CustomIndexTable/CustomIndexTableCell";

const MergeKotView = ({
  orders,
  selectedKot,
  setMergeBillPopupNew,
  getOrderListData,
}) => {
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const userToken = JSON.parse(localStorage.getItem("userToken"));


  const [selectedFolioFilter, setSelectedFolioFilter] = useState(
    selectedKot?.id
  );
  const handleSelectFolioFilter = (label, value) => {
    setSelectedFolioFilter(label);
  };

  const [selectedArray, setSelectedArray] = useState([]);

  console.log(orders);

  // const allFolioArrayFilters = orders.filter(
  //   (item) => +item.id !== +selectedFolioFilter
  // );

  const allFolioArrayFilters = orders.filter(
    (item) => +item.id !== +selectedFolioFilter && item.order_status !== "cancelled"
  );

  console.log(allFolioArrayFilters);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState([]);

  const orderLabelArray = [
    {
      label: "Select Option",
      value: "",
    },
    ...orders?.map((item) => {
      return {
        label: item.order_no,
        value: item.id,
      };
    }),
  ];

  useEffect(() => {
    if (selectedResources) {
      setSelectedArray(selectedResources);
    }
  }, [selectedResources]);

  const rowMarkupAllFolios = allFolioArrayFilters?.map(
    ({ id, folio_no, order_no, order_type, net_total, date, index }) => {
      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedArray.includes(id)}
          position={id}
        >
          <IndexTable.Cell>{order_no}</IndexTable.Cell>

          <IndexTable.Cell>
            <UnderscoreRemoveCapitalize text={order_type} />
          </IndexTable.Cell>
          <IndexTable.Cell>{formatIndianCurrency(net_total)}</IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const rowMarkupAllFoliosCustom = allFolioArrayFilters?.map(
    ({ id, folio_no, order_no, order_type, net_total, date, index }) => {
      return (
        <CustomIndexTableRow
          id={id}
          key={id}
          selectedItems={selectedArray} // send for checking the check box is checked
          setSelectedItems={setSelectedArray}
          showCheckbox={true} // show the check box
        >
          <CustomIndexTableCell>{order_no}</CustomIndexTableCell>

          <CustomIndexTableCell>
            <UnderscoreRemoveCapitalize text={order_type} />
          </CustomIndexTableCell>
          <CustomIndexTableCell>
            {formatIndianCurrency(net_total)}
          </CustomIndexTableCell>
        </CustomIndexTableRow>
      );
    }
  );

  const mergeHandle = (e) => {
    e.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/restaurant/kot/merge/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          parentKot: selectedFolioFilter,
          kotList: selectedArray,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          getOrderListData(property_id?.id);
          showSuccessToast(data.message);
          // window.location.reload();
          setMergeBillPopupNew(false);
          // navigate("/agents");
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  return (
    <>
      <div className="bg-backdrop">
        <form
          onSubmit={(e) => e.preventDefault()}
          style={{ width: "350px", padding: "0" }}
          className="stock-add-model-container"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "15px",
              padding: "20px 20px 0px ",
            }}
          >
            <div className="add-ota-model-container-heading">Merge KOT</div>
            <div
              className="pointer"
              onClick={() => {
                setMergeBillPopupNew(false);
                //   setTransferPayments(false);
                //   setTable1DataPayments([]);
                //   setTable2DataPayments([]);
                //   setLeftFolioId("");
                //   setRightFolioId("");
                //   setSelectedDateFilterValueLeft("Select Option");
                //   setSelectedDateFilterValueRight("Select Option");
              }}
            >
              <Icon source={CancelMajor} color="base" />
            </div>
          </div>
          <div
            className="transfer_charges_table1_merge_folio"
            style={{ width: "100%" }}
          >
            <div style={{ marginLeft: "20px", marginRight: "20px" }}>
              {/* <div style={{ marginTop: "10px" }}>
                <VerticalInputSelectCustomCopy
                  required={true}
                  options={orderLabelArray}
                  name="room_bed_type"
                  focused={false}
                  titleName="Select Order Number"
                  onChange={(e) => handleSelectFolioFilter(e.target.value)}
                  autoComplete="off"
                  value={selectedFolioFilter}
                />
              </div> */}
              Selected Kot : {selectedKot.order_no}
            </div>

            {orders?.length > 0 ? (
              <div>
                {/* <LegacyCard>
                  <IndexTable
                    resourceName={resourceName}
                    itemCount={orders.length}
                    selectedItemsCount={
                      allResourcesSelected ? "All" : selectedArray.length
                    }
                    onSelectionChange={handleSelectionChange}
                    headings={[
                      { title: "KOT ID" },
                      { title: "Kot Type" },
                      { title: "Total Amount" },
                    ]}
                  >
                    {rowMarkupAllFolios}
                  </IndexTable>
                </LegacyCard> */}
                <div
                  style={{
                    marginTop: "20px",
                    height: "300px",
                    width: "100%",
                    overflow: "auto",
                  }}
                  className="overflowContainer"
                >
                  <CustomIndexTable
                    allItems={allFolioArrayFilters} // table data
                    selectedItems={selectedArray} //selected items from table
                    setSelectedItems={setSelectedArray} // state from parent component to update table
                    showCheckbox={true}
                    headings={[
                      // table headings
                      "KOT ID",
                      "Kot Type",
                      "Total Amount",
                    ]}
                  >
                    {rowMarkupAllFoliosCustom}
                  </CustomIndexTable>
                </div>

                <div style={{ padding: "10px" }}>
                  <BgThemeButtonFullWidth onClick={mergeHandle}>
                    Merge
                  </BgThemeButtonFullWidth>
                </div>
              </div>
            ) : (
              <NoDataFoundSmall />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default MergeKotView;

{
  /* <div className="transfer_charges_table_parent">
              <div className="transfer_charges_table1">
                <DashboardFilter
                  values={folioFilterOptions}
                  onSelect={handleSelectFolioLeft}
                  selectedValue={selectedDateFilterValueLeft}
                />
                {table1DataPayments?.length > 0 ? (
                  <div style={{ marginTop: "20px" }}>
                    <LegacyCard>
                      <IndexTable
                        itemCount={table1DataPayments.length}
                        selectedItemsCount={
                          allResourcesSelected
                            ? "All"
                            : selectedResources.length
                        }
                        resourceName={resourceName}
                        onSelectionChange={handleSelectionChange}
                        headings={[
                          { title: "Type" },
                          { title: "Date" },
                          { title: "Total Amt." },
                        ]}
                      >
                        {rowMarkupTable1Payments}
                      </IndexTable>
                    </LegacyCard>
                  </div>
                ) : (
                  <NoDataFoundSmall />
                )}
              </div>
              <div
                className="transfer_charges_table_mid"
                onClick={() =>
                  handleSubmitPaymentTransferFolio(selectedResources)
                }
              >
                <svg
                  width="32"
                  height="30"
                  viewBox="0 0 32 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="32" height="30" rx="4" fill="#3968ED" />
                  <path
                    d="M10 9.5L15.5 15L10 20.5"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M16.5 9.5L22 15L16.5 20.5"
                    stroke="white"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
              <div className="transfer_charges_table2">
                <DashboardFilter
                  values={filteredFolioOptions}
                  onSelect={handleSelectFolioRight}
                  selectedValue={selectedDateFilterValueRight}
                />
                {table2DataPayments.length > 0 ? (
                  <div style={{ marginTop: "20px" }}>
                    <LegacyCard>
                      <IndexTable
                        itemCount={table2DataPayments.length}
                        resourceName={resourceName}
                        onSelectionChange={handleSelectionChange}
                        headings={[
                          { title: "Type" },
                          { title: "Date" },
                          { title: "Total Amt." },
                        ]}
                        selectable={false}
                      >
                        {rowMarkupTable2Payments}
                      </IndexTable>
                    </LegacyCard>
                  </div>
                ) : (
                  <NoDataFoundSmall />
                )}
              </div>
            </div> */
}
