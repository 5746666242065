import {
  ChoiceList,
  Collapsible,
  Icon,
  LegacyCard,
  LegacyStack,
  Link,
  Page,
} from "@shopify/polaris";
import React, { useRef, useState, useEffect } from "react";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import VerticalTextAreaCustom from "../CustomComponents/VerticalTextAreaCustom";
import { websites_inner_lists } from "../../assets/constant";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { useNavigate } from "react-router";
import ReactQuill from "react-quill";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AddBlogComp = () => {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);
  const [jsonData, setJsonData] = useState("");
  const [selectedVisibility, setSelectedVisibility] = useState("");
  const [loader, setLoader] = useState(true);
  const [uploadedFile, setUploadedFile] = useState();
  const [logoUrl, setLogoUrl] = useState(null);
  const [editorHtml, setEditorHtml] = useState("");

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["align", "code-block"],
      ["link", "image"],
      ["undo", "redo"], // Add undo and redo options
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "align",
    "code-block",
    "link",
    "image",
  ];

  const handleTextChange = (value) => {
    setEditorHtml(value);
  };

  const handleFileUploadChange = (e) => {
    e.stopPropagation();
    setUploadedFile(e.target.files[0]);
  };

  useEffect(() => {
    if (uploadedFile) {
      const url = URL.createObjectURL(uploadedFile);
      setLogoUrl(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [uploadedFile]);

  const handleRemoveLogobtn = (e) => {
    e.stopPropagation();
    setUploadedFile(null);
  };

  const navigate = useNavigate();

  const handleChangeVisibility = (value) => {
    setSelectedVisibility(value[0]);
  };

  const [addBlogPostData, setAddBlogPostsData] = useState({
    title: "",
    type: "post",
    content: "",
    content: "",
    status: "",
    blog_category: "",
    meta_title: "",
    meta_description: "",
    url_handle: "",
  });

  useEffect(() => {
    setAddBlogPostsData((prevData) => ({
      ...prevData,
      content: editorHtml,
      status: selectedVisibility,
    }));
  }, [selectedVisibility, editorHtml]);

  const handleValueChange = (event) => {
    setAddBlogPostsData({
      ...addBlogPostData,
      [event.target.name]: event.target.value,
    });
  };

  const options = [
    { label: "Travel", value: "Travel" },
    { label: "Adventure", value: "Adventure" },
    { label: "Gk", value: "Gk" },
  ];

  const handleSubmitAddDiscount = (e) => {
    e.preventDefault();
    {
      addBlogPostData.status === "" &&
        showErrorToast("Please Select Visibility!");
    }
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/store/post/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addBlogPostData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast("Blog Post Created Successfully!");
          setAddBlogPostsData({
            title: "",
            type: "post",
            content: editorHtml,
            content: "",
            status: selectedVisibility,
            blog_category: "",
            meta_title: "",
            meta_description: "",
            url_handle: "",
          });
          navigate("/websites/blogs");
        } else if (data.error) {
          // const firstErrorKey = Object.keys(data.error)[0];
          // const firstErrorMessage = data.error[firstErrorKey][0];
          showErrorToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      });
  };

  return (
    <>
      {/* <SidebarInnerLists innerLists={websites_inner_lists} /> */}
      <div className="flex justify_content_center">
        <form
          // className="edit-rooms-full-container "
          style={{ width: "80%" }}
          onSubmit={(e) => handleSubmitAddDiscount(e)}
        >
          <div>
            <GoBackButtonCustom
              buttonNextText={"Add Blog Post"}
              onClick={() => navigate("/websites/blogs")}
            />
          </div>
          <div className="edit-rooms-full-container-div">
            <div className="edit-rooms-full-container-left">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  width: "650px",
                }}
              >
                <div>
                  <LegacyCard sectioned>
                    <LegacyStack vertical>
                      <div
                        aria-controls="basic-collapsible"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      >
                        <div>Blog details</div>
                      </div>
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <VerticalInputFieldCustom
                              required={true}
                              focused={false}
                              name="title"
                              titleName="Title"
                              autoComplete="off"
                              onChange={handleValueChange}
                              value={addBlogPostData.title}
                            />
                          </div>
                        </div>
                        <div className="editor_container">
                          <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            value={editorHtml}
                            onChange={handleTextChange}
                            style={{ border: "1px solid #ccc" }}
                          />
                          {/* <CKEditor
                          editor={ClassicEditor}
                          data="<p>Hello World</p> "
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            handleEditorChange(data);
                          }}
                        /> */}
                        </div>
                      </div>
                    </LegacyStack>
                  </LegacyCard>
                </div>
                {/* <div>
                                <LegacyCard sectioned>
                                    <LegacyStack vertical>
                                        <div
                                            aria_controls="basic-collapsible"
                                            style={{
                                                cursor: "pointer",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                fontWeight: "700",
                                                fontSize: "14px",
                                            }}
                                        >
                                            <div>Search Engine Listing</div>
                                        </div>

                                        <p
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                color: "#888",
                                            }}
                                        >
                                            Add a title and description to see how this Room might
                                            appear
                                            <br />
                                            in a search engine listing
                                        </p>
                                        <div className="edit-rooms-form-container">
                                            <div className="form-container-div-full">
                                                <VerticalInputFieldCustom
                                                    name="meta_title"
                                                    value={addBlogPostData.meta_title}
                                                    focused={false}
                                                    titleName="Page Title"
                                                    onChange={handleValueChange}
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className="form-container-div-full">
                                                <VerticalInputFieldCustom
                                                    name="meta_description"
                                                    value={addBlogPostData.meta_description}
                                                    focused={false}
                                                    titleName="Meta Description"
                                                    onChange={handleValueChange}
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className="form-container-div-full">
                                                <VerticalInputFieldCustom
                                                    name="url_handle"
                                                    value={window.location.href}
                                                    focused={false}
                                                    titleName="Url Handle"
                                                    onChange={handleValueChange}
                                                    autoComplete="off"
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </LegacyStack>
                                </LegacyCard>
                            </div> */}
                <div className="flex flex_gap_10 mb-20">
                  <div>
                    <BgThemeButton type="submit" children={"Add"} loader={loader}/>
                  </div>
                  <div style={{ color: "rgba(0, 122, 92, 1)" }}>
                    {/* <Link to="/websites/blogs" style={{ textDecoration: "none" }}> */}
                    <BgOutlineThemeButton
                      onClick={() => navigate("/websites/blogs")}
                      type="button"
                      children={"Discard"}
                    />
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="edit-rooms-full-container-right">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <div>
                  <LegacyCard sectioned>
                    <LegacyStack vertical>
                      <div
                        aria_controls="basic-collapsible"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                        }}
                      >
                        <div>Visibility</div>
                      </div>
                      <ChoiceList
                        choices={[
                          { label: "Visible", value: "visible" },
                          { label: "Hidden", value: "hidden" },
                        ]}
                        selected={selectedVisibility}
                        onChange={handleChangeVisibility}
                      />
                    </LegacyStack>
                  </LegacyCard>
                </div>

                {/* <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Organization</div>

                    </div>
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        titleName="Blog Category"
                        required={true}
                        options={options}
                        focused={false}
                        name="blog_category"
                        onChange={handleValueChange}
                        value={addBlogPostData.blog_category}
                      />
                    </div>
                  </LegacyStack>
                </LegacyCard>
              </div> */}
                {/* <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div style={{position: 'relative'}}>
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        onChange={handleFileUploadChange}
                        className={`${!uploadedFile ? 'featureImages_upload_file_input' : 'featureImages_upload_file_input featureImages_addzindex'}`}
                      />
                      {uploadedFile ? (
                        <div className="featureImages_logoPreview_container">
                          <div>
                            <img
                              src={logoUrl}
                              alt="error"
                              height="200px"
                              width="100%"
                            />
                          </div>
                          <div className="featureImages_btnContainer">
                            <button type="button" onClick={(e) => handleRemoveLogobtn(e)} className="featureImages_removeLogobtn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 8 8"
                                fill="none"
                              >
                                <path d="M1 6.94752L7 1" stroke="black" />
                                <path d="M7 6.94775L1 1.00023" stroke="black" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="featureImages_uplaodFile_displaytext_container">
                          <p className="featured_image_text">Add Files</p>
                          <p className="featured_image_text_dragdrop">or drop JPG, PNG images here</p>
                        </div>)}
                    </div>
                  </LegacyStack>
                </LegacyCard>
              </div> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddBlogComp;
