import React, { useState } from "react";
import { popupStyles } from "./styles";
import { BgThemeButton, BgOutlineThemeButton } from "../../UI/Buttons";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../VerticalInputSelectCustomCopy";
import SearchComponent from "../SearchComponent/SearchComponent";
import SelectDateRange from "../SelectDateRange/SelectDateRange";

const HoldRoomsAgentsPopup = () => {
  const [holdRoomPopup, setHoldRoomPopup] = useState(false);
  const [checkedCategories, setCheckedCategories] = useState({});
  const handleHoldRoomsButtonClick = () => {
    setHoldRoomPopup(true);
  };
  const [selectedAgent, setSelectedAgent] = useState("contracted");
  const handleRadioAgentChange = (agentType) => {
    setSelectedAgent(agentType);
  };

  const handleDateChange = (form_date, to_date) => {};

  const roomCategories = [
    "Deluxe",
    "Super Deluxe",
    "Luxury",
    "Executive",
    "Family Suite",
  ];
  const handleCheckboxChange = (category) => {
    setCheckedCategories((prev) => {
      const newCheckedCategories = { ...prev, [category]: !prev[category] };

      // Call API if checkbox is checked
      if (newCheckedCategories[category]) {
        // Replace the below line with your API call
        console.log(`API called for ${category}`);
        // Assuming the API returns a value for the input, you can set it in the state
        newCheckedCategories[`${category}Input`] = "API response value";
      } else {
        // Remove the input value if unchecked
        delete newCheckedCategories[`${category}Input`];
      }

      return newCheckedCategories;
    });
  };

  const [selectedRelease, setSelectedRelease] = useState("auto_release");

  const handleReleaseRadioChange = (name) => {
    setSelectedRelease(name);
  };

  const autoReleaseOptions = [
    {
      label: "D7",
      value: "100%",
    },
    {
      label: "D5",
      value: "50%",
    },
    {
      label: "D3",
      value: "0",
    },
  ];

  return (
    <div style={popupStyles.container}>
      <div style={popupStyles.containerChild}>
        <h4 style={popupStyles.popupHeadings}>Hold Rooms</h4>
        <div className="flex flex_gap_15">
          <label className="flex flex_gap_5 align_items_center pointer">
            <input
              name="agentType"
              type="radio"
              checked={selectedAgent === "contracted"}
              onClick={() => handleRadioAgentChange("contracted")}
            />
            <span> Contracted Agent</span>
          </label>
          <label className="flex flex_gap_5 align_items_center pointer">
            <input
              name="agentType"
              type="radio"
              checked={selectedAgent === "general"}
              onClick={() => handleRadioAgentChange("general")}
            />
            <span>General Agent</span>
          </label>
        </div>

        <div>
          {selectedAgent === "contracted" ? (
            <>
              <table style={popupStyles.table}>
                <thead>
                  <tr>
                    <th style={{ paddingRight: "5px" }}>Select Agent</th>
                    <th style={{ paddingLeft: "5px" }}>Select Date Range</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ paddingRight: "5px" }}>
                      <VerticalInputSelectCustomCopy />
                    </td>
                    <td style={{ paddingLeft: "5px" }}>
                      <SelectDateRange dataFromChild={handleDateChange} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <>
              <table style={popupStyles.tableBig}>
                <thead>
                  <tr>
                    <th style={{ paddingRight: "5px" }}>Company Name</th>
                    <th style={{ padding: "0 5px" }}>Phone (optional)</th>
                    <th style={{ paddingLeft: "5px" }}>Select Date Range</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ paddingRight: "5px" }}>
                      <SearchComponent />
                    </td>
                    <td style={{ padding: "0 5px" }}>
                      <VerticalInputFieldCustom />
                    </td>
                    <td style={{ paddingLeft: "5px" }}>
                      <SelectDateRange dataFromChild={handleDateChange} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
        {/* ===========room Categories=========== */}
        <div>
          <h4 style={popupStyles.categoryHeading}>Select Room Categories</h4>
          {roomCategories.map((item, index) => (
            <div
              key={index}
              style={{ height: "40px" }}
              className="flex justify_content_between align_items_center"
            >
              <div>
                <label className="flex flex_gap_10 pointer">
                  <input
                    type="checkbox"
                    checked={!!checkedCategories[item]}
                    onChange={() => handleCheckboxChange(item)}
                  />{" "}
                  <span>{item}</span>
                </label>
              </div>
              <div>
                {checkedCategories[item] && (
                  <div className="flex flex_gap_15">
                    <div className="flex flex_gap_5 align_items_center">
                      <span>Avl. Rooms</span>
                      <VerticalInputFieldCustom
                        disabled={true}
                        style={popupStyles.smallInput}
                      />
                    </div>

                    <div className="flex flex_gap_5 align_items_center">
                      <span>Hold Rooms</span>
                      <VerticalInputFieldCustom
                        style={popupStyles.smallInput}
                        type="text"
                        value={checkedCategories[`${item}Input`] || ""}
                        readOnly
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <hr />
      <div style={popupStyles.containerChild}>
        <div className="flex justify_content_between">
          <div className="flex flex_gap_15">
            <label className="flex flex_gap_5 align_items_center pointer">
              <input
                name="releaseRooms"
                type="radio"
                checked={selectedRelease === "auto_release"}
                onClick={() => handleReleaseRadioChange("auto_release")}
              />
              <span>Auto Release</span>
            </label>
            <label className="flex flex_gap_5 align_items_center pointer">
              <input
                name="releaseRooms"
                type="radio"
                checked={selectedRelease === "manual_release"}
                onClick={() => handleReleaseRadioChange("manual_release")}
              />
              <span>Manual Release</span>
            </label>
          </div>
          {selectedRelease === "auto_release" && (
            // <div>

            <div className="flex flex_gap_10  align_center">
              {autoReleaseOptions.map((item, index) => (
                <div className="flex flex_gap_5 align_items_center" key={index}>
                  <span>{item.label}</span>
                  <VerticalInputFieldCustom
                    style={{ width: "60px", height: "20px" }}
                    type="text"
                    value={item?.value}
                    disabled={true}
                  />
                </div>
              ))}
               <div>
           <div className="flex align_center agentHoldRoomsPopupReleaseTypeContainer" >
            <p className="agentHoldRoomsPopupReleaseType">High To Low Category</p>
           </div>
          </div>
            </div>
            // </div>
          )}
         
        </div>

        <div className="flex flex_gap_10" style={{ marginTop: "20px" }}>
          <BgThemeButton children={"Add"} />
          <BgOutlineThemeButton children={"Cancel"} />
        </div>
      </div>
    </div>
  );
};

export default HoldRoomsAgentsPopup;
