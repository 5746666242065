import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { formatIndianCurrency } from "../../utils/utils";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";

const ReportPaymentCommission = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const names = [
    {
      agent_name: "Agent Name",
      commission_amount: "Commission Amount",
      commission_percentage: "Commission Percentage",
      room_total: "Room Total",

    },
  ];
  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <IndexTable.Row id={order.id} key={order.id} position={order.index}>
        <IndexTable.Cell>{order.agent_name}</IndexTable.Cell>

        <IndexTable.Cell>
          {" "}
          {order.commission_amount
            ? formatIndianCurrency(order.commission_amount)
            : "0"}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {order.commission_percentage
            ? order.commission_percentage + "%"
            : "0"}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {order.room_total ? formatIndianCurrency(order.room_total) : "0"}
        </IndexTable.Cell>
      </IndexTable.Row>
    ));

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div id="receipt" className="room-types-full-container reports-new-background">
          <div className="room-types-full-container-div">
            <div className="room-type-heading">Commission Report </div>
            {departureReport?.length > 0 && (
              <div className="flex flex_gap_15">
                <ButtonToDownloadTablePDF
                  itemToDownloadID={"receipt"}
                  downloadFileName={"Commission Report"}
                  ignoreFromPrint={"pdf"}
                  pageFormat={'A3'}
                />
                <DownloadCsvTables tableData={departureReport} names={names} />
              </div>
            )}
          </div>

          {departureReport && departureReport.length > 0 ? (
            <>
              <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={departureReport.length}
                  selectable={false}
                  headings={[
                    { title: "Agent Name" },
                    { title: "Commission Amount (₹)" },
                    { title: "Commission Percentage (%)" },
                    { title: "Room Total (₹)" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
              </LegacyCard>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>

        // </div>
      )}
    </>
  );
};
export default ReportPaymentCommission;
