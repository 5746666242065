import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "./UI/Buttons";
import { Tooltip } from "@shopify/polaris";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";

const StaffPermissions = () => {
  let { staffid } = useParams();

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const role = JSON.parse(localStorage.getItem("role"));
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([
    {
      id: 1,
      name: "Reservations",
      permission: [
        {
          id: "create_reservations",
          label: "Create reservations",
          value: "create_reservations",
        },
        {
          id: "update_reservations",
          label: "Update reservations",
          value: "update_reservations",
        },
        // {
        //   id: "delete_reservations",
        //   label: "Delete reservations",
        //   value: "delete_reservations",
        // },
        {
          id: "read_reservations",
          label: "Read reservations",
          value: "read_reservations",
        },
        {
          id: "show_single_booking",
          label: "Show single booking",
          value: "show_single_booking",
        },
        {
          id: "show_preview_bill",
          label: "Show preview booking",
          value: "show_preview_bill",
        },
        {
          id: "cancel_reservation",
          label: "Cancel Reservation",
          value: "cancel_reservation",
        },
      ],
    },
    {
      id: 2,
      name: "Dashboard",
      permission: [
        // {
        //   id: "create_dashboard",
        //   label: "Create dashboard",
        //   value: "create_dashboard",
        // },
        {
          id: "assign_rooms",
          label: "Assign Rooms",
          value: "assign_rooms",
        },
        {
          id: "transfer_rooms",
          label: "Transfer Rooms",
          value: "transfer_rooms",
        },
        // {
        //   id: "update_dashboard",
        //   label: "Update dashboard",
        //   value: "update_dashboard",
        // },
        // {
        //   id: "delete_dashboard",
        //   label: "Delete dashboard",
        //   value: "delete_dashboard",
        // },
        {
          id: "read_dashboard",
          label: "Read dashboard",
          value: "read_dashboard",
        },
      ],
    },
    {
      id: 3,
      name: "Check In",
      permission: [
        {
          id: "check_in",
          label: "Check In",
          value: "check_in",
        },
      ],
    },
    {
      id: 4,
      name: "Check Out",
      permission: [
        {
          id: "check_out",
          label: "Check Out",
          value: "check_out",
        },
      ],
    },
    {
      id: 11,
      name: "Low Inventory Dates",
      permission: [
        {
          id: "create_low_inventory_dates",
          label: "Create Low Inventory Dates",
          value: "create_low_inventory_dates",
        },
        {
          id: "update_low_inventory_dates",
          label: "Update Low Inventory Dates",
          value: "update_low_inventory_dates",
        },
        {
          id: "delete_low_inventory_dates",
          label: "Delete Low Inventory Dates",
          value: "delete_low_inventory_dates",
        },
        {
          id: "read_low_inventory_dates",
          label: "Read Low Inventory Dates",
          value: "read_low_inventory_dates",
        },
      ],
    },
    {
      id: 12,
      name: "Rooms",
      permission: [
        { id: "create_rooms", label: "Create rooms", value: "create_rooms" },
        { id: "update_rooms", label: "Update rooms", value: "update_rooms" },
        { id: "delete_rooms", label: "Delete rooms", value: "delete_rooms" },
        { id: "read_rooms", label: "Read rooms", value: "read_rooms" },
      ],
    },
    {
      id: 13,
      name: "Rooms Type",
      permission: [
        {
          id: "read_room_types",
          label: "Read room types",
          value: "read_room_types",
        },
        {
          id: "update_room_types",
          label: "Update room types",
          value: "update_room_types",
        },
        {
          id: "delete_room_types",
          label: "Delete room types",
          value: "delete_room_types",
        },
        {
          id: "create_room_types",
          label: "Create room types",
          value: "create_room_types",
        },
      ],
    },
    {
      id: 14,
      name: "Meal Plans",
      permission: [
        {
          id: "create_meal_plans",
          label: "Add Meal Plans",
          value: "create_meal_plans",
        },
        {
          id: "update_meal_plans",
          label: "Update Meal Plans",
          value: "update_meal_plans",
        },
        {
          id: "delete_meal_plans",
          label: "Delete Meal Plans",
          value: "delete_meal_plans",
        },
        {
          id: "read_meal_plans",
          label: "Read Meal Plans",
          value: "read_meal_plans",
        },
      ],
    },
    {
      id: 15,
      name: "Room Rate Plans",
      permission: [
        {
          id: "create_room_rate_plan",
          label: "Create Room Rate Plans",
          value: "create_room_rate_plan",
        },
        {
          id: "update_room_rate_plan",
          label: "Update Room Rate Plans",
          value: "update_room_rate_plan",
        },
        {
          id: "delete_room_rate_plan",
          label: "Delete Room Rate Plans",
          value: "delete_room_rate_plan",
        },
        {
          id: "read_room_rate_plan",
          label: "Read Room Rate Plans",
          value: "read_room_rate_plan",
        },
      ],
    },
    {
      id: 16,
      name: "Property Targets",
      permission: [
        
        {
          id: "read_property_targets",
          label: "Read Property Targets",
          value: "read_property_targets",
        },
      ],
    },
    {
      id: 17,
      name: "Inventory",
      permission: [
        {
          id: "read_inventory",
          label: "Read inventory",
          value: "read_inventory",
        },
        {
          id: "read_bulk_inventory",
          label: "Read bulk_inventory",
          value: "read_bulk_inventory",
        },
        {
          id: "read_block_inventory",
          label: "Read block inventory",
          value: "read_block_inventory",
        },
        {
          id: "read_unhold_inventory",
          label: "Read unhold inventory",
          value: "read_unhold_inventory",
        },
        {
          id: "read_unblock_inventory",
          label: "Read unblock inventory",
          value: "read_unblock_inventory",
        },
        {
          id: "read_hold_inventory",
          label: "Read hold inventory",
          value: "read_hold_inventory",
        },
        {
          id: "update_hold_inventory",
          label: "Update hold inventory",
          value: "update_hold_inventory",
        },
        {
          id: "update_bulk_inventory",
          label: "Update bulk inventory",
          value: "update_bulk_inventory",
        },
        {
          id: "update_unhold_inventory",
          label: "Update unhold inventory",
          value: "update_unhold_inventory",
        },
        {
          id: "update_unblock_inventory",
          label: "Update unblock inventory",
          value: "update_unblock_inventory",
        },
        {
          id: "update_block_inventory",
          label: "Update block inventory",
          value: "update_block_inventory",
        },
      ],
    },
    {
      id: 18,
      name: "Channel Inventory",
      permission: [
        {
          id: "create_channel_inventory",
          label: "Create Channel Inventory",
          value: "create_channel_inventory",
        },
        {
          id: "update_channel_inventory",
          label: "Update Channel Inventory",
          value: "update_channel_inventory",
        },
        {
          id: "delete_channel_inventory",
          label: "Delete Channel Inventory",
          value: "delete_channel_inventory",
        },
        {
          id: "read_channel_inventory",
          label: "Read Channel Inventory",
          value: "read_channel_inventory",
        },
      ],
    },
    {
      id: 19,
      name: "More Details",
      permission: [
        {
          id: "update_more_details",
          label: "Update More Details",
          value: "update_more_details",
        },
        {
          id: "read_more_details",
          label: "Read More Details",
          value: "read_more_details",
        },
      ],
    },
    {
      id: 20,
      name: "Cancel Booking",
      permission: [
        {
          id: "update_cancel_booking",
          label: "Update Cancel Booking",
          value: "update_cancel_booking",
        },
        {
          id: "read_cancel_booking",
          label: "Read Cancel Booking",
          value: "read_cancel_booking",
        },
      ],
    },
    {
      id: 21,
      name: "View Voucher",
      permission: [
        {
          id: "update_view_voucher",
          label: "Update View Voucher",
          value: "update_view_voucher",
        },
        {
          id: "read_view_voucher",
          label: "Read View Voucher",
          value: "read_view_voucher",
        },
      ],
    },
    {
      id: 22,
      name: "Generate Bill",
      permission: [
        {
          id: "update_generate_bill",
          label: "Update Generate Bill",
          value: "update_generate_bill",
        },
        {
          id: "read_generate_bill",
          label: "Read Generate Bill",
          value: "read_generate_bill",
        },
      ],
    },
    {
      id: 23,
      name: "Analytics",
      permission: [
        {
          id: "create_analytics",
          label: "Create Analytics",
          value: "create_analytics",
        },
        {
          id: "update_analytics",
          label: "Update Analytics",
          value: "update_analytics",
        },
        {
          id: "delete_analytics",
          label: "Delete Analytics",
          value: "delete_analytics",
        },
        {
          id: "read_analytics",
          label: "Read Analytics",
          value: "read_analytics",
        },
        {
          id: "edit_analytics",
          label: "Edit Analytics",
          value: "edit_analytics",
        },
      ],
    },
    {
      id: 24,
      name: "Competitor Analysis",
      permission: [
        {
          id: "create_competitors_analysis",
          label: "Create Competitor Analysis",
          value: "create_competitors_analysis",
        },
        {
          id: "update_competitors_analysis",
          label: "Update Competitor Analysis",
          value: "update_competitors_analysis",
        },
        {
          id: "delete_competitors_analysis",
          label: "Delete Competitor Analysis",
          value: "delete_competitors_analysis",
        },
        {
          id: "read_competitors_analysis",
          label: "Read Competitor Analysis",
          value: "read_competitors_analysis",
        },
        {
          id: "edit_competitors_analysis",
          label: "Edit Competitor Analysis",
          value: "edit_competitors_analysis",
        },
      ],
    },
    {
      id: 25,
      name: "Competitors",
      permission: [
        {
          id: "create_competitors",
          label: "Create Competitors",
          value: "create_competitors",
        },
        {
          id: "update_competitors",
          label: "Update Competitors",
          value: "update_competitors",
        },
        {
          id: "delete_competitors",
          label: "Delete Competitors",
          value: "delete_competitors",
        },
        {
          id: "read_competitors",
          label: "Read Competitors",
          value: "read_competitors",
        },
        {
          id: "edit_competitors",
          label: "Edit Competitors",
          value: "edit_competitors",
        },
      ],
    },
    {
      id: 26,
      name: "Customers",
      permission: [
        {
          id: "create_customers",
          label: "Create Customers",
          value: "create_customers",
        },
        {
          id: "update_customers",
          label: "Update Customers",
          value: "update_customers",
        },
        {
          id: "delete_customers",
          label: "Delete Customers",
          value: "delete_customers",
        },
        {
          id: "read_customers",
          label: "Read Customers",
          value: "read_customers",
        },
        {
          id: "edit_customers",
          label: "Edit Customers",
          value: "edit_customers",
        },
      ],
    },
    {
      id: 27,
      name: "Agent",
      permission: [
        { id: "read_agent", label: "Read Agent", value: "read_agent" },
      ],
    },
    {
      id: 28,
      name: "Agents",
      permission: [
        { id: "create_agents", label: "Create Agents", value: "create_agents" },
        { id: "update_agents", label: "Update Agents", value: "update_agents" },
        { id: "delete_agents", label: "Delete Agents", value: "delete_agents" },
        { id: "read_agents", label: "Read Agents", value: "read_agents" },
        { id: "edit_agents", label: "Edit Agents", value: "edit_agents" },
      ],
    },
    {
      id: 29,
      name: "Agent Categories",
      permission: [
        {
          id: "create_agent_categories",
          label: "Create Agent Categories",
          value: "create_agent_categories",
        },
        {
          id: "update_agent_categories",
          label: "Update Agent Categories",
          value: "update_agent_categories",
        },
        {
          id: "delete_agent_categories",
          label: "Delete Agent Categories",
          value: "delete_agent_categories",
        },
        {
          id: "read_agent_categories",
          label: "Read Agent Categories",
          value: "read_agent_categories",
        },
        {
          id: "edit_agent_categories",
          label: "Edit Agent Categories",
          value: "edit_agent_categories",
        },
      ],
    },
    {
      id: 30,
      name: "Discounts",
      permission: [
        {
          id: "create_discounts",
          label: "Create Discounts",
          value: "create_discounts",
        },
        {
          id: "update_discounts",
          label: "Update Discounts",
          value: "update_discounts",
        },
        {
          id: "delete_discounts",
          label: "Delete Discounts",
          value: "delete_discounts",
        },
        {
          id: "read_discounts",
          label: "Read Discounts",
          value: "read_discounts",
        },
        {
          id: "edit_discounts",
          label: "Edit Discounts",
          value: "edit_discounts",
        },
      ],
    },
    {
      id: 31,
      name: "Website",
      permission: [
        {
          id: "create_website",
          label: "Create Website",
          value: "create_website",
        },
        {
          id: "update_website",
          label: "Update Website",
          value: "update_website",
        },
        {
          id: "delete_website",
          label: "Delete Website",
          value: "delete_website",
        },
        { id: "read_website", label: "Read Website", value: "read_website" },
        { id: "edit_website", label: "Edit Website", value: "edit_website" },
      ],
    },
    {
      id: 32,
      name: "Blog Posts",
      permission: [
        {
          id: "create_blog_posts",
          label: "Create Blog Posts",
          value: "create_blog_posts",
        },
        {
          id: "update_blog_posts",
          label: "Update Blog Posts",
          value: "update_blog_posts",
        },
        {
          id: "delete_blog_posts",
          label: "Delete Blog Posts",
          value: "delete_blog_posts",
        },
        {
          id: "read_blog_posts",
          label: "Read Blog Posts",
          value: "read_blog_posts",
        },
        {
          id: "edit_blog_posts",
          label: "Edit Blog Posts",
          value: "edit_blog_posts",
        },
      ],
    },
    {
      id: 33,
      name: "Pages",
      permission: [
        { id: "create_pages", label: "Create Pages", value: "create_pages" },
        { id: "update_pages", label: "Update Pages", value: "update_pages" },
        { id: "delete_pages", label: "Delete Pages", value: "delete_pages" },
        { id: "read_pages", label: "Read Blog Pages", value: "read_pages" },
        { id: "edit_pages", label: "Edit Blog Pages", value: "edit_pages" },
      ],
    },
    {
      id: 34,
      name: "Suppliers",
      permission: [
        {
          id: "create_suppliers",
          label: "Create suppliers",
          value: "create_suppliers",
        },
        {
          id: "update_suppliers",
          label: "Update suppliers",
          value: "update_suppliers",
        },
        {
          id: "delete_suppliers",
          label: "Delete suppliers",
          value: "delete_suppliers",
        },
        {
          id: "read_suppliers",
          label: "Read suppliers",
          value: "read_suppliers",
        },
      ],
    },
    {
      id: 35,
      name: "Items",
      permission: [
        { id: "read_items", label: "Read Items", value: "read_items" },
      ],
    },
    {
      id: 36,
      name: "Purchases",
      permission: [
        {
          id: "create_purchases",
          label: "Create purchases",
          value: "create_purchases",
        },
        {
          id: "update_purchases",
          label: "Update purchases",
          value: "update_purchases",
        },
        {
          id: "delete_purchases",
          label: "Delete purchases",
          value: "delete_purchases",
        },
        {
          id: "read_purchases",
          label: "Read purchases",
          value: "read_purchases",
        },
        {
          id: "issue_item_purchases",
          label: "Issue item purchases",
          value: "issue_item_purchases",
        },
      ],
    },
    {
      id: 37,
      name: "Add stock",
      permission: [
        {
          id: "create_add_stock",
          label: "Create Add stock",
          value: "create_add_stock",
        },
        {
          id: "update_add_stock",
          label: "Update Add stock",
          value: "update_add_stock",
        },
        {
          id: "delete_add_stock",
          label: "Delete Add stock",
          value: "delete_add_stock",
        },
        {
          id: "read_add_stock",
          label: "Read Add stock",
          value: "read_add_stock",
        },
        {
          id: "edit_add_stock",
          label: "Edit Add stock",
          value: "edit_add_stock",
        },
      ],
    },
    {
      id: 38,
      name: "Issue Items",
      permission: [
        {
          id: "create_issue_items",
          label: "Create Issue Items",
          value: "create_issue_items",
        },
        {
          id: "update_issue_items",
          label: "Update Issue Items",
          value: "update_issue_items",
        },
        {
          id: "delete_issue_items",
          label: "Delete Issue Items",
          value: "delete_issue_items",
        },
        {
          id: "read_issue_items",
          label: "Read Issue Items",
          value: "read_issue_items",
        },
        {
          id: "edit_issue_items",
          label: "Edit Issue Items",
          value: "edit_issue_items",
        },
      ],
    },
    {
      id: 39,
      name: "Expenses",
      permission: [
        {
          id: "create_expenses",
          label: "Create expenses",
          value: "create_expenses",
        },
        {
          id: "update_expenses",
          label: "Update expenses",
          value: "update_expenses",
        },
        {
          id: "delete_expenses",
          label: "Delete expenses",
          value: "delete_expenses",
        },
        { id: "read_expenses", label: "Read expenses", value: "read_expenses" },
      ],
    },
    {
      id: 40,
      name: "KOT",
      permission: [
        { id: "create_kot", label: "Create KOT", value: "create_kot" },
        { id: "update_kot", label: "Update KOT", value: "update_kot" },
        { id: "delete_kot", label: "Delete KOT", value: "delete_kot" },
        { id: "read_kot", label: "Read KOT", value: "read_kot" },
        { id: "view_kot_bill", label: "View KOT Bill", value: "view_kot_bill" },
        {
          id: "generate_kot_bill",
          label: "Generate KOT Bill",
          value: "generate_kot_bill",
        },
        {
          id: "cancel_kot_bill",
          label: "Cancel KOT Bill",
          value: "cancel_kot_bill",
        },
      ],
    },
    {
      id: 41,
      name: "KOT Bill",
      permission: [
        {
          id: "generate_kot_bill",
          label: "Generate KOT Bill",
          value: "generate_kot_bill",
        },
        {
          id: "cancel_kot_bill",
          label: "Cancel KOT Bill",
          value: "cancel_kot_bill",
        },
      ],
    },
    {
      id: 42,
      name: "Food Sale",
      permission: [
        {
          id: "read_food_sale",
          label: "Read Food Sale",
          value: "read_food_sale",
        },
        {
          id: "create_food_sale",
          label: "Create Food Sale",
          value: "create_food_sale",
        },
        {
          id: "settle_bill_food_sale",
          label: "Settle bill food sale",
          value: "settle_bill_food_sale",
        },
        {
          id: "view_bill_food_sale",
          label: "View bill food sale",
          value: "view_bill_food_sale",
        },
        {
          id: "generate_bill_food_sale",
          label: "Generate bill food sale",
          value: "generate_bill_food_sale",
        },
      ],
    },
    {
      id: 43,
      name: "Events",
      permission: [
        { id: "create_events", label: "Create Events", value: "create_events" },
        { id: "update_events", label: "Update Events", value: "update_events" },
        { id: "delete_events", label: "Delete Events", value: "delete_events" },
        { id: "read_events", label: "Read Events", value: "read_events" },
        { id: "edit_events", label: "Edit Events", value: "edit_events" },
      ],
    },
    {
      id: 44,
      name: "Event Categories",
      permission: [
        {
          id: "create_event_categories",
          label: "Create Event Categories",
          value: "create_event_categories",
        },
        {
          id: "update_event_categories",
          label: "Update Event Categories",
          value: "update_event_categories",
        },
        {
          id: "delete_event_categories",
          label: "Delete Event Categories",
          value: "delete_event_categories",
        },
        {
          id: "read_event_categories",
          label: "Read Event Categories",
          value: "read_event_categories",
        },
        {
          id: "edit_event_categories",
          label: "Edit Event Categories",
          value: "edit_event_categories",
        },
      ],
    },
    {
      id: 45,
      name: "Settle Bill",
      permission: [
        {
          id: "create_settle_bill",
          label: "Create Settle Bill",
          value: "create_settle_bill",
        },
        {
          id: "update_settle_bill",
          label: "Update Settle Bill",
          value: "update_settle_bill",
        },
        {
          id: "delete_settle_bill",
          label: "Delete Settle Bill",
          value: "delete_settle_bill",
        },
        {
          id: "read_settle_bill",
          label: "Read Settle Bill",
          value: "read_settle_bill",
        },
        {
          id: "edit_settle_bill",
          label: "Edit Settle Bill",
          value: "edit_settle_bill",
        },
      ],
    },
    {
      id: 46,
      name: "Preview Voucher",
      permission: [
        {
          id: "read_preview_voucher",
          label: "Read Preview Voucher",
          value: "read_preview_voucher",
        },
      ],
    },
    {
      id: 47,
      name: "Banquet Menus",
      permission: [
        {
          id: "create_banquet_menus",
          label: "Create Banquet Menus",
          value: "create_banquet_menus",
        },
        {
          id: "update_banquet_menus",
          label: "Update Banquet Menus",
          value: "update_banquet_menus",
        },
        {
          id: "delete_banquet_menus",
          label: "Delete Banquet Menus",
          value: "delete_banquet_menus",
        },
        {
          id: "read_banquet_menus",
          label: "Read Banquet Menus",
          value: "read_banquet_menus",
        },
        {
          id: "edit_banquet_menus",
          label: "Edit Banquet Menus",
          value: "edit_banquet_menus",
        },
      ],
    },
    {
      id: 48,
      name: "Banquet Menu Items",
      permission: [
        {
          id: "create_banquet_menu_Items",
          label: "Create Banquet Menu Items",
          value: "create_banquet_menu_Items",
        },
        {
          id: "update_banquet_menu_Items",
          label: "Update Banquet Menu Items",
          value: "update_banquet_menu_Items",
        },
        {
          id: "delete_banquet_menu_Items",
          label: "Delete Banquet Menu Items",
          value: "delete_banquet_menu_Items",
        },
        {
          id: "read_banquet_menu_Items",
          label: "Read Banquet Menu Items",
          value: "read_banquet_menu_Items",
        },
        {
          id: "edit_banquet_menu_Items",
          label: "Edit Banquet Menu Items",
          value: "edit_banquet_menu_Items",
        },
      ],
    },
    {
      id: 49,
      name: "Banquet Menu Categories",
      permission: [
        {
          id: "create_banquet_menu_categories",
          label: "Create Banquet Menu Categories",
          value: "create_banquet_menu_categories",
        },
        {
          id: "update_banquet_menu_categories",
          label: "Update Banquet Menu Categories",
          value: "update_banquet_menu_categories",
        },
        {
          id: "delete_banquet_menu_categories",
          label: "Delete Banquet Menu Categories",
          value: "delete_banquet_menu_categories",
        },
        {
          id: "read_banquet_menu_categories",
          label: "Read Banquet Menu Categories",
          value: "read_banquet_menu_categories",
        },
        {
          id: "edit_banquet_menu_categories",
          label: "Edit Banquet Menu Categories",
          value: "edit_banquet_menu_categories",
        },
      ],
    },
    {
      id: 50,
      name: "Communications",
      permission: [
        {
          id: "create_communications",
          label: "Create Communications",
          value: "create_communications",
        },
        {
          id: "send_communications",
          label: "Send Communications",
          value: "send_communications",
        },
        {
          id: "delete_communications",
          label: "Delete Communications",
          value: "delete_communications",
        },
        {
          id: "read_communications",
          label: "Read Communications",
          value: "read_communications",
        },
        {
          id: "edit_communications",
          label: "Edit Communications",
          value: "edit_communications",
        },
      ],
    },
    {
      id: 51,
      name: "Settings",
      permission: [
        {
          id: "assign_staff_permissions",
          label: "Assign Staff Permissions",
          value: "assign_staff_permissions",
        },
        {
          id: "create_settings",
          label: "Create Settings",
          value: "create_settings",
        },
        {
          id: "update_settings",
          label: "Update Settings",
          value: "update_settings",
        },
        {
          id: "delete_settings",
          label: "Delete Settings",
          value: "delete_settings",
        },
        { id: "read_settings", label: "Read Settings", value: "read_settings" },
        { id: "edit_settings", label: "Edit Settings", value: "edit_settings" },
      ],
    },

    {
      id: 52,
      name: "Housekeeping Dashboard",
      permission: [
        {
          id: "create_housekeeping_dashboard",
          label: "Create Housekeeping Dashboard",
          value: "create_housekeeping_dashboard",
        },
        {
          id: "update_housekeeping_dashboard",
          label: "Update Housekeeping Dashboard",
          value: "update_housekeeping_dashboard",
        },
        {
          id: "delete_housekeeping_dashboard",
          label: "Delete Housekeeping Dashboard",
          value: "delete_housekeeping_dashboard",
        },
        {
          id: "read_housekeeping_dashboard",
          label: "Read Housekeeping Dashboard",
          value: "read_housekeeping_dashboard",
        },
      ],
    },
    {
      id: 53,
      name: "Reports",
      permission: [
        {
          id: "daily_activity_reports",
          label: "Daily Activity Reports",
          value: "daily_activity_reports",
        },
        {
          id: "production_reports",
          label: "Production Reports",
          value: "production_reports",
        },
        {
          id: "finance_reports",
          label: "Finance Reports",
          value: "finance_reports",
        },
        {
          id: "competitors_analysis",
          label: "Competitors Analysis",
          value: "competitors_analysis",
        },
        {
          id: "store_and_purchases_reports",
          label: "Store and Purchases Reports",
          value: "store_and_purchases_reports",
        },
      ],
    },

    {
      id: 54,
      name: "Housekeeping Staff",
      permission: [
        {
          id: "create_housekeeping_staff",
          label: "Create Housekeeping Staff",
          value: "create_housekeeping_staff",
        },
        {
          id: "update_housekeeping_staff",
          label: "Update Housekeeping Staff",
          value: "update_housekeeping_staff",
        },
        {
          id: "delete_housekeeping_staff",
          label: "Delete Housekeeping Staff",
          value: "delete_housekeeping_staff",
        },
        {
          id: "read_housekeeping_staff",
          label: "Read Housekeeping Staff",
          value: "read_housekeeping_staff",
        },
        {
          id: "active_housekeeping_staff",
          label: "Active Housekeeping Staff",
          value: "active_housekeeping_staff",
        },
        {
          id: "inactive_housekeeping_staff",
          label: "Inactive Housekeeping Staff",
          value: "inactive_housekeeping_staff",
        },
      ],
    },

    {
      id: 55,
      name: "Daily Activity Reports",
      permission: [
      
        {
          id: "daily_activity_departure",
          label: "Daily Activity Departure",
          value: "daily_activity_departure",
        },
        {
          id: "daily_activity_in_house",
          label: "Daily Activity In House",
          value: "daily_activity_in_house",
        },
        {
          id: "daily_activity_cancellation",
          label: "Daily Activity Cancellation",
          value: "daily_activity_cancellation",
        },
        {
          id: "daily_activity_account_balance",
          label: "Daily Activity Account Balance",
          value: "daily_activity_account_balance",
        },
        {
          id: "daily_activity_no_show",
          label: "Daily Activity No Show",
          value: "daily_activity_no_show",
        },
        {
          id: "daily_activity_room_assignment",
          label: "Daily Activity Room Assignment",
          value: "daily_activity_room_assignment",
        },
        {
          id: "daily_activity_payment_reconciliation",
          label: "Daily Activity Payment Reconciliation",
          value: "daily_activity_payment_reconciliation",
        },
        {
          id: "daily_activity_revenue_report",
          label: "Daily Activity Payment Reconciliation",
          value: "daily_activity_revenue_report",
        },
      ],
    },
    {
      id: 56,
      name: "Production Reports",
      permission: [
       
        {
          id: "production_occupancy",
          label: "Production Occupancy",
          value: "production_occupancy",
        },
        {
          id: "production_reservation_by_region",
          label: "Production Reservation By Region",
          value: "production_reservation_by_region",
        },
        {
          id: "production_adr_report",
          label: "Production ADR Report",
          value: "production_adr_report",
        },
        {
          id: "production_reservations_by_type",
          label: "Production Reservations By Type",
          value: "production_reservations_by_type",
        },
        {
          id: "production_reservations_by_plan",
          label: "Production Reservations By Plan",
          value: "production_reservations_by_plan",
        },
        {
          id: "production_room_occupancy",
          label: "Production Room Occupancy",
          value: "production_room_occupancy",
        },
        {
          id: "production_complimentary_kot",
          label: "Production Complimentary KOT's",
          value: "production_complimentary_kot",
        },
        {
          id: "production_cancelled_kot",
          label: "Production Cancelled KOT's",
          value: "production_cancelled_kot",
        },
      ],
    },
    {
      id: 57,
      name: "Finance Reports",
      permission: [
    
        {
          id: "finance_payment_ledger_report",
          label: "Finance Payment Ledger Report",
          value: "finance_payment_ledger_report",
        },
        {
          id: "finance_invoice_report",
          label: "Finance Invoice Report",
          value: "finance_invoice_report",
        },
        {
          id: "finance_tax_report",
          label: "Finance Tax Report",
          value: "finance_tax_report",
        },
        {
          id: "finance_commission_report",
          label: "Finance Commission Report",
          value: "finance_commission_report",
        },
        {
          id: "finance_food_sale_report",
          label: "Finance Food Sale Report",
          value: "finance_food_sale_report",
        },
        {
          id: "finance_agent_sale_report",
          label: "Finance Agent Sale Report",
          value: "finance_agent_sale_report",
        },
        {
          id: "finance_itemized_sale_report",
          label: "Finance Itemized Sale Report",
          value: "finance_itemized_sale_report",
        },
        {
          id: "finance_city_ledger_report",
          label: "Finance City Ledger Report",
          value: "finance_city_ledger_report",
        },
        {
          id: "finance_cashier_report",
          label: "Finance Cashier Report",
          value: "finance_cashier_report",
        },
        {
          id: "finance_wise_sale_report",
          label: "Finance Sale Report",
          value: "finance_wise_sale_report",
        },
      ],
      
    },
    {
      id: 58,
      name: "F & B Discount",
      permission: [
    
        {
          id: "f_&_b_discount",
          label: "f & b discount",
          value: "f_&_b_discount",
        },
      ],
      
    },
    
  ]);
  const permissions = options.flatMap((option) =>
    option.permission.map((permission) => permission.id)
  );

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    if (property_id) {
      fetchInfo();
    }
  }, [property_id]);

  const fetchInfo = async () => {
    try {
      const response = await fetch(
        // https://dev.revcatalyst.in/api/v1/account/single/user/44/4
        `${process.env.REACT_APP_BASE_URL}/api/v1/account/single/user/${staffid}/${property_id.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      //   if (data.message === "Unauthorised.") {
      //     navigate("/");
      //   }
      setSelectedPermissions(data.permissions);
      setPermissionsArray(data.permissions);
    } catch (error) {
      //   setLoader(false);
    }
  };

 

  const [editMode, setEditMode] = useState(false);
  const [permissionsArray, setPermissionsArray] = useState(selectedPermissions);
  const handlePermissionUpdateClick = () => {
    fetch(
      // https://dev.revcatalyst.in/api/v1/account/staff/update/4
      `${process.env.REACT_APP_BASE_URL}/api/v1/account/staff/update/${property_id.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          updatedPermissions: permissionsArray,
          staff_id: staffid,
          property_id: property_id.id,
          // role: role,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      });
  };
  const handlePermissionChange = (e) => {
    if (e.target.checked) {
      setPermissionsArray([...permissionsArray, e.target.value]);
    } else {
      setPermissionsArray(
        permissionsArray.filter((permission) => permission !== e.target.value)
      );
    }
  };

  const handleEditClick = () => {
    if (editMode) {
      setEditMode(false);
    } else {
      setEditMode(true);
    }
    setPermissionsArray(selectedPermissions);
  };

  const handlePermissionSelectAll = () => {
    setPermissionsArray(permissions);
  };
  const handlePermissionClearAll = () => {
    setPermissionsArray([]);
  };
  const [indexToShow, seIndexToShow] = useState(0);

  const styles = {
    optionContainer: {
      borderBottom: "1px solid #ccc",
      marginTop: "20px",
      paddingBottom: "5px",
      marginBottom: "5px",
      cursor: "pointer",
    },
    optionName: {
      fontWeight: "bold",
      fontFamily: "Inter",
      fontSize: "16px",
    },
    permissionTd: {
      display: "flex",
      alignItems: "center",
    },
    label: {
      marginRight: "10px",
      cursor: "pointer",
    },
    RotateButton: {
      cursor: "pointer",
      border: "none",
      background: "transparent",
    },
  };
  console.log(permissionsArray, "permissionsArray");
  
  return (
    <>
      <div
        className="main_container"
        style={{ width: "80%", margin: "auto" }}
      >
        <div style={{background: "#fff" ,padding:"20px",borderRadius:"10px"}}>
         

        <div className="room-types-full-container">
          <div className="room-types-full-container-div">
            <div className="room-type-heading">Permissions</div>
            <div style={{ display: "flex", gap: "10px" }}>
              {/* {permissions && permissions.includes("create_rooms") ? ( */}
              <BgThemeButton
                onClick={handlePermissionClearAll}
                children={"Clear all"}
              />
              <BgThemeButton
                onClick={handleEditClick}
                children={"Select Preferred Permissions"}
              />
              <BgThemeButton
                onClick={handlePermissionSelectAll}
                children={"Select all"}
              />
              {/* ) : (
                <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                  <BgThemeButtonDisabled
                    // onClick={addModalButtonClickHandler}
                    children={"Add Rooms"}
                  />
                </Tooltip>
              )} */}
            </div>
          </div>
        </div>

        {/* <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            width: "100%",
            backgroundColor: "#fff",
            margin: "auto",
            padding: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {permissions.map((permission, index) => {
            return (
              <div key={index} style={{ width: "200px" }}>
                <input
                  type="checkbox"
                  value={permission}
                  id={permission}
                  onChange={handlePermissionChange}
                  checked={permissionsArray.includes(permission)}
                  style={{ marginRight: "10px" }}
                />
                <label for={permission} style={{ cursor: "pointer" }}>
                  {" "}
                  {permission.replace(/_/g, " ")}
                </label>
              </div>
            );
          })}
        </div> */}
        <div
          style={{
            overflow: "auto",
            height: "400px",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "8px",
          }}
          className="overflowContainer"
        >
          {options.map((option, index) => (
            <React.Fragment key={index}>
              <div
                style={styles.optionContainer}
                className="flex justify_content_between"
                onClick={() => seIndexToShow(index)}
              >
                <p style={styles.optionName}>{option.name}</p>
               <div>
               <span>
                 
                 {
                   permissionsArray.filter((permission) =>
                     option.permission.some(
                       (item) => item.value === permission
                     )
                   ).length
                 }
                 /{option.permission.length}
               </span>
               
                 <svg
                   width="12"
                   height="8"
                   viewBox="0 0 12 8"
                   fill="none"
                   style={{
                     transform: `rotate(${
                       indexToShow === index ? 180 : 0
                     }deg)`,
                     marginLeft: "10px",
                   }}
                   xmlns="http://www.w3.org/2000/svg"
                 >
                   <path
                     d="M11 1.5L6 6.5L0.999999 1.5"
                     stroke="#888888"
                     strokeWidth="1.5"
                   />
                 </svg>
               </div>
               
              </div>
              {indexToShow === index && (
                <>
                  <div>
                    {option.permission.map((item, pIndex) => (
                      <td key={pIndex} style={styles.permissionTd}>
                        <input
                          type="checkbox"
                          value={item.value}
                          id={item.label}
                          onChange={handlePermissionChange}
                          checked={permissionsArray.includes(item.value)}
                          style={styles.label}
                        />
                        <label htmlFor={item.label} style={styles.label}>
                          {item.label}
                        </label>
                      </td>
                    ))}
                  </div>
                </>
              )}
            </React.Fragment>
          ))}
        </div>

        <div style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
          <BgThemeButton
            onClick={handlePermissionUpdateClick}
            loader={loader}
            className={"permission_edit_btn"}
          >
            Update Permissions
          </BgThemeButton>
          <a href="/settings/staffs">
            <BgOutlineThemeButton className={"permission_edit_btn"}>
              Discard
            </BgOutlineThemeButton>
          </a>
        </div>






        </div>
        
      </div>
    </>
  );
};

export default StaffPermissions;







