import React, { useRef, useState, useEffect } from "react";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import { websites_inner_lists } from "../../assets/constant";
import SelectDateRangeWithoutInput from "../CustomComponents/SelectDateRangeWithoutInput/SelectDateRangeWithoutInput";
import { BgThemeButton } from "../UI/Buttons";
import NoDataFound from "../CustomComponents/NoDataFound";

const WebsitesComp = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateRangeShowCustom, setDateRangeShowCustom] = useState(false);
  const [valueArray, setValueArray] = useState([]);
  const accordRef = useRef(null);

  const dateOptions = [
    { id: 1, value: "-7", label: "Past 7 Days" },
    { id: 2, value: "-30", label: "Past 30 Days" },
    { id: 3, value: "-90", label: "Past 90 Days" },
  ];

  const customDateClickHandler = () => {};

  const dataFromChild = (from_date, to_date) => {};

  const handleAccordClick = (id, label, value) => {
    setValueArray([{ id: id, label: label, value: value }]);
  };

  const toggleAccord = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (accordRef.current && !accordRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [accordRef]);

  return (
    <>
      <SidebarInnerLists innerLists={websites_inner_lists} />
      {/* <NoDataFound /> */}
      <div className="common_title">
        <h2>Website</h2>

        <div className="reservation_bar_container" style={{ margin: "20px 0" }}>
          <div
            style={{ position: "relative", paddingRight: "14px" }}
            className="reservation_dateRange toggleAccord"
          >
            <div
              onClick={() => setDateRangeShowCustom(false)}
              className="reservation_dateRange_calender_div"
            >
              <SelectDateRangeWithoutInput dataFromChild={dataFromChild} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
              ref={accordRef}
              onClick={toggleAccord}
            >
              <p className="reservation_total_paragraph thisMonth">
                {valueArray.length > 0 ? valueArray[0].label : "This Month"}
              </p>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  className={`${isOpen ? "accordImageRotate" : null} `}
                >
                  <path d="M1 1L5 5L9 1" stroke="#333333" />
                </svg>
              </div>
            </div>

            {isOpen && (
              <>
                <div
                  style={{
                    position: "absolute",
                    border: "1px solid #aaaaaa",
                    left: "0",
                    width: "100%",
                    height: "fit-content",
                    zIndex: 999,
                  }}
                  className="accord_container"
                >
                  {dateOptions.map((qwer) => (
                    <>
                      <div
                        key={qwer.id}
                        className="accord_container_values"
                        onClick={() =>
                          handleAccordClick(qwer.id, qwer.label, qwer.value)
                        }
                      >
                        {qwer.label}
                      </div>
                    </>
                  ))}
                  <BgThemeButton onClick={() => customDateClickHandler()}>
                    Custom
                  </BgThemeButton>
                </div>
              </>
            )}
          </div>

          {dateRangeShowCustom && (
            <div
              style={{
                position: "absolute",
                top: "20px",
                zIndex: "999",
              }}
            >
              <SelectDateRangeWithoutInput dataFromChild={dataFromChild} />
            </div>
          )}

          <div className="reservation_total ">
            <h5 className="reservation_total_heading">150</h5>
            <p
              style={{ fontWeight: "600" }}
              className="reservation_total_paragraph"
            >
              Total Reservations
            </p>
          </div>
          <div className="reservation_total confirmed">
            <h5 className="reservation_total_heading">140</h5>
            <p
              style={{ fontWeight: "600" }}
              className="reservation_total_paragraph"
            >
              Confirmed
            </p>
          </div>
          <div className="reservation_total confirmed">
            <h5 className="reservation_total_heading">08</h5>
            <p
              style={{ fontWeight: "600" }}
              className="reservation_total_paragraph"
            >
              Cancelled
            </p>
          </div>
          <div className="reservation_total pending confirmed">
            <h5 className="reservation_total_heading">02</h5>
            <p
              style={{ fontWeight: "600" }}
              className="reservation_total_paragraph"
            >
              Pending
            </p>
          </div>
          <div className="reservation_downloadImage">
            <button className="reservation_downloadImage_button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  d="M15.0001 18.6429L10.4465 14.0894L11.7215 12.7688L14.0894 15.1367V7.71436H15.9108V15.1367L18.2786 12.7688L19.5536 14.0894L15.0001 18.6429ZM9.53579 22.2858C9.03489 22.2858 8.60595 22.1073 8.24895 21.7503C7.89195 21.3933 7.71375 20.9646 7.71436 20.4644V17.7322H9.53579V20.4644H20.4644V17.7322H22.2858V20.4644C22.2858 20.9652 22.1073 21.3942 21.7503 21.7512C21.3933 22.1082 20.9646 22.2864 20.4644 22.2858H9.53579Z"
                  fill="#888888"
                />
                <rect
                  x="0.25"
                  y="0.25"
                  width="29.5"
                  height="29.5"
                  rx="3.75"
                  stroke="#CCCCCC"
                  strokeWidth="0.5"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="webpage_box3">
          <div className="webpage_box3_image "></div>
          <div className="webpage_box3_textcontent" s>
            <div style={{ display: "flex", gap: "10px" }}>
              <p className="webpage_box3_textcontent_heading">
                Golden Fern Resort
              </p>
              <p className="webpage_box3_textcontent_paragraphs">
                Active theme
              </p>
            </div>
            <p className="webpage_box3_textcontent_paragraphs_white">
              Last saved: Jan 13, 2022, GMT+11
            </p>
            <p className="webpage_box3_textcontent_paragraphs_white">
              Debut version 16.5.2
            </p>
          </div>
          <div
            style={{ margin: " 0 20px 0 auto", height: "35px", width: "35px" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
            >
              <rect
                x="0.25"
                y="0.25"
                width="34.5"
                height="34.5"
                rx="3.75"
                stroke="#888888"
                strokeWidth="0.5"
              />
              <path
                d="M12.2059 17.3529C12.2059 18.6524 11.1524 19.7059 9.85294 19.7059C8.55345 19.7059 7.5 18.6524 7.5 17.3529C7.5 16.0534 8.55345 15 9.85294 15C11.1524 15 12.2059 16.0534 12.2059 17.3529Z"
                fill="#888888"
              />
              <path
                d="M19.8529 17.3529C19.8529 18.6524 18.7995 19.7059 17.5 19.7059C16.2005 19.7059 15.1471 18.6524 15.1471 17.3529C15.1471 16.0534 16.2005 15 17.5 15C18.7995 15 19.8529 16.0534 19.8529 17.3529Z"
                fill="#888888"
              />
              <path
                d="M27.5 17.3529C27.5 18.6524 26.4466 19.7059 25.1471 19.7059C23.8476 19.7059 22.7941 18.6524 22.7941 17.3529C22.7941 16.0534 23.8476 15 25.1471 15C26.4466 15 27.5 16.0534 27.5 17.3529Z"
                fill="#888888"
              />
            </svg>
          </div>
        </div>

        <div className="webpage_box4">
          <h5 className="webpage_box4_heading">Free Themes</h5>
          <p className="webpage_box4_paragraph">
            Made with core features you can easily customize—no coding needed.
          </p>

          <div className="webpage_box4_child">
            <div className="webpage_box4_child_card">
              <div className="webpage_box4_child_card_image"></div>
              <div className="webpage_box4_child_card_text">
                <div className="webpage_box4_child_card_text_container">
                  <h5 className="webpage_box4_child_card_text_heading">
                    Lorem Ipsum
                  </h5>
                  <p className="webpage_box4_child_card_text_paragraph">
                    By Revcatalyst
                  </p>
                </div>
                <div className="webpage_box4_child_card_button_container">
                  <button className="webpage_box4_child_card_button">
                    Publish
                  </button>
                </div>
              </div>
            </div>
            <div className="webpage_box4_child_card">
              <div className="webpage_box4_child_card_image"></div>
              <div className="webpage_box4_child_card_text">
                <div className="webpage_box4_child_card_text_container">
                  <h5 className="webpage_box4_child_card_text_heading">
                    Lorem Ipsum
                  </h5>
                  <p className="webpage_box4_child_card_text_paragraph">
                    By Revcatalyst
                  </p>
                </div>
                <div className="webpage_box4_child_card_button_container">
                  <button className="webpage_box4_child_card_button">
                    Publish
                  </button>
                </div>
              </div>
            </div>
            <div className="webpage_box4_child_card">
              <div className="webpage_box4_child_card_image"></div>
              <div className="webpage_box4_child_card_text">
                <div className="webpage_box4_child_card_text_container">
                  <h5 className="webpage_box4_child_card_text_heading">
                    Lorem Ipsum
                  </h5>
                  <p className="webpage_box4_child_card_text_paragraph">
                    By Revcatalyst
                  </p>
                </div>
                <div className="webpage_box4_child_card_button_container">
                  <button className="webpage_box4_child_card_button">
                    Publish
                  </button>
                </div>
              </div>
            </div>

            <div className="webpage_box4_child_card">
              <div className="webpage_box4_child_card_image"></div>
              <div className="webpage_box4_child_card_text">
                <div className="webpage_box4_child_card_text_container">
                  <h5 className="webpage_box4_child_card_text_heading">
                    Lorem Ipsum
                  </h5>
                  <p className="webpage_box4_child_card_text_paragraph">
                    By Revcatalyst
                  </p>
                </div>
                <div className="webpage_box4_child_card_button_container">
                  <button className="webpage_box4_child_card_button">
                    Publish
                  </button>
                </div>
              </div>
            </div>

            <div className="webpage_box4_child_card">
              <div className="webpage_box4_child_card_image"></div>
              <div className="webpage_box4_child_card_text">
                <div className="webpage_box4_child_card_text_container">
                  <h5 className="webpage_box4_child_card_text_heading">
                    Lorem Ipsum
                  </h5>
                  <p className="webpage_box4_child_card_text_paragraph">
                    By Revcatalyst
                  </p>
                </div>
                <div className="webpage_box4_child_card_button_container">
                  <button className="webpage_box4_child_card_button">
                    Publish
                  </button>
                </div>
              </div>
            </div>

            <div className="webpage_box4_child_card">
              <div className="webpage_box4_child_card_image"></div>
              <div className="webpage_box4_child_card_text">
                <div className="webpage_box4_child_card_text_container">
                  <h5 className="webpage_box4_child_card_text_heading">
                    Lorem Ipsum
                  </h5>
                  <p className="webpage_box4_child_card_text_paragraph">
                    By Revcatalyst
                  </p>
                </div>
                <div className="webpage_box4_child_card_button_container">
                  <button className="webpage_box4_child_card_button">
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebsitesComp;
