import React from "react";
import WebsitesComp from "../../Components/Websites/WebsitesComp";

const Websites = () => {
  return (
    <>
      <div className="main_container">
        
        <WebsitesComp />
      </div>
    </>
  );
};

export default Websites;
