import { IndexTable, LegacyCard } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../assets/toastUtils';
import CustomCssComponentTableText from './CustomComponents/CustomCssComponentTableText';
import NoDataFound from './CustomComponents/NoDataFound';
import { useSelector } from 'react-redux';

const AddHoldRoomType = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigates to the previous page in the history
  };

  const ToggleActiveDisable = (props) => {
    const [isToggled, setIsToggled] = useState(props.status === "enable");
    const [beddingFinalValue, setBeddingFinalValue] = useState("");
    const userToken = JSON.parse(localStorage.getItem("userToken"));
    const user = JSON.parse(localStorage.getItem("user"));
    const property_id = useSelector((state) => state.property.value);
  
    const handleToggle = useCallback(() => {
      setIsToggled((prevIsToggled) => !prevIsToggled);
    }, []);
  
    const handleToggleStatus = async () => {
      if (isToggled) {
        // setBeddingFinalValue("enabled");
        await handleDisable();
      } else {
        await handleEnable();
        // setBeddingFinalValue("enabled");
      }
    };
    const [loader, setLoader] = useState(true);
    const currentDate = new Date().toISOString().split("T")[0];
    const handleDisable = async () => {
      const userId = props.userId;
      try {
        setLoader(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/update/active/status/0/${props.userId}/${property_id?.id}`,
          {
            method: "GET",
            // body: JSON.stringify({ user_id: userId }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        const data = await response.json();
        if (data.success) {
          showSuccessToast(data.message);
          setIsToggled(false);
          window.location.reload();
        } else {
          showErrorToast(data.message);
        }
      } catch (error) {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      }
    };
  
    const handleEnable = async () => {
      const userId = props.userId;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/update/active/status/1/${props.userId}/${property_id?.id}`,
          {
            method: "GET",
            // body: JSON.stringify({ user_id: userId }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        const data = await response.json();
        if (data.success) {
          showSuccessToast(data.message);
          setIsToggled(1);
          window.location.reload();
        } else {
          showErrorToast(data.message);
          setIsToggled(false);
        }
      } catch (error) {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      }
    };
  
    useEffect(() => {
      if (isToggled === false) {
        setBeddingFinalValue("Disabled");
      } else {
        setBeddingFinalValue("Enabled");
      }
    }, [isToggled]);
  
    return (
      <div className="flex align_items_center">
        {/* <span className="toggle_text">{beddingFinalValue} Base Category:</span> */}
        <label className="toggle-container">
          <input
            name="extra_bedding"
            type="checkbox"
            checked={isToggled}
            onChange={handleToggle}
            className="toggle-checkbox-custom"
          />
          <span
            className="toggle-slider"
            onClick={() => handleToggleStatus()}
          ></span>
        </label>
      </div>
    );
  };

   // Pagignation
   const itemsPerPage = 10;
   const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
//   const currentOrders = orders?.slice(startIndex, endIndex);

  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(true);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const fetchInfo = async (propertyId) => {
    try {
      setLoader(true); // Set loading before sending API request
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setOrders(data.plans);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  const property_id = useSelector((state) => state.property.value);


  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
    }
  }, [property_id]);

  const currentOrders = orders?.slice(startIndex, endIndex);

  const rowMarkup = currentOrders?.map(
    ({ id, meal_plan_type, meal_plan_name, is_active, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        // selected={selectedResources.includes(id)}
        position={id}
      >
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={meal_plan_type}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={meal_plan_name}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <ToggleActiveDisable
            positive={"enable"}
            negative={"disable"}
            status={is_active === 0 ? "disable" : "enable"}
            userId={id}
          />
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  return (
    <div className="room-types-full-container">
            <div className="room-types-full-container-divs">
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem', marginTop: "20px", gap: "20px"}}>
      <svg
      style={{cursor: "pointer"}}
      onClick={() => handleGoBack()}
                  className="back-btn"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <rect
                    x="29.75"
                    y="29.75"
                    width="29.5"
                    height="29.5"
                    rx="5.75"
                    transform="rotate(180 29.75 29.75)"
                    stroke="#666666"
                    strokeWidth="0.5"
                  />
                  <line
                    x1="21"
                    y1="15.043"
                    x2="9"
                    y2="15.043"
                    stroke="#666666"
                  />
                  <path
                    d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
                    stroke="#666666"
                    strokeWidth="1.2"
                  />
                </svg>
             
              <h4 style={{ fontWeight: "bold", fontSize: "18px" }}>
                Agent Contracts
              </h4>
              </div>

              {orders && orders.length > 0 ? (
              <>
                <LegacyCard>
                  <IndexTable
                    // resourceName={resourceName}
                    itemCount={currentOrders?.length}
                    // selectedItemsCount={
                    //   allResourcesSelected ? "All" : selectedResources.length
                    // }
                    // onSelectionChange={handleSelectionChange}
                    headings={[
                      { title: "Plan Type" },
                      { title: " " },
                      { title: " " },
                      { title: " " },
                      { title: " " },
                      { title: " " },
                      { title: " " },
                      { title: " " },
                      { title: " " },
                      { title: " " },
                      { title: "Plan Name" },
                    ]}
                  >
                    {rowMarkup}
                  </IndexTable>
                </LegacyCard>

                {/* <CustomPagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={orders.length}
                  onPageChange={handlePageChange}
                  clearSelection={clearSelection}
                /> */}
              </>
            ) : (
              <NoDataFound />
            )}
      </div>
      </div>
   
  );
};

export default AddHoldRoomType;
