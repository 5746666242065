import React, { useEffect, useState } from "react";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import { Button, LegacyCard } from "@shopify/polaris";
import { formatIndianCurrency } from "../../utils/utils";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import LoaderSmall from "../UI/Loader/LoaderSmall";

const PropertyDomains = ({ editSettingTrue, setEditSettingTrue }) => {
  const selectedProperty = JSON.parse(localStorage.getItem("selectedProperty"));
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const [showAddStaffPopup, setShowAddStaffPopup] = useState(false);
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const [deletedSingleIds, setDeletedSingleIds] = useState([]);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const [filterType, setFilterType] = useState("room_tax");

  // Onchange Function
  const setAddItemDataHandleChangeOther = (event) => {
    setDynamicInputListOtherData({
      ...dynamicInputListOtherData,
      [event.target.name]: event.target.value,
    });
  };
  const setAddItemDataHandleChangeRoom = (event) => {
    setDynamicInputListRoomData({
      ...dynamicInputListRoomData,
      [event.target.name]: event.target.value,
    });
  };

  const [paymentGateways, setPaymentGateways] = useState([]);
  const getPropertyDomainHandle = (e) => {
    if (e) {
      e.preventDefault();
    }
    setLoader(true);
    fetch(
      // dev.revcatalyst.in/api/v1/payment_gateway/list/{property_id?}
      `${process.env.REACT_APP_BASE_URL}/api/v1/property/domain/list/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setPaymentGateways(data.data);
          setLoader(false);
        } else {
          showErrorToast(data.message);
          setLoader(false);
        }
        // setLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoader(false);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const getOtherTaxesHandle = (e) => {
    if (e) {
      e.preventDefault();
    }
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/other/tax/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          getPropertyDomainHandle();
          setShowDynamicInputListOther(data.data);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const [editId, setEditId] = useState("");

  // Edit
  const getOneDataRoom = async (id) => {
    setEditId(id);
    try {
      const response = await fetch(
        // dev.revcatalyst.in/api/v1/payment_gateway/edit/{id}/{property_id?}
        `${process.env.REACT_APP_BASE_URL}/api/v1/property/domain/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        setAddDomainData(data.data);

        setEditShowDynamicInputListRoom(true);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateOtherTaxesHandle = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      // setLoader(true);
      const response = await fetch(
        // api/v1/update/room/tax/10
        `${process.env.REACT_APP_BASE_URL}/api/v1/update/other/tax/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...dynamicInputListOtherData }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setDynamicInputListOtherData({});
        setEditShowDynamicInputListOther(false);
        getOtherTaxesHandle();
      } else {
        showErrorToast(data.message);
        // setLoader(false);
      }
    } catch (error) {
      // setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  // Delete

  const deleteRoomTaxesHandle = async () => {
    const response = await fetch(
      // dev.revcatalyst.in/api/v1/payment_gateway/delete/{id}/{property_id?}
      `${process.env.REACT_APP_BASE_URL}/api/v1/property/domain/delete/${deletedSingleIds}/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id: deletedSingleIds }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setShowModelMenuActive(false);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    if (property_id) {
      getPropertyDomainHandle();
    }
  }, [property_id]);

  const handleAddDomainGateway = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/property/domain/add/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify(addDomainData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setShowAddStaffPopup(false);
          showSuccessToast(data.message);
          setAddDomainData({});
          getPropertyDomainHandle();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const handleUpdatePaymentGateway = (event, id) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/property/domain/update/${editId}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify(addDomainData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // window.location.reload();
        if (data.success) {
          setShowAddStaffPopup(false);
          setEditShowDynamicInputListRoom(false);
          showSuccessToast(data.message);
          setAddDomainData({});
          getPropertyDomainHandle();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const addonsForBanquetEvents = [
    { id: 0, label: "Select Option" },
    { id: 1, label: "F&B", value: "F&B" },
    { id: 3, label: "Housekeeping", value: "housekeeping" },
    // { id: 3, label: "Room Settlement", value: "room_settlement" },
    { id: 4, label: "Miscellaneous", value: "misc" },
  ];

  const roomTaxAddons = [
    { id: 0, label: "Select Option", value: "" },
    { id: 3, label: "Room Taxes", value: "room_tax" },
  ];

  const taxRateOptions = [
    { id: 0, label: "Select Option" },
    { id: 1, label: "5%", value: "5" },
    { id: 2, label: "12%", value: "12" },
    { id: 3, label: "18%", value: "18" },
  ];

  const [dynamicInputList, setDynamicInputList] = useState([
    {
      id: 1,
      tax_name: "",
      hsn_code: "",
      tax_rate: "",
      tax_name: "",
    },
  ]);

  const [dynamicInputListRoomData, setDynamicInputListRoomData] = useState({
    tax_name: "",
    hsn_code: "",
    tax_rate: "",
    tax_name: "",
  });
  const [dynamicInputListOtherData, setDynamicInputListOtherData] = useState({
    tax_name: "",
    hsn_code: "",
    tax_rate: "",
    tax_name: "",
    min: "",
    max: "",
  });

  const handleAddClick = (id) => {
    const lastItem = dynamicInputList[dynamicInputList.length - 1];
    if (
      (lastItem && lastItem.tax_name === "") ||
      lastItem.hsn_code === "" ||
      lastItem.hsn_code === " " ||
      lastItem.tax_rate === "" ||
      lastItem.tax_rate === " " ||
      lastItem.tax_name === " " ||
      lastItem.tax_name === ""
    ) {
      showErrorToast("Please add the above fields first...!!");
    } else {
      setDynamicInputList([
        ...dynamicInputList,
        {
          id: id + 1,
          tax_name: "",
          hsn_code: "",
          tax_rate: "",
          tax_name: "",
        },
      ]);
    }
  };

  const handleRemoveClick = (idToRemove) => {
    const updatedList = dynamicInputList.filter(
      (item) => item.id !== idToRemove
    );
    setDynamicInputList(updatedList);
  };

  // const dynamicPreviewBillHandleChange = (e, index) => {
  //   const { name, value } = e.target;

  //   const isDuplicate = dynamicInputList.some(
  //     (item, i) => i !== index && item.tax_name === value && name === "tax_name"
  //   );
  //   const isDuplicateHn = dynamicInputList.some(
  //     (item, i) => i !== index && item.hsn_code === value && name === "hsn_code"
  //   );

  //   if (isDuplicate) {
  //     showErrorToast("Duplicate entry.");
  //     return;
  //   }
  //   if (isDuplicateHn) {
  //     showErrorToast("Duplicate HSN code.");
  //     return;
  //   }

  //   setDynamicInputList((prevInputList) => {
  //     const updatedInputList = [...prevInputList];
  //     updatedInputList[index][name] = value;
  //     return updatedInputList;
  //   });
  // };

  const dynamicPreviewBillHandleChange = (e, index) => {
    const { name, value } = e.target;
    const fieldName = name === "tax_name" ? "tax_name" : "hsn_code";

    const isDuplicate = dynamicInputList.some(
      (item, i) => i !== index && item[fieldName] === value
    );

    if (isDuplicate) {
      showErrorToast(
        name === "tax_name" ? "Duplicate entry." : "Duplicate HSN code."
      );
      return;
    }

    setDynamicInputList((prevInputList) => {
      const updatedInputList = [...prevInputList];
      updatedInputList[index][name] = value;
      return updatedInputList;
    });
  };

  const [dynamicInputListRoom, setDynamicInputListRoom] = useState([
    {
      id: 1,
      tax_name: "",
      hsn_code: "",
      tax_rate: "",
      tax_name: "",
      min: "",
      max: "",
    },
  ]);

  const [showDynamicInputListRoom, setShowDynamicInputListRoom] = useState([]);
  const [editShowDynamicInputListRoom, setEditShowDynamicInputListRoom] =
    useState(false);
  const [editShowDynamicInputListOther, setEditShowDynamicInputListOther] =
    useState(false);
  const [showDynamicInputListOther, setShowDynamicInputListOther] = useState(
    []
  );

  const handleAddClickRoom = (id) => {
    const lastItem = dynamicInputListRoom[dynamicInputListRoom.length - 1];
    if (
      (lastItem &&
        // lastItem.hsn_code === "" ||
        // lastItem.hsn_code === " " ||
        lastItem.tax_rate === "") ||
      lastItem.tax_rate === " "
      // ||
      // lastItem.tax_name === " " ||
      // lastItem.tax_name === ""
    ) {
      showErrorToast("Please add the above fields ...!!");
    } else {
      setDynamicInputListRoom([
        ...dynamicInputListRoom,
        {
          id: id + 1,
          tax_name: "",
          hsn_code: "",
          tax_rate: "",
          tax_name: "",
          min: "",
          max: "",
        },
      ]);
    }
  };

  const handleRemoveClickRoom = (idToRemove) => {
    const updatedList = dynamicInputList.filter(
      (item) => item.id !== idToRemove
    );
    setDynamicInputList(updatedList);
  };

  const dynamicPreviewBillHandleChangeRoom = (e, index) => {
    const { name, value } = e.target;

    const isDuplicate = dynamicInputList.some(
      (item, i) => i !== index && item.tax_name === value && name === "tax_name"
    );
    const isDuplicateHn = dynamicInputList.some(
      (item, i) => i !== index && item.hsn_code === value && name === "hsn_code"
    );

    if (isDuplicate) {
      showErrorToast("Duplicate entry.");
      return;
    }
    if (isDuplicateHn) {
      showErrorToast("Duplicate HSN code.");
      return;
    }

    setDynamicInputListRoom((prevInputList) => {
      const updatedInputList = [...prevInputList];
      updatedInputList[index][name] = value;
      return updatedInputList;
    });
  };

  const [addDomainData, setAddDomainData] = useState({
    domain: "",
    checkout_url: "",
    dns_record_mapped: "",
    is_primary: "",
    search_url: "",
  });

  // Onchange Function
  const setAddItemDataHandleChange = (event) => {
    setAddDomainData({
      ...addDomainData,
      [event.target.name]: event.target.value,
    });
  };

  const statusOptions = [
    { value: "", label: "Select Option" },
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];
  const modeOptions = [
    { value: "", label: "Select Option" },
    { value: "1", label: "Live" },
    { value: "0", label: "Test" },
  ];
  const paymentGatewaysOptions = [
    { value: "", label: "Select Option" },
    { value: "atoms", label: "Atoms" },
    { value: "razor_pay", label: "Razor pay" },
  ];

  return (
    <>
      <div className="setting_location_sec">
        <div
          style={{ width: "100%" }}
          className="room-type-heading flex justify_content_between align_items_center"
        >
          <div></div>

          <BgThemeButton onClick={() => setShowAddStaffPopup(true)}>
            Add Domains
          </BgThemeButton>
        </div>
        <LegacyCard>
          <div style={{ padding: "15px" }}>
            <div className="edit-rooms-form-wrapper-container">
              {loader ? <LoaderSmall /> : (
                <div>
                  {paymentGateways && paymentGateways.length > 0 ? (
                    <table
                      style={{
                        backgroundColor: "white",
                        padding: "0 10px 20px 0px",
                        borderCollapse: "collapse",
                        borderRadius: "10px",
                        fontFamily: "Inter",
                        width: "100%",
                        marginTop: "25px",
                      }}
                    >
                      <tr
                        style={{
                          textAlign: "left",
                          marginBottom: "10px",
                          borderBottom: "1px solid #E8E8E8",
                        }}
                      >
                        {/* <th style={{ padding: "0px" }}>Name</th> */}
                        <th style={{ padding: "0px" }}>Domain</th>
                        <th style={{ padding: "0px" }}>Check-Out URL</th>
                        <th style={{ padding: "0px" }}>Search URL</th>
                        <th style={{ padding: "0px" }}>DNS Record Mapped</th>
                        <th style={{ padding: "0px" }}>Is Primary</th>
                        <th style={{ padding: "0px" }}></th>
                        {/* <th style={{ padding: "10px" }}>Total Amount</th> */}
                        <th
                          style={{
                            width: "10px",
                            padding: "10px",
                          }}
                        ></th>
                      </tr>

                      {paymentGateways.map((gateway, index) => {
                        return (
                          <tr>
                            <>
                              <td style={{ padding: "10px 0" }}>
                                <UnderscoreRemoveCapitalize
                                  text={gateway.domain}
                                />
                              </td>
                              <td style={{ padding: "10px 0" }}>
                                {gateway.checkout_url}
                              </td>
                              <td style={{ padding: "10px 0" }}>
                                {gateway.search_url}
                              </td>
                              <td style={{ padding: "10px 0" }}>
                                {Number(gateway.is_active) === 1 ? (
                                  <StatusUpdate
                                    status={gateway.dns_record_mapped}
                                  />
                                ) : (
                                  <StatusUpdate
                                    status={gateway.dns_record_mapped}
                                  />
                                )}
                              </td>
                              <td style={{ padding: "10px 0" }}>
                                {Number(gateway.is_active) === 1 ? (
                                  <StatusUpdate status={gateway.is_primary} />
                                ) : (
                                  <StatusUpdate status={gateway.is_primary} />
                                )}
                              </td>

                              <td
                                style={{
                                  padding: "10px",
                                  width: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: "30px",
                                  }}
                                >
                                  <div
                                    onClick={() => [getOneDataRoom(gateway.id)]}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M11.3919 1.98174L14.0182 4.60884L14.4562 4.1707C15.1813 3.44536 15.1813 2.26952 14.4562 1.54418C13.7308 0.818608 12.5547 0.818608 11.8294 1.54418L11.3919 1.98174Z"
                                        fill="#3968ED"
                                      />
                                      <path
                                        d="M12.8124 5.81229L10.1861 3.18518L1.85249 11.5201L1 15L4.47873 14.1472L12.8124 5.81229Z"
                                        fill="#3968ED"
                                      />
                                    </svg>
                                  </div>
                                  <div
                                    onClick={() => [
                                      showModelMenu(),
                                      setDeletedSingleIds(gateway.id),
                                    ]}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                                        fill="#E03838"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                                        fill="#E03838"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </td>
                            </>
                          </tr>
                        );
                      })}
                    </table>
                  ) : (
                    <NoDataFoundSmall />
                  )}
                </div>
              )}
            </div>
          </div>
        </LegacyCard>
      </div>

      {showAddStaffPopup && (
        <div className="bg-backdrop">
          <div>
            <LegacyCard>
              <form
                onSubmit={handleAddDomainGateway}
                style={{ padding: "20px", width: "800px" }}
              >
                <GoBackButtonCustom
                  buttonNextText={"Add Domains"}
                  onClick={() => setShowAddStaffPopup(false)}
                />

                <div className="edit-rooms-form-wrapper-container">
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        name={"domain"}
                        className="textfield"
                        focused={false}
                        titleName="Domain"
                        onChange={setAddItemDataHandleChange}
                        autoComplete="off"
                        value={addDomainData.domain}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        name={"checkout_url"}
                        className="textfield"
                        focused={false}
                        titleName="Check-Out URL"
                        onChange={setAddItemDataHandleChange}
                        autoComplete="off"
                        value={addDomainData.checkout_url}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        // options={paymentGatewaysOptions}
                        required={true}
                        type={"text"}
                        name={"search_url"}
                        className="textfield"
                        focused={false}
                        titleName="Search URL"
                        onChange={setAddItemDataHandleChange}
                        autoComplete="off"
                        value={addDomainData.search_url}
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        options={statusOptions}
                        required={true}
                        name={"dns_record_mapped"}
                        className="textfield"
                        focused={false}
                        titleName="Dns Record Mapped"
                        onChange={setAddItemDataHandleChange}
                        autoComplete="off"
                        value={addDomainData.dns_record_mapped}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        options={statusOptions}
                        required={true}
                        name={"is_primary"}
                        className="textfield"
                        focused={false}
                        titleName="Is Primary"
                        onChange={setAddItemDataHandleChange}
                        autoComplete="off"
                        value={addDomainData.is_primary}
                      />
                    </div>
                  </div>

                  <BgThemeButton children={"Add"} type={"submit"} loader={loader}/>
                </div>
              </form>
            </LegacyCard>
          </div>
        </div>
      )}
      {editShowDynamicInputListRoom && (
        <div className="bg-backdrop">
          <div>
            <LegacyCard>
              <form
                onSubmit={(e) => handleUpdatePaymentGateway(e, editId)}
                style={{ padding: "20px", width: "800px" }}
              >
                <GoBackButtonCustom
                  buttonNextText={"Edit Payment Gateway"}
                  onClick={() => setEditShowDynamicInputListRoom(false)}
                />

                <div className="edit-rooms-form-wrapper-container">
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        // options={paymentGatewaysOptions}
                        required={true}
                        type={"text"}
                        name={"name"}
                        className="textfield"
                        focused={false}
                        titleName="Name"
                        onChange={setAddItemDataHandleChange}
                        autoComplete="off"
                        value={addDomainData.name}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        name={"domain"}
                        className="textfield"
                        focused={false}
                        titleName="Domain"
                        onChange={setAddItemDataHandleChange}
                        autoComplete="off"
                        value={addDomainData.domain}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        name={"checkout_url"}
                        className="textfield"
                        focused={false}
                        titleName="Check-Out URL"
                        onChange={setAddItemDataHandleChange}
                        autoComplete="off"
                        value={addDomainData.checkout_url}
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        options={statusOptions}
                        required={true}
                        name={"dns_record_mapped"}
                        className="textfield"
                        focused={false}
                        titleName="Dns Record Mapped"
                        onChange={setAddItemDataHandleChange}
                        autoComplete="off"
                        value={addDomainData.dns_record_mapped}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        options={statusOptions}
                        required={true}
                        name={"is_primary"}
                        className="textfield"
                        focused={false}
                        titleName="Is Primary"
                        onChange={setAddItemDataHandleChange}
                        autoComplete="off"
                        value={addDomainData.is_primary}
                      />
                    </div>
                  </div>

                  <BgThemeButton children={"Update"} type={"submit"} loader={loader}/>
                </div>
              </form>
            </LegacyCard>
          </div>
        </div>
      )}

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                >
                  Discard
                </Button>
                <Button onClick={deleteRoomTaxesHandle} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default PropertyDomains;
