import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BgOutlineThemeButton, BgThemeButton } from "../../../UI/Buttons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../assets/toastUtils";
import VerticalInputFieldCustom from "../../VerticalInputFieldCustom.jsx";

import CustomCountryStateSelector from "../../CustomCountryStateSelector.jsx";

const ModifyReservationArrivalsChangeGuestDetails = ({
  bookingData,
  setShowModifyPopup,
  getBookingData,
}) => {
  const [loader, setLoader] = useState(false);
  const property_id = useSelector((state) => state.property.value);
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const navigate = useNavigate();
  const [modifyGuestDetails, setModifyGuestDetails] = useState({
    guestName: "",
    guestPhone: "",
    guestEmail: "",
    guestCity: "",
    guestState: "",
    guestCountry: "",
    guestPinCode: "",
    guestDOB: "",
    guestAddress: "",
  });
  useEffect(() => {
    if (bookingData.guest) {
      setModifyGuestDetails((prevState) => ({
        ...prevState,
        guestName: bookingData.guest[0].guest_name || "",
        guestPhone: bookingData.guest[0].guest_phone || "",
        guestEmail: bookingData.guest[0].guest_email || "",
        guestCity: bookingData.guest[0].guest_city || "",
        guestState: bookingData.guest[0].guest_state || "",
        guestCountry: bookingData.guest[0].guest_country || "",
        guestPinCode: bookingData.guest[0].guest_pincode || "",
        guestDOB: bookingData.guest[0].guest_dob || "",
        guestAddress: bookingData.guest[0].guest_address || "",
      }));
    }
  }, [bookingData]);

  const handleModifyGuestDetails = (e) => {
    setModifyGuestDetails({
      ...modifyGuestDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleModifyCountryStateChange = (data) => {
    setModifyGuestDetails((perv) => ({
      ...perv,
      guestCountry: data?.country,
      guestState: data?.state,
    }));
  };
  const handleModifyReservationGuestSubmit = async () => {
    const formData = new FormData();
    formData?.append("reservationId", bookingData?.unique_booking_id);

    if (modifyGuestDetails?.guestName?.trim() !== "") {
      formData?.append("guestName", modifyGuestDetails?.guestName);
    }

    if (modifyGuestDetails?.guestPhone?.trim() !== "") {
      formData?.append("guestPhone", modifyGuestDetails?.guestPhone);
    }

    if (modifyGuestDetails?.guestEmail?.trim() !== "") {
      formData?.append("guestEmail", modifyGuestDetails?.guestEmail);
    }

    if (modifyGuestDetails?.guestCity?.trim() !== "") {
      formData?.append("guestCity", modifyGuestDetails?.guestCity);
    }

    if (modifyGuestDetails?.guestState?.trim() !== "") {
      formData?.append("guestState", modifyGuestDetails?.guestState);
    }

    if (modifyGuestDetails?.guestCountry?.trim() !== "") {
      formData?.append("guestCountry", modifyGuestDetails?.guestCountry);
    }

    if (modifyGuestDetails?.guestPinCode !== "") {
      formData?.append("guestPinCode", modifyGuestDetails?.guestPinCode);
    }

    if (modifyGuestDetails?.guestAddress?.trim() !== "") {
      formData?.append("guestAddress", modifyGuestDetails?.guestAddress);
    }
    // if (reasons.guestDetailsReason !== "") {
    //   formData?.append("reason", modifyGuestDetails?.guestAddress);
    // }

    const myHeaders = new Headers();
    myHeaders.append("key", `Bearer ${userToken}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modify/guestDetails/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      setLoader(false);

      showSuccessToast("Guest Details updated Successfully");
      setModifyGuestDetails({
        guestName: "",
        guestPhone: "",
        guestEmail: "",
        guestCity: "",
        guestState: "",
        guestCountry: "",
        guestPinCode: "",
        guestDOB: "",
        guestAddress: "",
      });

      setShowModifyPopup(false);
      // fetchBookingDetails();

      if (getBookingData !== null && getBookingData !== undefined) {
        getBookingData(bookingData.unique_booking_id);
      } else {
        window.location.reload();
      }

      return data;
    } catch (error) {
      console.error(error);
      showErrorToast(error.message);
      setLoader(false);
      return null;
    }
  };
  return (
    <div>
      <div>
        <table
          className="modifyReservationPopupTable"
          style={{ width: "100%", borderSpacing: "10px" }}
        >
          <tbody>
            <tr>
              <td colSpan={4}>
                <VerticalInputFieldCustom
                  titleName={"Name of Guest"}
                  name={"guestName"}
                  value={modifyGuestDetails.guestName}
                  onChange={handleModifyGuestDetails}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <VerticalInputFieldCustom
                  titleName={"Phone"}
                  name={"guestPhone"}
                  type={"number"}
                  value={modifyGuestDetails.guestPhone}
                  onChange={handleModifyGuestDetails}
                />
              </td>
              <td colSpan={2}>
                <VerticalInputFieldCustom
                  titleName={"Email"}
                  onChange={handleModifyGuestDetails}
                  name={"guestEmail"}
                  value={modifyGuestDetails.guestEmail}
                />
              </td>
            </tr>
            <tr>
              
              <td colSpan={2}>
                <CustomCountryStateSelector
                  defaultCountry={modifyGuestDetails.guestCountry}
                  defaultState={modifyGuestDetails.guestState}
                  getCountryState={handleModifyCountryStateChange}
                  style={{ display: "flex", gap: "10px" }}
                />
              </td>

              <td>
                <VerticalInputFieldCustom
                  titleName={"City"}
                  name={"guestCity"}
                  value={modifyGuestDetails.guestCity}
                  onChange={handleModifyGuestDetails}
                />
              </td>
              <td>
                <VerticalInputFieldCustom
                  titleName={"Pin Code"}
                  name={"guestPinCode"}
                  value={modifyGuestDetails.guestPinCode}
                  onChange={handleModifyGuestDetails}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <VerticalInputFieldCustom
                  titleName={"Address"}
                  name={"guestAddress"}
                  value={modifyGuestDetails.guestAddress}
                  onChange={handleModifyGuestDetails}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div className="flex flex_gap_10" style={{ marginTop: "20px" }}>
          <BgThemeButton
            children={"Modify"}
            onClick={handleModifyReservationGuestSubmit}
            loader={loader}
          />
          <BgOutlineThemeButton
            children={"Cancel"}
            onClick={() => setShowModifyPopup(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default ModifyReservationArrivalsChangeGuestDetails;
