import "./ChefDashBoardNewUi.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchInput from "../../CustomComponents/SearchSuggessionInput.jsx/SearchInput";
import { showSuccessToast, showErrorToast } from "../../../assets/toastUtils";
import NoDataFound from "../../CustomComponents/NoDataFound";
import { Link, useLocation } from "react-router-dom";
const ChefDashBoardNewUi = () => {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const fetchKitchenKot = async (propertyId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/kitchen/kots/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      setFetchedData(data.kot);

      //   setLoader(false);
    } catch (error) {
      //   setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchKitchenKot(property_id?.id);
    }
  }, [property_id]);
  // const changeStatus = async (status, kotId, propertyId) => {
  //     try {
  //         const response = await fetch(
  //             // https://dev.revcatalyst.in/api/v1/kitchen/kots/update/status/new_kot/4/10
  //             `${process.env.REACT_APP_BASE_URL}/api/v1/kitchen/kots/update/status/${kotId}/${propertyId}`,
  //             {
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Authorization: `Bearer ${userToken}`,
  //                 },
  //             }
  //         );
  //         const data = await response.json();
  //         if (data.success) {
  //             showSuccessToast(data.message);
  //             fetchKitchenKot(property_id?.id);
  //         } else {
  //             showErrorToast(data.message);
  //         }
  //         // setFetchedData(data.kot);
  //         //   setLoader(false);
  //     } catch (error) {
  //         //   setLoader(false);
  //         console.error(error);
  //     }
  // };
  const changeStatus = (kot_id, property_id, status) => {
    setLoader(true);

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/kitchen/kots/update/status/${kot_id}/${property_id}`,
      {
        method: "POST",
        body: JSON.stringify({
          status: status,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setLoader(false);
          fetchKitchenKot(property_id);
        } else {
          showSuccessToast(data.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
    // setShowBookingDetails(false);
  };

  useEffect(() => {
    if (property_id) {
      fetchKitchenKot(property_id?.id);
    }
  }, [property_id]);


  const [fetchedData, setFetchedData] = useState([]);

  const sortedData = fetchedData
    .filter(
      (item) =>
        item.order_status !== "cancelled" && item.order_status !== "prepared"
    )
    .sort((a, b) => {
      const order = { preparing: 1, new_kot: 2 };

      const statusA = a.status;
      const statusB = b.status;

      return order[statusA] - order[statusB];
    });

  return (
    <div className="">
      <div
        className="flex flex_gap_10"
        style={{ margin: "20px 0px", fontSize: "15px", fontWeight: "500" }}
      >
        <div className="flex flex_gap_10 align_items_center">
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4" cy="4" r="4" fill="#BD941B" />
          </svg>
          Under Preparation
        </div>
        <div className="flex flex_gap_10 align_items_center">
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4" cy="4" r="4" fill="#3968ED" />
          </svg>
          New KOT
        </div>
      </div>
      <div className="chefDashboardCardsContainer">
        {fetchedData && fetchedData.length > 0 ? (
          sortedData.map((item, index) => (
            <div className="chefDashboardCards" key={index}>
              <div
                className={`chefDashboardCardHeader ${
                  item.order_status === "new_kot"
                    ? "addBackgroundColorToHeaderThreeAndFour"
                    : ""
                } ${
                  item.order_status === "preparing"
                    ? "addBackgroundColorToHeaderTwo"
                    : ""
                }`}
              >
                <div className="chef_dashboard_items_list">
                  <h5 className="chefDashboardKotType">{item.room_no}</h5>
                  <h6 className="chefDashboardKotTime">Room No.</h6>
                </div>
                <div className="chef_dashboard_items_list chef_dashboard_items_list_mid">
                  <h5 className="chefDashboardKotType">{item.order_no}</h5>
                  <h6 className="chefDashboardKotTime">KOT No.</h6>
                </div>
                <div className="chef_dashboard_items_list">
                  <h5 className="chefDashboardKotType">{item.time}</h5>
                  {/* <h6 className="chefDashboardKotTime">PM</h6> */}
                </div>
                {/* <h4 className="chefDashboardKotType">{item.kot_type}</h4>
                                <h5 className="chefDashboardKotTime">{item.time}</h5> */}
              </div>
              {item?.items?.map((category, categoryIndex) => (
                <div key={categoryIndex}>
                  <div className="chefDashboardCardCategory">
                    {category?.categoryName}
                  </div>
                  {category?.items?.map((product, productIndex) => (
                    <div
                      key={productIndex}
                      className="chefDashboardCardProduct"
                    >
                      <p className="chefDashboardCardProductName">
                        {product.quantity} {product.name}
                      </p>
                    </div>
                  ))}
                </div>
              ))}
              {/* <div className="chefDashboardKotContent">
                                <p style={{fontSize:'14px'}}>Lorem ipsum dolor sit amet conse ctetur. Est sed quis lectus bibendum semper mattis sit.</p>
                            </div> */}

              <div className="chefDashboardCardFooter">
                {item.order_status === "new_kot" ? (
                  <button
                    onClick={() =>
                      changeStatus(item.id, property_id?.id, "preparing")
                    }
                    className="addBackgroundColorToHeaderThreeAndFour chefDashboardCardButton"
                    style={{ cursor: "auto " }}
                  >
                    Mark as Preparing
                  </button>
                ) : item.order_status === "preparing" ? (
                  <button
                    onClick={() =>
                      changeStatus(item.id, property_id?.id, "prepared")
                    }
                    className="addBackgroundColorToHeaderTwo chefDashboardCardButton"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="13"
                      viewBox="0 0 18 13"
                      fill="none"
                      style={{ marginRight: "5px" }}
                    >
                      <path
                        d="M1.28564 6.07143L6.71422 11.5L16.7142 1.5"
                        stroke="white"
                        stroke-width="2"
                      />
                    </svg>
                    Mark as Ready
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <NoDataFound />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default ChefDashBoardNewUi;
