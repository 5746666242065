import React, { useEffect, useRef, useState } from "react";
import SidebarHotelOptions from "../../SidebarHotelOptions";
import { Link, useLocation } from "react-router-dom";
import { BgOutlineThemeButton } from "../../UI/Buttons";

const CustomBottomNavBarItems = ({
  limitOnFront,
  showMenuButton,
  navMenuItems,
}) => {
  const [showMenuLinks, setShowMenuLinks] = useState(false);
  const location = useLocation();
  const otherMenusRef = useRef(null);

  const permission = JSON.parse(localStorage.getItem("permissions"));

  const activeIndex = navMenuItems.findIndex(
    (item) => item.link === location.pathname
  );

  const endIndex = typeof limitOnFront === "number" ? limitOnFront : 4;

  const handleClickOutside = (event) => {
    if (
      otherMenusRef.current &&
      !otherMenusRef.current.contains(event.target)
    ) {
      setShowMenuLinks(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="footer_nav_bar">
        {/* <div className="footer_nav_bar_hotel_options">
          <SidebarHotelOptions />
        </div> */}
        <div className="footer_nav_bar_menus">
          {navMenuItems &&
            navMenuItems?.slice(0, endIndex)?.map((item, index) => (
              <div key={index}>
                {permission?.includes(item?.permission) && (
                  <div
                    key={item.id}
                    className={`footer_nav_bar_item ${
                      index === activeIndex ? "active" : ""
                    }`}
                  >
                    {/* <a href={item.link} className="item_list"> */}
                    <Link
                      onClick={() => setShowMenuLinks(false)}
                      to={item.link}
                      className="item_list"
                    >
                      {item.icon} {item.name}
                    </Link>
                  </div>
                )}
              </div>
            ))}
        </div>

        <div className="footer_nav_bar_other_menus" ref={otherMenusRef}>
          {showMenuLinks && (
            <div className="other_menus_item">
              {navMenuItems &&
                navMenuItems.slice(endIndex).map((item) => (
                  <>
                    {permission?.includes(item?.permission) && (
                      <div key={item.id}>
                        <Link
                          onClick={() => setShowMenuLinks(false)}
                          to={item.link}
                          className="menus_item_list_item"
                        >
                          {item.icon} {item.name}
                        </Link>
                      </div>
                    )}
                  </>
                ))}
            </div>
          )}
          {navMenuItems && navMenuItems.length > endIndex ? (
            // <BgOutlineThemeButton
            //   onClick={() => setShowMenuLinks(!showMenuLinks)}
            //   icon={
            //     showMenuLinks ? (
            //       <div>
            //         <svg
            //           xmlns="http://www.w3.org/2000/svg"
            //           width="12"
            //           height="8"
            //           viewBox="0 0 12 8"
            //           fill="none"
            //         >
            //           <path
            //             d="M11 1L6 6L0.999999 1"
            //             stroke="#3968ED"
            //             strokeWidth="1.5"
            //           />
            //         </svg>
            //       </div>
            //     ) : (
            //       <div>
            //         <svg
            //           xmlns="http://www.w3.org/2000/svg"
            //           width="12"
            //           height="8"
            //           viewBox="0 0 12 8"
            //           fill="none"
            //         >
            //           <path
            //             d="M1 7L6 2L11 7"
            //             stroke="#3968ED"
            //             strokeWidth="1.5"
            //           />
            //         </svg>
            //       </div>
            //     )
            //   }
            //   children={"Menu"}
            // />
            <div
              className="footer_nav_bar_menu_item "
              style={{ position: "relative" }}
              onClick={() => setShowMenuLinks(!showMenuLinks)}
            >
              <svg
                width="4"
                height="16"
                viewBox="0 0 4 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14C0 13.45 0.195833 12.9792 0.5875 12.5875C0.979167 12.1958 1.45 12 2 12C2.55 12 3.02083 12.1958 3.4125 12.5875C3.80417 12.9792 4 13.45 4 14C4 14.55 3.80417 15.0208 3.4125 15.4125C3.02083 15.8042 2.55 16 2 16ZM2 10C1.45 10 0.979167 9.80417 0.5875 9.4125C0.195833 9.02083 0 8.55 0 8C0 7.45 0.195833 6.97917 0.5875 6.5875C0.979167 6.19583 1.45 6 2 6C2.55 6 3.02083 6.19583 3.4125 6.5875C3.80417 6.97917 4 7.45 4 8C4 8.55 3.80417 9.02083 3.4125 9.4125C3.02083 9.80417 2.55 10 2 10ZM2 4C1.45 4 0.979167 3.80417 0.5875 3.4125C0.195833 3.02083 0 2.55 0 2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0C2.55 0 3.02083 0.195833 3.4125 0.5875C3.80417 0.979167 4 1.45 4 2C4 2.55 3.80417 3.02083 3.4125 3.4125C3.02083 3.80417 2.55 4 2 4Z"
                  fill="#888888"
                />
              </svg>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default CustomBottomNavBarItems;

{
  /* <div>
    {showMenuButton && (
        <>
            <button
                className="customBottomNavBarItemsButton"
                onClick={() => setShowMenuLinks(!showMenuLinks)}
            >
                Menu{" "}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    transform={!showMenuLinks ? "rotate(180)" : ""}
                >
                    <path
                        d="M11 1L6 6L0.999999 1"
                        stroke="#3968ED"
                        strokeWidth="1.5"
                    />
                </svg>
            </button>
        </>
    )}
</div>  */
}

// ......................................
// import React, { useEffect, useRef, useState } from "react";
// import SidebarHotelOptions from "../../SidebarHotelOptions";
// import { Link, useLocation } from "react-router-dom";
// import { BgOutlineThemeButton } from "../../UI/Buttons";

// const CustomBottomNavBarItems = ({
//   limitOnFront,
//   showMenuButton,
//   navMenuItems,
// }) => {
//   const [showMenuLinks, setShowMenuLinks] = useState(false);
//   const location = useLocation();
//   const otherMenusRef = useRef(null);

//   const permission = JSON.parse(localStorage.getItem("permissions"));

//   const activeIndex = navMenuItems.findIndex(
//     (item) => item.link === location.pathname
//   );

//   const endIndex = typeof limitOnFront === "number" ? limitOnFront : 4;

//   const handleClickOutside = (event) => {
//     // if (
//     //   otherMenusRef.current &&
//     //   !otherMenusRef.current.contains(event.target)
//     // ) {
//     //   setShowMenuLinks(false);
//     // }
//     setShowMenuLinks(false);
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   return (
//     <>
//       <div className="footer_nav_bar">
//         {/* <div className="footer_nav_bar_hotel_options">
//           <SidebarHotelOptions />
//         </div> */}
//         <div className="footer_nav_bar_menus">
//           {navMenuItems &&
//             navMenuItems?.slice(0, endIndex)?.map((item, index) => (
//               <>
//                 {permission?.includes(item?.permission) && (
//                   <div
//                     key={item.id}
//                     className={`footer_nav_bar_item ${index === activeIndex ? "active" : ""
//                       }`}
//                   >
//                     {/* <a href={item.link} className="item_list"> */}
//                     <Link
//                       onClick={() => setShowMenuLinks(false)}
//                       to={item.link}
//                       className="item_list"
//                     >
//                       {item.icon} {item.name}
//                     </Link>
//                   </div>
//                 )}
//               </>
//             ))}
//           <div className="footer_nav_bar_menu_item " style={{ position: 'relative' }} onClick={() => setShowMenuLinks(!showMenuLinks)} ref={otherMenusRef}>
// <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <path d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14C0 13.45 0.195833 12.9792 0.5875 12.5875C0.979167 12.1958 1.45 12 2 12C2.55 12 3.02083 12.1958 3.4125 12.5875C3.80417 12.9792 4 13.45 4 14C4 14.55 3.80417 15.0208 3.4125 15.4125C3.02083 15.8042 2.55 16 2 16ZM2 10C1.45 10 0.979167 9.80417 0.5875 9.4125C0.195833 9.02083 0 8.55 0 8C0 7.45 0.195833 6.97917 0.5875 6.5875C0.979167 6.19583 1.45 6 2 6C2.55 6 3.02083 6.19583 3.4125 6.5875C3.80417 6.97917 4 7.45 4 8C4 8.55 3.80417 9.02083 3.4125 9.4125C3.02083 9.80417 2.55 10 2 10ZM2 4C1.45 4 0.979167 3.80417 0.5875 3.4125C0.195833 3.02083 0 2.55 0 2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0C2.55 0 3.02083 0.195833 3.4125 0.5875C3.80417 0.979167 4 1.45 4 2C4 2.55 3.80417 3.02083 3.4125 3.4125C3.02083 3.80417 2.55 4 2 4Z" fill="#888888" />
// </svg>
//             {showMenuLinks && (
//               <div className="other_menus_item">
//                 {navMenuItems &&
//                   navMenuItems.slice(endIndex).map((item) => (
//                     <>
//                       {permission?.includes(item?.permission) && (
//                         <div key={item.id}>
//                           <Link
//                             onClick={() => setShowMenuLinks(false)}
//                             to={item.link}
//                             className="menus_item_list_item"
//                           >
//                             {item.icon} {item.name}
//                           </Link>
//                         </div>
//                       )}
//                     </>
//                   ))}
//               </div>
//             )}
//           </div>

//         </div>

//       </div>
//     </>
//   );
// };

// export default CustomBottomNavBarItems;
