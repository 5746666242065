import React, { useEffect, useState } from "react";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import { Button, LegacyCard } from "@shopify/polaris";
import { formatIndianCurrency } from "../../utils/utils";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import LoaderSmall from "../UI/Loader/LoaderSmall";

const TaxSettingsCrud = ({ editSettingTrue, setEditSettingTrue }) => {
  const selectedProperty = JSON.parse(localStorage.getItem("selectedProperty"));
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [loader, setLoader] = useState(false);

  const [showAddStaffPopup, setShowAddStaffPopup] = useState(false);
  const property_id = useSelector((state) => state.property.value);

  const [deletedSingleIds, setDeletedSingleIds] = useState([]);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const [addPropertyData, setAddPropertyData] = useState({
    property_name: selectedProperty.property_name,
    property_phone: selectedProperty.property_phone,
    property_landline: "",
    property_landline2: "",
    property_email: selectedProperty.property_email,
    property_hotel_type: selectedProperty.property_hotel_type,
    food_prefix: selectedProperty.food_prefix,
    booking_prefix: selectedProperty.booking_prefix,
    property_address: selectedProperty.property_address,
    property_country: selectedProperty.property_country,
    property_city: selectedProperty.property_city,
    property_state: selectedProperty.property_state,
    property_tax_rate: 0,
    property_zip: selectedProperty.property_zip,
    property_description: selectedProperty.property_description,
    property_checkin: selectedProperty.property_checkin,
    property_checkout: selectedProperty.property_checkout,
    property_facebook: "",
    property_instagram: "",
    property_twitter: "",
    property_linkedin: "",
    property_threads: "",
    property_whatsapp: "",
    property_status: "",
    property_hotel_id: "",
  });

  const [filterType, setFilterType] = useState("room_tax");

  // Onchange Function
  const setAddItemDataHandleChangeOther = (event) => {
    setDynamicInputListOtherData({
      ...dynamicInputListOtherData,
      [event.target.name]: event.target.value,
    });
  };

  const setAddItemDataHandleChangeRoom = (event) => {
    setDynamicInputListRoomData({
      ...dynamicInputListRoomData,
      [event.target.name]: event.target.value,
    });
  };

  // Fetch
  const getRoomTaxesHandle = (e) => {
    if (e) {
      e.preventDefault();
    }
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/room/tax/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setShowDynamicInputListRoom(data.data);
          setLoader(false);

        } else {
          showErrorToast(data.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoader(false);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const getOtherTaxesHandle = (e) => {
    if (e) {
      e.preventDefault();
    }
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/other/tax/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // setDynamicInputList(data.data);
          setShowDynamicInputListOther(data.data);
          setLoader(false);
        } else {
          showErrorToast(data.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoader(false);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const [editId, setEditId] = useState("");

  // Edit
  const getOneDataRoom = async (id) => {
    setEditId(id);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/room/tax/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        setDynamicInputListRoomData(data.data);

        setEditShowDynamicInputListRoom(true);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOneDataOther = async (id) => {
    setEditId(id);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/other/tax/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setDynamicInputListOtherData(data.data);

        setEditShowDynamicInputListOther(true);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // update
  const updateRoomTaxesHandle = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      // setLoader(true);
      const response = await fetch(
        // api/v1/update/room/tax/10
        `${process.env.REACT_APP_BASE_URL}/api/v1/update/room/tax/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...dynamicInputListRoomData }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setDynamicInputListRoomData({});
        setEditShowDynamicInputListRoom(false);
        getRoomTaxesHandle();
        // set
      } else {
        showErrorToast(data.message);
        // setLoader(false);
      }
    } catch (error) {
      // setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const updateOtherTaxesHandle = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      // setLoader(true);
      const response = await fetch(
        // api/v1/update/room/tax/10
        `${process.env.REACT_APP_BASE_URL}/api/v1/update/other/tax/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...dynamicInputListOtherData }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setDynamicInputListOtherData({});
        setEditShowDynamicInputListOther(false);
        getOtherTaxesHandle();
      } else {
        showErrorToast(data.message);
        // setLoader(false);
      }
    } catch (error) {
      // setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  // Delete

  const deleteRoomTaxesHandle = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/delete/room/tax/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id: deletedSingleIds }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setShowModelMenuActive(false);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const deleteOtherTaxesHandle = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/delete/other/tax/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id: deletedSingleIds }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          showModelMenuActive(false);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    if (property_id) {
      getRoomTaxesHandle();
      getOtherTaxesHandle();
    }
  }, [property_id]);

  const handleAddOtherRoomTax = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/create/other/tax/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify(dynamicInputList),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // window.location.reload();
        if (data.success) {
          setShowAddStaffPopup(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handlePostRoomTax = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/create/room/tax/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify(dynamicInputListRoom),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // window.location.reload();
        if (data.success) {
          // navigate("/settings");
          setShowAddStaffPopup(false);
          showSuccessToast(data.message);
          getRoomTaxesHandle();
          getOtherTaxesHandle();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const addonsForBanquetEvents = [
    { id: 0, label: "Select Option" },
    { id: 1, label: "F&B", value: "F&B" },
    { id: 3, label: "Housekeeping", value: "housekeeping" },
    // { id: 3, label: "Room Settlement", value: "room_settlement" },
    { id: 4, label: "Miscellaneous", value: "misc" },
  ];

  const roomTaxAddons = [
    { id: 0, label: "Select Option", value: "" },
    { id: 3, label: "Room Taxes", value: "room_tax" },
  ];

  const taxRateOptions = [
    { id: 0, label: "Select Option" },
    { id: 1, label: "5%", value: "5" },
    { id: 2, label: "12%", value: "12" },
    { id: 3, label: "18%", value: "18" },
  ];

  const [dynamicInputList, setDynamicInputList] = useState([
    {
      id: 1,
      tax_name: "",
      hsn_code: "",
      tax_rate: "",
      tax_name: "",
    },
  ]);

  const [dynamicInputListRoomData, setDynamicInputListRoomData] = useState({
    tax_name: "",
    hsn_code: "",
    tax_rate: "",
    tax_name: "",
  });
  const [dynamicInputListOtherData, setDynamicInputListOtherData] = useState({
    tax_name: "",
    hsn_code: "",
    tax_rate: "",
    tax_name: "",
    min: "",
    max: "",
  });

  const handleAddClick = (id) => {
    const lastItem = dynamicInputList[dynamicInputList.length - 1];
    if (
      (lastItem && lastItem.tax_name === "") ||
      lastItem.hsn_code === "" ||
      lastItem.hsn_code === " " ||
      lastItem.tax_rate === "" ||
      lastItem.tax_rate === " " ||
      lastItem.tax_name === " " ||
      lastItem.tax_name === ""
    ) {
      showErrorToast("Please add the above fields first...!!");
    } else {
      setDynamicInputList([
        ...dynamicInputList,
        {
          id: id + 1,
          tax_name: "",
          hsn_code: "",
          tax_rate: "",
          tax_name: "",
        },
      ]);
    }
  };

  const handleRemoveClick = (idToRemove) => {
    const updatedList = dynamicInputList.filter(
      (item) => item.id !== idToRemove
    );
    setDynamicInputList(updatedList);
  };

  // const dynamicPreviewBillHandleChange = (e, index) => {
  //   const { name, value } = e.target;

  //   const isDuplicate = dynamicInputList.some(
  //     (item, i) => i !== index && item.tax_name === value && name === "tax_name"
  //   );
  //   const isDuplicateHn = dynamicInputList.some(
  //     (item, i) => i !== index && item.hsn_code === value && name === "hsn_code"
  //   );

  //   if (isDuplicate) {
  //     showErrorToast("Duplicate entry.");
  //     return;
  //   }
  //   if (isDuplicateHn) {
  //     showErrorToast("Duplicate HSN code.");
  //     return;
  //   }

  //   setDynamicInputList((prevInputList) => {
  //     const updatedInputList = [...prevInputList];
  //     updatedInputList[index][name] = value;
  //     return updatedInputList;
  //   });
  // };

  const dynamicPreviewBillHandleChange = (e, index) => {
    const { name, value } = e.target;
    const fieldName = name === "tax_name" ? "tax_name" : "hsn_code";

    const isDuplicate = dynamicInputList.some(
      (item, i) => i !== index && item[fieldName] === value
    );

    if (isDuplicate) {
      showErrorToast(name === "tax_name" ? "Duplicate entry." : "Duplicate HSN code.");
      return;
    }

    setDynamicInputList((prevInputList) => {
      const updatedInputList = [...prevInputList];
      updatedInputList[index][name] = value;
      return updatedInputList;
    });
  };

  const [dynamicInputListRoom, setDynamicInputListRoom] = useState([
    {
      id: 1,
      tax_name: "",
      hsn_code: "",
      tax_rate: "",
      tax_name: "",
      min: "",
      max: "",
    },
  ]);

  const [showDynamicInputListRoom, setShowDynamicInputListRoom] = useState([]);
  const [editShowDynamicInputListRoom, setEditShowDynamicInputListRoom] =
    useState(false);
  const [editShowDynamicInputListOther, setEditShowDynamicInputListOther] =
    useState(false);
  const [showDynamicInputListOther, setShowDynamicInputListOther] = useState(
    []
  );

  const handleAddClickRoom = (id) => {
    const lastItem = dynamicInputListRoom[dynamicInputListRoom.length - 1];
    if (
      (lastItem &&
        // lastItem.hsn_code === "" ||
        // lastItem.hsn_code === " " ||
        lastItem.tax_rate === "") ||
      lastItem.tax_rate === " "
      // ||
      // lastItem.tax_name === " " ||
      // lastItem.tax_name === ""
    ) {
      showErrorToast("Please add the above fields ...!!");
    } else {
      setDynamicInputListRoom([
        ...dynamicInputListRoom,
        {
          id: id + 1,
          tax_name: "",
          hsn_code: "",
          tax_rate: "",
          tax_name: "",
          min: "",
          max: "",
        },
      ]);
    }
  };

  const handleRemoveClickRoom = (idToRemove) => {
    const updatedList = dynamicInputList.filter(
      (item) => item.id !== idToRemove
    );
    setDynamicInputList(updatedList);
  };

  const dynamicPreviewBillHandleChangeRoom = (e, index) => {
    const { name, value } = e.target;

    const isDuplicate = dynamicInputList.some(
      (item, i) => i !== index && item.tax_name === value && name === "tax_name"
    );
    const isDuplicateHn = dynamicInputList.some(
      (item, i) => i !== index && item.hsn_code === value && name === "hsn_code"
    );

    if (isDuplicate) {
      showErrorToast("Duplicate entry.");
      return;
    }
    if (isDuplicateHn) {
      showErrorToast("Duplicate HSN code.");
      return;
    }

    setDynamicInputListRoom((prevInputList) => {
      const updatedInputList = [...prevInputList];
      updatedInputList[index][name] = value;
      return updatedInputList;
    });
  };
  return (
    <>
      <div className="setting_location_sec">
        <LegacyCard>
          <div style={{ padding: "15px" }}>
            <div
              style={{ width: "100%" }}
              className="room-type-heading flex justify_content_between align_items_center"
            >
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                <input
                  type="radio"
                  name="gst_type"
                  value="room_tax"
                  id="room_tax"
                  onChange={() => setFilterType("room_tax")}
                  checked={filterType === "room_tax"}
                />
                <label htmlFor="room_tax" className="pointer">
                  Room Tax
                </label>

                <input
                  type="radio"
                  name="gst_type"
                  value="other_tax"
                  id="other_tax"
                  onChange={() => setFilterType("other_tax")}
                  checked={filterType === "other_tax"}
                />
                <label htmlFor="other_tax" className="pointer">
                  Other Tax
                </label>
              </div>
              <BgThemeButton onClick={() => setShowAddStaffPopup(true)}>
                Add Tax
              </BgThemeButton>
            </div>
            <div className="edit-rooms-form-wrapper-container">
              {filterType === "room_tax" && (

                <>
                  {loader ? (
                    <LoaderSmall />
                  ) : (
                    <div>
                      {showDynamicInputListRoom &&
                        showDynamicInputListRoom.length > 0 ? (
                        <table
                          style={{
                            backgroundColor: "white",
                            padding: "0 10px 20px 0px",
                            borderCollapse: "collapse",
                            borderRadius: "10px",
                            fontFamily: "Inter",
                            width: "100%",
                            marginTop: "25px",
                          }}
                        >
                          <tr
                            style={{
                              textAlign: "left",
                              marginBottom: "10px",
                            }}
                          >
                            <th style={{ padding: "0px" }}>Tax Name</th>
                            <th style={{ padding: "0px" }}>Min</th>
                            <th style={{ padding: "0px" }}>Max</th>
                            <th style={{ padding: "0px" }}>HSN Code</th>
                            <th style={{ padding: "0px" }}>Tax Rate</th>
                            <th style={{ padding: "0px" }}></th>
                            {/* <th style={{ padding: "10px" }}>Total Amount</th> */}
                            <th
                              style={{
                                width: "10px",
                                padding: "10px",
                              }}
                            ></th>
                          </tr>

                          {showDynamicInputListRoom.map((tax, index) => {
                            return (
                              <tr>
                                <>
                                  <td style={{ padding: "10px 0" }}>
                                    <UnderscoreRemoveCapitalize
                                      text={
                                        showDynamicInputListRoom[index]?.tax_name
                                      }
                                    />
                                  </td>
                                  <td style={{ padding: "10px 0" }}>
                                    {formatIndianCurrency(
                                      showDynamicInputListRoom[index]?.min
                                    )}
                                  </td>
                                  <td style={{ padding: "10px 0" }}>
                                    {formatIndianCurrency(
                                      showDynamicInputListRoom[index]?.max
                                    )}
                                  </td>
                                  <td style={{ padding: "10px 0" }}>
                                    {showDynamicInputListRoom[index]?.hsn_code}
                                  </td>
                                  <td>
                                    {showDynamicInputListRoom[index]?.tax_rate}
                                  </td>

                                  <td
                                    style={{
                                      padding: "10px",
                                      width: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        gap: "30px",
                                      }}
                                    >
                                      <div
                                        onClick={() => [getOneDataRoom(tax.id)]}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <path
                                            d="M11.3919 1.98174L14.0182 4.60884L14.4562 4.1707C15.1813 3.44536 15.1813 2.26952 14.4562 1.54418C13.7308 0.818608 12.5547 0.818608 11.8294 1.54418L11.3919 1.98174Z"
                                            fill="#3968ED"
                                          />
                                          <path
                                            d="M12.8124 5.81229L10.1861 3.18518L1.85249 11.5201L1 15L4.47873 14.1472L12.8124 5.81229Z"
                                            fill="#3968ED"
                                          />
                                        </svg>
                                      </div>
                                      <div
                                        onClick={() => [
                                          showModelMenu(),
                                          setDeletedSingleIds(tax.id),
                                        ]}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                                            fill="#E03838"
                                          />
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                                            fill="#E03838"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </td>
                                </>
                              </tr>
                            );
                          })}
                        </table>
                      ) : (
                        <NoDataFoundSmall />
                      )}
                    </div>
                  )}
                </>
              )}
              {filterType === "other_tax" && (
                <>
                  {loader ? (
                    <LoaderSmall />
                  ) : (
                    <div>
                      {showDynamicInputListOther &&
                        showDynamicInputListOther.length > 0 ? (
                        <table
                          style={{
                            backgroundColor: "white",
                            padding: "0 10px 20px 0px",
                            borderCollapse: "collapse",
                            borderRadius: "10px",
                            fontFamily: "Inter",
                            width: "100%",
                            marginTop: "25px",
                          }}
                        >
                          <tr
                            style={{
                              textAlign: "left",
                              marginBottom: "10px",
                            }}
                          >
                            <th style={{ padding: "0px" }}>Tax Name</th>
                            <th style={{ padding: "0px" }}>HSN Code</th>
                            <th style={{ padding: "0px" }}>Tax Rate</th>
                            {/* <th style={{ padding: "10px" }}>Total Amount</th> */}
                            <th
                              style={{
                                width: "10px",
                                padding: "10px",
                              }}
                            ></th>
                          </tr>
                          {showDynamicInputListOther.map((tax, index) => {
                            return (
                              <tr>
                                <>
                                  <td style={{ padding: "10px 0" }}>
                                    <UnderscoreRemoveCapitalize
                                      text={
                                        showDynamicInputListOther[index]?.tax_name
                                      }
                                    />
                                  </td>

                                  <td style={{ padding: "10px 0" }}>
                                    {showDynamicInputListOther[index]?.hsn_code}
                                  </td>
                                  <td>
                                    {showDynamicInputListOther[index]?.tax_rate}%
                                  </td>

                                  <td
                                    style={{
                                      padding: "10px",
                                      width: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        gap: "30px",
                                      }}
                                    >
                                      <div
                                        onClick={() => [getOneDataOther(tax.id)]}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <path
                                            d="M11.3919 1.98174L14.0182 4.60884L14.4562 4.1707C15.1813 3.44536 15.1813 2.26952 14.4562 1.54418C13.7308 0.818608 12.5547 0.818608 11.8294 1.54418L11.3919 1.98174Z"
                                            fill="#3968ED"
                                          />
                                          <path
                                            d="M12.8124 5.81229L10.1861 3.18518L1.85249 11.5201L1 15L4.47873 14.1472L12.8124 5.81229Z"
                                            fill="#3968ED"
                                          />
                                        </svg>
                                      </div>
                                      <div
                                        onClick={() => [
                                          showModelMenu(),
                                          setDeletedSingleIds(tax.id),
                                        ]}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                                            fill="#E03838"
                                          />
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                                            fill="#E03838"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </td>
                                </>
                              </tr>
                            );
                          })}
                        </table>
                      ) : (
                        <NoDataFoundSmall />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </LegacyCard>
      </div>

      {showAddStaffPopup && (
        <div className="bg-backdrop">
          <div>
            <LegacyCard>
              <div style={{ padding: "20px", width: "800px" }}>
                <GoBackButtonCustom
                  buttonNextText={"Add Taxes"}
                  onClick={() => setShowAddStaffPopup(false)}
                />
                <div
                  style={{ marginTop: "20px", width: "100%" }}
                  className="room-type-heading flex justify_content_between align_items_center"
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      color: "#333",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    <input
                      type="radio"
                      name="room_tax"
                      value="room_tax"
                      id="room_tax"
                      onChange={() => setFilterType("room_tax")}
                      checked={filterType === "room_tax"}
                    />
                    <label htmlFor="room_tax" className="pointer">
                      Room Tax
                    </label>
                    <input
                      type="radio"
                      name="other_tax"
                      value="other_tax"
                      id="other_tax"
                      onChange={() => setFilterType("other_tax")}
                      checked={filterType === "other_tax"}
                    />
                    <label htmlFor="other_tax" className="pointer">
                      Other Tax
                    </label>
                  </div>
                </div>

                <div
                  className="edit-rooms-form-wrapper-container"
                  style={{ marginTop: "20px" }}
                >
                  {filterType === "room_tax" && (
                    <form onSubmit={handlePostRoomTax}>
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="form-container-div-half">
                          <div className="form-container-div-half">
                            <table
                              style={{
                                backgroundColor: "white",
                                padding: "0 10px 20px 0px",
                                borderCollapse: "collapse",
                                borderRadius: "10px",
                                fontFamily: "Inter",
                                width: "100%",
                              }}
                            >
                              <tr
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                <th style={{ padding: "0px" }}>Tax Name</th>
                                <th style={{ padding: "0px" }}>Min</th>
                                <th style={{ padding: "0px" }}>Max</th>
                                <th style={{ padding: "0px" }}>HSN Code</th>
                                <th style={{ padding: "0px" }}>Tax Rate</th>
                                {/* <th style={{ padding: "10px" }}>Total Amount</th> */}
                                <th
                                  style={{
                                    width: "10px",
                                    padding: "10px",
                                  }}
                                ></th>
                              </tr>

                              {dynamicInputListRoom &&
                                dynamicInputListRoom.map((bill, index) => {
                                  return (
                                    // <div>{bill.reference}</div>
                                    <tr>
                                      <>
                                        <td style={{ paddingRight: "10px" }}>
                                          <VerticalInputSelectCustomCopy
                                            options={roomTaxAddons}
                                            className="inputDynamicPreviewBill"
                                            type="text"
                                            name="tax_name"
                                            value={
                                              dynamicInputListRoom[index]
                                                ?.tax_name
                                            }
                                            style={{ width: "100px" }}
                                            onChange={(e) =>
                                              dynamicPreviewBillHandleChangeRoom(
                                                e,
                                                index
                                              )
                                            }
                                          // disabled={true}
                                          />
                                        </td>
                                        <td style={{ paddingRight: "10px" }}>
                                          <VerticalInputFieldCustom
                                            className="inputDynamicPreviewBill"
                                            type="number"
                                            name="min"
                                            value={
                                              dynamicInputListRoom[index]?.min
                                            }
                                            style={{ width: "100px" }}
                                            onChange={(e) =>
                                              dynamicPreviewBillHandleChangeRoom(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                        </td>
                                        <td style={{ paddingRight: "10px" }}>
                                          <VerticalInputFieldCustom
                                            className="inputDynamicPreviewBill"
                                            type="number"
                                            name="max"
                                            value={
                                              dynamicInputListRoom[index]?.max
                                            }
                                            style={{ width: "100px" }}
                                            onChange={(e) =>
                                              dynamicPreviewBillHandleChangeRoom(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                        </td>
                                        <td style={{ paddingRight: "10px" }}>
                                          <VerticalInputFieldCustom
                                            className="inputDynamicPreviewBill"
                                            type="number"
                                            name="hsn_code"
                                            value={
                                              dynamicInputListRoom[index]
                                                ?.hsn_code
                                            }
                                            style={{ width: "100px" }}
                                            onChange={(e) =>
                                              dynamicPreviewBillHandleChangeRoom(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <VerticalInputSelectCustomCopy
                                            options={taxRateOptions}
                                            className="inputDynamicPreviewBill"
                                            type="text"
                                            name="tax_rate"
                                            value={
                                              dynamicInputListRoom[index]
                                                ?.tax_rate
                                            }
                                            style={{ width: "100px" }}
                                            onChange={(e) =>
                                              dynamicPreviewBillHandleChangeRoom(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                        </td>

                                        <td
                                          style={{
                                            padding: "10px",
                                            width: "10px",
                                          }}
                                        >
                                          {dynamicInputListRoom.length ===
                                            index + 1 ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleAddClickRoom(bill.id)
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                              >
                                                <g clipPath="url(#clip0_1270_978)">
                                                  <path
                                                    d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                    stroke="#3968ED"
                                                  />
                                                  <path
                                                    d="M10.5866 7H3.41333"
                                                    stroke="#3968ED"
                                                    strokeMiterlimit="10"
                                                  />
                                                  <path
                                                    d="M7 3.41344L7 10.5867"
                                                    stroke="#3968ED"
                                                    strokeMiterlimit="10"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_1270_978">
                                                    <rect
                                                      width="14"
                                                      height="14"
                                                      fill="white"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </div>
                                          ) : (
                                            <div
                                              onClick={() =>
                                                handleRemoveClickRoom(bill.id)
                                              }
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                              >
                                                <g clipPath="url(#clip0_1270_876)">
                                                  <path
                                                    d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                    stroke="#3968ED"
                                                  />
                                                  <path
                                                    d="M10.5866 7H3.41333"
                                                    stroke="#3968ED"
                                                    strokeMiterlimit="10"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_1270_876">
                                                    <rect
                                                      width="14"
                                                      height="14"
                                                      fill="white"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </div>
                                          )}
                                        </td>
                                      </>
                                    </tr>
                                  );
                                })}
                            </table>

                            {/* <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "25px",
                                gap: "10px",
                              }}
                            >
                              <div style={{ cursor: "pointer" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_1270_978)">
                                    <path
                                      d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                      stroke="#3968ED"
                                    />
                                    <path
                                      d="M10.5866 7H3.41333"
                                      stroke="#3968ED"
                                      strokeMiterlimit="10"
                                    />
                                    <path
                                      d="M7 3.41344L7 10.5867"
                                      stroke="#3968ED"
                                      strokeMiterlimit="10"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1270_978">
                                      <rect
                                        width="14"
                                        height="14"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>

                              <div style={{ cursor: "pointer" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_1270_876)">
                                    <path
                                      d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                      stroke="#3968ED"
                                    />
                                    <path
                                      d="M10.5866 7H3.41333"
                                      stroke="#3968ED"
                                      strokeMiterlimit="10"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1270_876">
                                      <rect
                                        width="14"
                                        height="14"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <div className="flex flex_gap_10">
                          <BgThemeButton children={"Add"} type={"submit"} />
                        </div>
                      </div>
                    </form>
                  )}
                  {filterType === "other_tax" && (
                    <form onSubmit={handleAddOtherRoomTax}>
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="form-container-div-half">
                          <div className="form-container-div-half">
                            <table
                              style={{
                                backgroundColor: "white",
                                padding: "0 10px 20px 0px",
                                borderCollapse: "collapse",
                                borderRadius: "10px",
                                fontFamily: "Inter",
                                width: "100%",
                              }}
                            >
                              <tr
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                <th style={{ padding: "0px" }}>Tax Name</th>
                                <th style={{ padding: "0px" }}>HSN Code</th>
                                <th style={{ padding: "0px" }}>Tax Rate</th>
                                <th
                                  style={{
                                    width: "10px",
                                    padding: "10px",
                                  }}
                                ></th>
                              </tr>

                              {dynamicInputList &&
                                dynamicInputList.map((bill, index) => {
                                  return (
                                    <tr>
                                      <>
                                        <td style={{ paddingRight: "10px" }}>
                                          <VerticalInputSelectCustomCopy
                                            options={addonsForBanquetEvents}
                                            className="inputDynamicPreviewBill"
                                            type="text"
                                            name="tax_name"
                                            value={
                                              dynamicInputList[index]?.tax_name
                                            }
                                            style={{ width: "100px" }}
                                            onChange={(e) =>
                                              dynamicPreviewBillHandleChange(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                        </td>
                                        <td style={{ paddingRight: "10px" }}>
                                          <VerticalInputFieldCustom
                                            className="inputDynamicPreviewBill"
                                            type="number"
                                            name="hsn_code"
                                            value={
                                              dynamicInputList[index]?.hsn_code
                                            }
                                            style={{ width: "100px" }}
                                            onChange={(e) =>
                                              dynamicPreviewBillHandleChange(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <VerticalInputSelectCustomCopy
                                            options={taxRateOptions}
                                            className="inputDynamicPreviewBill"
                                            type="text"
                                            name="tax_rate"
                                            value={
                                              dynamicInputList[index]?.tax_rate
                                            }
                                            style={{ width: "100px" }}
                                            onChange={(e) =>
                                              dynamicPreviewBillHandleChange(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                        </td>

                                        <td
                                          style={{
                                            padding: "10px",
                                            width: "10px",
                                          }}
                                        >
                                          {dynamicInputList.length ===
                                            index + 1 ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleAddClick(bill.id)
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                              >
                                                <g clipPath="url(#clip0_1270_978)">
                                                  <path
                                                    d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                    stroke="#3968ED"
                                                  />
                                                  <path
                                                    d="M10.5866 7H3.41333"
                                                    stroke="#3968ED"
                                                    strokeMiterlimit="10"
                                                  />
                                                  <path
                                                    d="M7 3.41344L7 10.5867"
                                                    stroke="#3968ED"
                                                    strokeMiterlimit="10"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_1270_978">
                                                    <rect
                                                      width="14"
                                                      height="14"
                                                      fill="white"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </div>
                                          ) : (
                                            <div
                                              onClick={() =>
                                                handleRemoveClick(bill.id)
                                              }
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                              >
                                                <g clipPath="url(#clip0_1270_876)">
                                                  <path
                                                    d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                    stroke="#3968ED"
                                                  />
                                                  <path
                                                    d="M10.5866 7H3.41333"
                                                    stroke="#3968ED"
                                                    strokeMiterlimit="10"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_1270_876">
                                                    <rect
                                                      width="14"
                                                      height="14"
                                                      fill="white"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </div>
                                          )}
                                        </td>
                                      </>
                                    </tr>
                                  );
                                })}
                            </table>
                          </div>
                        </div>
                        <div className="flex flex_gap_10">
                          <BgThemeButton children={"Add"} type={"submit"} loader={loader}/>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </LegacyCard>
          </div>
        </div>
      )}

      {editShowDynamicInputListOther && (
        <div className="bg-backdrop">
          <div>
            <LegacyCard>
              <div style={{ padding: "20px", width: "800px" }}>
                <GoBackButtonCustom
                  buttonNextText={"Edit Other Taxes"}
                  onClick={() => setEditShowDynamicInputListOther(false)}
                />

                <div
                  className="edit-rooms-form-wrapper-container"
                  style={{ marginTop: "20px" }}
                >
                  <form onSubmit={updateOtherTaxesHandle}>
                    <div className="edit-rooms-form-wrapper-container">
                      <div className="form-container-div-half">
                        <div className="form-container-div-half">
                          <table
                            style={{
                              backgroundColor: "white",
                              padding: "0 10px 20px 0px",
                              borderCollapse: "collapse",
                              borderRadius: "10px",
                              fontFamily: "Inter",
                              width: "100%",
                            }}
                          >
                            <tr
                              style={{
                                textAlign: "left",
                              }}
                            >
                              <th style={{ padding: "0px" }}>Tax Name</th>
                              <th style={{ padding: "0px" }}>HSN Code</th>
                              <th style={{ padding: "0px" }}>Tax Rate</th>
                              {/* <th style={{ padding: "10px" }}>Total Amount</th> */}
                              <th
                                style={{
                                  width: "10px",
                                  padding: "10px",
                                }}
                              ></th>
                            </tr>

                            <tr>
                              <>
                                <td style={{ paddingRight: "10px" }}>
                                  <VerticalInputSelectCustomCopy
                                    disabled={true}
                                    options={addonsForBanquetEvents}
                                    className="inputDynamicPreviewBill"
                                    type="text"
                                    name="tax_name"
                                    value={dynamicInputListOtherData.tax_name}
                                    style={{ width: "100px" }}
                                    onChange={setAddItemDataHandleChangeOther}
                                  />
                                </td>
                                <td style={{ paddingRight: "10px" }}>
                                  <VerticalInputFieldCustom
                                    className="inputDynamicPreviewBill"
                                    type="number"
                                    name="hsn_code"
                                    value={dynamicInputListOtherData.hsn_code}
                                    style={{ width: "100px" }}
                                    onChange={setAddItemDataHandleChangeOther}
                                  />
                                </td>
                                <td>
                                  <VerticalInputSelectCustomCopy
                                    options={taxRateOptions}
                                    className="inputDynamicPreviewBill"
                                    type="text"
                                    name="tax_rate"
                                    value={dynamicInputListOtherData.tax_rate}
                                    style={{ width: "100px" }}
                                    onChange={setAddItemDataHandleChangeOther}
                                  />
                                </td>
                              </>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className="flex flex_gap_10">
                        <BgThemeButton children={"Update"} type={"submit"} />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </LegacyCard>
          </div>
        </div>
      )}

      {editShowDynamicInputListRoom && (
        <div className="bg-backdrop">
          <div>
            <LegacyCard>
              <div style={{ padding: "20px", width: "800px" }}>
                <GoBackButtonCustom
                  buttonNextText={"Edit Room Taxes"}
                  onClick={() => setEditShowDynamicInputListRoom(false)}
                />

                <div
                  className="edit-rooms-form-wrapper-container"
                  style={{ marginTop: "20px" }}
                >
                  <form onSubmit={updateRoomTaxesHandle}>
                    <div className="edit-rooms-form-wrapper-container">
                      <div className="form-container-div-half">
                        <div className="form-container-div-half">
                          <table
                            style={{
                              backgroundColor: "white",
                              padding: "0 10px 20px 0px",
                              borderCollapse: "collapse",
                              borderRadius: "10px",
                              fontFamily: "Inter",
                              width: "100%",
                            }}
                          >
                            <tr
                              style={{
                                textAlign: "left",
                              }}
                            >
                              <th style={{ padding: "0px" }}>Tax Name</th>
                              <th style={{ padding: "0px" }}>Min</th>
                              <th style={{ padding: "0px" }}>Max</th>
                              <th style={{ padding: "0px" }}>HSN Code</th>
                              <th style={{ padding: "0px" }}>Tax Rate</th>
                              {/* <th style={{ padding: "10px" }}>Total Amount</th> */}
                              <th
                                style={{
                                  width: "10px",
                                  padding: "10px",
                                }}
                              ></th>
                            </tr>

                            <tr>
                              <>
                                <td style={{ paddingRight: "10px" }}>
                                  <VerticalInputSelectCustomCopy
                                    disabled={true}
                                    options={roomTaxAddons}
                                    className="inputDynamicPreviewBill"
                                    type="text"
                                    name="tax_name"
                                    value={dynamicInputListRoomData.tax_name}
                                    style={{ width: "100px" }}
                                    onChange={setAddItemDataHandleChangeRoom}
                                  />
                                </td>
                                <td style={{ paddingRight: "10px" }}>
                                  <VerticalInputFieldCustom
                                    className="inputDynamicPreviewBill"
                                    type="number"
                                    name="min"
                                    value={dynamicInputListRoomData.min}
                                    style={{ width: "100px" }}
                                    onChange={setAddItemDataHandleChangeRoom}
                                  />
                                </td>
                                <td style={{ paddingRight: "10px" }}>
                                  <VerticalInputFieldCustom
                                    className="inputDynamicPreviewBill"
                                    type="number"
                                    name="max"
                                    value={dynamicInputListRoomData.max}
                                    style={{ width: "100px" }}
                                    onChange={setAddItemDataHandleChangeRoom}
                                  />
                                </td>
                                <td style={{ paddingRight: "10px" }}>
                                  <VerticalInputFieldCustom
                                    className="inputDynamicPreviewBill"
                                    type="number"
                                    name="hsn_code"
                                    value={dynamicInputListRoomData.hsn_code}
                                    style={{ width: "100px" }}
                                    onChange={setAddItemDataHandleChangeRoom}
                                  />
                                </td>
                                <td>
                                  <VerticalInputSelectCustomCopy
                                    options={taxRateOptions}
                                    className="inputDynamicPreviewBill"
                                    type="text"
                                    name="tax_rate"
                                    value={dynamicInputListRoomData.tax_rate}
                                    style={{ width: "100px" }}
                                    onChange={setAddItemDataHandleChangeRoom}
                                  />
                                </td>
                              </>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className="flex flex_gap_10">
                        <BgThemeButton children={"Update"} type={"submit"} />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </LegacyCard>
          </div>
        </div>
      )}

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                >
                  Discard
                </Button>
                {filterType === "room_tax" ? (
                  <Button onClick={deleteRoomTaxesHandle} destructive>
                    Yes, Remove
                  </Button>
                ) : (
                  <Button onClick={deleteOtherTaxesHandle} destructive>
                    Yes, Remove
                  </Button>
                )}
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default TaxSettingsCrud;
