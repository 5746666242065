import React from "react";
import "./EventVoucher.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Link, useNavigate, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import NoDataFound from "../../CustomComponents/NoDataFound";
import Loader from "../../UI/Loader/Loader";
import { CheckWithoutBgUnderlineBtn } from "../../UI/Buttons";

const EventCommunicationVoucher = ({eventid}) => {
  const [eventData, setPreviewBillDetailData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showShareOnEmail, setShowShareOnEmail] = useState(false);
  const [showShareOnWhatsapp, setShowShareWhatsapp] = useState(false);
  const [bookingData, setBookingData] = useState({});
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [voucherEmail, setVoucherEmail] = useState("");
  const [urlToSend, setUrlToSend] = useState("");
  const shareLink = urlToSend;

  const property_id = useSelector((state) => state.property.value);

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const navigate = useNavigate();

  // let { eventid } = useParams();

  const [pdfFile, setPdfFile] = useState(null);

  const [activeTabButtonShare, setActiveTabButtonShare] = useState("");

  const handleShowShareOnEmail = (click) => {
    setActiveTabButtonShare(click);
    if (showShareOnWhatsapp === true) setShowShareWhatsapp(false);
    setShowShareOnEmail(!showShareOnEmail);
  };
  const handleShowShareOnWhatsapp = (click) => {
    setActiveTabButtonShare(click);
    if (showShareOnEmail === true) setShowShareOnEmail(false);
    setShowShareWhatsapp(!showShareOnWhatsapp);
  };

  const handlePrintClick = () => {
    window.print();
  };
  const handleDownload = () => {
    const htmlContent = document.querySelector(
      ".booking-choice-full-parent-container-left"
    );

    const dpi = 500;
    const scale = 10;
    const imageQuality = 1;

    html2canvas(htmlContent, { dpi: dpi, scale: scale }).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = 210;
      const pdfHeight = 297;

      const aspectRatio = canvas.width / canvas.height;
      let imgWidth = pdfWidth;
      let imgHeight = pdfWidth / aspectRatio;

      if (imgHeight > pdfHeight) {
        imgHeight = pdfHeight;
        imgWidth = pdfHeight * aspectRatio;
      }

      const xOffset = (pdfWidth - imgWidth) / 2;
      const yOffset = 0;

      pdf.addImage(
        canvas.toDataURL("image/jpeg", imageQuality),
        "PNG",
        xOffset,
        yOffset,
        imgWidth,
        imgHeight
      );
      const generatedPdfFile = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(generatedPdfFile);

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = pdfUrl;
      downloadLink.download = "downloaded-page.pdf";
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      setPdfFile(downloadLink);
      setUrlToSend(downloadLink);
      // Simulate a click on the download link to trigger the download
      downloadLink.click();

      // Clean up by removing the download link
      document.body.removeChild(downloadLink);
    });
  };

  const handleWhatsAppbuttonClick = () => {
    const htmlContent = document.querySelector(
      ".booking-choice-full-parent-container-left"
    );

    const dpi = 500;
    const scale = 10;
    const imageQuality = 1;

    html2canvas(htmlContent, { dpi: dpi, scale: scale }).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = 210;
      const pdfHeight = 297;

      const aspectRatio = canvas.width / canvas.height;
      let imgWidth = pdfWidth;
      let imgHeight = pdfWidth / aspectRatio;

      if (imgHeight > pdfHeight) {
        imgHeight = pdfHeight;
        imgWidth = pdfHeight * aspectRatio;
      }

      const xOffset = (pdfWidth - imgWidth) / 2;
      const yOffset = 0;

      pdf.addImage(
        canvas.toDataURL("image/jpeg", imageQuality),
        "PNG",
        xOffset,
        yOffset,
        imgWidth,
        imgHeight
      );
      const generatedPdfFile = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(generatedPdfFile);

      const downloadLink = document.createElement("a");
      downloadLink.href = pdfUrl;
      downloadLink.download = "downloaded.pdf";
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      setPdfFile(downloadLink);
      setUrlToSend(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
    });
    // if (/^\d{10}$/.test(whatsappNumber)) {
    //   const linkText = `Click this link to view the shared content:`;
    //   const whatsappMessage = `${linkText}: ${urlToSend}`;
    //   const whatsappUrl = `https://wa.me/91${whatsappNumber}?text=${encodeURIComponent(
    //     whatsappMessage
    //   )}`;

    //   window.open(whatsappUrl, "_blank");
    // } else {
    //   showErrorToast(
    //     "Please enter a valid 10-digit WhatsApp number without country code."
    //   );
    // }
    const shareUrl = `blob:http://localhost:3000/3de55a2a-ed5a-4bed-aa05-d62005c918fd`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleEmailButtonClick = async () => {
    try {
      // setLoader(true);
      const response = await fetch(
        // ${process.env.REACT_APP_BASE_URL}/send/voucher/316028/saurabhunmetered@gmail.com/1

        // ${process.env.REACT_APP_BASE_URL}/api/v1/send/voucher/316028/saurabhunmetered@gmail.com/1
        `${process.env.REACT_APP_BASE_URL}/api/v1/send/event/voucher/${eventid}/${voucherEmail}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setVoucherEmail("");
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // const handleEmailButtonClick = () => {
  //   const htmlContent = document.querySelector(
  //     ".booking-choice-full-parent-container-left"
  //   );

  //   const dpi = 500;
  //   const scale = 10;
  //   const imageQuality = 1;

  //   html2canvas(htmlContent, { dpi: dpi, scale: scale }).then((canvas) => {
  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const pdfWidth = 210;
  //     const pdfHeight = 297;

  //     const aspectRatio = canvas.width / canvas.height;
  //     let imgWidth = pdfWidth;
  //     let imgHeight = pdfWidth / aspectRatio;

  //     if (imgHeight > pdfHeight) {
  //       imgHeight = pdfHeight;
  //       imgWidth = pdfHeight * aspectRatio;
  //     }

  //     const xOffset = (pdfWidth - imgWidth) / 2;
  //     const yOffset = 0;

  //     pdf.addImage(
  //       canvas.toDataURL("image/jpeg", imageQuality),
  //       "PNG",
  //       xOffset,
  //       yOffset,
  //       imgWidth,
  //       imgHeight
  //     );
  //     const generatedPdfFile = pdf.output("blob");
  //     const pdfUrl = URL.createObjectURL(generatedPdfFile);

  //     const downloadLink = document.createElement("a");
  //     downloadLink.href = pdfUrl;
  //     downloadLink.download = "downloaded.pdf";
  //     downloadLink.style.display = "none";
  //     document.body.appendChild(downloadLink);
  //     setPdfFile(downloadLink);
  //     setUrlToSend(downloadLink);
  //     // downloadLink.click();

  //     document.body.removeChild(downloadLink);
  //   });

  //   // blob:http://localhost:3000/3de55a2a-ed5a-4bed-aa05-d62005c918fd
  //   const subject = "Check out this PDF";
  //   const emailBody = `Here is the PDF you requested: ${urlToSend}`;
  //   const mailtoUrl = `mailto:?subject=${encodeURIComponent(
  //     subject
  //   )}&body=${encodeURIComponent(emailBody)}`;
  //   window.location.href = mailtoUrl;
  // };

  const [slotsArray, setSlotsArray] = useState([]);
  const [processedSlotsArray, setProcessedSlotsArray] = useState([]);

  async function fetchData(propertyId) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/event/view/bill/${eventid}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          setPreviewBillDetailData(data.event);
          setSlotsArray(data.event.event.slots);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.error("API request error:", error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const uniqueDates = new Set();

    const updatedResultArray = [];

    slotsArray?.forEach((item) => {
      const date = item.date;
      const slot = item.slot;

      if (!uniqueDates.has(date)) {
        uniqueDates.add(date);
        updatedResultArray.push({ date, slots: [slot] });
      } else {
        const existingEntry = updatedResultArray.find(
          (entry) => entry.date === date
        );
        existingEntry.slots.push(slot);
      }
    });

    setProcessedSlotsArray(updatedResultArray);
  }, [slotsArray]);

  useEffect(() => {
    if (property_id) {
      fetchData(property_id?.id);
    }
  }, [property_id]);

  if (isLoading) {
    return <Loader />;
  }

  if (!eventData) {
    return <NoDataFound />;
  }
  return (
    <>
      {/* <div className="main_container"> */}
        {/* <div className="goBackBtn">
          <Link to="/communication" style={{ display: "inline-block" }}>
            <CheckWithoutBgUnderlineBtn children={"Go Back"} />
          </Link>
        </div> */}
        <div >
          {/* Voucher Full Width */}
          <div
            style={{ backgroundColor: "#FFF" }}
            className="booking-choice-full-parent-container-left"
          >
            <div
              style={{ width: "100%", marginBottom: 0 }}
              className="voucher_container"
            >
              <table
                cellSpacing={0}
                border={0}
                cellPadding={0}
                style={{ margin: "0 auto" }}
              >
                <tbody>
                  <tr>
                    <td
                      align="center"
                      style={{ backgroundColor: "#ffffff", paddingTop: 10 }}
                    >
                      <table
                        style={{ width: 800, minWidth: 800, margin: "0 auto" }}
                        cellPadding={0}
                        cellSpacing={0}
                      >
                        <tbody>
                          <tr>
                            <td valign="center" style={{ paddingLeft: 15 }}>
                              <img
                                alt=""
                                src="../assets/images/golden-fern-logo 1.png"
                                style={{
                                  display: "block",
                                  border: 0,
                                  color: "white",
                                  fontFamily: "sans-serif",
                                  fontSize: 25,
                                  fontWeight: "bold",
                                }}
                                width={165}
                              />
                            </td>
                            <td style={{ paddingRight: 18 }}>
                              <div
                                style={{
                                  textAlign: "right",
                                  fontSize: 18,
                                  color: "#313715",
                                  padding: "0px 0px 3px 0px",
                                }}
                              >
                                <b>Event ID: {eventData?.event?.event_id}</b>
                              </div>
                              <div
                                style={{
                                  textAlign: "right",
                                  fontSize: 12,
                                  color: "#3968ED",
                                  padding: "0px 0px 3px 0px",
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                }}
                              >
                                {eventData?.booking?.status.replace("_", " ")}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellSpacing={0}
                cellPadding={0}
                style={{ margin: "0 auto" }}
              >
                <tbody>
                  <tr>
                    <td align="center" style={{ backgroundColor: "#fff" }}>
                      <table
                        style={{
                          width: 800,
                          minWidth: 800,
                          margin: "0 auto",
                          padding: '20px 0',
                        }}
                        cellPadding={0}
                        cellSpacing={0}
                      >
                        <tbody>
                          <tr>
                            <td
                              valign="center"
                              style={{
                                backgroundColor: "#3968ED",
                                fontWeight: 500,
                                textTransform: "uppercase",
                                color: "#fff",
                                padding: "10px 20px 10px 20px",
                                fontSize: 10,
                                borderRadius: "10px 10px 0 0",
                              }}
                            >
                              {eventData &&
                                eventData.propertyDetail.property_name}
                            </td>
                          </tr>
                          <tr>
                            <td align="center">
                              <table
                                cellSpacing={0}
                                border={0}
                                cellPadding={0}
                                width="100%"
                                style={{
                                  border: "1px solid #3968ED",
                                  borderRadius: "0 0 10px 10px",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <table
                                        align="left"
                                        border={0}
                                        cellSpacing={0}
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td style={{ padding: 10 }}>
                                              <table>
                                                <tbody>
                                                  <tr>
                                                    <th
                                                      width="20%"
                                                      style={{
                                                        textAlign: "left",
                                                        fontFamily:
                                                          '"Poppins", sans-serif',
                                                        fontSize: 14,
                                                        color: "#171717",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Ph No.:{" "}
                                                      {eventData &&
                                                        eventData.propertyDetail
                                                          .property_phone}
                                                    </th>
                                                    <th
                                                      width="20%"
                                                      style={{
                                                        textAlign: "left",
                                                        fontSize: 14,
                                                        color: "#171717",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Check-In
                                                    </th>
                                                    <th
                                                      width="20%"
                                                      style={{
                                                        textAlign: "left",
                                                        fontSize: 14,
                                                        color: "#171717",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Check-Out
                                                    </th>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        textAlign: "left",
                                                        fontSize: 12,
                                                        color: "#171717",
                                                      }}
                                                    >
                                                      <b>
                                                        {eventData &&
                                                          eventData
                                                            .propertyDetail
                                                            .property_email}
                                                      </b>
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "left",
                                                        fontSize: 12,
                                                        color: "#171717",
                                                      }}
                                                    >
                                                      <b>Date: </b>
                                                      NA
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "left",
                                                        fontSize: 12,
                                                        color: "#171717",
                                                      }}
                                                    >
                                                      <b>Date: </b>
                                                      NA
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        textAlign: "left",
                                                        fontSize: 12,
                                                        color: "#3968ED",
                                                        textDecoration:
                                                          "underline",
                                                        paddingBottom: 5,
                                                      }}
                                                    >
                                                      {property_id &&
                                                        property_id?.property_address}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "left",
                                                        fontSize: 12,
                                                        color: "#171717",
                                                        display: "block",
                                                      }}
                                                    >
                                                      {/* <b>Time: </b> */}
                                                      {/* {eventData &&
                                                        eventData.booking.created_at.slice(
                                                          11,
                                                          16
                                                        )} */}
                                                      {/* 12:00 */}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "left",
                                                        fontSize: 12,
                                                        color: "#171717",
                                                        paddingBottom: 18,
                                                      }}
                                                    >
                                                      {/* <b>Time: </b> */}
                                                      {/* {eventData &&
                                                        eventData.booking.updated_at.slice(
                                                          11,
                                                          16
                                                        )}
                                                         */}
                                                      {/* 14:00 */}
                                                    </td>
                                                  </tr>
                                                  {/* <tr>
                                                    <td
                                                      style={{
                                                        color: "#3968ED",
                                                        fontSize: 12,
                                                        textDecoration:
                                                          "underline",
                                                      }}
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={15}
                                                        height={15}
                                                        viewBox="0 0 10 10"
                                                        fill="none"
                                                      >
                                                        <g clipPath="url(#clip0_1780_490)">
                                                          <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M9 4C9 1.79 7.21 0 5 0C2.79 0 1 1.79 1 4C1 4.075 1 4.145 1.005 4.22C1.07 5.995 2 8.03 4.57 9.865C4.825 10.045 5.175 10.045 5.43 9.865C8 8.03 8.93 5.995 8.995 4.22C9 4.14501 9 4.07498 9 4ZM6.0605 5.0605C6.342 4.7795 6.5 4.398 6.5 4C6.5 3.602 6.3415 3.221 6.0605 2.9395C5.7795 2.658 5.398 2.5 5 2.5C4.602 2.5 4.221 2.6585 3.9395 2.9395C3.658 3.2205 3.5 3.602 3.5 4C3.5 4.398 3.658 4.7795 3.9395 5.0605C4.2205 5.342 4.602 5.5 5 5.5C5.3975 5.5 5.779 5.342 6.0605 5.0605Z"
                                                            fill="#3968ED"
                                                          />
                                                        </g>
                                                        <defs>
                                                          <clipPath id="clip0_1780_490">
                                                            <rect
                                                              width={50}
                                                              height={50}
                                                              fill="white"
                                                            />
                                                          </clipPath>
                                                        </defs>
                                                      </svg>
                                                      Get Direction
                                                    </td>
                                                  </tr> */}
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              {eventData &&
                eventData?.booking?.items?.map((item, index) => (
                  <table
                    cellSpacing={0}
                    cellPadding={0}
                    style={{ margin: "0 auto" }}
                    key={item.id}
                  >
                    <tbody>
                      <tr>
                        <td align="center" style={{ backgroundColor: "#fff" }}>
                          <table
                            style={{
                              width: 800,
                              minWidth: 800,
                              margin: "0 auto",
                              padding: 20,
                            }}
                            cellPadding={0}
                            cellSpacing={0}
                          >
                            <tbody>
                              <tr>
                                <td
                                  valign="center"
                                  style={{
                                    backgroundColor: "#3968ED",
                                    fontWeight: 500,
                                    textTransform: "uppercase",
                                    borderRadius: "10px 10px 0 0",
                                    color: "#fff",
                                    padding: 10,
                                    fontSize: 10,
                                  }}
                                >
                                  {item.room_type_name}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <table
                                    cellPadding={0}
                                    cellSpacing={0}
                                    align="left"
                                    style={{
                                      border: "1px solid #3968ED",
                                      borderRadius: "0 0 10px 10px",
                                      width: "100%",
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textTransform: "uppercase",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          {/* <b>Guest Name</b> */}
                                          <b>RATE PER NIGHT</b>
                                        </td>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textAlign: "end",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          {item.room_rate}
                                          {/* {eventData &&
                                            eventData?.booking
                                              ?.guest[0]?.guest_name} */}
                                        </td>
                                      </tr>
                                      {/* <tr>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textTransform: "uppercase",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          <b>Room Type</b>
                                        </td>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textAlign: "end",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          {item.room_type_name}
                                          {eventData &&
                                            eventData.booking.items[0]
                                              .room_type_name}
                                        </td>
                                      </tr> */}
                                      <tr>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textTransform: "uppercase",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          <b>Meal Plan</b>
                                        </td>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textAlign: "end",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          {item.room_plan}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textTransform: "uppercase",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          <b>No of Rooms</b>
                                        </td>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textAlign: "end",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          {item.no_of_rooms}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textTransform: "uppercase",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          <b>No Of Adult</b>
                                        </td>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textAlign: "end",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          {item.adult}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textTransform: "uppercase",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          <b>No Of Child</b>
                                        </td>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textAlign: "end",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          {item.child}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textTransform: "uppercase",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          <b>Extra Adult</b>
                                        </td>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textAlign: "end",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          0
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textTransform: "uppercase",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          <b>Extra Child</b>
                                        </td>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textAlign: "end",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          0
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            textTransform: "uppercase",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                          }}
                                        >
                                          {/* <b>Booking Date</b> */}
                                          <b>ROOM TOTAL AMOUNT</b>
                                        </td>
                                        <td
                                          style={{
                                            fontSize: 12,
                                            color: "#313715",
                                            width: 287,
                                            paddingTop: 7,
                                            paddingBottom: 7,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                            textAlign: "end",
                                          }}
                                        >
                                          {item.room_plan_total}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ))}

              <div
                style={{
                  width: "100%",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <div
                  style={{ width: "100%" }}
                  className="transaction-table-container"
                >
                  <table className="transaction-table">
                    <thead>
                      <tr className="table-head-row">
                        <th className="table-header-events">Event Details</th>
                        <th className="table-header-events"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{ padding: "0 10px" }}
                          className="table-data"
                        >
                          Event Name
                        </td>
                        <td
                          style={{ padding: "0 10px" }}
                          className="table-data"
                        >
                          {eventData.event.event_name}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ padding: "0 10px" }}
                          className="table-data"
                        >
                          Event ID
                        </td>
                        <td
                          style={{ padding: "0 10px" }}
                          className="table-data"
                        >
                          {eventData.event.event_id}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ padding: "0 10px" }}
                          className="table-data"
                        >
                          Event Category
                        </td>
                        <td
                          style={{ padding: "0 10px" }}
                          className="table-data"
                        >
                          {eventData?.event?.category?.category_name}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ padding: "0 10px" }}
                          className="table-data"
                        >
                          No Of Pax
                        </td>
                        <td
                          style={{ padding: "0 10px" }}
                          className="table-data"
                        >
                          {eventData?.event.no_of_pax}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ padding: "0 10px" }}
                          className="table-data"
                        >
                          No Of Plates
                        </td>
                        <td
                          style={{ padding: "0 10px" }}
                          className="table-data"
                        >
                          {eventData?.event?.no_of_plates}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  style={{ width: "100%" }}
                  className="transaction-table-container"
                >
                  <table className="transaction-table">
                    <thead>
                      <tr className="table-head-row">
                        <th className="table-header-events">Client Details</th>
                        <th className="table-header-events"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{ padding: "2px 10px" }}
                          className="table-data"
                        >
                          Client Name
                        </td>
                        <td
                          style={{ padding: "2px 10px" }}
                          className="table-data"
                        >
                          {eventData?.event?.client_name}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{ padding: "2px 10px" }}
                          className="table-data"
                        >
                          Client Phone
                        </td>
                        <td
                          style={{ padding: "2px 10px" }}
                          className="table-data"
                        >
                          {eventData?.event?.client_phone}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ padding: "2px 10px" }}
                          className="table-data"
                        >
                          Client Email
                        </td>
                        <td
                          style={{ padding: "2px 10px" }}
                          className="table-data"
                        >
                          {eventData?.event?.client_email}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
                className="invoice_container"
              >
                <div className="transaction-table-container" style={{}}>
                  <table className="transaction-table">
                    <thead>
                      <tr className="table-head-row">
                        <th className="table-header-events">Item Name</th>
                        <th className="table-header-events">Item Price</th>
                        <th className="table-header-events">Quantity</th>
                        <th className="table-header-events">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventData.event &&
                        eventData.event.items.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="table-data">{item?.item_name}</td>
                              <td className="table-data">{item?.price}</td>
                              <td className="table-data">{1}</td>
                              <td className="table-data">{item?.price}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
                className="invoice_container"
              >
                <div
                  style={{ width: "100%" }}
                  className="transaction-table-container"
                >
                  <table className="transaction-table">
                    <thead>
                      <tr className="table-head-row">
                        <th className="table-header-events">Room Details</th>
                        <th className="table-header-events"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{ fontWeight: "bold", padding: "0 10px" }}
                          className="table-data"
                        >
                          Room Name
                        </td>
                        <td
                          style={{ fontWeight: "bold", padding: "0 10px" }}
                          className="table-data"
                        >
                          Quantity
                        </td>
                      </tr>

                      {eventData.event &&
                        eventData.event.rooms.map((room, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{ padding: "0 10px" }}
                                className="table-data"
                              >
                                {room.room_name}
                              </td>
                              <td
                                style={{ padding: "0 10px" }}
                                className="table-data"
                              >
                                {room.quantity}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div
                  style={{ width: "100%" }}
                  className="transaction-table-container"
                >
                  <table className="transaction-table">
                    <thead>
                      <tr className="table-head-row">
                        <th className="table-header-events">Slot Details</th>
                        <th className="table-header-events"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{ fontWeight: "bold", padding: "2px 10px" }}
                          className="table-data"
                        >
                          Date
                        </td>
                        <td
                          style={{ fontWeight: "bold", padding: "2px 10px" }}
                          className="table-data"
                        >
                          Timing
                        </td>
                      </tr>
                      {eventData.event &&
                        processedSlotsArray &&
                        processedSlotsArray.map((slot, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{ padding: "2px 10px" }}
                                className="table-data"
                              >
                                {slot?.date}
                              </td>
                              <td
                                style={{ padding: "2px 10px" }}
                                className="table-data"
                              >
                                {slot.slots.map((slot, index) => (
                                  <span
                                    key={index}
                                    style={{ marginRight: "5px" }}
                                  >
                                    {slot}
                                  </span>
                                ))}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>

              <table
                cellSpacing={0}
                cellPadding={0}
                style={{ margin: "0 auto" }}
              >
                <tbody>
                  <tr>
                    <td align="left" style={{ backgroundColor: "#fff" }}>
                      <table
                        style={{
                          width: 800,
                          minWidth: 800,
                          margin: "0 auto",
                          padding: 20,
                          paddingBottom: "20px",
                        }}
                        cellPadding={0}
                        cellSpacing={0}
                      >
                        <tbody>
                          <tr>
                            <td
                              valign="center"
                              style={{
                                backgroundColor: "#3968ED",
                                fontWeight: 500,
                                textTransform: "upperCase",
                                borderRadius: "10px 10px 0 0",
                                color: "#fff",
                                padding: 10,
                                fontSize: 10,
                              }}
                            >
                              Payment Details
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                align="left"
                                style={{
                                  border: "1px solid #3968ED",
                                  borderRadius: "0 0 10px 10px",
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        textTransform: "uppercase",
                                        width: 287,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        textAlign: "left",
                                      }}
                                    >
                                      <b>Tax Rate</b>
                                    </td>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        textAlign: "end",
                                        width: 287,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                      }}
                                    >
                                      {eventData?.event?.tax_applied || ""}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        textTransform: "uppercase",
                                        width: 287,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        textAlign: "left",
                                      }}
                                    >
                                      <b>Gst Type</b>
                                    </td>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        textAlign: "end",
                                        width: 287,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                      }}
                                    >
                                      {eventData?.event?.gst_type.toUpperCase() ||
                                        ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        textTransform: "uppercase",
                                        width: 287,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        textAlign: "left",
                                      }}
                                    >
                                      <b>Sub Total</b>
                                    </td>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        textAlign: "end",
                                        width: 287,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                      }}
                                    >
                                      &#x20b9;
                                      {eventData?.event?.sub_total || ""}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        textTransform: "uppercase",
                                        width: 287,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        textAlign: "left",
                                      }}
                                    >
                                      <b>Tax Amount</b>
                                    </td>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        textAlign: "end",
                                        width: 287,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                      }}
                                    >
                                      &#x20b9;
                                      {eventData?.event?.tax_amount}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        textTransform: "uppercase",
                                        width: 287,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        textAlign: "left",
                                      }}
                                    >
                                      <b>Grand Total</b>
                                    </td>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        textAlign: "end",
                                        width: 287,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                      }}
                                    >
                                      &#x20b9;
                                      {eventData &&
                                        eventData?.event?.grand_total}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        textTransform: "uppercase",
                                        width: 287,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        textAlign: "left",
                                      }}
                                    >
                                      <b>Payment Method</b>
                                    </td>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        textAlign: "end",
                                        width: 287,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {eventData &&
                                        eventData?.bill?.payment_mode.replace(
                                          "_",
                                          " "
                                        )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* <div
              style={{ width: "800", marginBottom: 0 }}
              className="voucher_container"
            >
              <table
                cellSpacing={0}
                cellPadding={0}
                style={{ margin: "0 auto" }}
              >
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: "#fff" }}>
                      <table
                        style={{ padding: 20 }}
                        cellPadding={0}
                        cellSpacing={0}
                      >
                        <tbody>
                          <tr>
                            <td
                              valign="center"
                              style={{
                                backgroundColor: "#3968ED",
                                fontWeight: 500,
                                textTransform: "uppercase",
                                borderRadius: "10px 10px 0 0",
                                color: "#fff",
                                padding: 10,
                                fontSize: 10,
                              }}
                            >
                              Important Reminders
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                align="left"
                                style={{
                                  border: "1px solid #3968ED",
                                  borderRadius: "0 0 10px 10px",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        width: "100%",
                                        padding: 10,
                                      }}
                                    >
                                      <p>
                                        1. Please cross check the standard check
                                        in time for property to ensure a smooth
                                        check-in and enjoy the full experience.{" "}
                                        <br />
                                      </p>
                                      <p>
                                        2. If you have any special requests or
                                        dietary restrictions, please inform us
                                        in advance so that we can make the
                                        necessary arrangements. <br />
                                      </p>
                                      <p>
                                        3. Kindly retain this email as your
                                        booking confirmation and present it upon
                                        arrival or when requested.
                                      </p>
                                      <p style={{ fontStyle: "italic" }}>
                                        Should you have any further questions or
                                        require assistance, feel free to reach
                                        out to our customer support team at{" "}
                                        Email :{" "}
                                        <span
                                          style={{
                                            color: "#3968ED",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          {property_id &&
                                            property_id?.property_email}{" "}
                                        </span>
                                        or Phone :{" "}
                                        <span
                                          style={{
                                            color: "#3968ED",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          {eventData &&
                                            eventData?.booking?.guest[0]
                                              ?.guest_phone}
                                        </span>
                                      </p>
                                      <p style={{ fontStyle: "italic" }}>
                                        We look forward to welcoming you and
                                        providing an exceptional booking
                                        experience.
                                      </p>
                                      <p
                                        style={{
                                          fontStyle: "italic",
                                          paddingBottom: 15,
                                        }}
                                      >
                                        Thank you once again for choosing Eco
                                        Hospitality!
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style={{
                          padding: "0 20px 20px 20px",
                        }}
                        cellPadding={0}
                        cellSpacing={0}
                      >
                        <tbody>
                          <tr>
                            <td
                              valign="center"
                              style={{
                                backgroundColor: "#3968ED",
                                fontWeight: 500,
                                textTransform: "uppercase",
                                borderRadius: "10px 10px 0 0",
                                color: "#fff",
                                padding: 10,
                                fontSize: 10,
                              }}
                            >
                              Hotel Policies
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                align="left"
                                style={{
                                  border: "1px solid #3968ED",
                                  borderRadius: "0 0 10px 10px",
                                  padding: 10,
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        width: "100%",
                                      }}
                                    >
                                      <p
                                        style={{
                                          borderBottom: "1px solid #ccc",
                                          paddingBottom: 15,
                                        }}
                                      >
                                        Arrival and Departure Policy
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            float: "right",
                                          }}
                                        >
                                          Check-In: 1400 Hours
                                        </span>
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            float: "right",
                                            marginRight: 25,
                                          }}
                                        >
                                          Check-In: 1400 Hours
                                        </span>
                                      </p>
                                      <p>
                                        Early arrival is subject to
                                        availability. For guaranteed early
                                        check-in, reservation needs to be made
                                        starting from the previous night. <br />
                                      </p>
                                      <p
                                        style={{
                                          fontSize: 10,
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Late check-outs are available on request
                                        and subject to availability. A recent
                                        Government notification requires guests
                                        to present proof of identity at the time
                                        of check in. Guests are requested to
                                        carry with them the required document
                                        during their travel.
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style={{
                          padding: "0 20px 20px 20px",
                        }}
                        cellPadding={0}
                        cellSpacing={0}
                      >
                        <tbody>
                          <tr>
                            <td
                              valign="center"
                              style={{
                                backgroundColor: "#3968ED",
                                fontWeight: 500,
                                textTransform: "uppercase",
                                borderRadius: "10px 10px 0 0",
                                color: "#fff",
                                padding: 10,
                                fontSize: 10,
                              }}
                            >
                              Cancellation Policies
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                align="left"
                                style={{
                                  border: "1px solid #3968ED",
                                  borderRadius: "0 0 10px 10px",
                                  padding: 10,
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        width: "100%",
                                      }}
                                    >
                                      <p>
                                        Reservation must be cancelled 15 days
                                        prior to the planned date of arrival.
                                        One night’s stay will be levied for
                                        cancellations received upto 8 days prior
                                        to arrival. Full stay will be levied for
                                        cancellations received within 7 days
                                        prior to arrival.
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style={{
                          padding: "0 20px 20px 20px",
                        }}
                        cellPadding={0}
                        cellSpacing={0}
                      >
                        <tbody>
                          <tr>
                            <td
                              valign="center"
                              style={{
                                backgroundColor: "#3968ED",
                                fontWeight: 500,
                                textTransform: "uppercase",
                                borderRadius: "10px 10px 0 0",
                                color: "#fff",
                                padding: 10,
                                fontSize: 10,
                              }}
                            >
                              Child Policies
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                align="left"
                                style={{
                                  border: "1px solid #3968ED",
                                  borderRadius: "0 0 10px 10px",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        width: "100%",
                                        padding: 10,
                                      }}
                                    >
                                      <p>
                                        Child below the age of 5 years is
                                        complimentary without extra bed and
                                        child in the age group of 6 to 11 years
                                        shall be chargeable as per the rates
                                        mentioned above in the special rates
                                        column.
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <h4
                        style={{
                          fontWeight: "lighter",
                          margin: "5px 0",
                          textAlign: "center",
                        }}
                      >
                        STAY IN TOUCH
                      </h4>
                      <p style={{ textAlign: "center" }}>
                        <a
                          href=""
                          style={{
                            border: "1px solid #888",
                            borderRadius: "50%",
                            marginTop: "-12px",
                            display: "inline-block",
                            padding: "5px 8px",
                            textAlign: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            viewBox="0 0 11 11"
                            fill="none"
                            style={{ marginTop: "4px" }}
                          >
                            <path
                              d="M6.57129 5.92857H7.64272L8.07129 4.21429H6.57129V3.35714C6.57129 2.91571 6.57129 2.5 7.42843 2.5H8.07129V1.06C7.93157 1.04157 7.404 1 6.84686 1C5.68329 1 4.857 1.71014 4.857 3.01429V4.21429H3.57129V5.92857H4.857V9.57143H6.57129V5.92857Z"
                              fill="#888888"
                            />
                          </svg>
                        </a>
                        <a
                          href=""
                          style={{
                            border: "1px solid #888",
                            borderRadius: "50%",
                            marginTop: "-12px",
                            display: "inline-block",
                            padding: "5px 8px",
                            margin: "0 5px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            viewBox="0 0 11 11"
                            fill="none"
                            style={{ marginTop: "4px" }}
                          >
                            <path
                              d="M3.91394 1H7.51394C8.88537 1 9.99965 2.11429 9.99965 3.48571V7.08571C9.99965 7.74497 9.73776 8.37722 9.2716 8.84338C8.80544 9.30954 8.17319 9.57143 7.51394 9.57143H3.91394C2.54251 9.57143 1.42822 8.45714 1.42822 7.08571V3.48571C1.42822 2.82646 1.69011 2.19421 2.15627 1.72805C2.62243 1.26189 3.25468 1 3.91394 1ZM3.82822 1.85714C3.41903 1.85714 3.0266 2.01969 2.73726 2.30904C2.44792 2.59838 2.28537 2.99081 2.28537 3.4V7.17143C2.28537 8.02429 2.97537 8.71429 3.82822 8.71429H7.59965C8.00884 8.71429 8.40127 8.55173 8.69062 8.26239C8.97996 7.97305 9.14251 7.58062 9.14251 7.17143V3.4C9.14251 2.54714 8.45251 1.85714 7.59965 1.85714H3.82822ZM7.96394 2.5C8.10602 2.5 8.24228 2.55644 8.34274 2.65691C8.44321 2.75737 8.49965 2.89363 8.49965 3.03571C8.49965 3.17779 8.44321 3.31406 8.34274 3.41452C8.24228 3.51499 8.10602 3.57143 7.96394 3.57143C7.82186 3.57143 7.6856 3.51499 7.58513 3.41452C7.48466 3.31406 7.42822 3.17779 7.42822 3.03571C7.42822 2.89363 7.48466 2.75737 7.58513 2.65691C7.6856 2.55644 7.82186 2.5 7.96394 2.5ZM5.71394 3.14286C6.28226 3.14286 6.8273 3.36862 7.22917 3.77049C7.63103 4.17235 7.85679 4.71739 7.85679 5.28571C7.85679 5.85403 7.63103 6.39908 7.22917 6.80094C6.8273 7.20281 6.28226 7.42857 5.71394 7.42857C5.14562 7.42857 4.60057 7.20281 4.19871 6.80094C3.79684 6.39908 3.57108 5.85403 3.57108 5.28571C3.57108 4.71739 3.79684 4.17235 4.19871 3.77049C4.60057 3.36862 5.14562 3.14286 5.71394 3.14286ZM5.71394 4C5.37294 4 5.04592 4.13546 4.8048 4.37658C4.56368 4.61769 4.42822 4.94472 4.42822 5.28571C4.42822 5.62671 4.56368 5.95373 4.8048 6.19485C5.04592 6.43597 5.37294 6.57143 5.71394 6.57143C6.05493 6.57143 6.38196 6.43597 6.62307 6.19485C6.86419 5.95373 6.99965 5.62671 6.99965 5.28571C6.99965 4.94472 6.86419 4.61769 6.62307 4.37658C6.38196 4.13546 6.05493 4 5.71394 4Z"
                              fill="#888888"
                            />
                          </svg>
                        </a>
                        <a
                          href=""
                          style={{
                            border: "1px solid #888",
                            borderRadius: "50%",
                            marginTop: "-12px",
                            display: "inline-block",
                            padding: 5,
                            padding: "5px 8px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            viewBox="0 0 11 11"
                            fill="none"
                            style={{ marginTop: "4px" }}
                          >
                            <g clipPath="url(#clip0_1785_1089)">
                              <path
                                d="M10.0245 3.11931C9.97325 2.92904 9.87296 2.75556 9.73363 2.61622C9.59429 2.47689 9.42081 2.3766 9.23054 2.32539C8.52982 2.13574 5.71411 2.13574 5.71411 2.13574C5.71411 2.13574 2.89839 2.13574 2.19768 2.32539C2.00741 2.3766 1.83392 2.47689 1.69459 2.61622C1.55526 2.75556 1.45497 2.92904 1.40375 3.11931C1.27291 3.83394 1.20942 4.55926 1.21411 5.28574C1.20942 6.01223 1.27291 6.73755 1.40375 7.45217C1.45497 7.64244 1.55526 7.81593 1.69459 7.95526C1.83392 8.09459 2.00741 8.19488 2.19768 8.2461C2.89839 8.43574 5.71411 8.43574 5.71411 8.43574C5.71411 8.43574 8.52982 8.43574 9.23054 8.2461C9.42081 8.19488 9.59429 8.09459 9.73363 7.95526C9.87296 7.81593 9.97325 7.64244 10.0245 7.45217C10.1553 6.73755 10.2188 6.01223 10.2141 5.28574C10.2188 4.55926 10.1553 3.83394 10.0245 3.11931ZM4.81411 6.63574V3.93574L7.15089 5.28574L4.81411 6.63574Z"
                                fill="#888888"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1785_1089">
                                <rect
                                  width="10.2857"
                                  height="10.2857"
                                  fill="white"
                                  transform="translate(0.571289 0.143066)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                      </p>

                      <table
                        style={{ padding: 20 }}
                        cellPadding={0}
                        cellSpacing={0}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                align="left"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: 12,
                                        color: "#313715",
                                        width: "100%",
                                        padding: 10,
                                      }}
                                    >
                                      <p>
                                        In the interests of your privacy and
                                        cyber security safety we ask that you do
                                        not send your credit card details in any
                                        written correspondence to this office.
                                        Please call us with those details here.
                                      </p>
                                      <p>
                                        You’re receiving this message because
                                        you have provided your email to receive
                                        communications from Eco Hospitality. For
                                        details on our privacy policies, please
                                        visit
                                        <span
                                          style={{
                                            color: "#3968ED",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          here.
                                        </span>
                                      </p>
                                      <p>
                                        If you have questions or comments
                                        regarding this email, please contact us
                                        at
                                        <span
                                          style={{
                                            color: "#3968ED",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          {property_id &&
                                            property_id?.property_email}
                                        </span>
                                      </p>
                                      <p>
                                        ©2023 Eco Hospitality. All rights
                                        reserved.
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table cellSpacing={0} cellPadding={0}>
                <tbody>
                  <tr style={{ width: "200px" }}>
                    <td align="left" style={{ backgroundColor: "#fff" }}>
                      <table
                        style={{
                          width: 800,
                          minWidth: 800,
                          margin: "0 auto",
                          padding: 20,
                        }}
                        cellPadding={0}
                        cellSpacing={0}
                      >
                        <td
                          style={{
                            fontFamily: "Inter",
                            fontSize: "10px",
                            color: "#888",
                            textAlign: "right",
                          }}
                        >
                          Powered by
                          <br />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="81"
                            height="15"
                            viewBox="0 0 81 15"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_1423_2144)">
                              <path
                                d="M28.7683 11.6896C27.9727 11.6896 27.2891 11.516 26.7177 11.1687C26.1518 10.8274 25.6951 10.3248 25.4032 9.72217C25.1009 9.05098 24.9448 8.32045 24.9458 7.58129C24.9468 6.84212 25.1047 6.11203 25.4088 5.44166C25.7032 4.84076 26.1603 4.33938 26.7255 3.99738C27.2947 3.65091 27.9723 3.47729 28.7584 3.47653C29.3707 3.46314 29.9781 3.59223 30.5347 3.85409C31.027 4.08788 31.4474 4.45521 31.7505 4.91626C32.0541 5.39511 32.2234 5.95017 32.2401 6.52089H30.2083C30.1646 6.15953 30.004 5.8236 29.752 5.56675C29.6242 5.44344 29.4736 5.34761 29.309 5.28492C29.1445 5.22222 28.9693 5.19392 28.7939 5.2017C28.4818 5.19487 28.176 5.29183 27.9223 5.47804C27.6589 5.68485 27.4583 5.96409 27.3439 6.2832C27.1957 6.69221 27.1253 7.12647 27.1363 7.56258C27.1252 8.0036 27.1945 8.44287 27.3406 8.85788C27.4535 9.17999 27.6547 9.46191 27.9201 9.66986C28.143 9.82791 28.4028 9.92273 28.6731 9.9447C28.9433 9.96667 29.2145 9.91501 29.4589 9.79495C29.6583 9.68953 29.8274 9.53276 29.9496 9.34006C30.0857 9.12365 30.1729 8.8789 30.205 8.62361H32.2367C32.2136 9.19056 32.0459 9.74143 31.7505 10.2214C31.4581 10.6864 31.0463 11.0598 30.5603 11.3006C30.0008 11.5719 29.3871 11.7051 28.7683 11.6896Z"
                                fill="#3968ED"
                              />
                              <path
                                d="M35.8352 11.6841C35.3803 11.6923 34.9288 11.6016 34.5106 11.418C34.1358 11.2499 33.817 10.9732 33.5936 10.622C33.3589 10.2276 33.243 9.77112 33.2605 9.30962C33.2478 8.92402 33.3295 8.54129 33.4981 8.19628C33.6508 7.90247 33.8734 7.6529 34.1454 7.47073C34.4322 7.28171 34.7472 7.14189 35.078 7.05678C35.4386 6.96118 35.8062 6.89539 36.1771 6.86004C36.629 6.81228 36.9932 6.76565 37.2696 6.72244C37.4811 6.70147 37.686 6.63563 37.8714 6.52911C37.9321 6.48718 37.9812 6.42983 38.0139 6.36267C38.0466 6.2955 38.0618 6.22081 38.0579 6.14587V6.11402C38.0661 5.97228 38.0433 5.83044 37.9913 5.69883C37.9392 5.56722 37.8592 5.44916 37.757 5.35322C37.5572 5.17354 37.2733 5.0837 36.9054 5.0837C36.5777 5.06616 36.2532 5.15793 35.9806 5.34526C35.7612 5.50485 35.6008 5.73567 35.5254 6.0003L33.527 5.83768C33.6193 5.37577 33.8244 4.94538 34.1232 4.58673C34.4353 4.22162 34.8303 3.94081 35.2734 3.76906C35.7957 3.5677 36.3508 3.47006 36.9088 3.48135C37.3282 3.47848 37.7464 3.52968 38.1534 3.63373C38.5307 3.72864 38.8886 3.89152 39.2103 4.11478C39.5159 4.32969 39.7664 4.61677 39.9409 4.95178C40.1275 5.32908 40.2195 5.74806 40.2084 6.17088V11.5352H38.1656V10.4321H38.1056C37.9806 10.6808 37.8107 10.9031 37.6049 11.0871C37.386 11.2817 37.1322 11.4309 36.8577 11.5261C36.5281 11.6377 36.1823 11.6912 35.8352 11.6841ZM36.4525 10.1614C36.7434 10.1677 37.0315 10.1014 37.2918 9.96808C37.5241 9.84779 37.721 9.66648 37.8625 9.44268C38.0017 9.21819 38.0739 8.95691 38.0701 8.69097V7.84715C37.9842 7.90038 37.8915 7.94132 37.7948 7.96884C37.6782 8.00523 37.5472 8.03821 37.4028 8.06778C37.2585 8.09734 37.1131 8.12236 36.9676 8.14511L36.5735 8.20424C36.3447 8.23523 36.1208 8.29639 35.9073 8.3862C35.7333 8.45533 35.5801 8.57026 35.4632 8.7194C35.3556 8.87015 35.3004 9.05358 35.3067 9.24025C35.3006 9.37234 35.3268 9.50388 35.3828 9.62301C35.4388 9.74214 35.523 9.8451 35.6276 9.92259C35.8703 10.091 36.1596 10.1748 36.4525 10.1614Z"
                                fill="#3968ED"
                              />
                              <path
                                d="M45.8148 9.79394L45.5816 9.83829C45.4817 9.85734 45.3802 9.86685 45.2785 9.86673C45.1482 9.86919 45.0187 9.84643 44.8966 9.79963C44.7872 9.75429 44.6968 9.67096 44.6413 9.56423C44.5735 9.41615 44.5423 9.25332 44.5502 9.09V5.23708H46.0113V3.58015H44.5502V1.67529H42.3964V3.58015H41.335V5.23708H42.3964V9.38C42.3761 9.83408 42.4869 10.2842 42.715 10.6742C42.9328 11.0175 43.2505 11.2821 43.6232 11.4304C44.0572 11.6003 44.5217 11.6728 44.9854 11.6431C45.2251 11.6378 45.4638 11.6092 45.6982 11.5578C45.8936 11.5112 46.0446 11.4702 46.1534 11.4361L45.8148 9.79394Z"
                                fill="#3968ED"
                              />
                              <path
                                d="M49.6254 11.6839C49.1704 11.6922 48.719 11.6015 48.3008 11.4177C47.9256 11.2496 47.6065 10.973 47.3827 10.6217C47.1479 10.2274 47.032 9.77083 47.0496 9.30932C47.0369 8.92372 47.1186 8.54099 47.2872 8.19598C47.4391 7.90198 47.6614 7.65233 47.9333 7.47043C48.2202 7.28141 48.5352 7.14159 48.8659 7.05648C49.227 6.96096 49.5949 6.89518 49.9662 6.85974C50.4181 6.81198 50.7811 6.76535 51.0576 6.72214C51.2691 6.70136 51.4741 6.63552 51.6593 6.52881C51.7204 6.48717 51.7699 6.42991 51.8028 6.3627C51.8357 6.29549 51.8509 6.22065 51.847 6.14557V6.11372C51.8548 5.97201 51.832 5.83027 51.7799 5.69872C51.7279 5.56716 51.648 5.44905 51.5461 5.35292C51.3455 5.17324 51.0616 5.0834 50.6945 5.0834C50.3664 5.06594 50.0416 5.15769 49.7686 5.34496C49.5495 5.50487 49.3891 5.73556 49.3134 6L47.3216 5.83396C47.4143 5.37198 47.6198 4.9416 47.9189 4.58302C48.2312 4.21734 48.6266 3.93612 49.0702 3.76422C49.5925 3.56264 50.1476 3.46499 50.7056 3.4765C51.1234 3.47567 51.5397 3.52839 51.9447 3.63343C52.3216 3.72855 52.6791 3.89143 53.0005 4.11448C53.3063 4.32908 53.557 4.61624 53.731 4.95148C53.9182 5.32858 54.0102 5.7477 53.9986 6.17058V11.5349H51.9569V10.4318H51.8958C51.7715 10.681 51.6015 10.9034 51.3951 11.0868C51.1747 11.2825 50.9189 11.432 50.6423 11.5269C50.3144 11.6378 49.9706 11.6908 49.6254 11.6839ZM50.2415 10.1611C50.5325 10.1677 50.8207 10.1013 51.0809 9.96778C51.3135 9.84752 51.5108 9.66622 51.6527 9.44238C51.7915 9.21777 51.8633 8.9565 51.8592 8.69067V7.84685C51.7734 7.9005 51.6807 7.94147 51.5838 7.96854C51.4728 8.00493 51.3374 8.03791 51.1919 8.06748C51.0465 8.09704 50.9021 8.12206 50.7578 8.14481L50.3637 8.20394C50.1349 8.23503 49.911 8.29618 49.6975 8.3859C49.5234 8.45488 49.3702 8.56984 49.2534 8.7191C49.1458 8.86985 49.0906 9.05328 49.0969 9.23995C49.0902 9.37212 49.1161 9.50389 49.1722 9.62312C49.2283 9.74236 49.3127 9.84523 49.4177 9.92229C49.6604 10.0908 49.9497 10.1746 50.2427 10.1611H50.2415Z"
                                fill="#3968ED"
                              />
                              <path
                                d="M57.8257 0.929199V11.5338H55.6719V0.929199H57.8257Z"
                                fill="#3968ED"
                              />
                              <path
                                d="M60.8745 14.5168C60.6178 14.5182 60.3614 14.4965 60.1084 14.4519C59.907 14.4187 59.7094 14.3642 59.5189 14.2893L60.0052 12.6426C60.2267 12.7156 60.4566 12.7588 60.6891 12.7711C60.8769 12.7827 61.0634 12.7321 61.2209 12.6267C61.3924 12.4929 61.5202 12.3089 61.5873 12.099L61.7138 11.7578L58.9282 3.56982H61.1931L62.8008 9.41061H62.8807L64.5028 3.58006H66.7832L63.7656 12.3901C63.6329 12.7954 63.4339 13.1746 63.1771 13.5114C62.9276 13.8324 62.6056 14.0865 62.239 14.2518C61.8075 14.4379 61.3425 14.5282 60.8745 14.5168Z"
                                fill="#3968ED"
                              />
                              <path
                                d="M74.3472 5.84778L72.3754 5.97288C72.3401 5.80037 72.2638 5.63943 72.1533 5.50434C72.0355 5.35724 71.8857 5.2404 71.7159 5.16317C71.5124 5.07327 71.2923 5.02981 71.0708 5.03581C70.7755 5.02581 70.4834 5.10023 70.227 5.25074C70.1248 5.3047 70.0387 5.3861 69.9781 5.48625C69.9175 5.58639 69.8846 5.70153 69.8829 5.81935C69.8821 5.91165 69.9017 6.00293 69.9402 6.08641C69.9786 6.16989 70.0349 6.24343 70.1049 6.30154C70.253 6.43194 70.5072 6.53732 70.8677 6.61768L72.2743 6.91564C73.0293 7.07333 73.5922 7.32845 73.963 7.68099C74.1473 7.85816 74.2923 8.07384 74.388 8.31341C74.4838 8.55299 74.5282 8.81086 74.5181 9.06954C74.5273 9.56524 74.3706 10.0492 74.074 10.441C73.7528 10.8522 73.3316 11.1695 72.8528 11.3611C72.2837 11.5895 71.6764 11.7012 71.0653 11.6897C70.0372 11.6897 69.2193 11.4695 68.6116 11.029C68.3186 10.8244 68.0716 10.5581 67.8872 10.2478C67.7028 9.93752 67.5853 9.59048 67.5425 9.22989L69.6597 9.11617C69.6848 9.26415 69.7389 9.40537 69.8189 9.53127C69.8989 9.65716 70.003 9.76511 70.1249 9.84854C70.4088 10.0089 70.7268 10.0951 71.051 10.0997C71.3751 10.1043 71.6954 10.027 71.9835 9.8747C72.0868 9.81787 72.1737 9.7341 72.2353 9.63186C72.2969 9.52962 72.331 9.41254 72.3343 9.29244C72.3338 9.19586 72.3112 9.10076 72.2682 9.01482C72.2251 8.92888 72.163 8.85451 72.0867 8.79774C71.8623 8.64215 71.6065 8.54031 71.3384 8.49979L69.9939 8.22572C69.2352 8.0703 68.6716 7.80078 68.303 7.41716C68.1185 7.22418 67.9742 6.99486 67.8788 6.74309C67.7834 6.49131 67.7388 6.22233 67.7479 5.95241C67.736 5.47805 67.8794 5.01327 68.1553 4.63209C68.4524 4.24265 68.8501 3.94636 69.3033 3.7769C69.8585 3.56567 70.4476 3.46381 71.0397 3.47667C72.0205 3.47667 72.7928 3.68895 73.3568 4.11351C73.6315 4.31518 73.8617 4.57382 74.0323 4.87263C74.2029 5.17143 74.3102 5.50369 74.3472 5.84778Z"
                                fill="#3968ED"
                              />
                              <path
                                d="M79.8161 9.79394L79.5841 9.83829C79.4841 9.85734 79.3827 9.86685 79.281 9.86673C79.1507 9.8693 79.0211 9.84653 78.8991 9.79963C78.7894 9.75454 78.699 9.67115 78.6437 9.56423C78.5755 9.41632 78.5442 9.25336 78.5527 9.09V5.23708H80.0137V3.58015H78.5527V1.67529H76.3988V3.58015H75.3374V5.23708H76.3988V9.38C76.3779 9.83385 76.4879 10.284 76.7152 10.6742C76.9326 11.0175 77.25 11.282 77.6223 11.4304C78.0563 11.6001 78.5208 11.6726 78.9845 11.6431C79.2242 11.6378 79.4629 11.6092 79.6973 11.5578C79.8938 11.5112 80.0448 11.4702 80.1525 11.4361L79.8161 9.79394Z"
                                fill="#3968ED"
                              />
                              <path
                                d="M20.2306 1.81388V13.1861C20.2315 13.4246 20.1865 13.6608 20.0981 13.8814C20.0096 14.102 19.8796 14.3025 19.7154 14.4715C19.5513 14.6406 19.3561 14.7747 19.1412 14.8664C18.9263 14.9581 18.6959 15.0054 18.4631 15.0057H1.5519C1.13966 15.0057 0.74431 14.8379 0.452815 14.5394C0.16132 14.2408 -0.00244141 13.8358 -0.00244141 13.4136V1.59212C-0.00244141 1.16986 0.16132 0.764902 0.452815 0.466322C0.74431 0.167742 1.13966 0 1.5519 0H18.4609C18.6934 -4.6864e-08 18.9236 0.0469264 19.1384 0.138096C19.3532 0.229266 19.5483 0.362894 19.7127 0.531343C19.877 0.699791 20.0074 0.899758 20.0962 1.11982C20.1851 1.33988 20.2308 1.57573 20.2306 1.81388Z"
                                fill="#3968ED"
                              />
                              <path
                                d="M15.6522 0H1.55434C0.695902 0 0 0.712814 0 1.59212V13.4079C0 14.2872 0.695902 15 1.55434 15H15.6522C16.5107 15 17.2066 14.2872 17.2066 13.4079V1.59212C17.2066 0.712814 16.5107 0 15.6522 0Z"
                                fill="#5780EF"
                              />
                              <path
                                d="M12.6279 0H1.55434C0.695902 0 0 0.712814 0 1.59212V13.4079C0 14.2872 0.695902 15 1.55434 15H12.6279C13.4863 15 14.1823 14.2872 14.1823 13.4079V1.59212C14.1823 0.712814 13.4863 0 12.6279 0Z"
                                fill="#7C9CF3"
                              />
                              <path
                                d="M24.1578 3.57861L21.4443 11.5324H20.2319V9.27043H20.2719L21.8795 3.57861H24.1578Z"
                                fill="#3968ED"
                              />
                              <path
                                d="M7.20748 3.46608C6.79278 3.45911 6.38752 3.59308 6.05504 3.84705C5.70237 4.12879 5.45154 4.52298 5.34227 4.96721H5.26122V3.5798H3.17285V11.5336H5.32672V7.03356C5.32113 6.73228 5.39558 6.43511 5.54211 6.17382C5.68223 5.92877 5.88489 5.72747 6.12832 5.59155C6.38852 5.44861 6.68022 5.37656 6.97544 5.3823C7.13926 5.3831 7.30283 5.39526 7.46505 5.4187C7.60675 5.43515 7.74704 5.46252 7.88472 5.50057V3.54341C7.7751 3.51665 7.66382 3.49765 7.55165 3.48655C7.43735 3.47357 7.32247 3.46674 7.20748 3.46608Z"
                                fill="white"
                              />
                              <path
                                d="M14.6773 4.48089C14.3467 4.14743 13.9498 3.89068 13.5138 3.72804C13.0548 3.55776 12.5698 3.47265 12.0816 3.47672C11.3847 3.45953 10.6965 3.63851 10.092 3.99416C9.52869 4.33754 9.07326 4.83916 8.77973 5.43957C8.45833 6.10895 8.29878 6.84769 8.31454 7.59347C8.31454 8.43275 8.4696 9.15867 8.77973 9.77126C9.07545 10.3679 9.53713 10.8614 10.1065 11.1894C10.6816 11.5222 11.3684 11.689 12.1671 11.6898C12.7464 11.701 13.3223 11.5979 13.8635 11.3861C14.3244 11.2051 14.7366 10.9143 15.0659 10.5378C15.3779 10.1731 15.5915 9.7313 15.6854 9.2561L13.6937 9.12077C13.6246 9.31289 13.5142 9.48663 13.3706 9.6291C13.2244 9.76933 13.0501 9.87527 12.8599 9.93957C12.6478 10.0108 12.4258 10.0458 12.2026 10.0431C11.876 10.0514 11.5531 9.97094 11.2667 9.80993C11.0049 9.65473 10.7933 9.42419 10.6583 9.14692C10.5076 8.82831 10.4337 8.47732 10.4429 8.12342H15.731V7.51841C15.7424 6.91484 15.6484 6.31404 15.4534 5.74434C15.2882 5.27004 15.0233 4.83871 14.6773 4.48089ZM10.4484 6.75534C10.4575 6.48063 10.5301 6.21205 10.6605 5.97179C10.7995 5.71629 11.0026 5.50349 11.2489 5.35542C11.5123 5.19997 11.8121 5.12131 12.116 5.12797C12.4049 5.12191 12.6902 5.19409 12.9431 5.33722C13.1762 5.47433 13.3681 5.67411 13.4983 5.91493C13.636 6.17347 13.7056 6.46428 13.7003 6.75875L10.4484 6.75534Z"
                                fill="white"
                              />
                              <path
                                d="M20.2319 9.27043V11.5324H19.0184L16.3027 3.57861H18.5776L20.1886 9.27043H20.2319Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1423_2144">
                                <rect
                                  width="80.1562"
                                  height="15"
                                  fill="white"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </td>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default EventCommunicationVoucher;
