import {
  Button,
  ChoiceList,
  Collapsible,
  Icon,
  IndexTable,
  LegacyCard,
  LegacyStack,
  Page,
  Tooltip,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useRef, useState, useEffect } from "react";
import { websites_inner_lists } from "../../assets/constant";
import { useNavigate, Link } from "react-router-dom";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import { useSelector } from "react-redux";
import { DeleteMajor } from "@shopify/polaris-icons";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "../UI/Buttons";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import NoDataFound from "../CustomComponents/NoDataFound";
import Loader from "../UI/Loader/Loader";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";

function formatDate(created_at) {
  const date = new Date(created_at);
  const options = {
    year: "2-digit", // 2-digit year (e.g., 23 for 2023)
    month: "short", // Short month name (e.g., Jul)
    day: "numeric", // Numeric day (e.g., 12)
    hour: "numeric", // Numeric hour (e.g., 4)
    minute: "2-digit", // 2-digit minute (e.g., 30)
    hour12: true, // Use 12-hour clock format
  };

  return date.toLocaleString("en-US", options);
}

const WebsitesBlogComp = () => {
  const navigate = useNavigate();
  const property_id = useSelector((state) => state.property.value);
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [loader, setLoader] = useState(true);
  const [pages, setPages] = useState([]);
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const fetchInfo = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/posts/${propertyId}?type=post`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setPages(data.posts.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const resourceName = {
    singular: "page",
    plural: "pages",
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
    }
  }, [property_id]);

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(pages);

  useEffect(() => {
    if (selectedResources.length === 1) {
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    // clearSelection();
    const response = await fetch(
      // /api/v1/delete/post/16
      `${process.env.REACT_APP_BASE_URL}/api/v1/delete/post/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          // clearSelection();
          fetchInfo(property_id?.id);
          // clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const rowMarkup = pages?.map(({ id, title, slug, created_at, content }) => {
    return (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={id}
      >
        <IndexTable.Cell>
          <CustomCssComponentTableText innerText={title}>
            <div style={{ color: "#888", fontSize: "12px" }}>
              {formatDate(created_at)}
            </div>
          </CustomCssComponentTableText>
        </IndexTable.Cell>
        {/* <IndexTable.Cell>
          <CheckWithoutBgUnderlineBtn children={"View Page"} />
        </IndexTable.Cell> */}
      </IndexTable.Row>
    );
  });

  return (
    <>
      <SidebarInnerLists innerLists={websites_inner_lists} />

      <div className="room-types-full-container">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Blog Posts</div>
          <div>
            {/* {permissions && permissions.includes("create_website_posts") ? ( */}
            <BgThemeButton
              onClick={() => navigate("/websites/addblog")}
              children={"Add Posts"}
            />
            {/* ) : (
              <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                <BgThemeButtonDisabled
                  // onClick={() => navigate("/websites/addblog")}
                  children={"Add Posts"}
                />
              </Tooltip>
            )} */}
          </div>
        </div>

        {loader ? (
          <Loader />
        ) : (
          <>
            {pages && pages.length > 0 ? (
              <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={pages.length}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: `Showing ${pages.length} Posts` },
                    // { title: "" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>

                {/* Single select */}
                {showLowerMenuSingleSelect && (
                  <div className="room-type-bottom-container">
                    <div className="room-type-bottom-container-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          gap: "20px",
                        }}
                      >
                        {/* {permissions &&
                        permissions.includes("delete_website_posts") ? ( */}
                        <Button
                          plain
                          destructive
                          // onClick={showModelMenu}
                          onClick={() => showModelMenu()}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <Icon source={DeleteMajor} color="base" />
                            </span>
                            <span>Remove</span>
                          </div>
                        </Button>
                        {/* ) : (
                          <Tooltip
                            content="Please Contact Your Admin"
                            dismissOnMouseOut
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: "0.7",
                                cursor: "no-drop",
                              }}
                            >
                              <span>
                                <Icon source={DeleteMajor} color="base" />
                              </span>
                              <span>Remove</span>
                            </div>
                          </Tooltip>
                        )} */}

                        {permissions &&
                        permissions.includes("delete_website_posts") ? (
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/websites/blogs/editblog/${selectedResources[0]}`}
                          >
                            <BgOutlineThemeButton children={"Edit"} />
                          </Link>
                        ) : (
                          <Tooltip
                            content="Please Contact Your Admin"
                            dismissOnMouseOut
                          >
                            <BgOutlineThemeButton
                              style={{ opacity: "0.8", cursor: "no-drop" }}
                              children={"Edit"}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Multiple select */}
                {showLowerMenuMultipleSelect && (
                  <div className="room-type-bottom-container">
                    <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {permissions &&
                        permissions.includes("delete_website_posts") ? (
                          <Button
                            plain
                            destructive
                            // onClick={showModelMenu}
                            onClick={() => showModelMenu()}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                <Icon source={DeleteMajor} color="base" />
                              </span>
                              <span>Remove</span>
                            </div>
                          </Button>
                        ) : (
                          <Tooltip
                            content="Please Contact Your Admin"
                            dismissOnMouseOut
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: "0.4",
                                cursor: "no-drop",
                              }}
                            >
                              <span>
                                <Icon source={DeleteMajor} color="base" />
                              </span>
                              <span>Remove</span>
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {showModelMenuActive && (
                  <>
                    <div className="bg-backdrop">
                      <section className="room-type-model-container ">
                        <h4>Are You Sure?</h4>
                        <p>You Want To Remove?</p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Button
                            onClick={() =>
                              setShowModelMenuActive(!showModelMenuActive)
                            }
                          >
                            Discard
                          </Button>
                          <Button onClick={deleteData} destructive>
                            Yes, Remove
                          </Button>
                        </div>
                      </section>
                    </div>
                  </>
                )}
              </LegacyCard>
            ) : (
              <NoDataFound />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default WebsitesBlogComp;
