import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { formatIndianCurrency } from "../../utils/utils";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import { CustomReportTable, CustomReportTableCell, CustomReportTableRow } from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";

const ReportPaymentFoodSaleReport = ({ departureReport, loader, fullData }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>{order.kot_date}</CustomReportTableCell>
        <CustomReportTableCell>{`KOT-` + order.kot_id}</CustomReportTableCell>

        <CustomReportTableCell>
          {" "}
          {order.kot_gst_rate ? order.kot_gst_rate + "%" : "0"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.kot_discount_rate ? order.kot_discount_rate + "%" : "0"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.kot_paid_amount
            ? formatIndianCurrency(order.kot_paid_amount)
            : "0"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.kot_balance_amount
            ? formatIndianCurrency(order.kot_balance_amount)
            : "0"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.kot_status ? (
            <UnderscoreRemoveCapitalize
              text={
                order.kot_status === "parsely_paid"
                  ? "Partially Paid"
                  : order.kot_status
              }
            />
          ) : (
            "N/A"
          )}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));


  // const rowMarkup = departureReport?.map(
  //   ({
  //     id,
  //     bill_created,
  //     booking_id,
  //     bill_no,
  //     booked_on,
  //     check_in,
  //     check_out,
  //     booking_source,
  //     status,
  //   }) => (
  //     <CustomReportTableRow
  //       CustomReportTableRow
  //       id={id}
  //       key={id}
  //       // bodyStyles={{
  //       //   backgroundColor: "red",
  //       //   fontSize: "10px",
  //       // }}
  //     >
  //       <CustomReportTableCell>{item_name}</CustomReportTableCell>
  //       <CustomReportTableCell>
  //         {item_category ? item_category : "N/A"}
  //       </CustomReportTableCell>
  //       <CustomReportTableCell>
  //         {unit_name ? unit_name : "N/A"}
  //       </CustomReportTableCell>
  //       <CustomReportTableCell>{purchased_quantity}</CustomReportTableCell>
  //       <CustomReportTableCell>
  //         {formatIndianCurrency(total_amount)}
  //       </CustomReportTableCell>
  //       <CustomReportTableCell>
  //         {issued_quantity ? issued_quantity : 0}
  //       </CustomReportTableCell>
  //       <CustomReportTableCell>
  //         {formatIndianCurrency(issued_amount)}
  //       </CustomReportTableCell>
  //       <CustomReportTableCell>
  //         {remaining_quantity ? remaining_quantity : 0}
  //       </CustomReportTableCell>
  //       <CustomReportTableCell>
  //         {formatIndianCurrency(remaining_amount)}
  //       </CustomReportTableCell>
  //     </CustomReportTableRow>
  //   )
  // );

  const extractedData = departureReport?.map(item => ({
    kot_date: item && item?.kot_date ? item?.kot_date : "N/A",
    kot_id: item && item?.kot_id ? item?.kot_id : "N/A",
    kot_gst_rate: item && item?.kot_gst_rate ? item?.kot_gst_rate : "N/A",
    kot_discount_rate: item && item?.kot_discount_rate ? item?.kot_discount_rate : "N/A",
    kot_balance_amount: item && item?.kot_balance_amount ? item?.kot_balance_amount : "N/A",
    kot_paid_amount: item && item?.kot_paid_amount ? item?.kot_paid_amount : "N/A",
    kot_status: item && item?.kot_status ? item?.kot_status : "N/A",
  }));
  console.log(extractedData);
  const names = [
    {
      kot_date: "Date",
      kot_id: "KOT ID	",
      kot_gst_rate: "Gst Rate(%)	",
      kot_discount_rate: "Discount Rate (%)	",
      kot_balance_amount: "Balance Amount	",
      kot_paid_amount: "Paid Amount	",
      kot_status: "Status",
    },
  ];
  console.log(departureReport);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container reports-new-background">
          <div></div>

          <div
            className="room-types-full-container-div"
            style={{ display: "block" }}
            id="table"
          >
            <div className="room-type-heading">
              Food Sales Report
              <div id="ignoreItem" className="flex flex_gap_15 align_center" style={{ float: "right" }}>
                <ButtonToDownloadTablePDF
                  itemToDownloadID={"table"}
                  downloadFileName={"Food Sales Report"}
                  ignoreFromPrint={"ignoreItem"}
                  pdfOrientation={"portrait"}
                  pdfMargins={[0.1, 0.1, 0.1, 0.1]}
                />

                <DownloadCSVButton itemToDownloadID={"table"}
                  downloadFileName={"Food Sales Report"} />
              </div>
            </div>
            <div
              style={{ display: "flex", gap: "30px", margin: "10px 0 20px 0" }}
            >
              <div>
                <div>
                  {" "}
                  <strong>Subtotal</strong> :{" "}
                  {formatIndianCurrency(fullData?.sale_data?.SubTotal)}
                </div>
              </div>
              <div>
                <div>
                  {" "}
                  <strong>CGST</strong> :{" "}
                  {formatIndianCurrency(fullData?.sale_data?.CGST)}
                </div>
              </div>
              <div>
                <div>
                  {" "}
                  <strong>SGST</strong> :{" "}
                  {formatIndianCurrency(fullData?.sale_data?.SGST)}
                </div>
              </div>
              <div>
                <div>
                  {" "}
                  <strong>Total Tax Amount</strong> :{" "}
                  {formatIndianCurrency(fullData?.sale_data?.TotalTaxAmount)}
                </div>
              </div>
              <div>
                <div>
                  {" "}
                  <strong>Paid Amount</strong> :{" "}
                  {formatIndianCurrency(fullData?.sale_data?.PaidAmount)}
                </div>
              </div>
              <div>
                <div>
                  {" "}
                  <strong>Balance Amount</strong> :{" "}
                  {formatIndianCurrency(fullData?.sale_data?.BalanceAmount)}
                </div>
              </div>
              <div>
                <div>
                  {" "}
                  <strong>Net Total</strong> :{" "}
                  {formatIndianCurrency(fullData?.sale_data?.NetTotal)}
                </div>
              </div>
            </div>
            {departureReport && departureReport?.length > 0 ? (
              <>
                {/* <LegacyCard>
                  <IndexTable
                    resourceName={resourceName}
                    itemCount={departureReport?.length}
                    selectable={false}
                    headings={[
                      { title: "Date" },
                      { title: "KOT ID" },
                      { title: "Gst Rate(%)" },
                      { title: "Discount Rate (%)" },
                      { title: "Balance Amount" },
                      { title: "Paid Amount" },
                      { title: "Status" },
                    ]}
                  >
                    {rowMarkup}
                  </IndexTable>
                </LegacyCard> */}
                <CustomReportTable
                  headings={[
                    "Date",
                    "KOT ID",
                    "Gst Rate(%)",
                    "Discount Rate (%)",
                    "Paid Amount",
                    "Balance Amount",
                    "Status",
                  ]}
                  // commonHeadings={[
                  //   "",
                  //   "",
                  //   "",
                  //   "Purchased",
                  //   "Purchased",
                  //   "Issued",
                  //   "Issued",
                  //   "Stock",
                  //   "Stock",
                  // ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {/* {calculateTotals} */}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ReportPaymentFoodSaleReport;
