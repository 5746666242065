import {
    IndexTable,
    LegacyCard,
    useIndexResourceState,
    Button,
    Icon,
    Page,
} from "@shopify/polaris";

import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import VerticalInputFieldCustom from "../../CustomComponents/VerticalInputFieldCustom";
import { BgThemeButton } from "../../UI/Buttons";
import NoDataFound from "../../CustomComponents/NoDataFound";
import LoaderSmall from "../../UI/Loader/LoaderSmall";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { useSelector } from "react-redux";
import GoBackButtonCustom from "../../GoBackButtonCustom/GoBackButtonCustom";
import HandleNaN from "../../CustomComponents/HandleNaN/HandleNaN";

const StoreIssuedItemsPage = () => {
    const userToken = JSON.parse(localStorage.getItem("userToken"));
    const property_id = useSelector((state) => state.property.value);
    const [loader, setLoader] = useState(false);
    const [orders, setOrders] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [items, setItems] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState("");

    const [valueArray, setValueArray] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const accordRef = useRef(null);
    const [dayTypeFilter, setDayTypeFilter] = useState("-1");
    const [openAccordion, setOpenAccordion] = useState("first");
    const [issuedItemAmount, setIssuedItemAmount] = useState([]);

    const dateOptions = [
        { id: 0, value: "-1", label: "Today" },
        { id: 1, value: "-7", label: "Past 7 Days" },
        { id: 2, value: "-30", label: "Past 30 Days" },
        { id: 3, value: "-90", label: "Past 90 Days" },
        { id: 4, value: "+7", label: "Future 7 Days" },
        { id: 5, value: "+30", label: "Future 30 Days" },
        { id: 6, value: "+90", label: "Future 90 Days" },
    ];

    const toggleAccordion = (accordion) => {
        if (openAccordion === accordion) {
            setOpenAccordion(null); // Close the currently open accordion
        } else {
            setOpenAccordion(accordion); // Open the clicked accordion
        }
    };

    const handleAccordClick = (id, label, value) => {
        setValueArray([{ id: id, label: label, value: value }]);
        setDayTypeFilter(value);
        setIsOpen(!isOpen);
    };

    const toggleAccord = () => {
        setIsOpen(!isOpen);
    };

    const navigate = useNavigate();

    const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
        useState(false);

    const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
        useState(false);

    const [showModelMenuActive, setShowModelMenuActive] = useState(false);

    const [showAddStockModelMenuActive, setShowAddStockModelMenuActive] =
        useState(false);

    const [showAddItemModelMenuActive, setShowAddItemModelMenuActive] =
        useState(false);

    const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

    const [issues, setIssues] = useState({
        issue_to: "",
        issue_by: "",
        issue_date: "",
        details: [],
    });

    const [itemDetails, setItemDetails] = useState([
        {
            purchased_item_id: "",
            item_price: "",
            quantity: "",
            max_qty: "",
        },
    ]);

    const [errors, setErrors] = useState(Array(itemDetails.length).fill(""));
    const [hasQuantityError, setHasQuantityError] = useState(false);

    const handleAddStockChange = (e) => {
        const { name, value } = e.target;
        setIssues((prevState) => ({ ...prevState, [name]: value }));
    };

    useEffect(() => {
        setIssues((prevValue) => ({
            ...prevValue,
            details: itemDetails,
        }));
    }, [itemDetails]);

    const handleChangePurchaseItem = (event, index) => {
        const selectedId = parseInt(event.target.value);
        const selectedItem = purchases?.find((item) => item.id === selectedId);

        if (selectedItem) {
            const updatedItemDetails = [...itemDetails];

            updatedItemDetails[index] = {
                ...updatedItemDetails[index],
                purchased_item_id: selectedId,
                item_price: selectedItem.item_price,
                max_qty: selectedItem.item_qty,
            };

            setItemDetails(updatedItemDetails);
        } else {
            const updatedItemDetails = [...itemDetails];
            updatedItemDetails[index] = {
                ...updatedItemDetails[index],
                purchased_item_id: "",
                max_qty: "",
            };
            setItemDetails(updatedItemDetails);
        }
    };

    const handleTableInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedItemDetails = [...itemDetails];
        updatedItemDetails[index][name] = value;

        setItemDetails(updatedItemDetails);
    };

    const handleQuantityChange = (event, index) => {
        const newItems = [...itemDetails];
        const inputValue = event.target.value;
        const maxQty = newItems[index].max_qty;

        if (parseInt(inputValue) > maxQty) {
            const newErrors = [...errors];
            setHasQuantityError(true)
            newErrors[index] = "Quantity cannot exceed the maximum.";
            setErrors(newErrors);
        } else {
            const newErrors = [...errors];
            newErrors[index] = ""; // Clear the error message if the quantity is within the limit.
            setErrors(newErrors);
            setHasQuantityError(false)
        }

        newItems[index].quantity = inputValue;
        setItemDetails(newItems);
    };

    const handleRemoveStock = (index) => {
        const updatedItemDetails = [...itemDetails];
        updatedItemDetails.splice(index, 1);
        setItemDetails(updatedItemDetails);
    };

    const handleAddStock = () => {
        setItemDetails([
            ...itemDetails,
            {
                purchased_item_id: "",
                item_price: "",
                quantity: "",
                max_qty: "",
            },
        ]);
    };

    const fetchInfo = async (id) => {
        try {
            setLoader(true);
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/issues/${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();
            if (data.message === "Unauthorised.") {
                navigate("/");
            }
            setOrders(data.Item);
            setIssuedItemAmount(data.total_data)
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.error(error);
        }
    };

    // const fetchStaff = async (id) => {
    //     try {
    //         setLoader(true);
    //         const response = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/api/v1/account/get-user/${id}`,
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Authorization: `Bearer ${userToken}`,
    //                 },
    //             }
    //         );
    //         const data = await response.json();
    //         if (data.message === "Unauthorised.") {
    //             navigate("/");
    //         }
    //         setStaffs(data.data);
    //         setLoader(false);
    //     } catch (error) {
    //         setLoader(false);
    //         console.error(error);
    //     }
    // };

    // const fetchItems = async (id) => {
    //     try {
    //         setLoader(true);
    //         const response = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/api/v1/items/${id}`,
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Authorization: `Bearer ${userToken}`,
    //                 },
    //             }
    //         );
    //         const data = await response.json();
    //         if (data.message === "Unauthorised.") {
    //             navigate("/");
    //         }
    //         setItems(data.Item);
    //         setLoader(false);
    //     } catch (error) {
    //         setLoader(false);
    //         console.error(error);
    //     }
    // };

    // const fetchPurchases = async (id) => {
    //     try {
    //         setLoader(true);
    //         const response = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/api/v1/purchased/items/${id}`,
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Authorization: `Bearer ${userToken}`,
    //                 },
    //             }
    //         );
    //         const data = await response.json();
    //         if (data.message === "Unauthorised.") {
    //             navigate("/");
    //         }
    //         setPurchases(data.purchase_Items);
    //         setLoader(false);
    //     } catch (error) {
    //         setLoader(false);
    //         console.error(error);
    //     }
    // };

    const resourceName = {
        singular: "order",
        plural: "orders",
    };

    const {
        selectedResources,
        allResourcesSelected,
        handleSelectionChange,
        clearSelection,
    } = useIndexResourceState(orders);

    useEffect(() => {
        if (selectedResources.length === 1) {
            getOneData(selectedResources[0]);
            setShowLowerMenuSingleSelect(true);
            setShowLowerMenuMultipleSelect(false);
        } else if (selectedResources.length > 1) {
            setShowLowerMenuMultipleSelect(true);
            setShowLowerMenuSingleSelect(false);
        } else {
            setShowLowerMenuSingleSelect(false);
            setShowLowerMenuMultipleSelect(false);
        }
    }, [selectedResources]);

    const rowMarkup = orders?.map(
        ({
            id,
            category_id,
            purchased_item_name,
            item_name,
            issue_to,
            quantity,
            issue_by,
            index,
        }) => (
            <IndexTable.Row
                id={id}
                key={index}
                selected={selectedResources.includes(id)}
                position={index}
            >
                <IndexTable.Cell>{item_name}</IndexTable.Cell>
                <IndexTable.Cell>{issue_to}</IndexTable.Cell>
                <IndexTable.Cell>{issue_by}</IndexTable.Cell>
                <IndexTable.Cell>{quantity}</IndexTable.Cell>
            </IndexTable.Row>
        )
    );

    const showModelMenu = () => {
        setShowModelMenuActive(!showModelMenuActive);
    };

    const handleSubmitAddIssueItem = async (e) => {
        e.preventDefault();
        if (hasQuantityError) {
            showErrorToast("Please correct quantity errors before submitting.");
            return;
        }

        try {
            setLoader(true);
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/create/issues/${property_id?.id}`,
                {
                    method: "POST",
                    body: JSON.stringify({ ...issues }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();
            if (data.success) {
                fetchInfo(property_id?.id);
                clearSelection();
                showSuccessToast(data.message);
                setIssues({
                    issue_to: "",
                    issue_by: "",
                    issue_date: "",
                    details: [],
                });
                setItemDetails([
                    {
                        purchased_item_id: "",
                        item_price: "",
                        quantity: "",
                        max_qty: "",
                    },
                ]);
                setShowAddItemModelMenuActive(false);
                setLoader(false)
                // window.location.reload()
            }
            else if (data.error) {
                const firstErrorKey = Object.keys(data.error)[0];
                const firstErrorMessage = data.error[firstErrorKey][0];
                showErrorToast(firstErrorMessage);
                fetchInfo(property_id?.id);
                setLoader(false)
            } else {
                showErrorToast(data.message);
                setLoader(false)
            }
        } catch (error) {
            console.error("Error:", error);
            showErrorToast("An error occurred while processing your request.");
            setLoader(false)
        }
    };

    useEffect(() => {
        if (property_id) {
            fetchInfo(property_id?.id, dayTypeFilter);
            // fetchStaff(property_id?.id);
            // fetchItems(property_id?.id);
            // fetchPurchases(property_id?.id);
        }
    }, [property_id, dayTypeFilter]);

    const getOneData = async (id) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/edit/purchased/${id}/${property_id?.id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();

        } catch (error) {
            console.error(error);
        }
    };

    const deletedIds = {
        ids: selectedResources,
    };

    const removeItemConfirm = async () => {
        try {
            setLoader(true);
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/issues/delete/${property_id?.id}`,
                {
                    method: "DELETE",
                    body: JSON.stringify(deletedIds),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();
            if (data.success) {
                fetchInfo(property_id?.id);
                showSuccessToast(data.message);
                clearSelection();
                setShowModelMenuActive(false);
            } else {
                showErrorToast(data.message);
            }
        } catch (error) {
            console.error("Error:", error);
            showErrorToast("An error occurred while processing your request.");
        }
    };

    const handleUpdateStock = async (id) => {
        try {
            setLoader(true);
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/update/purchased/${id}/${property_id?.id}`,
                {
                    method: "POST",
                    body: JSON.stringify({ ...issues }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();
            if (data.success) {
                fetchInfo(property_id?.id);
                clearSelection();
                showSuccessToast(data.message);
                setIssues({
                    category_id: "",
                    puchased_item_id: "",
                    issue_to: "",
                    issue_by: "",
                    // quantity: "",
                });
                setShowEditModelMenuActive(false);
                // window.location.reload()
            } else {
                showErrorToast(data.message);
            }
        } catch (error) {
            console.error("Error:", error);
            showErrorToast("An error occurred while processing your request.");
        }
    };

    return (
        <div className="main_container">
            {loader ? (
                <LoaderSmall />
            ) : (
                <>
                    <div className="room-types-full-container">
                        <div className="room-types-full-container-div">
                            <div>
                                <GoBackButtonCustom
                                    buttonNextText={"Issued Items"}
                                    onClick={() => navigate("/dashboard")}
                                />
                            </div>
                        </div>

                        <div className="booking-table-full-container">
                            <div className="booking-first-row-head">
                                <div
                                    style={{
                                        width: "100%",
                                        backgroundColor: "#EFF0F2",
                                    }}
                                >
                                    <div className="reservation_bar_container">
                                        <div
                                            style={{ position: "relative", paddingRight: "14px" }}
                                            className="reservation_dateRange toggleAccord"
                                        >
                                            <div
                                                ref={accordRef}
                                                onClick={toggleAccord}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    width: "fit-content",
                                                    gap: "10px",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                >
                                                    <g clipPath="url(#clip0_1_5515)">
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M13.625 2H11.75V1.25C11.75 0.835786 11.4142 0.5 11 0.5C10.5858 0.5 10.25 0.835786 10.25 1.25V2H5V1.25C5 0.835786 4.66421 0.5 4.25 0.5C3.83579 0.5 3.5 0.835786 3.5 1.25V2H2.375C1.775 2 1.25 2.525 1.25 3.125V14.375C1.25 14.975 1.775 15.5 2.375 15.5H13.625C14.225 15.5 14.75 14.975 14.75 14.375V3.125C14.75 2.525 14.225 2 13.625 2ZM2.75 14H13.25V6.5H2.75V14Z"
                                                            fill="#333333"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_5515">
                                                            <rect
                                                                width="15"
                                                                height="15"
                                                                fill="white"
                                                                transform="translate(0.5 0.5)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p className="reservation_total_paragraph thisMonth">
                                                    {valueArray.length > 0
                                                        ? valueArray[0].label
                                                        : "Today"}
                                                </p>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="10"
                                                    height="6"
                                                    viewBox="0 0 10 6"
                                                    fill="none"
                                                    className={`${isOpen ? "accordImageRotate" : null
                                                        } `}
                                                >
                                                    <path d="M1 1L5 5L9 1" stroke="#333333" />
                                                </svg>
                                            </div>
                                            {isOpen && (
                                                <>
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            border: "1px #3333332d solid",
                                                            left: "0",
                                                            width: "100%",
                                                            height: "fit-content",
                                                            zIndex: 999,
                                                        }}
                                                        className="accord_container"
                                                    >
                                                        {dateOptions.map((qwer) => (
                                                            <div
                                                                key={qwer.id}
                                                                className="accord_container_values"
                                                                onClick={() =>
                                                                    handleAccordClick(
                                                                        qwer.id,
                                                                        qwer.label,
                                                                        qwer.value
                                                                    )
                                                                }
                                                            >
                                                                {qwer.label}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="reservation_total ">
                                            <h5 className="reservation_total_heading">
                                                <HandleNaN
                                                    currency={'₹'}
                                                    value={Math.trunc(
                                                        issuedItemAmount?.issuedItem
                                                    )}
                                                />
                                            </h5>
                                            <p
                                                style={{ fontWeight: "600" }}
                                                className="reservation_total_paragraph"
                                            >
                                                Total Issued Items
                                            </p>
                                        </div>
                                        <div className="reservation_total confirmed">
                                            <h5 className="reservation_total_heading">
                                                <HandleNaN
                                                    currency={'₹'}
                                                    value={Math.trunc(
                                                        issuedItemAmount?.itemAmt
                                                    )}
                                                />
                                            </h5>
                                            <p
                                                style={{ fontWeight: "600" }}
                                                className="reservation_total_paragraph"
                                            >
                                                Issued Items Amount
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <LegacyCard>
                            {orders && orders.length > 0 ? (
                                <IndexTable
                                    resourceName={resourceName}
                                    itemCount={orders.length}
                                    selectedItemsCount={
                                        allResourcesSelected ? "All" : selectedResources.length
                                    }
                                    onSelectionChange={handleSelectionChange}
                                    headings={[
                                        { title: "Puchased Item Name" },
                                        { title: "Issue To" },
                                        { title: "Issue By" },
                                        { title: "Quantity" },
                                    ]}
                                >
                                    {rowMarkup}
                                </IndexTable>
                            ) : (
                                <NoDataFound />
                            )}

                            {/* Single select */}
                            {showLowerMenuSingleSelect && (
                                <div className=" room-type-bottom-container">
                                    <div className="room-type-bottom-container-div">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                                gap: "20px",
                                            }}
                                        >
                                            <Button plain destructive onClick={showModelMenu}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <span>
                                                        <Icon source={DeleteMajor} color="base" />
                                                    </span>
                                                    <span>Remove</span>
                                                </div>
                                            </Button>

                                            {/* <BgThemeButton
                                              children={"Edit"}
                                              onClick={() => setShowEditModelMenuActive(!showEditModelMenuActive)}
                                          /> */}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Multiple select */}
                            {showLowerMenuMultipleSelect && (
                                <div className=" room-type-bottom-container">
                                    <div className="room-type-bottom-container-div">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                            }}
                                        >
                                            <Button plain destructive onClick={showModelMenu}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <span>
                                                        <Icon source={DeleteMajor} color="base" />
                                                    </span>
                                                    <span>Remove</span>
                                                </div>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </LegacyCard>
                    </div>

                    {/* Add Item Button Click */}
                    {showAddItemModelMenuActive && (
                        <>
                            <div className="bg-backdrop">
                                <section className="stock-add-model-container">
                                    <form onSubmit={(e) => handleSubmitAddIssueItem(e)}>
                                        <div style={{ marginLeft: "-200px" }}>
                                            <Page
                                                backAction={{
                                                    content: "Products",
                                                    url: `/hotelier/purchase/issueitems`,
                                                }}
                                                title="Add Issue Items"
                                                compactTitle
                                            ></Page>
                                        </div>

                                        <div className="form-container-div-half">
                                            <div className="form-container-div">
                                                <div style={{ marginBottom: "4px" }}>
                                                    Issued To
                                                    <span style={{ color: "red" }}>*</span>
                                                </div>
                                                <select
                                                    style={{
                                                        width: "100%",
                                                        minHeight: "2.25rem",
                                                        border: "1px solid rgba(171, 177, 186, 1)",
                                                        borderRadius: "3px",
                                                    }}
                                                    required={true}
                                                    name="issue_to"
                                                    onChange={handleAddStockChange}
                                                    value={issues.issue_to}
                                                >
                                                    <option value="">Select Option</option>
                                                    {staffs?.map((x, index) => (
                                                        <option key={index} value={x.name}>
                                                            {x.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-container-div">
                                                <VerticalInputFieldCustom
                                                    titleName={"Issue Date"}
                                                    type="date"
                                                    value={issues.issue_date}
                                                    name="issue_date"
                                                    className="addStaffInputs"
                                                    onChange={handleAddStockChange}
                                                    required={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="addStockTable">
                                            <div className="addStockTableHeader">
                                                <div style={{ width: "25%" }}>Purchased Item Name</div>
                                                <div style={{ width: "25%" }}>Item Price</div>
                                                <div style={{ width: "25%" }}>Qty</div>
                                                <div style={{ width: "5%" }}>Max Qty</div>
                                                <div style={{ width: "10%" }}></div>
                                            </div>

                                            {itemDetails?.map((_, index) => (
                                                <div className="addStockTableContent" key={index}>
                                                    <div style={{ width: "25%" }}>
                                                        <select
                                                            style={{
                                                                width: "100%",
                                                                minHeight: "2.25rem",
                                                                border: "1px solid rgba(171, 177, 186, 1)",
                                                                borderRadius: "3px",
                                                            }}
                                                            required={true}
                                                            name="purchased_item_id"
                                                            onChange={(e) =>
                                                                handleChangePurchaseItem(e, index)
                                                            }
                                                            value={itemDetails[index].purchased_item_id}
                                                        >
                                                            <option value="">Select Option</option>
                                                            {purchases?.map((x, index) => (
                                                                <option key={index} value={x.id}>
                                                                    {x.item_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    <div style={{ width: "25%" }}>
                                                        <VerticalInputFieldCustom
                                                            type="number"
                                                            value={itemDetails[index].item_price}
                                                            className="addStockTablePrice"
                                                            name="item_price"
                                                            required={"true"}
                                                            onChange={(e) => handleTableInputChange(e, index)}
                                                        />
                                                    </div>

                                                    <div style={{ width: "25%" }}>
                                                        <VerticalInputFieldCustom
                                                            type="number"
                                                            value={itemDetails[index].quantity}
                                                            className="addStockTableTotalPrice"
                                                            name="quantity"
                                                            required={"true"}
                                                            onChange={(e) => handleQuantityChange(e, index)}
                                                        />
                                                        <span style={{ color: "red" }}>
                                                            {errors[index]}
                                                        </span>
                                                    </div>

                                                    <div style={{ width: "5%" }}>
                                                        <VerticalInputFieldCustom
                                                            type="number"
                                                            className="addStockTableTotalPrice"
                                                            name={`max_qty_${index}`}
                                                            value={itemDetails[index]?.max_qty}
                                                            disabled={"disabled"}
                                                        />
                                                    </div>

                                                    <div style={{ width: "10%" }}>
                                                        <button
                                                            onClick={handleAddStock}
                                                            className="addStockButton"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="14"
                                                                height="14"
                                                                viewBox="0 0 14 14"
                                                                fill="none"
                                                            >
                                                                <g clipPath="url(#clip0_622_2184)">
                                                                    <path
                                                                        d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                                        stroke="#3968ED"
                                                                    />
                                                                    <path
                                                                        d="M10.5868 7H3.41357"
                                                                        stroke="#3968ED"
                                                                        strokeMiterlimit="10"
                                                                    />
                                                                    <path
                                                                        d="M7 3.41344L7 10.5867"
                                                                        stroke="#3968ED"
                                                                        strokeMiterlimit="10"
                                                                    />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_622_2184">
                                                                        <rect width="14" height="14" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Add More
                                                        </button>

                                                        {index > 0 && (
                                                            <button
                                                                onClick={() => handleRemoveStock(index)}
                                                                className="addStockButton"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="15"
                                                                    height="15"
                                                                    viewBox="0 0 15 15"
                                                                    fill="none"
                                                                    style={{ marginLeft: "10px" }}
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        clipRule="evenodd"
                                                                        d="M10.5 3H12.75C13.1642 3 13.5 3.33579 13.5 3.75L13.5 4.5H1.5L1.50002 3.75C1.50002 3.33579 1.83581 3 2.25002 3H4.50002V1.125C4.50002 0.50368 5.0037 0 5.62502 0H9.37502C9.99634 0 10.5 0.50368 10.5 1.125V3ZM6.00002 1.5V3H9.00002V1.5H6.00002Z"
                                                                        fill="#888888"
                                                                    />
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        clipRule="evenodd"
                                                                        d="M2.25002 6H12.75V13.875C12.75 14.4963 12.2463 15 11.625 15H3.37502C2.7537 15 2.25002 14.4963 2.25002 13.875V6ZM5.25 8.25H3.75V12.75H5.25V8.25ZM8.25 8.25H6.75V12.75H8.25V8.25ZM9.75 8.25H11.25V12.75H9.75V8.25Z"
                                                                        fill="#888888"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                gap: "10px",
                                            }}
                                        >
                                            <BgThemeButton
                                                children={"Add"}
                                                type={"submit"}
                                            // onClick={}
                                            />
                                            <Button
                                                onClick={() => setShowAddItemModelMenuActive(false)}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </form>
                                </section>
                            </div>
                        </>
                    )}

                    {/* Edit Button Click */}
                    {showEditModelMenuActive && (
                        <>
                            <div className="bg-backdrop"></div>
                        </>
                    )}

                    {showModelMenuActive && (
                        <>
                            <div className="bg-backdrop">
                                <section className="room-type-model-container ">
                                    <h4>Are You Sure?</h4>
                                    <p>You Want To Remove?</p>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <Button
                                            onClick={() =>
                                                setShowModelMenuActive(!showModelMenuActive)
                                            }
                                        >
                                            Discard
                                        </Button>
                                        <Button onClick={removeItemConfirm} destructive>
                                            Yes, Remove
                                        </Button>
                                    </div>
                                </section>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};
export default StoreIssuedItemsPage;
