import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { formatDate, formatIndianCurrency } from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
const ReportPaymentReconciliation = ({
  departureReport,
  loader,
  settlementOrders,
}) => {
  const property_id = useSelector((state) => state.property.value);
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const names = [
    {
      date: "Date",
      payment_mode: "Payment Mode",
      settlement_amount: "Settlement Amount",
    },
  ];

  const handleSinglePaymentClick = (date, payment_type) => {
    singlePaymentDetails(
      new Date(date).toISOString().split("T")[0],
      payment_type
    );
  };

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <IndexTable.Row id={order.id} key={order.id} position={order.index}>
        <IndexTable.Cell>{formatDate(new Date(order.date))}</IndexTable.Cell>
        <IndexTable.Cell>
          <UnderscoreRemoveCapitalize text={order.payment_mode} />{" "}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <span
            onClick={() =>
              handleSinglePaymentClick(order.date, order.payment_mode)
            }
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {" "}
            {formatIndianCurrency(order.settlement_amount)}
          </span>
        </IndexTable.Cell>
      </IndexTable.Row>
    ));
  const foodSettlementRowMarkup =
    settlementOrders &&
    settlementOrders?.length > 0 &&
    settlementOrders?.map((order) => (
      <IndexTable.Row id={order.id} key={order.id} position={order.index}>
        <IndexTable.Cell>{order.date}</IndexTable.Cell>
        <IndexTable.Cell>
          <UnderscoreRemoveCapitalize text={order.payment_mode} />{" "}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatIndianCurrency(order.settlement_amount)}
        </IndexTable.Cell>
      </IndexTable.Row>
    ));

  const [showPaymentPopup, setPaymentPopup] = useState(false);
  const [singlePaymentData, setSinglePaymentData] = useState();
  const singlePaymentHeadings = [
    "Date",
    "Booking ID",
    "Payment Type",
    "Settlement Amount (₹)",
  ];

  const singlePaymentDetails = async (date, payment_type) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/details-payment-reconciliation-report/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            date: date,
            payment_type: payment_type,
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        setSinglePaymentData(data.data);
        setPaymentPopup(true);
      } else {
        setSinglePaymentData(data.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div style={{ display: "flex", gap: "10px" }} id="room-types">
            <div
              style={{ width: "100%" }}
              className="room-types-full-container"
            >
              <div className="room-types-full-container-div">
                <div className="room-type-heading">Room Payment Ledger </div>
                {departureReport?.length > 0 && (
                  <div
                    className="flex flex_gap_15 align_center"
                    id="doNotPrint"
                  >
                    {/* <DownloadPdfTables
                      tableData={departureReport}
                      tableNames={names}
                    />
                    <DownloadCsvTables tableData={departureReport} names={names}/> */}
                    <ButtonToDownloadTablePDF
                      downloadFileName={"room_payment_ledger"}
                      itemToDownloadID={"room-types"}
                      ignoreFromPrint={"doNotPrint"}
                    />
                    <DownloadCSVButton
                      downloadFileName={"room_payment_ledger"}
                      itemToDownloadID={"room-types"}
                    />
                  </div>
                )}
              </div>

              {departureReport && departureReport?.length > 0 ? (
                <>
                  <LegacyCard>
                    <IndexTable
                      resourceName={resourceName}
                      itemCount={departureReport.length}
                      selectable={false}
                      headings={[
                        { title: "Date" },
                        { title: "Payment Method" },
                        { title: "Settlement Amount (₹)" },
                      ]}
                    >
                      {rowMarkup}
                    </IndexTable>
                  </LegacyCard>
                </>
              ) : (
                <NoDataFound />
              )}
            </div>

            {/* <div style={{ width: "100%" }} className="room-types-full-container">
              {
                settlementOrders && settlementOrders.length > 0 && (
                  <>
                    <div className="room-types-full-container-div">
                      <div className="room-type-heading">Food Ledger</div>
                    </div>
                    <LegacyCard>
                      <IndexTable
                        resourceName={resourceName}
                        itemCount={departureReport.length}
                        selectable={false}
                        headings={[
                          { title: "Date" },
                          { title: "Payment Method" },
                          { title: "Settlement Amount (₹)" },
                        ]}
                      >
                        {foodSettlementRowMarkup}
                      </IndexTable>
                    </LegacyCard>
                  </>
                )
                // : (
                //   <NoDataFound />
                // )
              }
            </div> */}
          </div>

          {/* <div>
            <>
              <h1>Summary Report</h1>
              <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={departureReport.length}
                  selectable={false}
                  headings={[
                    { title: "Date" },
                    { title: "Payment Method" },
                    { title: "Settlement Amount (₹)" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
              </LegacyCard>
            </>
          </div> */}
        </>
        // </div>
      )}

      {showPaymentPopup && (
        <>
          <div
            className="global_popup_container_background"
            onClick={() => setPaymentPopup(false)}
          >
            <div
              className="global_popup_container"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="flex justify_content_between align_center"
                style={{ marginBottom: "10px" }}
              >
                <h4 className="room-type-heading"> Single Payment Details</h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  onClick={() => setPaymentPopup(false)}
                  style={{ margin: "0px 0 0 auto",cursor: "pointer" }}
                >
                  <path
                    d="M1 13.1501L13 0.850098"
                    stroke="#AAAAAA"
                    stroke-width="1.2"
                  />
                  <path
                    d="M13 13.1501L0.999999 0.850147"
                    stroke="#AAAAAA"
                    stroke-width="1.2"
                  />
                </svg>
              </div>

              <CustomReportTable
                headings={singlePaymentHeadings}
                headerStyles={{
                  padding: "10px 5px",
                  fontWeight: "bold",
                  backgroundColor: "#f2f2f2",
                  borderBottom: "1px solid #CCC",
                }}
              >
                {singlePaymentData.map((data, index) => (
                  <CustomReportTableRow key={index}>
                    <CustomReportTableCell>{formatDate(new Date(data?.date))}</CustomReportTableCell>
                    <CustomReportTableCell>
                      #{data?.unique_booking_id}
                    </CustomReportTableCell>
                    <CustomReportTableCell>
                      {data?.payment_mode.replaceAll("_", " ")}
                    </CustomReportTableCell>
                    <CustomReportTableCell>
                      {formatIndianCurrency(data?.amount)}
                    </CustomReportTableCell>
                  </CustomReportTableRow>
                ))}
              </CustomReportTable>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ReportPaymentReconciliation;
