import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BgOutlineThemeButton, BgThemeButton } from "../../../UI/Buttons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../assets/toastUtils";
import VerticalInputFieldCustom from "../../VerticalInputFieldCustom.jsx";
import {
  grandTotalForInclusive,
  grandTotalForExclusive,
  taxAmountForInclusive,
  taxAmountForExclusive,
  calculateSubtotalForInclusive,
  calculateSubtotalForExclusive,
} from "../calculationFunctions/CalculationFunctions.jsx";
import {
  calculateTotal,
  calculateNumberOfNights,
} from "../calculationFunctions/CalculateKeyTotalFromArrayOfObjects";
import ModifyReservationSelectComponent from "../ModifyReservationSelectComponent/ModifyReservationSelectComponent.jsx";
const ModifyReservationArrivalsChangeRoomCategory = ({
  bookingData,
  addOnCost,
  reasons,
  setReasons,
  setShowModifyPopup,
  handleReasonChange,
  getBookingData,
  checkKeyValue,
}) => {
  const [loader, setLoader] = useState(false);
  const property_id = useSelector((state) => state.property.value);
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const navigate = useNavigate();

  const [editedRoomCategory, setEditedRoomCategory] = useState([]);

  useEffect(() => {
    if (bookingData && bookingData.items) {
      const newEditedRoomCategory = bookingData.items.map((item) => ({
        room_category: item.room_type_name,
        changed_room_category: "",
        room_id: item.room_id,
        oldRoomId: item.room_id,
        rate_plan_id: item.rate_plan_id,
        meal_plan: item.room_plan,
        room_rate_per_night: item.room_rate,
        ad_Hoc: item.ad_Hoc || "",
        room_rate: [],
        no_child: item.no_child,
        no_adult: item.no_adult,
        extra_adult: item.extra_adult,

        booked_rooms: item.all_assigned_rooms,

        paxes: item.paxes,
        tax_type: item.tax_type,
        no_of_rooms: item.no_of_rooms,
        no_of_nights: item.no_nights,
        booking_id: item.id,
        discount_rate: item.discount_rate,
        id: item.rate_plan_id,
        new_room_rate: "",
        old_tax_rate: item.tax_rate,
        old_tax_amount: item.tax_amount,
        old_discount_amount: item.discount_amount,
        old_grand_total: item.room_grand_total,
        old_sub_total: item.room_total_amount,
      }));

      setEditedRoomCategory(newEditedRoomCategory);
      localStorage.setItem(
        "editedRoomCategory",
        JSON.stringify(newEditedRoomCategory)
      );
    }
  }, [bookingData]);


  const [roomCategoryOptions, setRoomCategoryOptions] = useState([]);

  const fetchRoomCategory = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setRoomCategoryOptions(data?.rooms);
      setLoader(false);
    } catch (error) {
      console.error(error);
      showErrorToast("Could not find Room Categories. Please try again.!");
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchRoomCategory();
    }
  }, [property_id]);

  const handleRoomCategoryChange = async (e, index, rooms) => {
    const room_id = e.target.value;
    const changedRoomCategoryObject = roomCategoryOptions.find(
      (category) => category.id === parseInt(room_id)
    );
    const changedRoomCategory = changedRoomCategoryObject?.room_name;
    const isRoomCategoryInArray = editedRoomCategory.find(
      (item) => item.changed_room_category === changedRoomCategory
    );

    if (isRoomCategoryInArray) {
      showErrorToast(
        "Room category already exists. Please choose another category."
      );
      return;
    }

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/check/availability/${property_id?.id}?check_in=${bookingData.check_in}&check_out=${bookingData.check_out}&room_id=${room_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setLoader(false);

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      if (data.message === "Unauthorised.") {
        navigate("/");
      }

      const blockedRooms = data.available_rooms_dates.filter(
        (item) => item.blocked !== 0
      );
      if (rooms > data.available_rooms) {
        showErrorToast(
          `Failed to change room category. Rooms available for this category is ${data.available_rooms}. Please choose another category.`
        );
        return;
      } else if (blockedRooms.length > 0) {
        showErrorToast(
          "Room is blocked for selected dates :  " +
            blockedRooms.map((room) => room.date).join(", ")
        );
        return;
      } else {
        setEditedRoomCategory((prevState) => {
          const updatedEditedRoomCategory = [...prevState];
          updatedEditedRoomCategory[index] = {
            ...updatedEditedRoomCategory[index],
            room_id: room_id,
            changed_room_category: changedRoomCategory,
          };
          return updatedEditedRoomCategory;
        });
        fetchAvailableRooms(index, room_id);
        setShowRoomCategoryTotal(false);
        setNewRoomCategoryTotal(0);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const fetchAvailableRooms = async (index, room_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/clean/room/no/${room_id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      if (data.message === "Unauthorised.") {
        navigate("/");
      }

      setEditedRoomCategory((prevState) => {
        const updatedEditedRoomCategory = [...prevState];
        updatedEditedRoomCategory[index] = {
          ...updatedEditedRoomCategory[index],
          available_rooms_options: data.data,
        };
        return updatedEditedRoomCategory;
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleRoomNoChange = (e, index, roomIndex) => {
    setEditedRoomCategory((prevState) => {
      const updatedBookingData = [...prevState];
      const updatedBookedRooms = [
        ...(updatedBookingData[index]?.change_room_no || []),
      ]; // Ensure booked_rooms is an array

      // Update the value at the specific roomIndex
      updatedBookedRooms[roomIndex] = e.target.value;

      updatedBookingData[index] = {
        ...updatedBookingData[index],
        change_room_no: updatedBookedRooms,
      };

      return updatedBookingData;
    });
  };

  const [showRoomRateField, setShowRoomRateField] = useState(
    Array(editedRoomCategory.length).fill(false)
  );
  const handleShowRoomRateField = (index) => {
    setShowRoomRateField((prevShowRoomRateField) => {
      const updatedShowRoomRateField = [...prevShowRoomRateField];
      updatedShowRoomRateField[index] = !updatedShowRoomRateField[index];
      return updatedShowRoomRateField;
    });
  };
  useEffect(() => {
    showRoomRateField.forEach((show, index) => {
      if (!show) {
        setEditedRoomCategory((prevEditedRoomCategory) => {
          const updatedEditedRoomCategory = [...prevEditedRoomCategory];
          updatedEditedRoomCategory[index].new_room_rate_per_night = "";
          return updatedEditedRoomCategory;
        });
      }
    });
  }, [showRoomRateField]);

  const [modificationDateCategory, setModificationDateCategory] = useState("");
  const [showRoomCategoryTotal, setShowRoomCategoryTotal] = useState(false);
  const [newRoomCategoryTotal, setNewRoomCategoryTotal] = useState(0);

  const handleRoomCategoryTotal = () => {
    if (!modificationDateCategory) {
      showErrorToast("please choose the modification date");
      return;
    }
    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };

    const oldNights = calculateNumberOfNights(
      new Date(bookingData.check_in),
      new Date(modificationDateCategory) // This is the current date
    );

    const newNights = calculateNumberOfNights(
      new Date(modificationDateCategory),
      new Date(bookingData.check_out)
    );

    const rooms = JSON.parse(localStorage.getItem("editedRoomCategory")).map(
      (item) => {
        const rate_per_night = item.room_rate_per_night;
        const taxRate = +rate_per_night > 7500 ? 18 : 12;
        const discountAmount =
          (+item.old_discount_amount / +item.no_of_nights) * oldNights;
        const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

        const modifiedItem = {
          Rate: rate_per_night,
          RoomTypeName: item.changed_room_category,
          BookingItemId: item.booking_id,
          RoomDiscount: discountAmount,
          TaxRate: taxRate,
          NetTotal:
            item.tax_type === "inclusive"
              ? grandTotalForInclusive(
                  +rate_per_night,
                  +oldNights,
                  +item.no_of_rooms,
                  +1,
                  +childPrice,
                  +1,
                  +extraAdultPrice,
                  +discountAmount
                )
              : grandTotalForExclusive(
                  +rate_per_night,
                  +oldNights,
                  +item.no_of_rooms,
                  +1,
                  +childPrice,
                  +1,
                  +extraAdultPrice,
                  +taxRate,
                  +discountAmount
                ),
          TaxAmount:
            item.tax_type === "inclusive"
              ? taxAmountForInclusive(
                  +rate_per_night,
                  +oldNights,
                  +item.no_of_rooms,
                  +1,
                  +childPrice,
                  +1,
                  +extraAdultPrice,
                  +taxRate,
                  +discountAmount
                )
              : taxAmountForExclusive(
                  +rate_per_night,
                  +oldNights,
                  +item.no_of_rooms,
                  +1,
                  +childPrice,
                  +1,
                  +extraAdultPrice,
                  +taxRate,
                  +discountAmount
                ),
          TaxbleTotal:
            item.tax_type === "inclusive"
              ? calculateSubtotalForInclusive(
                  +rate_per_night,
                  +oldNights,
                  +item.no_of_rooms,
                  +1,
                  +childPrice,
                  +1,
                  +extraAdultPrice,
                  +taxRate,
                  +discountAmount
                )
              : calculateSubtotalForExclusive(
                  +rate_per_night,
                  +oldNights,
                  +item.no_of_rooms,
                  +1,
                  +childPrice,
                  +1,
                  +extraAdultPrice,
                  +discountAmount
                ),
        };
        return modifiedItem;
      }
    );

    const roomsNew = editedRoomCategory.map((item) => {
      const rate_per_night = item.new_room_rate_per_night
        ? item.new_room_rate_per_night
        : item.room_rate_per_night;
      const taxRate = +rate_per_night > 7500 ? 18 : 12;
      const discountAmount =
        (+item.old_discount_amount / +item.no_of_nights) * +newNights;
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const modifiedItem = {
        Rate: rate_per_night,
        oldRoomId: item.oldRoomId,
        RoomId: item.room_id,
        RoomTypeName: item.changed_room_category
          ? item.changed_room_category
          : item.room_category,
        BookingItemId: item.booking_id,
        RoomDiscount: discountAmount,
        TaxRate: taxRate,
        change_room_no: item.change_room_no,
        booked_rooms: item.booked_rooms,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              )
            : grandTotalForExclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : taxAmountForExclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : calculateSubtotalForExclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              ),
      };
      return modifiedItem;
    });

    function mergeRoomArrays(rooms, roomsNew) {
      if (rooms.length !== roomsNew.length) {
        throw new Error(
          "The lengths of the rooms and roomsNew arrays do not match."
        );
      }

      return roomsNew.map((roomNew, index) => {
        const room = rooms[index];

        // Check if booked_rooms and change_room_no exist and are arrays
        const bookedRooms = Array.isArray(roomNew.booked_rooms)
          ? roomNew.booked_rooms
          : [];
        const changeRoomNo = Array.isArray(roomNew.change_room_no)
          ? roomNew.change_room_no
          : [];

        // Ensure bookedRooms and changeRoomNo have the same length to map correctly
        const transferRooms = bookedRooms.map((item, itemIndex) => {
          return {
            oldAssignedRoom: item.assigned_room,
            newAssignedRoom: changeRoomNo[itemIndex] || null,
          };
        });

        return {
          ...roomNew,
          RoomDiscount: +room.RoomDiscount + +roomNew.RoomDiscount,
          NetTotal: +room.NetTotal + +roomNew.NetTotal,
          TaxAmount: +room.TaxAmount + +roomNew.TaxAmount,
          TaxbleTotal: +room.TaxbleTotal + +roomNew.TaxbleTotal,
          transferRooms,
        };
      });
    }

    const mergedRooms = mergeRoomArrays(rooms, roomsNew);

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "upgrade_downgrade_room",
      bookingNetTotal:
        calculateTotal(mergedRooms, "NetTotal") + addOnCost.NetTotal,
      bookingTaxableAmount:
        calculateTotal(mergedRooms, "TaxbleTotal") + addOnCost.taxAmount,
      bookingTaxAmount:
        calculateTotal(mergedRooms, "TaxAmount") + addOnCost.taxAmount,
      bookingDiscountAmount:
        calculateTotal(mergedRooms, "RoomDiscount") + addOnCost.discountAmount,
      Room: mergedRooms,
    };
    setNewRoomCategoryTotal(requestBody.bookingNetTotal);
    setShowRoomCategoryTotal(true);
  };

  function validateRoomChanges(data) {
    return data.every((entry) => {
      const roomCategoryChanged = entry.changed_room_category;
      const  changedRoomArray= entry?.change_room_no ? entry?.change_room_no.length :[]

      if (
        roomCategoryChanged && 
        changedRoomArray === entry.booked_rooms.length &&   entry?.change_room_no.every(room => room !== null && room !== '')
      ) {
        return true;
      } else if (
        roomCategoryChanged &&
        changedRoomArray !== entry.booked_rooms.length
      ) {
        return false;
      }

      return true;
    });
  }

  const handleRoomCategorySubmit = async (e) => {
     if (!validateRoomChanges(editedRoomCategory)){
      showErrorToast("Please select the rooms")
      return
     }

    if (reasons.changeRoomCategoryReason === "") {
      showErrorToast("Please enter a reason for changing room category");
      return;
    }

    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };

    const oldNights = calculateNumberOfNights(
      new Date(bookingData.check_in),
      new Date(modificationDateCategory) // This is the current date
    );

    const newNights = calculateNumberOfNights(
      new Date(modificationDateCategory),
      new Date(bookingData.check_out)
    );

    const rooms = editedRoomCategory.map((item) => {
      const rate_per_night = item.room_rate_per_night;
      const taxRate = +rate_per_night > 7500 ? 18 : 12;
      const discountAmount =
        (+item.old_discount_amount / +item.no_of_nights) * oldNights;
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const modifiedItem = {
        Rate: rate_per_night,
        RoomTypeName: item.room_category,
        BookingItemId: item.booking_id,
        RoomDiscount: discountAmount,
        TaxRate: taxRate,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +rate_per_night,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              )
            : grandTotalForExclusive(
                +rate_per_night,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +rate_per_night,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : taxAmountForExclusive(
                +rate_per_night,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +rate_per_night,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : calculateSubtotalForExclusive(
                +rate_per_night,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              ),
      };
      return modifiedItem;
    });

    const roomsNew = editedRoomCategory.map((item) => {
      const rate_per_night = item.new_room_rate_per_night
        ? item.new_room_rate_per_night
        : item.room_rate_per_night;
      const taxRate = +rate_per_night > 7500 ? 18 : 12;
      const discountAmount =
        (+item.old_discount_amount / +item.no_of_nights) * newNights;
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const modifiedItem = {
        Rate: rate_per_night,
        oldRoomId: item.oldRoomId,
        RoomID: item.room_id,
        RoomTypeName: item.changed_room_category
          ? item.changed_room_category
          : item.room_category,
        BookingItemId: item.booking_id,
        RoomDiscount: discountAmount,
        TaxRate: taxRate,
        change_room_no: item.change_room_no,
        booked_rooms: item.booked_rooms,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              )
            : grandTotalForExclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : taxAmountForExclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : calculateSubtotalForExclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              ),
      };
      return modifiedItem;
    });

    function mergeRoomArrays(rooms, roomsNew) {
      if (rooms.length !== roomsNew.length) {
        throw new Error(
          "The lengths of the rooms and roomsNew arrays do not match."
        );
      }

      return roomsNew.map((roomNew, index) => {
        const room = rooms[index];

        // Check if booked_rooms and change_room_no exist and are arrays
        const bookedRooms = Array.isArray(roomNew.booked_rooms)
          ? roomNew.booked_rooms
          : [];
        const changeRoomNo = Array.isArray(roomNew.change_room_no)
          ? roomNew.change_room_no
          : [];

        // Ensure bookedRooms and changeRoomNo have the same length to map correctly
        const transferRooms = bookedRooms.map((item, itemIndex) => {
          return {
            oldAssignedRoom: item.assigned_room,
            newAssignedRoom: changeRoomNo[itemIndex] || item.assigned_room,
          };
        });

        return {
          ...roomNew,
          RoomDiscount: +room.RoomDiscount + +roomNew.RoomDiscount,
          NetTotal: +room.NetTotal + +roomNew.NetTotal,
          TaxAmount: +room.TaxAmount + +roomNew.TaxAmount,
          TaxbleTotal: +room.TaxbleTotal + +roomNew.TaxbleTotal,
          transferRooms,
        };
      });
    }

    const mergedRooms = mergeRoomArrays(rooms, roomsNew);

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "upgrade_downgrade_room",
      bookingNetTotal:
        calculateTotal(mergedRooms, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        calculateTotal(mergedRooms, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        calculateTotal(mergedRooms, "TaxAmount") + +addOnCost.taxAmount,
      bookingDiscountAmount:
        calculateTotal(mergedRooms, "RoomDiscount") + +addOnCost.discountAmount,
      requestReason: reasons.changeRoomCategoryReason,
      bookingDueAmount:
        +(+calculateTotal(mergedRooms, "NetTotal") + +addOnCost.NetTotal) -
        +bookingData.paid_amount,
      Room: mergedRooms,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      showSuccessToast("Room category changed successfully");
      setShowRoomCategoryTotal(false);
      setNewRoomCategoryTotal(0);
      setShowModifyPopup(false);
      setReasons({});
      // fetchBookingDetails();
      getBookingData(bookingData.unique_booking_id);
      setLoader(false);
      return data;
    } catch (error) {
      console.error(error);

      showErrorToast(error.message);
      setLoader(false);
      return null;
    }
  };

  const handleRoomCategorySubmitWithoutRooms = async () => {
    if (reasons.changeRoomCategoryReason === "") {
      showErrorToast("Please enter a reason for changing room category");
      return;
    }

    const extractPrices = (paxes) => {
      let childPrice = 0;
      let extraAdultPrice = 0;

      if (Array.isArray(paxes)) {
        paxes.forEach((pax) => {
          if (pax.pax_type === "child") {
            childPrice += parseFloat(pax.price); // Accumulate child prices
          } else if (pax.pax_type === "adult") {
            extraAdultPrice += parseFloat(pax.price); // Accumulate adult prices
          }
        });
      } else {
        console.error("Invalid paxes data:", paxes);
      }

      return { childPrice, extraAdultPrice };
    };
    const oldNights = calculateNumberOfNights(
      new Date(bookingData.check_in),
      new Date(modificationDateCategory) // This is the current date
    );

    const newNights = calculateNumberOfNights(
      new Date(modificationDateCategory),
      new Date(bookingData.check_out)
    );

    const rooms = editedRoomCategory.map((item) => {
      const rate_per_night = item.room_rate_per_night;
      const taxRate = +rate_per_night > 7500 ? 18 : 12;
      const discountAmount =
        (+item.old_discount_amount / +item.no_of_nights) * oldNights;
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const modifiedItem = {
        Rate: rate_per_night,
        RoomTypeName: item.room_category,
        BookingItemId: item.booking_id,
        RoomDiscount: discountAmount,
        TaxRate: taxRate,
        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +rate_per_night,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              )
            : grandTotalForExclusive(
                +rate_per_night,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +rate_per_night,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : taxAmountForExclusive(
                +rate_per_night,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +rate_per_night,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : calculateSubtotalForExclusive(
                +rate_per_night,
                +oldNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              ),
      };
      return modifiedItem;
    });

    const roomsNew = editedRoomCategory.map((item) => {
      const rate_per_night = item.new_room_rate_per_night
        ? item.new_room_rate_per_night
        : item.room_rate_per_night;
      const taxRate = +rate_per_night > 7500 ? 18 : 12;
      const discountAmount =
        (+item.old_discount_amount / +item.no_of_nights) * newNights;
      const { childPrice, extraAdultPrice } = extractPrices(item.paxes);

      const modifiedItem = {
        Rate: rate_per_night,
        // room
        RoomID: item.room_id,
        RoomTypeName: item.changed_room_category
          ? item.changed_room_category
          : item.room_category,
        BookingItemId: item.booking_id,
        RoomDiscount: discountAmount,
        TaxRate: taxRate,

        NetTotal:
          item.tax_type === "inclusive"
            ? grandTotalForInclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              )
            : grandTotalForExclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxAmount:
          item.tax_type === "inclusive"
            ? taxAmountForInclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : taxAmountForExclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              ),
        TaxbleTotal:
          item.tax_type === "inclusive"
            ? calculateSubtotalForInclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +taxRate,
                +discountAmount
              )
            : calculateSubtotalForExclusive(
                +rate_per_night,
                +newNights,
                +item.no_of_rooms,
                +1,
                +childPrice,
                +1,
                +extraAdultPrice,
                +discountAmount
              ),
      };
      return modifiedItem;
    });

    function mergeRoomArrays(rooms, roomsNew) {
      if (rooms.length !== roomsNew.length) {
        throw new Error(
          "The lengths of the rooms and roomsNew arrays do not match."
        );
      }

      return roomsNew.map((roomNew, index) => {
        const room = rooms[index];

        return {
          ...roomNew,
          RoomDiscount: +room.RoomDiscount + +roomNew.RoomDiscount,
          NetTotal: +room.NetTotal + +roomNew.NetTotal,
          TaxAmount: +room.TaxAmount + +roomNew.TaxAmount,
          TaxbleTotal: +room.TaxbleTotal + +roomNew.TaxbleTotal,
        };
      });
    }

    const mergedArray = mergeRoomArrays(rooms, roomsNew);

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "change_room_category",
      bookingNetTotal:
        +calculateTotal(mergedArray, "NetTotal") + +addOnCost.NetTotal,
      bookingTaxableAmount:
        +calculateTotal(mergedArray, "TaxbleTotal") + +addOnCost.taxableAmount,
      bookingTaxAmount:
        +calculateTotal(mergedArray, "TaxAmount") + +addOnCost.taxAmount,
      Room: mergedArray,
      requestReason: reasons.changeRoomCategoryReason,
      bookingDiscountAmount:
        +calculateTotal(mergedArray, "RoomDiscount") +
        +addOnCost.discountAmount,
      bookingDueAmount:
        +calculateTotal(mergedArray, "NetTotal") +
        +addOnCost.NetTotal -
        +bookingData.paid_amount,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      showSuccessToast("Room category changed successfully");
      setShowRoomCategoryTotal(false);
      setNewRoomCategoryTotal(0);
      setShowModifyPopup(false);
      setReasons({});
      getBookingData(bookingData.unique_booking_id);
      setLoader(true);

      return data;
    } catch (error) {
      console.error(error);
      showErrorToast(error.message);
      setLoader(true);
      return null;
    }
  };

  const handleDiscountAmountChangeRoomCategory = (
    event,
    index,
    oldRoomRate,
    newRoomRate,
    noOfRooms
  ) => {
    const { name, value } = event.target;
    if (
      +value > +newRoomRate * +noOfRooms ||
      +value > +oldRoomRate * +noOfRooms
    ) {
      showErrorToast("enter valid discount amount");
      return;
    } else {
      setEditedRoomCategory((prevState) => {
        const updatedEditedRoomCategory = [...prevState];
        updatedEditedRoomCategory[index] = {
          ...updatedEditedRoomCategory[index],
          [name]: value,
        };
        return updatedEditedRoomCategory;
      });
      setShowRoomCategoryTotal(false);
      setNewRoomCategoryTotal(0);
    }
  };

  const handleNewRoomRateChangeRoomCategory = (event, index) => {
    const { name, value } = event.target;
    setEditedRoomCategory((prevState) => {
      const updatedEditedRoomCategory = [...prevState];
      updatedEditedRoomCategory[index] = {
        ...updatedEditedRoomCategory[index],
        [name]: value,
      };
      return updatedEditedRoomCategory;
    });
    setShowRoomCategoryTotal(false);
    setNewRoomCategoryTotal(0);
  };

  const [showTransferRoom, setShowTransferRoom] = useState(false);

  //----transfer room only ------
  const [editRoomNumbers, setEditRoomNumbers] = useState([]);
  const [enabledRoomChangeCategory, setEnabledRoomChangeCategory] = useState(
    []
  );

  useEffect(() => {
    if (bookingData && bookingData.items) {
      const newEditedRoomCategory = bookingData.items.map((item) => ({
        room_category: item.room_type_name,
        changed_room_category: "",
        room_id: item.room_id,
        rate_plan_id: item.rate_plan_id,
        meal_plan: item.room_plan,
        room_rate_per_night: item.room_rate,
        ad_Hoc: item.ad_Hoc || "",
        room_rate: [],

        booked_rooms: item.all_assigned_rooms,

        paxes: item.paxes,
        tax_type: item.tax_type,
        no_of_rooms: item.no_of_rooms,
        no_of_nights: item.no_nights,
        booking_id: item.id,
        discount_rate: item.discount_rate,
        id: item.rate_plan_id,
        new_room_rate: "",
        old_tax_rate: item.tax_rate,
        old_tax_amount: item.tax_amount,
        old_discount_amount: item.discount_amount,
        old_grand_total: item.room_grand_total,
        old_sub_total: item.room_total_amount,
      }));

      setEditRoomNumbers(newEditedRoomCategory);
      localStorage.setItem(
        "editRoomNumbers",
        JSON.stringify(newEditedRoomCategory)
      );
    }
  }, [bookingData]);

  const handleRoomChangeEnableDisable = async (e, index, room_id) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setLoader(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/get/clean/room/no/${room_id}/${property_id?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        if (data.message === "Unauthorised.") {
          navigate("/");
        }

        setEditRoomNumbers((prevState) => {
          const updatedEditedRoomCategory = [...prevState];
          updatedEditedRoomCategory[index] = {
            ...updatedEditedRoomCategory[index],
            available_rooms_options: data.data,
          };
          return updatedEditedRoomCategory;
        });
        setEnabledRoomChangeCategory((prevEnabledMealPlanChange) => [
          ...prevEnabledMealPlanChange,
          index,
        ]);
        setLoader(false);
      } catch (error) {
        console.error(error);
        setLoader(false);
      }
    } else {
      setEditRoomNumbers((prevState) => {
        const updatedEditedRoomCategory = [...prevState];
        updatedEditedRoomCategory[index] = {
          ...updatedEditedRoomCategory[index],
          available_rooms_options: [],
          change_room_no: [],
        };
        return updatedEditedRoomCategory;
      });

      setEnabledRoomChangeCategory((prevEnabledMealPlanChange) =>
        prevEnabledMealPlanChange.filter((i) => i !== index)
      );
    }
  };

  const handleRoomNoChangeTransferRoom = (e, index, roomIndex) => {
    setEditRoomNumbers((prevState) => {
      const updatedBookingData = [...prevState];
      const updatedBookedRooms = [
        ...(updatedBookingData[index]?.change_room_no || []),
      ];

      // Update the value at the specific roomIndex
      updatedBookedRooms[roomIndex] = e.target.value;

      updatedBookingData[index] = {
        ...updatedBookingData[index],
        change_room_no: updatedBookedRooms,
      };

      return updatedBookingData;
    });
  };

  const handleTransferRoomSubmit = async () => {
    if (reasons.changeRoomCategoryReason === "") {
      showErrorToast("Please enter a reason for changing room category");
      return;
    }

    const transferRooms = editRoomNumbers.map((room) => ({
      RoomID: room.room_id,
      BookingItemId: room.booking_id,
      RoomTypeName: room.room_category,

      transferRooms: room.booked_rooms.map((bookedRoom, index) => ({
        oldAssignedRoom: bookedRoom.assigned_room,
        newAssignedRoom:
          room?.change_room_no?.[index] ?? bookedRoom.assigned_room,
      })),
    }));

    const requestBody = {
      reservationId: bookingData.unique_booking_id,
      requestType: "transfer_room",
      requestReason: reasons.changeRoomCategoryReason,
      Room: transferRooms,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/reservation/modif/stay/${property_id.id}`,
        requestOptions
      );
      const data = await response.json();
      showSuccessToast(data.message);

      setShowModifyPopup(false);
      setReasons({});
      getBookingData(bookingData.unique_booking_id);

      if (!response.ok) {
        showErrorToast(data.message);
        throw new Error(data.error);
      }

      return data;
    } catch (error) {
      console.error(error);
      showErrorToast(error.message);

      return null;
    }
  };
  // --- transfer room end --------
  return (
    <div>
      {checkKeyValue(editedRoomCategory, "booked_rooms") ? (
        <div className="flex flex_gap_10" style={{ marginBottom: "10px" }}>
          <label className="flex flex_gap_5" style={{ cursor: "pointer" }}>
            <input
              type="radio"
              checked={!showTransferRoom}
              value={""}
              onChange={() => setShowTransferRoom(false)}
            />{" "}
            Change Room Category
          </label>
          <label className="flex flex_gap_5" style={{ cursor: "pointer" }}>
            <input
              type="radio"
              value={""}
              checked={showTransferRoom}
              onChange={() => setShowTransferRoom(true)}
            />{" "}
            Transfer Room
          </label>
        </div>
      ) : null}

      {!showTransferRoom ? (
        <div>
          <table
            className="modifyReservationPopupTable"
            style={{
              width: "100%",
              borderSpacing: "10px 0px",
            }}
          >
            <thead>
              <tr>
                <th>Room Category</th>
                <th>Change to</th>

                <th>Meal Plan</th>
                {checkKeyValue(editedRoomCategory, "booked_rooms") ? (
                  <>
                    {" "}
                    <th>Room No.</th>
                    <th></th>
                  </>
                ) : null}
                <th>Rate/Night</th>
                <th></th>

                <th>Disc. Amt</th>
              </tr>
            </thead>
            <tbody>
              {checkKeyValue(editedRoomCategory, "booked_rooms")
                ? editedRoomCategory.map((items, index) => (
                    <tr key={index}>
                      <td className="modify_reservation_table_cell_data">
                        <VerticalInputFieldCustom
                          value={items.room_category}
                          disabled={true}
                        />
                      </td>
                      <td className="modify_reservation_table_cell_data">
                        <div>
                          <div className="select">
                            <select
                              className="select-custom"
                              style={{
                                width: "100%",
                                minHeight: "2.25rem",
                                border: "0.0625rem solid #888",
                                borderRadius: "0.25rem",
                                paddingLeft: "5px",
                              }}
                              onChange={(e) =>
                                handleRoomCategoryChange(
                                  e,
                                  index,
                                  items.no_of_rooms
                                )
                              }
                              value={items.room_id}
                            >
                              <option value="">select</option>
                              {roomCategoryOptions.map((item, index) => {
                                const maxOccupancy =
                                  +items.no_of_rooms *
                                    (+items.no_adult +
                                      +items.no_child +
                                      +items.extra_adult) >
                                  +item.max_occupancy * +items.no_of_rooms;

                                const extraAdultLimit =
                                  +items.no_of_rooms * +items.extra_adult >
                                  (+item.max_adults - +items.base_adult) *
                                    +items.no_of_rooms;

                                const childLimit =
                                  +items.no_child * +items.no_of_rooms >
                                  +item.max_child * +items.no_of_rooms;
                               
                                return (
                                  <option
                                    key={index}
                                    value={item?.id}
                                    disabled={
                                      items?.room_category === item.room_name ||
                                      childLimit ||
                                      extraAdultLimit
                                      //  ( items?.room_category ===
                                      //   item.room_name) || (+items.no_of_rooms*( +items.no_adult + +items.no_child + +items.extraAdult) ) > (+item.max_occupancy * +items.no_of_rooms ) || (+items.no_child * +items.no_of_rooms) > (+item.max_child * +items.no_of_rooms)  || (+items.no_adult * +items.no_of_rooms) > (+item.base_adult * +items.no_of_rooms)
                                    }
                                  >
                                    {item.room_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </td>
                      <td className="modify_reservation_table_cell_data">
                        <VerticalInputFieldCustom
                          value={items.meal_plan}
                          disabled={true}
                        />
                      </td>
                      <td className="modify_reservation_table_cell_data">
                        {items?.booked_rooms?.map((room, roomIndex) => (
                          <div key={roomIndex}>
                            <VerticalInputFieldCustom
                              value={room.assigned_room}
                              disabled={true}
                            />
                          </div>
                        ))}
                      </td>
                      <td className="modify_reservation_table_cell_data">
                        {items?.available_rooms_options?.length > 0 && (
                          <>
                            {items?.booked_rooms?.map((room, roomIndex) => (
                              <div key={roomIndex}>
                                <ModifyReservationSelectComponent
                                  optionKeyName={"room_no"}
                                  options={items?.available_rooms_options}
                                  onChange={(e) =>
                                    handleRoomNoChange(e, index, roomIndex)
                                  }
                                  selectedOptions={
                                    items.change_room_no
                                      ? items.change_room_no
                                      : []
                                  }
                                  value={
                                    items.change_room_no
                                      ? items.change_room_no[roomIndex] || ""
                                      : ""
                                  }
                                />
                              </div>
                            ))}
                          </>
                        )}
                      </td>
                      <td className="d_flex flex_gap_10 modify_reservation_table_cell_data">
                        <div
                          className="create_booking_editable_input_container"
                          style={{
                            width: "50%",
                            height: "100%",
                          }}
                        >
                          <div
                            className="common_min_input d_flex gap_7"
                            style={{
                              marginTop: "5px",
                              width: "100px",
                              height: "38px",
                            }}
                          >
                            <input
                              defaultValue={items.room_rate_per_night}
                              disabled
                            ></input>
                            <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() => handleShowRoomRateField(index)}
                              style={{
                                cursor: "pointer",
                                zIndex: "10000",
                              }}
                            >
                              <g clip-path="url(#clip0_5205_24822)">
                                <path
                                  d="M8.40292 1.02256L10.2788 2.97704L10.5917 2.65107C11.1096 2.11144 11.1096 1.23666 10.5917 0.697025C10.0736 0.157225 9.23346 0.157225 8.71536 0.697025L8.40292 1.02256Z"
                                  fill="#888888"
                                />
                                <path
                                  d="M9.41751 3.87236L7.54162 1.91788L1.58902 8.11881L0.980103 10.7077L3.46491 10.0733L9.41751 3.87236Z"
                                  fill="#888888"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_5205_24822">
                                  <rect
                                    width="10"
                                    height="10.4167"
                                    fill="white"
                                    transform="translate(0.980072 0.291687)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </td>

                      <td
                        className="modify_reservation_table_cell_data"
                        style={{
                          width: showRoomRateField[index] ? "80px" : "0",
                        }}
                      >
                        {showRoomRateField[index] && (
                          <div style={{ width: "100%" }}>
                            <VerticalInputFieldCustom
                              value={items.new_room_rate_per_night}
                              name="new_room_rate_per_night"
                              onChange={(e) =>
                                handleNewRoomRateChangeRoomCategory(e, index)
                              }
                            />
                          </div>
                        )}
                      </td>
                      <td className="modify_reservation_table_cell_data">
                        <div style={{ width: "80px" }}>
                          <VerticalInputFieldCustom
                            value={Number(items.old_discount_amount)}
                            name="old_discount_amount"
                            onChange={(e) =>
                              handleDiscountAmountChangeRoomCategory(
                                e,
                                index,
                                items.room_rate_per_night,
                                items.new_room_rate_per_night,
                                items.no_of_rooms
                              )
                            }
                            type={"number"}
                            disabled={true}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                : editedRoomCategory.map((items, index) => (
                    <tr key={index}>
                      <td className="modify_reservation_table_cell_data">
                        <VerticalInputFieldCustom
                          value={items.room_category}
                          disabled={true}
                        />
                      </td>
                      <td className="modify_reservation_table_cell_data">
                        <div>
                          <div className="select">
                            <select
                              className="select-custom"
                              style={{
                                width: "100%",
                                minHeight: "2.25rem",
                                border: "0.0625rem solid #888",
                                borderRadius: "0.25rem",
                                paddingLeft: "5px",
                              }}
                              onChange={(e) =>
                                handleRoomCategoryChange(
                                  e,
                                  index,
                                  items.no_of_rooms
                                )
                              }
                              value={items.room_id}
                            >
                              <option value="">select</option>
                              {roomCategoryOptions.map((item, index) => {
                                
                                const maxOccupancy =
                                  +items.no_of_rooms *
                                    (+items.no_adult +
                                      +items.no_child +
                                      +items.extra_adult) >
                                  +item.max_occupancy * +items.no_of_rooms;

                                const extraAdultLimit =
                                  +items.no_of_rooms * +items.extra_adult >
                                  (+item.max_adults - +items.base_adult) *
                                    +items.no_of_rooms;

                                const childLimit =
                                  +items.no_child * +items.no_of_rooms >
                                  +item.max_child * +items.no_of_rooms;

                                return (
                                  <option
                                    key={index}
                                    value={item?.id}
                                    disabled={
                                      items?.room_category === item.room_name ||
                                      childLimit ||
                                      extraAdultLimit
                                      //  ( items?.room_category ===
                                      //   item.room_name) || (+items.no_of_rooms*( +items.no_adult + +items.no_child + +items.extraAdult) ) > (+item.max_occupancy * +items.no_of_rooms ) || (+items.no_child * +items.no_of_rooms) > (+item.max_child * +items.no_of_rooms)  || (+items.no_adult * +items.no_of_rooms) > (+item.base_adult * +items.no_of_rooms)
                                    }
                                  >
                                    {item.room_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </td>
                      <td className="modify_reservation_table_cell_data">
                        <VerticalInputFieldCustom
                          value={items.meal_plan}
                          disabled={true}
                        />
                      </td>
                      <td className="d_flex flex_gap_10 modify_reservation_table_cell_data">
                        <div
                          className="create_booking_editable_input_container"
                          style={{
                            width: "50%",
                            height: "100%",
                          }}
                        >
                          <div
                            className="common_min_input d_flex gap_7"
                            style={{
                              marginTop: "5px",
                              width: "100px",
                              height: "38px",
                            }}
                          >
                            <input
                              defaultValue={items.room_rate_per_night}
                              disabled
                            ></input>
                            <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() => handleShowRoomRateField(index)}
                              style={{
                                cursor: "pointer",
                                zIndex: "10000",
                              }}
                            >
                              <g clip-path="url(#clip0_5205_24822)">
                                <path
                                  d="M8.40292 1.02256L10.2788 2.97704L10.5917 2.65107C11.1096 2.11144 11.1096 1.23666 10.5917 0.697025C10.0736 0.157225 9.23346 0.157225 8.71536 0.697025L8.40292 1.02256Z"
                                  fill="#888888"
                                />
                                <path
                                  d="M9.41751 3.87236L7.54162 1.91788L1.58902 8.11881L0.980103 10.7077L3.46491 10.0733L9.41751 3.87236Z"
                                  fill="#888888"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_5205_24822">
                                  <rect
                                    width="10"
                                    height="10.4167"
                                    fill="white"
                                    transform="translate(0.980072 0.291687)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </td>
                      <td
                        style={{
                          width: showRoomRateField[index] ? "80px" : "0",
                        }}
                      >
                        {showRoomRateField[index] && (
                          <div style={{ width: "100%" }}>
                            <VerticalInputFieldCustom
                              value={items.new_room_rate_per_night}
                              name="new_room_rate_per_night"
                              onChange={(e) =>
                                handleNewRoomRateChangeRoomCategory(e, index)
                              }
                            />
                          </div>
                        )}
                      </td>
                      <td className="modify_reservation_table_cell_data">
                        <VerticalInputFieldCustom
                          value={Number(items.old_discount_amount)}
                          name="old_discount_amount"
                          onChange={(e) =>
                            handleDiscountAmountChangeRoomCategory(
                              e,
                              index,
                              items.room_rate_per_night,
                              items.new_room_rate_per_night,
                              items.no_of_rooms
                            )
                          }
                          type={"number"}
                        />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <span> Modification Date</span> :{" "}
            <span>
              <VerticalInputFieldCustom
                type="date"
                min={bookingData.check_in}
                value={modificationDateCategory}
                max={bookingData.check_out}
                onChange={(e) => [
                  setModificationDateCategory(e.target.value),
                  setShowRoomCategoryTotal(false),
                  setNewRoomCategoryTotal(0),
                ]}
              />
            </span>
          </div>

          <div className="modify_reservation_reason_container">
            <VerticalInputFieldCustom
              titleName={"Reason to Modify*"}
              name="changeRoomCategoryReason"
              onChange={handleReasonChange}
            />

            <div className="modify_reservation_reason_container flex flex_gap_10 align_items_center">
              <label>New Total:</label>

              <VerticalInputFieldCustom
                value={newRoomCategoryTotal}
                disabled={true}
              />

              <div>Previous Total : {bookingData?.grand_total}</div>
            </div>
          </div>

          <>
            {/* buttons for new room rate */}

            <div className="flex flex_gap_10">
              {checkKeyValue(editedRoomCategory, "booked_rooms") ? (
                <BgThemeButton
                  children={!showRoomCategoryTotal ? "Calculate" : "Modify"}
                  onClick={
                    !showRoomCategoryTotal
                      ? handleRoomCategoryTotal
                      : handleRoomCategorySubmit
                  }
                  loader={loader}
                />
              ) : (
                <BgThemeButton
                  children={!showRoomCategoryTotal ? "Calculate" : "Modify"}
                  onClick={
                    !showRoomCategoryTotal
                      ? handleRoomCategoryTotal
                      : handleRoomCategorySubmitWithoutRooms
                  }
                  loader={loader}
                />
              )}

              <BgOutlineThemeButton
                children={"Cancel"}
                onClick={() => setShowModifyPopup(false)}
              />
            </div>
          </>
        </div>
      ) : (
        <>
          {/* transfer rooms table */}
          <div>
            <table
              className="modifyReservationPopupTable"
              style={{
                width: "70%",
                borderSpacing: "10px 0px",
              }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Room Category</th>
                  <th>Room No.</th>
                  <th>Change to </th>
                  {/* <th>Rate/Night</th> */}
                  {/* <th></th> */}

                  {/* <th>Discount Amount</th> */}
                </tr>
              </thead>
              <tbody>
                {editRoomNumbers.map((items, changeRoomNumberIndex) => (
                  <tr key={changeRoomNumberIndex}>
                    <td className="modify_reservation_table_cell_data">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleRoomChangeEnableDisable(
                            e,
                            changeRoomNumberIndex,
                            items.room_id
                          )
                        }
                        checked={enabledRoomChangeCategory.includes(
                          changeRoomNumberIndex
                        )}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                    <td className="modify_reservation_table_cell_data">
                      <div>
                        <VerticalInputFieldCustom
                          value={items.room_category}
                          disabled={true}
                        />
                      </div>
                    </td>
                    <td className="modify_reservation_table_cell_data">
                      {items?.booked_rooms?.map((room, roomIndex) => (
                        <div key={roomIndex}>
                          <VerticalInputFieldCustom
                            value={room.assigned_room}
                            disabled={true}
                          />
                        </div>
                      ))}
                    </td>
                    <td className="modify_reservation_table_cell_data">
                      {items?.booked_rooms?.map((room, roomIndex) => (
                        <div key={roomIndex}>
                          <ModifyReservationSelectComponent
                            optionKeyName={"room_no"}
                            disabled={
                              !items?.available_rooms_options ||
                              items.available_rooms_options.length === 0
                            }
                            options={items?.available_rooms_options}
                            onChange={(e) =>
                              handleRoomNoChangeTransferRoom(
                                e,
                                changeRoomNumberIndex,
                                roomIndex
                              )
                            }
                            selectedOptions={
                              items.change_room_no ? items.change_room_no : []
                            }
                            value={
                              items.change_room_no
                                ? items.change_room_no[roomIndex] || ""
                                : ""
                            }
                          />
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="modify_reservation_reason_container">
              <VerticalInputFieldCustom
                titleName={"Reason to Modify*"}
                name="changeRoomCategoryReason"
                onChange={handleReasonChange}
              />

              <div className="modify_reservation_reason_container flex flex_gap_10 align_items_center">
                <label>New Total:</label>

                <VerticalInputFieldCustom
                  value={bookingData.grand_total}
                  disabled={true}
                />

                <div>Previous Total : {bookingData?.grand_total}</div>
              </div>
            </div>

            <>
              {/* buttons for new room rate */}

              <div className="flex flex_gap_10">
                <BgThemeButton
                  children={"Modify"}
                  onClick={handleTransferRoomSubmit}
                />
                <BgOutlineThemeButton
                  children={"Cancel"}
                  onClick={() => setShowModifyPopup(false)}
                />
              </div>
            </>
          </div>
        </>
      )}
    </div>
  );
};

export default ModifyReservationArrivalsChangeRoomCategory;
