import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Button,
  Icon,
  Tooltip,
} from "@shopify/polaris";
import { Link, useLocation } from "react-router-dom";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomCssComponentTableText from "../../CustomComponents/CustomCssComponentTableText";
import StatusUpdate from "../../CustomComponents/StatusUpdate";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import SidebarInnerLists from "../../CustomComponents/SidebarInnerLists";
// import {
//   other_select_nav_items_inventory,
//   rooms_inner_lists,
// } from "../../assets/constant";
import {
  other_select_nav_items_inventory,
  rooms_inner_lists,
} from "../../../assets/constant";
import Loader from "../../UI/Loader/Loader";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  WithoutBgButtonBlue,
} from "../../UI/Buttons";
import VerticalInputFieldCustom from "../../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../../CustomComponents/VerticalInputSelectCustomCopy";
import CustomPagination from "../../CustomComponents/CustomPagination/CustomPagination";
import NoDataFound from "../../CustomComponents/NoDataFound";

import UnderscoreRemoveCapitalize from "../../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";

const TableViewChefDashBoard = () => {
  const navigate = useNavigate();
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
  const [editRooms, setEditRooms] = useState(false);

  const [showLowerMenuAddMenu, setShowLowerMenuAddMenu] = useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [orders, setOrders] = useState([]);
  // fetch data
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);

  // Pagignation
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(orders.length / itemsPerPage);
    setCurrentPage(newPage > totalPages ? totalPages : newPage);
  };

  const sortMethods = {
    none: { method: (a, b) => null },
    ascendingRooms: { method: undefined },
    sortedRooms: {
      method: (a, b) => {
        const nameComparison = a?.room_name?.localeCompare(b?.room_name);

        if (nameComparison !== 0) {
          return nameComparison;
        }
        return a?.room_no - b?.room_no;
      },
    },
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders =
    orders && orders.length > 0
      ? orders
          .sort(sortMethods["sortedRooms"].method)
          .slice(startIndex, endIndex)
      : [];

  const fetchInfo = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/restaurant/table_view/fetch/${propertyId}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   property_id: property_id,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  // const chargesForDate = () => {Object.keys(viewFolioBillsData.charges).map((date) => {
  const [fetchedRoomType, setFetchedRoomType] = useState([]);

  const fetchRoomType = async (propertyId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setFetchedRoomType(data.rooms);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      // fetchRoomType(property_id?.id);
    }
  }, [property_id]);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(currentOrders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else {
      setAddItemData({
        room_name: "",
        room_no: "",
        floor_no: "",
        status: "clean",
        room_id: fetchedRoomType ? fetchedRoomType[0]?.id : "",
        property_id: property_id?.id,
      });
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const getOneData = async (id) => {
    setEditId(id);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit_table/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        table_no: data.tabledata.table_no,
        status: data.tabledata.status,
      };
      setCategoriesData(updatedData);
      setEditRooms(true);
    } catch (error) {
      console.error(error);
    }
  };

  const rowMarkup = currentOrders?.map(
    ({ id, room_no, room_name, floor_no, status, index }) => {
      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={id}
        >
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={room_no}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={room_name}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={floor_no}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <StatusUpdate status={status}></StatusUpdate>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const roomTypeOptions = fetchedRoomType?.map((roomType) => {
    return { label: roomType.room_name, value: roomType.id };
  });

  // UseState for forms
  const [addItemData, setAddItemData] = useState({
    service: "",
    description: "",
    rate: "",
    quantity: "",
    total_amount: "",
    sub_total: "",
    discount: "",
    gst_type: "",
    gst: "",
  });

  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      [event.target.name]: event.target.value,
    });
  };
  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/delete/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          clearSelection();
          fetchInfo(property_id?.id);
          clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);

          const totalPages = Math.ceil(orders.length / itemsPerPage);
          if (currentPage > totalPages) {
            handlePageChange(totalPages);
          }
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [editId, setEditId] = useState(null);

  const updateFunction = async (id) => {
    if (categoriesData.table_no === "") {
      showErrorToast("Please Fill the Table No. ...!!!");
      return;
    }
    if (categoriesData.status === "") {
      showErrorToast("Please Select the Table Status ...!!!");
      return;
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/update_table/${id}/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify(categoriesData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        fetchInfo(property_id?.id);
        setShowLowerMenuSingleSelect(false);
        clearSelection();
        showSuccessToast(data.message);
        setEditRooms(false);
      } else if (data.error) {
        const firstErrorKey = Object.keys(data.error)[0];
        const firstErrorMessage = data.error[firstErrorKey][0];
        showErrorToast(firstErrorMessage);
        fetchInfo(property_id?.id);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    clearSelection();
  };

  // ...............................
  const tabs = [
    {
      title: "Table View",
      link: "table-view",
    },
    {
      title: "Room View",
      link: "room-view",
    },
  ];
  const location = useLocation();
  const activeTab = tabs.findIndex((tab) =>
    location.pathname.includes(tab.link)
  );

  const statusOptions = [
    { label: "Select Option", value: "" },
    { label: "Occupied", value: "occupied" },
    { label: "Vacant", value: "vacant" },
  ];
  const [categoriesData, setCategoriesData] = useState({
    table_no: "",
    status: "",
  });

  const setAddItemDataHandleChangeTables = (event) => {
    setCategoriesData({
      ...categoriesData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitAddTable = async (event) => {
    event.preventDefault();
    if (categoriesData.table_no === "") {
      showErrorToast("Please Fill the Table No. ...!!!");
      return;
    }
    if (categoriesData.status === "") {
      showErrorToast("Please Select the Table Status ...!!!");
      return;
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create_table/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({
            ...categoriesData,
            property_id: property_id?.id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        setShowLowerMenuSingleSelect(false);
        setShowLowerMenuMultipleSelect(false);
        // clearSelection();
        showSuccessToast(data.message);
        setCategoriesData({
          table_no: "",
          status: "",
        });
      } else if (data.error) {
        const firstErrorKey = Object.keys(data.error)[0];
        const firstErrorMessage = data.error[firstErrorKey][0];
        showErrorToast(firstErrorMessage);
        fetchInfo(property_id?.id);
        setLoader(false);
        // setShowAddCategoriesLowerMenuActive(false);
        // return;
      } else {
        showErrorToast(data.message);
        setLoader(false);
        // setShowAddCategoriesLowerMenuActive(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoader(false);
      showErrorToast("An error occurred while processing your request.");
      // setShowAddCategoriesLowerMenuActive(false);
    }
  };

  return (
    <div className="main_container">
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="">
            <div
              className="custom_tab_container"
              // style={{ marginBottom: "20px" }}
            >
              <div className="custom_tab_header">
                {tabs.map((tab, index) => (
                  <Link
                    to={`/chef-dashboard/${tab.link}`}
                    className={`tab_item ${
                      index === activeTab ? "active" : ""
                    }`}
                    key={index}
                  >
                    {tab.title}
                  </Link>
                ))}
              </div>
            </div>
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              onClick={() => [setShowLowerMenuMultipleSelect(true)]}
            >
              <WithoutBgButtonBlue>Add</WithoutBgButtonBlue>
            </div>

            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                borderRadius: "4px",
              }}
            >
              <div style={{ display: "flex", paddingTop: "10px" }}>
                {orders &&
                  orders.map((room) => {
                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            padding: "10px 20px",
                            paddingTop: "0",
                            // borderBottom: "0.5px solid #CCC",
                          }}
                        >
                          <div
                            onClick={() => getOneData(room.id, room.id)}
                            style={{
                              width: "80px",
                              height: "45px",
                              // backgroundColor: "#BD941B",
                              backgroundColor:
                                room.status === "occupied"
                                  ? "#BD941B"
                                  : "rgba(21, 170, 18, 1)",
                              color: "white",
                              fontSize: "10px",
                              fontWeight: "500",
                              borderRadius: "4px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              //   cursor: "pointer",
                              //   flexDirection: "column",
                            }}
                            className="hoverOnRoom"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                width: "100%",
                                height: "100%",
                                lineHeight: "12px",
                                textAlign: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "600",
                              }}
                            >
                              <p>{room.table_no}</p>
                              <p>{room.net_total}</p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* Edit Button Click */}
          {editRooms && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Edit Table
                    </div>
                    <div
                      onClick={() => setEditRooms(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        titleName="Table No."
                        required={true}
                        name="table_no"
                        className="textfield"
                        onChange={(e) => setAddItemDataHandleChangeTables(e)}
                        focused={false}
                        type={"number"}
                        value={categoriesData.table_no}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        options={statusOptions}
                        titleName="Status"
                        name="status"
                        value={categoriesData.status}
                        onChange={(e) => setAddItemDataHandleChangeTables(e)}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={(e) => updateFunction(editId)}
                        type="submit"
                        children={"Update"}
                      />
                      <Button onClick={() => setEditRooms(false)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
          {showLowerMenuMultipleSelect && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => handleSubmitAddTable(e)}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Add Table
                    </div>
                    <div
                      onClick={() => setShowLowerMenuMultipleSelect(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        titleName="Table No."
                        required={true}
                        name="table_no"
                        className="textfield"
                        onChange={(e) => setAddItemDataHandleChangeTables(e)}
                        focused={false}
                        type={"number"}
                        value={categoriesData.table_no}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        options={statusOptions}
                        titleName="Status"
                        name="status"
                        value={categoriesData.status}
                        onChange={(e) => setAddItemDataHandleChangeTables(e)}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton type="submit" children={"Add"} loader={loader}/>
                      <Button
                        onClick={() => setShowLowerMenuMultipleSelect(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={deleteData} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default TableViewChefDashBoard;
