import {
  Button,
  Icon,
  IndexTable,
  LegacyCard,
  Page,
  Select,
  TextField,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { DeleteMajor } from "@shopify/polaris-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import Loader from "./Loader/Loader";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { BgOutlineThemeButton, BgThemeButton } from "./Buttons";
import NoDataFound from "../CustomComponents/NoDataFound";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import LoaderSmall from "./Loader/LoaderSmall";

const EventCategories = ({
  setShowAddUnitsMenuActive,
  setShowAddDepartmentMenuActive,
  setShowAddModelMenuActive,
}) => {
  const category = [
    {
      id: 1,
      property_id: 1,
      category_name: "Wedding",
    },
    {
      id: 2,
      property_id: 1,
      category_name: "Corporate Meeting",
    },
    {
      id: 3,
      property_id: 1,
      category_name: "Party",
    },
  ];
  const [categories, setCategories] = useState([]);

  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [categoriesData, setCategoriesData] = useState({
    category_name: "",
  });

  const [
    showAddCategoriesLowerMenuActive,
    setShowAddCategoriesLowerMenuActive,
  ] = useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const resourceName = {
    singular: "category",
    plural: "categories",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(categories);

  const addCategoriesRowMarkup = categories?.map(
    ({ id, category_name, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{category_name}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );
  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowAddCategoriesLowerMenuActive(false);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const setAddItemDataHandleChange = (event) => {
    setCategoriesData({
      ...categoriesData,
      [event.target.name]: event.target.value,
    });
  };

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/event/category/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setCategories(data.categories);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
    }
  }, [property_id]);

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/event/category/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        category_name: data.category.category_name,
      };
      setCategoriesData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const updateFunction = async (id) => {
    if (categoriesData.category_name === "") {
      showErrorToast("Please Fill the department name...!!!");
      return;
    }
    const findDepartment = categories.find((category) => category.id === id);
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/event/category/update/${id}/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify(categoriesData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        fetchInfo(property_id?.id);
        setShowLowerMenuSingleSelect(false);
        clearSelection();
        showSuccessToast(data.message);
      } else if (data.error) {
        const firstErrorKey = Object.keys(data.error)[0];
        const firstErrorMessage = data.error[firstErrorKey][0];
        showErrorToast(firstErrorMessage);
        fetchInfo(property_id?.id);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const handleSubmitAddDepartment = async (event) => {
    event.preventDefault();
    if (categoriesData.category_name === "") {
      showErrorToast("Please Fill the department name...!!!");
      return;
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create/event/category/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({
            ...categoriesData,
            property_id: property_id?.id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        setShowLowerMenuSingleSelect(false);
        setShowAddCategoriesLowerMenuActive(false);
        clearSelection();
        showSuccessToast(data.message);
        setCategoriesData({
          category_name: "",
        });
      } else if (data.error) {
        const firstErrorKey = Object.keys(data.error)[0];
        const firstErrorMessage = data.error[firstErrorKey][0];
        showErrorToast(firstErrorMessage);
        fetchInfo(property_id?.id);
        // return;
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/event/category/delete/${property_id?.id}`,
        {
          method: "DELETE",
          body: JSON.stringify(deletedIds),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        clearSelection();
        setShowLowerMenuMultipleSelect(false);
        setShowModelMenuActive(false);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };

  const handleOpenAddCategoryClick = () => {
    clearSelection();
    setShowAddCategoriesLowerMenuActive(!showAddCategoriesLowerMenuActive);
    setShowLowerMenuMultipleSelect(false);
    setShowLowerMenuSingleSelect(false);
    setCategoriesData({
      category_name: "",
    });
  };

  return (
    <>
      <div className="bg-backdrop">
        <section className="agent-add-categories-model-container">
          <LegacyCard>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: "10px",
              }}
            >
              <div>
                <Page
                  backAction={{ content: "Products", url: `/events` }}
                  title="Event Categories"
                  compactTitle
                ></Page>
              </div>
              <div>
                <BgThemeButton
                  onClick={() => handleOpenAddCategoryClick()}
                  children={"Add Category"}
                />
              </div>
            </div>
            <div className="overflow_y_scroll_x_hidden"
              style={{
                padding: "10px",
                maxHeight: "300px",
              }}
            >
              {loader ? (
                <LoaderSmall />
              ) : (
                <>
                  {categories && categories.length > 0 ? (
                    <IndexTable
                      resourceName={resourceName}
                      itemCount={categories.length}
                      selectedItemsCount={
                        allResourcesSelected ? "All" : selectedResources.length
                      }
                      onSelectionChange={handleSelectionChange}
                      headings={[{ title: "Categories" }]}
                    >
                      {addCategoriesRowMarkup}
                    </IndexTable>
                  ) : (
                    <NoDataFoundSmall />
                  )}
                </>
              )}
            </div>

            {/* Single select */}
            {showLowerMenuSingleSelect && (
              <div className="room-type-bottom-container">
                <div className="room-type-bottom-container-popup-div">
                  <div>
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="room-type-bottom-div">
                      <div>Category Name</div>
                      <div style={{ width: "200px" }}>
                        <VerticalInputFieldCustom
                          // required={true}
                          name="category_name"
                          className="textfield"
                          onChange={(e) => setAddItemDataHandleChange(e)}
                          focused={false}
                          value={categoriesData.category_name}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <BgThemeButton
                      onClick={() => updateFunction(selectedResources[0])}
                      type="submit"
                      children={"Update"}
                    />
                  </form>
                </div>
              </div>
            )}

            {/* Multiple select */}
            {showLowerMenuMultipleSelect && (
              <div className="room-type-bottom-container">
                <div className="room-type-bottom-container-popup-div">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {/* Add Button Click */}
            {showAddCategoriesLowerMenuActive && (
              <div className="room-type-bottom-container">
                <div className="room-type-bottom-container-popup-div">
                  <form
                    onSubmit={(e) => handleSubmitAddDepartment(e)}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="room-type-bottom-div">
                      <div style={{ marginLeft: "10px" }}>Category Name</div>
                      <div style={{ width: "150px" }}>
                        <VerticalInputFieldCustom
                          name="category_name"
                          className="textfield"
                          onChange={(e) => setAddItemDataHandleChange(e)}
                          focused={false}
                          value={categoriesData.category_name}
                          autoComplete="off"
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        color: "#3968ED",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton children={"Add"} type="submit" />
                      <BgOutlineThemeButton
                        onClick={() => {
                          setShowAddCategoriesLowerMenuActive(false);
                          setCategoriesData({
                            category_name: "",
                          });
                        }}
                      >
                        Cancel
                      </BgOutlineThemeButton>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </LegacyCard>
        </section>
      </div>

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button onClick={showModelMenu}>Discard</Button>
                <Button onClick={() => deleteData()} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default EventCategories;
