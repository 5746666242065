import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { calculateTotalWithReduce, formatIndianCurrency } from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";

const ReportCategoryWiseReport = ({ departureReport, loader }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkupAllFoliosCustom = departureReport?.map(
    ({ id, count, order_no, order_type, net_total, date, index }) => {
      return (
        <CustomIndexTableRow
          id={id}
          key={id}
          // selectedItems={selectedArray} // send for checking the check box is checked
          // setSelectedItems={setSelectedArray}
          showCheckbox={false} // show the check box
        >
          <CustomIndexTableCell>{id}</CustomIndexTableCell>

          <CustomIndexTableCell>{count}</CustomIndexTableCell>
          <CustomIndexTableCell>
            {formatIndianCurrency(id)}
          </CustomIndexTableCell>
        </CustomIndexTableRow>
      );
    }
  );

  const handleDownload = () => {
    const html2pdf = require("html2pdf.js");
    const originalTable = document.getElementById("receipt");
    const clonedTable = originalTable.cloneNode(true);

    clonedTable.style.fontSize = "12px";
    clonedTable.style.fontFamily = "Arial, sans-serif";
    clonedTable.style.lineHeight = "1.5";
    clonedTable.style.margin = "auto";

    const tempContainer = document.createElement("div");
    tempContainer.appendChild(clonedTable);

    html2pdf()
      .from(tempContainer)
      .set({
        margin: [0.5, 0.5, 0.5, 0.5], // Adjust margin as needed
        filename: "receipt.pdf",
        image: { type: "jpeg", quality: 5 }, // Use higher quality for images
        html2canvas: { scale: 1 }, // Increase scale for higher resolution
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" }, // Adjust PDF format and orientation as needed
      })
      .save();
  };

  const downloadCSV = () => {
    // Prepare CSV content
    const csvHeaders = "Category,Item,Count,Sale";
    const csvContent = `${csvHeaders}\n${departureReport
      .map((category) =>
        category.items
          .map((item) =>
            [category.category, item.item, item.count, item.sale].join(",")
          )
          .join("\n")
      )
      .join("\n")}`;

    // Create a blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element to trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // console.log(departureReport);

  const rowMarkup = departureReport?.map(
    ({
      id,
      request_id,

      issue_item_date,
      item_category,
      department_name,
      issue_to,
      quantity,
      unique_purchase_id,
      total_amount,
      item_name,
      unit_name,
      status,
      purchase_amount,
      purchase_quantity,
      issue_amount,
      remain_amount
    }) => (
      <CustomReportTableRow
        CustomReportTableRow
        id={id}
        key={id}
        // bodyStyles={{
        //   backgroundColor: "red",
        //   fontSize: "10px",
        // }}
      >
        <CustomReportTableCell>
          {item_category ? item_category : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(purchase_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(issue_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {" "}
          {formatIndianCurrency(remain_amount)}
        </CustomReportTableCell>
      </CustomReportTableRow>
    )
  );
  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell>{formatIndianCurrency(calculateTotalWithReduce(departureReport, "purchase_amount"))}</CustomReportTableCell>
      <CustomReportTableCell>{formatIndianCurrency(calculateTotalWithReduce(departureReport, "issue_amount"))}</CustomReportTableCell>
      <CustomReportTableCell>{formatIndianCurrency(calculateTotalWithReduce(departureReport, "remain_amount"))}</CustomReportTableCell>
    </CustomReportTableRow>
  );
  const reportIntro = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell>{formatIndianCurrency(calculateTotalWithReduce(departureReport, "purchase_amount"))}</CustomReportTableCell>
      <CustomReportTableCell>{formatIndianCurrency(calculateTotalWithReduce(departureReport, "purchase_amount"))}</CustomReportTableCell>
      <CustomReportTableCell>{formatIndianCurrency(calculateTotalWithReduce(departureReport, "purchase_amount"))}</CustomReportTableCell>
    </CustomReportTableRow>
  );
  const extractedData = departureReport?.map(item => ({
    item_category: item && item?.item_category ? item?.item_category : 'N/A',
    purchase_amount: item &&  item?.purchase_amount ? item?.purchase_amount : 'N/A',
    issue_amount: item &&  item?.issue_amount ? item?.issue_amount : 'N/A',
    remain_amount: item &&  item?.remain_amount ? item?.remain_amount : 'N/A',
  }));

  const names = [
    {
      item_category: "Category",
      purchase_amount: "Purchased Amount (₹)	",
      issue_amount: "Issued Amount (₹)	",
      remain_amount:  "Remaining Stock Amount (₹)",
    },
  ];
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div id="receipt" className="room-types-full-container reports-new-background">
          <div className="room-types-full-container-div">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div className="room-type-heading">Category wise Report</div>
              {departureReport.length > 0 && (
                <div className="flex flex_gap_15 align_center" id="pdf">
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Category_wise_Report"}
                    ignoreFromPrint={"pdf"}
                    // pdfStyles={

                    // }
                  />
                 <DownloadCSVButton  itemToDownloadID={"receipt"}
                    downloadFileName={"Category_wise_Report"}/>
                </div>
              )}
            </div>
          </div>
          <div>
            {departureReport && departureReport.length > 0 ? (
              <>
                {/* {departureReport &&
                  departureReport.length > 0 &&
                  departureReport.map((order) => {
                    return (
                      <div className="mt-20">
                        <div
                          className="category_name"
                          style={{ marginBottom: "10px" }}
                        >
                          {order.category}
                        </div> */}

                <CustomReportTable
                  headings={[
                    "Category",
                    "Purchased Amount (₹)",
                    "Issued Amount (₹)",
                    "Remaining Stock Amount (₹)",
                  ]}
                  // commonHeadings={[
                  //   "",
                  //   "",
                  //   "",
                  //   "",
                  //   "",
                  //   "",
                  //   "Requested by",
                  //   "Requested by",
                  //   "",
                  // ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  
                  {rowMarkup}
                  {calculateTotals}
                </CustomReportTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ReportCategoryWiseReport;
