import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
const ItemIssueSearch = (props) => {
  const [kotID, setKotID] = useState("");
  const { sendDataToParent } = props;

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);

  useEffect(() => {
    sendDataToParent(kotID);
    if (kotID === null || kotID === "") {
    }
  }, [kotID]);
  return (
    <div className="booking_search_content" style={{ height: "32px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="#666666"
      >
        <path
          d="M13.5233 12.4628L16.7355 15.6742L15.6742 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0187 11.9062C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.34975 11.1503 3 8.25 3C5.34975 3 3 5.34975 3 8.25C3 11.1503 5.34975 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9062 12.0187L12.0187 11.9062Z"
          fill="#666666"
        />
      </svg>
      <input
        style={{}}
        type="text"
        name="kotID"
        id=""
        onKeyDown={props.onKeyDown}
        onChange={(e) => setKotID(e.target.value)}
        placeholder={props.placeholder}
        value={kotID}
      />
      {kotID && (
        <div
          onClick={() => [
            props.clearFunction(null, null, "clear"),
            setKotID(""),
          ]}
          style={{ padding: "3px", cursor: "pointer" }}
          type="button"
        >
          &times;
        </div>
      )}
    </div>
  );
};
export default ItemIssueSearch;
