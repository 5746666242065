// import React, { useEffect, useState } from "react";
// import "./StayViewNew.css";
// import { useSelector } from "react-redux";
// import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
// import {
//   other_select_nav_items_inventory,
//   rooms_inner_lists,
//   stay_reservation_views,
// } from "../../assets/constant";
// import { showErrorToast } from "../../assets/toastUtils";
// import BookingDetailSliderNew from "../CustomComponents/BookingDetails/BookingDetailsSliderNew";
// import { calculateToDateMinus, calculateToDatePlus } from "../../utils/utils";
// import { Link, useLocation } from "react-router-dom";
// import Loader from "../UI/Loader/Loader";

// const StayViewNew = () => {
//   const [openHoldBookingDetails, setOpenHoldBookingDetails] = useState(false);

//   const location = useLocation();
//   const userToken = JSON.parse(localStorage.getItem("userToken"));
//   const [data, setData] = useState([]);
//   const property_id = useSelector((state) => state.property.value);
//   const [loader, setLoader] = useState(false);

//   const [masterFolioDetails, setMasterFolioDetails] = useState(null);

//   const permissions = JSON.parse(localStorage.getItem("permissions"));

//   const [openSingleBookingDetails, setOpenSingleBookingDetails] =
//     useState(false);

//   const [chargesDetails, setChargesDetails] = useState(null);
//   const [bookingDetails, setBookingDetails] = useState(null);

//   const getSingleBookingDataHandler = (bookingId) => {
//     setLoader(true);
//     fetch(
//       `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
//       {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${userToken}`,
//         },
//       }
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.success) {
//           setOpenSingleBookingDetails(true);
//           setLoader(false);
//           setBookingDetails(data?.booking);
//           setChargesDetails(data?.charges);
//           setMasterFolioDetails(data?.masterFolio);
//         } else {
//           setLoader(false);
//           showErrorToast(data.message);
//         }
//       })
//       .catch((error) => {
//         setLoader(false);
//         console.error("Error:", error);
//         showErrorToast("An error occurred while processing your request.");
//       });
//   };

//   const fetchInfo = async (id, date, filterType) => {
//     try {
//       setLoader(true);
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/view/fetch/${id}/stay-view?currentDate=${date}&filterType=${filterType}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );
//       const data = await response.json();
//       setData(data?.data);
//       setLoader(false);
//     } catch (error) {
//       console.error(error);
//       setLoader(false);
//     }
//   };

//   const [date_range, setDateRange] = useState([]);

//   useEffect(() => {
//     if (!data || !Array.isArray(data) || data.length === 0) {
//       setDateRange(undefined);
//       return;
//     }

//     const date_range = data.map((view) =>
//       view?.rooms?.map((room) =>
//         room?.booking?.map((booking) => booking?.booking_date)
//       )
//     );

//     if (
//       !date_range[0] ||
//       !Array.isArray(date_range[0]) ||
//       date_range[0].length === 0
//     ) {
//       setDateRange(undefined);
//       return;
//     }

//     setSelectedLastDate(date_range[0][0][date_range[0][0].length - 1]);
//     setSelectedFirstDate(date_range[0][0][0]);

//     setDateRange(date_range[0][0]);
//   }, [data]);

//   const currentDate = new Date().toISOString().split("T")[0];

//   useEffect(() => {
//     if (property_id) {
//       fetchInfo(property_id?.id, calculateToDateMinus(currentDate, 8), "next");
//     }
//   }, [property_id]);

//   function formatDate(inputDate) {
//     const dateObj = new Date(inputDate);
//     const monthNames = [
//       "January",
//       "Feb",
//       "March",
//       "April",
//       "May",
//       "June",
//       "July",
//       "August",
//       "September",
//       "October",
//       "November",
//       "December",
//     ];

//     const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

//     const day = dateObj.getDate();
//     const month = monthNames[dateObj.getMonth()];
//     const dayOfWeek = dayNames[dateObj.getDay()];

//     const formattedDate = `${String(day).padStart(
//       2,
//       "0"
//     )} ${month} ${dayOfWeek}`;

//     return formattedDate;
//   }

//   const myData = {
//     date_range: [
//       "2024-03-01",
//       "2024-03-02",
//       "2024-03-03",
//       "2024-03-04",
//       "2024-03-05",
//       "2024-03-06",
//       "2024-03-07",
//       "2024-03-08",
//       "2024-03-09",
//       "2024-03-10",
//       "2024-03-11",
//       "2024-03-12",
//       "2024-03-13",
//       "2024-03-14",
//       "2024-03-15",
//     ],
//     data: [
//       {
//         room_category: "Deluxe",
//         rooms: [
//           {
//             room_no: "101",
//             booking: [
//               {
//                 booking_date: "2024-03-01",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-02",
//                 booking: [
//                   {
//                     booking_id: "b5678",
//                     booking_date: "2024-03-02",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-02",
//                     check_out_date: "2024-03-05",
//                     status: "checked_in",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-03",
//                 booking: [
//                   {
//                     booking_id: "b5678",
//                     booking_date: "2024-03-03",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-02",
//                     check_out_date: "2024-03-05",
//                     status: "checked_in",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-04",
//                 booking: [
//                   {
//                     booking_id: "b5678",
//                     booking_date: "2024-03-04",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-02",
//                     check_out_date: "2024-03-05",
//                     status: "checked_in",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-05",
//                 booking: [
//                   {
//                     booking_id: "b5678",
//                     booking_date: "2024-03-05",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-02",
//                     check_out_date: "2024-03-05",
//                   },
//                   {
//                     booking_id: "b5678",
//                     booking_date: "2024-03-05",
//                     unique_booking_id: "777",
//                     check_in_date: "2024-03-05",
//                     check_out_date: "2024-03-06",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-06",
//                 booking: [
//                   {
//                     booking_id: "b5678",
//                     booking_date: "2024-03-06",
//                     unique_booking_id: "777",
//                     check_in_date: "2024-03-05",
//                     check_out_date: "2024-03-06",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-07",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-08",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-09",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-10",
//                 booking: [
//                   {
//                     booking_id: "b181925",
//                     booking_date: "2024-03-10",
//                     unique_booking_id: "ub181920",
//                     check_in_date: "2024-03-10",
//                     check_out_date: "2024-03-11",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-11",
//                 booking: [
//                   {
//                     booking_id: "b181925",
//                     booking_date: "2024-03-11",
//                     unique_booking_id: "ub181920",
//                     check_in_date: "2024-03-10",
//                     check_out_date: "2024-03-11",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-12",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-13",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-14",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-15",
//                 booking: [
//                   {
//                     booking_id: "b484950",
//                     booking_date: "2024-03-15",
//                     unique_booking_id: "ub484950",
//                     check_in_date: "2024-03-15",
//                     check_out_date: "2024-03-16",
//                   },
//                 ],
//               },
//             ],
//           },
//           {
//             room_no: "102",
//             booking: [
//               {
//                 booking_date: "2024-03-01",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-02",
//                 booking: [
//                   {
//                     booking_id: "b5678",
//                     booking_date: "2024-03-02",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-02",
//                     check_out_date: "2024-03-04",
//                     status: "confirmed",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-03",
//                 booking: [
//                   {
//                     booking_id: "b5678",
//                     booking_date: "2024-03-03",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-02",
//                     check_out_date: "2024-03-04",
//                     status: "confirmed",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-04",
//                 booking: [
//                   {
//                     booking_id: "b5678",
//                     booking_date: "2024-03-04",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-02",
//                     check_out_date: "2024-03-04",
//                     status: "confirmed",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-05",
//                 booking: [
//                   {
//                     booking_id: "b567877",
//                     booking_date: "2024-03-05",
//                     unique_booking_id: "888",
//                     check_in_date: "2024-03-05",
//                     check_out_date: "2024-03-06",
//                     status: "checked_in",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-06",
//                 booking: [
//                   {
//                     booking_id: "b567877",
//                     booking_date: "2024-03-05",
//                     unique_booking_id: "888",
//                     check_in_date: "2024-03-05",
//                     check_out_date: "2024-03-06",
//                     status: "checked_in",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-07",
//                 booking: [
//                   {
//                     booking_id: "b567877",
//                     booking_date: "2024-03-05",
//                     unique_booking_id: "888",
//                     check_in_date: "2024-03-05",
//                     check_out_date: "2024-03-06",
//                     status: "",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-08",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-09",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-10",
//                 booking: [
//                   {
//                     booking_id: "b181925",
//                     booking_date: "2024-03-10",
//                     unique_booking_id: "ub181920",
//                     check_in_date: "2024-03-10",
//                     check_out_date: "2024-03-11",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-11",
//                 booking: [
//                   {
//                     booking_id: "b181925",
//                     booking_date: "2024-03-11",
//                     unique_booking_id: "ub181920",
//                     check_in_date: "2024-03-10",
//                     check_out_date: "2024-03-11",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-12",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-13",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-14",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-15",
//                 booking: [
//                   {
//                     booking_id: "b484950",
//                     booking_date: "2024-03-15",
//                     unique_booking_id: "ub484950",
//                     check_in_date: "2024-03-15",
//                     check_out_date: "2024-03-16",
//                   },
//                 ],
//               },
//             ],
//           },
//         ],
//       },
//       {
//         room_category: "Cottage",
//         rooms: [
//           {
//             room_no: "200",
//             booking: [
//               {
//                 booking_date: "2024-03-01",
//                 booking: [
//                   {
//                     booking_id: "b5678",
//                     booking_date: "2024-03-02",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-01",
//                     check_out_date: "2024-03-03",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-02",
//                 booking: [
//                   {
//                     booking_id: "b5678",
//                     booking_date: "2024-03-02",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-01",
//                     check_out_date: "2024-03-03",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-03",
//                 booking: [
//                   {
//                     booking_id: "b5678",
//                     booking_date: "2024-03-03",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-01",
//                     check_out_date: "2024-03-03",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-04",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-05",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-06",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-07",
//                 booking: [
//                   {
//                     booking_id: "14455",
//                     booking_date: "2024-03-07",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-05",
//                     check_out_date: "2024-03-07",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-08",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-09",
//                 booking: [
//                   {
//                     booking_id: "b181925",
//                     booking_date: "2024-03-09",
//                     unique_booking_id: "ub181920",
//                     check_in_date: "2024-03-09",
//                     check_out_date: "2024-03-11",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-10",
//                 booking: [
//                   {
//                     booking_id: "b181925",
//                     booking_date: "2024-03-10",
//                     unique_booking_id: "ub181920",
//                     check_in_date: "2024-03-09",
//                     check_out_date: "2024-03-11",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-11",
//                 booking: [
//                   {
//                     booking_id: "b181925",
//                     booking_date: "2024-03-11",
//                     unique_booking_id: "ub181920",
//                     check_in_date: "2024-03-10",
//                     check_out_date: "2024-03-11",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-12",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-13",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-14",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-15",
//                 booking: [],
//               },
//             ],
//           },
//           {
//             room_no: "201",
//             booking: [
//               {
//                 booking_date: "2024-03-01",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-02",
//                 booking: [
//                   {
//                     booking_id: "b5678",
//                     booking_date: "2024-03-02",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-01",
//                     check_out_date: "2024-03-03",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-03",
//                 booking: [
//                   {
//                     booking_id: "b5678",
//                     booking_date: "2024-03-03",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-01",
//                     check_out_date: "2024-03-03",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-04",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-05",
//                 booking: [
//                   {
//                     booking_id: "14455",
//                     booking_date: "2024-03-05",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-05",
//                     check_out_date: "2024-03-07",
//                     status: "confirmed",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-06",
//                 booking: [
//                   {
//                     booking_id: "14455",
//                     booking_date: "2024-03-06",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-05",
//                     check_out_date: "2024-03-07",
//                     status: "confirmed",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-07",
//                 booking: [
//                   {
//                     booking_id: "14455",
//                     booking_date: "2024-03-07",
//                     unique_booking_id: "ub5678",
//                     check_in_date: "2024-03-05",
//                     check_out_date: "2024-03-07",
//                     status: "confirmed",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-08",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-09",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-10",
//                 booking: [
//                   {
//                     booking_id: "b181925",
//                     booking_date: "2024-03-10",
//                     unique_booking_id: "ub181920",
//                     check_in_date: "2024-03-10",
//                     check_out_date: "2024-03-11",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-11",
//                 booking: [
//                   {
//                     booking_id: "b181925",
//                     booking_date: "2024-03-11",
//                     unique_booking_id: "ub181920",
//                     check_in_date: "2024-03-10",
//                     check_out_date: "2024-03-11",
//                   },
//                 ],
//               },
//               {
//                 booking_date: "2024-03-12",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-13",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-14",
//                 booking: [],
//               },
//               {
//                 booking_date: "2024-03-15",
//                 booking: [
//                   {
//                     booking_id: "b484950",
//                     booking_date: "2024-03-15",
//                     unique_booking_id: "ub484950",
//                     check_in_date: "2024-03-15",
//                     check_out_date: "2024-03-16",
//                     status: "confirmed",
//                   },
//                 ],
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   };

//   const newData = {
//     dates: ["2024-06-19", "2024-06-20", "2024-06-21", "2024-06-22"],
//     categories: [
//       {
//         name: "Deluxe Room",
//         bookings: [
//           {
//             checkin: "2024-06-19",
//             checkout: "2024-06-21",
//             name: "Booking 1",
//             roomNumber: "101",
//           },
//           {
//             checkin: "2024-06-20",
//             checkout: "2024-06-22",
//             name: "Booking 2",
//             roomNumber: "102",
//           },
//         ],
//       },
//       {
//         name: "Suit",
//         bookings: [
//           {
//             checkin: "2024-06-19",
//             checkout: "2024-06-20",
//             name: "Booking 3",
//             roomNumber: "201",
//           },
//           {
//             checkin: "2024-06-21",
//             checkout: "2024-06-22",
//             name: "Booking 4",
//             roomNumber: "202",
//           },
//         ],
//       },
//     ],
//   };

//   console.log(data);

//   const newFlat = data.map((item) => ({
//     room_category: item.room_category,
//     rooms: {
//       room_no: item.rooms.map((room) => {
//         const bookingNewData = [];
//         const bookingData = room?.booking.map(
//           (booking) =>
//             booking?.booking.length !== 0 &&
//             bookingNewData.push(booking?.booking)
//         );
//         return {
//           room_no: room.room_no,
//           bookings: bookingNewData,
//         };
//       }),
//     },
//   }));

//   console.log("newFlat", newFlat);

//   const updatedData = data.map((category) => ({
//     ...category,
//     rooms: category.rooms.map((room) => ({
//       ...room,
//       booking: room.booking.map((booking) => ({
//         ...booking,
//         booking: Array.isArray(booking.booking)
//           ? booking.booking
//           : [booking.booking],
//       })),
//     })),
//   }));

//   const flatMap = data.flatMap((item) =>
//     item.rooms.flatMap((room) => room.booking.flatMap((room) => room.booking))
//   );
//   // let uniqueArr = [...new Map(arr.map(item => [item.articleId, item])).values()];

//   const uniqueBookingArray = [
//     ...new Map(flatMap.map((item) => [item.unique_booking_id, item])).values(),
//   ];

//   // console.log("flatMap", flatMap);
//   // console.log(uniqueBookingArray, "updatedData");
//   const [booking, setBooking] = useState({
//     booking_id: "b606162",
//     booking_date: "2024-03-19",
//     unique_booking_id: "ub606162",
//     check_in_date: "2024-03-18",
//     check_out_date: "2024-03-19",
//   });

//   const isDateInRange = (dateToCheck, checkInDate, checkOutDate) => {
//     return dateToCheck >= checkInDate && dateToCheck <= checkOutDate;
//   };

//   const dateToCheck = "2024-03-20";
//   const formattedDateToCheck = dateToCheck;

//   const isDateWithinRange = isDateInRange(
//     dateToCheck,
//     booking.check_in_date,
//     booking.check_out_date
//   );

//   const checkNextData = (date) => {};

//   const [selectedLastDate, setSelectedLastDate] = useState("");
//   const [selectedFirstDate, setSelectedFirstDate] = useState("");

//   const handleNextClick = async () => {
//     await fetchInfo(property_id?.id, selectedLastDate, "next");
//     setSelectedFirstDate(selectedLastDate);
//     setSelectedLastDate(calculateToDatePlus(selectedLastDate, 15));
//   };

//   const handlePreviousClick = async () => {
//     await fetchInfo(property_id?.id, selectedFirstDate, "previous");
//     setSelectedLastDate(selectedFirstDate);
//     setSelectedFirstDate(calculateToDateMinus(selectedFirstDate, 15));
//   };

//   const [selectedCategory, setSelectedCategory] = useState(0);

//   const handleCategoryClick = (category) => {
//     setSelectedCategory(category);
//   };

//   return (
//     <div style={{ overflow: "auto" }}>
//       {/* <SidebarInnerLists innerLists={stay_reservation_views} /> */}

//       <div
//         style={{
//           display: "flex",
//           zIndex: 20,
//           justifyContent: "space-between",
//           left: "0",
//           top: "0",
//         }}
//         className="footer_inner_lists"
//       >
//         <ul className="flex flex_gap_10">
//           {permissions &&
//             stay_reservation_views &&
//             stay_reservation_views.map((item, index) => (
//               <React.Fragment key={index}>
//                 {permissions && permissions.includes(item.permission) && (
//                   <li style={{ listStyle: "none" }}>
//                     <Link
//                       to={item.link}
//                       className={`inner_list_item ${
//                         location.pathname === item.link ? "active" : ""
//                       }`}
//                       key={index}
//                     >
//                       {item.name}
//                     </Link>
//                   </li>
//                 )}
//               </React.Fragment>
//             ))}
//         </ul>
//         <div className="flex flex_gap_10" style={{ marginRight: "20px" }}>
//           <div
//             onClick={handlePreviousClick}
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               fontSize: "14px",
//             }}
//             className="pointer"
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="20"
//               height="20"
//               viewBox="0 0 8 13"
//               fill="none"
//             >
//               <path
//                 d="M7 1.50003L2 6.50003L7 11.5"
//                 stroke="#333333"
//                 stroke-width="1.5"
//               />
//             </svg>
//           </div>
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//             className="pointer"
//             onClick={handleNextClick}
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="20"
//               height="20"
//               viewBox="0 0 8 13"
//               fill="none"
//             >
//               <path
//                 d="M1 11.5L6 6.50003L0.999999 1.50003"
//                 stroke="#333333"
//                 stroke-width="1.5"
//               />
//             </svg>
//           </div>
//         </div>
//       </div>
//       {/* <div id="table-wrapper">
//           <div id="table-scroll"> */}

//       <div className="booking-choice-full-parent-container-right-heading-new">
//         <div
//           style={{
//             width: "100%",
//             backgroundColor: "#EFF0F2",
//             marginTop: "10px",
//           }}
//         >
//           <div className="reservation_bar_container" style={{ width: "100%" }}>
//             <div className="reservation_total">
//               {/* <CustomDateRangeSelector
//                       style={{ padding: "43px 0px 20px" }}
//                       onSelectedRangeChange={handleSelectedRangeChange}
//                       onApplyButtonClick={handleApplyButtonClick}
//                       onDateRangeSelected={handleDateRangeSelected}
//                     /> */}

//               <div className="reservation_total confirmed">
//                 <h5 className="reservation_total_heading">
//                   {/* {bookingOuterDetails?.confirmed_bookings} */}{" "}
//                   {/* {bookingData?.all_bookings?.flatMap((item) =>
//                     item.category_bookings.filter(
//                       (booking) => booking.status === "checked_in"
//                     )
//                   ).length || 0} */}
//                   {uniqueBookingArray && uniqueBookingArray.length > 0
//                     ? uniqueBookingArray.filter(
//                         (item) => item.status === "checked_in"
//                       ).length
//                     : 0}
//                 </h5>
//                 <p className="reservation_total_paragraph">Checked-In</p>
//               </div>

//               <div className="reservation_total pending confirmed">
//                 <h5 className="reservation_total_heading">
//                   {uniqueBookingArray && uniqueBookingArray.length > 0
//                     ? uniqueBookingArray.filter(
//                         (item) => item.status === "checked_out"
//                       ).length
//                     : 0}
//                 </h5>
//                 <p className="reservation_total_paragraph">Checked-Out</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {loader ? (
//         <Loader />
//       ) : (
//         <div className="main-container-stay-view" style={{ marginTop: "10px" }}>
//           <div className="main-container-stay-view-first">
//             <div></div>
//             <div className="flex flex_gap_10">
//               {/* <div
//             onClick={() =>
//               fetchInfo(
//                 property_id?.id,
//                 calculateToDateMinus(currentDate, 15),
//                 "previous"
//               )
//             }
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//             className="pointer"
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="8"
//               height="13"
//               viewBox="0 0 8 13"
//               fill="none"
//             >
//               <path
//                 d="M7 1.50003L2 6.50003L7 11.5"
//                 stroke="#333333"
//                 stroke-width="1.5"
//               />
//             </svg>
//           </div> */}
//               {date_range?.map((item, index) => {
//                 return (
//                   <div
//                     onClick={() => fetchInfo(property_id?.id, item, "next")}
//                     className={
//                       currentDate === item
//                         ? "stay-view-date-currentDate pointer"
//                         : "stay-view-date pointer"
//                     }
//                     key={index}
//                   >
//                     <div>{formatDate(item)}</div>
//                   </div>
//                 );
//               })}

//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "center",
//                 }}
//                 className="pointer"
//                 // onClick={() =>
//                 //   fetchInfo(
//                 //     property_id?.id,
//                 //     calculateToDatePlus(currentDate, 15),
//                 //     "next"
//                 //   )
//                 // }
//               >
//                 {/* <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="8"
//               height="13"
//               viewBox="0 0 8 13"
//               fill="none"
//             >
//               <path
//                 d="M1 11.5L6 6.50003L0.999999 1.50003"
//                 stroke="#333333"
//                 stroke-width="1.5"
//               />
//             </svg> */}
//               </div>
//             </div>
//           </div>

//           <div className="main-container-stay-view-second">
//             <div>
//               {updatedData &&
//                 updatedData?.map((roomCategory, categoryIndex) => {
//                   return (
//                     <div
//                       style={{
//                         borderBottom: "0.5px solid rgba(0, 0, 0, 0.1)",
//                         padding: "4px 10px",
//                         paddingBottom: "15px",
//                       }}
//                       key={categoryIndex}
//                     >
//                       <div
//                         onClick={() => handleCategoryClick(categoryIndex)}
//                         className="stay-view-room-category pointer"
//                         style={{ display: "flex", alignItems: "center" }}
//                       >
//                         {selectedCategory === categoryIndex ? (
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             width="14"
//                             height="14"
//                             viewBox="0 0 10 10"
//                             fill="none"
//                           >
//                             <g clip-path="url(#clip0_4924_7145)">
//                               <path
//                                 d="M1.91507 0.5H8.08493C8.86645 0.5 9.5 1.13355 9.5 1.91507V8.08493C9.5 8.86645 8.86645 9.5 8.08493 9.5H1.91507C1.13355 9.5 0.5 8.86645 0.5 8.08493V1.91507C0.5 1.13355 1.13355 0.5 1.91507 0.5Z"
//                                 stroke="#3968ED"
//                               />
//                               <path
//                                 d="M7.56172 5H2.43799"
//                                 stroke="#3968ED"
//                                 stroke-miterlimit="10"
//                               />
//                             </g>
//                             <defs>
//                               <clipPath id="clip0_4924_7145">
//                                 <rect width="10" height="10" fill="white" />
//                               </clipPath>
//                             </defs>
//                           </svg>
//                         ) : (
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             width="14"
//                             height="14"
//                             viewBox="0 0 14 14"
//                             fill="none"
//                           >
//                             <g clip-path="url(#clip0_5968_9816)">
//                               <path
//                                 d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
//                                 stroke="#3968ED"
//                               />
//                               <path
//                                 d="M10.5863 7H3.41309"
//                                 stroke="#3968ED"
//                                 stroke-miterlimit="10"
//                               />
//                               <path
//                                 d="M7 3.41369L7 10.5869"
//                                 stroke="#3968ED"
//                                 stroke-miterlimit="10"
//                               />
//                             </g>
//                             <defs>
//                               <clipPath id="clip0_5968_9816">
//                                 <rect width="14" height="14" fill="white" />
//                               </clipPath>
//                             </defs>
//                           </svg>
//                         )}
//                         <span style={{ marginLeft: "5px" }}>
//                           {roomCategory.room_category}
//                         </span>
//                       </div>

//                       {/* CATEGORY */}

//                       {selectedCategory === categoryIndex && (
//                         <div className="rooms-array-container">
//                           {roomCategory.rooms.length > 0 ? (
//                             <>
//                               {roomCategory.rooms.map((room, roomIndex) => {
//                                 return (
//                                   <div
//                                     key={roomIndex}
//                                     className="flex justify_content_between"
//                                     style={{ padding: "2px 0" }}
//                                   >
//                                     <div>{room.room_no}</div>
//                                     <div
//                                       className="flex flex_gap_10"
//                                       style={{ marginRight: "10px" }}
//                                     >
//                                       {room.booking.map((booking, index) => {
//                                         const previousBooking =
//                                           room.booking[index - 1];
//                                         const nextBookingId =
//                                           room.booking[index + 1];
//                                         const currentBookingId =
//                                           room.booking[index]?.booking[0]
//                                             ?.unique_booking_id;
//                                         const checkoutDate = new Date(
//                                           booking.check_out_date
//                                         );
//                                         const currentDate = new Date();

//                                         if (
//                                           nextBookingId?.booking?.length > 0
//                                         ) {
//                                           return (
//                                             <div
//                                               className="stay-view-bookings-array"
//                                               key={index}
//                                             >
//                                               {nextBookingId?.booking
//                                                 ?.unique_booking_id ===
//                                                 booking.booking
//                                                   .unique_booking_id &&
//                                               booking?.booking.length > 0 &&
//                                               nextBookingId?.booking[0]
//                                                 ?.unique_booking_id ===
//                                                 booking.booking[0]
//                                                   .unique_booking_id ? (
//                                                 <>
//                                                   {booking.booking.length ===
//                                                     1 &&
//                                                   nextBookingId?.booking[0]
//                                                     ?.unique_booking_id ===
//                                                     booking.booking[0]
//                                                       .unique_booking_id ? (
//                                                     <div
//                                                       style={{
//                                                         width: "maxContent",
//                                                         backgroundColor:
//                                                           booking?.booking[0]
//                                                             ?.status ===
//                                                           "checked_in"
//                                                             ? "rgba(21, 170, 18, 1)"
//                                                             : booking
//                                                                 ?.booking[0]
//                                                                 ?.status ===
//                                                               "confirmed"
//                                                             ? "rgba(57, 104, 237, 1)"
//                                                             : currentDate >
//                                                               checkoutDate
//                                                             ? "rgba(255, 255, 255, 1)"
//                                                             : "rgba(189, 148, 27, 1)",
//                                                       }}
//                                                       className="stay-view-bookings-array-absolute pointer"
//                                                       onClick={() =>
//                                                         getSingleBookingDataHandler(
//                                                           currentBookingId
//                                                         )
//                                                       }
//                                                     >
//                                                       <></>
//                                                       {!previousBooking ||
//                                                       !previousBooking
//                                                         .booking[0] ||
//                                                       previousBooking.booking[0]
//                                                         ?.unique_booking_id !==
//                                                         booking.booking[0]
//                                                           ?.unique_booking_id ? (
//                                                         <div>
//                                                           {
//                                                             booking.booking[0]
//                                                               ?.booking_guest
//                                                           }
//                                                         </div>
//                                                       ) : (
//                                                         ""
//                                                       )}
//                                                     </div>
//                                                   ) : (
//                                                     <div
//                                                       style={{
//                                                         backgroundColor:
//                                                           booking?.booking[1]
//                                                             ?.status ===
//                                                           "checked_in"
//                                                             ? "rgba(21, 170, 18, 1)"
//                                                             : booking
//                                                                 ?.booking[1]
//                                                                 ?.status ===
//                                                               "confirmed"
//                                                             ? "rgba(57, 104, 237, 1)"
//                                                             : currentDate >
//                                                               checkoutDate
//                                                             ? "red"
//                                                             : "rgba(189, 148, 27, 1)",
//                                                       }}
//                                                       className="stay-view-bookings-array-absolute pointer"
//                                                       onClick={() =>
//                                                         getSingleBookingDataHandler(
//                                                           currentBookingId
//                                                         )
//                                                       }
//                                                     >
//                                                       {booking.booking
//                                                         .length === 1
//                                                         ? booking.booking[0]
//                                                             ?.unique_booking_id
//                                                         : booking.booking[1]
//                                                             ?.unique_booking_id}
//                                                     </div>
//                                                   )}
//                                                 </>
//                                               ) : (
//                                                 <div className="stay-view-bookings-array-empty"></div>
//                                               )}
//                                             </div>
//                                           );
//                                         } else {
//                                           return (
//                                             <div
//                                               className="stay-view-bookings-array-empty"
//                                               key={index}
//                                             ></div>
//                                           );
//                                         }
//                                       })}
//                                     </div>
//                                   </div>
//                                 );
//                               })}
//                             </>
//                           ) : (
//                             <div>No Rooms added.</div>
//                           )}
//                         </div>
//                       )}
//                     </div>
//                   );
//                 })}
//             </div>
//           </div>

//           {openSingleBookingDetails && (
//             // <BookingDetails
//             //   bookingDetails={bookingDetails}
//             //   setShowBookingDetails={() => setOpenSingleBookingDetails(false)}
//             // />

//             // <BookingDetailsSlider
//             //   bookingDetails={bookingDetails}
//             //   setShowBookingDetails={setOpenSingleBookingDetails}
//             // />

//             <BookingDetailSliderNew
//               data={bookingDetails}
//               chargesDetails={chargesDetails}
//               masterFolioDetails={masterFolioDetails}
//               setShowBookingDetails={setOpenSingleBookingDetails}
//               getSingleBookingDataHandler={getSingleBookingDataHandler}
//             />
//           )}
//         </div>
//       )}
//       {/* </div>
//       </div> */}
//     </div>
//   );
// };
// export default StayViewNew;
import React, { useEffect, useState } from "react";
import "./StayViewNew.css";
import { useSelector } from "react-redux";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import {
  other_select_nav_items_inventory,
  rooms_inner_lists,
  stay_reservation_views,
} from "../../assets/constant";
import { showErrorToast } from "../../assets/toastUtils";
import BookingDetailSliderNew from "../CustomComponents/BookingDetails/BookingDetailsSliderNew";
import { calculateToDateMinus, calculateToDatePlus } from "../../utils/utils";
import { Link, useLocation } from "react-router-dom";
import Loader from "../UI/Loader/Loader";
import NoDataFound from "../CustomComponents/NoDataFound";
import ReservationTooltip from "./ReservationTooltip";

const StayViewNew = () => {
  const [openHoldBookingDetails, setOpenHoldBookingDetails] = useState(false);

  const location = useLocation();
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [data, setData] = useState([]);
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);

  const [masterFolioDetails, setMasterFolioDetails] = useState(null);

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const [openSingleBookingDetails, setOpenSingleBookingDetails] =
    useState(false);

  const [chargesDetails, setChargesDetails] = useState(null);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState({});

  const getDateByMonthWeekName = (dateStr) => {
    const date = new Date(dateStr);

    // Get day, month, and weekday in required format
    const day = date.toLocaleDateString("en-GB", { day: "2-digit" });
    const month = date.toLocaleDateString("en-GB", { month: "short" });
    const weekday = date
      .toLocaleDateString("en-GB", { weekday: "short" })
      .toUpperCase();

    return `${day} ${month} ${weekday}`;
  };

  const getSingleBookingDataHandler = (bookingId) => {
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOpenSingleBookingDetails(true);
          setLoader(false);
          setBookingDetails(data?.booking);
          setChargesDetails(data?.charges);
          setMasterFolioDetails(data?.masterFolio);
        } else {
          setLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const fetchInfo = async (id, date, filterType) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/view/fetch/${id}/stay-view?currentDate=${date}&filterType=${filterType}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data?.data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const [date_range, setDateRange] = useState([]);

  useEffect(() => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      setDateRange(undefined);
      return;
    }

    const date_range = data.map((view) =>
      view?.rooms?.map((room) =>
        room?.booking?.map((booking) => booking?.booking_date)
      )
    );

    if (
      !date_range[0] ||
      !Array.isArray(date_range[0]) ||
      date_range[0].length === 0
    ) {
      setDateRange(undefined);
      return;
    }

    setSelectedLastDate(date_range[0][0][date_range[0][0].length - 1]);
    setSelectedFirstDate(date_range[0][0][0]);

    setDateRange(date_range[0][0]);
  }, [data]);

  const currentDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id, calculateToDateMinus(currentDate, 8), "next");
    }
  }, [property_id]);

  function formatDate(inputDate) {
    const dateObj = new Date(inputDate);
    const monthNames = [
      "January",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

    const day = dateObj.getDate();
    const month = monthNames[dateObj.getMonth()];
    const dayOfWeek = dayNames[dateObj.getDay()];

    const formattedDate = `${String(day).padStart(
      2,
      "0"
    )} ${month} ${dayOfWeek}`;

    return formattedDate;
  }
  const [bookingData, setBookingData] = useState({});

  useEffect(() => {
    const bookings = data.map((item) => ({
      room_category: item.room_category,
      expanded: true,
      rooms: {
        room_no: item.rooms.map((room) => {
          const bookingNewData = [];
          const bookingData = room?.booking.map(
            (booking) =>
              booking?.booking.length !== 0 &&
              bookingNewData.push(booking?.booking)
          );
          return {
            room_no: room.room_no,
            bookings: bookingNewData,
          };
        }),
      },
    }));
    setBookingData(bookings);
    setExpandedCategories(
          bookings.reduce((acc, category) => {
            acc[category.room_category] = true; // By default, all categories are expanded
            return acc;
          }, {})
        );
    
  },[data])
 

  // useEffect(() => {
  //   setExpandedCategories(
  //     bookingData.reduce((acc, category) => {
  //       acc[category.room_category] = true; // By default, all categories are expanded
  //       return acc;
  //     }, {})
  //   );
  // }, [bookingData]);

  console.log(expandedCategories, "expandedCategories");

  console.log("bookingData", bookingData);

  // Toggle expand/collapse for a category
  const toggleExpandCollapse = (category) => {
    setExpandedCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  const updatedData = data.map((category) => ({
    ...category,
    rooms: category.rooms.map((room) => ({
      ...room,
      booking: room.booking.map((booking) => ({
        ...booking,
        booking: Array.isArray(booking.booking)
          ? booking.booking
          : [booking.booking],
      })),
    })),
  }));

  const flatMap = data.flatMap((item) =>
    item.rooms.flatMap((room) => room.booking.flatMap((room) => room.booking))
  );
  // let uniqueArr = [...new Map(arr.map(item => [item.articleId, item])).values()];

  const uniqueBookingArray = [
    ...new Map(flatMap.map((item) => [item.unique_booking_id, item])).values(),
  ];

  // console.log("flatMap", flatMap);
  // console.log(uniqueBookingArray, "updatedData");
  const [booking, setBooking] = useState({
    booking_id: "b606162",
    booking_date: "2024-03-19",
    unique_booking_id: "ub606162",
    check_in_date: "2024-03-18",
    check_out_date: "2024-03-19",
  });

  const isDateInRange = (dateToCheck, checkInDate, checkOutDate) => {
    return dateToCheck >= checkInDate && dateToCheck <= checkOutDate;
  };

  const dateToCheck = "2024-03-20";
  const formattedDateToCheck = dateToCheck;

  const isDateWithinRange = isDateInRange(
    dateToCheck,
    booking.check_in_date,
    booking.check_out_date
  );

  const checkNextData = (date) => {};

  const [selectedLastDate, setSelectedLastDate] = useState("");
  const [selectedFirstDate, setSelectedFirstDate] = useState("");

  const handleNextClick = async () => {
    await fetchInfo(property_id?.id, selectedLastDate, "next");
    setSelectedFirstDate(selectedLastDate);
    setSelectedLastDate(calculateToDatePlus(selectedLastDate, 15));
  };

  const handlePreviousClick = async () => {
    await fetchInfo(property_id?.id, selectedFirstDate, "previous");
    setSelectedLastDate(selectedFirstDate);
    setSelectedFirstDate(calculateToDateMinus(selectedFirstDate, 15));
  };

  const [selectedCategory, setSelectedCategory] = useState(0);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "hold":
        return "#BD941B";
      case "confirmed":
        return "#3968ED";
      case "checked_in":
        return "#15AA12";
      case "cancelled":
        return "#E03838";
      default:
        return "#ff8040";
    }
  };

  // Function to create a row for bookings
  const createBookingRow = (bookings, allDates, index) => {
    const tr = Array(allDates?.length).fill(<td></td>);
    bookings.forEach((booking) => {
      const checkInIndex = allDates?.indexOf(booking.check_in_date);
      const checkOutIndex = allDates?.indexOf(booking.check_out_date);
      // console.log(bookingData);

      if (checkInIndex !== -1) {
        // Calculate colSpan to include the check-out date
        const colSpan =
          checkOutIndex === -1
            ? allDates.length - checkInIndex
            : checkOutIndex - checkInIndex + 1;

        // Create a cell that spans the booking duration
        const td = (
          <td
            className="booking"
            colSpan={colSpan}
            key={`${booking.unique_booking_id}-${booking.check_in_date}`}
            style={{
              paddingLeft: allDates[0] <= booking.check_in ? "25px" : "0px",
              paddingRight:
                allDates[allDates.length - 1] <= booking.check_out
                  ? "0px"
                  : "25px",
            }}
          >
            <div
              className="unique_id_paragraph_bar"
              style={{
                background: getStatusColor(booking.status),
                // marginLeft:
                //   allDates[0] <= booking.check_in_date ? "40px" : "0px",
                // marginRight:
                //   allDates[allDates.length - 1] <= booking.check_out_date
                //     ? "0px"
                //     : "40px",
              }}
              onClick={() =>
                getSingleBookingDataHandler(booking.unique_booking_id)
              }
            >
              {/* {allDates[allDates.length - 1]} {booking.check_out} */}
              <span className="reservationViewGuestTruncated">
                {booking.booking_guest.length > 20
                  ? `${booking.booking_guest.slice(0, 20)}...`
                  : booking.booking_guest}
              </span>
              <ReservationTooltip booking={booking} />
            </div>
          </td>
        );

        // Insert the booking cell into the appropriate position
        tr[checkInIndex] = td;

        // Replace spanned-over cells with null
        for (let i = checkInIndex + 1; i < checkInIndex + colSpan; i++) {
          tr[i] = null;
        }
      } else if (checkInIndex === -1) {
        // Calculate colSpan to include the check-out date
        const colSpan = checkOutIndex - 0 + 1;
        

        // Create a cell that spans the booking duration
        const td = (
          <td
            className="booking"
            colSpan={1}
            key={`${booking.unique_booking_id}-${booking.check_in}`}
            style={{
              paddingLeft: allDates[0] <= booking.check_in ? "25px" : "0px",
              paddingRight:
                allDates[allDates.length - 1] <= booking.check_out
                  ? "0px"
                  : "25px",
            }}

          >
            <div
              className="unique_id_paragraph_bar"
              style={{
                background: getStatusColor(booking.status),
                // marginLeft: allDates[0] <= booking.check_in ? "40px" : "0px",
                // marginRight:
                //   allDates[allDates.length - 1] <= booking.check_out
                //     ? "0px"
                //     : "40px",
              }}
              onClick={() =>
                getSingleBookingDataHandler(booking.unique_booking_id)
              }
            >
              <span className="reservationViewGuestTruncated">
                {booking.booking_guest.length > 20
                  ? `${booking.booking_guest.slice(0, 20)}...`
                  : booking.booking_guest}
              </span>
              <ReservationTooltip booking={booking} />
            </div>
          </td>
        );

        // Insert the booking cell into the appropriate position
        tr[0] = td;

        // Replace spanned-over cells with null
        for (let i = 0 + 1; i < 0 + colSpan; i++) {
          tr[i] = null;
        }
      }
    });

    return (
      // <td
      //   key={bookings.map((b) => b.booking_id).join("-")}
      //   style={
      //     index % 2 !== 0 ? { background: "white" } : { background: "#F5F5F5" }
      //   }
      // >
      <>{tr.filter((cell) => cell !== null)}</>
      // </td>
    );
  };

  const getDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const decideColSpan = (booking, dateRange) => {
    const colSpan = getDaysDifference(booking.check_in, booking.check_out);
  };

  return (
    <div>
      {/* <SidebarInnerLists innerLists={stay_reservation_views} /> */}

      <div
        style={{
          display: "flex",
          zIndex: 20,
          justifyContent: "space-between",
          left: "0",
          top: "0",
        }}
        className="footer_inner_lists"
      >
        <ul className="flex flex_gap_10">
          {permissions &&
            stay_reservation_views &&
            stay_reservation_views.map((item, index) => (
              <React.Fragment key={index}>
                {permissions && permissions.includes(item.permission) && (
                  <li style={{ listStyle: "none" }}>
                    <Link
                      to={item.link}
                      className={`inner_list_item ${
                        location.pathname === item.link ? "active" : ""
                      }`}
                      key={index}
                    >
                      {item.name}
                    </Link>
                  </li>
                )}
              </React.Fragment>
            ))}
        </ul>
        <div className="flex flex_gap_10" style={{ marginRight: "20px" }}>
          <div
            onClick={handlePreviousClick}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "14px",
            }}
            className="pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 8 13"
              fill="none"
            >
              <path
                d="M7 1.50003L2 6.50003L7 11.5"
                stroke="#333333"
                stroke-width="1.5"
              />
            </svg>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="pointer"
            onClick={handleNextClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 8 13"
              fill="none"
            >
              <path
                d="M1 11.5L6 6.50003L0.999999 1.50003"
                stroke="#333333"
                stroke-width="1.5"
              />
            </svg>
          </div>
        </div>
      </div>
      {/* <div id="table-wrapper">
          <div id="table-scroll"> */}

      <div className="booking-choice-full-parent-container-right-heading-new">
        <div
          style={{
            width: "100%",
            backgroundColor: "#EFF0F2",
            marginTop: "10px",
          }}
        >
          <div className="reservation_bar_container" style={{ width: "100%" }}>
            <div className="reservation_total">
              {/* <CustomDateRangeSelector
                      style={{ padding: "43px 0px 20px" }}
                      onSelectedRangeChange={handleSelectedRangeChange}
                      onApplyButtonClick={handleApplyButtonClick}
                      onDateRangeSelected={handleDateRangeSelected}
                    /> */}

              <div className="reservation_total confirmed">
                <h5 className="reservation_total_heading">
                  {/* {bookingOuterDetails?.confirmed_bookings} */}{" "}
                  {/* {bookingData?.all_bookings?.flatMap((item) =>
                    item.category_bookings.filter(
                      (booking) => booking.status === "checked_in"
                    )
                  ).length || 0} */}
                  {uniqueBookingArray && uniqueBookingArray.length > 0
                    ? uniqueBookingArray.filter(
                        (item) => item.status === "checked_in"
                      ).length
                    : 0}
                </h5>
                <p className="reservation_total_paragraph">Checked-In</p>
              </div>

              <div className="reservation_total pending confirmed">
                <h5 className="reservation_total_heading">
                  {uniqueBookingArray && uniqueBookingArray.length > 0
                    ? uniqueBookingArray.filter(
                        (item) => item.status === "checked_out"
                      ).length
                    : 0}
                </h5>
                <p className="reservation_total_paragraph">Checked-Out</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container-stay-view" style={{ marginTop: "10px" }}>
        {loader ? (
          <Loader />
        ) : (
          <div className="reservation_view_container overflowContainer ">
            <table className="booking-table">
              <thead>
                <tr>
                  <th className="reservation_button_headers   button_align_right"></th>
                  {date_range?.map((date) => (
                    <th key={date} className="reservation_date_headers">
                      <div
                        onClick={() => fetchInfo(property_id?.id, date, "next")}
                        className={
                          new Date(date)?.toDateString() ===
                          new Date()?.toDateString()
                            ? "reservation_date_container_current_date"
                            : "reservation_date_container"
                        }
                      >
                        {getDateByMonthWeekName(date)}
                      </div>
                    </th>
                  ))}
                  {/* <th className="reservation_button_headers button_align_left"></th> */}
                </tr>
              </thead>

              {/* map */}

              {bookingData && bookingData?.length > 0 ? (
                <tbody>
                  {bookingData?.map((item) => (
                    <React.Fragment key={item?.room_category}>
                      <tr key={item?.room_category}>
                        {/* <td
                          colSpan={date_range?.length + 2}
                          style={{ textAlign: "left" }}
                          className="reservation_button_headers button_align_right"
                        >
                          {item?.room_category}
                        </td> */}
                        <td
                          colSpan={date_range?.length + 2}
                          className="room-category"
                        >
                          <button
                            className="reservation_view_room-category-button flex flex_gap_5 align_center"
                            onClick={() =>
                              toggleExpandCollapse(item?.room_category)
                            }
                          >
                            {item?.expanded ? (
                              <img
                                src="/reservationViewButtonImages/reservationViewCollapse.svg"
                                alt="arrow"
                                className="arrow"
                              />
                            ) : (
                              <img
                                src="/reservationViewButtonImages/reservationViewExpand.svg"
                                alt="arrow"
                                className="arrow"
                              />
                            )}

                            <span> {item?.room_category}</span>
                          </button>
                        </td>
                      </tr>
                      {expandedCategories[item.room_category]&& (item?.rooms?.room_no?.map((room, index) => {
                        return (
                          <tr
                            key={room?.room_no}
                            style={
                              index % 2 !== 0
                                ? { background: "white" }
                                : { background: "#F5F5F5" }
                            }
                          >
                            <td>{room?.room_no}</td>

                            {date_range?.length > 0 &&
                              createBookingRow(
                                room?.bookings,
                                date_range,
                                index
                              )}
                          </tr>
                        );
                      })) }

                      {}
                    </React.Fragment>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <td colSpan={date_range?.length + 2}>
                    <NoDataFound />
                  </td>
                </tbody>
              )}
            </table>
          </div>
        )}
      </div>

      {openSingleBookingDetails && (
        <BookingDetailSliderNew
          data={bookingDetails}
          chargesDetails={chargesDetails}
          masterFolioDetails={masterFolioDetails}
          setShowBookingDetails={setOpenSingleBookingDetails}
          getSingleBookingDataHandler={getSingleBookingDataHandler}
        />
      )}
    </div>
  );
};
export default StayViewNew;
