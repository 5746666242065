import React, { useState, useRef, useEffect, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { showErrorToast } from "../../../../assets/toastUtils";
// import "./selectdateRange.css";
import "../CreateBookingEditableInput/CreateBookingEditableInput.css"

const CustomInput = forwardRef((props, ref) => (
  <div ref={ref} className="dateRangePicker" >
    {/* Your custom input content */}
  </div>
));

const DateRangePickerNew = ({
  dataFromChild,
  defaultDate,
  disabled,
  minDate,
  maxDate,
  error
}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const datePickerRef = useRef(null);

  const handleDateChange = (update) => {
   setDateRange(update);
  };
  

  const handleContainerClick = () => {
    datePickerRef.current.setOpen(true);
  };
  const handleContainerClickDisabled = () => {
    datePickerRef.current.setOpen(false);
  };

  const [from_date, setFrom_date] = useState("");
  const [to_date, setTo_date] = useState("");  

  useEffect(() => {
    if (startDate && endDate) {
      const from_date = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`;
      const to_date = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`;
  
      if (from_date === to_date) {
        // Handle error here, such as displaying an error message
        showErrorToast('Please choose a Check Out date different from Check In date.');
        return;
      }
  
      setFrom_date(from_date);
      setTo_date(to_date);
      dataFromChild(from_date, to_date);
      datePickerRef.current.setOpen(false);
    }
  }, [startDate, endDate]);

  function getDateDDMMYYYY(date) {
     
    
    const day = String(date?.getDate()).padStart(2, '0');
    const month = String(date?.getMonth() + 1).padStart(2, '0'); 
    const year = date?.getFullYear();
  
    return `${day}-${month}-${year}`;
  }
  

  return (
    <>
      <label className="Create_booking_label">Select Date Range</label>
      <div
        style={{height:"27px",border: error ? "1px solid #E03838" :"",backgroundColor: error ? "#FFEAEA" : "" }}  
        className={`${!disabled ? "selectdateRange_container" : "selectdateRange_container disabled"} ${error ? "create_booking_error" : ""}`}
        onClick={!disabled ? handleContainerClick : handleContainerClickDisabled}
      >
        <div className="selectdateRange_text" >
          {startDate || endDate ? (
            `${from_date ? getDateDDMMYYYY(new Date(from_date)) : " "} : ${to_date ? getDateDDMMYYYY(new Date(to_date)) : ""}`
          ) : (
            <>{defaultDate ? defaultDate : <>Select Date Range</>}</>
          )}
        </div>
        <div>
          <DatePicker
            className="SelectDateRangeInput"
            ref={datePickerRef}
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            customInput={<CustomInput />}
            popperPlacement="bottom-end"
            maxDate={maxDate}
            minDate={minDate}
          />
        </div>
      </div>
    </>
  );
};

export default  DateRangePickerNew;




