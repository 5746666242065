import {
  Button,
  Icon,
  IndexTable,
  LegacyCard,
  Page,
  Text,
  useIndexResourceState,
  Sticky,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { BgOutlineThemeButton, BgThemeButton } from "./Buttons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import Loader from "./Loader/Loader";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import LoaderSmall from "./Loader/LoaderSmall";

const ShowHouseCheckIn = ({
  array,
  setShowInHouseCheckIn,
  setShowArrivalConfirmed,
  setShowBookingDetails,
  setBookingDetails,
  setShowCheckOutBookingDetails,
  setShowCheckOutSettlementDetails,

  setShowDepartureCheckOut,
  setCheckOutBookingDetail,
  assignRoomArray,
  onkeyPressValue,
  dayTypeFilter,
  setOnKeyPressValueHandleChange,
  handleOnKeyDown,
  handlelistRemove,
  selectedRooms,
  roomNumbers,
  bookingShortId,
}) => {
  const [units, setUnits] = useState([]);

  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showAssignRoomPopup, setShowAssignRoomPopup] = useState(false);
  const [clickedBookingId, setClickedBookingId] = useState("");
  const [shortBookingId, setShortBookingId] = useState("");

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [unitsData, setUnitsData] = useState({
    units_name: "",
  });

  const completeCheckOut = (uniqueBookingId) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/booking/checkout/${uniqueBookingId}/${property_id?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [
    showAddCategoriesLowerMenuActive,
    setShowAddCategoriesLowerMenuActive,
  ] = useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const resourceName = {
    singular: "unit",
    plural: "units",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(units);

  const addCategoriesRowMarkup = units?.map(
    ({
      id,
      booked_from,
      unique_booking_id,
      check_out,
      index,
      web_checkin,
      guest,
      items,
      assigned_rooms,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          {booked_from}
          <div
            style={{ cursor: "pointer", color: "#3968ED" }}
            onClick={() => submitBookingData(unique_booking_id)}
          >
            #{unique_booking_id}
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {web_checkin === 0 ? <span>Manual</span> : <span>Web</span>}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <TextDateFormatter as={`div`} date={check_out} showYear={true} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text alignment="right">
            {items[0].assigned_rooms !== null &&
            items[0].assigned_rooms !== "" ? (
              <>
                <div style={{ textAlign: "end" }}>Assigned Room:</div>

                <div style={{ textAlign: "end", fontWeight: "bold" }}>
                  {items.map((room_no, index) => {
                    return (
                      <span>
                        {room_no.assigned_rooms}
                        {index < items.length - 1 ? ", " : ". "}
                      </span>
                    );
                  })}
                </div>
                {/* <div style={{ textAlign: "end" }}>Assigned Room:</div>
                <div style={{ textAlign: "end", fontWeight: "bold" }}>
                  {assigned_rooms}
                </div> */}
              </>
            ) : (
              <span style={{ textAlign: "-webkit-right" }}>
                <BgOutlineThemeButton
                  onClick={() => [
                    setClickedBookingId(unique_booking_id),
                    setShortBookingId(id),
                    fetchAssignRooms(property_id?.id, id),
                  ]}
                >
                  Assign rooms
                </BgOutlineThemeButton>
              </span>
            )}
          </Text>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );
  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowAddCategoriesLowerMenuActive(false);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const setAddItemDataHandleChange = (event) => {
    setUnitsData({
      ...unitsData,
      [event.target.name]: event.target.value,
    });
  };

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/${property_id?.id}&dayTypeFilter=${dayTypeFilter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setUnits(data.checkin);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const completeCheckIn = (e, bookingId) => {
    // every
    // some

    const checkMaxRooms =
      selectedData &&
      selectedData.every((room) => {
        return (
          room.maxRooms ===
          (room.selectedRoooms ? room.selectedRoooms.length : 0)
        );
      });

    if (!checkMaxRooms) {
      showErrorToast("Please assign the rooms first.");
      return;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/room/no/${shortBookingId}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          assign_room: selectedData,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // setShowBookingDetails(false);
          // setBookingDetails(data.booking);
          setShowInHouseCheckIn(false);
          setShowAssignRoomPopup(false);
          showSuccessToast(data.message);
          // setShowDashBoardCheckIn(false);
          window.location.reload();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
    }
  }, [property_id]);

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/unit/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        units_name: data.Unit.units_name,
      };
      setUnitsData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const updateFunction = async (id) => {
    const findDepartment = units.find((unit) => unit.id === id);
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/unit/update/${id}/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify(unitsData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        fetchInfo(property_id?.id);
        setShowLowerMenuSingleSelect(false);
        clearSelection();
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const submitBookingData = (bookingId) => {
    // e.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setShowCheckOutBookingDetails(true);
          setCheckOutBookingDetail(data.booking);
          setShowInHouseCheckIn(false);
          // showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleSubmitAddDepartment = async (event) => {
    event.preventDefault();
    if (unitsData.units_name === "") {
      showErrorToast("Please fill the unit name...!!!");
      return;
    }

    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create/unit/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...unitsData, property_id: property_id?.id }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        setShowLowerMenuSingleSelect(false);
        clearSelection();
        showSuccessToast(data.message);
        setUnitsData({
          units_name: "",
        });
      } else if (data.error) {
        const firstErrorKey = Object.keys(data.error)[0];
        const firstErrorMessage = data.error[firstErrorKey][0];
        showErrorToast(firstErrorMessage);
        fetchInfo(property_id?.id);
        // return;
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [assignRoomsData, setAssignRoomsData] = useState([]);

  const fetchAssignRooms = async (propertyId, bookingId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/check-in/getdata/${bookingId}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          setAssignRoomsData(data.availablerooms);
          setLoader(false);
          setShowAssignRoomPopup(true);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      setLoader(false);
      showErrorToast("Something went wrong...");
      console.error(error);
    }
  };

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/unit/delete/${property_id?.id}`,
        {
          method: "DELETE",
          body: JSON.stringify(deletedIds),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        clearSelection();
        setShowLowerMenuMultipleSelect(false);
        setShowModelMenuActive(false);
        setShowAddCategoriesLowerMenuActive(false);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const handleAddUnitClick = () => {
    clearSelection();
    setShowAddCategoriesLowerMenuActive(!showAddCategoriesLowerMenuActive);
    setShowLowerMenuMultipleSelect(false);
    setShowLowerMenuSingleSelect(false);
    setUnitsData({
      ...unitsData,
      units_name: "",
    });
  };

  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedRoooms, selectedRooooms] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [selected, setSelected] = useState({});

  const handleRoomClick = (roomId, maxRooms) => {
    setSelectedRoom(roomId);
  };

  const handleCheckboxChange = (roomId, roomName, roomNoId, room_no) => {
    selectedRooooms((prevSelectedRooms) => {
      const newSelectedRooms = { ...prevSelectedRooms };
      const currentSelection = newSelectedRooms[roomId] || [];

      if (currentSelection.includes(room_no)) {
        newSelectedRooms[roomId] = currentSelection.filter(
          (id) => id !== room_no
        );
      } else {
        const roomCategory = assignRoomsData.find((room) => room.id === roomId);
        const maxRooms = parseInt(roomCategory.no_of_rooms, 10);

        if (currentSelection.length < maxRooms) {
          newSelectedRooms[roomId] = [...currentSelection, room_no];
        } else {
          showErrorToast(
            `Cannot select more than ${maxRooms} rooms for ${roomName}`
          );
          return prevSelectedRooms;
        }
      }
      return newSelectedRooms;
    });
  };

  useEffect(() => {
    const newData = assignRoomsData.map((room) => ({
      name: room.room_name,
      id: room.id,
      selectedRoooms: selectedRoooms[room.id] || [],
      maxRooms: parseInt(room.no_of_rooms, 10),
    }));
    setSelectedData(newData);
  }, [selectedRoooms, assignRoomsData]);

  return (
    <>
      <div className="bg-backdrop">
        <section className="agent-add-categories-model-container">
          {!showAssignRoomPopup && (
            <LegacyCard>
              <div
                style={{
                  padding: "16px 26px 0 26px",
                }}
              >
                <GoBackButtonCustom
                  buttonNextText={`Checked In Users`}
                  onClick={() => [
                    setShowInHouseCheckIn(false),
                    setShowArrivalConfirmed(false),
                  ]}
                />
              </div>

              <div
                className="overflow_y_scroll_x_hidden"
                style={{
                  padding: "10px",
                  maxHeight: "300px",
                }}
              >
                {loader ? (
                  <LoaderSmall />
                ) : (
                  <>
                    {units && units.length > 0 ? (
                      <IndexTable
                        selectable={false}
                        resourceName={resourceName}
                        itemCount={units.length}
                        selectedItemsCount={
                          allResourcesSelected
                            ? "All"
                            : selectedResources.length
                        }
                        onSelectionChange={handleSelectionChange}
                        headings={[
                          { title: "Name" },
                          { title: "Booking Type" },
                          { title: "Check-Out Date" },
                          { title: "" },
                        ]}
                      >
                        {addCategoriesRowMarkup}
                      </IndexTable>
                    ) : (
                      <NoDataFoundSmall />
                    )}
                  </>
                )}
              </div>

              {/* Single select */}
              {showLowerMenuSingleSelect && (
                <>
                  <div className="bg-backdrop">
                    <form className="room-plan-add-model-container">
                      <h4>Add Competitor</h4>

                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          {/* <CustomerSelectAddBox
                         onCompetitorsIdsChange={handleCompetitorsIdsArrayChange}
                       /> */}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <BgThemeButton
                          //  onClick={(e) => handleSubmitAddCompetitor(e)}
                          children={"Add"}
                        />

                        <BgOutlineThemeButton
                          //  onClick={removeAddModalContainer}
                          children={"Discard"}
                        />
                      </div>
                    </form>
                  </div>
                </>
              )}

              {/* Multiple select */}
              {showLowerMenuMultipleSelect && (
                <div className="room-type-bottom-container">
                  <div className="room-type-bottom-container-div">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Button plain destructive onClick={showModelMenu}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </LegacyCard>
          )}
        </section>

        {/* Add Button Click */}
        {showAssignRoomPopup && (
          <div
            style={
              {
                // width: "500px",
                // marginBottom: "20px",
                // // maxHeight: "500px",
                // overflowY: "auto",
              }
            }
            className="room-plan-add-model-container "
          >
            <GoBackButtonCustom
              buttonNextText={`Assign Rooms for Booking ID : ${clickedBookingId}`}
              onClick={() => [
                setShowAssignRoomPopup(false),
                setSelectedRoom(""),
              ]}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                flexDirection: "column",
              }}
            >
              {selectedData && selectedData.length > 0 ? (
                selectedData.map((data, index) => (
                  <div
                    key={index}
                    className={`room-box ${
                      selectedRoom === data.name ? "selected" : ""
                    }`}
                  >
                    <div className="availableRoomsSelectBoxContainer">
                      <div
                        className="availableRoomsSelectBox"
                        onClick={() =>
                          handleRoomClick(data.name, data.maxRooms)
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span>{data.name}</span>
                          <span>Rooms:({data.maxRooms})</span>
                          <span style={{ height: "20px", width: "20px" }}>
                            <svg
                              viewBox="0 0 20 20"
                              class="Polaris-Icon__Svg"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.72 8.47a.75.75 0 0 1 1.06 0l3.47 3.47 3.47-3.47a.75.75 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06Z"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                      {selectedRoom === data.name && (
                        <div className="checkbox-container">
                          {assignRoomsData
                            .find((room) => room.room_name === data.name)
                            .sorted_rooms.map((roomNo) => (
                              <div className="flex flex-gap-10" key={roomNo.id}>
                                <input
                                  style={{ marginRight: "5px" }}
                                  type="checkbox"
                                  checked={data.selectedRoooms.includes(
                                    roomNo.room_no
                                  )}
                                  id={`room-${roomNo.room_no}`}
                                  value={roomNo.room_no}
                                  disabled={
                                    roomNo.status === "occupied" ||
                                    roomNo.status === "dirty" ||
                                    roomNo.status === "out_of_order"
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(
                                      roomNo.room_id,
                                      data.name,
                                      roomNo.id,
                                      roomNo.room_no
                                    )
                                  }
                                />
                                <label htmlFor={`room-${roomNo.room_no}`}>
                                  {`Room ${roomNo.room_no} (${roomNo.status})`}
                                </label>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div>No Rooms </div>
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <BgThemeButton
                onClick={(e) => completeCheckIn(e, clickedBookingId)}
                children={"Assign"}
              />

              <BgOutlineThemeButton
                onClick={() => setShowInHouseCheckIn(false)}
                children={"Discard"}
              />
            </div>
          </div>
        )}

        {showModelMenuActive && (
          <>
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button onClick={showModelMenu}>Discard</Button>
                <Button onClick={() => deleteData()} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </>
        )}
      </div>
    </>
  );
};

export default ShowHouseCheckIn;
