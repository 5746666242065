import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { formatIndianCurrency } from "../../utils/utils";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";

const ReportSaleSummeryReport = ({
  departureReport,
  loader,
  fullData,
  donShowTitle,
}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkup =
    fullData &&
    fullData?.summary?.length > 0 &&
    fullData?.summary?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>
          {order.Invoice_No ? order.Invoice_No : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>{order.Inv_Date}</CustomReportTableCell>
        <CustomReportTableCell>
          {order.Booking_Ref ? order.Booking_Ref : "N/A"}
        </CustomReportTableCell>

        <CustomReportTableCell>
          {" "}
          {order.Primary_Guest ? order.Primary_Guest : "N/A"}
        </CustomReportTableCell>
        {/* <IndexTable.Cell>
          {" "}
          {order.Room_Outlet ? order.Room_Outlet : "N/A"}
        </IndexTable.Cell> */}
        <CustomReportTableCell>
          {order.Registered_Name ? order.Registered_Name : "N/A"}
        </CustomReportTableCell>
        {/* <IndexTable.Cell>{order.Account ? order.Account : "N/A"}</IndexTable.Cell> */}
        <CustomReportTableCell>
          {order.GSTIN ? order.GSTIN : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.HSN_SAC_Code ? order.HSN_SAC_Code : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.Account_Details ? order.Account_Details : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.Tax_Rate ? <div>{+order.Tax_Rate}%</div> : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.Taxable_Amount ? (
            <div>{formatIndianCurrency(order.Taxable_Amount)}</div>
          ) : (
            "N/A"
          )}
        </CustomReportTableCell>

        <IndexTable.Cell>
          {formatIndianCurrency(order.SGST_Amount)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatIndianCurrency(order.CGST_Amount)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {order.Invoice_Value
            ? formatIndianCurrency(order.Invoice_Value)
            : "N/A"}
        </IndexTable.Cell>
      </CustomReportTableRow>
    ));

  const downloadData = departureReport.map((report) => {
    return {
      date: report.date,
      guest_name: report.guest_name ? report.guest_name : "N/A",
      reference: report.guest_name
        ? `#${report.unique_booking_id} Folio-${report.folio_no} (${report.room_no})`
        : "Paid Credit",
      credits: report.credit_amount,
      debits: report.debit_amount,
      available_credit_limit: report.available_credit_limit,
    };
  });

  // headings={[
  //   { title: "Date" },
  //   { title: "INV No." },
  //   { title: "Booking Ref" },
  //   { title: "Guest Name" },
  //   { title: "Room No." },
  //   { title: "Place Of Supply " },
  //   { title: "Account" },
  //   { title: "Tax Rate" },
  //   { title: "Taxable Amt." },
  //   { title: "SGST" },
  //   { title: "CGST" },
  //   { title: "INV Value" },
  // ]}

  const names = [
    {
      Invoice_No: "INV No.",
      Inv_Date: "Date",
      Booking_Ref: "Booking Ref",
      Primary_Guest: "Guest",
      GSTIN: "GST IN",
      Account_Details: "Account",
      Tax_Rate: "Tax Rate",
      Taxable_Amount: "Taxable Amt.",
      SGST_Amount: "SGST",
      CGST_Amount: "CGST",
      Invoice_Value: "INV Value",
    },
  ];

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container" id="sales_table">
          <div className="room-types-full-container-div">
            <div className="room-type-heading">Sales Summary Report </div>
            {fullData?.summary?.length > 0 && (
              <div className="flex flex_gap_15" id="ignoreFromPrint">
                <ButtonToDownloadTablePDF
                  itemToDownloadID={"sales_table"}
                  downloadFileName={"sales-summary-report"}
                  ignoreFromPrint={"ignoreFromPrint"}
                  pdfOrientation={"landscape"}
                  pdfMargins={[0.1, 0.1, 0.1, 0.1]}
                  pdfStyles={{
                    fontSize: "11px",
                    lineHeight: "1px",
                  }}
                  pageFormat={"a3"}
                />
                {/* <DownloadCsvTables tableData={fullData?.summary} names={names} /> */}
                <DownloadCSVButton itemToDownloadID={"sales_table"}  downloadFileName={"sales-summary-report"} />
              </div>
            )}
          </div>

          {/* <div style={{ display: "flex", gap: "30px", marginBottom: "20px" }}>
            <div>
              <div>
                {" "}
                <strong>Total Credit Limit</strong> :{" "}
                {formatIndianCurrency(fullData?.companyCredit?.credit_amount)}
              </div>
            </div>
            <div>
              <div>
                {" "}
                <strong>Available Credit Limit</strong> :{" "}
                {formatIndianCurrency(fullData?.companyCredit?.balance_amount)}
              </div>
            </div>
            <div>
              <div>
                {" "}
                <strong>Used Credit Limit</strong> :{" "}
                {formatIndianCurrency(fullData?.companyCredit?.used_amount)}
              </div>
            </div>
          </div> */}
          {fullData && fullData?.summary?.length > 0 ? (
            <>
              {/* <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={fullData?.summary?.length}
                  selectable={false}
                  headings={[
                    { title: "INV No." },
                    { title: " INV Date" },
                    { title: "Booking ID" },
                    { title: "Guest Name" },
                    // { title: "Booking Source" },
                    { title: "GST IN" },
                    { title: "HSN/SAC" },
                    { title: "Account Description" },
                    { title: "Tax Rate" },
                    { title: "Taxable Amt." },
                    { title: "SGST" },
                    { title: "CGST" },
                    { title: "INV Value" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
              </LegacyCard> */}

              <CustomReportTable
                headings={[
                  "INV No.",
                  "INV Date",
                  "Booking ID",
                  "Guest Name",
                  "Booking Source",
                  "GST IN",
                  "HSN",
                  "Account Description",
                  "Tax Rate",
                  "Taxable Amt.",
                  "SGST",
                  "CGST",
                  "INV Value",
                ]}
                // commonHeadings={[
                //   "",
                //   "",
                //   "",
                //   "Purchased",
                //   "Purchased",
                //   "Issued",
                //   "Issued",
                //   "Stock",
                //   "Stock",
                // ]}
                headerStyles={{
                  backgroundColor: "#dddddd",
                  fontSize: "12px",
                  textAlign: "center",
                }}
                tableStyles={{ textAlign: "left" }}
              >
                {rowMarkup}
                {/* {calculateTotals} */}
              </CustomReportTable>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>

        // </div>
      )}
    </>
  );
};
export default ReportSaleSummeryReport;
