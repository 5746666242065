import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  BgOutlineSendAgainButton,
  BgOutlineThemeButton,
  BgThemeButton,
  WithoutBgButtonBlue,
} from "../../UI/Buttons";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../VerticalInputSelectCustomCopy";
import { CancelMajor } from "@shopify/polaris-icons";
import CustomRoomChoiceSelect from "../CustomRoomChoiceSelect/CustomRoomChoiceSelect";
import CreateMessageNewPage from "../CreateMessageNewPage/CreateMessageNewPage";
// import { showErrorCustomToast, showSuccessToast } from "../../../assets/toastUtils";
import BookingStatusFilter from "../BookingStatusFilter/BookingStatusFilter";
import BookingStatusFilterRadio from "../BookingStatusFilter/BookingStatusFilterRadio";
import RatesStatusFilter from "../RatesStatusFilter/CustomCommunicationFilterRadio";
import CommunicationTagInput from "../CommunicationTagInput/CommunictionTagInput";
import CommunicationSingleSelect from "../CommunicationTagInput/CommunicationSingleRadio";
import CommunicationVerticalInput from "./CommunicationVerticalInput";
import { Icon } from "@shopify/polaris";
import PreviewCommunicationBillCopy from "../../Communication/PreviewCommunicationBillCopy";
import PaymentReceiptCommunication from "../../Communication/PaymentReceiptCommunication";
import ToastContainer from "../CustomToastify/CustomToastContainer";
import {
  showSuccessToast,
  showErrorCustomToast,
  showSuccessfullToast,
} from "../CustomToastify/ToastFunctions";
import { useToast } from "../CustomToastify/ToastContext";

const CommunicationCreateNewPage = () => {
  return (
    <>
      {/* Header */}
      <div
        className="communicaiton_lead_container"
        style={{
          display: "flex",
          marginTop: "80px",
          gap: "20px",
          alignItems: "center",
          marginBottom: "10px",
          justifyContent: "center",
        }}
      ></div>

      {/* Card */}
      {withSendCard(() => <div></div>)()}

      {/* Other content */}
    </>
  );
};

const CommunicationSendCard = () => {
  const toastRef = useToast();
  const [filterName, setFilterName] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [loader, setLoader] = useState(false);
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [bookingPaymentData, setBookingPaymentData] = useState(null);
  const [clearTags, setClearTags] = useState(false);
  console.log(clearTags, "cleartagsss");

  const property_id = useSelector((state) => state.property.value);

  const [allAgentTypes, setAllAgentTypes] = useState([]);
  const [selectedAgent, setSelectedAgentType] = useState([]);
  const [existing_user, setSelectedUser] = useState([]);
  const [propertyLogoLoader, setPropertyLogoLoader] = useState(false);
  const [uploadedLogoFile, setUploadedLogoFile] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [initialNameValue, setInitialNameValue] = useState(""); // Set initial name value
  const [initialEmailValue, setInitialEmailValue] = useState("");
  const [addChargesData, setAddChargesData] = useState({
    communication_type: "b2b_rates",
    sender_type: "",
    name: initialNameValue,
    email: initialEmailValue,
    status: "",
    existing_user: [],
    payment_link: "",
    gst: "",
    phone: "",
    files: new FormData(),
    customer_id: "",
    unique_booking_id: "",
    booking_id: "",
  });

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setAddChargesData((prevData) => ({
      ...prevData,
      name: newName,
    }));
  };

  console.log(bookingPaymentData, "bookingPaymentData");

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setAddChargesData((prevData) => ({
      ...prevData,
      email: newEmail,
    }));
  };

  const [uploadedFile, setUploadedFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState([]);

  console.log(searchName, "searchName");

  const [selectedObjects, setSelectedObjects] = useState([]);
  const [showNameEmail, setShowNameEmail] = useState(false);

  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const [bookingDetails, setBookingDetails] = useState([]);
  console.log(bookingDetails, "bookingDetails");

  console.log(selectedFiles, "selectedFiles");
  const handleToggleBookingPaymentReceiptPopup = () => {
    // if (addItemData.name !== "" && addItemData.email !== "") {

    alert("test");

    const response = fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/success/payment-receipt/booking/${searchName}/${property_id?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setBookingPaymentData(data.message);
          setShowBookingPaymentReceiptPopup(true);
        } else {
          showErrorCustomToast(toastRef, data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorCustomToast(
          toastRef,
          "An error occurred while processing your request."
        );
      });
  };

  // useEffect(() => {
  //   handleToggleBookingPaymentReceiptPopup();
  // }, [searchName]);

  console.log(bookingPaymentData, "bookingpaydata");

  const submitBookingData = (e) => {
    if (e) {
      e.preventDefault();
    }
    fetchBookingDetails();
  };

  const fetchBookingDetails = () => {
    // Set loading state to true while fetching
    // setLoading(true);

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${searchName}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          const guest = data.booking.guest[0];
          const { guest_name, guest_email } = guest;

          setAddChargesData((prevData) => ({
            ...prevData,
            name: guest_name,
            email: guest_email,
            unique_booking_id: data.booking.unique_booking_id,
            booking_id: data.booking.id,
            sender_type: "by_booking",
          }));

          setBookingDetails(data.booking);
          setShowNameEmail(true);
        } else {
          showErrorCustomToast(toastRef, data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
    
    showErrorCustomToast(toastRef, error.message); 
      })
      .finally(() => {
        // Reset loading state when fetch completes (either success or failure)
        // setLoading(false);
      });
  };

  useEffect(() => {
    if (
      bookingDetails &&
      bookingDetails.guest &&
      bookingDetails.guest.length > 0
    ) {
      const { guest_name, guest_email } = bookingDetails.guest[0];
      setInitialNameValue(guest_name);
      setInitialEmailValue(guest_email);
    }
  }, [bookingDetails]);

  const [voucherTitle, setVoucherTitle] = useState("");

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: "",
    to_date: "",
  });

  const handleDateRangeChange = (dates, formattedFromDate, formattedToDate) => {
    setSelectedDatesRange({
      from_date: formattedFromDate,
      to_date: formattedToDate,
    });
  };
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [eventDetails, setEventDetails] = useState(false);
  const [communicationOn, setCommunicationOn] = useState("");
  const [communicationType, setCommunicationType] = useState("");
  const [iframeSrc, setIframeSrc] = useState("");
  const [showIframePopup, setShowIframePopup] = useState(false);
  const [iframeContent, setIframeContent] = useState("");
  const [showBookingPreviewVoucherPopup, setShowBookingPreviewVoucherPopup] =
    useState(false);

  const [showBookingPreviewBillPopup, setShowBookingPreviewBillPopup] =
    useState(false);
  const [showBookingPaymentReceiptPopup, setShowBookingPaymentReceiptPopup] =
    useState(false);

  const handleToggleBookingPreviewVoucherPopup = (e) => {
    e.stopPropgation();

    // if (addItemData.name !== "" && addItemData.email !== "") {
    setShowBookingPreviewVoucherPopup(false);
    // } else {
    //showErrorCustomToast(toastRef,"Please Enter the above fields...!");
    // }
  };
  const handleToggleBookingPreviewBillPopup = () => {
    // if (addItemData.name !== "" && addItemData.email !== "") {
    setShowBookingPreviewBillPopup(!showBookingPreviewBillPopup);
    // } else {
    //showErrorCustomToast(toastRef,"Please Enter the above fields...!");
    // }
  };

  // preview
  const handleSubmitPreview = async (event, statuses, vouchertitle) => {
    try {
      setLoader(false);

      // Extract necessary details from bookingDetails
      const guest =
        bookingDetails.guest && bookingDetails.guest.length > 0
          ? bookingDetails.guest[0]
          : {};
      const additionalNotes = bookingDetails.additional_notes || "";
      const emailSub = bookingDetails.email_sub || "";
      const extraAdult =
        bookingDetails.items && bookingDetails.items.length > 0
          ? bookingDetails.items[0].extra_adult
          : "";
      const extraChild =
        bookingDetails.items && bookingDetails.items.length > 0
          ? bookingDetails.items[0].extra_child
          : "";
      const extraBed =
        bookingDetails.items && bookingDetails.items.length > 0
          ? bookingDetails.items[0].extra_bed
          : "";
      const noOfRooms =
        bookingDetails.items && bookingDetails.items.length > 0
          ? bookingDetails.items[0].no_of_rooms
          : "";
      const discount = bookingDetails.total_discount_amount || "";
      const email = guest.guest_email || "";

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/preview/template/${
          property_id?.id
        }?communication_type=${"email"}&communication_on=${"bookings"}&template=${
          addChargesData.communication_type
        }&status=${statuses}&booking_event_id=${
          eventDetails.id || bookingDetails.id
        }&from_date=${bookingDetails.check_in}&end_date=${
          bookingDetails.check_out
        }&name=${guest.guest_name}&customer_email=${
          guest.guest_email
        }&additional_notes=${additionalNotes}&email_sub=${emailSub}&extra_adult=${extraAdult}&extra_child=${extraChild}&extra_bed=${extraBed}&no_of_rooms=${noOfRooms}&discount=${discount}&email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success && data.html) {
        setVoucherTitle(vouchertitle);
        setIframeSrc(
          `data:text/html;charset=utf-8,${encodeURIComponent(data.html)}`
        );
        // setShowIframePopup(true);

        if (addChargesData?.communication_type === "voucher") {
          setShowBookingPreviewVoucherPopup(true);
          setShowBookingPreviewBillPopup(false);
          setShowBookingPaymentReceiptPopup(false);
        } else if (addChargesData?.communication_type === "bill") {
          setShowBookingPreviewVoucherPopup(false);
          setShowBookingPreviewBillPopup(true);
          setShowBookingPaymentReceiptPopup(false);
        } else if (addChargesData?.communication_type === "payment-receipt") {
          // handleToggleBookingPaymentReceiptPopup();
          console.log("testtststs");
          setShowBookingPaymentReceiptPopup(true);
          setShowBookingPreviewVoucherPopup(false);
          setShowBookingPreviewBillPopup(false);
        }

        setLoader(false);
      } else {
        showErrorCustomToast(toastRef, data.message || "something went wrong");
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const handleCommunicationSubmitPreviewVoucher = async (
    type,
    unique_booking_id
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v2/communication/booking-data/fetch/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            template: type,
            unique_booking_id: unique_booking_id,
            status: "preview",
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success && data.html) {
        setVoucherTitle(type); // Set the voucher title, adjust as needed
        setIframeSrc(
          `data:text/html;charset=utf-8,${encodeURIComponent(data.html)}`
        );
        setShowBookingPreviewVoucherPopup(true);
        // setShowIframePopup(true);
        // if (type === "voucher") {
        //   setShowBookingPreviewVoucherPopup(true);
        //   setShowBookingPreviewBillPopup(false);
        //   setShowBookingPaymentReceiptPopup(false);
        // } else if (type === "bill") {
        //   setShowBookingPreviewVoucherPopup(false);
        //   setShowBookingPreviewBillPopup(true);
        //   setShowBookingPaymentReceiptPopup(false);
        // } else if (type === "payment_receipt") {
        //   handleToggleBookingPaymentReceiptPopup();
        //   setShowBookingPreviewVoucherPopup(false);
        //   setShowBookingPreviewBillPopup(false);
        //   setShowBookingPaymentReceiptPopup(true);
        // }

        setLoader(false);
      } else {
        showErrorCustomToast(toastRef, data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorCustomToast(
        toastRef,
        error
      );
    }
  };
  console.log(addChargesData.sender_type, "addsdsd");

  const [isEnterPressed, setIsEnterPressed] = useState(false);

  const onKeyPressHandler = (e) => {
    if (e.key === "Enter") {
      setIsEnterPressed(true);
      submitBookingData();
    }
  };

  console.log(selectedObjects, "selectedObjects");

  const handleGetDataFromChild = useCallback((selectedObjects) => {
    setSelectedObjects(selectedObjects);

    const ids = selectedObjects.map((obj) => obj.id);
    setSelectedIds(ids);
  }, []);

  const handleMultipleDataFromChild = useCallback((selectedObjects) => {
    setSelectedObjects(selectedObjects);

    const ids = selectedObjects.map((obj) => obj.id);

    setAddChargesData((prevState) => ({
      ...prevState,
      existing_user: ids,
    }));
  }, []);

  const [selectedSingleObject, setSelectedSingleObject] = useState(null);

  const handleGetSingleDataFromChild = useCallback((selectedObject) => {
    setSelectedSingleObject(selectedObject);

    if (selectedObject) {
      setSelectedIds([selectedObject.id]);
    } else {
      setSelectedIds([]);
      console.log([], "selectedIds");
    }
  }, []);

  useEffect(() => {
    setAddChargesData((prevData) => ({
      ...prevData,
      customer_id: selectedIds,
    }));
  }, [selectedIds]);

  const [customerData, setCustomerData] = useState([]);

  const fetchCustomerLists = useCallback(
    async (propertyId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/hotel/get/customer/${propertyId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        const data = await response.json();
        if (data?.data) {
          setCustomerData(data.data);
        } else {
          setCustomerData([]);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [userToken]
  );

  useEffect(() => {
    if (property_id) {
      fetchCustomerLists(property_id.id);
    }
  }, [property_id, fetchCustomerLists]);

  console.log(addChargesData, "addcharges");

  const setAddChargesHandleChange = (event) => {
    const { name, value } = event.target;

    setAddChargesData({
      ...addChargesData,
      [name]: value,
    });
  };

  const isUrlValid = (url) => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
  };

  const navigate = useNavigate();
  console.log(bookingPaymentData, "bookingPaymentData");
  // console.log(addChargesData, "addChargesData");

  const handleCheckboxChange = (value) => {
    setClearTags(true);
  
    setSelectedOption(value);
    setSelectedAgentType([]);
  
    // Reset relevant fields when switching options
    const resetFields = {
      name: "",
      phone: "",
      email: "",
      gst: "",
      customer_id: "",
      existing_user: [],
      files: new FormData(),
      payment_link: "",
    };
  
    setAddChargesData((prevState) => ({
      ...prevState,
      ...resetFields,
      sender_type: value,
    }));
  
    setSelectedFiles([]);
  };
  

  //bookingdata

  //  dummy array of options

  const dummyOptions = [
    { label: "B2B Rates", value: "b2b_rates" },
    { label: "Rates & Availability", value: "rates_and_availability" },
    { label: "Property Brochure", value: "property_brochure" },
    { label: "Property Images", value: "property_images" },
    { label: "Payment Link", value: "payment_link" },
    { label: "Sales/Offer Creatives", value: "sales_offer_creative" },
    { label: "Booking Confirmation", value: "voucher" },
    { label: "Bill", value: "bill" },
    { label: "Payment Receipt", value: "payment-receipt" },
  ];

  console.log(addChargesData, "adssjdsjd");

  const handleChange = (event) => {
    setShowNameEmail(false);
    setSelectedOption("");

    const resetFields = {
      name: "",
      phone: "",
      email: "",
      gst: "",
      customer_id: "",
      existing_user: "",
      files: new FormData(),
      payment_link: "",
      booking_id: "",
      unique_booking_id: "",
    };

    setSearchName("");
    const { name, value } = event.target;
    setAddChargesData({
      ...addChargesData,
      ...resetFields,
      [name]: value,
      sender_type: "",
    });
  };

  const fileInputRef = useRef(null);

  const handleFileUploadChange = (event) => {
    const files = Array.from(event.target.files);
    const allowedExtensionsOnce = ["pdf"];
    const allowedExtensionsMultiple = ["jpg", "jpeg", "png", "webp"];
    let newSelectedFiles = [...selectedFiles];

    let pdfAlreadyAdded = newSelectedFiles.some((file) =>
      allowedExtensionsOnce.includes(file.name.split(".").pop().toLowerCase())
    );
    let imageAlreadyAdded = newSelectedFiles.some((file) =>
      allowedExtensionsMultiple.includes(
        file.name.split(".").pop().toLowerCase()
      )
    );

    files.forEach((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensionsOnce.includes(extension)) {
        if (pdfAlreadyAdded || imageAlreadyAdded) {
          showErrorCustomToast(
            toastRef,
            "You can only add one PDF file or multiple image files."
          );
        } else {
          pdfAlreadyAdded = true;
          newSelectedFiles.push(file);
        }
      } else if (allowedExtensionsMultiple.includes(extension)) {
        if (pdfAlreadyAdded) {
          showErrorCustomToast(
            toastRef,
            "You can only add one PDF file or multiple image files."
          );
        } else {
          imageAlreadyAdded = true;
          newSelectedFiles.push(file);
        }
      } else {
        showErrorCustomToast(
          toastRef,
          `File with extension ${extension} is not allowed`
        );
      }
    });

    setSelectedFiles(newSelectedFiles);

    // Clear the input value to allow the same file to be uploaded again
    event.target.value = "";
  };

  const handleRemoveLogobtn = (index) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);

    // Reset the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  //clear
  const filterAddChargesData = () => {
    let filteredData = {
      communication_type: addChargesData.communication_type,
      sender_type: selectedOption,
      status: addChargesData.status,
    };

    console.log(filteredData, "filetrerdatadt");

    switch (selectedOption) {
      case "new_agent":
      case "new_customer":
        filteredData = {
          ...filteredData,
          name: addChargesData.name,
          phone: addChargesData.phone,
          email: addChargesData.email,
          existing_user: addChargesData.existing_user,
        };
        if (selectedOption === "new_customer") {
          filteredData.email = addChargesData.email;
        }
        break;
      case "existing_agent":
        filteredData.existing_user = addChargesData.existing_user;
        break;
      case "customer":
        filteredData.customer_id = addChargesData.customer_id;
        break;
      default:
        break;
    }

    return filteredData;
  };

  const [submitAsDraft, setSubmitAsDraft] = useState(false);

  console.log(addChargesData, "testtstst")

  useEffect(() => {
    if (submitAsDraft) {
      handleSubmit(null, "draft"); // Pass null for event
      setSubmitAsDraft(false);
    }
  }, [submitAsDraft]);

  const handleSaveAsDraft = (event) => {
    event.preventDefault();

    handleSubmit(event, "draft");
  };

  console.log(selectedObjects, "select");

  const CommunicationValidateForm = (
    addChargesData,
    selectedObjects,
    selectedFiles,
    isEnterPressed
  ) => {
    if (
      selectedObjects.length <= 0 &&
      addChargesData.sender_type === "existing_agent"
    ) {
      showErrorCustomToast(toastRef, "Please select all required fields ");
      return false;
    }

    if (
      !addChargesData.sender_type &&
      addChargesData.communication_type !== "payment_link" &&
      addChargesData.communication_type !== "voucher" &&
      addChargesData.communication_type !== "bill" &&
      addChargesData.communication_type !== "payment-receipt"
    ) {
      showErrorCustomToast(toastRef, "Please select all required fields ");
      return false;
    }
    if (!addChargesData.sender_type && !addChargesData.communication_type) {
      showErrorCustomToast(toastRef, "Please select all required fields");
      return false;
    }
    if (
      addChargesData.sender_type === "new_agent" ||
      addChargesData.sender_type === "new_customer"
    ) {
      if (!addChargesData.name || !addChargesData.email) {
        showErrorCustomToast(toastRef, "Please fill in all required fields");
        return false;
      }
    }

    if (
      selectedFiles.length === 0 &&
      addChargesData.communication_type !== "payment_link" &&
      addChargesData.communication_type !== "voucher" &&
      addChargesData.communication_type !== "bill" &&
      addChargesData.communication_type !== "payment-receipt"
    ) {
      showErrorCustomToast(toastRef, "Please add attachment before sending");
      return false;
    }
    if (
      addChargesData.communication_type === "payment_link" &&
      addChargesData.payment_link === "" 
    ) {
      showErrorCustomToast(toastRef, "Please fill in all required fields");
      return false;
    }
    if (
      addChargesData.communication_type === "payment_link" &&
      addChargesData.payment_link &&
      addChargesData.sender_type === ""
    ) {
      showErrorCustomToast(toastRef, "Please fill in all required fields");
      return false;
    }
    if (
      !addChargesData.sender_type &&
      addChargesData.communication_type !== "voucher" &&
      addChargesData.communication_type !== "bill" &&
      addChargesData.communication_type !== "payment-receipt"
    ) {
      showErrorCustomToast(toastRef, "Please fill in all required fields");
      return false;
    }

    if (
      (addChargesData.communication_type === "voucher" ||
        addChargesData.communication_type === "bill" ||
        addChargesData.communication_type === "payment-receipt") &&
      (!searchName || !isEnterPressed)
    ) {
      showErrorCustomToast(
        toastRef,
        "Please enter a booking ID and press Enter"
      );
      return false;
    }

    if (
      addChargesData.sender_type === "customer" &&
      selectedObjects.length <= 0
    ) {
      // alert("test")
      showErrorCustomToast(toastRef, "Please fill in all required fields");
      return false;
    }

    // if (
    //   addChargesData.exisiting_user === ""
    // ) {

    //  showErrorCustomToast(toastRef,"Please fill in all required fieldsa");
    //   return false;
    // }

    return true;
  };

  const handleSubmit = (event, status) => {
    // setLoader(true)
    if (event.type === "keypress" && event.key === "Enter") {
      // If the Enter key is pressed, just return without performing validation
      return;
    }

    event.preventDefault();

    if (
      !CommunicationValidateForm(
        addChargesData,
        selectedObjects,
        selectedFiles,
        isEnterPressed
      )
    ) {
      return;
    }

    console.log(addChargesData.sender_type, "sendere");

    const filteredData = filterAddChargesData();
    // Only include the status field if it's a draft
    const formData =
      status === "draft" ? { ...filteredData, status } : filteredData;
    setLoader(false);
    submitFormData(formData);
  };

  const submitFormData = (filteredData) => {
    const formData = new FormData();
    formData.append("communication_type", filteredData.communication_type);
    formData.append("sender_type", addChargesData.sender_type);
    formData.append("name", addChargesData.name || "");
    formData.append("email", addChargesData.email || "");
    formData.append("status", filteredData.status);
    formData.append("payment_link", addChargesData.payment_link || "");
    formData.append("phone", filteredData.phone || "");
    formData.append("gst", addChargesData.gst || "");
    formData.append("customer_id", filteredData.customer_id || "");
    formData.append(
      "unique_booking_id",
      addChargesData.unique_booking_id || ""
    );
    formData.append("booking_id", addChargesData.booking_id || "");

    selectedFiles.forEach((file) => {
      formData.append("lead_images[]", file);
    });

    if (filteredData.sender_type === "existing_agent") {
      formData.append("exisiting_user", addChargesData.existing_user);
    }

    console.log(formData, "formdata");

    fetchCommunicationPostMessage(formData);
  };

  const fetchCommunicationPostMessage = (payload) => {
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v2/create/communication/${property_id?.id}`,
      {
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // showSuccessToast(data.message);
          showSuccessfullToast(toastRef, data.message);

          setLoader(false);
          navigate("/communication");
        } else {
          showErrorCustomToast(toastRef, data.error || "Something went wrong");
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorCustomToast(
          toastRef,
          "An error occurred while processing your request."
        );
        setLoader(false);
      });
  };

  // console.log(allAgentTypes, "allagent");

  const fetchAgentLists = async (propertyId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      if (data?.data.length > 0) {
        setAllAgentTypes(data.data);
      } else setAllAgentTypes([]);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      // fetchDashboardInfo(property_id.id, dayTypeFilter);
      fetchAgentLists(property_id.id);
    }
  }, [property_id]);

  console.log(addChargesData, "addcharges");

  const getDataFromCustomRoomChoiceSelect = (data) => {
    // console.log(data?.id, "teststts")

    setSelectedAgentType(data?.id);
    // setSelectedUser(data);
    setAddChargesData({
      ...addChargesData,
      existing_user: data?.id,
    });
  };

  const getDataFromCustomRoomChoiceRadio = (data) => {
    setAddChargesData({
      ...addChargesData,
      existing_user: data,
    });
  };
  const getDataFromCustomerChoice = (data) => {
    setAddChargesData({
      ...addChargesData,
      customer_id: data,
    });
  };

  const choiceListAgentArray = allAgentTypes.map((room) => {
    return { name: room.agent_name, id: room.agent_information };
  });

  console.log(customerData, "customdata");
  console.log(choiceListAgentArray, "customdata");

  const choiceListCustomArray = customerData.map((room) => {
    return { label: room.name, value: room.id };
  });

  // Function to render input field based on selected option
  const renderInputField = () => {
    if (selectedOption === "new_agent" || selectedOption === "new_customer") {
      return (
        <>
          <div
            className="form-container-div-half"
            style={{ marginBottom: "20px" }}
          >
            <div className="form-container-div">
              <VerticalInputFieldCustom
                style={{ outline: "none" }}
                type="name"
                required={true}
                focused={false}
                name="name"
                titleName={
                  selectedOption === "new_agent"
                    ? "Company Name"
                    : "Customer Name"
                }
                onChange={setAddChargesHandleChange}
                autoComplete="off"
                value={addChargesData.name}
              />
            </div>
            <div className="form-container-div">
              <VerticalInputFieldCustom
                style={{ outline: "none" }}
                type="number"
                focused={false}
                name="phone"
                required={true}
                titleName="Phone"
                onChange={setAddChargesHandleChange}
                autoComplete="off"
                value={addChargesData.phone}
              />
            </div>

            {selectedOption === "new_customer" ? (
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  style={{ outline: "none" }}
                  type="email"
                  focused={false}
                  required={true}
                  name="email"
                  titleName="Email"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={addChargesData.email}
                />
              </div>
            ) : null}
          </div>

          {selectedOption !== "new_customer" && (
            <div
              className="form-container-div-half"
              style={{ marginBottom: "20px" }}
            >
              <div className="form-container-div">
                <VerticalInputFieldCustom
                  style={{ outline: "none" }}
                  type="email"
                  focused={false}
                  required={true}
                  name="email"
                  titleName="Email"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={addChargesData.email}
                />
              </div>

              <div
                className="form-container-div"
                style={{ position: "relative" }}
              >
                <VerticalInputFieldCustom
                  style={{ outline: "none" }}
                  type="text"
                  focused={false}
                  name="gst"
                  titleName="GSTIN Number"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={addChargesData.gst}
                />
              </div>
            </div>
          )}
        </>
      );
    } else if (selectedOption === "existing_agent") {
      return (
        <div style={{ marginBottom: "20px" }}>
          {addChargesData.communication_type === "rates_and_availability" ? (
            // <RatesStatusFilter
            //   bookingFIlterData={choiceListAgentArray}
            //   getSelectedBookingFiltersFromChild={
            //     getDataFromCustomRoomChoiceRadio
            //   }
            //   showButtons={true}
            // />
            <CommunicationSingleSelect
              label="Search by name"
              data={choiceListAgentArray}
              getDataFromChild={getDataFromCustomRoomChoiceSelect}
                 clearTags={clearTags}
              setClearTags={setClearTags}
            />
          ) : (
            // <CustomRoomChoiceSelect
            //   roomChoice={choiceListAgentArray}
            //   getDataFromCustomRoomChoiceSelect={
            //     getDataFromCustomRoomChoiceSelect
            //   }
            //   selectedRoomsIds={selectedAgent}
            //   showButtons={true}
            // />
            <CommunicationTagInput
              label="Search by name"
              data={choiceListAgentArray}
              getDataFromChild={handleMultipleDataFromChild}
              clearTags={clearTags}
              setClearTags={setClearTags}
            />
          )}
        </div>
      );
    } else if (selectedOption === "customer") {
      return (
        <div style={{ marginBottom: "20px" }}>
          {addChargesData.communication_type === "rates_and_availability" ? (
            <CommunicationSingleSelect
              label="Search by name"
              data={customerData}
              getDataFromChild={handleGetSingleDataFromChild}
              clearTags={clearTags}
              setClearTags={setClearTags}
            />
          ) : (
            <CommunicationTagInput
              label="Search by name"
              data={customerData}
              getDataFromChild={handleGetDataFromChild}
              setClearTags={setClearTags}
              clearTags={clearTags}
            />
          )}
        </div>
      );
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div style={{ padding: "20px" }}>
        <div
          className="bg-backdropsss "
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
            width: "100",
          }}
        >
          <div>
            <div
              className="flex "
              style={{
                alignItems: "center",
                gap: "10px",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={handleGoBack}
              >
                <svg
                  className="back-btn"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <rect
                    x="29.75"
                    y="29.75"
                    width="29.5"
                    height="29.5"
                    rx="5.75"
                    transform="rotate(180 29.75 29.75)"
                    stroke="#666666"
                    strokeWidth="0.5"
                  />
                  <line
                    x1="21"
                    y1="15.043"
                    x2="9"
                    y2="15.043"
                    stroke="#666666"
                  />
                  <path
                    d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
                    stroke="#666666"
                    strokeWidth="1.2"
                  />
                </svg>
              </div>
              <h4 style={{ fontWeight: "bold", fontSize: "18px" }}>
                Create Message
              </h4>
            </div>

            <div
              style={{ width: "600px", zIndex: "0" }}
              onSubmit={(event) => {
                if (addChargesData.communication_type === "voucher") {
                  console.log(addChargesData.communication_type, "comm_type");
                  event.preventDefault();
                } else {
                  event.preventDefault();
                  console.log(
                    addChargesData.communication_type,
                    "handlesubmite"
                  );
                }
              }}
              className="stock-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  What to Send ?
                </div>
              </div>
              <div className="form-container-div-full">
                <div>
                  <VerticalInputSelectCustomCopy
                    style={{ outline: "none", cursor: "pointer" }}
                    // titleName="Communication Type"
                    name="communication_type"
                    value={addChargesData.communication_type}
                    onChange={handleChange}
                    options={dummyOptions}
                  />
                </div>
                {/* Other input fields */}
              </div>

              {addChargesData.communication_type === "voucher" ||
              addChargesData.communication_type === "bill" ||
              addChargesData.communication_type === "payment-receipt" ? (
                //boking confirmation inputs
                <div
                  className="form-container-div-half"
                  style={{ marginBottom: "20px" }}
                >
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      titleName="Enter Booking Id"
                      name="bookingId"
                      // onChange={(e) => setSearchName(e.target.value)}
                      onChange={(e) => {
                        handleChange(e);
                        setSearchName(e.target.value); 
                      }}
                      value={searchName}
                      type="text"
                      placeholder="Enter Booking Id"
                      required
                      onKeyDown={onKeyPressHandler}
                    />
                  </div>
                  {showNameEmail && bookingDetails && (
                    <>
                      <div
                        className="form-container-div"
                        style={{ width: "200px" }}
                      >
                        <CommunicationVerticalInput
                          style={{ outline: "none", width: "200px" }}
                          type="text"
                          required={true}
                          focused={false}
                          name="name"
                          titleName="Name"
                          autoComplete="off"
                          value={addChargesData.name}
                          onChange={handleNameChange} // Add onChange handler
                        />
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          style={{ outline: "none" }}
                          type="email"
                          required={true}
                          focused={false}
                          name="email"
                          titleName="Email"
                          autoComplete="off"
                          value={addChargesData.email}
                          onChange={handleEmailChange} // Add onChange handler
                        />
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="form-container-div-full">
                  <div>
                    <div
                      className="flex gap_7"
                      style={{ marginBottom: "10px" }}
                    >
                      {addChargesData.communication_type !== "Select" ? (
                        <label className="Create_booking_label flex gap_7">
                          <input
                            type="radio"
                            checked={selectedOption === "existing_agent"}
                            onChange={() =>
                              handleCheckboxChange("existing_agent")
                            }
                          />
                          Existing Agent
                        </label>
                      ) : null}

                      <label className="Create_booking_label flex gap_7">
                        <input
                          type="radio"
                          checked={selectedOption === "new_agent"}
                          onChange={() => handleCheckboxChange("new_agent")}
                        />
                        New Agent
                      </label>

                      <label className="Create_booking_label flex gap_7">
                        <input
                          type="radio"
                          checked={selectedOption === "customer"}
                          onChange={() => handleCheckboxChange("customer")}
                        />
                        Existing Customer
                      </label>

                      <label className="Create_booking_label flex gap_7">
                        <input
                          type="radio"
                          checked={selectedOption === "new_customer"}
                          onChange={() => handleCheckboxChange("new_customer")}
                        />
                        New Customer
                      </label>
                    </div>
                  </div>

                  <div className="form-container-full-div">
                    {selectedOption && renderInputField()}
                  </div>

                  {/* File Upload Section */}
                  {addChargesData.communication_type !==
                    "rates_and_availability" &&
                    addChargesData.communication_type !== "payment_link" && (
                      <div style={{ position: "relative" }}>
                        <input
                          accept="image/*,application/pdf"
                          type="file"
                          id="select-image"
                          multiple
                          onChange={handleFileUploadChange}
                          ref={fileInputRef}
                          className={`${
                            selectedFiles.length === 0
                              ? "featureImages_upload_file_input"
                              : "featureImages_upload_file_input featureImages_addzindex"
                          }`}
                        />

                        {selectedFiles.length > 0 ? (
                          <div
                            className="quote-imgs-thumbs"
                            style={{
                              maxHeight: "300px",
                              overflowY: "auto",
                              scrollbarWidth: "none",
                              msOverflowStyle: "none",
                            }}
                          >
                            {selectedFiles.map((file, index) => {
                              const extension = file.name
                                .split(".")
                                .pop()
                                .toLowerCase();
                              const fileName = file.name;
                              const maxLength = 80;
                              return (
                                <div
                                  key={index}
                                  className="multiple_image_upload_box"
                                  style={{
                                    width:
                                      extension === "pdf"
                                        ? "100%"
                                        : "fit-content",
                                    gap: "0px",
                                    position: "relative",
                                  }}
                                >
                                  {["jpg", "jpeg", "png", "webp"].includes(
                                    extension
                                  ) ? (
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt="uploaded"
                                      className="img-preview-thumb show"
                                    />
                                  ) : (
                                    extension === "pdf" && (
                                      <div
                                        style={{
                                          marginRight: "30px",
                                          display: "flex",
                                          gap: "5px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div className="pdf-icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="26"
                                            height="26"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              d="M8.4636 12.2103C8.4636 11.7543 8.1468 11.4819 7.5882 11.4819C7.3602 11.4819 7.206 11.5041 7.125 11.5257V12.9903C7.221 13.0119 7.3386 13.0197 7.5 13.0197C8.0958 13.0197 8.4636 12.7179 8.4636 12.2103ZM11.9232 11.4963C11.673 11.4963 11.511 11.5185 11.415 11.5407V14.7855C11.511 14.8077 11.6652 14.8077 11.805 14.8077C12.8202 14.8149 13.4826 14.2557 13.4826 13.0713C13.4904 12.0411 12.8868 11.4963 11.9232 11.4963Z"
                                              fill="#E03838"
                                            />
                                            <path
                                              d="M18.5508 9.58994H18.144V7.62734C18.1437 7.61487 18.1425 7.60244 18.1404 7.59014C18.1411 7.5112 18.1131 7.4347 18.0618 7.37474L14.796 3.64514L14.7936 3.64274C14.7521 3.59773 14.6993 3.56477 14.6406 3.54734L14.6226 3.54134C14.5977 3.53512 14.5721 3.5319 14.5464 3.53174H6.5202C6.1536 3.53174 5.856 3.82994 5.856 4.19594V9.58994H5.4492C4.9248 9.58994 4.5 10.0147 4.5 10.5391V15.4759C4.5 15.9997 4.9254 16.4251 5.4492 16.4251H5.856V19.8049C5.856 20.1709 6.1536 20.4691 6.5202 20.4691H17.4798C17.8458 20.4691 18.144 20.1709 18.144 19.8049V16.4251H18.5508C19.0752 16.4251 19.5 15.9997 19.5 15.4759V10.5391C19.5 10.0147 19.0746 9.58994 18.5508 9.58994ZM6.5202 4.19594H14.214V7.59434C14.214 7.77794 14.3628 7.92614 14.5464 7.92614H17.4804V9.58994H6.5202V4.19594ZM14.6742 13.0339C14.6742 13.9831 14.3286 14.6383 13.8492 15.0427C13.3272 15.4765 12.5328 15.6829 11.5614 15.6829C10.98 15.6829 10.5684 15.6457 10.2882 15.6097V10.7383C10.7004 10.6723 11.2374 10.6357 11.8044 10.6357C12.7458 10.6357 13.3566 10.8049 13.8348 11.1655C14.3502 11.5477 14.6742 12.1585 14.6742 13.0339ZM6.0126 15.6319V10.7383C6.3582 10.6795 6.8442 10.6357 7.5282 10.6357C8.22 10.6357 8.7132 10.7683 9.0438 11.0329C9.36 11.2831 9.573 11.6953 9.573 12.1807C9.573 12.6667 9.4116 13.0783 9.117 13.3579C8.7342 13.7185 8.1678 13.8805 7.5054 13.8805C7.3584 13.8805 7.2258 13.8733 7.1232 13.8583V15.6319H6.0126ZM17.4798 19.6249H6.5202V16.4251H17.4798V19.6249ZM18.4422 11.5921H16.536V12.7255H18.3168V13.6381H16.536V15.6319H15.411V10.6723H18.4422V11.5921Z"
                                              fill="#E03838"
                                            />
                                          </svg>
                                        </div>
                                        <a
                                          href={URL.createObjectURL(file)}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ color: "#333333" }}
                                        >
                                          {/* {file.name} */}
                                          {fileName.length > maxLength
                                            ? `${fileName.slice(
                                                0,
                                                maxLength
                                              )}...`
                                            : fileName}
                                        </a>
                                      </div>
                                    )
                                  )}

                                  <div
                                    className="featureImages_btnContainer"
                                    style={{
                                      position: "absolute",
                                      left: extension !== "pdf" ? "124px" : "",
                                      right: extension === "pdf" ? "4px" : "",
                                      top: extension === "pdf" ? "6px" : "6px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      onClick={() => handleRemoveLogobtn(index)}
                                      className="featureImages_removeLogobtn"
                                      style={{ backgroundColor: "transparent" }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <circle
                                          cx="8"
                                          cy="8"
                                          r="8"
                                          fill="#3968ED"
                                        />
                                        <path
                                          d="M4.44336 11.5554L11.5545 4.44434"
                                          stroke="white"
                                          strokeWidth="1.2"
                                        />
                                        <path
                                          d="M11.5545 11.5557L4.44336 4.44455"
                                          stroke="white"
                                          strokeWidth="1.2"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div
                            className="featureImages_uplaodFile_displaytext_container"
                            style={{ position: "relative ", height: "80px" }}
                          >
                            <p
                              className="featured_image_text fs-13"
                              style={{
                                backgroundColor: "#ECF1FD",
                                color: "#3968ED",
                                marginBottom: "6px",
                                marginTop: "6px",
                              }}
                            >
                              Add Files
                            </p>
                            <p
                              className="featured_image_text_dragdrop fs-13"
                              style={{ color: "#3968ED", marginBottom: "6px" }}
                            >
                              or drop JPG, PNG & PDF images here
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                </div>
              )}

              {/* //payment link  */}

              {addChargesData.communication_type === "payment_link" ? (
                <>
                  <div className="form-container-div-full d-flex column">
                    {/* <VerticalInputFieldCustom
    style={{ outline: "none" }}
    type="string"
    focused={false}
    name="payment_link"
    titleName="Payment Link"
    onChange={setAddChargesHandleChange}
    autoComplete="off"
    value={addChargesData.payment_link} */}
                    <label>Payment Link</label>
                    <input
                      type="url"
                      name="payment_link"
                      onChange={setAddChargesHandleChange}
                      value={addChargesData.payment_link}
                      style={{
                        marginTop: "5px",
                        width: "100%",
                        minHeight: "2.25rem",
                        border: isUrlValid(addChargesData.payment_link)
                          ? "0.0625rem solid rgb(136, 136, 136)"
                          : "0.0625rem solid rgb(136, 136, 136)",
                        borderRadius: "0.25rem",
                        paddingLeft: "5px",
                        outline: "none",
                        cursor: "pointer",
                        backgroundColor: isUrlValid(addChargesData.payment_link)
                          ? "transparent"
                          : "rgb(255, transparent, 234)",
                      }}
                    />
                  </div>
                </>
              ) : null}

              <div className="flex gap-7 " style={{ gap: "10px" }}>
                {addChargesData.communication_type !==
                  "rates_and_availability" && (
                  <>
                    <BgThemeButton
                      type="submit"
                      loader={loader}
                      onClick={(event) => handleSubmit(event, "send")}
                    >
                      Send
                    </BgThemeButton>

                    {showNameEmail && bookingDetails && (
                      <>
                        {addChargesData.communication_type !==
                        "payment-receipt" ? (
                          <BgOutlineThemeButton
                            onClick={(e) =>
                              handleSubmitPreview(
                                e,
                                "preview",
                                "Booking Voucher"
                              )
                            }
                            style={{ height: "40px", width: "100px" }}
                            className="more_options_btn"
                          >
                            Preview
                          </BgOutlineThemeButton>
                        ) : (
                          <BgOutlineThemeButton
                            onClick={(e) =>
                              handleCommunicationSubmitPreviewVoucher(
                                addChargesData.communication_type,
                                addChargesData.unique_booking_id
                              )
                            }
                            style={{ height: "35px", width: "100px" }}
                            className="more_options_btn"
                          >
                            Preview
                          </BgOutlineThemeButton>
                        )}
                      </>
                    )}

                    <WithoutBgButtonBlue onClick={handleSaveAsDraft}>
                      Save as Draft
                    </WithoutBgButtonBlue>
                  </>
                )}
              </div>

              {}

              {/* {filterName === "B2B rates" ||
            filterName === "Property brochure" ? (
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <BgThemeButton
                    style={{ width: "100%" }}
                    children={"Send"}
                    type={"submit"}
                  />
                </div>

                <div className="form-container-div">
                  <BgOutlineThemeButton
                    style={{ width: "100%" }}
                    children={"Preview"}
                    type={"button"}
                  />
                </div>
              </div>
            ) : null} */}

              {/* {filterName === "Payment link" ||
            filterName === "Property images" ? (
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <BgThemeButton children={"send"} />
                </div>
              </div>
            ) : null} */}
            </div>
          </div>
        </div>

        {addChargesData.communication_type === "rates_and_availability" ? (
          <div className="communication-create-message-card-wrap">
            <CreateMessageNewPage
              name={addChargesData.name}
              email={addChargesData.email}
              phone={addChargesData.phone}
              sender_type={addChargesData.sender_type}
              customer_id={addChargesData.customer_id}
              agent_id={addChargesData.existing_user}
              gst={addChargesData.gst}
              communication_type={addChargesData.communication_type}
              dontShowCreateMessage={true}
              validateSenderType={true}
            />
          </div>
        ) : null}
      </div>
      {showBookingPreviewVoucherPopup && (
        <>
          <div className="bg-backdrop">
            <section
              className="communication_iframe_container"
              // style={{ pointerEvents: "none" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                  padding: "0 25px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  {addChargesData.communication_type
                    .replace(/[_-]/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </div>

                <div
                  onClick={(e) => setShowBookingPreviewVoucherPopup(false)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>
              <iframe
                title="Preview"
                src={iframeSrc}
                width="100%"
                height="100%"
              />
              {/* <PreviewCommunicationVoucher uniquesearchName={searchName} /> */}
            </section>
          </div>
        </>
      )}
      {/* Show Booking Preview Bill PopUp */}
      {showBookingPreviewBillPopup && (
        <>
          <div className="bg-backdrop">
            <section className="communication_preview_voucher_popup_container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                  padding: "0 25px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  Booking Bill
                </div>
                <div
                  onClick={handleToggleBookingPreviewBillPopup}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>
              <PreviewCommunicationBillCopy uniquebookingid={searchName} />
            </section>
          </div>
        </>
      )}

      {/* Show Booking Payment Receipt PopUp */}
      {showBookingPaymentReceiptPopup && (
        <>
          <div className="bg-backdrop">
            <section className="communication_preview_voucher_popup_container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                  padding: "0 25px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  Booking Payment Receipt
                </div>
                <div
                  onClick={() => setShowBookingPaymentReceiptPopup(false)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>
              <PaymentReceiptCommunication
                uniquesearchName={searchName}
                data={bookingDetails}
              />
            </section>
          </div>
        </>
      )}
      {/* <ToastContainer ref={toastRef} rtl={false} /> */}
    </>
  );
};

const withSendCard = (CommunicationWrappedComponent) => {
  return () => (
    <div>
      <CommunicationSendCard />
      <CommunicationWrappedComponent />
    </div>
  );
};

export default CommunicationCreateNewPage;
