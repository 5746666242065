import React, { useState } from "react";
import { popupStyles } from "./styles";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import SearchComponent from "../SearchComponent/SearchComponent";
import SelectDateRange from "../SelectDateRange/SelectDateRange";
import { BgThemeButton, BgOutlineThemeButton } from "../../UI/Buttons";

const ReleaseRoomsManualAgentsPopup = () => {
  const [holdRoomPopup, setHoldRoomPopup] = useState(false);
  const [checkedCategories, setCheckedCategories] = useState({});
  const handleHoldRoomsButtonClick = () => {
    setHoldRoomPopup(true);
  };
  const [selectedAgent, setSelectedAgent] = useState("contracted");
  const handleRadioAgentChange = (agentType) => {
    setSelectedAgent(agentType);
  };

  const handleDateChange = (form_date, to_date) => {};

  const roomCategories = [
    "Deluxe",
    "Super Deluxe",
    "Luxury",
    "Executive",
    "Family Suite",
  ];
  const handleCheckboxChange = (category) => {
    setCheckedCategories((prev) => {
      const newCheckedCategories = { ...prev, [category]: !prev[category] };

      // Call API if checkbox is checked
      if (newCheckedCategories[category]) {
        // Replace the below line with your API call
        console.log(`API called for ${category}`);
        // Assuming the API returns a value for the input, you can set it in the state
        newCheckedCategories[`${category}Input`] = "API response value";
      } else {
        // Remove the input value if unchecked
        delete newCheckedCategories[`${category}Input`];
      }

      return newCheckedCategories;
    });
  };

  const [selectedRelease, setSelectedRelease] = useState("auto_release");

  const handleReleaseRadioChange = (name) => {
    setSelectedRelease(name);
  };

  return (
    <div style={popupStyles.container}>
      <div style={popupStyles.containerChild}>
        <h4 style={popupStyles.popupHeadings}>Release Rooms</h4>

        <div>
          <table style={popupStyles.tableBig}>
            <thead>
              <tr>
                <th style={{ paddingRight: "5px" }}>Company Name</th>
                <th style={{ padding: "0 5px" }}>Phone (optional)</th>
                <th style={{ paddingLeft: "5px" }}>Select Date Range</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ paddingRight: "5px" }}>
                  <SearchComponent />
                </td>
                <td style={{ padding: "0 5px" }}>
                  <VerticalInputFieldCustom />
                </td>
                <td style={{ paddingLeft: "5px" }}>
                  <SelectDateRange dataFromChild={handleDateChange} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* ===========room Categories=========== */}
        <div>
          <h4 style={popupStyles.categoryHeading}>Select Room Categories</h4>
          {roomCategories.map((item, index) => (
            <div
              key={index}
              style={{ height: "40px" }}
              className="flex justify_content_between align_items_center"
            >
              <div>
                <label className="flex flex_gap_10 pointer">
                  <input
                    type="checkbox"
                    checked={!!checkedCategories[item]}
                    onChange={() => handleCheckboxChange(item)}
                  />{" "}
                  <span>{item}</span>
                </label>
              </div>
              <div>
                {checkedCategories[item] && (
                  <div className="flex flex_gap_15">
                    <div className="flex flex_gap_5 align_items_center">
                      <span>Avl. Rooms</span>
                      <VerticalInputFieldCustom
                        disabled={true}
                        style={popupStyles.smallInput}
                      />
                    </div>

                    <div className="flex flex_gap_5 align_items_center">
                      <span>Hold Rooms</span>
                      <VerticalInputFieldCustom
                        style={popupStyles.smallInput}
                        type="text"
                        value={checkedCategories[`${item}Input`] || ""}
                        readOnly
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex_gap_10" style={{ marginTop: "20px" }}>
          <BgThemeButton children={"Update"} />
          <BgOutlineThemeButton children={"Cancel"} />
        </div>
      </div>
    </div>
  );
};

export default ReleaseRoomsManualAgentsPopup;
