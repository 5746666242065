// import React, { useState, useEffect, useRef } from 'react';
// import moment from 'moment';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { BgOutlineThemeButton, BgThemeButton } from '../UI/Buttons';

// const CommunicationCustomDatePicker = ({ onSelectedRangeChange , onApplyButtonClick , onDateRangeSelected , onOptionSelection}) => {
//     const [startDate, setStartDate] = useState(moment().subtract(29, 'days').toDate());
//     const [endDate, setEndDate] = useState(moment().toDate());
//     const [selectedRange, setSelectedRange] = useState('Last 30 Days');
//     const [showOptionsPopup, setShowOptionsPopup] = useState(false);
//     const [showCustomPicker, setShowCustomPicker] = useState(false);
//     const inputRef = useRef(null);
//     const optionsPopupRef = useRef(null);
//     const customPickerRef = useRef(null);
//     const options = [
//         { label: 'Today', value: -1 },
//         { label: 'Last 7 Days', value: "-7" },
//         { label: 'Last 30 Days', value: "-30" },
//         { label: 'Last 90 Days', value: "-90" },
//         { label: 'Future 7 Days', value:" +7 "},
//         { label: 'Future 30 Days', value: '+30' },
//         { label: 'Future 90 Days', value: "+90" },
//         { label: 'Custom Range', value: '' }
//     ];

//     useEffect(() => {
//         updateInputValue();
//     }, [selectedRange, startDate, endDate]);

//     useEffect(() => {
//         const start = moment().subtract(29, 'days').toDate();
//         const end = moment().toDate();
//         handleDateChange([start, end]);
//     }, []);

//     const updateInputValue = () => {
//         const selectedOption = options.find(option => option.value === selectedRange);
//         if (selectedOption) {
//             inputRef.current.value = selectedOption.label;
//         } else if (selectedRange === 'Custom Range') {
//             const rangeLabel = `${moment(startDate).format('DD/MM/YYYY')} - ${endDate ? moment(endDate).format('DD/MM/YYYY') : ''}`;
//             inputRef.current.value = rangeLabel;
//         }
//     };

//     const formatDate = (date) => {
//         return moment(date).format('DD/MM/YYYY');
//     };

//     const handleDateChange = (dates) => {
//         const [start, end] = dates;
//         setStartDate(start);
//         setEndDate(end);
//     };

//     const handleOptionSelection = (selectedRange, rangeValue) => {
//         if (selectedRange === 'Custom Range') {
//             setSelectedRange(selectedRange);
//             setShowOptionsPopup(true);
//             setShowCustomPicker(true);
//         } else {
//             setSelectedRange(rangeValue);
//             closePopups();
//             onSelectedRangeChange(rangeValue);
//         }
//     };

//     const closePopups = () => {
//         setShowOptionsPopup(false);
//         setShowCustomPicker(false);
//     };

//     const handleApply = () => {
//         if (selectedRange === 'Custom Range') {
//             setSelectedRange(`${formatDate(startDate)} - ${formatDate(endDate)}`);
//             const from_date = formatDateToISO(startDate);
//             const to_date = formatDateToISO(endDate);
//             onDateRangeSelected({ from_date, to_date });
//             onApplyButtonClick({ from_date, to_date });
//         }

//         closePopups();
//     };

//     const handleCancel = () => {
//         closePopups();
//     };
//     // const clearDateRange = () => {
//     //     setSelectedRange(''); // Clear selectedRange state
//     //     setStartDate(moment().subtract(29, 'days').toDate()); // Reset startDate
//     //     setEndDate(moment().toDate()); // Reset endDate
//     //     updateInputValue(); // Update input field value
//     // };
//     const formatDateToISO = (date) => {
//         return moment(date).format('YYYY-MM-DD');
//     };
//     return (
//         <div style={{ position: 'relative' }}>
//             <input
//             className='date_range_input'
//                 type="text"
//                 ref={inputRef}
//                 onClick={() => setShowOptionsPopup(!showOptionsPopup)}
//                 placeholder="Select Date Range"
//                 readOnly
//                 style={{ cursor: 'pointer', padding: '10px 5px', border: '1px solid #ddd', outline: 'none', borderRadius: '4px' }}
//             />
//             {showOptionsPopup && (
//                 <div ref={optionsPopupRef} className="options-popup">
//                     {options.map(option => (
//                         <div key={option.label} onClick={() => handleOptionSelection(option.label, option.value)} className='popup_options_item'>{option.label}</div>
//                     ))}
//                     {showCustomPicker && (
//                         <div className="date-picker-animation">
//                             <DatePicker
//                                 selected={startDate}
//                                 startDate={startDate}
//                                 endDate={endDate}
//                                 onChange={handleDateChange}
//                                 selectsRange
//                                 inline
//                             />
//                         </div>
//                     )}
//                     <div className='flex flex_gap_10' style={{ textAlign: 'center', margin: '10px' }}>
//                         <BgThemeButton children={"Apply"} onClick={handleApply} />
//                         <BgOutlineThemeButton children={"Cancel"} onClick={handleCancel} />
//                         {/* <BgOutlineThemeButton children={"Clear"} onClick={clearDateRange} />  */}
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default CommunicationCustomDatePicker;

import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";

const CommunicationCustomDatePicker = ({
  onSelectedRangeChange,
  onApplyButtonClick,
  onDateRangeSelected,
  onOptionSelection,
  sendDataToParent,
  customStyle,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRange, setSelectedRange] = useState(-1);
  const [showOptionsPopup, setShowOptionsPopup] = useState(false);
  const [showCustomPicker, setShowCustomPicker] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const inputRef = useRef(null);
  const optionsPopupRef = useRef(null);
  const customPickerRef = useRef(null);
  const options = [
    { label: "Today", value: -1 },
    { label: "Last 7 Days", value: -7 },
    { label: "Last 30 Days", value: -30 },
    { label: "Last 90 Days", value: -90 },
    { label: "Future 7 Days", value: 7 },
    { label: "Future 30 Days", value: 30 },
    { label: "Future 90 Days", value: 90 },
    { label: "Custom Range", value: "" },
  ];

  useEffect(() => {
    sendDataToParent(isOpened);
    updateInputValue();
  }, [selectedRange, startDate, endDate, isOpened]);

  console.log(isOpened, "customstyle");

  //   useEffect(() => {
  //     const start = new Date(new Date().setDate(new Date().getDate() - 29));
  //     const end = new Date();
  //     handleDateChange([start, end]);
  //   }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      optionsPopupRef.current &&
      !optionsPopupRef.current.contains(event.target)
    ) {
      closePopups();
      setIsOpened(false);
    }
  };

  const updateInputValue = () => {
    const selectedOption = options.find(
      (option) => option.value.toString() === selectedRange.toString()
    );
    if (selectedOption) {
      inputRef.current.value = selectedOption.label;
    } else if (selectedRange === "Custom Range") {
      let rangeLabel = "Select Date Range";
      if (startDate && endDate) {
        rangeLabel = `${formatDate(startDate)} - ${formatDate(endDate)}`;
      }
      inputRef.current.value = rangeLabel;
    }
  };

  const formatDate = (date) => {
    const dd = String(date?.getDate()).padStart(2, "0");
    const mm = String(date?.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = date?.getFullYear();
    return `${dd}/${mm}/${yyyy}`;
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleOptionSelection = (selectedRange, rangeValue) => {
    if (selectedRange === "Custom Range") {
      setSelectedRange(selectedRange);
      setShowOptionsPopup(true);
      setShowCustomPicker(true);
    } else {
      setSelectedRange(rangeValue);
      closePopups();
      setIsOpened(false);
      onSelectedRangeChange(rangeValue);
    }
  };

  const closePopups = () => {
    setShowOptionsPopup(false);
    setShowCustomPicker(false);
  };

  const handleApply = () => {
    if (selectedRange === "Custom Range") {
      setSelectedRange(`${formatDate(startDate)} - ${formatDate(endDate)}`);
      const from_date = formatDateToISO(startDate);
      const to_date = formatDateToISO(endDate);
      onDateRangeSelected({ from_date, to_date });
      onApplyButtonClick({ from_date, to_date });
    }

    closePopups();
  };

  const handleClearFilters = () => {
    setSelectedRange(-1);
    handleOptionSelection("option.label", -1);

    closePopups();
    setIsOpened(false);
  };

  const handleCancel = () => {
    closePopups();
  };

  const formatDateToISO = (date) => {
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = date.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        onClick={() => {
          setShowOptionsPopup(!showOptionsPopup);
          setIsOpened(true);
        }}
        style={{
          position: "relative",
          zIndex: "1000000",
          border: "1px solid #AAAAAA",
          borderRadius: "4px",
          padding: "0px 5px",
          alignItems: "center",
          cursor: "pointer",
        }}
        className="d_flex"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath="url(#clip0_1_5515)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.625 2H11.75V1.25C11.75 0.835786 11.4142 0.5 11 0.5C10.5858 0.5 10.25 0.835786 10.25 1.25V2H5V1.25C5 0.835786 4.66421 0.5 4.25 0.5C3.83579 0.5 3.5 0.835786 3.5 1.25V2H2.375C1.775 2 1.25 2.525 1.25 3.125V14.375C1.25 14.975 1.775 15.5 2.375 15.5H13.625C14.225 15.5 14.75 14.975 14.75 14.375V3.125C14.75 2.525 14.225 2 13.625 2ZM2.75 14H13.25V6.5H2.75V14Z"
              fill="#333333"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_5515">
              <rect
                width="15"
                height="15"
                fill="white"
                transform="translate(0.5 0.5)"
              />
            </clipPath>
          </defs>
        </svg>

        <input
          className="date_range_input"
          type="text"
          ref={inputRef}
          placeholder="Select Date Range"
          readOnly
          style={{
            cursor: "pointer",
            padding: "7px 5px",
            border: "0",
            outline: "none",
          }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
        >
          <path d="M1 1L5 5L9 1" stroke="#333333" />
        </svg>
      </div>
      {showOptionsPopup && (
        <div
          ref={optionsPopupRef}
          className={`options-popup ${
            customStyle ? "communication-custom-padding" : ""
          }`}
        >
          {options.map((option) => (
            <div
              key={option.label}
              onClick={() => handleOptionSelection(option.label, option.value)}
              className="popup_options_item"
            >
              {option.label}
            </div>
          ))}
          {showCustomPicker && (
            <div className="date-picker-animation">
              <DatePicker
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                selectsRange
                inline
              />
            </div>
          )}
          <div
            className="flex flex_gap_10"
            style={{ textAlign: "center", margin: "10px" }}
          >
            <BgThemeButton children={"Apply"} onClick={handleApply} />
            <BgOutlineThemeButton children={"Cancel"} onClick={handleCancel} />
            <BgOutlineThemeButton
              children={"Clear"}
              onClick={handleClearFilters}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CommunicationCustomDatePicker;
