import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { formatIndianCurrency } from "../../utils/utils";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";

const ReportCityLedgerReport = ({
  departureReport,
  loader,
  fullData,
  donShowTitle,
}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>{order.date}</CustomReportTableCell>
        <CustomReportTableCell>
          {order.guest_name ? order.guest_name : "N/A"}
        </CustomReportTableCell>

        <CustomReportTableCell>
          {" "}
          {order.guest_name ? (
            <div>
              #{order.unique_booking_id} Folio-{order.folio_no} ({order.room_no}
              )
            </div>
          ) : (
            "Paid Credit"
          )}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.kot_no ? order.kot_no : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(order.total_credits)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(order.credit_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(order.debit_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.available_credit_limit
            ? formatIndianCurrency(order.available_credit_limit)
            : "0"}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));

  // const downloadData = departureReport.map((report) => {
  //   return {
  //     date: report.date,
  //     guest_name: report.guest_name ? report.guest_name : "N/A",
  //     reference: report.guest_name
  //       ? `#${report.unique_booking_id} Folio-${report.folio_no} (${report.room_no})`
  //       : "Paid Credit",
  //     credits: report.credit_amount,
  //     debits: report.debit_amount,
  //     available_credit_limit: report.available_credit_limit,
  //   };
  // });

  const names = [
    {
      date: "Date",
      guest_name: "Guest Name",
      reference: "Ref",
      credits: "Credits",
      debits: "Debits",
      available_credit_limit: "Balance",
    },
  ];

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container" id="cityLedgerReport">
          <div className="room-types-full-container-div">
            <div className="room-type-heading">City Ledger Report </div>
            {departureReport?.length > 0 && (
              <div className="flex flex_gap_15" id="doNotPrint">
                <ButtonToDownloadTablePDF
                 itemToDownloadID={"cityLedgerReport"}
                 downloadFileName={"cityLedgerReport"}
                 ignoreFromPrint={"doNotPrint"}
                
                />
                <DownloadCSVButton  itemToDownloadID={"cityLedgerReport"}
                 downloadFileName={"cityLedgerReport"}/>
              </div>
            )}
          </div>

          <div style={{ display: "flex", gap: "30px", marginBottom: "20px" }}>
            <div>
              <div>
                {" "}
                <strong>Total Credit Limit</strong> :{" "}
                {formatIndianCurrency(fullData?.companyCredit?.credit_amount)}
              </div>
            </div>
            <div>
              <div>
                {" "}
                <strong>Available Credit Limit</strong> :{" "}
                {formatIndianCurrency(fullData?.companyCredit?.balance_amount)}
              </div>
            </div>
            <div>
              <div>
                {" "}
                <strong>Used Credit Limit</strong> :{" "}
                {formatIndianCurrency(fullData?.companyCredit?.used_amount)}
              </div>
            </div>
          </div>
          {departureReport && departureReport.length > 0 ? (
            <>
              {/* <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={departureReport.length}
                  selectable={false}
                  headings={[
                    { title: "Date" },
                    { title: "Guest Name" },
                    { title: "Reference" },
                    { title: "KOT No" },
                    { title: "Total Credits" },
                    { title: "Credits " },
                    { title: "Debits" },
                    { title: "Balance" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
              </LegacyCard> */}

              <CustomReportTable
                allItems={departureReport} // table data
                // selectedItems={selectedArray} //selected items from table
                // setSelectedItems={setSelectedArray} // state from parent component to update table
                showCheckbox={false}
                headings={[
                  "Date",
                  "Guest Name",
                  "Reference",
                  "KOT No",
                  "Total Credits",
                  "Credits",
                  "Debits",
                  "Balance",
                ]}
                headerStyles={{
                  backgroundColor: "#dddddd",
                  fontSize: "12px",
                  textAlign: "center",
                }}
                tableStyles={{ textAlign: "left" }}
              >
                {rowMarkup}
              </CustomReportTable>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>

        // </div>
      )}
    </>
  );
};
export default ReportCityLedgerReport;
