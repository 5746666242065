import React, { useEffect } from "react";
// import Dashboard from "../Components/Dashboard";
import MainDashboard from "../Components/Dashboard/MainDashboard/MainDashboard";

const DashboardPage = () => {
  useEffect(() => {
    // Set the meta title
    document.title =
      "Dashboard - RevCatalyst - Boosting Your Hotel Performance";

    // Create or update the meta description tag
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);

  return (
    <>
      <div className="main_container">
        {/* <Dashboard /> */}
        <MainDashboard />
      </div>
    </>
  );
};

export default DashboardPage;
