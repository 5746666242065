import React, { useEffect, useState } from "react";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import {
  unstable_HistoryRouter,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
} from "@shopify/polaris";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import StatusUpdate from "./CustomComponents/StatusUpdate";
import NoDataFound from "./CustomComponents/NoDataFound";
import { useSelector } from "react-redux";
import { formatIndianCurrency } from "../utils/utils";
import Loader from "./UI/Loader/Loader";
import GoBackButtonCustom from "./GoBackButtonCustom/GoBackButtonCustom";
import { showErrorToast } from "../assets/toastUtils";
import BookingDetailSliderNew from "./CustomComponents/BookingDetails/BookingDetailsSliderNew";
import TextDateFormatter from "./Communication/TextDateFormatter/TextDateFormatter";
import { WithoutBgButtonBlue } from "./UI/Buttons";
import UnderscoreRemoveCapitalize from "./CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";

const EditAgentProfile = () => {
  const { agentid } = useParams();

  const navigate = useNavigate();
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [orders, setOrders] = useState([]);
  const [allData, setAllData] = useState([]);
  // fetch data
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(true);

  const customers_inner_list = [
    {
      name: "Used Credit",
      link: `/credit/${agentid}`,
      permission: "read_customers",
    },
    {
      name: "Payments",
      link: `/transfer/${agentid}`,
      permission: "read_agents",
    },
    {
      name: "Added Credits",
      link: `/agents/credits/${agentid}`,
      permission: "read_agents",
    },
    // {
    //   name: "Advance Payments",
    //   link: `/agents/advance/payments/${agentid}`,
    //   permission: "read_agents",
    // },
    {
      name: "Bookings",
      link: `/agents/bookings/${agentid}`,
      permission: "read_agents",
    },
    {
      name: "Credit Finance Logs",
      link: `/agents/credit/Logs/${agentid}`,
      permission: "read_agents",
    },
    {
      name: "City Ledger Report",
      link: `/agents/city/Ledger/${agentid}`,
      permission: "read_agents",
    },
  ];

  const [masterFolioDetails, setMasterFolioDetails] = useState(null);

  const [openSingleBookingDetails, setOpenSingleBookingDetails] =
    useState(false);

  const [chargesDetails, setChargesDetails] = useState(null);
  const [bookingDetails, setBookingDetails] = useState(null);

  const getSingleBookingDataHandler = (bookingId) => {
    // setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOpenSingleBookingDetails(true);
          setLoader(false);
          setBookingDetails(data?.booking);
          setChargesDetails(data?.charges);
          setMasterFolioDetails(data?.masterFolio);
        } else {
          setLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchInfoCreditAmount(property_id?.id);
    }
  }, [property_id]);

  const fetchInfo = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/company/credits/${agentid}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      // setOrders(data?.data);
      setAllData(data);

      // setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  const fetchInfoCreditAmount = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/credit_transaction/${agentid}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      setOrders(data?.data);
      // setOrders(data);
      setLoader(false);
      // setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  const rowMarkup = orders?.map((data, index) => {
    return (
      <IndexTable.Row
        id={data.id}
        key={data.id}
        selected={selectedResources.includes(data.id)}
        position={data.id}
      >
        <IndexTable.Cell>{index + 1}</IndexTable.Cell>
        <IndexTable.Cell>
          {data.date ? (
            <TextDateFormatter date={data.date} showYear={true} as={"div"} />
          ) : (
            "N/A"
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {data.folio_no ? (
            <WithoutBgButtonBlue
              style={{ cursor: "pointer", color: "#3B82F6" }}
              onClick={() => [
                localStorage.removeItem("selectedFolioValue", ""),
                localStorage.removeItem("selectedFolioLabel", ""),
                navigate(`/bookings/folio/${data.booking_id}`),
              ]}
            >
              {data.folio_no}
            </WithoutBgButtonBlue>
          ) : (
            "N/A"
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {data.kot_order_no ? data.kot_order_no : "N/A"}
        </IndexTable.Cell>

        <IndexTable.Cell>
          {data.unique_booking_id ? (
            <span
              style={{ cursor: "pointer", color: "#3B82F6" }}
              onClick={() =>
                getSingleBookingDataHandler(data.unique_booking_id)
              }
            >
              #{data.unique_booking_id}
            </span>
          ) : (
            "N/A"
          )}
        </IndexTable.Cell>

        <IndexTable.Cell>
          {formatIndianCurrency(data.total_credits)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatIndianCurrency(data.credit_amount)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatIndianCurrency(data.debit_amount)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {<UnderscoreRemoveCapitalize text={data.transaction_type} />}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatIndianCurrency(data.used_credit_limit)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatIndianCurrency(data.available_credit_limit)}
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  useEffect(() => {
    if (selectedResources.length === 1) {
      // getOneData(selectedResources[0]);
      // setShowLowerMenuSingleSelect(true);
      // setShowLowerMenuMultipleSelect(false);
      // setShowLowerMenuAddMenu(false);
    } else if (selectedResources.length > 1) {
      // setShowLowerMenuMultipleSelect(true);
      // setShowLowerMenuSingleSelect(false);
      // setShowLowerMenuAddMenu(false);
    } else {
      // setAddItemData({
      //   room_name: "",
      //   room_no: "",
      //   floor_no: "",
      //   status: "clean",
      //   room_id: fetchedRoomType ? fetchedRoomType[0]?.id : "",
      //   property_id: property_id?.id,
      // });
      // setShowLowerMenuSingleSelect(false);
      // setShowLowerMenuAddMenu(false);
      // setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  return (
    <>
      <SidebarInnerLists innerLists={customers_inner_list} />

      <div
        className="booking-choice-full-parent-container-right-heading-new"
        style={{ marginTop: "10px" }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#EFF0F2",
          }}
        >
          <div className="reservation_bar_container" style={{ width: "100%" }}>
            <div style={{ marginTop: "10px" }}>
              <GoBackButtonCustom onClick={() => navigate("/agents")} />
            </div>
            <div className="reservation_total ">
              <h5 className="reservation_total_heading">
                {formatIndianCurrency(allData?.data?.credit_amount)}
              </h5>
              <p className="reservation_total_paragraph">Total Credit Limit</p>
            </div>
            <div className="reservation_total confirmed">
              <h5 className="reservation_total_heading">
                {formatIndianCurrency(allData?.data?.balance_amount)}
              </h5>
              <p className="reservation_total_paragraph">Available Cred. Lmt</p>
            </div>
            <div className="reservation_total confirmed">
              <h5 className="reservation_total_heading">
                {formatIndianCurrency(allData?.data?.used_amount)}
              </h5>
              <p className="reservation_total_paragraph">Used Cred. Lmt.</p>
            </div>
          </div>
        </div>
      </div>

      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container">
          {orders && orders.length > 0 ? (
            <>
              <LegacyCard>
                <IndexTable
                  selectable={false}
                  resourceName={resourceName}
                  itemCount={orders.length}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: "S No." },
                    { title: "Date" },
                    { title: "Folio No." },
                    { title: "KOT No." },
                    { title: "Booking ID" },
                    { title: "Total Credits" },
                    { title: "Credit Amount" },
                    { title: "Debit Amount" },
                    { title: "Transaction Type" },
                    { title: "Used" },
                    { title: "Available" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
              </LegacyCard>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      )}

      {openSingleBookingDetails && (
        // <BookingDetails
        //   bookingDetails={bookingDetails}
        //   setShowBookingDetails={() => setOpenSingleBookingDetails(false)}
        // />

        // <BookingDetailsSlider
        //   bookingDetails={bookingDetails}
        //   setShowBookingDetails={setOpenSingleBookingDetails}
        // />

        <BookingDetailSliderNew
          data={bookingDetails}
          chargesDetails={chargesDetails}
          masterFolioDetails={masterFolioDetails}
          setShowBookingDetails={setOpenSingleBookingDetails}
          getSingleBookingDataHandler={getSingleBookingDataHandler}
        />
      )}
    </>
  );
};

export default EditAgentProfile;
