// // Polaris Imports
// import {
//   Button,
//   Card,
//   ChoiceList,
//   HorizontalStack,
//   Icon,
//   IndexTable,
//   Pagination,
//   Tooltip,
//   useIndexResourceState,
//   Text,
// } from "@shopify/polaris";
// import BarCharts from "../BarCharts";
// import { PolarisVizProvider } from "@shopify/polaris-viz";
// import { useCallback, useEffect, useRef, useState } from "react";
// import {
//   BgOutlineThemeButton,
//   BgThemeButton,
//   BgThemeButtonDisabled,
//   CheckWithoutBgUnderlineBtn,
//   WithoutBgButtonBlue,
// } from "../UI/Buttons";

// import DatePicker from "react-datepicker";
// import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
// import CustomProgressBar from "../CustomComponents/CustomProgressBar";
// import CustomToggleButton from "../UI/CustomToggleButton";
// import { Doughnut } from "react-chartjs-2";
// import { Chart, ArcElement } from "chart.js";
// import { Page, LegacyCard, DataTable } from "@shopify/polaris";
// import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
// import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
// import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
// import { useSelector } from "react-redux";
// import Loader from "../UI/Loader/Loader";
// import { useNavigate } from "react-router-dom";
// import CompetitorsAfter from "../CompetitorsAfter";
// import SelectDateRangeWithoutInput from "../CustomComponents/SelectDateRangeWithoutInput/SelectDateRangeWithoutInput";
// import ShowConfirmedArrivals from "../UI/ShowConfirmedArrivals";
// import ShowHouseCheckIn from "../UI/ShowHouseCheckIn";
// import ShowDepartureCheckOut from "../UI/ShowDepartureCheckOut";
// import NoDataFound from "../CustomComponents/NoDataFound";
// import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
// import { current } from "@reduxjs/toolkit";
// import LineChartDashboard from "../LineChartsDashboard";
// import SelectDateRange from "../CustomComponents/SelectDateRange/SelectDateRange";
// import StatusUpdate from "../CustomComponents/StatusUpdate";
// import ReactDatePicker from "react-datepicker";
// import SelectDatePickerCustom from "../CustomComponents/SelectDateRange/SelectDatePickerCustom";
// import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
// import {
//   permission_inner_lists,
//   rooms_inner_lists,
// } from "../../assets/constant";
// import { CSVLink } from "react-csv";
// import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
// import CustomRoomChoiceSelect from "../CustomComponents/CustomRoomChoiceSelect/CustomRoomChoiceSelect";
// import { formatIndianCurrency } from "../../utils/utils";
// import {
//   CustomReportTable,
//   CustomReportTableCell,
//   CustomReportTableRow,
// } from "../CustomComponents/CustomReportComponent/CustomReportComponent";

// Chart.register(ArcElement);

// const ComparisonRevparDoubleCharts = ({
//   tableOneData,
//   tableTwoData,
//   status,
//   reportData,
// }) => {
//   const currentDate = new Date().toISOString().split("T")[0];
//   const [fetchedData, setFetchedData] = useState(null);
//   const [openDayStatus, setOpenDayStatus] = useState();
//   const [showDashBoardCheckIn, setShowDashBoardCheckIn] = useState(false);
//   const [showDashBoardCheckOut, setShowDashBoardCheckOut] = useState(false);
//   const [showDashboardAllCheckIn, setShowDashboardAllCheckIn] = useState(false);
//   const [showManualSearchInput, setShowManualSearchInput] = useState(false);
//   const [showWebInput, setShowWebInput] = useState(false);
//   const [showBookingDetails, setShowBookingDetails] = useState(false);

//   const [showCheckOutBookingDetails, setShowCheckOutBookingDetails] =
//     useState(false);
//   const [
//     showCheckOutIdClickBookingDetails,
//     setShowCheckOutIdClickBookingDetails,
//   ] = useState(false);

//   const [settlementDoneCheckOut, setSettlementDoneCheckOut] = useState(false);

//   const [showCheckOutSettlementDetails, setShowCheckOutSettlementDetails] =
//     useState(false);
//   const [openBookingDetails, setOpenBookingDetails] = useState(true);
//   const userToken = JSON.parse(localStorage.getItem("userToken"));

//   const [bookingDetails, setBookingDetails] = useState([]);
//   const [allSettlementsData, setAllSettlementsData] = useState([]);

//   // show click arrow buttons
//   const [showArrivalConfirmed, setShowArrivalConfirmed] = useState(false);
//   const [showAllSettlement, setShowAllSettlement] = useState(false);
//   const [showInHouseCheckIn, setShowInHouseCheckIn] = useState(false);
//   const [showDepartureCheckOut, setShowDepartureCheckOut] = useState(false);

//   const [checkOutBookingDetail, setCheckOutBookingDetail] = useState([]);

//   const property_id = useSelector((state) => state.property.value);

//   const [dayTypeFilter, setDayTypeFilter] = useState("");
//   const [isOpen, setIsOpen] = useState(false);
//   const [isOpen1, setIsOpen1] = useState(false);
//   const [isOpen2, setIsOpen2] = useState(false);
//   const [isOpen3, setIsOpen3] = useState(false);
//   const [valueArray, setValueArray] = useState([]);
//   const accordRef = useRef(null);

//   const toggleAccord = () => {
//     setIsOpen(!isOpen);
//     setIsOpen1(false);
//     setIsOpen2(false);
//   };
//   const toggleAccord1 = () => {
//     setIsOpen(false);
//     setIsOpen1(!isOpen1);
//     setIsOpen2(false);
//   };
//   const toggleAccord2 = () => {
//     setIsOpen2(!isOpen2);
//     // setIsOpen1(false);
//     // setIsOpen(false);
//   };
//   const toggleAccord3 = () => {
//     setIsOpen3(!isOpen3);
//     setIsOpen1(false);
//     setIsOpen(false);
//   };

//   const permissions = JSON.parse(localStorage.getItem("permissions"));

//   // Reservations
//   const dateOptions = [
//     { id: 0, value: "-1", label: "Today" },
//     { id: 0, value: "-2", label: "Yesterday" },
//     { id: 1, value: "-7", label: "Past 7 Days" },
//     { id: 2, value: "-30", label: "Past 30 Days" },
//     { id: 3, value: "-90", label: "Past 90 Days" },
//     { id: 4, value: "-1", label: "Last Year" },
//     { id: 5, value: "-3", label: "Last 3 year" },
//   ];
//   // Reservations
//   const dateOptions1 = [
//     { id: 0, value: "revenue", label: "Revenue" },
//     { id: 1, value: "profit", label: "Profit" },
//     { id: 2, value: "reservation", label: "Reservation" },
//     { id: 3, value: "expense", label: "Expense" },
//     { id: 4, value: "event", label: "Event" },
//     { id: 5, value: "f&b", label: "F&B" },
//   ];

//   const [allRoomTypes, setAllRoomTypes] = useState([]);
//   const fetchAllRoomTypes = async (propertyId) => {
//     try {
//       // setLoader(true);
//       const response = await fetch(
//         // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
//         `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );
//       const data = await response.json();
//       if (data.message === "Unauthorised.") {
//         navigate("/");
//       }
//       setAllRoomTypes(data.rooms);

//       const roomIds = data.rooms.map((room) => room.id);
//       setSelectedRoomType(roomIds);
//       if (property_id) {
//         fetchInfo(property_id?.id, roomIds, selectedRoomType);
//       }
//       // setLoader(false);
//     } catch (error) {
//       // setLoader(false);
//       console.error(error);
//     }
//   };

//   const choiceListRoomsArray = allRoomTypes.map((room) => {
//     return { label: room.room_name, value: room.id };
//   });

//   const monthWiseChoiceListArray = [
//     { label: "Mar", value: "mar_2024" },
//     { label: "Apr", value: "apr_2024" },
//     { label: "May", value: "may_2024" },
//     { label: "Jun", value: "jun_2024" },
//     { label: "Jul", value: "jul_2024" },
//     { label: "Aug", value: "aug_2024" },
//     { label: "Sep", value: "sep_2024" },
//     { label: "Oct", value: "oct_2024" },
//     { label: "Nov", value: "nov_2024" },
//     { label: "Dec", value: "dec_2024" },
//     { label: "Jan", value: "jan_2025" },
//     { label: "Feb", value: "feb_2025" },
//   ];

//   const monthArray = monthWiseChoiceListArray.map((month) => {
//     return [{ label: month.label }];
//   });

//   const roomIdsArray = allRoomTypes.map((room) => room.id);

//   const handleAccordClick = (id, label, value) => {
//     setValueArray([{ id: id, label: label, value: value }]);
//     setDayTypeFilter(value);
//   };

//   const handleShowDashBoardCheckIn = (e) => {
//     e.stopPropagation();
//     setShowDashBoardCheckIn(!showDashBoardCheckIn);
//   };

//   const handleShowDashBoardCheckOut = (e) => {
//     e.stopPropagation();
//     setBookingId("");
//     setShowDashBoardCheckOut(!showDashBoardCheckOut);
//   };

//   const handleShowManualSearchInput = () => {
//     {
//       showWebInput === true && setShowWebInput(false);
//     }
//     setShowManualSearchInput(!showManualSearchInput);
//   };

//   const handleShowWebInput = () => {
//     {
//       showManualSearchInput === true && setShowManualSearchInput(false);
//     }
//     setShowWebInput(!showWebInput);
//   };

//   const [bookingOpenIndex, setOpenBookingIndex] = useState(1);
//   const [bookingAccordianActive, setBookingAccordianActive] = useState("");

//   const handleOpenBookigDetails = (idx) => {
//     setOpenBookingIndex(idx);
//     if (bookingOpenIndex === idx) {
//       setBookingAccordianActive("active");
//     } else {
//       setBookingAccordianActive("");
//     }
//   };

//   const handleShowDashBoardAllCheckIn = () => {
//     setShowDashboardAllCheckIn(!showDashboardAllCheckIn);
//   };

//   const [loader, setLoader] = useState(true);

//   const navigate = useNavigate();

//   const [selectedDiscountMethod, setSelectedDiscountMethod] =
//     useState("automatic");

//   const handleDiscountMethodChange = (value) => {
//     setSelectedDiscountMethod(value);
//   };

//   const [selectedRoomType, setSelectedRoomType] = useState([]);
//   const [selectedMonths, setSelectedMonths] = useState([]);
//   const [selectedBalanceType, setSelectedBalanceType] = useState([]);
//   const [selectedBookingStatus, setSelectedBookingStatus] = useState([
//     "in-house",
//   ]);

//   const handleRoomTypeStatus = useCallback(
//     (value) => setSelectedRoomType(value),
//     []
//   );
//   const handleBalanceTypeStatus = useCallback(
//     (value) => setSelectedBalanceType(value),
//     []
//   );
//   const handleBookingStatus = useCallback(
//     (value) => setSelectedBookingStatus(value),
//     []
//   );

//   // const currentDate = new Date().toISOString().split("T")[0];

//   const calculateToDate = (fromDate) => {
//     const toDate = new Date(fromDate);
//     toDate.setDate(toDate.getDate() - 15);
//     return toDate.toISOString().split("T")[0];
//   };
//   const calculatePlusToDate = (fromDate) => {
//     const toDate = new Date(fromDate);
//     toDate.setDate(toDate.getDate() + 15);
//     return toDate.toISOString().split("T")[0];
//   };

//   const [selectedDatesRange, setSelectedDatesRange] = useState({
//     from_date: calculateToDate(currentDate),
//     to_date: calculatePlusToDate(currentDate),
//   });
//   const [comparisonStartDateRange, setComparisonStartDateRange] = useState({
//     from_date: calculateToDate(currentDate),
//     to_date: currentDate,
//   });
//   const [comparisonEndDateRange, setComparisonEndDateRange] = useState({
//     from_date: calculateToDate(currentDate),
//     to_date: currentDate,
//   });

//   const dataFromChild = (from_date, to_date) => {
//     setSelectedDatesRange({
//       from_date: from_date,
//       to_date: to_date,
//     });
//   };
//   const dataFromChild1 = (from_date, to_date) => {
//     setComparisonStartDateRange({
//       from_date: from_date,
//       to_date: to_date,
//     });
//   };
//   const dataFromChild2 = (from_date, to_date) => {
//     setComparisonEndDateRange({
//       from_date: from_date,
//       to_date: to_date,
//     });
//   };

//   useEffect(() => {
//     if (property_id) {
//       // fetchDashboardInfo(property_id.id, dayTypeFilter);
//       // fetchAllRoomTypes(property_id.id);
//     }
//   }, [property_id]);

//   const openDayStatusHandler = useCallback((data) => {
//     setOpenDayStatus(data);
//   }, []);

//   const chart_data = {
//     datasets: [
//       {
//         data: [fetchedData?.total_revenue, fetchedData?.foodSale, 0],
//         backgroundColor: ["#3968ED", "#15AA12", "#CCCCCC"],
//         display: true,
//       },
//     ],
//   };

//   const [bookingId, setBookingId] = useState("");

//   const [openAccordion, setOpenAccordion] = useState("first");

//   // UseState for forms
//   const [settlementAddData, setSettlementAddData] = useState({
//     settlement_name: "",
//     payment_mode: "",
//     notes: "",
//     date: "",
//     amount: "",
//     property_id: property_id?.id,
//     other_payment_mode: "",
//   });

//   const paymentModeOptions = [
//     { label: "Select Option" },
//     { label: "Cash", value: "cash" },
//     { label: "Bank Payment", value: "bank_payment" },
//     { label: "UPI", value: "upi" },
//     { label: "Credit Card", value: "credit_card" },
//     { label: "Debit Card", value: "debit_card" },
//     { label: "Other", value: "other" },
//   ];

//   const reportFilterOptions = [
//     { label: "Select Option" },
//     { label: "Arrival", value: "arrival" },
//     { label: "Departure", value: "departure" },
//     { label: "In House", value: "in_house" },
//     // { label: "RevPar", value: "rev_par" },
//   ];

//   const yearFilterOptions = [
//     { label: "Select Option" },
//     { label: "Date", value: "date" },
//     { label: "Month", value: "month" },
//   ];

//   const reportTypeOptions = [
//     { label: "Select Option" },
//     { label: "Achieved Revenue", value: "achieved_revenue" },
//     { label: "Achieved Occupancy", value: "achieved_occupancy" },
//     { label: "Achieved RevPar", value: "achieved_revpar" },
//     { label: "Competitors Reports", value: "competitor_reports" },
//   ];

//   const renderYearContent = (year) => {
//     const tooltipText = `Tooltip for year: ${year}`;
//     return <span title={tooltipText}>{year}</span>;
//   };

//   useEffect(() => {
//     document.title = "RevCatalyst - Boosting Your Hotel Performance";

//     let metaDescription = document.querySelector('meta[name="description"]');
//     if (!metaDescription) {
//       metaDescription = document.createElement("meta");
//       metaDescription.name = "description";
//       document.head.appendChild(metaDescription);
//     }
//     metaDescription.content =
//       "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
//   }, []);

//   const resourceName = {
//     singular: "order",
//     plural: "orders",
//   };

//   const [orders, setOrders] = useState([]);
//   const [combinedData, setCombinedData] = useState([]);
//   const [chartData, setChartData] = useState([]);
//   const [fullData, setFullData] = useState([]);
//   const [filterName, setFilterName] = useState("Comparison");
//   const [yearFilter, setYearFilter] = useState("date");
//   const [reportType, setReportType] = useState("achieved_revenue");

//   const [urlFilter, setUrlFilter] = useState("occupancy");

//   const handleUrlInnerFilter = useCallback(
//     (e) => {
//       if (e === "payment_ledger_report") {
//         setUrlFilter("payment");
//       } else {
//         setUrlFilter("occupancy");
//       }
//     },
//     [urlFilter]
//   );

//   const generateYearOptions = (currentYear) => {
//     const numYears = 3;
//     const startYear = currentYear - numYears;
//     const endYear = currentYear;

//     const years = [];
//     years.push({ label: "Select a year", value: "" });
//     for (let year = startYear; year <= endYear; year++) {
//       years.push({ label: year.toString(), value: year });
//     }

//     return years;
//   };

//   const data = [
//     {
//       name: "Booking",
//       color: "#7C9CF3",
//       data: chartData,
//     },
//   ];

//   // Example usage:
//   const currentYear = new Date().getFullYear();
//   const yearOptions = generateYearOptions(currentYear);

//   const fetchInfo = async (propertyId, roomIds) => {
//     try {
//       setLoader(true);
//       setChartData([]);
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/compertitor/analysis/reports/${propertyId}`,
//         {
//           method: "POST",
//           body: JSON.stringify({
//             from_date: selectedDatesRange.from_date,
//             to_date: selectedDatesRange.to_date,
//             room_types: selectedMonths,
//           }),
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );

//       const data = await response.json();

//       if (data.success) {
//         setOrders(data.reponsedata);
//         setFullData(data);
//         showSuccessToast(data.message);
//         setLoader(false);
//       } else {
//         showErrorToast(data.message);
//         setLoader(false);
//       }

//       setLoader(false);
//     } catch (error) {
//       setLoader(false);
//       console.error("Error:", error);
//       showErrorToast("An error occurred while processing your request.");
//     }
//   };

//   const fetchFilterInfo = async (propertyId, roomIds) => {
//     try {
//       setLoader(true);
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/compertitor/analysis/reports/${propertyId}`,
//         {
//           method: "POST",
//           body: JSON.stringify({
//             from_date: selectedDatesRange.from_date,
//             to_date: selectedDatesRange.to_date,
//             room_types: selectedRoomType,
//           }),
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );

//       const data = await response.json();

//       if (data.success) {
//         setOrders(data.reponsedata);
//         setFullData(data);
//         showSuccessToast(data.message);
//         setLoader(true);
//       } else {
//         showErrorToast(data.message);
//         setLoader(true);
//       }

//       setLoader(false);
//     } catch (error) {
//       setLoader(false);
//       console.error("Error:", error);
//       showErrorToast("An error occurred while processing your request.");
//     }
//   };

//   const {
//     selectedResources,
//     allResourcesSelected,
//     handleSelectionChange,
//     clearSelection,
//   } = useIndexResourceState(orders);

//   const combinedRowMarkup = tableOneData?.map((order, index) => (
//     <IndexTable.Row
//       id={index}
//       key={index}
//       selected={selectedResources.includes(index)}
//       position={order.index}
//     >
//       <IndexTable.Cell>
//         <div className="green">
//           <svg
//             width="22"
//             height="14"
//             xmlns="http://www.w3.org/2000/svg"
//             fill-rule="evenodd"
//             clipRule="evenodd"
//           >
//             <path d="M11 2.206l-6.235 7.528-.765-.645 7.521-9 7.479 9-.764.646-6.236-7.53v21.884h-1v-21.883z" />
//           </svg>
//         </div>
//       </IndexTable.Cell>
//       <IndexTable.Cell>
//         <>
//           {order.tableOneData?.value > order.tableTwoData?.value ? (
//             <div className="green">
//               <svg
//                 width="24"
//                 height="24"
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill-rule="evenodd"
//                 clipRule="evenodd"
//               >
//                 <path d="M11 2.206l-6.235 7.528-.765-.645 7.521-9 7.479 9-.764.646-6.236-7.53v21.884h-1v-21.883z" />
//               </svg>
//             </div>
//           ) : (
//             <div className="green">
//               <svg
//                 clipRule="evenodd"
//                 fill-rule="evenodd"
//                 stroke-linejoin="round"
//                 stroke-miterlimit="2"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="m5.214 14.522s4.505 4.502 6.259 6.255c.146.147.338.22.53.22s.384-.073.53-.22c1.754-1.752 6.249-6.244 6.249-6.244.144-.144.216-.334.217-.523 0-.193-.074-.386-.221-.534-.293-.293-.766-.294-1.057-.004l-4.968 4.968v-14.692c0-.414-.336-.75-.75-.75s-.75.336-.75.75v14.692l-4.979-4.978c-.289-.289-.761-.287-1.054.006-.148.148-.222.341-.221.534 0 .189.071.377.215.52z"
//                   fill-rule="nonzero"
//                 />
//               </svg>
//             </div>
//           )}
//         </>
//       </IndexTable.Cell>
//     </IndexTable.Row>
//   ));

//   const newData = tableOneData?.map((order) => ({
//     Date: order?.key,
//     Revpar: order?.value,
//   }));

//   const rowMarkupTableOne = tableOneData?.map((order, index) => (
//     <IndexTable.Row
//       id={index}
//       key={index}
//       selected={selectedResources.includes(index)}
//       position={order.index}
//     >
//       <IndexTable.Cell>
//         <div style={{ textAlign: "start" }} className="blue">
//           {order?.key}
//         </div>
//       </IndexTable.Cell>

//       <IndexTable.Cell>
//         <div style={{ textAlign: "start" }} className="blue">
//           {status === "Occupancy" ? (
//             <div className="blue">
//               {order?.value > 100 ? 100 : order?.value}%
//             </div>
//           ) : status === "ADR" ? (
//             <div className="blue">{formatIndianCurrency(order?.value)}</div>
//           ) : status === "RevPar" ? (
//             <div className="blue">{formatIndianCurrency(order?.value)}</div>
//           ) : (
//             order?.value
//           )}
//         </div>
//       </IndexTable.Cell>
//     </IndexTable.Row>
//   ));

//   const rowMarkupTableTwo = tableTwoData?.map((order, index) => (
//     <IndexTable.Row
//       id={index}
//       key={index}
//       selected={selectedResources.includes(index)}
//       position={order.index}
//     >
//       <IndexTable.Cell>
//         <div style={{ textAlign: "start" }} className="yellow">
//           {order?.key}
//         </div>
//       </IndexTable.Cell>

//       <IndexTable.Cell>
//         <div style={{ textAlign: "start" }} className="yellow">
//           {status === "Occupancy" ? (
//             <div>{order?.value}%</div>
//           ) : status === "ADR" ? (
//             order?.value
//           ) : (
//             order?.value
//           )}
//         </div>
//       </IndexTable.Cell>
//       {/* <IndexTable.Cell>
//         <>
//           {order.tableOneData?.value > order.tableTwoData?.value ? (
//             <div className="green">
//               <svg
//                 width="24"
//                 height="24"
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill-rule="evenodd"
//                 clipRule="evenodd"
//               >
//                 <path d="M11 2.206l-6.235 7.528-.765-.645 7.521-9 7.479 9-.764.646-6.236-7.53v21.884h-1v-21.883z" />
//               </svg>
//             </div>
//           ) : (
//             <div className="flex justify_content_center align_items_center green">
//               <svg
//                 clipRule="evenodd"
//                 fill-rule="evenodd"
//                 stroke-linejoin="round"
//                 stroke-miterlimit="2"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="m5.214 14.522s4.505 4.502 6.259 6.255c.146.147.338.22.53.22s.384-.073.53-.22c1.754-1.752 6.249-6.244 6.249-6.244.144-.144.216-.334.217-.523 0-.193-.074-.386-.221-.534-.293-.293-.766-.294-1.057-.004l-4.968 4.968v-14.692c0-.414-.336-.75-.75-.75s-.75.336-.75.75v14.692l-4.979-4.978c-.289-.289-.761-.287-1.054.006-.148.148-.222.341-.221.534 0 .189.071.377.215.52z"
//                   fill-rule="nonzero"
//                 />
//               </svg>
//             </div>
//           )}
//         </>
//       </IndexTable.Cell> */}
//     </IndexTable.Row>
//   ));

//   const otherMenusRef = useRef(null);
//   const handleClickOutside = (event) => {
//     if (
//       otherMenusRef.current &&
//       !otherMenusRef.current.contains(event.target)
//     ) {
//       setIsOpen(false);
//       setIsOpen1(false);
//       setIsOpen2(false);
//       setIsOpen3(false);
//     }
//   };

//   useEffect(() => {
//     if (tableOneData && tableTwoData) {
//       let newArray = tableOneData?.map((obj1) => {
//         let obj2 = tableTwoData?.find((obj2) => obj2.date === obj1.date);

//         return { tableOneData: obj1, tableTwoData: obj2 };
//       });
//       setCombinedData(newArray);
//     }
//   }, [tableOneData, tableTwoData]);

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const getDataFromCustomRoomChoiceSelect = (data) => {
//     setSelectedMonths(data);
//   };

//   const RevPar = [{ title: "Date" }, { title: "RevPar" }];
//   const revPar = ["Date", "RevPar", "Date", "RevPar", "Compare"];

//   const Occupancy = [{ title: "Date" }, { title: "Occupancy" }];
//   const occupancy = ["Date", "Occupancy", "Date", "Occupancy", "Compare"];

//   const Adr = [{ title: "Date" }, { title: "ADR" }];
//   const adr = ["Date", "ADR", "Date", "ADR", "Compare"];
//   const { from_data, to_data } = reportData ? reportData : {};

//   const commonColors = ["#ecf1fd", "#ecf1fd", "#fff9e8", "#fff9e8", "#dff8df"];
//   // const headings =

//   return (
//     <>
//       {/* {loader ? (
//           <Loader />
//         ) : ( */}
//       <>
//         <section className="dashboard_page">
//           <div
//             style={{
//               width: "100%",
//               display: "flex",
//               flexDirection: "column",
//               gap: "20px",
//             }}
//           >
//             {tableOneData && tableOneData.length > 0 ? (
//               <div
//                 style={{
//                   background: "rgba(255, 255, 255, 1)",
//                   padding: "20px",
//                   borderRadius: "10px",
//                 }}
//               >
//                 <CheckWithoutBgUnderlineBtn
//                   style={{
//                     display: "flex",
//                     justifyContent: "end",
//                     width: "100%",
//                     marginBottom: "10px",
//                   }}
//                 >
//                   <CSVLink data={newData || []}>Download CSV</CSVLink>
//                 </CheckWithoutBgUnderlineBtn>
//                 <div title="Reservation Insights">
//                   <div className="flex">
//                     <div style={{ width: "45%" }}>
//                       {tableOneData && tableOneData.length > 0 ? (
//                         <LegacyCard>
//                           <IndexTable
//                             selectable={false}
//                             resourceName={resourceName}
//                             itemCount={tableOneData.length}
//                             selectedItemsCount={
//                               allResourcesSelected
//                                 ? "All"
//                                 : selectedResources.length
//                             }
//                             onSelectionChange={handleSelectionChange}
//                             headings={
//                               status === "Occupancy"
//                                 ? Occupancy
//                                 : status === "ADR"
//                                 ? Adr
//                                 : RevPar
//                             }
//                           >
//                             {rowMarkupTableOne}
//                           </IndexTable>
//                         </LegacyCard>
//                       ) : (
//                         <NoDataFound />
//                       )}
//                     </div>

//                     <div style={{ width: "45%" }}>
//                       <LegacyCard>
//                         <IndexTable
//                           selectable={false}
//                           resourceName={resourceName}
//                           itemCount={tableTwoData.length}
//                           selectedItemsCount={
//                             allResourcesSelected
//                               ? "All"
//                               : selectedResources.length
//                           }
//                           onSelectionChange={handleSelectionChange}
//                           headings={
//                             status === "Occupancy"
//                               ? Occupancy
//                               : status === "ADR"
//                               ? Adr
//                               : RevPar
//                           }
//                         >
//                           {rowMarkupTableTwo}
//                         </IndexTable>
//                       </LegacyCard>
//                     </div>
//                     <div style={{ width: "10%" }}>
//                       <LegacyCard>
//                         <IndexTable
//                           selectable={false}
//                           resourceName={resourceName}
//                           itemCount={tableTwoData.length}
//                           selectedItemsCount={
//                             allResourcesSelected
//                               ? "All"
//                               : selectedResources.length
//                           }
//                           onSelectionChange={handleSelectionChange}
//                           headings={[{ title: "Compare" }]}
//                         >
//                           {combinedRowMarkup}
//                         </IndexTable>
//                       </LegacyCard>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ) : (
//               <NoDataFound />
//             )}

//             <CustomReportTable
//               headings={
//                 status === "Occupancy"
//                   ? occupancy
//                   : status === "ADR"
//                   ? adr
//                   : revPar
//               }
//               groupBackgroundColors={commonColors}
//               headerStyles={{
//                 padding: "10px 5px",
//                 fontWeight: "bold",
//                 backgroundColor: "#f2f2f2",
//                 borderBottom: "1px solid #CCC",
//               }}
//             >
//               {from_data?.map((fromItem, index) => {
//                 const toItem = to_data[index] || {};
//                 const fromValue = fromItem.value;
//                 const toValue = toItem.value;

//                 return (
//                   <CustomReportTableRow
//                     key={index}
//                     bodyStyles={{ borderBottom: "1px solid #CCC" }}
//                   >
//                     <CustomReportTableCell
//                       groupBackgroundColors={commonColors[0]}
//                     >
//                       {fromItem.key}
//                     </CustomReportTableCell>
//                     <CustomReportTableCell
//                       groupBackgroundColors={commonColors[1]}
//                     >
//                       {status === "Occupancy" ? (
//                         <>{fromValue > 100 ? 100 : fromValue}%</>
//                       ) : status === "ADR" ? (
//                         <>{formatIndianCurrency(fromValue)}</>
//                       ) : status === "RevPar" ? (
//                         <>{formatIndianCurrency(fromValue)}</>
//                       ) : (
//                         fromValue
//                       )}
//                     </CustomReportTableCell>
//                     <CustomReportTableCell
//                       groupBackgroundColors={commonColors[2]}
//                     >
//                       {toItem.key}
//                     </CustomReportTableCell>
//                     <CustomReportTableCell
//                       groupBackgroundColors={commonColors[3]}
//                     >
//                       {toValue}
//                     </CustomReportTableCell>
//                     <CustomReportTableCell
//                       groupBackgroundColors={commonColors[4]}
//                       styles={{width:"100px"}}
//                     >
//                      <div className="flex justify_content_between" style={{width:"100%"}}>
//                      <span>
//                         <svg
//                           width="22"
//                           height="14"
//                           xmlns="http://www.w3.org/2000/svg"
//                           fill-rule="evenodd"
//                           clipRule="evenodd"
//                         >
//                           <path d="M11 2.206l-6.235 7.528-.765-.645 7.521-9 7.479 9-.764.646-6.236-7.53v21.884h-1v-21.883z" />
//                         </svg>
//                       </span>

//                       {fromValue > toValue ? (
//                         <span>
//                            <svg
//                             clipRule="evenodd"
//                              width="24"
//                              height="12"
//                             fill-rule="evenodd"
//                             stroke-linejoin="round"
//                             stroke-miterlimit="2"
//                             viewBox="0 0 24 24"
//                             xmlns="http://www.w3.org/2000/svg"
//                             transform="rotate(-180 )"
//                           >
//                             <path
//                               d="m5.214 14.522s4.505 4.502 6.259 6.255c.146.147.338.22.53.22s.384-.073.53-.22c1.754-1.752 6.249-6.244 6.249-6.244.144-.144.216-.334.217-.523 0-.193-.074-.386-.221-.534-.293-.293-.766-.294-1.057-.004l-4.968 4.968v-14.692c0-.414-.336-.75-.75-.75s-.75.336-.75.75v14.692l-4.979-4.978c-.289-.289-.761-.287-1.054.006-.148.148-.222.341-.221.534 0 .189.071.377.215.52z"
//                               fill-rule="nonzero"
//                             />
//                           </svg>
//                         </span>
//                       ) : (
//                         <span>
//                           <svg
//                             clipRule="evenodd"
//                              width="24"
//                              height="12"
//                             fill-rule="evenodd"
//                             stroke-linejoin="round"
//                             stroke-miterlimit="2"
//                             viewBox="0 0 24 24"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <path
//                               d="m5.214 14.522s4.505 4.502 6.259 6.255c.146.147.338.22.53.22s.384-.073.53-.22c1.754-1.752 6.249-6.244 6.249-6.244.144-.144.216-.334.217-.523 0-.193-.074-.386-.221-.534-.293-.293-.766-.294-1.057-.004l-4.968 4.968v-14.692c0-.414-.336-.75-.75-.75s-.75.336-.75.75v14.692l-4.979-4.978c-.289-.289-.761-.287-1.054.006-.148.148-.222.341-.221.534 0 .189.071.377.215.52z"
//                               fill-rule="nonzero"
//                             />
//                           </svg>
//                         </span>
//                       )}
//                      </div>
//                     </CustomReportTableCell>
//                   </CustomReportTableRow>
//                 );
//               })}
//             </CustomReportTable>
//           </div>
//         </section>
//       </>
//       {/* )} */}
//     </>
//   );
// };

// export default ComparisonRevparDoubleCharts;

// Polaris Imports
import {
  Button,
  Card,
  ChoiceList,
  HorizontalStack,
  Icon,
  IndexTable,
  Pagination,
  Tooltip,
  useIndexResourceState,
  Text,
} from "@shopify/polaris";
import BarCharts from "../BarCharts";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "../UI/Buttons";

import DatePicker from "react-datepicker";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import CustomProgressBar from "../CustomComponents/CustomProgressBar";
import CustomToggleButton from "../UI/CustomToggleButton";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { Page, LegacyCard, DataTable } from "@shopify/polaris";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { useSelector } from "react-redux";
import Loader from "../UI/Loader/Loader";
import { useNavigate } from "react-router-dom";
import CompetitorsAfter from "../CompetitorsAfter";
import SelectDateRangeWithoutInput from "../CustomComponents/SelectDateRangeWithoutInput/SelectDateRangeWithoutInput";
import ShowConfirmedArrivals from "../UI/ShowConfirmedArrivals";
import ShowHouseCheckIn from "../UI/ShowHouseCheckIn";
import ShowDepartureCheckOut from "../UI/ShowDepartureCheckOut";
import NoDataFound from "../CustomComponents/NoDataFound";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import { current } from "@reduxjs/toolkit";
import LineChartDashboard from "../LineChartsDashboard";
import SelectDateRange from "../CustomComponents/SelectDateRange/SelectDateRange";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import ReactDatePicker from "react-datepicker";
import SelectDatePickerCustom from "../CustomComponents/SelectDateRange/SelectDatePickerCustom";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import {
  permission_inner_lists,
  rooms_inner_lists,
} from "../../assets/constant";
import { CSVLink } from "react-csv";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import CustomRoomChoiceSelect from "../CustomComponents/CustomRoomChoiceSelect/CustomRoomChoiceSelect";
import { formatIndianCurrency } from "../../utils/utils";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";

Chart.register(ArcElement);

const ComparisonRevparDoubleCharts = ({
  tableOneData,
  tableTwoData,
  status,
  reportData,
  title,
}) => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [fetchedData, setFetchedData] = useState(null);
  const [openDayStatus, setOpenDayStatus] = useState();
  const [showDashBoardCheckIn, setShowDashBoardCheckIn] = useState(false);
  const [showDashBoardCheckOut, setShowDashBoardCheckOut] = useState(false);
  const [showDashboardAllCheckIn, setShowDashboardAllCheckIn] = useState(false);
  const [showManualSearchInput, setShowManualSearchInput] = useState(false);
  const [showWebInput, setShowWebInput] = useState(false);
  const [showBookingDetails, setShowBookingDetails] = useState(false);

  const [showCheckOutBookingDetails, setShowCheckOutBookingDetails] =
    useState(false);
  const [
    showCheckOutIdClickBookingDetails,
    setShowCheckOutIdClickBookingDetails,
  ] = useState(false);

  const [settlementDoneCheckOut, setSettlementDoneCheckOut] = useState(false);

  const [showCheckOutSettlementDetails, setShowCheckOutSettlementDetails] =
    useState(false);
  const [openBookingDetails, setOpenBookingDetails] = useState(true);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const [bookingDetails, setBookingDetails] = useState([]);
  const [allSettlementsData, setAllSettlementsData] = useState([]);

  // show click arrow buttons
  const [showArrivalConfirmed, setShowArrivalConfirmed] = useState(false);
  const [showAllSettlement, setShowAllSettlement] = useState(false);
  const [showInHouseCheckIn, setShowInHouseCheckIn] = useState(false);
  const [showDepartureCheckOut, setShowDepartureCheckOut] = useState(false);

  const [checkOutBookingDetail, setCheckOutBookingDetail] = useState([]);

  const property_id = useSelector((state) => state.property.value);

  const [dayTypeFilter, setDayTypeFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [valueArray, setValueArray] = useState([]);
  const accordRef = useRef(null);

  const toggleAccord = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
    setIsOpen2(false);
  };
  const toggleAccord1 = () => {
    setIsOpen(false);
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
  };
  const toggleAccord2 = () => {
    setIsOpen2(!isOpen2);
    // setIsOpen1(false);
    // setIsOpen(false);
  };
  const toggleAccord3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen1(false);
    setIsOpen(false);
  };

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  // Reservations
  const dateOptions = [
    { id: 0, value: "-1", label: "Today" },
    { id: 0, value: "-2", label: "Yesterday" },
    { id: 1, value: "-7", label: "Past 7 Days" },
    { id: 2, value: "-30", label: "Past 30 Days" },
    { id: 3, value: "-90", label: "Past 90 Days" },
    { id: 4, value: "-1", label: "Last Year" },
    { id: 5, value: "-3", label: "Last 3 year" },
  ];
  // Reservations
  const dateOptions1 = [
    { id: 0, value: "revenue", label: "Revenue" },
    { id: 1, value: "profit", label: "Profit" },
    { id: 2, value: "reservation", label: "Reservation" },
    { id: 3, value: "expense", label: "Expense" },
    { id: 4, value: "event", label: "Event" },
    { id: 5, value: "f&b", label: "F&B" },
  ];

  const [allRoomTypes, setAllRoomTypes] = useState([]);
  const fetchAllRoomTypes = async (propertyId) => {
    try {
      // setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAllRoomTypes(data.rooms);

      const roomIds = data.rooms.map((room) => room.id);
      setSelectedRoomType(roomIds);
      if (property_id) {
        fetchInfo(property_id?.id, roomIds, selectedRoomType);
      }
      // setLoader(false);
    } catch (error) {
      // setLoader(false);
      console.error(error);
    }
  };

  const choiceListRoomsArray = allRoomTypes.map((room) => {
    return { label: room.room_name, value: room.id };
  });

  const monthWiseChoiceListArray = [
    { label: "Mar", value: "mar_2024" },
    { label: "Apr", value: "apr_2024" },
    { label: "May", value: "may_2024" },
    { label: "Jun", value: "jun_2024" },
    { label: "Jul", value: "jul_2024" },
    { label: "Aug", value: "aug_2024" },
    { label: "Sep", value: "sep_2024" },
    { label: "Oct", value: "oct_2024" },
    { label: "Nov", value: "nov_2024" },
    { label: "Dec", value: "dec_2024" },
    { label: "Jan", value: "jan_2025" },
    { label: "Feb", value: "feb_2025" },
  ];

  const monthArray = monthWiseChoiceListArray.map((month) => {
    return [{ label: month.label }];
  });

  const roomIdsArray = allRoomTypes.map((room) => room.id);

  const handleAccordClick = (id, label, value) => {
    setValueArray([{ id: id, label: label, value: value }]);
    setDayTypeFilter(value);
  };

  const handleShowDashBoardCheckIn = (e) => {
    e.stopPropagation();
    setShowDashBoardCheckIn(!showDashBoardCheckIn);
  };

  const handleShowDashBoardCheckOut = (e) => {
    e.stopPropagation();
    setBookingId("");
    setShowDashBoardCheckOut(!showDashBoardCheckOut);
  };

  const handleShowManualSearchInput = () => {
    {
      showWebInput === true && setShowWebInput(false);
    }
    setShowManualSearchInput(!showManualSearchInput);
  };

  const handleShowWebInput = () => {
    {
      showManualSearchInput === true && setShowManualSearchInput(false);
    }
    setShowWebInput(!showWebInput);
  };

  const [bookingOpenIndex, setOpenBookingIndex] = useState(1);
  const [bookingAccordianActive, setBookingAccordianActive] = useState("");

  const handleOpenBookigDetails = (idx) => {
    setOpenBookingIndex(idx);
    if (bookingOpenIndex === idx) {
      setBookingAccordianActive("active");
    } else {
      setBookingAccordianActive("");
    }
  };

  const handleShowDashBoardAllCheckIn = () => {
    setShowDashboardAllCheckIn(!showDashboardAllCheckIn);
  };

  const [loader, setLoader] = useState(true);

  const navigate = useNavigate();

  const [selectedDiscountMethod, setSelectedDiscountMethod] =
    useState("automatic");

  const handleDiscountMethodChange = (value) => {
    setSelectedDiscountMethod(value);
  };

  const [selectedRoomType, setSelectedRoomType] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedBalanceType, setSelectedBalanceType] = useState([]);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState([
    "in-house",
  ]);

  const handleRoomTypeStatus = useCallback(
    (value) => setSelectedRoomType(value),
    []
  );
  const handleBalanceTypeStatus = useCallback(
    (value) => setSelectedBalanceType(value),
    []
  );
  const handleBookingStatus = useCallback(
    (value) => setSelectedBookingStatus(value),
    []
  );

  // const currentDate = new Date().toISOString().split("T")[0];

  const calculateToDate = (fromDate) => {
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() - 15);
    return toDate.toISOString().split("T")[0];
  };
  const calculatePlusToDate = (fromDate) => {
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 15);
    return toDate.toISOString().split("T")[0];
  };

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: calculatePlusToDate(currentDate),
  });
  const [comparisonStartDateRange, setComparisonStartDateRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: currentDate,
  });
  const [comparisonEndDateRange, setComparisonEndDateRange] = useState({
    from_date: calculateToDate(currentDate),
    to_date: currentDate,
  });

  const dataFromChild = (from_date, to_date) => {
    setSelectedDatesRange({
      from_date: from_date,
      to_date: to_date,
    });
  };
  const dataFromChild1 = (from_date, to_date) => {
    setComparisonStartDateRange({
      from_date: from_date,
      to_date: to_date,
    });
  };
  const dataFromChild2 = (from_date, to_date) => {
    setComparisonEndDateRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  useEffect(() => {
    if (property_id) {
      // fetchDashboardInfo(property_id.id, dayTypeFilter);
      // fetchAllRoomTypes(property_id.id);
    }
  }, [property_id]);

  const openDayStatusHandler = useCallback((data) => {
    setOpenDayStatus(data);
  }, []);

  const chart_data = {
    datasets: [
      {
        data: [fetchedData?.total_revenue, fetchedData?.foodSale, 0],
        backgroundColor: ["#3968ED", "#15AA12", "#CCCCCC"],
        display: true,
      },
    ],
  };

  const [bookingId, setBookingId] = useState("");

  const [openAccordion, setOpenAccordion] = useState("first");

  // UseState for forms
  const [settlementAddData, setSettlementAddData] = useState({
    settlement_name: "",
    payment_mode: "",
    notes: "",
    date: "",
    amount: "",
    property_id: property_id?.id,
    other_payment_mode: "",
  });

  const paymentModeOptions = [
    { label: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    { label: "Other", value: "other" },
  ];

  const reportFilterOptions = [
    { label: "Select Option" },
    { label: "Arrival", value: "arrival" },
    { label: "Departure", value: "departure" },
    { label: "In House", value: "in_house" },
    // { label: "RevPar", value: "rev_par" },
  ];

  const yearFilterOptions = [
    { label: "Select Option" },
    { label: "Date", value: "date" },
    { label: "Month", value: "month" },
  ];

  const reportTypeOptions = [
    { label: "Select Option" },
    { label: "Achieved Revenue", value: "achieved_revenue" },
    { label: "Achieved Occupancy", value: "achieved_occupancy" },
    { label: "Achieved RevPar", value: "achieved_revpar" },
    { label: "Competitors Reports", value: "competitor_reports" },
  ];

  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  useEffect(() => {
    document.title = "RevCatalyst - Boosting Your Hotel Performance";

    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [orders, setOrders] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [filterName, setFilterName] = useState("Comparison");
  const [yearFilter, setYearFilter] = useState("date");
  const [reportType, setReportType] = useState("achieved_revenue");

  const [urlFilter, setUrlFilter] = useState("occupancy");

  const handleUrlInnerFilter = useCallback(
    (e) => {
      if (e === "payment_ledger_report") {
        setUrlFilter("payment");
      } else {
        setUrlFilter("occupancy");
      }
    },
    [urlFilter]
  );

  const generateYearOptions = (currentYear) => {
    const numYears = 3;
    const startYear = currentYear - numYears;
    const endYear = currentYear;

    const years = [];
    years.push({ label: "Select a year", value: "" });
    for (let year = startYear; year <= endYear; year++) {
      years.push({ label: year.toString(), value: year });
    }

    return years;
  };

  const data = [
    {
      name: "Booking",
      color: "#7C9CF3",
      data: chartData,
    },
  ];

  // Example usage:
  const currentYear = new Date().getFullYear();
  const yearOptions = generateYearOptions(currentYear);

  const fetchInfo = async (propertyId, roomIds) => {
    try {
      setLoader(true);
      setChartData([]);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/compertitor/analysis/reports/${propertyId}`,
        {
          method: "POST",
          body: JSON.stringify({
            from_date: selectedDatesRange.from_date,
            to_date: selectedDatesRange.to_date,
            room_types: selectedMonths,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.reponsedata);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchFilterInfo = async (propertyId, roomIds) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/compertitor/analysis/reports/${propertyId}`,
        {
          method: "POST",
          body: JSON.stringify({
            from_date: selectedDatesRange.from_date,
            to_date: selectedDatesRange.to_date,
            room_types: selectedRoomType,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.reponsedata);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(true);
      } else {
        showErrorToast(data.message);
        setLoader(true);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const revPar = ["Date", "RevPar", "Date", "RevPar", "Compare"];

  const occupancy = ["Date", "Occupancy", "Date", "Occupancy", "Compare"];

  const adr = ["Date", "ADR", "Date", "ADR", "Compare"];
  const { from_data, to_data } = reportData ? reportData : {};

  const commonColors = ["#ecf1fd", "#ecf1fd", "#fff9e8", "#fff9e8", "#dff8df"];

  return (
    <>
      <section className="dashboard_page">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {tableOneData && tableOneData.length > 0 ? (
            <div
              style={{
                background: "rgba(255, 255, 255, 1)",
                padding: "20px",
                borderRadius: "10px",
              }}
              id="table"
            >
              <div
                style={{ marginBottom: "10px" }}
                id="doNotPrint"
                className="flex justify_content_between"
              >
                <h4 className="report-chart-heading">{title} </h4>
                <div
                  style={{ width: "fit-content" }}
                  id="doNotPrint"
                  className="flex flex_gap_15"
                >
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"table"}
                    downloadFileName={title}
                    ignoreFromPrint={"doNotPrint"}
                  />
                  <DownloadCSVButton
                    itemToDownloadID={"table"}
                    downloadFileName={title}
                  />
                </div>
              </div>
              <div title="Reservation Insights">
                <CustomReportTable
                  headings={
                    status === "Occupancy"
                      ? occupancy
                      : status === "ADR"
                      ? adr
                      : revPar
                  }
                  groupBackgroundColors={commonColors}
                  headerStyles={{
                    padding: "10px 5px",
                    fontWeight: "bold",
                    backgroundColor: "#f2f2f2",
                    borderBottom: "1px solid #CCC",
                  }}
                >
                  {from_data?.map((fromItem, index) => {
                    const toItem = to_data[index] || {};
                    const fromValue = fromItem.value;
                    const toValue = toItem.value;

                    return (
                      <CustomReportTableRow
                        key={index}
                        bodyStyles={{ borderBottom: "1px solid #CCC" }}
                      >
                        <CustomReportTableCell
                          groupBackgroundColors={commonColors[0]}
                        >
                          {fromItem.key}
                        </CustomReportTableCell>
                        <CustomReportTableCell
                          groupBackgroundColors={commonColors[1]}
                        >
                          {status === "Occupancy" ? (
                            <>{fromValue > 100 ? 100 : fromValue}%</>
                          ) : status === "ADR" ? (
                            <>{formatIndianCurrency(fromValue)}</>
                          ) : status === "RevPar" ? (
                            <>{formatIndianCurrency(fromValue)}</>
                          ) : (
                            fromValue
                          )}
                        </CustomReportTableCell>
                        <CustomReportTableCell
                          groupBackgroundColors={commonColors[2]}
                        >
                          {toItem.key}
                        </CustomReportTableCell>
                        <CustomReportTableCell
                          groupBackgroundColors={commonColors[3]}
                        >
                          {toValue}
                        </CustomReportTableCell>
                        <CustomReportTableCell
                          groupBackgroundColors={commonColors[4]}
                          styles={{ width: "100px" }}
                        >
                          
                           

                            {+fromValue >= +toValue ? (
                              <span>
                                <svg
                                  clipRule="evenodd"
                                  width="24"
                                  height="15"
                                  fill-rule="evenodd"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="2"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  transform="rotate(-180 )"
                                >
                                  <path
                                    d="m5.214 14.522s4.505 4.502 6.259 6.255c.146.147.338.22.53.22s.384-.073.53-.22c1.754-1.752 6.249-6.244 6.249-6.244.144-.144.216-.334.217-.523 0-.193-.074-.386-.221-.534-.293-.293-.766-.294-1.057-.004l-4.968 4.968v-14.692c0-.414-.336-.75-.75-.75s-.75.336-.75.75v14.692l-4.979-4.978c-.289-.289-.761-.287-1.054.006-.148.148-.222.341-.221.534 0 .189.071.377.215.52z"
                                    fill-rule="nonzero"
                                  />
                                </svg>
                              </span>
                            ) : (
                              <span>
                                <svg
                                  clipRule="evenodd"
                                  width="24"
                                  height="15"
                                  fill-rule="evenodd"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="2"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m5.214 14.522s4.505 4.502 6.259 6.255c.146.147.338.22.53.22s.384-.073.53-.22c1.754-1.752 6.249-6.244 6.249-6.244.144-.144.216-.334.217-.523 0-.193-.074-.386-.221-.534-.293-.293-.766-.294-1.057-.004l-4.968 4.968v-14.692c0-.414-.336-.75-.75-.75s-.75.336-.75.75v14.692l-4.979-4.978c-.289-.289-.761-.287-1.054.006-.148.148-.222.341-.221.534 0 .189.071.377.215.52z"
                                    fill-rule="nonzero"
                                  />
                                </svg>
                              </span>
                            )}
                         
                        </CustomReportTableCell>
                      </CustomReportTableRow>
                    );
                  })}
                </CustomReportTable>
              </div>
            </div>
          ) : (
            <NoDataFound />
          )}
        </div>
      </section>
    </>
  );
};

export default ComparisonRevparDoubleCharts;
