import React from "react";
import AddBlogComp from "../../Components/Websites/AddBlogComp";

const AddBlog = () => {
    return (
        <>
            <div className="main_container">
                <AddBlogComp />
            </div>
        </>
    );
};

export default AddBlog;
