import React, { useState, useEffect, useCallback } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  CheckWithoutBgUnderlineBtn,
} from "../UI/Buttons";
import { useSelector } from "react-redux";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import RoomTypeAmenities from "../CustomComponents/RoomTypeAmenities/RoomTypeAmenities";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { Icon, Label, LegacyCard, LegacyStack } from "@shopify/polaris";
import { CancelMajor } from "@shopify/polaris-icons";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import CustomCountryStateSelector from "../CustomComponents/CustomCountryStateSelector";

const ProfileSettingsNew = () => {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);

  const [formData, setFromData] = useState({});

  useEffect(() => {
    if (property_id) {
      getPropertyData(property_id?.id);
    }
  }, [property_id]);

  const getPropertyData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/properties/edit/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        id: data.property.id,

        amenities: data.property.amenities || [],
        property_name: data.property.property_name,
        property_phone: data.property.property_phone,
        property_landline: data.property.property_landline,
        property_landline2: data.property.property_landline2,
        property_email: data.property.property_email,
        property_alternate_email: data.property_alternate_email,
        property_type: data.property.property_type,
        food_prefix: data.property.food_prefix,
        booking_prefix: data.property.booking_prefix,
        property_address: data.property.property_address,
        property_country: data.property.property_country,
        property_city: data.property.property_city,
        property_state: data.property.property_state,
        property_tax_rate: data.property.property_tax_rate,
        property_zip: data.property.property_zip,
        property_description: data.property.property_description,
        // ..................
        property_area: data.property.property_area || "",
        gst_no: data.property.gst_no,
        fssai_no: data.property.fssai_no,
        pan_no: data.property.pan_no,

        property_checkin: data.property.property_checkin,
        property_checkout: data.property.property_checkout,
        property_facebook: data.property.property_facebook,
        property_instagram: data.property.property_instagram,
        property_twitter: data.property.property_twitter,
        property_linkedin: data.property.rates_and_deals_timespan,
        property_threads: data.property.property_threads,
        property_whatsapp: data.property.property_whatsapp,
        property_status: data.property.property_status,
        property_hotel_id: data.property.property_hotel_id,
        rates_and_deals_timespan: data.property.rates_and_deals_timespan,
        tentative_hold_timespan: data.property.tentative_hold_timespan,
        tentative_reconfirmation_time:
          data.property.tentative_reconfirmation_time,
        secondary_color: data.property.secondary_color,
        primary_color: data.property.primary_color,
        property_start_rating: data.property.property_start_rating,
        channel_manager_hotel_id: data?.property?.channel_manager_hotel_id,
        property_start_rating: data?.property?.property_start_rating,
      };
      setFromData(updatedData);
      setAmenities(
        data?.property?.amenities?.map((amenity) =>
          amenity?.amenity_name ? amenity?.amenity_name : null
        )
      );

      // setAmenities(data?.property?.amenities);
      setSelectedDiscount({
        discount_amount: data?.property?.discount_amount,
        discount_type: data?.property?.discount_type,
      });
      setIsToggled(data?.property?.is_enable === "disable"? false: true);
      console.log(data?.property?.is_enable,"ashfcsfgvwoeibyhe")
      
    } catch (error) {
      console.error(error);
      // setLoader(false);
    }
  };


  const [loader, setLoader] = useState(false);

  const handleProfileSettingSubmit = (e) => {
    e.preventDefault();

    const requestBody = {
      id: formData.id,
      amenities: formData.amenities,
      channel_manager_hotel_id: formData.channel_manager_hotel_id,
      fssai_no: formData.fssai_no,
      gst_no: formData.gst_no,
      pan_no: formData.pan_no,
      property_address: formData.property_address,
      property_area: formData.property_area,
      property_city: formData.property_city,
      property_country: formData.property_country,
      property_description: formData.property_description,
      property_email: formData.property_email,
      property_type: formData.property_type,
      property_landline: formData.property_landline,
      property_name: formData.property_name,
      property_phone: formData.property_phone,
      property_start_rating: formData.property_start_rating,
      property_state: formData.property_state,
      property_zip: formData.property_zip,
      property_checkin: formData.property_checkin,
      property_checkout: formData.property_checkout,
      property_alternate_email: formData.property_alternate_email,
      discount_type: selectedDiscount.discount_type,
      discount_amount: selectedDiscount.discount_amount,
      is_enable: isToggled ? "enable" : "disable",
    };

    setLoader(true);

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/properties/update/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          showSuccessToast(data.message);
          getPropertyData(property_id?.id);
          setFormEditMode(false);
          setLoader(false);
        } else {
          setLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
        setLoader(false);
      });
  };

  const handleFormDataInputChange = (e) => {
    const { name, value } = e.target;
    setFromData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const getAmenitiesFromChild = (data) => {
    setFromData((prevFormData) => ({
      ...prevFormData,
      amenities: data,
    }));
  };

  // console.log(formData,"formData")
  const handleSelectCountryState = (data) => {
    setFromData((prevState) => ({
      ...prevState,
      property_country: data.country,
      property_state: data.state,
    }));
  };
  const [formEditMode, setFormEditMode] = useState(false);

  const [amenities, setAmenities] = useState([]);

  const ratingsOption = [
    { label: "Select", value: "" },
    { label: "1 Star", value: "1 Star" },
    { label: "2 Star", value: "2 Star" },
    { label: "3 Star", value: "3 Star" },
    { label: "4 Star", value: "4 Star" },
    { label: "5 Star", value: "5 Star" },
  ];

  const propertyAreaOptions = [
    { label: "Select", value: "" },
    { label: "Hilly", value: "Hilly" },
    { label: "Coastal", value: "Coastal" },
    { label: "Urban", value: "Urban" },
    { label: "Suburban", value: "Suburban" },
    { label: "Rural", value: "Rural" },
    { label: "Mountainous", value: "Mountainous" },
    { label: "Desert", value: "Desert" },
    { label: "Island", value: "Island" },
    { label: "Forest", value: "Forest" },
    { label: "Lakefront", value: "Lakefront" },
  ];
  const propertyTypeOptions = [
    { label: "Select", value: "" },
    { label: "Budget", value: "budget" },
    { label: "Standard", value: "standard" },
    { label: "Luxury", value: "luxury" },
    { label: "Deluxe", value: "deluxe" },
    { label: "Super Deluxe", value: "super deluxe" },
    { label: "Premium", value: "premium" },
  ];
  // console.log(amenities,"aschaho")

  const [selectedDiscount, setSelectedDiscount] = useState("");

  const handleDiscountChange = async (item) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/property-discount-add/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            discount_type: item.discount_type,
            discount_amount: item.discount_amount,
            is_enable: "enable",
          }),
        }
      );

      const data = await response.json();
      if (data.success) {
        setSelectedDiscount(item);
        showSuccessToast("offer added successfully");
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };

  const propertySpecialOffers = [
    { discount_amount: "1.00", discount_type: "fixed" },
    {
      discount_amount: "25.00",
      discount_type: "percent",
    },
    { discount_amount: "0.00", discount_type: "fixed" },
    // {
    //   discount_amount: "full",
    //   discount_type: "fixed",
    //   label: "Pay full amount and Book",
    // },
  ];
  const [isToggled, setIsToggled] = useState();
  const ToggleActiveDisable = (props) => {
    
    
  
    const handleToggle = useCallback(() => {
      setIsToggled((prevIsToggled) => !prevIsToggled);
    }, []);
    
    return (
      <div className="flex align_items_center">
        <label className="toggle-container">
          <input
            name="extra_bedding"
            type="checkbox"
            checked={isToggled}
            onChange={handleToggle}
            className="toggle-checkbox-custom"
          />
          <span className="toggle-slider"></span>
        </label>
      </div>
    );
  };
  return (
    <>
      <form onSubmit={handleProfileSettingSubmit}>
        <div
          className="flex  justify_content_between"
          style={{ width: "100%" }}
        >
          <div style={{ width: "65%" }}>
            {/* general details */}
            <div className="profile_tab_form ">
              <h3>General Details</h3>

              {formEditMode ? (
                <>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        onChange={handleFormDataInputChange}
                        type={"text"}
                        name="property_name"
                        focused={false}
                        titleName="Legal Property Name "
                        autoComplete="off"
                        //
                        value={formData.property_name}
                      />
                    </div>

                    <div className="form-container-div ">
                      <VerticalInputSelectCustomCopy
                        required={true}
                        onChange={handleFormDataInputChange}
                        type={"text"}
                        name="property_type"
                        focused={false}
                        titleName="Property Type "
                        options={propertyTypeOptions}
                        autoComplete="off"
                        //
                        value={formData.property_type}
                      />
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        titleName={"Property Rating "}
                        required={true}
                        name="property_start_rating"
                        options={ratingsOption}
                        value={formData.property_start_rating}
                        onChange={handleFormDataInputChange}
                      />
                    </div>

                    <div className="form-container-div ">
                      {/* <VerticalInputFieldCustom
                        required={true}
                        onChange={handleFormDataInputChange}
                        type={"text"}
                        name="property_email"
                        focused={false}
                        titleName="Property Area "
                        autoComplete="off"
                        value={formData.property_email}
                      /> */}
                      <VerticalInputSelectCustomCopy
                        required={true}
                        onChange={handleFormDataInputChange}
                        options={propertyAreaOptions}
                        name="property_area"
                        focused={false}
                        titleName="Property Area "
                        value={formData.property_area}
                      />
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div ">
                      <VerticalInputFieldCustom
                        format={"hh:mm A"}
                        type="time"
                        onChange={handleFormDataInputChange}
                        name="property_checkin"
                        className="textfield"
                        focused={false}
                        titleName="Check-In"
                        autoComplete="off"
                        value={formData.property_checkin}
                      />
                    </div>

                    <div className="form-container-div ">
                      <VerticalInputFieldCustom
                        format={"hh:mm A"}
                        type="time"
                        onChange={handleFormDataInputChange}
                        name="property_checkout"
                        className="textfield"
                        focused={false}
                        titleName="Check-Out"
                        autoComplete="off"
                        value={formData.property_checkout}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">
                      Legal Property Name
                    </div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_name}
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">Property Type</div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_type}
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">Property Rating</div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_start_rating}
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">Property Area</div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_area}
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">Check-In</div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_checkin}
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">Check-Out</div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_checkout}
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* contact details */}
            <div className="profile_tab_form">
              <h3>Contact Details</h3>

              {formEditMode ? (
                <>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        onChange={handleFormDataInputChange}
                        type={"text"}
                        name="property_phone"
                        focused={false}
                        titleName="Phone Number "
                        autoComplete="off"
                        value={formData.property_phone}
                      />
                    </div>

                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        // required={true}
                        onChange={handleFormDataInputChange}
                        type={"text"}
                        name="property_landline"
                        focused={false}
                        titleName="Landline Number"
                        autoComplete="off"
                        value={formData.property_landline}
                      />
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        onChange={handleFormDataInputChange}
                        type={"text"}
                        name="property_email"
                        focused={false}
                        titleName="Email Address "
                        autoComplete="off"
                        value={formData.property_email}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={false}
                        onChange={handleFormDataInputChange}
                        type={"text"}
                        name="property_alternate_email"
                        focused={false}
                        titleName="Alternative Email (Optional)"
                        autoComplete="off"
                        value={formData.property_alternate_email}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">Phone Number</div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_phone}
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">Landline Number</div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_landline}
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">Email</div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_email}
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">Alternative Email</div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_alternate_email}
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* property address */}

            <div className="profile_tab_form">
              <h3>Property Address</h3>

              {formEditMode ? (
                <>
                  <div className="flex justify_content_between flex_gap_10">
                    {/* <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        onChange={handleFormDataInputChange}
                        type={"text"}
                        name="property_country"
                        focused={false}
                        titleName="Country "
                        autoComplete="off"
                        value={formData.property_country}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        onChange={handleFormDataInputChange}
                        type={"text"}
                        name="property_state"
                        focused={false}
                        titleName="State"
                        autoComplete="off"
                        value={formData.property_state}
                      />
                    </div> */}
                    <CustomCountryStateSelector
                      onSelect={handleSelectCountryState}
                      getCountryState={handleSelectCountryState}
                      defaultCountry={formData.property_country}
                      defaultState={formData.property_state}
                    />
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        onChange={handleFormDataInputChange}
                        type={"text"}
                        name="property_city"
                        focused={false}
                        titleName="City"
                        autoComplete="off"
                        value={formData.property_city}
                      />
                    </div>

                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        onChange={handleFormDataInputChange}
                        type={"text"}
                        name="property_zip"
                        focused={false}
                        titleName="Pin Code "
                        autoComplete="off"
                        value={formData.property_zip}
                      />
                    </div>
                  </div>

                  <VerticalInputFieldCustom
                    required={true}
                    onChange={handleFormDataInputChange}
                    type={"text"}
                    name="property_address"
                    focused={false}
                    titleName="Address"
                    autoComplete="off"
                    value={formData.property_address}
                  />
                </>
              ) : (
                <>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">Country</div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_country}
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">State</div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_state}
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">City</div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_city}
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">Pin Code</div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_zip}
                    </div>
                  </div>
                  <div className="flex justify_content_between flex_gap_10 ">
                    <div className="form-container-div">Address</div>

                    <div
                      className="form-container-div  "
                      style={{ textAlign: "right" }}
                    >
                      {formData.property_address}
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* property description */}

            <div className="profile_tab_form">
              <h3>Property Description</h3>

              {formEditMode ? (
                <>
                  <VerticalInputFieldCustom
                    required={false}
                    onChange={handleFormDataInputChange}
                    type={"text"}
                    name="property_description"
                    focused={false}
                    titleName=""
                    autoComplete="off"
                    value={formData.property_description}
                  />
                </>
              ) : (
                <>
                  <div className="form-container-div  ">
                    {formData.property_description}
                  </div>
                </>
              )}
            </div>
          </div>

          <div style={{ width: "32%" }}>
            {/* amenities */}

            <div className="profile_tab_form">
              <h3>Amenities</h3>

              {formEditMode ? (
                <>
                  <RoomTypeAmenities
                    getAmenitiesFromChild={getAmenitiesFromChild}
                    searchBox={true}
                    amenities={amenities}
                  />
                </>
              ) : (
                <>
                  <div className="flex flex_wrap flex_gap_5">
                    {amenities?.length > 0 &&
                      amenities?.map((item, index) => (
                        <div key={index}>
                          <p className="profile_settings_amenities">{item}</p>
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>

            {/*property id */}

            <div className="profile_tab_form">
              <h3>Property ID</h3>

              {/**/}

              {formEditMode ? (
                <>
                  <VerticalInputFieldCustom
                    required={true}
                    onChange={handleFormDataInputChange}
                    type={"text"}
                    name="gst_no"
                    focused={false}
                    titleName="GST No."
                    autoComplete="off"
                    value={formData?.gst_no}
                  />

                  <VerticalInputFieldCustom
                    required={true}
                    onChange={handleFormDataInputChange}
                    type={"text"}
                    name="pan_no"
                    focused={false}
                    titleName="PAN No."
                    autoComplete="off"
                    value={formData?.pan_no}
                  />

                  <VerticalInputFieldCustom
                    required={true}
                    onChange={handleFormDataInputChange}
                    type={"text"}
                    name="fssai_no"
                    focused={false}
                    titleName="FSSAI No."
                    autoComplete="off"
                    value={formData?.fssai_no}
                    // value={formData.property_email}
                  />
                </>
              ) : (
                <>
                  <div>
                    <div
                      className="form-container-div"
                      style={{ fontWeight: "500", fontSize: "15px" }}
                    >
                      GST No.
                    </div>

                    <div className="form-container-div">
                      {formData.gst_no || "NA"}
                    </div>
                  </div>
                  <div>
                    <div
                      className="form-container-div"
                      style={{ fontWeight: "500", fontSize: "15px" }}
                    >
                      PAN No.
                    </div>

                    <div className="form-container-div">
                      {formData?.pan_no || "NA"}
                    </div>
                  </div>
                  <div>
                    <div
                      className="form-container-div"
                      style={{ fontWeight: "500", fontSize: "15px" }}
                    >
                      FSSAI No.
                    </div>

                    <div className="form-container-div">
                      {formData?.fssai_no || "NA"}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="profile_tab_form ">
              <div className="flex justify_content_between align_center">
                <h3>Property Special Offers</h3>
               {
                formEditMode && ( <ToggleActiveDisable
                  positive={"enable"}
                  negative={"disable"}
                
                />)
               }
              </div>

              {formEditMode ? (
                <>
                  {isToggled && propertySpecialOffers.map((option, index) => {
                    return (
                      <div key={index}>
                        <label style={{ cursor: "pointer" }}>
                          <input
                            type="radio"
                            name="paymentOption"
                            value={option.discount_amount}
                            checked={
                              selectedDiscount.discount_amount ===
                                option.discount_amount &&
                              selectedDiscount.discount_type ===
                                option.discount_type
                            }
                            onChange={() => handleDiscountChange(option)}
                            style={{ marginRight: "5px" }}
                          />
                          {/* {option.label} */}
                          {option.discount_amount !== undefined && (
                            <>
                              Pay{" "}
                              {option.discount_type === "fixed" ? (
                                <>₹{option.discount_amount}</>
                              ) : (
                                <>{option.discount_amount} %</>
                              )}{" "}
                              now and book
                            </>
                          )}
                        </label>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  { isToggled &&  (
                    <div>
                      <label style={{ cursor: "pointer" }}>
                        <input
                          type="radio"
                          name="paymentOption"
                          checked
                          onChange={() => {}}
                          style={{ marginRight: "5px" }}
                          disabled
                        />
                        {/* {option.label} */}
                        {selectedDiscount.discount_amount !== undefined && (
                          <>
                            Pay{" "}
                            {selectedDiscount.discount_type === "fixed" ? (
                              <>₹{selectedDiscount.discount_amount}</>
                            ) : (
                              <>{selectedDiscount.discount_amount} %</>
                            )}{" "}
                            now and book
                          </>
                        )}
                      </label>
                    </div>
                  )}

                  {}
                </>
              )}
            </div>

            {/* chanel manager hotel id */}

            {/* <div className="profile_tab_form">
              <h3>Channel Manager Hotel ID</h3>

              {formEditMode ? (
                <>
                  <VerticalInputFieldCustom
                    required={false}
                    onChange={handleFormDataInputChange}
                    type={"text"}
                    name="channel_manager_hotel_id"
                    focused={false}
                    titleName=""
                    autoComplete="off"
                    value={formData.channel_manager_hotel_id}
                  />
                </>
              ) : (
                <>
                  <div className="form-container-div  ">
                    {formData.channel_manager_hotel_id || "NA"}
                  </div>
                </>
              )}
            </div> */}
          </div>
        </div>

        {formEditMode ? (
          <BgThemeButton
            children={"Update"}
            type={"submit"}
            loader={loader}
            // onClick={handleProfileSettingSubmit}
          />
        ) : (
          <BgThemeButton
            children={"Edit"}
            type={"button"}
            onClick={(e) => {
              e.preventDefault();
              setFormEditMode(true);
            }}
          />
        )}
        {/* <BgThemeButton
          children={formEditMode ? "Update" : "Edit"}
          type={formEditMode ? "submit" : "button"}
          onClick={
            !formEditMode
              ? () => setFormEditMode(true)
              : handleProfileSettingSubmit
          }
        /> */}
      </form>
    </>
  );
};

export default ProfileSettingsNew;
