import "./customReportTable.css";

export const CustomReportTable = ({
  children,
  headings,
  commonHeadings,
  headerStyles,
  commonTextColors,
  groupBackgroundColors,
  commonHeadingsTop,
}) => {
  const calculateColSpans = (commonHeadings) => {
    const colspanMap = [];
    let currentHeading = commonHeadings[0];
    let count = 0;

    commonHeadings?.forEach((heading, index) => {
      if (heading === currentHeading) {
        count++;
      } else {
        if (currentHeading !== "") {
          colspanMap.push({ heading: currentHeading, colspan: count });
        } else {
          for (let i = 0; i < count; i++) {
            colspanMap.push({ heading: currentHeading, colspan: 1 });
          }
        }
        currentHeading = heading;
        count = 1;
      }

      if (index === commonHeadings?.length - 1) {
        if (currentHeading !== "") {
          colspanMap.push({ heading: currentHeading, colspan: count });
        } else {
          for (let i = 0; i < count; i++) {
            colspanMap.push({ heading: currentHeading, colspan: 1 });
          }
        }
      }
    });

    return colspanMap;
  };

  const colspanMap = Array.isArray(commonHeadings)
    ? calculateColSpans(commonHeadings)
    : null;
  const colspanColorMap = Array.isArray(commonTextColors)
    ? calculateColSpans(commonTextColors)
    : null;
  const colspanBackgoundColorMap = Array.isArray(groupBackgroundColors)
    ? calculateColSpans(groupBackgroundColors)
    : null;

  const colSpanMapTop = Array.isArray(commonHeadingsTop)
    ? calculateColSpans(commonHeadingsTop)
    : null;

  return (
    <>
      <div className="custom_report_table_container">
        <table className="custom_report_table repeat-table-headers">
          <thead style={ headerStyles}>
            {commonHeadingsTop && commonHeadingsTop?.length > 0 && (
              <>
                <tr
                  style={{
                    fontSize: headerStyles && headerStyles?.fontSize,
                    fontFamily: headerStyles && headerStyles?.fontFamily,
                  }}
                  className="custom_report_table_commonHeading_row"
                >
                  {colSpanMapTop?.map((item, index) => (
                    <th
                      key={index}
                      style={{
                        borderBottom:
                          item.heading !== "" && ".5px solid #9e9d9d",
                        borderLeft: index !== 0 && ".5px solid #9e9d9d",
                        textAlign: headerStyles && headerStyles?.textAlign,
                        padding: headerStyles && headerStyles?.padding,
                      }}
                      colSpan={item.colspan}
                      className="report_table_head_row_th"
                    >
                      {item?.heading}
                    </th>
                  ))}
                </tr>
              </>
            )}

            {commonHeadings && commonHeadings?.length > 0 && (
              <>
                <tr
                  style={{
                    fontSize: headerStyles && headerStyles?.fontSize,
                    fontFamily: headerStyles && headerStyles?.fontFamily,
                  }}
                  className={
                    commonHeadingsTop && commonHeadingsTop.length > 0
                      ? ""
                      : "custom_report_table_commonHeading_row"
                  }
                >
                  {colspanMap?.map((item, index) => (
                    <th
                      key={index}
                      style={{
                        borderBottom:
                          item.heading !== "" && ".5px solid #9e9d9d",
                        borderLeft: index !== 0 && ".5px solid #9e9d9d",
                        color:
                          commonTextColors && colspanColorMap[index].heading,
                        backgroundColor:
                          groupBackgroundColors &&
                          colspanBackgoundColorMap[index].heading,
                        textAlign: headerStyles && headerStyles?.textAlign,
                        padding: headerStyles && headerStyles?.padding,
                        // backgroundColor:"red"
                      }}
                      colSpan={item.colspan}
                      className="report_table_head_row_th"
                    >
                      {item?.heading}
                    </th>
                  ))}
                </tr>
              </>
            )}
            <tr style={headerStyles}>
              {headings?.map((heading, index) => (
                <th
                  key={index}
                  style={{
                    borderLeft: index !== 0 && ".5px solid #9e9d9d",
                    color: commonTextColors && commonTextColors[index],
                    backgroundColor:
                      groupBackgroundColors && groupBackgroundColors[index],
                    textAlign: headerStyles && headerStyles?.textAlign,
                    padding: headerStyles && headerStyles?.padding,
                    borderBottom: headerStyles && headerStyles?.borderBottom,
                  }}
                  className="report_table_head_row_th"
                >
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
    </>
  );
};

export const CustomReportTableRow = ({
  id,
  children,
  bodyStyles,
  index,
  itemsLength,
}) => {

  return (
    
    <tr
      key={id}
      className="custom_index_table_body_row_tr"
      style={{
        ...bodyStyles,
        borderBottom:
          index === (itemsLength - 1) ? "none" : bodyStyles?.borderBottom,
      }}
      // style={{ bodyStyles }}
    >
      {children}
    </tr>
  );
};

export const CustomReportTableCell = ({
  children,
  commonTextColors,
  groupBackgroundColors,
  styles,
}) => {
  return (
    <td
      className="custom_index_table_body_row_td"
      style={{
        ...styles,
        color: commonTextColors,
        backgroundColor: groupBackgroundColors,
       
        
      }}
    >
      {children}
    </td>
  );
};
