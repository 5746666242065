import React from "react";
import { useState, useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./AddRoomBill.css";
import LOGO from "../../../assets/images/logoG.png";
import { showErrorToast } from "../../../assets/toastUtils";
import { useSelector } from "react-redux";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "../../UI/Buttons";
import VerticalInputFieldCustom from "../../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../../CustomComponents/VerticalInputSelectCustomCopy";
import { useParams } from "react-router-dom";

const AddRoomBill = () => {
  const [showShareOnEmail, setShowShareOnEmail] = useState(false);
  const [showShareOnWhatsapp, setShowShareWhatsapp] = useState(false);
  const [bookingData, setBookingData] = useState({});
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const property_id = useSelector((state) => state.property.value);

  const [singleBillData, setSingleBillData] = useState({
    name: "",
    email: "",
    gst: "",
    phone: "",
    user: "new_customer",
    bill_date: "",

    dob: "",
    country: "",
    address: "",
    pincode: "",
    identity_proof_type: "",
    identity_proof_no: "",
    identity_proof_img: "",
  });

  const [editableItems, setEditableItems] = useState([
    {
      refNo: 0,
      description: 0,
      qty: 0,
      rate: 0,
      amount: 0,
      discount: 0,
      discountAmount: 0,
      taxRate: 0,
      netAmount: 0,
      debit: 0,
      credit: 0,
      balance: 0,
      date: "2023-01-01",
    },
  ]);

  //   const navigate = useNavigate();

  const [loader, setLoader] = useState(true);

  const discountForSubTotal = "10%";
  const subTotal = editableItems
    .map((item) => parseFloat(item.balance))
    .filter((balance) => !isNaN(balance))
    .reduce((total, balance) => total + balance, 0);

  const grandTotal =
    subTotal - (subTotal * parseFloat(discountForSubTotal)) / 100;

  const [editMode, setEditMode] = useState(true);

  const handleEdit = (index, field, value) => {
    const newItems = [...editableItems];
    newItems[index][field] = value;
    setEditableItems(newItems);
  };

  const handleQuantityChange = (index, newQty) => {
    const newItems = [...editableItems];
    const item = { ...newItems[index] };
    item.qty = newQty;
    item.amount = parseFloat(newQty) * parseFloat(item.rate);
    item.discountAmount =
      item.amount - (item.amount * parseFloat(item.discount)) / 100;
    item.netAmount =
      item.discountAmount +
      (item.discountAmount * parseFloat(item.taxRate)) / 100;
    item.balance =
      item.discountAmount +
      (item.discountAmount * parseFloat(item.taxRate)) / 100;
    newItems[index] = item;
    setEditableItems(newItems);
  };

  const handleDiscountChange = (index, newDis) => {
    const newItems = [...editableItems];
    const item = { ...newItems[index] };
    item.discount = newDis;
    item.discountAmount =
      item.amount - (item.amount * parseFloat(newDis)) / 100;
    item.netAmount = item.discountAmount + (item.discountAmount * 5) / 100;

    item.balance = item.netAmount;

    newItems[index] = item;
    setEditableItems(newItems);
  };

  const handleRateChange = (index, newRate) => {
    const newItems = [...editableItems];
    const item = { ...newItems[index] };
    item.rate = newRate;
    item.amount = parseFloat(newRate) * parseFloat(item.qty);
    item.discountAmount =
      item.amount - (item.amount * parseFloat(item.discount)) / 100;

    item.netAmount =
      item.discountAmount * (parseFloat(item.taxRate) / 100) +
      item.discountAmount;
    item.balance =
      item.discountAmount * (parseFloat(item.taxRate) / 100) +
      item.discountAmount;
    newItems[index] = item;
    setEditableItems(newItems);
  };

  const handleTaxRateChange = (index, newTaxRate) => {
    const newItems = [...editableItems];
    const item = { ...newItems[index] };
    item.taxRate = parseFloat(newTaxRate);
    if (isNaN(item.taxRate)) {
      item.netAmount = 0;
    } else {
      if (!isNaN(item.discountAmount) && !isNaN(item.taxRate)) {
        item.netAmount =
          item.discountAmount + (item.discountAmount * item.taxRate) / 100;
      } else {
        item.netAmount = 0;
      }
    }

    item.balance = item.netAmount;
    newItems[index] = item;
    setEditableItems(newItems);
  };
  const handleDebitChange = (index, newTaxRate) => {
    const newItems = [...editableItems];
    const item = { ...newItems[index] };

    if (newTaxRate > item.netAmount) {
      alert("Debit cannot be greater than net amount");
      return;
    }
    item.debit = newTaxRate;
    item.balance = newTaxRate;
    item.credit = 0;
    newItems[index] = item;
    setEditableItems(newItems);
  };

  const handleCreditChange = (index, newTaxRate) => {
    const newItems = [...editableItems];
    const item = { ...newItems[index] };
    if (newTaxRate > item.netAmount) {
      alert("Credit cannot be greater than net amount");
      return;
    }

    item.credit = newTaxRate;
    item.balance = item.netAmount - newTaxRate;
    item.debit = 0;
    newItems[index] = item;
    setEditableItems(newItems);
  };

  const handleUpdate = () => {
    setEditMode(false);
  };

  const setAddItemDataHandleChange = (event) => {
    setSingleBillData({
      ...singleBillData,
      [event.target.name]: event.target.value,
    });
  };

  const { id } = useParams();

  const fetchEditDataHandler = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/hotel/fetch/customer/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setSingleBillData(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };


  useEffect(() => {
    if (property_id || id) {
      fetchEditDataHandler(property_id?.id);
    }
  }, [property_id]);

  const paymentModeOptions = [
    { label: "Select Option", value: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    { label: "Other", value: "other" },
  ];

  const [billType, setBillType] = useState("room");
  const billTypeOptions = [
    // { label: "Select Option", value: "Select Option" },
    { label: "Room", value: "room" },
    { label: "Housekeeping", value: "housekeeping" },
    { label: "F&B", value: "F&B" },
  ];

  const handleAddRow = () => {
    const newItem = {
      refNo: "",
      description: "",
      qty: 0,
      rate: 0,
      amount: 0,
      taxRate: 0,
      debit: 0,
      credit: 0,
      balance: 0,
      netAmount: 0,
      remove: true,
    };

    const lastItem = editableItems[editableItems.length - 1];
    if (
      lastItem &&
      lastItem.refNo === "" &&
      lastItem.description === "" &&
      lastItem.qty === 0 &&
      lastItem.rate === 0 &&
      lastItem.amount === 0 &&
      lastItem.taxRate === 0 &&
      lastItem.debit === 0 &&
      lastItem.credit === 0 &&
      lastItem.balance === 0 &&
      lastItem.netAmount === 0
    ) {
      showErrorToast("Please fill all the fields");
    } else {
      setEditableItems([...editableItems, newItem]);
    }
  };

  const handleRemoveRow = (index) => {
    if (editableItems.length <= 1) {
      return;
    }
    const newItems = [...editableItems];
    newItems.splice(index, 1);
    setEditableItems(newItems);
  };

  const handlePrint = () => {
    if (!editMode) {
      window.print();
    } else {
      alert("Disable Edit Mode First");
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (editMode && event.ctrlKey && event.key === "p") {
        event.preventDefault();
        alert("Print shortcut is disabled in edit mode");
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [editMode]);

  const containerRef = useRef(null);
  const generatePdf = () => {
    const foodBillContent = containerRef.current;
    if (editMode) {
      alert("Disable Edit Mode First");
    } else {
      html2canvas(foodBillContent, {
        scale: 3,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 1);
        const pdf = new jsPDF({
          unit: "px",
          format: [canvas.width + 40, canvas.height + 40],
        });
        pdf.addImage(imgData, "JPEG", 20, 20, canvas.width, canvas.height);
        pdf.save("food_bill.pdf");
      });
    }
  };

  const [settlementEditMode, setSettlementEditMode] = useState(false);
  const [settlementData, setSettlementData] = useState([
    { paymentOption: "upi", amount: 100, notes: "test notes" },
    { paymentOption: "credit card", amount: 200, notes: "test notes 2" },
  ]);

  const totalSettlementAmount = settlementData.reduce((total, item) => {
    const amount = parseFloat(item.amount);
    return isNaN(amount) ? total : total + amount;
  }, 0);
  const handleSettlementChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSettlementData = [...settlementData];
    updatedSettlementData[index][name] = value;
    setSettlementData(updatedSettlementData);
  };


  const handleUpdateInSettlement = () => {
    const lastItem = settlementData[settlementData.length - 1];
    if (
      (lastItem.paymentOption !== "" && lastItem.amount !== 0,
      lastItem.notes !== "")
    ) {
      if (totalSettlementAmount === grandTotal) {
        setSettlementEditMode(false);
      } else {
        alert("Settlement amount should be equal to total bill amount");
      }
    } else {
      showErrorToast("Please fill all the fields");
    }
  };

  const handleAddRowInSettlement = () => {
    const lastItem = settlementData[settlementData.length - 1];
    const newItem = {
      paymentOption: "",
      amount: 0,
      notes: "",
    };

    if (
      lastItem.paymentOption !== "" &&
      lastItem.amount !== 0 &&
      lastItem.notes !== ""
    ) {
      setSettlementData([...settlementData, newItem]);
    } else {
      showErrorToast("Please fill all the fields");
    }
  };

  const handleRemoveRowInSettlement = (index) => {
    if (settlementData.length <= 1) {
      return;
    }
    const newItems = [...settlementData];
    newItems.splice(index, 1);
    setSettlementData(newItems);
  };

  return (
    <div>
      <div className="main_container">
        <div style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
          <div
            className="booking-choice-full-parent-container-left"
            style={{ width: "100%" }}
          >
            <div className="flex flex_gap_10 justify_content_end mb-10">
              {editMode ? (
                <>
                  <CheckWithoutBgUnderlineBtn onClick={handleUpdate}>
                    Update
                  </CheckWithoutBgUnderlineBtn>
                  <CheckWithoutBgUnderlineBtn onClick={handleAddRow}>
                    Add Row
                  </CheckWithoutBgUnderlineBtn>
                </>
              ) : (
                <>
                  <CheckWithoutBgUnderlineBtn onClick={() => setEditMode(true)}>
                    Edit Bill
                  </CheckWithoutBgUnderlineBtn>
                  <CheckWithoutBgUnderlineBtn
                    onClick={handlePrint}
                    children={"Print"}
                  />

                  <CheckWithoutBgUnderlineBtn
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                      >
                        <path
                          d="M9.50008 12.3566L5.92924 8.78579L6.92908 7.75025L8.78591 9.60708V3.78662H10.2142V9.60708L12.0711 7.75025L13.0709 8.78579L9.50008 12.3566ZM5.21508 15.2133C4.82229 15.2133 4.48591 15.0733 4.20596 14.7934C3.92601 14.5134 3.78627 14.1773 3.78674 13.785V11.6425H5.21508V13.785H13.7851V11.6425H15.2134V13.785C15.2134 14.1777 15.0734 14.5141 14.7935 14.7941C14.5135 15.074 14.1774 15.2138 13.7851 15.2133H5.21508Z"
                          fill="white"
                        />
                      </svg>
                    }
                    children={"Download"}
                    onClick={generatePdf}
                  />
                </>
              )}
            </div>
            <div
              style={{
                // width: "750px",
                margin: "0 auto",
                padding: "20px",
                paddingRight: editMode ? "50px" : "20px",
                paddingLeft: "20px",
                background: "#fff",
              }}
              className="foodBillContent"
              ref={containerRef}
            >
              <table
                style={{
                  width: "100%",
                  fontFamily: "Ariel",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ textAlign: "left" }}>
                      <img src={LOGO} alt="error" width={"150px0px"} />
                    </th>

                    <th
                      style={{
                        textAlign: "right",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      <p
                        style={{
                          width: "300px",
                            fontWeight: "600",
                          margin: "5px 0 5px auto",
                        }}
                      >
                        P:{property_id && property_id.property_phone}
                      </p>
                      <p
                        style={{
                          width: "300px",
                          margin: "5px 0 5px auto",
                          fontWeight: "600",
                        }}
                      >
                        W: www.ecohospitality.com
                      </p>
                      <p
                        style={{
                          width: "300px",
                          // fontSize: "10px",
                          fontWeight: "400",
                          margin: "0 0 0 auto",
                        }}
                      >
                       {property_id && property_id.property_address}
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th
                      colSpan={2}
                      style={{
                        padding: "10px 0"
                      }}
                    >
                      <div
                        style={{ height: "0", borderTop: "0.5px solid #ccc" }}
                      ></div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      <div style={{ fontWeight: "500px" }}>
                        <div>Date</div>
                        <input
                          className="bill-inputs"
                          titleName={"Date"}
                          type={"date"}
                        />
                      </div>
                      <div style={{ margin: "10px 0" }}>
                        <p style={{ fontWeight: "600" }}>INVOICE NO:</p>
                        <p style={{ fontWeight: "500px" }}>123456</p>
                      </div>
                      <div style={{ fontWeight: "500px" }}>
                        <VerticalInputSelectCustomCopy
                          titleName={"Bill Type"}
                          type={"text"}
                          options={billTypeOptions}
                          value={billType}
                          onChange={(e) => setBillType(e.target.value)}
                        />
                      </div>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <div>
                        <div style={{ fontWeight: "500" }}>TO:</div>

                        <span
                          style={{
                            fontWeight: "400px",
                            lineHeight: "15px",
                          }}
                        >
                          <span
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{ fontWeight: "600", marginRight: "5px" }}
                            >
                              Name :
                            </span>
                            <input
                              className="bill-inputs"
                              onChange={setAddItemDataHandleChange}
                              name="name"
                              value={singleBillData.name}
                            />
                          </span>
                        </span>
                        <span
                          style={{
                            fontWeight: "400px",
                            lineHeight: "15px",
                          }}
                        >
                          <span
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{ fontWeight: "600", marginRight: "5px" }}
                            >
                              Phone Number :
                            </span>
                            <input
                              className="bill-inputs"
                              onChange={setAddItemDataHandleChange}
                              name="phone"
                              type="number"
                              value={singleBillData.phone}
                            />
                          </span>
                        </span>
                        <span
                          style={{
                            fontWeight: "400px",
                            lineHeight: "15px",
                          }}
                        >
                          <span
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{ fontWeight: "600", marginRight: "5px" }}
                            >
                              {" "}
                              Email :
                            </span>
                            <input
                              className="bill-inputs"
                              onChange={setAddItemDataHandleChange}
                              name="email"
                              type="text"
                              value={singleBillData.email}
                            />
                          </span>
                          Booking ID: 123456
                        </span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th
                      colSpan={2}
                      style={{
                        padding: "10px 0",
                      }}
                    >
                      <div
                        style={{ height: "0", borderTop: "0.5px solid #ccc" }}
                      ></div>
                    </th>
                  </tr>
                </tbody>
              </table>

              <table
                style={{
                  width: "100%",
                  fontFamily: "Arial",
                  borderCollapse: "collapse",
                }}
              >
                <thead
                  style={{
                    textAlign: "left",
                    fontWeight: "500",
                    fontSize: "10px",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        borderLeft: "1px solid #333",
                        background: "#ccc",
                        padding: "10px",
                      }}
                    >
                      {/* S.No */}
                      Date
                    </th>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        background: "#ccc",
                        padding: "10px",
                      }}
                    >
                      Ref.No
                    </th>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        background: "#ccc",
                        padding: "10px",
                      }}
                    >
                      Description
                    </th>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        background: "#ccc",
                        padding: "10px",
                      }}
                    >
                      Qty
                    </th>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        background: "#ccc",
                        padding: "10px",
                      }}
                    >
                      Rate
                    </th>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        background: "#ccc",
                        padding: "10px",
                      }}
                    >
                      Amount
                    </th>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        background: "#ccc",
                        padding: "10px",
                      }}
                    >
                      Discount
                    </th>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        background: "#ccc",
                        padding: "10px",
                      }}
                    >
                      Taxable Amt.
                    </th>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        background: "#ccc",
                        padding: "10px",
                      }}
                    >
                      Tax Rate
                    </th>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        background: "#ccc",
                        padding: "10px",
                      }}
                    >
                      Net Amount
                    </th>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        background: "#ccc",
                        padding: "10px",
                      }}
                    >
                      Debit
                    </th>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        background: "#ccc",
                        padding: "10px",
                      }}
                    >
                      Credit
                    </th>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        borderRight: "1px solid #333",
                        background: "#ccc",
                        padding: "10px",
                        textAlign: "right",
                      }}
                    >
                      Balance
                    </th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    textAlign: "left",
                    fontWeight: "400",
                    fontSize: "11px",
                  }}
                >
                  {/* Map over billItems array to generate table rows */}
                  {editableItems.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            textAlign: "center",
                            padding: "5px",
                            borderLeft: "1px solid #333",
                            borderBottom:
                              index === editableItems.length - 1
                                ? "1px solid #333"
                                : null,
                          }}
                        >
                          {editMode ? (
                            <input
                              type="date"
                              // value={index + 1}
                              value={item.date}
                              // readOnly
                              name="date"
                              onChange={(e) =>
                                handleEdit(index, "date", e.target.value)
                              }
                              style={{ width: "100%", padding: "2px 5px   " }}
                            />
                          ) : (
                            item.date // index + 1
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            padding: "5px",
                            borderBottom:
                              index === editableItems.length - 1
                                ? "1px solid #333"
                                : null,
                          }}
                        >
                          {editMode ? (
                            <input
                              type="text"
                              value={item.refNo}
                              onChange={(e) =>
                                handleEdit(index, "refNo", e.target.value)
                              }
                              style={{ width: "100%", padding: "2px 5px" }}
                            />
                          ) : (
                            item.refNo
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            padding: "5px",
                            borderBottom:
                              index === editableItems.length - 1
                                ? "1px solid #333"
                                : null,
                          }}
                        >
                          {editMode ? (
                            <input
                              type="text"
                              value={item.description}
                              onChange={(e) =>
                                handleEdit(index, "description", e.target.value)
                              }
                              style={{ width: "100%", padding: "2px 5px" }}
                            />
                          ) : (
                            item.description
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            padding: "5px",
                            borderBottom:
                              index === editableItems.length - 1
                                ? "1px solid #333"
                                : null,
                          }}
                        >
                          {editMode ? (
                            <input
                              type="number"
                              value={isNaN(item.qty) ? 0 : item.qty}
                              onChange={(e) =>
                                handleQuantityChange(index, e.target.value)
                              }
                              style={{ width: "100%", padding: "2px 5px" }}
                            />
                          ) : (
                            item.qty
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            padding: "5px",
                            borderBottom:
                              index === editableItems.length - 1
                                ? "1px solid #333"
                                : null,
                          }}
                        >
                          {editMode ? (
                            <input
                              type="number"
                              value={isNaN(item.rate) ? 0 : item.rate}
                              onChange={(e) =>
                                handleRateChange(index, e.target.value)
                              }
                              style={{ width: "100%", padding: "2px 5px" }}
                            />
                          ) : (
                            item.rate
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            padding: "5px",
                            borderBottom:
                              index === editableItems.length - 1
                                ? "1px solid #333"
                                : null,
                          }}
                        >
                          {editMode ? (
                            <input
                              type="number"
                              value={item.amount}
                              readOnly
                              style={{ width: "100%", padding: "2px 5px" }}
                            />
                          ) : (
                            item.amount
                          )}
                        </td>

                        <td
                          style={{
                            padding: "10px",
                            borderBottom:
                              index === editableItems.length - 1
                                ? "1px solid #333"
                                : null,
                          }}
                        >
                          {editMode ? (
                            <input
                              type="number"
                              value={isNaN(item.discount) ? 0 : item.discount}
                              onChange={(e) =>
                                handleDiscountChange(index, e.target.value)
                              }
                              style={{ width: "100%", padding: "2px 5px" }}
                            />
                          ) : (
                            item.discount
                          )}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderBottom:
                              index === editableItems.length - 1
                                ? "1px solid #333"
                                : null,
                          }}
                        >
                          {editMode ? (
                            <input
                              type="number"
                              value={
                                isNaN(item.discountAmount)
                                  ? 0
                                  : item.discountAmount
                              }
                              readOnly
                              style={{ width: "100%", padding: "2px 5px" }}
                            />
                          ) : (
                            item.discountAmount
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",

                            padding: "5px",
                            borderBottom:
                              index === editableItems.length - 1
                                ? "1px solid #333"
                                : null,
                          }}
                        >
                          {editMode ? (
                            <input
                              type="number"
                              value={
                                isNaN(item.taxRate)
                                  ? 0
                                  : item.taxRate.toString()
                              }
                              onChange={(e) =>
                                handleTaxRateChange(index, e.target.value)
                              }
                              style={{ width: "100%", padding: "2px 5px" }}
                            />
                          ) : (
                            item.taxRate
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",

                            padding: "5px",
                            borderBottom:
                              index === editableItems.length - 1
                                ? "1px solid #333"
                                : null,
                          }}
                        >
                          {editMode ? (
                            <input
                              disabled
                              type="number"
                              value={isNaN(item.netAmount) ? 0 : item.netAmount}
                              readOnly
                              style={{ width: "100%", padding: "2px 5px" }}
                            />
                          ) : (
                            item.netAmount
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",

                            padding: "5px",
                            borderBottom:
                              index === editableItems.length - 1
                                ? "1px solid #333"
                                : null,
                          }}
                        >
                          {editMode ? (
                            <input
                              type="number"
                              value={isNaN(item.debit) ? 0 : item.debit}
                              onChange={(e) =>
                                handleDebitChange(index, e.target.value)
                              }
                              style={{ width: "100%", padding: "2px 5px" }}
                            />
                          ) : (
                            item.debit
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            padding: "5px",
                            borderBottom:
                              index === editableItems.length - 1
                                ? "1px solid #333"
                                : null,
                          }}
                        >
                          {editMode ? (
                            <input
                              type="number"
                              value={isNaN(item.credit) ? 0 : item.credit}
                              onChange={(e) =>
                                handleCreditChange(index, e.target.value)
                              }
                              style={{ width: "100%", padding: "2px 5px" }}
                            />
                          ) : (
                            item.credit
                          )}
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            textAlign: "right",
                            borderRight: "1px solid #333",
                            borderBottom:
                              index === editableItems.length - 1
                                ? "1px solid #333"
                                : null,
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            {editMode ? (
                              <input
                                type="number"
                                value={isNaN(item.balance) ? 0 : item.balance}
                                onChange={(e) =>
                                  handleEdit(index, "balance", e.target.value)
                                }
                                style={{ width: "100%", padding: "2px 5px" }}
                              />
                            ) : (
                              item.balance
                            )}
                            {editMode && item.remove ? (
                              // <td style={{ padding: "10px", textAlign: "center" }}>
                              <>
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "-25px",
                                    top: "4px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleAddRow(index)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                  >
                                    <g clipPath="url(#clip0_1270_978)">
                                      <path
                                        d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                        stroke="#3968ED"
                                      />
                                      <path
                                        d="M10.5866 7H3.41333"
                                        stroke="#3968ED"
                                        strokeMiterlimit="10"
                                      />
                                      <path
                                        d="M7 3.41344L7 10.5867"
                                        stroke="#3968ED"
                                        strokeMiterlimit="10"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1270_978">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </div>
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "-45px",
                                    top: "4px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleRemoveRow(index)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                                      fill="#E03838"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                                      fill="#E03838"
                                    />
                                  </svg>
                                </div>
                              </>
                            ) : (
                              <>
                                {editMode && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "-25px",
                                      top: "4px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleAddRow(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                    >
                                      <g clipPath="url(#clip0_1270_978)">
                                        <path
                                          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                          stroke="#3968ED"
                                        />
                                        <path
                                          d="M10.5866 7H3.41333"
                                          stroke="#3968ED"
                                          strokeMiterlimit="10"
                                        />
                                        <path
                                          d="M7 3.41344L7 10.5867"
                                          stroke="#3968ED"
                                          strokeMiterlimit="10"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1270_978">
                                          <rect
                                            width="14"
                                            height="14"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td
                      colSpan={12}
                      style={{
                        textAlign: "right",
                        padding: "5px",
                        borderTop: "1px solid #333",
                        borderLeft: "1px solid #333",
                      }}
                    >
                      SUB TOTAL
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        textAlign: "right",
                        border: "1px solid #333",
                        background: "#ccc",
                      }}
                    >
                      {subTotal}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={12}
                      style={{
                        textAlign: "right",
                        padding: "5px",
                        borderLeft: "1px solid #333",
                      }}
                    >
                      DISCOUNT
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        textAlign: "right",
                        border: "1px solid #333",
                        background: "#ccc",
                      }}
                    >
                      {discountForSubTotal}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={12}
                      style={{
                        textAlign: "right",
                        padding: "10px",
                        borderLeft: "1px solid #333",
                        borderBottom: "1px solid #333",
                      }}
                    >
                      GRAND TOTAL
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "right",
                        border: "1px solid #333",
                        background: "#ccc",
                      }}
                    >
                      {grandTotal}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                style={{
                  borderCollapse: "collapse",
                  margin: "20px 0",
                  width: "100%",
                  // fontFamily: "Arial, sans-serif",
                }}
              >
                <thead>
                  <tr
                    style={{
                      textAlign: "left",
                      fontWeight: "400",
                      fontSize: "10px",
                    }}
                  >
                    <th colSpan={3} style={{ padding: "10px 0" }}>
                      Transaction
                    </th>
                  </tr>
                  <tr
                    style={{
                      textAlign: "left",
                      fontWeight: "400",
                      fontSize: "10px",
                    }}
                  >
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        borderBottom: "1px solid #333",
                        borderLeft: "1px solid #333",
                        padding: "10px",
                        background: "#ccc",
                      }}
                    >
                      Payment Mode
                    </th>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        borderBottom: "1px solid #333",
                        padding: "10px",
                        background: "#ccc",
                      }}
                    >
                      Notes
                    </th>
                    <th
                      style={{
                        borderTop: "1px solid #333",
                        borderBottom: "1px solid #333",
                        borderRight: "1px solid #333",
                        textAlign: "right",
                        padding: "10px",
                        background: "#ccc",
                      }}
                    >
                      Amount
                    </th>
                    <th style={{ border: "none" }}></th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    textAlign: "left",
                    fontWeight: "400",
                    fontSize: "11px",
                  }}
                >
                  {settlementData.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          textAlign: "left",
                          padding: "10px",
                          border: "1px solid #333",
                          borderRight: "none",
                        }}
                      >
                        {editMode ? (
                          <select
                            name="paymentOption"
                            value={item.paymentOption}
                            onChange={(e) => handleSettlementChange(index, e)}
                            style={{ width: "60%", padding: "2px 5px" }}
                          >
                            {paymentModeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        ) : (
                          item.paymentOption
                        )}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          borderTop: "1px solid #333",
                          borderBottom: "1px solid #333",
                          borderLeft: "none",
                        }}
                      >
                        {editMode ? (
                          <input
                            value={item.notes}
                            name="notes"
                            onChange={(e) => handleSettlementChange(index, e)}
                            style={{ width: "50%", padding: "2px 5px" }}
                          />
                        ) : (
                          item.notes
                        )}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          padding: "10px",
                          border: "1px solid #333",
                          borderLeft: "none",
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          {editMode ? (
                            <input
                              value={item.amount}
                              name="amount"
                              onChange={(e) => handleSettlementChange(index, e)}
                              style={{
                                padding: "2px 5px",
                                textAlign: "right",
                              }}
                            />
                          ) : (
                            item.amount
                          )}

                          {editMode && index !== 0 ? (
                            <>
                              <div
                                style={{
                                  position: "absolute",
                                  right: "-30px",
                                  top: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleAddRowInSettlement(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_1270_978)">
                                    <path
                                      d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                      stroke="#3968ED"
                                    />
                                    <path
                                      d="M10.5866 7H3.41333"
                                      stroke="#3968ED"
                                      strokeMiterlimit="10"
                                    />
                                    <path
                                      d="M7 3.41344L7 10.5867"
                                      stroke="#3968ED"
                                      strokeMiterlimit="10"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1270_978">
                                      <rect
                                        width="14"
                                        height="14"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div
                                style={{
                                  position: "absolute",
                                  right: "-50px",
                                  top: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleRemoveRowInSettlement(index)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                                    fill="#E03838"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                                    fill="#E03838"
                                  />
                                </svg>
                              </div>
                            </>
                          ) : (
                            <>
                              {editMode && (
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "-30px",
                                    top: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleAddRowInSettlement(index)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                  >
                                    <g clipPath="url(#clip0_1270_978)">
                                      <path
                                        d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                        stroke="#3968ED"
                                      />
                                      <path
                                        d="M10.5866 7H3.41333"
                                        stroke="#3968ED"
                                        strokeMiterlimit="10"
                                      />
                                      <path
                                        d="M7 3.41344L7 10.5867"
                                        stroke="#3968ED"
                                        strokeMiterlimit="10"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1270_978">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: "right",
                        padding: "5px",
                        borderTop: "1px solid #333",
                        borderLeft: "1px solid #333",
                      }}
                    >
                      Total Settlement Amount
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        textAlign: "right",
                        border: "1px solid #333",
                        background: "#ccc",
                        width: "10%",
                      }}
                    >
                      {totalSettlementAmount}
                    </td>
                  </tr>
                </tbody>
              </table>
              {editMode && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    marginTop: "10px",
                    gap: "10px",
                  }}
                >
                  {grandTotal !== totalSettlementAmount ? (
                    <BgThemeButtonDisabled children={"Update"} />
                  ) : (
                    <BgThemeButton onClick={generatePdf} children={"Update"} />
                  )}

                  <BgOutlineThemeButton
                    onClick={() => window.location.reload()}
                    children={"Cancel"}
                  />
                </div>
              )}

              {!editMode && (
                <table
                  style={{
                    border: "1px solid #333",
                    width: "100%",
                    borderCollapse: "collapse",
                    borderBottom: "none",
                  }}
                >
                  <tbody
                    style={{
                      textAlign: "left",
                      fontWeight: "400",
                      fontSize: "11px",
                    }}
                  >
                    <tr style={{ background: "#ccc" }}>
                      <td style={{ padding: "10px" }}>HSN Code</td>
                      <td style={{ padding: "10px" }}>Description</td>
                      <td style={{ padding: "10px" }}>Tax Rate</td>
                      <td style={{ padding: "10px" }}>Taxable Amt. </td>
                      <td style={{ padding: "10px" }}>CGST</td>
                      <td style={{ padding: "10px" }}>SGST</td>
                      <td style={{ padding: "10px" }}>VAT</td>
                      <td style={{ padding: "10px", textAlign: "right" }}>
                        Total Amt.
                      </td>
                    </tr>

                    <tr>
                      <td style={{ padding: "10px" }}>12334</td>
                      <td style={{ padding: "10px" }}>Room Rent</td>
                      <td style={{ padding: "10px" }}>12%</td>
                      <td style={{ padding: "10px" }}>10000</td>
                      <td style={{ padding: "10px" }}>600</td>
                      <td style={{ padding: "10px" }}>600</td>
                      <td style={{ padding: "10px" }}>000</td>
                      <td style={{ padding: "10px", textAlign: "right" }}>
                        11000
                      </td>
                    </tr>
                    <tr
                      style={{
                        borderTop: "1px solid #333",
                        borderLeft: "1px solid #fff",
                      }}
                    >
                      <td colSpan={2}></td>
                      <td style={{ padding: "10px" }}>Grand Total:</td>
                      <td style={{ padding: "10px" }}>11500</td>
                      <td style={{ padding: "10px" }}>685</td>
                      <td style={{ padding: "10px" }}>660</td>
                      <td style={{ padding: "10px" }}>00</td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "right",
                          borderRight: "1px solid #fff",
                        }}
                      >
                        12457
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              <table
                style={{
                  borderCollapse: "collapse",
                  borderBottom: "none",
                  fontFamily: "Ariel",
                  width: "100%",
                  margin: "20px 0",
                }}
              >
                <tbody>
                  <tr>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      Thank you for being with us!
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: "center",
                        fontSize: "10px",
                        padding: "15px 0",
                      }}
                    >
                      Bill verified & recommended for payment. I agree to be
                      held personally liable for payment of this bill regardless
                      of charges & instructions
                    </td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "left" }}>Guest Copy</td>
                    <td style={{ textAlign: "right" }}>Guest Signature</td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{ textAlign: "center", paddingTop: "40px" }}
                    >
                      <img src={LOGO} alt="error" width={"150px0px"} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* <div className="booking-choice-full-parent-container-right " style={{ width: "20%" }}>
                        <div className="share_preview_bill">
                            <h2>
                                <strong>Share on:</strong>
                            </h2>
                            <div
                                style={{ padding: "0" }}
                                className="share_preview_bill_child"
                            // onClick={handleShowShareOnEmail}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                >
                                    <circle cx="15" cy="15" r="15" fill="#3968ED" />
                                    <path
                                        d="M8 11.6608V18.9313C8 19.5232 8.47984 20.003 9.07175 20.003H21.2183C21.8102 20.003 22.29 19.5232 22.29 18.9313V11.6607L15.505 15.6187C15.2825 15.7485 15.0074 15.7485 14.7849 15.6187L8 11.6608Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M21.8922 10.2384C21.7081 10.0893 21.4736 10 21.2183 10H9.07175C8.81636 10 8.58183 10.0893 8.39771 10.2384L15.1449 14.1743L21.8922 10.2384Z"
                                        fill="white"
                                    />
                                </svg>
                                <h3>Edit</h3>
                            </div>
                            <div
                                className="hideOnPrint"
                            >
                                {editMode ? (
                                    <>
                                        <button onClick={handleUpdate}>Update</button>
                                        <button onClick={handleAddRow}>Add Row</button>
                                    </>
                                ) : (
                                    <button onClick={() => setEditMode(true)}>Edit</button>
                                )}
                            </div>

                            <div

                                className="hideOnPrint"
                            >
                                <button onClick={handlePrint} className="hideOnPrint">
                                    print
                                </button>
                                <BgThemeButton
                                    // icon={
                                    //     <svg
                                    //         xmlns="http://www.w3.org/2000/svg"
                                    //         width="18"
                                    //         height="19"
                                    //         viewBox="0 0 18 19"
                                    //         fill="none"
                                    //     >
                                    //         <path
                                    //             d="M13.2851 5.92918H4.71507V3.07251H13.2851V5.92918ZM13.2851 9.85709C13.4874 9.85709 13.6571 9.78853 13.7943 9.65141C13.9314 9.51429 13.9997 9.3448 13.9992 9.14293C13.9992 8.94058 13.9307 8.77085 13.7936 8.63373C13.6564 8.49661 13.4869 8.42828 13.2851 8.42876C13.0827 8.42876 12.913 8.49732 12.7759 8.63444C12.6387 8.77156 12.5704 8.94106 12.5709 9.14293C12.5709 9.34527 12.6395 9.51501 12.7766 9.65213C12.9137 9.78925 13.0832 9.85757 13.2851 9.85709ZM11.8567 14.4992V11.6425H6.1434V14.4992H11.8567ZM13.2851 15.9275H4.71507V13.0708H1.8584V8.78584C1.8584 8.1788 2.0667 7.66984 2.48329 7.25896C2.89989 6.84807 3.40576 6.64287 4.0009 6.64334H13.9992C14.6063 6.64334 15.1152 6.84878 15.5261 7.25967C15.937 7.67055 16.1422 8.17928 16.1417 8.78584V13.0708H13.2851V15.9275Z"
                                    //             fill="#3968ED"
                                    //         />
                                    //     </svg>
                                    // }
                                    onClick={generatePdf}
                                    children={"Print"}
                                />
                                <button className="hideOnPrint" onClick={generatePdf}>
                                    download as pdf
                                </button>
                            </div>



                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default AddRoomBill;
