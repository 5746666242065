import { useNavigate, useParams } from "react-router-dom";
import BookingStatusUpdate from "../CustomComponents/BookingStatusUpdate";
import "./Folio.css";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import { useEffect, useRef, useState } from "react";
import DashboardFilter from "../Dashboard/DashboardFiltersComponent/DashboardFilter";
import NoDataFound from "../CustomComponents/NoDataFound";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  WithoutBgButtonBlue,
} from "../UI/Buttons";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Icon,
} from "@shopify/polaris";
import { CancelMajor } from "@shopify/polaris-icons";
import { useSelector } from "react-redux";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import HandleNaN from "../CustomComponents/HandleNaN/HandleNaN";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import FolioTopBarandNavigation from "./FolioTopBarandNavigation";
import Loader from "../UI/Loader/Loader";

const FolioLog = () => {
  const navigate = useNavigate();
  const clickOutSideRef = useRef(null);
  const [editBillTo, setEditBillTo] = useState(false);
  const [showFolioFeature, setShowFolioFeature] = useState(false);
  const [createNewFolio, setCreateNewFolio] = useState(false);
  const [addGuestPopup, setAddGuestPopup] = useState(false);
  const [addChargesPopup, setAddChargesPopup] = useState(false);

  const [chargesList, setChargesList] = useState([]);
  const [settlements, setSettlements] = useState([]);
  const [folioSummery, setFolioSummery] = useState([]);
  const [folioData, setFolioData] = useState([]);
  const [folioBookings, setFolioBookings] = useState([]);
  const [roomDetails, setRoomDetails] = useState([]);

  const [guest, setGuest] = useState([]);
  const { folioId, folioUniqueId } = useParams();

  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(true);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const folioFilterOptions = [
    {
      value: "today",
      label: "Today",
    },
    {
      value: "mtd",
      label: "MTD",
    },
    {
      value: "qtd",
      label: "QTD",
    },
    {
      value: "ytd",
      label: "YTD",
    },
    {
      value: "future 1 month",
      label: "Future 1 Month",
    },
    {
      value: "future 3 months",
      label: "Future 3 Months",
    },
  ];

  const proofOptions = [
    { label: "Select option" },
    { label: "Aadhar Card", value: "aadhar" },
    { label: "PAN Card", value: "pan" },
    { label: "Passport", value: "passport" },
    { label: "Driving License", value: "driving_license" },
    // { label: "Other", value: "other" },
  ];

  const gstTypeOptions = [
    { label: "select Option", value: "" },
    { label: "Inclusive", value: "inclusive" },
    { label: "Exclusive", value: "exclusive" },
  ];

  const gstOptions = [
    { label: "Select Option", value: "" },
    { label: "8%", value: "8" },
    { label: "12%", value: "12" },
    { label: "15%", value: "15" },
  ];

  const [addChargesData, setAddChargesData] = useState({
    charge_type: "",
    description: "",
    rate: "",
    quantity: "",
    net_total: "",
    subtotal: "",
    discount_amount: "",
    gst_type: "inclusive",
    discount_rate: "",
    tax_rate: "",
    tax_amount: "",
  });

  const [selectedDateFilterValue, setSelectedDateFilterValue] = useState("MTD");
  const [dayTypeFilter, setDayTypeFilter] = useState("");

  const handleSelect = (label, value) => {
    setSelectedDateFilterValue(label);
    setDayTypeFilter(value);
  };

  useEffect(() => {
    const rate = parseFloat(addChargesData.rate);
    const quantity = parseFloat(addChargesData.quantity);
    const discount_rate = parseFloat(addChargesData.discount_rate);
    const tax_rate = parseFloat(addChargesData.tax_rate);

    let subtotal = rate * quantity;

    const discountAmount = (subtotal * discount_rate) / 100;
    const priceAfterDiscount = subtotal - discountAmount;

    let gstAmount = 0;

    if (addChargesData.gst_type === "exclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / 100;
    } else if (addChargesData.gst_type === "inclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / (100 + tax_rate);
    }

    let totalAmount = priceAfterDiscount + gstAmount;

    setAddChargesData({
      ...addChargesData,
      subtotal: subtotal.toFixed(2),
      net_total: totalAmount.toFixed(2),
      discount_amount: discountAmount.toFixed(2),
      tax_amount: gstAmount.toFixed(2),
    });
  }, [
    addChargesData.rate,
    addChargesData.quantity,
    addChargesData.discount_rate,
    addChargesData.gst_type,
    addChargesData.tax_rate,
    addChargesData.tax_amount,
  ]);

  const setAddChargesHandleChange = (event) => {
    setAddChargesData({
      ...addChargesData,
      [event.target.name]: event.target.value,
    });
  };

  const fetchFolioInfo = async (propertyId, selectedFolioId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL
        }/api/v1/folios/${folioId}/${propertyId}?folio_id=${selectedFolioId ? selectedFolioId : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setFolioData(data?.data);
      setChargesList(data?.data?.charges);
      setSettlements(data?.data?.payments);
      setFolioSummery(data?.data?.folio_summary);
      setGuest(data?.data?.guest);
      setRoomDetails(data?.data?.roomDetails);
      setFolioBookings(data?.data?.booking);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id && folioUniqueId) {
      fetchFolioInfo(property_id?.id, folioUniqueId);
    }
  }, [property_id]);

  const resourceName = {
    singular: "chargesList",
    plural: "chargesLists",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(chargesList);

  const formattedDateTime = (date) => {
    return new Date(date).toLocaleString("en-US", {
      dateStyle: "medium",
      timeStyle: "short",
    });
  };


  const chargesRowMarkup = folioData?.folio_logs?.map(
    ({
      id,

      action,
      action_taken_by,
      index,
      created_at,
    }) => {
      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={id}
        >
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={action}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={action_taken_by}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>{formattedDateTime(created_at)}</IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const serviceOptions = [
    { label: "Select Option", value: "" },
    { label: "Room-Charge", value: "Room-Charge" },
    { label: "Food-Charge", value: "Food-Charge" },
    { label: "Housekeeping-Charge ", value: "Housekeeping-Charge" },
    { label: "Miscellaneous-Charge", value: "Miscellaneous-Charge" },
    { label: "Laundry-Charge", value: "Laundry-Charge" },
    // { label: "MISC", value: "misc" },
  ];

  const handleSubmitCreateFolio = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add/folios/charges/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addChargesData, folio_id: folioData.id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddChargesData(false);
          fetchFolioInfo(property_id?.id, folioUniqueId);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleClickOutside = (event) => {
    if (
      clickOutSideRef.current &&
      !clickOutSideRef.current.contains(event.target)
    ) {
      setShowFolioFeature(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [chargeAddedTransactions, setChargeAddedTransactions] = useState({
    discountAmountSum: "",
    subTotalSum: "",
    taxAmountSum: "",
    grandTotalSum: "",
    paidAmountSum: "",
    dueAmountSum: "",
  });

  useEffect(() => {
    const discountAmountSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.discount_amount || 0),
      0
    );
    const subTotalSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.sub_total),
      0
    );
    const taxAmountSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.tax_amount),
      0
    );
    const grandTotalSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.net_total),
      0
    );
    const paidAmountSum = folioData?.payments?.reduce(
      (sum, transaction) => sum + parseFloat(transaction.amount || 0),
      0
    );
    const dueAmountSum = chargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.due_amount || 0),
      0
    );

    const transactions = {
      discountAmountSum,
      subTotalSum,
      taxAmountSum,
      grandTotalSum,
      paidAmountSum,
      dueAmountSum,
    };
    setChargeAddedTransactions(transactions);
  }, [chargesList]);

  const roomNumberArray = [
    {
      label: "Select option",
      value: null,
    },
    ...roomDetails?.map((room) => ({
      label: room?.roomNo?.toString(),
      value: room?.roomNo?.toString(),
    })),
  ];
  const guestsArray = [
    {
      label: "Select option",
      value: null,
    },
    ...roomDetails?.flatMap((room) =>
      room?.guest_details?.map((guest) => ({
        label: guest?.guest_name,
        value: guest?.id?.toString(),
      }))
    ),
  ];

  const dataFromChild = (id) => {
    fetchFolioInfo(property_id?.id, id);
    navigate(`/bookings/folio-logs/${folioId}/${id}`);
    // const currentUrl = window.location.href;
    // console.log(currentUrl);
    // const parts = currentUrl.split('/');
    // parts[parts.length - 1] = folioId; // Replace the last part with '38'
    // const newUrl = parts.join('/');
    // console.log(newUrl);
    // // window.location.replace(newUrl);
  };
  return (
    <>
      <FolioTopBarandNavigation
        guestsArray={guestsArray}
        roomNumberArray={roomNumberArray}
        // handleSelect={handleSelect}
        // fetchFolioInfoParent = {fetchFolioInfo(property_id?.id)}
        dataFromChild={dataFromChild}
      />

      {loader ? (
        <Loader />
      ) : (
        <div
          className="charges_main mt-20"
          style={{ marginRight: "20px", marginLeft: "20px" }}
        >
          {folioData?.folio_logs && folioData?.folio_logs.length > 0 ? (
            <>
              <LegacyCard>
                <IndexTable
                  selectable={false}
                  resourceName={resourceName}
                  itemCount={chargesList.length}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: "Action" },
                    { title: "User" },
                    { title: "Date / Time" },
                  ]}
                >
                  {chargesRowMarkup}
                </IndexTable>
              </LegacyCard>
            </>
          ) : (
            <NoDataFoundSmall />
          )}
        </div>
      )}
    </>
  );
};
export default FolioLog;
