import React, { useCallback, useState, useEffect } from "react";
import { Button, Icon, Link, TextField } from "@shopify/polaris";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import logo from "../../assets/images/logo.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BgThemeButton,
  BgThemeButtonDisabled,
  BgThemeButtonFullWidth,
  BgThemeButtonFullWidthDisabled,
  CheckWithoutBgUnderlineBtn,
} from "../UI/Buttons";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { useDispatch, useSelector } from "react-redux";
import { saveLoginToken } from "../../reduxToolkit/Slice/loginTokenSlice";
import {
  checkIsAuthenticatedFalse,
  checkIsAuthenticatedTrue,
} from "../../reduxToolkit/Slice/isAuthenticatedSlice";

import PasswordVerticalComponent from "../CustomComponents/PasswordComponent";

const ForgotPassword = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const useremail = searchParams.get("email");
  const dispatch = useDispatch();
  const [email, setEmail] = useState(useremail);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [emailEntered, setEmailEntered] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);
  const [forgotPasswordPage, setForgotPasswordPage] = useState(true);
  const [validatePassword, setValidatePassword] = useState(false);

  const emailToPasswordHandler = () => {
    if (validateEmailFormat(email)) {
      setIsEmailValid(true);
      setEmailEntered(true);
    } else {
      setIsEmailValid(false);
    }
  };

  const validateEmailFormat = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordMismatchError, setPasswordMismatchError] = useState("");

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      setPasswordMismatchError("Passwords do not match.");
    } else {
      setPasswordsMatch(true);
      setPasswordMismatchError("");
    }
  };
  // saurabhunmetered%40gmail.com

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    if (password !== newConfirmPassword) {
      setPasswordsMatch(false);
      setPasswordMismatchError("Passwords do not match.");
    } else {
      setPasswordsMatch(true);
      setPasswordMismatchError("");
    }
  };

  const submitUserData = (e) => {
    localStorage.removeItem("selectedProperty");
    e.preventDefault();
    if (password === confirmPassword) {
      // Passwords match
      setPasswordsMatch(true);
    }
    const credentials = {
      email: email,
      password: password,
      password_confirmation: confirmPassword,
      token: token,
    };
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/password-reset`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setEmail("");
          setPassword("");
          navigate("/login");
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
        showErrorToast(error);
      });
  };

  // useEffect(() => {
  //   localStorage.removeItem("selectedProperty");
  // }, []);

  const changeEmailHandler = useCallback(() => setEmailEntered(false), []);

  // if (authenticated) {
  //   navigate("/dashboard");
  //   return;
  // }

  // if (!token) {
  //   navigate("/login");
  //   return;
  // }

  return (
    <>
      <div className="login-screen-container">
        <div className="login-full-container">
          <form onSubmit={(e) => submitUserData(e)}>
            <div>
              <img className="login-logo" src={logo} alt="logo" />
            </div>
            <div className="login-text-below-logo">
              <h3>Reset Password</h3>
              <p>Continue to RevCatalyst</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <VerticalInputFieldCustom
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="textfield"
                focused={false}
                autoComplete="off"
                required={true}
                disabled={true}
                titleName={"Email"}
              />
              <PasswordVerticalComponent
                name="password"
                onChange={handlePasswordChange}
                value={password}
                className="textfield"
                focused={false}
                autoComplete="off"
                required={true}
                titleName={"Password"}
              />
              <PasswordVerticalComponent
                name="confirmpassword"
                onChange={handleConfirmPasswordChange}
                value={confirmPassword}
                className="textfield"
                focused={false}
                autoComplete="off"
                required={true}
                titleName={"Confirm Password"}
              />
              {!isEmailValid && (
                <div
                  style={{
                    color: "red",
                    fontSize: "14px",
                    marginTop: "5px",
                  }}
                >
                  Invalid email format
                </div>
              )}
            </div>

            <div className="continue-with-email-button">
              {passwordsMatch ? (
                <BgThemeButtonFullWidth
                  style={{ width: "100%" }}
                  onClick={submitUserData}
                  children={"Reset Password"}
                />
              ) : (
                <BgThemeButtonFullWidthDisabled
                  style={{ width: "100%" }}
                  onClick={submitUserData}
                  children={"Reset Password"}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
