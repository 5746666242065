import "./ChefDashBoardNewUi.css";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchInput from "../../CustomComponents/SearchSuggessionInput.jsx/SearchInput";
import { showSuccessToast, showErrorToast } from "../../../assets/toastUtils";
import NoDataFound from "../../CustomComponents/NoDataFound";
import { Link, useLocation } from "react-router-dom";
import VerticalTextAreaCustom from "../../CustomComponents/VerticalTextAreaCustom";
import { Button } from "@shopify/polaris";
const KotViewChefDashBoard = () => {
    const userToken = JSON.parse(localStorage.getItem("userToken"));

    const property_id = useSelector((state) => state.property.value);

    const navigate = useNavigate();

    const fetchAsignRooms = async (propertyId) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/kitchen/kots/${propertyId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();

            setFetchedData(data.kot);
            //   setLoader(false);
        } catch (error) {
            //   setLoader(false);
            console.error(error);
        }
    };

    useEffect(() => {
        if (property_id) {
            fetchAsignRooms(property_id?.id);
        }
    }, [property_id]);

    // const changeStatus = async (status, kotId, propertyId) => {
    //     try {
    //         const response = await fetch(
    //             // https://dev.revcatalyst.in/api/v1/kitchen/kots/update/status/new_kot/4/10
    //             `${process.env.REACT_APP_BASE_URL}/api/v1/kitchen/kots/update/status/${status}/${kotId}/${propertyId}`,
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Authorization: `Bearer ${userToken}`,
    //                 },
    //             }
    //         );
    //         const data = await response.json();
    //         if (data.success) {
    //             showSuccessToast(data.message);
    //             fetchAsignRooms(property_id?.id);
    //         } else {
    //             showErrorToast(data.message);
    //         }
    //         // setFetchedData(data.kot);
    //         //   setLoader(false);
    //     } catch (error) {
    //         //   setLoader(false);
    //         console.error(error);
    //     }
    // };

    const changeStatus = (kot_id, property_id, status) => {
        setLoader(true);

        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v1/kitchen/kots/update/status/${kot_id}/${property_id}`,
            {
                method: "POST",
                body: JSON.stringify({
                    status: status,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    showSuccessToast(data.message);
                } else {
                    showSuccessToast(data.message);
                    setLoader(false);
                }
            })
            .catch((error) => {
                setLoader(false);
                console.error("Error:", error);
                showErrorToast("An error occurred while processing your request.");
            });
        // setShowBookingDetails(false);
    };

    const [fetchedData, setFetchedData] = useState([]);

    const sortedData = fetchedData
        .filter((item) => item.status !== "Cancelled")
        .sort((a, b) => {
            const order = { preparing: 1, new_kot: 2, pending: 3, prepared: 4 };

            const statusA = a.status;
            const statusB = b.status;

            return order[statusA] - order[statusB];
        });
    const tabs = [
        {
            title: "Table View",
            link: "table-view",
        },
        {
            title: "Room View",
            link: "room-view",
        },
    ];
    const location = useLocation();
    const activeTab = tabs.findIndex((tab) =>
        location.pathname.includes(tab.link)
    );

    // .............................................Cancel KOt................................
    const [cancelKot, setCancelKot] = useState(false);
    const [cancelReason, setCancelReason] = useState("");
    const [loader, setLoader] = useState(true);

    const cancelKotHandle = (kotID) => {
        setLoader(true);
        
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v1/restaurant/kot/cancel/${property_id?.id}`,
            {
                method: "POST",
                body: JSON.stringify({
                    kotID: kotID,
                    reason: cancelReason
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    showSuccessToast(data.message);
                    setCancelKot(false);
                    fetchAsignRooms(property_id?.id);
                } else {
                    showSuccessToast(data.message);
                    setLoader(false);
                }
            })
            .catch((error) => {
                setLoader(false);
                console.error("Error:", error);
                showErrorToast("An error occurred while processing your request.");
            });
        // setShowBookingDetails(false);
    };
    // useEffect(() => {
    //     if (kotID) {
    //         cancelKotHandle();
    //     }
    // }, [cancelKot]);


    return (
        <div className="main_container">
            <div className="custom_tab_container" style={{ marginBottom: "20px" }}>
                <div className="custom_tab_header">
                    {tabs.map((tab, index) => (
                        // <h3
                        //   key={index}
                        //   className={`tab_item ${index === activeTab ? "active" : ""
                        //     }`}
                        //   onClick={() => handleTabClick(tab.link, index)}
                        // >
                        //   {tab.title}
                        // </h3>
                        <Link
                            to={`/chef-dashboard/${tab.link}`}
                            className={`tab_item ${index === activeTab ? "active" : ""
                                }`}
                            key={index}
                        >
                            {tab.title}
                        </Link>
                    ))}
                </div>
            </div>
            <div className="flex flex_gap_10" style={{ margin: '20px 0px', fontSize: '15px', fontWeight: '500' }}>
                <div className="flex flex_gap_10 align_items_center">
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="4" cy="4" r="4" fill="#BD941B" />
                    </svg>
                    Under Preparation</div>
                <div className="flex flex_gap_10 align_items_center"><svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="4" fill="#3968ED" />
                </svg>Not Prepared</div>
            </div>

            <div className="chefDashboardCardsContainer">
                {fetchedData && fetchedData.length > 0 ? (
                    sortedData.map((item, index) => (
                        <div className="chefDashboardCards" key={index}>
                            <div
                                className={`chefDashboardCardHeader ${item.order_status === "prepared"
                                    ? "addBackgroundColorToHeaderOne"
                                    : item.order_status === "preparing"
                                        ? "addBackgroundColorToHeaderTwo"
                                        : ""
                                    }`}
                            >
                                <div className="chef_dashboard_items_list">
                                    <h5 className="chefDashboardKotType">{item.room_no}</h5>
                                    <h6 className="chefDashboardKotTime">Room No.</h6>
                                </div>
                                <div className="chef_dashboard_items_list chef_dashboard_items_list_mid">
                                    <h5 className="chefDashboardKotType">{item.order_no}</h5>
                                    <h6 className="chefDashboardKotTime">KOT No.</h6>
                                </div>
                                <div className="chef_dashboard_items_list">
                                    <h5 className="chefDashboardKotType">{item.time}</h5>
                                    {/* <h6 className="chefDashboardKotTime">PM</h6> */}
                                </div>
                                {/* <h4 className="chefDashboardKotType">{item.kot_type}</h4>
                                <h5 className="chefDashboardKotTime">{item.time}</h5> */}
                            </div>
                            {item?.items?.map((category, categoryIndex) => (
                                <div key={categoryIndex}>
                                    <div className="chefDashboardCardCategory">
                                        {category?.categoryName}
                                    </div>
                                    {category?.items?.map((product, productIndex) => (
                                        <div
                                            key={productIndex}
                                            className="chefDashboardCardProduct"
                                        >
                                            <p className="chefDashboardCardProductName">
                                                {product.quantity} {product.name}
                                            </p>
                                        </div>
                                    ))}

                                </div>
                            ))}
                            {/* <div className="chefDashboardKotContent">
                                <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amet conse ctetur. Est sed quis lectus bibendum semper mattis sit.</p>
                                <div className="flex chefDashboardKotContentEditButton" style={{ justifyContent: 'end' }}>
                                    <button onClick={handleButtonClick}>Edit</button>
                                </div>

                            </div> */}

                            <div className="chefDashboardCardFooter">
                                {item.order_status === "preparing" ? (
                                    <button
                                        onClick={() => {
                                            setCancelKot(true);
                                        }}
                                        className="chefDashBoardCancelButton chefDashboardCardButton"
                                    >
                                        Cancel
                                    </button>
                                ) : ""}
                            </div>
                            {/* <div className="chefDashboardCardFooter">
                                <button
                                    onClick={() => {
                                        setCancelKot(true);
                                        // cancelKotHandle(item.id);
                                    }}
                                    className="chefDashBoardCancelButton chefDashboardCardButton"
                                >
                                    Cancel
                                </button>
                            </div> */}
                            {cancelKot && (
                                <>
                                    <div className="bg-backdrop">
                                        <section className="room-type-model-container" style={{ width: '500px' }}>
                                            <h4>Are You Sure?</h4>
                                            <p style={{ marginBottom: "0" }}>
                                                Do You Want To Cancel KOT?
                                            </p>
                                            <>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                        width: "100%",
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    <div className="reason-input-button">Enter Reason</div>
                                                    <div className="reason-input-button">
                                                        {/* {reason?.reason?.trim()?.length}/{wordLimit} */}
                                                    </div>
                                                </div>
                                                <div style={{ width: "100%", marginBottom: "10px" }}>
                                                    <VerticalTextAreaCustom
                                                        name={"cancelReason"}
                                                        className="textfield"
                                                        focused={false}
                                                        type="text"
                                                        value={cancelReason}
                                                        autoComplete="off"
                                                        onChange={(e) => setCancelReason(e.target.value)}
                                                        cols={12}
                                                        rows={4}
                                                    />
                                                </div>
                                            </>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                }}
                                            >
                                                <Button
                                                    onClick={() => setCancelKot(!cancelKot)}
                                                >
                                                    Discard
                                                </Button>
                                                <Button
                                                    onClick={() => cancelKotHandle(item?.id)}
                                                    destructive
                                                    type={'Button'}
                                                >
                                                    Yes, Cancel
                                                </Button>
                                            </div>
                                        </section>
                                    </div>
                                </>
                            )}
                        </div>
                    ))
                ) : (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            <NoDataFound />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default KotViewChefDashBoard;