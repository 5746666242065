import { useState, useEffect } from "react";
import VerticalInputSelectCustomCopy from "../Components/CustomComponents/VerticalInputSelectCustomCopy";
import GoBackButtonCustom from "../Components/GoBackButtonCustom/GoBackButtonCustom";
import { BgThemeButton, WithoutBgButtonBlue } from "../Components/UI/Buttons";
import VerticalInputFieldCustom from "../Components/CustomComponents/VerticalInputFieldCustom";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchComponent from "../Components/CustomComponents/SearchComponent/SearchComponent";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import { formatIndianCurrency, globalErrorHandler } from "../utils/utils";
import Loader from "../Components/UI/Loader/Loader";
const EditPurchase = () => {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);
  const [suppliers, setSuppliers] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const { id } = useParams();
  const [outerIssueData, setOuterIssueData] = useState({
    supplier_id: "",
    purches_date: "",
    invoice_no: "",
    sub_total: "",
    due_amount: "",
    paid_amount: 0,
    grand_total: "",
    payment_status: "",
  });
  const [issueItems, setIssueItems] = useState({
    item_name: "",
    item_category: "",
    unit: "",
    item_type: "",
    price_per_unit: "",
    item_qty: "",
    discount: "",
    tax_rate: "",
    total_amt: "",
    item_price: "",
    subtotal: "",

    grand_total: "",

    // item_price: "",
    // item_weight: "",
    // weight_unit: "",
    // price_pre_unit: "",
    // item_qty: "1",
    // gst: "",
    // discount: "",
    // total_amount: "",
    // category_id: "",
    // item_id: "",
  });

  const getOneData = async (id) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/purchased/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        supplier_id: data.Purchase.supplier_id,
        purchase_date: data.Purchase.purchase_date,
        invoice_no: data.Purchase.invoice_no,
        details: data.Purchase.purchase_items,
        sub_total: data.Purchase.sub_total,
        grand_total: data.Purchase.grand_total,
        due_amount: data.Purchase.due_amount,
        paid_amount: data.Purchase.paid_amount,
        payment_status: data.Purchase.payment_status,
      };
      setOuterIssueData(updatedData);
      // setStocks(updatedData);
      const itemDetailsArray = data.Purchase.purchase_items.map(
        (item, index) => ({
          item_name: item.item_name,
          item_price: item.item_price,
          item_weight: item.item_weight,
          weight_unit: item.weight_unit,
          price_per_unit: item.item_rate,
          item_qty: item.item_qty,
          gst: item.gst,
          tax_rate: item.gst,
          discount: item.discount,
          total_amount: item.total_amount,
          item_id: item.item_id,
          unit: item.unit,
          unit_name: item.unit_name,
          item_category: item.item_category,
          total_amount: item.total_amount,
          total_amt: item.total_amount,
          tax_amount: item.tax_amount,
          sub_total: item.sub_total,
          discount_amount: item.discount_amount,
        })
      );
      setLoader(false);
      setIssueItemsTableData(itemDetailsArray);
    } catch (error) {
      console.error(error);
    }
  };

  const [issueItemsTableData, setIssueItemsTableData] = useState([]);
  const [items, setItems] = useState([]);

  const departmentNameOptions = [
    { label: "Select Option" },
    { label: "Paid", value: "paid" },
    { label: "Unpaid", value: "unpaid" },
  ];

  const itemCategoryOptions = [
    { label: "Select Option" },
    { label: "Fruits and Vegetables", value: "fruits_and_vegetables" },
    { label: "Dairy Products", value: "dairy_products" },
    { label: "Grains and Cereals", value: "grains_and_cereals" },
    { label: "Meat and Poultry", value: "meat_and_poultry" },
    { label: "Seafood", value: "seafood" },
    { label: "Beverages", value: "beverages" },
    { label: "Snacks", value: "snacks" },
    { label: "Bakery", value: "bakery" },
    { label: "Frozen Foods", value: "frozen_foods" },
    { label: "Condiments and Sauces", value: "condiments_and_sauces" },
    { label: "Canned Goods", value: "canned_goods" },
    { label: "Personal Care", value: "personal_care" },
  ];

  const getSelectedItem = (item, index) => {
    setIssueItems({
      ...issueItems,
      item_name: item.item_name,
      item_id: item.id,
      item_category: item.category_name,
      unit_id: item.unit_id,
      unit_name: item.unit_name,
      tax_rate: item.tax_slab,
      total_amt: "",
      item_qty: "1",
    });
  };

  const itemNameOptions = [
    { label: "Select Option" },
    { label: "Apple", value: "apple" },
    { label: "Orange", value: "orange" },
    { label: "Banana", value: "banana" },
    { label: "Tomato", value: "tomato" },
    { label: "Potato", value: "potato" },
    { label: "Onion", value: "onion" },
    { label: "Carrot", value: "carrot" },
    { label: "Lettuce", value: "lettuce" },
    { label: "Cucumber", value: "cucumber" },
    { label: "Broccoli", value: "broccoli" },
    { label: "Milk", value: "milk" },
    { label: "Bread", value: "bread" },
    { label: "Eggs", value: "eggs" },
    { label: "Cheese", value: "cheese" },
    { label: "Chicken", value: "chicken" },
    { label: "Fish", value: "fish" },
    { label: "Rice", value: "rice" },
    { label: "Pasta", value: "pasta" },
    { label: "Flour", value: "flour" },
    // Add more options as needed
  ];
  const itemTypeOptions = [
    { label: "Select Option" },
    { label: "Rakesh", value: "Rakesh" },
    { label: "Rakesh", value: "Rakesh" },
    { label: "Rakesh", value: "Rakesh" },
    { label: "Rakesh", value: "Rakesh" },
    { label: "Rakesh", value: "Rakesh" },
    { label: "Rakesh", value: "Rakesh" },
    { label: "Rakesh", value: "Rakesh" },
  ];

  const unitOptions = [
    { label: "Select" },
    { label: "Piece(s)", value: "pieces" },
    { label: "Kilogram(s)", value: "kg" },
    { label: "Gram(s)", value: "g" },
    { label: "Liter(s)", value: "liters" },
    { label: "Milliliter(s)", value: "ml" },
    { label: "Ounce(s)", value: "oz" },
    { label: "Pound(s)", value: "lbs" },
    { label: "Dozen", value: "dozen" },
    { label: "Package(s)", value: "package" },
    { label: "Carton(s)", value: "carton" },
    { label: "Bottle(s)", value: "bottle" },
    { label: "Can(s)", value: "can" },
    // Add more options as needed
  ];
  const bundleOptions = [
    { label: "Select" },
    { label: "Single", value: "single" },
    { label: "Pack", value: "pack" },
    { label: "Box", value: "box" },
    { label: "Bag", value: "bag" },
    { label: "Bundle", value: "bundle" },
    { label: "Case", value: "case" },
    { label: "Set", value: "set" },
    { label: "Carton", value: "carton" },
    { label: "Jar", value: "jar" },
    { label: "Container", value: "container" },
    { label: "Bundle", value: "bundle" },
    { label: "Dozen", value: "dozen" },
    // Add more options as needed
  ];
  const fetchSupplier = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/suppliers/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setSuppliers(data?.supplier?.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchItems = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/items/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setItems(data?.Item);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  useEffect(() => {
    if (property_id) {
      getOneData(id);
      fetchSupplier(property_id?.id);
      fetchItems(property_id?.id);
    }
  }, [property_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // if value 0
    if (value === "0" && (name === "price_per_unit" || name === "item_qty")) {
      showErrorToast("Value can't be 0");
      return;
    }

    if (name === "discount") {
      if (value > 100) {
        showErrorToast("Discount cannot be greater than 100");
        return;
      }
    }

    if (name === "tax_rate") {
      if (value > 100) {
        showErrorToast(" Tax rate cannot be greater than 100");
        return;
      }
    }

    const updatedIssueItems = { ...issueItems, [name]: value };

    if (
      name === "price_per_unit" ||
      name === "item_qty" ||
      name === "discount" ||
      name === "tax_rate" ||
      name === "item_name"
    ) {
      const price = updatedIssueItems.price_per_unit;

      const quantity = updatedIssueItems.item_qty
        ? updatedIssueItems.item_qty
        : 1;

      if (!quantity) {
        updatedIssueItems.item_qty = 1;
      }
      const discount = (price * quantity * updatedIssueItems.discount) / 100;
      console.log(discount);

      const taxRate = updatedIssueItems.tax_rate;
      const subtotal = price * quantity - discount;
      const totalAmount = subtotal + (subtotal * taxRate) / 100;
      const grandTotal = totalAmount;
      updatedIssueItems.total_amt = totalAmount;
      updatedIssueItems.total_amount = totalAmount;

      updatedIssueItems.sub_total = subtotal;
      updatedIssueItems.subtotal = subtotal;
      updatedIssueItems.grand_total = grandTotal;
      updatedIssueItems.discount_amount = discount;
      updatedIssueItems.item_price = price;
      updatedIssueItems.gst = taxRate;

      updatedIssueItems.tax_amount = (subtotal * taxRate) / 100;
    }

    setIssueItems(updatedIssueItems);
  };

  const handleAddItem = () => {
    const itemAlreadyExists = issueItemsTableData.some(
      (item) => item.item_name === issueItems.item_name
    );

    const errors = globalErrorHandler(issueItems, [
      "item_name",
      "item_category",
      "unit_name",
      "price_per_unit",
      "item_qty",
      "tax_rate",
      "total_amt",
    ]);

    console.log("errors", errors);
    setErrors(errors.errors);
    setTimeout(() => {
      setErrors({});
      return clearTimeout();
    }, 3000);

    if (errors.success)
      return showErrorToast("Please fill out all required fields.");

    console.log("itemAlreadyExists", itemAlreadyExists);
    if (itemAlreadyExists) {
      showErrorToast("Item already exists");
      return;
    }

    // if (
    //   !issueItems.item_name ||
    //   !issueItems.item_category ||
    //   !issueItems.unit_name ||
    //   !issueItems.price_per_unit ||
    //   !issueItems.item_qty ||
    //   !issueItems.tax_rate ||
    //   !issueItems.total_amt
    // ) {
    //   alert("Please fill out all required fields.");
    //   return;
    // }

    const newItem = { ...issueItems };
    setIssueItemsTableData([...issueItemsTableData, newItem]);
    setIssueItems({
      item_name: "",
      item_category: "",
      unit: "",
      unit_name: "",
      item_type: "",
      price_per_unit: "",
      item_qty: "",
      discount: "",
      tax_rate: "",
      total_amt: "",
    });
  };

  const handleDeleteItem = (index) => {
    const newData = [...issueItemsTableData];
    newData.splice(index, 1);
    setIssueItemsTableData(newData);
  };

  const totalItems = issueItemsTableData.length;
  const totalQty = issueItemsTableData.reduce(
    (acc, item) => acc + parseInt(item.item_qty),
    0
  );
  const totalAmt = issueItemsTableData.reduce(
    (acc, item) => acc + parseFloat(item.total_amt),
    0
  );

  const subtotal = issueItemsTableData.reduce(
    (acc, item) => Number(acc) + Number(item.sub_total),
    0
  );
  console.log("subtotal", issueItemsTableData);

  const grandTotal = issueItemsTableData.reduce(
    (acc, item) => acc + parseFloat(item.total_amount),
    0
  );

  const discountAmount = issueItemsTableData.reduce(
    (acc, item) => acc + parseFloat(item.discount_amount),
    0
  );

  const taxAmount = issueItemsTableData.reduce(
    (acc, item) => +acc + +item.tax_amount,
    0
  );

  console.log("taxAmount", issueItemsTableData);

  const supplierNameOptions = [
    { label: "Select Option" },
    ...suppliers.map((supplier) => {
      return { label: supplier.supplier_name, value: supplier.id };
    }),
  ];

  const handlePostData = async () => {
    const sendData = {
      ...outerIssueData,
      details: issueItemsTableData,
      sub_total: subtotal,
      grand_total: grandTotal,
      discount_amount: discountAmount,
      tax_amount: taxAmount,
      paid_amount: outerIssueData.payment_status === "paid" ? grandTotal : 0,
      balance_amount: outerIssueData.payment_status === "paid" ? 0 : grandTotal,
      due_amount: outerIssueData.payment_status === "paid" ? 0 : grandTotal,
    };

    console.log(outerIssueData);

    if (outerIssueData.invoice_no === "") {
      showErrorToast(`Please ENTER INVOICE NO.`);
      return;
    }
    if (outerIssueData.supplier_id === "") {
      showErrorToast(`Please select supplier`);
      return;
    }
    if (outerIssueData.purches_date === "") {
      showErrorToast(`Please select purchase date`);
      return;
    }
    if (outerIssueData.invoice_no === "") {
      showErrorToast(`Please select purchase date`);
      return;
    }
    if (issueItemsTableData?.length === 0) {
      showErrorToast("Please add the item");
      return;
    }

    // return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/update/purchased/${id}/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(sendData),
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        navigate("/hotelier/purchase");
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      {loader ? (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="main_container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 0",
            }}
          >
            <div className="room-type-heading">
              <GoBackButtonCustom
                onClick={() => navigate("/hotelier/purchase")}
                buttonNextText={"Edit Purchase"}
              />
            </div>
            <div></div>
          </div>
          <div
            style={{
              background: "rgba(255, 255, 255, 1)",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <div
              title="Reservation Insights"
              style={{
                paddingBottom: "10px",
                borderBottom: "1px solid #ddd",
                marginBottom: "10px",
              }}
            >
              <div className="flex flex_gap_10">
                <div style={{ width: "150px" }}>
                  <VerticalInputFieldCustom
                    titleName={"Bill/Inv No."}
                    name={"invoice_no"}
                    value={outerIssueData.invoice_no}
                    onChange={(e) => {
                      setOuterIssueData({
                        ...outerIssueData,
                        invoice_no: e.target.value,
                      });
                    }}
                    error={errors?.invoice_no ? true : false}
                  />
                </div>
                <div style={{ width: "150px" }}>
                  <VerticalInputFieldCustom
                    titleName={"Date"}
                    type={"Date"}
                    name={"purchase_date"}
                    value={outerIssueData.purchase_date}
                    onChange={(e) => {
                      setOuterIssueData({
                        ...outerIssueData,
                        purchase_date: e.target.value,
                      });
                    }}
                    error={errors?.purchase_date ? true : false}
                  />
                </div>
                <div style={{ width: "150px" }}>
                  <VerticalInputSelectCustomCopy
                    titleName={"Supplier"}
                    value={outerIssueData.supplier_id}
                    defaultValue={"arrival"}
                    options={supplierNameOptions}
                    onChange={(e) => {
                      setOuterIssueData({
                        ...outerIssueData,
                        supplier_id: e.target.value,
                      });
                    }}
                    error={errors?.supplier_id ? true : false}
                  />
                </div>
                <div style={{ width: "150px" }}>
                  <VerticalInputSelectCustomCopy
                    titleName={"Payment Status"}
                    value={outerIssueData.payment_status}
                    defaultValue={"arrival"}
                    options={departmentNameOptions}
                    onChange={(e) => {
                      setOuterIssueData({
                        ...outerIssueData,
                        payment_status: e.target.value,
                      });
                    }}
                    error={errors?.payment_status ? true : false}
                  />
                </div>
              </div>
            </div>
            <div title="Reservation Insights">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom: "10px",
                }}
              >
                <div className="flex flex_gap_10">
                  <div style={{ width: "150px" }}>
                    <SearchComponent
                      required={true}
                      titleName="Item Name"
                      searchArray={items}
                      keyName={"item_name"}
                      selectedValue={issueItems?.item_name}
                      // searchFilterBy="item_name"
                      getSelectedItem={getSelectedItem}
                      error={errors?.item_name ? true : false}
                    />
                  </div>
                  {/* <div style={{ width: "150px" }}>
                <VerticalInputSelectCustomCopy
                  titleName={"Item Name"}
                  options={itemNameOptions}
                  required={true}
                  value={issueItems.item_name}
                  onChange={(e) => handleChange(e)}
                  name="item_name"
                />
              </div> */}
                  <div style={{ width: "150px" }}>
                    <VerticalInputFieldCustom
                      disabled={true}
                      titleName={"Item Category"}
                      required={true}
                      options={itemCategoryOptions}
                      value={issueItems.item_category}
                      onChange={(e) => handleChange(e)}
                      name="item_category"
                      error={errors?.item_category ? true : false}
                    />
                  </div>

                  <div style={{ width: "100px" }}>
                    <VerticalInputFieldCustom
                      disabled={true}
                      required={true}
                      titleName={"Unit"}
                      options={unitOptions}
                      value={issueItems.unit_name}
                      onChange={(e) => handleChange(e)}
                      name="unit_name"
                      error={errors?.unit_name ? true : false}
                    />
                  </div>
                  <div style={{ width: "150px" }}>
                    <VerticalInputFieldCustom
                      disabled={issueItems.item_id ? false : true}
                      required={true}
                      type="number"
                      value={issueItems.price_per_unit}
                      onChange={(e) => handleChange(e)}
                      name="price_per_unit"
                      titleName="Price Per Unit"
                      error={errors?.price_per_unit ? true : false}
                    />
                  </div>
                  <div style={{ width: "150px" }}>
                    <VerticalInputFieldCustom
                      disabled={issueItems.item_id ? false : true}
                      type="number"
                      style={{ width: "100%" }}
                      required={true}
                      value={issueItems.item_qty}
                      onChange={(e) => handleChange(e)}
                      name="item_qty"
                      titleName="Quantity"
                      error={errors?.item_qty ? true : false}
                    />
                  </div>
                  <div style={{ width: "100px" }}>
                    <VerticalInputFieldCustom
                      disabled={issueItems.item_id ? false : true}
                      type="number"
                      style={{ width: "100%" }}
                      required={true}
                      value={issueItems.discount}
                      onChange={(e) => handleChange(e)}
                      name="discount"
                      titleName="Discount (%)"
                      error={errors?.discount ? true : false}
                    />
                  </div>
                  {/* <div className="create_booking_editable_input_container">
                <label>item_qty</label>
                <div
                  className="common_min_input"
                  style={{ marginTop: "4px", background: "#fff" }}
                >
                  <input
                    type="number"
                    style={{ width: "100%" }}
                    required={true}
                    value={issueItems.item_qty}
                    onChange={(e) => handleChange(e)}
                    name="item_qty"
                  ></input>
                </div>
              </div> */}
                  {/* <div className="create_booking_editable_input_container">
                <label>discount %</label>
                <div
                  className="common_min_input"
                  style={{ marginTop: "4px", background: "#fff" }}
                >
                  <input
                    type="number"
                    style={{ width: "100%" }}
                    required={true}
                    value={issueItems.discount}
                    onChange={(e) => handleChange(e)}
                    name="discount"
                  ></input>
                </div>
              </div> */}

                  <div style={{ width: "100px" }}>
                    <VerticalInputFieldCustom
                      disabled={true}
                      type="number"
                      style={{ width: "100%" }}
                      required={true}
                      value={issueItems.tax_rate}
                      onChange={(e) => handleChange(e)}
                      name="tax_rate"
                      titleName="Tax Rate"
                      error={errors?.tax_rate ? true : false}
                    />
                  </div>

                  {/* <div className="create_booking_editable_input_container">
                <label>Tax Rate %</label>
                <div className="common_min_input" style={{ marginTop: "4px" }}>
                  <input
                    type="number"
                    style={{ width: "100%" }}
                    required={true}
                    value={issueItems.tax_rate}
                    onChange={(e) => handleChange(e)}
                    name="tax_rate"
                  ></input>
                </div>
              </div> */}

                  <div style={{ width: "100px" }}>
                    <VerticalInputFieldCustom
                      disabled={true}
                      style={{ width: "100%" }}
                      type="number"
                      value={issueItems.total_amt}
                      required={true}
                      onChange={(e) => handleChange(e)}
                      name="total_amt"
                      titleName="Total Amt."
                      error={errors?.total_amt ? true : false}
                    />
                  </div>
                  {/* <div className="create_booking_editable_input_container">
                <label>Total Amt.</label>
                <div className="common_min_input" style={{ marginTop: "4px" }}>
                  <input
                    style={{ width: "100%" }}
                    type="number"
                    value={issueItems.total_amt}
                    required={true}
                    onChange={(e) => handleChange(e)}
                    name="total_amt"
                  ></input>
                </div>
              </div> */}
                  {/* <Link to={`/communication/createmessage`}> */}
                  <WithoutBgButtonBlue
                    style={{ fontSize: "14px", padding: "0" }}
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_5311_4678)">
                          <path
                            d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                            stroke="#3968ED"
                          />
                          <path
                            d="M10.5868 7H3.41357"
                            stroke="#3968ED"
                            stroke-miterlimit="10"
                          />
                          <path
                            d="M7 3.4132L7 10.5864"
                            stroke="#3968ED"
                            stroke-miterlimit="10"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_5311_4678">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    }
                    children={"Add"}
                    onClick={handleAddItem}
                  />
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>

          {issueItemsTableData?.length > 0 && (
            <>
              <div>
                <table
                  id="issue_items_page_table"
                  style={{
                    backgroundColor: "white",
                    padding: "10px 20px",
                    borderRadius: "10px",
                    fontFamily: "Inter",
                    width: "100%",
                    marginTop: "25px",
                  }}
                >
                  <tr
                    style={{
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                  >
                    <th style={{ padding: "0px" }}>Item Name</th>
                    <th style={{ padding: "0px" }}>Item Category</th>
                    <th style={{ padding: "0px" }}>Unit</th>
                    <th style={{ padding: "0px" }}>Price/Unit</th>
                    <th style={{ padding: "0px" }}>item_qty</th>
                    <th style={{ padding: "0px" }}>Discount</th>
                    <th style={{ padding: "0px" }}>Tax Rate %</th>
                    <th style={{ padding: "0px" }}>Total Amt.</th>
                  </tr>

                  {issueItemsTableData.map((item, index) => (
                    <tr key={index}>
                      <>
                        <td style={{ padding: "10px 0" }}>{item.item_name}</td>
                        <td style={{ padding: "10px 0" }}>
                          {item.item_category}
                        </td>
                        <td style={{ padding: "10px 0" }}>{item.unit_name}</td>
                        <td style={{ padding: "10px 0" }}>
                          {item.price_per_unit}
                        </td>
                        <td style={{ padding: "10px 0" }}>{item.item_qty}</td>
                        <td style={{ padding: "10px 0" }}>
                          {item.discount_amount} ({item.discount}%)
                        </td>
                        <td style={{ padding: "10px 0" }}>{item.tax_rate}</td>
                        <td style={{ padding: "10px 0" }}>{item.total_amt}</td>
                        <td
                          style={{
                            padding: "10px",
                            width: "10px",
                          }}
                        >
                          <div
                            onClick={() => handleDeleteItem(index)}
                            style={{ cursor: "pointer" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.2001 3.2H13.6001C14.0419 3.2 14.4001 3.55817 14.4001 4L14.4001 4.8H1.6001L1.60012 4C1.60012 3.55817 1.95829 3.2 2.40012 3.2H4.80012V1.2C4.80012 0.537258 5.33738 0 6.00012 0H10.0001C10.6629 0 11.2001 0.537259 11.2001 1.2V3.2ZM6.40012 1.6V3.2H9.60012V1.6H6.40012Z"
                                fill="#E03838"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2.40012 6.4H13.6001V14.8C13.6001 15.4627 13.0629 16 12.4001 16H3.60012C2.93738 16 2.40012 15.4627 2.40012 14.8V6.4ZM5.6001 8.8H4.0001V13.6H5.6001V8.8ZM8.8001 8.8H7.2001V13.6H8.8001V8.8ZM10.4001 8.8H12.0001V13.6H10.4001V8.8Z"
                                fill="#E03838"
                              />
                            </svg>
                          </div>
                        </td>
                      </>
                    </tr>
                  ))}
                </table>
              </div>
              <div className="flex justify_content_end align_items_center mt-20 gap_7">
                <div className="flex" style={{ gap: "10px" }}>
                  <div>
                    Subtotal: <strong>{formatIndianCurrency(subtotal)}</strong>
                  </div>
                  <div>
                    Discounted Amt:{" "}
                    <strong>{formatIndianCurrency(discountAmount)}</strong>
                  </div>
                  <div>
                    Tax Amt: <strong>{formatIndianCurrency(taxAmount)}</strong>
                  </div>
                  <div>
                    Total Amt:{" "}
                    <strong>{formatIndianCurrency(grandTotal)}</strong>
                  </div>
                </div>
              </div>
              <div className="mt-10 flex" style={{ justifyContent: "end" }}>
                <BgThemeButton
                  onClick={handlePostData}
                  children={"Update"}
                ></BgThemeButton>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default EditPurchase;
