import React, { useEffect } from "react";
import "./AgentHoldRoomsSliderSideBar.css";
import { BgOutlineThemeButton, BgThemeButton } from "../../UI/Buttons";
import VerticalInputSelectCustomCopy from "../VerticalInputSelectCustomCopy";
import CommunicationCustomDatePicker from "../CommunicationCustomDatePicker";

const AgentHoldRoomsSliderSideBar = ({ isOpen, toggleSidebar }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const dayRestrictions = [
    {
      day: "D-7",
      restrictions: "50%",
    },
    {
      day: "D-5",
      restrictions: "75%",
    },
    {
      day: "D-3",
      restrictions: "0%",
    },
  ];
  const handleOpenDatePickerData = (data) => {
    console.log(data, "dataaa");
  };

  return (
    <>
      <div
        className={`blur_background_AgentHoldRoomsSliderSideBar ${
          isOpen ? "AgentHoldRoomsSliderSideBarOpen" : ""
        }`}
        onClick={toggleSidebar}
      ></div>
      <div
        className={`AgentHoldRoomsSliderSideBarSidebar overflowContainer ${
          isOpen ? "AgentHoldRoomsSliderSideBarOpen" : ""
        }`}
      >
        <div className="flex justify_content_between agentSidebarContentContainer">
          <button className="close-button" onClick={toggleSidebar}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M1 13.1501L13 0.850098"
                stroke="#AAAAAA"
                stroke-width="1.2"
              />
              <path
                d="M13 13.1501L0.999999 0.850147"
                stroke="#AAAAAA"
                stroke-width="1.2"
              />
            </svg>
          </button>
          <h4>Agent Name</h4>
        </div>
        <hr className="agentHoldRoomsSidebarHr" />
        <div className="agentSidebarContentContainer flex flex_gap_10">
          <BgThemeButton children={"Create Booking"} />
          <BgOutlineThemeButton children={"Hold Rooms"} />
          <BgOutlineThemeButton children={"More Options"} />
        </div>
        {/* contract details */}
        <div>
          <div
            className="agentSidebarContentContainer agentHoldRoomsSidebarHeadingSmall flex flex_gap_10"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            Contract Details
          </div>
          <div className="agentSidebarContentContainer flex justify_content_between">
            <div>
              <h6 className="agentHoldRoomsSidebarHeadingSmall"> Start Date</h6>
              <p>01 Apr, 2024</p>
            </div>
            <div>
              <h6 className="agentHoldRoomsSidebarHeadingSmall"> End Date</h6>
              <p>01 Apr, 2024</p>
            </div>
            <div>
              <h6 className="agentHoldRoomsSidebarHeadingSmall">
                {" "}
                Total Hold Rooms
              </h6>
              <p>01 Apr, 2024</p>
            </div>
          </div>
        </div>

        {/* release restrictions */}
        <div>
          <div
            className="agentSidebarContentContainer agentHoldRoomsSidebarHeadingSmall flex flex_gap_10"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            Auto-Release Restriction
          </div>
          <div className="agentSidebarContentContainer ">
            {dayRestrictions.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex justify_content_between"
                  style={{ padding: "5px 0", borderBottom: "1px solid #333" }}
                >
                  <span className="agentHoldRoomsSidebarHeadingSmall">
                    {item.day}
                  </span>
                  <span>{item.restrictions}</span>
                </div>
              );
            })}
          </div>
        </div>

        {/*Contract insight */}
        <div>
          <div className="agentSidebarContentContainer ">
            <div className="agentHoldBookingSliderContractInsight">
              <div className="flex justify_content_between align_center">
                <h5>Contract Insights</h5>
                <div>
                  <VerticalInputSelectCustomCopy
                    style={{ minHeight: "25px" }}
                  />
                </div>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Hold Rooms</span>
                <span>200</span>
              </div>
              <div className="flex justify_content_between align_center">
                <span>Converted </span>
                <span>200</span>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Not Used</span>
                <span>200</span>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Remaining</span>
                <span>200</span>
              </div>
              <div className="flex justify_content_between align_center">
                <span>Converted Booking Amount</span>
                <span>₹ 200</span>
              </div>
            </div>
          </div>
        </div>

        {/*Rooms insight */}

        <div>
          <div className="agentSidebarContentContainer ">
            <div className="agentHoldBookingSliderContractInsight">
              <div className="flex justify_content_between align_center">
                <h5>Room Insights</h5>
                <div
                  className="flex justify_content_between align_center"
                  style={{ width: "65%" }}
                >
                  <div style={{ width: "48%" }}>
                    <div style={{ marginTop: "0.25rem" }}>
                      <CommunicationCustomDatePicker
                        sendDataToParent={handleOpenDatePickerData}
                      />
                    </div>
                  </div>
                  <div style={{ width: "48%" }}>
                    <VerticalInputSelectCustomCopy
                      style={{ minHeight: "28px" }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Hold Rooms</span>
                <span>200</span>
              </div>
              <div className="flex justify_content_between align_center">
                <span>Converted </span>
                <span>200</span>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Not Used</span>
                <span>200</span>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Remaining</span>
                <span>200</span>
              </div>
              <div className="flex justify_content_between align_center">
                <span>Converted Booking Amount</span>
                <span>₹ 200</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentHoldRoomsSliderSideBar;
