import React, { useEffect } from "react";
import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "./NoDataFound";
import CustomCssComponentTableText from "./CustomCssComponentTableText";
import DownloadCsvTables from "./DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "./DownloadPdfTables/DownloadPdfTables";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "./CustomReportComponent/CustomReportComponent";
import ButtonToDownloadTablePDF from "./DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "./DownloadCsvTables/DownloadCSVButton";

const ReportOccupancyChart = ({ reportData }) => {
  const repeatItems = (num) => {
    const items = ["Total", "Sold", "Hold", "Occ %"];
    const startingArray = ["Date"];
    const endArray = ["Net Daily Occupancy"];
    const colors = ["#ecf1ff", "#dff8df", "#fff4d2", "#ffeaea"];
    const emptyColor = "";

    let headings = [...startingArray];
    let colorsArray = [emptyColor];

    for (let i = 0; i < num; i++) {
      headings = headings.concat(items);
      const color = colors[i % colors.length];
      for (let j = 0; j < items.length; j++) {
        colorsArray.push(color);
      }
    }

    headings = headings.concat(endArray);
    colorsArray.push(emptyColor);

    return { headings, colors: colorsArray };
  };
  const roomCat =
    reportData &&
    reportData.length > 0 &&
    reportData[0].rooms.map((room) => room.room_name);

  function repeatElements(inputArray, repeatCount) {
    if (!Array.isArray(inputArray)) {
      return;
    }

    if (typeof repeatCount !== "number" || repeatCount <= 0) {
      return;
    }

    const emptyArray = [""];

    const array =
      inputArray &&
      inputArray.length > 0 &&
      inputArray.flatMap((item) => Array(repeatCount).fill(item));

    if (!array) {
      throw new TypeError("Failed to create the repeated array");
    }

    return [...emptyArray, ...array, ...emptyArray];
  }

  const repeatArrayLength =
    reportData && reportData.length > 0 && reportData[0].rooms.length;
 
   
    console.log(repeatItems(repeatArrayLength)?.colors,"colors")


  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Occupancy Chart</div>
          {reportData?.length > 0 && (
            <div className="flex flex_gap_15" id="pdf">
              <ButtonToDownloadTablePDF
                itemToDownloadID={"receipt"}
                downloadFileName={"occupancy_chart"}
                ignoreFromPrint={"pdf"}
              />
              {/* <DownloadCsvTables tableData={reportData} /> */}
              <DownloadCSVButton downloadFileName={"occupancy_chart"} itemToDownloadID={"receipt"}  />
            </div>
          )}
        </div>

        {reportData && reportData.length > 0 && (
          <CustomReportTable
            headings={repeatItems(repeatArrayLength)?.headings}
            commonHeadings={repeatElements(roomCat, repeatArrayLength)}
            headerStyles={{ backgroundColor: "#dddddd", fontSize: "12px" }}
            tableStyles={{ textAlign: "left" }}
            groupBackgroundColors={repeatItems(repeatArrayLength)?.colors}
          >
            {reportData.map((item, index) => {
              const color = [
                ...new Set(repeatItems(repeatArrayLength)?.colors),
              ].filter((color) => color !== "");

             
              return (
                <CustomReportTableRow key={index}>
                  <CustomReportTableCell
                    groupBackgroundColors={
                      repeatItems(repeatArrayLength)?.colors[0]
                    }
                  >
                    {item.date}
                  </CustomReportTableCell>
                  {item.rooms.map(
                    (
                      {
                        sold_rooms,
                        total_rooms,
                        hold_rooms,
                        rooms_occupancy,
                        room_name,
                      },
                      i
                    ) => {
                    
                      return (
                        <React.Fragment key={i}>
                          <CustomReportTableCell
                            groupBackgroundColors={color[i]}
                          >
                            {total_rooms}
                          </CustomReportTableCell>
                          <CustomReportTableCell
                            groupBackgroundColors={color[i]}
                          >
                            {sold_rooms}
                          </CustomReportTableCell>
                          <CustomReportTableCell
                            groupBackgroundColors={color[i]}
                          >
                            {hold_rooms}
                          </CustomReportTableCell>
                          <CustomReportTableCell
                            groupBackgroundColors={color[i]}
                          >
                            {rooms_occupancy}
                          </CustomReportTableCell>
                        </React.Fragment>
                      );
                    }
                  )}
                  <CustomReportTableCell
                    groupBackgroundColors={repeatItems(
                      repeatArrayLength
                    )?.colors?.slice(-1)}
                  >
                    {item.net_rooms_occupancy}
                  </CustomReportTableCell>
                </CustomReportTableRow>
              );
            })}
          </CustomReportTable>
        )}
      </div>
    </>
  );
};
export default ReportOccupancyChart;
