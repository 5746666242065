import {
  Button,
  Icon,
  IndexTable,
  LegacyCard,
  Page,
  Select,
  TextField,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { DeleteMajor } from "@shopify/polaris-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BgThemeButton, WithoutBgButtonBlue } from "./Buttons";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader/Loader";
import InnerLoader from "./Loader/innerLoader";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import LoaderSmall from "./Loader/LoaderSmall";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";

const AddAdvancePayments = ({ setShowAddModelMenuActive }) => {
  const { agentid } = useParams();
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [
    showAddCategoriesLowerMenuActive,
    setShowAddCategoriesLowerMenuActive,
  ] = useState(false);

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);

  const navigate = useNavigate();
  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [advancePayments, setAdvancePayments] = useState([]);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(advancePayments);

  const [addAgentCategories, setAddAgentCategories] = useState({
    catName: "",
    catDescription: "",
    catDiscount: null,
  });

  const setAddAgentCategoriesHandleChange = (event) => {
    setAddAgentCategories({
      ...addAgentCategories,
      [event.target.name]: event.target.value,
    });
  };

  const [addCompanyAdvanceState, setAddCompanyAdvanceState] = useState({
    agent_information_id: "",
    advance_amount: "",
  });

  const handleChangeCompanyCredits = (event) => {
    setAddCompanyAdvanceState({
      ...addCompanyAdvanceState,
      [event.target.name]: event.target.value,
    });
  };

  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  // fetch data
  const [btnStatus, setButtonStatus] = useState([]);

  const [loader, setLoader] = useState(false);

  const fetchInfo = async (id) => {
    if (!id) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/show/agent/advance/payment/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAdvancePayments(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
    }
  }, [property_id]);
  const filterAdvancePayments = advancePayments?.filter(
    (item) => Number(item?.agent_information_id) === Number(agentid)
  );


  const categoriesRowMarkup = filterAdvancePayments?.map(
    ({
      id,
      agent_information_id,
      advance_amount,
      used_amount,
      balance_amount,
      index,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>₹{advance_amount}</IndexTable.Cell>
        <IndexTable.Cell>₹{used_amount}</IndexTable.Cell>
        <IndexTable.Cell>₹{balance_amount}</IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{ cursor: "pointer" }} onClick={() => showModelMenu(id)}>
            {" "}
            <Icon source={DeleteMajor} color="base" />
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  // Fetch the room type click data
  const [agents, setAgents] = useState([]);
  const fetchAgentsInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setAgents(data.data);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setAgents([]);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchAgentsInfo(property_id.id);
    }
  }, [property_id]);

  const agentArray = [
    { label: "Select an option", value: "" }, // Your first option
    ...(agents?.map((agent) => ({
      label: agent.agent_name,
      value: agent.agent_information,
    })) || []),
  ];

  const updateAdvancePaymentsHandle = (e) => {
    e.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/update/agent/advance/payment/2/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify(addCompanyAdvanceState),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setShowLowerMenuSingleSelect(false);
          setShowAddCategoriesLowerMenuActive(false);
          fetchInfo(property_id?.id);
          setAddAgentCategories({
            catName: "",
            catDescription: "",
            catDiscount: "",
          });
          showSuccessToast(data.message);
        } else if (data.error) {
          const firstErrorKey = Object.keys(data.error)[0];
          const firstErrorMessage = data.error[firstErrorKey][0];
          setShowAddCategoriesLowerMenuActive(false);
          showErrorToast(firstErrorMessage);
        } else {
          showErrorToast(data.message);
          setShowAddCategoriesLowerMenuActive(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
        setShowAddCategoriesLowerMenuActive(false);
      });
  };
  const handleSubmitAddAgentCategories = (e) => {
    e.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/create/agent/advance/payment/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addCompanyAdvanceState,
          used_amount: "0",
          balance_amount: addCompanyAdvanceState.advance_amount,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setShowLowerMenuSingleSelect(false);
          setShowAddCategoriesLowerMenuActive(false);
          fetchInfo(property_id?.id);
          setAddAgentCategories({
            catName: "",
            catDescription: "",
            catDiscount: "",
          });
          showSuccessToast(data.message);
        } else if (data.error) {
          const firstErrorKey = Object.keys(data.error)[0];
          const firstErrorMessage = data.error[firstErrorKey][0];
          setShowAddCategoriesLowerMenuActive(false);
          showErrorToast(firstErrorMessage);
        } else {
          showErrorToast(data.message);
          setShowAddCategoriesLowerMenuActive(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
        setShowAddCategoriesLowerMenuActive(false);
      });
  };

  const [deleteId, setDeleteId] = useState("");

  const deleteData = async () => {
    const deletedIds = {
      ids: [deleteId],
    };

    const response = await fetch(
      // /api/v1/delete/agent/company/credits/10
      `${process.env.REACT_APP_BASE_URL}/api/v1/delete/agent/advance/payment/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // clearSelection();
          // setAddAgentCategories({
          //   catName: "",
          //   catDescription: "",
          //   catDiscount: "",
          // });
          fetchInfo(property_id?.id);
          clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/agent/advance/payment/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        setAddCompanyAdvanceState(data.data);
        setShowLowerMenuSingleSelect(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateFunction = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/category/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...addAgentCategories }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        setShowLowerMenuSingleSelect(false);
        clearSelection();
        showSuccessToast("Category Updated Successfully ..");
        setLoader(false);
      } else if (data.error) {
        const firstErrorKey = Object.keys(data.error)[0];
        const firstErrorMessage = data.error[firstErrorKey][0];
        showErrorToast(firstErrorMessage);
        fetchInfo(property_id?.id);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowAddCategoriesLowerMenuActive(false);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
      // setShowAddCategoriesLowerMenuActive(false);
    }
  }, [selectedResources]);
  const handleOpenAddCategoryClick = () => {
    clearSelection();
    setShowAddCategoriesLowerMenuActive(!showAddCategoriesLowerMenuActive);
    setShowLowerMenuMultipleSelect(false);
    setShowLowerMenuSingleSelect(false);
    setAddAgentCategories({
      catName: "",
      catDescription: "",
      catDiscount: null,
    });
  };

  const showModelMenu = (id) => {
    setDeleteId(id);
    setShowModelMenuActive(!showModelMenuActive);
  };

  const customers_inner_list = [
    {
      name: "Used Credit",
      link: `/credit/${agentid}`,
      permission: "read_customers",
    },
    {
      name: "Payments",
      link: `/transfer/${agentid}`,
      permission: "read_agents",
    },
    {
      name: "Added Credits",
      link: `/agents/credits/${agentid}`,
      permission: "read_agents",
    },
    // {
    //   name: "Advance Payments",
    //   link: `/agents/advance/payments/${agentid}`,
    //   permission: "read_agents",
    // },
    {
      name: "Bookings",
      link: `/agents/bookings/${agentid}`,
      permission: "read_agents",
    },
    {
      name: "Credit Finance Logs",
      link: `/agents/credit/Logs/${agentid}`,
      permission: "read_agents",
    }, {
      name: "City Ledger Report",
      link: `/agents/city/Ledger/${agentid}`,
      permission: "read_agents",
    },
  ];

  return (
    <>
      <div className="main_container">
        <SidebarInnerLists innerLists={customers_inner_list} />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 15px 0 15px",
          }}
        >
          <div className="room-type-heading"></div>
          <div>
            <WithoutBgButtonBlue
              style={{ fontSize: "14px", padding: "0" }}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clip-path="url(#clip0_5311_4678)">
                    <path
                      d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                      stroke="#3968ED"
                    />
                    <path
                      d="M10.5868 7H3.41357"
                      stroke="#3968ED"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M7 3.4132L7 10.5864"
                      stroke="#3968ED"
                      stroke-miterlimit="10"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5311_4678">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              }
              children={"Add"}
              onClick={() => handleOpenAddCategoryClick()}
            />
          </div>
        </div>
        <section className="" style={{ width: "100%" }}>
          <LegacyCard>
            <div className="">
              {loader ? (
                <LoaderSmall />
              ) : (
                <>
                  {filterAdvancePayments && filterAdvancePayments.length > 0 ? (
                    <IndexTable
                      // selectable={false}
                      resourceName={resourceName}
                      itemCount={advancePayments.length}
                      selectedItemsCount={
                        allResourcesSelected ? "All" : selectedResources.length
                      }
                      selectable={false}
                      onSelectionChange={handleSelectionChange}
                      headings={[
                        { title: "Credit Amount" },
                        { title: "Used Amount" },
                        { title: "Balance Amount" },
                      ]}
                    >
                      {categoriesRowMarkup}
                    </IndexTable>
                  ) : (
                    <NoDataFoundSmall />
                  )}
                </>
              )}
            </div>

            {/* Single select */}
            {showLowerMenuSingleSelect && (
              <div className="room-type-bottom-container">
                <div className="room-type-bottom-container-popup-div">
                  <div>
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        {/* <span>Remove</span> */}
                      </div>
                    </Button>
                  </div>
                  <form
                    action=""
                    onSubmit={(e) => updateAdvancePaymentsHandle(e)}
                  >
                    <div className="room-type-bottom-div">
                      <div style={{ width: "160px" }}>
                        <VerticalInputSelectCustomCopy
                          options={agentArray}
                          required={true}
                          name={"agent_information_id"}
                          className="textfield"
                          focused={false}
                          titleName="Select Agent"
                          onChange={handleChangeCompanyCredits}
                          autoComplete="off"
                          value={addCompanyAdvanceState.agent_information_id}
                        />
                      </div>
                      <div className="room-type-bottom-div">
                        <div style={{ width: "270px" }}>
                          <VerticalInputFieldCustom
                            required={true}
                            name={"advance_amount"}
                            className="textfield"
                            focused={false}
                            titleName="Amount"
                            onChange={handleChangeCompanyCredits}
                            autoComplete="off"
                            value={addCompanyAdvanceState.advance_amount}
                          />
                        </div>
                      </div>

                      <BgThemeButton type={"submit"}>Update</BgThemeButton>
                      {/* <div className="room-type-bottom-div">
                        <div>
                          <VerticalInputFieldCustom
                            required={true}
                            name={"catDiscount"}
                            className="textfield"
                            focused={false}
                            type={"number"}
                            titleName="Category Discount"
                            onChange={setAddAgentCategoriesHandleChange}
                            autoComplete="off"
                            value={addAgentCategories.catDiscount}
                            max={100}
                          />
                        </div>
                      </div> */}
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* Multiple select */}
            {showLowerMenuMultipleSelect && (
              <div className="room-type-bottom-container">
                <div
                  className="room-type-bottom-container-popup-div"
                  style={{
                    padding: "10px 20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {/* Add Button Click */}
            {showAddCategoriesLowerMenuActive && (
              <div className="room-type-bottom-container">
                <div
                  className="room-type-bottom-container-popup-div"
                  style={{
                    padding: "10px 20px",
                  }}
                >
                  <form
                    onSubmit={(e) => handleSubmitAddAgentCategories(e)}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="room-type-bottom-div">
                      <div style={{ width: "160px" }}>
                        <VerticalInputSelectCustomCopy
                          options={agentArray}
                          required={true}
                          name={"agent_information_id"}
                          className="textfield"
                          focused={false}
                          titleName="Select Agent"
                          onChange={handleChangeCompanyCredits}
                          autoComplete="off"
                          value={addCompanyAdvanceState.agent_information_id}
                        />
                      </div>
                      <div className="room-type-bottom-div">
                        <div style={{ width: "270px" }}>
                          <VerticalInputFieldCustom
                            required={true}
                            name={"advance_amount"}
                            className="textfield"
                            focused={false}
                            titleName="Amount"
                            onChange={handleChangeCompanyCredits}
                            autoComplete="off"
                            value={addCompanyAdvanceState.advance_amount}
                          />
                        </div>
                      </div>
                      {/* <div className="room-type-bottom-div">
                        <div>
                          <VerticalInputFieldCustom
                            required={true}
                            name={"catDiscount"}
                            className="textfield"
                            focused={false}
                            type={"number"}
                            titleName="Category Discount"
                            onChange={setAddAgentCategoriesHandleChange}
                            autoComplete="off"
                            value={addAgentCategories.catDiscount}
                            max={100}
                          />
                        </div>
                      </div> */}
                    </div>

                    <div
                      style={{
                        color: "#3968ED",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton children={"Add"} type="submit" />
                    </div>
                  </form>
                </div>
              </div>
            )}
          </LegacyCard>
        </section>
        {showModelMenuActive && (
          <>
            <div className="bg-backdrop">
              <section className="room-type-model-container ">
                <h4>Are You Sure?</h4>
                <p>You Want To Remove?</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button onClick={showModelMenu}>Discard</Button>
                  <Button onClick={() => deleteData()} destructive>
                    Yes, Remove
                  </Button>
                </div>
              </section>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AddAdvancePayments;
