import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Collapsible,
  DropZone,
  Icon,
  LegacyCard,
  LegacyStack,
  Link,
  Select,
  TextField,
  Text,
  Thumbnail,
  ButtonGroup,
  Page,
  Badge,
  Tag,
} from "@shopify/polaris";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalTextAreaCustom from "./CustomComponents/VerticalTextAreaCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import LoaderSmall from "./UI/Loader/LoaderSmall";
import CustomToggleButton from "./UI/CustomToggleButton";
import RoomTypesExtraBeddingToggle from "./RoomTypesExtraBeddingToggle/RoomTypesExtraBeddingToggle";
import RoomTypeAmenities from "./CustomComponents/RoomTypeAmenities/RoomTypeAmenities";

const EditRooms = () => {
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("Jaded Pixel");
  const [selected, setSelected] = useState("today");

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(true);
  const [open6, setOpen6] = useState(true);
  const [open7, setOpen7] = useState(true);
  const [open8, setOpen8] = useState(true);
  const [open9, setOpen9] = useState(true);

  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);
  const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);
  const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);
  const handleToggle4 = useCallback(() => setOpen4((open4) => !open4), []);
  const handleToggle5 = useCallback(() => setOpen5((open5) => !open5), []);
  const handleToggle6 = useCallback(() => setOpen6((open6) => !open6), []);
  const handleToggle7 = useCallback(() => setOpen7((open7) => !open7), []);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);
  const handleToggle9 = useCallback(() => setOpen9((open9) => !open9), []);

  // For status options
  // const [selectedOption, setSelectedOption] = useState("today");

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  const bedTypeOptions = [
    { label: "Select option", value: "" },
    { label: "Futon", value: "Futon" },
    { label: "King", value: "King" },
    { label: "Murphy Bed", value: "Murphy Bed" },
    { label: "Queen", value: "Queen" },
    { label: "Sofa Bed", value: "Sofa Bed" },
    { label: "Tatami Mats", value: "Tatami Mats" },
    { label: "Twin", value: "Twin" },
    { label: "Single", value: "Single" },
    { label: "Full", value: "Full" },
    { label: "Run of the House", value: "Run of the House" },
    { label: "Dorm Bed", value: "Dorm Bed" },
  ];

  const roomViewTypeOptions = [
    { label: "Select option", value: "" },
    { label: "Airport view", value: "Airport view" },
    { label: "Bay view", value: "Bay view" },
    { label: "City view", value: "City view" },
    { label: "Courtyard view", value: "Courtyard view" },
    { label: "Golf view", value: "Golf view" },
    { label: "Harbor view", value: "Harbor view" },
    { label: "Intercoastal view", value: "Intercoastal view" },
    { label: "Lake view", value: "Lake view" },
    { label: "Marina view", value: "Marina view" },
    { label: "Mountain view", value: "Mountain view" },
    { label: "Ocean view", value: "Ocean view" },
    { label: "Pool view", value: "Pool view" },
    { label: "River view", value: "River view" },
    { label: "Water view", value: "Water view" },
    { label: "Beach view", value: "Beach view" },
    { label: "Garden view", value: "Garden view" },
    { label: "Park view", value: "Park view" },
    { label: "Forest view", value: "Forest view" },
    { label: "Rain forest", value: "Rain forest" },
    { label: "Various views", value: "Various views" },
    { label: "Limited view", value: "Limited view" },
    { label: "Slope view", value: "Slope view" },
    { label: "Strip view", value: "Strip view" },
    { label: "Countryside view", value: "Countryside view" },
    { label: "Sea view", value: "Sea view" },
    { label: "Valley view", value: "Valley view" },
  ];

  const roomAreaTypeOptions = [
    { label: "Select option", value: "" },
    { label: "Square Meters", value: "Square Meters" },
    { label: "Square Feet", value: "Square Feet" },
  ];

  const userToken = JSON.parse(localStorage.getItem("userToken"));

  // for Tag
  // const [selectedTags, setSelectedTags] = useState([
  //   "Rustic",
  //   "Antique",
  //   "Vinyl",
  //   "Refurbished",
  //   "Singh",
  // ]);

  // const removeTag = useCallback(
  //   (tag) => () => {
  //     setSelectedTags((previousTags) =>
  //       previousTags.filter((previousTag) => previousTag !== tag)
  //     );
  //   },
  //   []
  // );
  // const tagMarkup = selectedTags.map((option) => (
  //   <Tag key={option} onRemove={removeTag(option)}>
  //     {option}
  //   </Tag>
  // ));

  const [bedding, setBedding] = useState("");

  // const beddingFinalValue = useCallback((data) => {
  //   setBedding(data)
  // }, [bedding]);

  const beddingFinalValue = useCallback((data) => {
    setBedding(data);
    setAddRoomTypeData((prevState) => {
      return {
        ...prevState,
        extra_bedding: data,
      };
    });
  }, []);

  const navigate = useNavigate();

  const [addRoomTypeData, setAddRoomTypeData] = useState({
    id: "",
    room_total: 0,
    rack_price: 0,
    max_child: 0,
    room_name: "",
    max_adults: 0,
    base_child: 0,
    base_adult: 0,
    infants: 0,
    extra_bed_limit: 0,
    room_size: 0,
    room_size_unit: "",
    room_bed_type: "",
    room_view_type: "",
    room_description: "",
    amenities: "",
    property_id: "",
    breakfast: 0,
    lunch: 100,
    dinner: 100,
    extra_adults: 0,
    child_with_Bed: 0,
    child_without_Bed: 0,
    extra_bedding: "",
    page_title: "",
    meta_description: "",
    url_handle: "",

    // room_images: "",
    status: "active",

    // new changes
    max_occupancy: "",
    min_los: "",
    max_los: "",
  });

  // const beddingFinalValue = useCallback((data) => {
  //   setAddRoomTypeData((prevData) => ({
  //     ...prevData,
  //     extra_bedding: data?.toLowerCase(),
  //   }));
  // }, [addRoomTypeData.extra_bedding]);

  // useEffect(()=>{
  //   setBedding(addRoomTypeData.extra_bedding)
  // },[addRoomTypeData.extra_bedding])

  // Onchange Function
  const setAddItemDataHandleChange = (event) => {
    setAddRoomTypeData({
      ...addRoomTypeData,
      [event.target.name]: event.target.value,
    });
  };

  let { roomtypeid } = useParams();

  const fetchInfo = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/edit/${roomtypeid}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setBedding(data.room.extra_bedding);
      // setSelectedTags(data?.room?.amenities)
      const updatedData = {
        id: data.room.id,
        room_total: data.room.room_total,
        status: data.room.status,
        rack_price: data.room.rack_price,
        max_child: data.room.max_child,
        room_name: data.room.room_name,
        max_adults: data.room.max_adults,
        base_child: data.room.base_child,
        base_adult: data.room.base_adult,
        infants: data.room.infants,
        extra_bed_limit: data.room.extra_bed_limit,
        room_size: data.room.room_size,
        room_size_unit: data.room.room_size_unit,
        room_bed_type: data.room.room_bed_type,
        room_description: data.room.room_description,
        room_view_type: data.room.room_view_type,
        amenities: data.room.amenities,
        property_id: data.room.property_id,
        breakfast: data.room.room_total,
        lunch: data.room.lunch,
        dinner: data.room.dinner,
        extra_adults: data.room.extra_adults,
        child_with_Bed: data.room.child_with_Bed,
        child_without_Bed: data.room.child_without_Bed,
        extra_bedding: data.room.extra_bedding,
        page_title: data.room.page_title,
        meta_description: data.room.meta_description,
        url_handle: data.room.url_handle,

        max_occupancy: data.room.max_occupancy,
        min_los: data.room.min_los,
        max_los: data.room.max_los,
      };

      setSelectedTags(data.room.amenities_arr);
      setAddRoomTypeData(updatedData);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchRoomTypesImages(property_id?.id);
    }
  }, [property_id]);

  const [onkeyPressValue, setOnKeyPressValue] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);

  const [buttonLoader, setButtonLoader] = useState(false);

  const updateFunction = (id) => {
    setButtonLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/update/${id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addRoomTypeData,
          amenities: selectedArray,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          fetchInfo(property_id.id);
          navigate("/roomstype");
          setButtonLoader(false);
        } else {
          showErrorToast(data.message);
          setButtonLoader(false);
        }
        setButtonLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setButtonLoader(false);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState([]);

  const handleFileUploadChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
    const file = files[0];
    const fileUrl = URL.createObjectURL(file);
    setUploadedFile(file);
    setLogoUrl(fileUrl);
  };

  const handleRemoveLogobtn = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const addRoomTypeImage = (event) => {
    event.preventDefault();
    setLoader(true);
    const formData = new FormData();
    formData.append("media_type", "room");
    formData.append("associated_id", roomtypeid);
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("room_images[]", selectedFiles[i]);
    }
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/media/upload/${property_id?.id}`,
      {
        method: "POST",
        body: formData,
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          fetchRoomTypesImages(property_id?.id);
          setSelectedFiles([]);
          setLoader(false);
          showSuccessToast(data.message);
        } else if (data.errors) {
          setLoader(false);
          const firstErrorKey = Object.keys(data.error)[0];
          const firstErrorMessage = data.error[firstErrorKey][0];
          showErrorToast(firstErrorMessage);
        } else {
          setLoader(false);
          showErrorToast(data.message);
        }
      });
  };

  const [fetchedImages, setFetchedImages] = useState([]);

  const fetchRoomTypesImages = (property_id) => {
    const formData = new FormData();
    formData.append("media_type", "room");
    formData.append("associated_id", roomtypeid);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/media/fetch/${property_id}`,
      {
        method: "POST",
        body: formData,
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setFetchedImages(data?.media.room_images);
      });
  };

  const deleteFetchedImages = async (roomId) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/media/delete/${roomId}/${property_id?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    const data = await response
      .json()
      .then((data) => {
        if (data.message) {
          showSuccessToast(data.message);
          fetchRoomTypesImages(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [selectedArray, setSelectedArray] = useState([]);

  const getAmenitiesFromChild = (data) => {
    setSelectedArray(data);
  };

 

 

  return (
    <>
      <div className="edit-rooms-full-container">
        <div>
          <Page
            backAction={{ content: "Products", url: `/roomstype` }}
            title="Edit Room"
            compactTitle
          ></Page>
        </div>
        <div className="edit-rooms-full-container-div">
          <div className="edit-rooms-full-container-left">
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                width: "591px",
              }}
            >
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle1}
                      aria-expanded={open1}
                      aria-controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      <div>Room Details</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open1}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <VerticalInputFieldCustom
                              focused={false}
                              name="room_name"
                              titleName="Room Type Name"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addRoomTypeData.room_name}
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              type="number"
                              required={true}
                              focused={false}
                              name="room_total"
                              titleName="Total Rooms"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addRoomTypeData.room_total}
                              min={1}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputSelectCustomCopy
                              required={true}
                              options={bedTypeOptions}
                              name="room_bed_type"
                              focused={false}
                              titleName="Bed Type"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addRoomTypeData.room_bed_type}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputSelectCustomCopy
                              required={true}
                              options={roomViewTypeOptions}
                              name="room_view_type"
                              value={addRoomTypeData.room_view_type}
                              focused={false}
                              titleName="Room View Type"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="form-container-div">
                          <VerticalTextAreaCustom
                            name="room_description"
                            value={addRoomTypeData.room_description}
                            focused={false}
                            titleName="Descriptions"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                            cols={10}
                            rows={5}
                          />
                        </div>
                        <div className="form-container-div"></div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle2}
                      aria_expanded={open2}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Guest</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open2}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-half">
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                name="base_adult"
                                value={addRoomTypeData.base_adult}
                                focused={false}
                                titleName="Base Adult"
                                onChange={setAddItemDataHandleChange}
                                autoComplete="off"
                              />
                            </div>
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                name="min_los"
                                value={addRoomTypeData.min_los}
                                focused={false}
                                titleName="Min LOS"
                                onChange={setAddItemDataHandleChange}
                                autoComplete="off"
                              />
                            </div>
                            <div className="form-container-div">
                              <VerticalInputFieldCustom
                                name="max_los"
                                value={addRoomTypeData.max_los}
                                focused={false}
                                titleName="Max LOS"
                                onChange={setAddItemDataHandleChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              name="max_adults"
                              value={addRoomTypeData.max_adults}
                              focused={false}
                              titleName="Max Adult"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              name="max_child"
                              value={addRoomTypeData.max_child}
                              focused={false}
                              titleName="Max Child"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              name="max_occupancy"
                              value={addRoomTypeData.max_occupancy}
                              focused={false}
                              titleName="Max Occupancy"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle3}
                      aria_expanded={open3}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Size</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open3}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              name="room_size"
                              value={addRoomTypeData.room_size}
                              focused={false}
                              titleName="Room Size"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputSelectCustomCopy
                              options={roomAreaTypeOptions}
                              name="room_size_unit"
                              value={addRoomTypeData.room_size_unit}
                              focused={false}
                              titleName="Unit"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle4}
                      aria_expanded={open4}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Extra</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open4}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          {/* <CustomToggleButton
                            positive={"enabled"}
                            negative={"disabled"}
                            func={beddingFinalValue}
                            title={"Bedding"}
                            byDefaultEnable={addRoomTypeData.extra_bedding === "enabled" ? true : false}
                          // byDefaultEnable={
                          //   bedding === "enabled" ? true : false
                          // }
                          /> */}

                          <RoomTypesExtraBeddingToggle
                            positive={"enabled"}
                            negative={"disabled"}
                            func={beddingFinalValue}
                            title={"Bedding"}
                            byDefaultEnable={
                              addRoomTypeData.extra_bedding === "enabled"
                                ? true
                                : false
                            }
                          />
                        </div>

                        {addRoomTypeData.extra_bedding === "enabled" && (
                          <div className="form-container-div-full">
                            <VerticalInputFieldCustom
                              required={true}
                              type={"number"}
                              name="extra_bed_limit"
                              value={addRoomTypeData.extra_bed_limit}
                              focused={false}
                              titleName="Extra Bed Limit"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              min={0}
                            />
                          </div>
                        )}
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle5}
                      aria_expanded={open5}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Media</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open5}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      {loader ? (
                        <LoaderSmall />
                      ) : (
                        <>
                          <div className="flex flex_gap_10 flex_wrap">
                            {/* {fetchedImages && fetchedImages.length > 0( */}
                            <>
                              {fetchedImages.map((item, index) => (
                                <div key={index}>
                                  <div className="flex flex_gap_10 justify-content-between">
                                    <div>
                                      <img
                                        src={item.src}
                                        alt={`Room Type Image ${index}`}
                                        height="80px"
                                        width="100px"
                                      />
                                    </div>
                                    <div className="featureImages_btnContainer">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          deleteFetchedImages(item.id)
                                        }
                                        className="featureImages_removeLogobtn"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="15"
                                          height="15"
                                          viewBox="0 0 8 8"
                                          fill="none"
                                        >
                                          <path
                                            d="M1 6.94752L7 1"
                                            stroke="black"
                                          />
                                          <path
                                            d="M7 6.94775L1 1.00023"
                                            stroke="black"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                            {/* )} */}
                          </div>
                          <div style={{ position: "relative" }}>
                            <input
                              accept="image/*"
                              type="file"
                              id="select-image"
                              multiple="multiple"
                              onChange={handleFileUploadChange}
                              className={`${
                                selectedFiles.length === 0
                                  ? "featureImages_upload_file_input"
                                  : "featureImages_upload_file_input featureImages_addzindex"
                              }`}
                            />
                            {selectedFiles && selectedFiles.length > 0 ? (
                              <div className="multiple_image_upload_container">
                                {selectedFiles.map((file, index) => (
                                  <div
                                    key={index}
                                    className="multiple_image_upload_box"
                                  >
                                    <div>
                                      <img
                                        src={URL.createObjectURL(file)}
                                        alt="error"
                                        height="90px"
                                        width="150px"
                                      />
                                    </div>
                                    <div className="featureImages_btnContainer">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleRemoveLogobtn(index)
                                        }
                                        className="featureImages_removeLogobtn"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="15"
                                          height="15"
                                          viewBox="0 0 8 8"
                                          fill="none"
                                        >
                                          <path
                                            d="M1 6.94752L7 1"
                                            stroke="black"
                                          />
                                          <path
                                            d="M7 6.94775L1 1.00023"
                                            stroke="black"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              // </div>
                              <div className="featureImages_uplaodFile_displaytext_container">
                                <p className="featured_image_text">Add Files</p>
                                <p className="featured_image_text_dragdrop">
                                  or drop JPG, PNG images here
                                </p>
                              </div>
                            )}
                            {selectedFiles && selectedFiles.length ? (
                              <BgThemeButton
                                type={"button"}
                                onClick={(event) => addRoomTypeImage(event)}
                                children={"Upload"}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      )}
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              {/* <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle6}
                      aria_expanded={open6}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Search Engine Listing</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>

                    <p
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#888",
                      }}
                    >
                      Add a title and description to see how this Room might
                      appear
                      <br />
                      in a search engine listing
                    </p>
                    <Collapsible
                      open={open6}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            name="page_title"
                            value={addRoomTypeData.page_title}
                            focused={false}
                            titleName="Page Title"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            name="meta_description"
                            value={addRoomTypeData.meta_description || ''}
                            focused={false}
                            titleName="Meta Description"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            name="url_handle"
                            value={addRoomTypeData.url_handle || ''}
                            focused={false}
                            titleName="Url Handle"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div> */}
            </form>
            <div className="rooms-edit-left-container-buttons">
              <div>
                <BgThemeButton
                  onClick={() => updateFunction(addRoomTypeData.id)}
                  children={"Update"}
                  loader={buttonLoader}
                />
              </div>
              <div style={{ color: "rgba(0, 122, 92, 1)" }}>
                <BgOutlineThemeButton
                  onClick={() => navigate("/roomstype")}
                  children={"Discard"}
                />
              </div>
            </div>
          </div>

          <div className="edit-rooms-full-container-right">
            <form
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle7}
                      aria_expanded={open7}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Status</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open7}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <VerticalInputSelectCustomCopy
                        options={statusOptions}
                        name="status"
                        value={addRoomTypeData.status}
                        focused={false}
                        titleName="Status"
                        onChange={setAddItemDataHandleChange}
                        autoComplete="off"
                      />
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>

              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle8}
                      aria_expanded={open8}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Amenities</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open8}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        {/* <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            type="text"
                            name="amenities"
                            value={onkeyPressValue}
                            focused={false}
                            onChange={(e) => setOnKeyPressValueHandleChange(e)}
                            onKeyDown={handleOnKeyDown}
                            autoComplete="off"
                          />
                          
                        </div> */}
                        <RoomTypeAmenities
                          searchBox={false}
                          amenities={selectedTags.length >0 ? selectedTags : []}
                          getAmenitiesFromChild={getAmenitiesFromChild}


                        />


                        {/* <LegacyStack spacing="tight">{tagMarkup}</LegacyStack> */}
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle9}
                      aria_expanded={open9}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      Pricing
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open9}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            name="rack_price"
                            value={addRoomTypeData.rack_price}
                            focused={false}
                            titleName="Rack Price"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            name="extra_adults"
                            value={addRoomTypeData.extra_adults}
                            focused={false}
                            titleName="Extra Adults"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            name="child_with_Bed"
                            value={addRoomTypeData.child_with_Bed}
                            focused={false}
                            titleName="Child With Bed"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            name="child_without_Bed"
                            value={addRoomTypeData.child_without_Bed}
                            focused={false}
                            titleName="Child Without Bed"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRooms;
