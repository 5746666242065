import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "./NoDataFound";
import CustomCssComponentTableText from "./CustomCssComponentTableText";
import DownloadCsvTables from "./DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "./DownloadPdfTables/DownloadPdfTables";
import { calculateTotalWithReduce, formatIndianCurrency } from "../../utils/utils";
import { CustomReportTable, CustomReportTableCell, CustomReportTableRow } from "./CustomReportComponent/CustomReportComponent";
import ButtonToDownloadTablePDF from "./DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "./DownloadCsvTables/DownloadCSVButton";

const ReportCancelledKot = ({ reportData }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const names = [
    {
      kot_id: "KOT ID",
      kot_date: "Date",
      kot_grand_total: "SubTotal",
      kot_tax_amount: "Tax",
      kot_total_amount: "Grand Total",
      kot_reason: "Reason",
    },
  ];
  const rowMarkup =
    reportData &&
    reportData?.map(
      (
        {
          kot_id,
          kot_date,
          kot_grand_total,
          kot_reason,
          kot_tax_amount,
          kot_total_amount,
          rooms_occupancy,
          room_name,
          total_rooms,
          sold_rooms,
        },
        index
      ) => {
        return (
          <CustomReportTableRow id={index} key={index}>
            <CustomReportTableCell>{kot_id}</CustomReportTableCell>
            <CustomReportTableCell>{kot_date}</CustomReportTableCell>

            <CustomReportTableCell>
              {formatIndianCurrency(kot_grand_total)}
            </CustomReportTableCell>
            <CustomReportTableCell>
              {formatIndianCurrency(kot_tax_amount)}
            </CustomReportTableCell>
            <CustomReportTableCell>
              {formatIndianCurrency(kot_total_amount)}
            </CustomReportTableCell>
            <CustomReportTableCell>{kot_reason}</CustomReportTableCell>
          </CustomReportTableRow>
        );
      }
    );

    const calculateTotals = (
      <CustomReportTableRow
        CustomReportTableRow
        id={"id"}
        key={"id"}
        bodyStyles={{
          borderTop: "1px solid #CCC",
          backgroundColor: "lightgray",
        }}
      >
        <CustomReportTableCell>{"Total"}</CustomReportTableCell>
        <CustomReportTableCell></CustomReportTableCell>
       
          <CustomReportTableCell>   {formatIndianCurrency(calculateTotalWithReduce(reportData, "kot_grand_total"))}</CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(calculateTotalWithReduce(reportData, "kot_tax_amount"))}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(
            calculateTotalWithReduce(reportData, "kot_total_amount")
          )}
        </CustomReportTableCell>
        <CustomReportTableCell></CustomReportTableCell>
      </CustomReportTableRow>
    );

  return (
    <>
      <div id="receipt" className="room-types-full-container reports-new-background">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Cancelled KOT's</div>
          {reportData?.length > 0 && (
            <div className="flex flex_gap_15 align_items_center" id="pdf">
             <ButtonToDownloadTablePDF
                    itemToDownloadID={"receipt"}
                    downloadFileName={"Cancelled KOT's"}
                    ignoreFromPrint={'pdf'}
                    // pdfStyles={

                    // }
                  />
            <DownloadCSVButton  itemToDownloadID={"receipt"}
                    downloadFileName={"Cancelled KOT's"}/>
            </div>
          )}
        </div>

        {reportData && reportData.length > 0 ? (
          <>
            {/* <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={reportData.length}
                selectable={false}
                headings={[
                  { title: "KOT ID" },
                  { title: "Date" },
                  { title: "SubTotal" },
                  { title: "Tax Amount" },
                  { title: "Grand Total" },
                  { title: "Reason" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard> */}

            <CustomReportTable
              headings={["KOT ID", "Date", "SubTotal", "Tax Amount", "Grand Total", "Reason"]}
              // commonHeadings={[
              //   "",
              //   "",
              //   "",
              //   "Purchased",
              //   "Purchased",
              //   "Issued",
              //   "Issued",
              //   "Stock",
              //   "Stock",
              // ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "center",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {calculateTotals}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};
export default ReportCancelledKot;
