import React from "react";
import { useState, useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./RoomBill.css";
import LOGO from "../../../assets/images/logoG.png";
import { showErrorToast } from "../../../assets/toastUtils";
import { useSelector } from "react-redux";
import { BgOutlineThemeButton, BgThemeButton, CheckWithoutBgUnderlineBtn, WithoutBgButtonBlue } from "../../UI/Buttons";

const RoomBill = () => {


    const [showShareOnEmail, setShowShareOnEmail] = useState(false);
    const [showShareOnWhatsapp, setShowShareWhatsapp] = useState(false);
    const [bookingData, setBookingData] = useState({});
    const userToken = JSON.parse(localStorage.getItem("userToken"));

    const property_id = useSelector((state) => state.property.value);

    const [editableItems, setEditableItems] = useState([
        {
            refNo: 1234,
            description: "Burger",
            qty: 2,
            rate: 250,
            amount: 500,
            discount: 10,
            discountAmount: 450,
            taxRate: 5,
            netAmount: 472.5,
            debit: 472.5,
            credit: "",
            balance: 472.5,
        },
        {
            refNo: 1234,
            description: "Burger",
            qty: 2,
            rate: 250,
            amount: 500,
            discount: 10,
            discountAmount: 450,
            taxRate: 5,
            netAmount: 472.5,
            debit: 472.5,
            credit: "",
            balance: 472.5,
        },
    ]);

    const discountForSubTotal = "10%";
    const subTotal = editableItems
        .map((item) => parseFloat(item.balance))
        .filter((balance) => !isNaN(balance))
        .reduce((total, balance) => total + balance, 0);

    const grandTotal =
        subTotal - (subTotal * parseFloat(discountForSubTotal)) / 100;

    const [editMode, setEditMode] = useState(false);

    const handleEdit = (index, field, value) => {
        const newItems = [...editableItems];
        newItems[index][field] = value;
        setEditableItems(newItems);
    };

    const handleQuantityChange = (index, newQty) => {
        const newItems = [...editableItems];
        const item = { ...newItems[index] };
        item.qty = newQty;
        item.amount = parseFloat(newQty) * parseFloat(item.rate);
        item.discountAmount =
            item.amount - (item.amount * parseFloat(item.discount)) / 100;
        item.netAmount =
            item.discountAmount +
            (item.discountAmount * parseFloat(item.taxRate)) / 100;
        item.balance =
            item.discountAmount +
            (item.discountAmount * parseFloat(item.taxRate)) / 100;
        newItems[index] = item;
        setEditableItems(newItems);
    };

    const handleDiscountChange = (index, newDis) => {
        const newItems = [...editableItems];
        const item = { ...newItems[index] };
        item.discount = newDis;
        item.discountAmount =
            item.amount - (item.amount * parseFloat(newDis)) / 100;
        item.netAmount = item.discountAmount + (item.discountAmount * 5) / 100;

        item.balance = item.netAmount;

        newItems[index] = item;
        setEditableItems(newItems);
    };

    const handleRateChange = (index, newRate) => {
        const newItems = [...editableItems];
        const item = { ...newItems[index] };
        item.rate = newRate;
        item.amount = parseFloat(newRate) * parseFloat(item.qty);
        item.discountAmount =
            item.amount - (item.amount * parseFloat(item.discount)) / 100;

        item.netAmount =
            item.discountAmount * (parseFloat(item.taxRate) / 100) +
            item.discountAmount;
        item.balance =
            item.discountAmount * (parseFloat(item.taxRate) / 100) +
            item.discountAmount;
        newItems[index] = item;
        setEditableItems(newItems);
    };

    const handleTaxRateChange = (index, newTaxRate) => {
        const newItems = [...editableItems];
        const item = { ...newItems[index] };
        item.taxRate = parseFloat(newTaxRate);
        if (isNaN(item.taxRate)) {
            item.netAmount = 0;
        } else {
            if (!isNaN(item.discountAmount) && !isNaN(item.taxRate)) {
                item.netAmount =
                    item.discountAmount + (item.discountAmount * item.taxRate) / 100;
            } else {
                item.netAmount = 0;
            }
        }

        item.balance = item.netAmount;
        newItems[index] = item;
        setEditableItems(newItems);
    };
    const handleDebitChange = (index, newTaxRate) => {
        const newItems = [...editableItems];
        const item = { ...newItems[index] };

        if (newTaxRate > item.netAmount) {
            alert("Debit cannot be greater than net amount");
            return;
        }
        item.debit = newTaxRate;
        item.balance = newTaxRate;
        item.credit = 0;
        newItems[index] = item;
        setEditableItems(newItems);
    };

    const handleCreditChange = (index, newTaxRate) => {
        const newItems = [...editableItems];
        const item = { ...newItems[index] };
        if (newTaxRate > item.netAmount) {
            alert("Credit cannot be greater than net amount");
            return;
        }

        item.credit = newTaxRate;
        item.balance = item.netAmount - newTaxRate;
        item.debit = 0;
        newItems[index] = item;
        setEditableItems(newItems);
    };

    const handleUpdate = () => {
        setEditMode(false);
    };

    const handleAddRow = () => {
        const newItem = {
            refNo: "",
            description: "",
            qty: 0,
            rate: 0,
            amount: 0,
            taxRate: 0,
            debit: 0,
            credit: 0,
            balance: 0,
            netAmount: 0,
            remove: true,
        };

        const lastItem = editableItems[editableItems.length - 1];
        if (
            lastItem &&
            lastItem.refNo === "" &&
            lastItem.description === "" &&
            lastItem.qty === 0 &&
            lastItem.rate === 0 &&
            lastItem.amount === 0 &&
            lastItem.taxRate === 0 &&
            lastItem.debit === 0 &&
            lastItem.credit === 0 &&
            lastItem.balance === 0 &&
            lastItem.netAmount === 0
        ) {
            alert("Please fill all the fields");
        } else {
            setEditableItems([...editableItems, newItem]);
        }
    };

    const handleRemoveRow = (index) => {
        if (editableItems.length <= 1) {
            return;
        }
        const newItems = [...editableItems];
        newItems.splice(index, 1);
        setEditableItems(newItems);
    };

    const handlePrint = () => {
        if (!editMode) {
            window.print();
        } else {
            alert("Disable Edit Mode First");
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (editMode && event.ctrlKey && event.key === "p") {
                event.preventDefault();
                alert("Print shortcut is disabled in edit mode");
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [editMode]);

    const containerRef = useRef(null);
    const generatePdf = () => {
        const foodBillContent = containerRef.current;
        if (editMode) {
            alert("Disable Edit Mode First");
        } else {
            html2canvas(foodBillContent, {
                scale: 3,
            }).then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg", 1);
                const pdf = new jsPDF({
                    unit: "px",
                    format: [canvas.width + 40, canvas.height + 40],
                });
                pdf.addImage(imgData, "JPEG", 20, 20, canvas.width, canvas.height);
                pdf.save("food_bill.pdf");
            });
        }
    };

    const [settlementEditMode, setSettlementEditMode] = useState(false);
    const [settlementData, setSettlementData] = useState([
        { paymentOption: "upi", amount: 100 },
        { paymentOption: "credit card", amount: 200 },
    ]);

    const handleSettlementChange = (index, e) => {
        const { name, value } = e.target;
        if (!Array.isArray(settlementData)) {
            alert("Settlement data is not in the correct format.");
            return;
        }

        const updatedSettlementData = [...settlementData];
        updatedSettlementData[index][name] = value;

        const newTotal = updatedSettlementData.reduce((total, item) => {
            const amount = parseFloat(item.amount);
            return isNaN(amount) ? total : total + amount;
        }, 0);

        if (!isNaN(newTotal) && newTotal <= grandTotal) {
            setSettlementData(updatedSettlementData);
        } else {
            alert("Settlement amount exceeds the grand total!");
            updatedSettlementData[index].amount = 0;
            setSettlementData(updatedSettlementData);
        }
    };


    const handleUpdateInSettlement = () => {
        const lastItem = settlementData[settlementData.length - 1];

        if (lastItem.paymentOption !== "" && lastItem.amount !== 0) {
            const newTotal = settlementData.reduce((total, item) => {
                const amount = parseFloat(item.amount);
                return isNaN(amount) ? total : total + amount;
            }, 0);
            if (!isNaN(newTotal) && newTotal <= grandTotal) {
                setSettlementEditMode(false);
            } else {
            }
        } else {
            alert("Please fill all the fields");
        }
    };

    const handleAddRowInSettlement = () => {
        const lastItem = settlementData[settlementData.length - 1];
        const newItem = {
            paymentOption: "",
            amount: 0,
        };

        if (lastItem.paymentOption !== "" && lastItem.amount !== 0) {
            setSettlementData([...settlementData, newItem]);
        } else {
            alert("Please fill all the fields");
        }
    };

    const handleRemoveRowInSettlement = (index) => {
        if (settlementData.length <= 1) {
            return;
        }
        const newItems = [...settlementData];
        newItems.splice(index, 1);
        setSettlementData(newItems);
    };

    return (
        <div>
            <div className="main_container">
                <div style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
                    <div className="booking-choice-full-parent-container-left" style={{ width: "80%" }}>

                        <div className="flex flex_gap_10 justify_content_end mb-10">



                            {editMode ? (
                                <>
                                    <CheckWithoutBgUnderlineBtn onClick={handleUpdate}>Update</CheckWithoutBgUnderlineBtn>

                                    <CheckWithoutBgUnderlineBtn onClick={handleAddRow}>Add Row</CheckWithoutBgUnderlineBtn>
                                </>
                            ) : (
                                <>
                                    <CheckWithoutBgUnderlineBtn onClick={() => setEditMode(true)}>Edit Bill</CheckWithoutBgUnderlineBtn>
                                    <CheckWithoutBgUnderlineBtn
                                        onClick={handlePrint}
                                        children={"Print"}
                                    />

                                    <CheckWithoutBgUnderlineBtn
                                        icon={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="19"
                                                height="19"
                                                viewBox="0 0 19 19"
                                                fill="none"
                                            >
                                                <path
                                                    d="M9.50008 12.3566L5.92924 8.78579L6.92908 7.75025L8.78591 9.60708V3.78662H10.2142V9.60708L12.0711 7.75025L13.0709 8.78579L9.50008 12.3566ZM5.21508 15.2133C4.82229 15.2133 4.48591 15.0733 4.20596 14.7934C3.92601 14.5134 3.78627 14.1773 3.78674 13.785V11.6425H5.21508V13.785H13.7851V11.6425H15.2134V13.785C15.2134 14.1777 15.0734 14.5141 14.7935 14.7941C14.5135 15.074 14.1774 15.2138 13.7851 15.2133H5.21508Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        }
                                        children={"Download"}
                                        onClick={generatePdf}
                                    />
                                </>

                            )}



                        </div>
                        <div
                            style={{
                                // width: "750px",
                                margin: "0 auto",
                                padding: "20px",
                                background: "#fff",
                            }}
                            className="foodBillContent"
                            ref={containerRef}
                        >
                            <table
                                style={{
                                    width: "100%",
                                    fontFamily: "Ariel",
                                    borderCollapse: "collapse",
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "left" }}>
                                            <img src={LOGO} alt="error" width={"150px0px"} />
                                        </th>

                                        <th
                                            style={{
                                                textAlign: "right",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                            }}
                                        >
                                            <p
                                                style={{
                                                    width: "300px",

                                                    margin: "5px 0 5px auto",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                P:1234567890
                                            </p>
                                            <p
                                                style={{
                                                    width: "300px",
                                                    margin: "5px 0 5px auto",
                                                }}
                                            >
                                                W: www.ecohospitality.com
                                            </p>
                                            <p
                                                style={{
                                                    width: "300px",
                                                    fontSize: "10px",
                                                    fontWeight: "400",
                                                    margin: "0 0 0 auto",
                                                }}
                                            >
                                                Ground Floor, Gupta Niwas, Near Petrol Pump, Vikas Nagar
                                                Shimla, Himachal Pradesh, India 171009
                                            </p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th
                                            colSpan={2}
                                            style={{
                                                padding: "10px 0",
                                            }}
                                        >
                                            <div
                                                style={{ height: "0", borderTop: "0.5px solid #ccc" }}
                                            ></div>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr style={{ fontSize: "10px" }}>
                                        <td style={{ textAlign: "left" }}>
                                            <p style={{ fontWeight: "600" }}>DATE:</p>
                                            <p style={{ fontWeight: "500px" }}>12 Aug, 2024</p>
                                            <div style={{ margin: "10px 0" }}>
                                                <p style={{ fontWeight: "600" }}>INVOICE NO:</p>
                                                <p style={{ fontWeight: "500px" }}>123456</p>
                                            </div>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            <div>
                                                <div style={{ fontWeight: "500" }}>TO:</div>

                                                <span
                                                    style={{
                                                        fontWeight: "400px",
                                                        lineHeight: "15px",
                                                    }}
                                                >
                                                    John Smith <br />
                                                    7778889990 <br /> example@email.com <br />
                                                    Booking ID: 123456
                                                </span>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th
                                            colSpan={2}
                                            style={{
                                                padding: "10px 0",
                                            }}
                                        >
                                            <div
                                                style={{ height: "0", borderTop: "0.5px solid #ccc" }}
                                            ></div>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>

                            <table
                                style={{
                                    width: "100%",
                                    fontFamily: "Arial",
                                    borderCollapse: "collapse",
                                }}
                            >
                                <thead
                                    style={{
                                        textAlign: "left",
                                        fontWeight: "500",
                                        fontSize: "10px",
                                    }}
                                >
                                    <tr>
                                        <th
                                            style={{
                                                borderTop: "1px solid #333",
                                                borderLeft: "1px solid #333",
                                                background: "#ccc",
                                                padding: "10px",
                                            }}
                                        >
                                            S.No
                                        </th>
                                        <th
                                            style={{
                                                borderTop: "1px solid #333",
                                                background: "#ccc",
                                                padding: "10px",
                                            }}
                                        >
                                            Ref.No
                                        </th>
                                        <th
                                            style={{
                                                borderTop: "1px solid #333",
                                                background: "#ccc",
                                                padding: "10px",
                                            }}
                                        >
                                            Description
                                        </th>
                                        <th
                                            style={{
                                                borderTop: "1px solid #333",
                                                background: "#ccc",
                                                padding: "10px",
                                            }}
                                        >
                                            Qty
                                        </th>
                                        <th
                                            style={{
                                                borderTop: "1px solid #333",
                                                background: "#ccc",
                                                padding: "10px",
                                            }}
                                        >
                                            Rate
                                        </th>
                                        <th
                                            style={{
                                                borderTop: "1px solid #333",
                                                background: "#ccc",
                                                padding: "10px",
                                            }}
                                        >
                                            Amount
                                        </th>
                                        <th
                                            style={{
                                                borderTop: "1px solid #333",
                                                background: "#ccc",
                                                padding: "10px",
                                            }}
                                        >
                                            Discount
                                        </th>
                                        <th
                                            style={{
                                                borderTop: "1px solid #333",
                                                background: "#ccc",
                                                padding: "10px",
                                            }}
                                        >
                                            Discounted Amount
                                        </th>
                                        <th
                                            style={{
                                                borderTop: "1px solid #333",
                                                background: "#ccc",
                                                padding: "10px",
                                            }}
                                        >
                                            Tax Rate
                                        </th>
                                        <th
                                            style={{
                                                borderTop: "1px solid #333",
                                                background: "#ccc",
                                                padding: "10px",
                                            }}
                                        >
                                            Net Amount
                                        </th>
                                        <th
                                            style={{
                                                borderTop: "1px solid #333",
                                                background: "#ccc",
                                                padding: "10px",
                                            }}
                                        >
                                            Debit
                                        </th>
                                        <th
                                            style={{
                                                borderTop: "1px solid #333",
                                                background: "#ccc",
                                                padding: "10px",
                                            }}
                                        >
                                            Credit
                                        </th>
                                        <th
                                            style={{
                                                borderTop: "1px solid #333",
                                                borderRight: "1px solid #333",
                                                background: "#ccc",
                                                padding: "10px",
                                                textAlign: "right",
                                            }}
                                        >
                                            Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    style={{ textAlign: "left", fontWeight: "400", fontSize: "10px" }}
                                >
                                    {/* Map over billItems array to generate table rows */}
                                    {editableItems.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "5px",
                                                        borderLeft: "1px solid #333",
                                                        borderBottom:
                                                            index === editableItems.length - 1
                                                                ? "1px solid #333"
                                                                : null,
                                                    }}
                                                >
                                                    {editMode ? (
                                                        <input
                                                            type="text"
                                                            value={index + 1}
                                                            readOnly
                                                            style={{ width: "100%" }}
                                                        />
                                                    ) : (
                                                        index + 1
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "5px",
                                                        borderBottom:
                                                            index === editableItems.length - 1
                                                                ? "1px solid #333"
                                                                : null,
                                                    }}
                                                >
                                                    {editMode ? (
                                                        <input
                                                            type="text"
                                                            value={item.refNo}
                                                            onChange={(e) =>
                                                                handleEdit(index, "refNo", e.target.value)
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                    ) : (
                                                        item.refNo
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "5px",
                                                        borderBottom:
                                                            index === editableItems.length - 1
                                                                ? "1px solid #333"
                                                                : null,
                                                    }}
                                                >
                                                    {editMode ? (
                                                        <input
                                                            type="text"
                                                            value={item.description}
                                                            onChange={(e) =>
                                                                handleEdit(index, "description", e.target.value)
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                    ) : (
                                                        item.description
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "5px",
                                                        borderBottom:
                                                            index === editableItems.length - 1
                                                                ? "1px solid #333"
                                                                : null,
                                                    }}
                                                >
                                                    {editMode ? (
                                                        <input
                                                            type="number"
                                                            value={isNaN(item.qty) ? 0 : item.qty}
                                                            onChange={(e) =>
                                                                handleQuantityChange(index, e.target.value)
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                    ) : (
                                                        item.qty
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "5px",
                                                        borderBottom:
                                                            index === editableItems.length - 1
                                                                ? "1px solid #333"
                                                                : null,
                                                    }}
                                                >
                                                    {editMode ? (
                                                        <input
                                                            type="number"
                                                            value={isNaN(item.rate) ? 0 : item.rate}
                                                            onChange={(e) =>
                                                                handleRateChange(index, e.target.value)
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                    ) : (
                                                        item.rate
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "5px",
                                                        borderBottom:
                                                            index === editableItems.length - 1
                                                                ? "1px solid #333"
                                                                : null,
                                                    }}
                                                >
                                                    {editMode ? (
                                                        <input
                                                            type="number"
                                                            value={item.amount}
                                                            readOnly
                                                            style={{ width: "100%" }}
                                                        />
                                                    ) : (
                                                        item.amount
                                                    )}
                                                </td>

                                                <td
                                                    style={{
                                                        padding: "10px",
                                                        borderBottom:
                                                            index === editableItems.length - 1
                                                                ? "1px solid #333"
                                                                : null,
                                                    }}
                                                >
                                                    {editMode ? (
                                                        <input
                                                            type="number"
                                                            value={isNaN(item.discount) ? 0 : item.discount}
                                                            onChange={(e) =>
                                                                handleDiscountChange(index, e.target.value)
                                                            }
                                                            style={{
                                                                textAlign: "center",
                                                                width: "100%",
                                                            }}
                                                        />
                                                    ) : (
                                                        item.discount
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        padding: "10px",
                                                        borderBottom:
                                                            index === editableItems.length - 1
                                                                ? "1px solid #333"
                                                                : null,
                                                    }}
                                                >
                                                    {editMode ? (
                                                        <input
                                                            type="number"
                                                            value={
                                                                isNaN(item.discountAmount) ? 0 : item.discountAmount
                                                            }
                                                            readOnly
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />
                                                    ) : (
                                                        item.discountAmount
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "center",

                                                        padding: "5px",
                                                        borderBottom:
                                                            index === editableItems.length - 1
                                                                ? "1px solid #333"
                                                                : null,
                                                    }}
                                                >
                                                    {editMode ? (
                                                        <input
                                                            type="number"
                                                            value={
                                                                isNaN(item.taxRate) ? 0 : item.taxRate.toString()
                                                            }
                                                            onChange={(e) =>
                                                                handleTaxRateChange(index, e.target.value)
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                    ) : (
                                                        item.taxRate
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "center",

                                                        padding: "5px",
                                                        borderBottom:
                                                            index === editableItems.length - 1
                                                                ? "1px solid #333"
                                                                : null,
                                                    }}
                                                >
                                                    {editMode ? (
                                                        <input
                                                            disabled
                                                            type="number"
                                                            value={isNaN(item.netAmount) ? 0 : item.netAmount}
                                                            readOnly
                                                            style={{ width: "100%" }}
                                                        />
                                                    ) : (
                                                        item.netAmount
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "center",

                                                        padding: "5px",
                                                        borderBottom:
                                                            index === editableItems.length - 1
                                                                ? "1px solid #333"
                                                                : null,
                                                    }}
                                                >
                                                    {editMode ? (
                                                        <input
                                                            type="number"
                                                            value={isNaN(item.debit) ? 0 : item.debit}
                                                            onChange={(e) =>
                                                                handleDebitChange(index, e.target.value)
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                    ) : (
                                                        item.debit
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "5px",
                                                        borderBottom:
                                                            index === editableItems.length - 1
                                                                ? "1px solid #333"
                                                                : null,
                                                    }}
                                                >
                                                    {editMode ? (
                                                        <input
                                                            type="number"
                                                            value={isNaN(item.credit) ? 0 : item.credit}
                                                            onChange={(e) =>
                                                                handleCreditChange(index, e.target.value)
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                    ) : (
                                                        item.credit
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        padding: "5px",
                                                        textAlign: "right",
                                                        borderRight: "1px solid #333",
                                                        borderBottom:
                                                            index === editableItems.length - 1
                                                                ? "1px solid #333"
                                                                : null,
                                                    }}
                                                >
                                                    {editMode ? (
                                                        <input
                                                            type="number"
                                                            value={isNaN(item.balance) ? 0 : item.balance}
                                                            onChange={(e) =>
                                                                handleEdit(index, "balance", e.target.value)
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                    ) : (
                                                        item.balance
                                                    )}
                                                </td>

                                                {editMode && item.remove ? (
                                                    <td style={{ padding: "10px", textAlign: "center" }}>
                                                        <button onClick={() => handleRemoveRow(index)}>
                                                            Remove
                                                        </button>
                                                    </td>
                                                ) : null}
                                            </tr>
                                        );
                                    })}

                                    <tr>
                                        <td
                                            colSpan={12}
                                            style={{
                                                textAlign: "right",
                                                padding: "5px",
                                                borderTop: "1px solid #333",
                                                borderLeft: "1px solid #333",
                                            }}
                                        >
                                            SUB TOTAL
                                        </td>
                                        <td
                                            style={{
                                                padding: "5px",
                                                textAlign: "right",
                                                border: "1px solid #333",
                                                background: "#ccc",
                                            }}
                                        >
                                            {subTotal}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            colSpan={12}
                                            style={{
                                                textAlign: "right",
                                                padding: "5px",
                                                borderLeft: "1px solid #333",
                                            }}
                                        >
                                            DISCOUNT
                                        </td>
                                        <td
                                            style={{
                                                padding: "5px",
                                                textAlign: "right",
                                                border: "1px solid #333",
                                                background: "#ccc",
                                            }}
                                        >
                                            {discountForSubTotal}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            colSpan={12}
                                            style={{
                                                textAlign: "right",
                                                padding: "10px",
                                                borderLeft: "1px solid #333",
                                                borderBottom: "1px solid #333",
                                            }}
                                        >
                                            GRAND TOTAL
                                        </td>
                                        <td
                                            style={{
                                                padding: "10px",
                                                textAlign: "right",
                                                border: "1px solid #333",
                                                background: "#ccc",
                                            }}
                                        >
                                            {grandTotal}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table
                                style={{
                                    borderCollapse: "collapse",
                                    margin: "20px 0",
                                    width: "100%",
                                    fontFamily: "Arial, sans-serif",
                                }}
                            >
                                <thead>
                                    <tr
                                        style={{ textAlign: "left", fontWeight: "400", fontSize: "10px" }}
                                    >
                                        <td colSpan={2} style={{ padding: "10px 0" }}>
                                            Transaction
                                        </td>
                                    </tr>
                                </thead>
                                <tbody
                                    style={{ textAlign: "left", fontWeight: "400", fontSize: "10px" }}
                                >
                                    {settlementData.map((item, index) => (
                                        <tr key={index}>
                                            <td
                                                style={{
                                                    textAlign: "left",
                                                    padding: "10px",
                                                    border: "1px solid #333",
                                                    borderRight: "none",
                                                }}
                                            >
                                                {settlementEditMode ? (
                                                    <select
                                                        name="paymentOption"
                                                        value={item.paymentOption}
                                                        onChange={(e) => handleSettlementChange(index, e)}
                                                    >
                                                        <option value={"upi"}>UPI </option>
                                                        <option value={"online"}>Online</option>
                                                        <option value={"debit card"}>Debit Card</option>
                                                        <option value={"credit card"}>Credit Card</option>
                                                    </select>
                                                ) : (
                                                    item.paymentOption
                                                )}
                                            </td>
                                            <td
                                                style={{
                                                    textAlign: "right",
                                                    padding: "10px",
                                                    border: "1px solid #333",
                                                    borderLeft: "none",
                                                }}
                                            >
                                                {settlementEditMode ? (
                                                    <input
                                                        value={item.amount}
                                                        name="amount"
                                                        onChange={(e) => handleSettlementChange(index, e)}
                                                        style={{ width: "30%" }}
                                                    />
                                                ) : (
                                                    item.amount
                                                )}
                                            </td>

                                            {settlementEditMode &&
                                                index === settlementData.length - 1 &&
                                                index !== 0 ? (
                                                <td>
                                                    <button onClick={() => handleRemoveRowInSettlement(index)}>
                                                        Remove
                                                    </button>
                                                </td>
                                            ) : null}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {settlementEditMode ? (
                                <button onClick={handleUpdateInSettlement}>update</button>
                            ) : (
                                <button onClick={() => setSettlementEditMode(true)}>edit</button>
                            )}

                            {settlementEditMode ? (
                                <button onClick={handleAddRowInSettlement}>Add Row</button>
                            ) : null}

                            <table
                                style={{
                                    border: "1px solid #333",
                                    width: "100%",
                                    borderCollapse: "collapse",
                                    borderBottom: "none",
                                }}
                            >
                                <tbody
                                    style={{ textAlign: "left", fontWeight: "400", fontSize: "10px" }}
                                >
                                    <tr style={{ background: "#ccc" }}>
                                        <td style={{ padding: "10px" }}>HSN Code</td>
                                        <td style={{ padding: "10px" }}>Description</td>
                                        <td style={{ padding: "10px" }}>Tax Rate</td>
                                        <td style={{ padding: "10px" }}>Taxable Amt. </td>
                                        <td style={{ padding: "10px" }}>CGST</td>
                                        <td style={{ padding: "10px" }}>SGST</td>
                                        <td style={{ padding: "10px" }}>VAT</td>
                                        <td style={{ padding: "10px", textAlign: "right" }}>
                                            Total Amt.
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ padding: "10px" }}>12334</td>
                                        <td style={{ padding: "10px" }}>Room Rent</td>
                                        <td style={{ padding: "10px" }}>12%</td>
                                        <td style={{ padding: "10px" }}>10000</td>
                                        <td style={{ padding: "10px" }}>600</td>
                                        <td style={{ padding: "10px" }}>600</td>
                                        <td style={{ padding: "10px" }}>000</td>
                                        <td style={{ padding: "10px", textAlign: "right" }}>11000</td>
                                    </tr>
                                    <tr
                                        style={{
                                            borderTop: "1px solid #333",
                                            borderLeft: "1px solid #fff",
                                        }}
                                    >
                                        <td colSpan={2}></td>
                                        <td style={{ padding: "10px" }}>Grand Total:</td>
                                        <td style={{ padding: "10px" }}>11500</td>
                                        <td style={{ padding: "10px" }}>685</td>
                                        <td style={{ padding: "10px" }}>660</td>
                                        <td style={{ padding: "10px" }}>00</td>
                                        <td
                                            style={{
                                                padding: "10px",
                                                textAlign: "right",
                                                borderRight: "1px solid #fff",
                                            }}
                                        >
                                            12457
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table
                                style={{
                                    borderCollapse: "collapse",
                                    borderBottom: "none",
                                    fontFamily: "Ariel",
                                    width: "100%",
                                    margin: "20px 0"
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <td colSpan={2} style={{ textAlign: "center", fontSize: "13px" }}>
                                            Thank you for being with us!
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            colSpan={2}
                                            style={{
                                                textAlign: "center",
                                                fontSize: "9px",
                                                padding: "15px 0",
                                            }}
                                        >
                                            Bill verified & recommended for payment. I agree to be held
                                            personally liable for payment of this bill regardless of charges
                                            & instructions
                                        </td>
                                    </tr>

                                    <tr style={{ fontSize: "12px" }}>
                                        <td style={{ textAlign: "left" }}>Guest Copy</td>
                                        <td style={{ textAlign: "right" }}>Guest Signature</td>
                                    </tr>
                                    <tr>
                                        <td
                                            colSpan={2}
                                            style={{ textAlign: "center", paddingTop: "40px" }}
                                        >
                                            <img src={LOGO} alt="error" width={"150px0px"} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    {/* <div className="booking-choice-full-parent-container-right " style={{ width: "20%" }}>
                        <div className="share_preview_bill">
                            <h2>
                                <strong>Share on:</strong>
                            </h2>
                            <div
                                style={{ padding: "0" }}
                                className="share_preview_bill_child"
                            // onClick={handleShowShareOnEmail}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                >
                                    <circle cx="15" cy="15" r="15" fill="#3968ED" />
                                    <path
                                        d="M8 11.6608V18.9313C8 19.5232 8.47984 20.003 9.07175 20.003H21.2183C21.8102 20.003 22.29 19.5232 22.29 18.9313V11.6607L15.505 15.6187C15.2825 15.7485 15.0074 15.7485 14.7849 15.6187L8 11.6608Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M21.8922 10.2384C21.7081 10.0893 21.4736 10 21.2183 10H9.07175C8.81636 10 8.58183 10.0893 8.39771 10.2384L15.1449 14.1743L21.8922 10.2384Z"
                                        fill="white"
                                    />
                                </svg>
                                <h3>Edit</h3>
                            </div>
                            <div
                                className="hideOnPrint"
                            >
                                {editMode ? (
                                    <>
                                        <button onClick={handleUpdate}>Update</button>
                                        <button onClick={handleAddRow}>Add Row</button>
                                    </>
                                ) : (
                                    <button onClick={() => setEditMode(true)}>Edit</button>
                                )}
                            </div>

                            <div

                                className="hideOnPrint"
                            >
                                <button onClick={handlePrint} className="hideOnPrint">
                                    print
                                </button>
                                <BgThemeButton
                                    // icon={
                                    //     <svg
                                    //         xmlns="http://www.w3.org/2000/svg"
                                    //         width="18"
                                    //         height="19"
                                    //         viewBox="0 0 18 19"
                                    //         fill="none"
                                    //     >
                                    //         <path
                                    //             d="M13.2851 5.92918H4.71507V3.07251H13.2851V5.92918ZM13.2851 9.85709C13.4874 9.85709 13.6571 9.78853 13.7943 9.65141C13.9314 9.51429 13.9997 9.3448 13.9992 9.14293C13.9992 8.94058 13.9307 8.77085 13.7936 8.63373C13.6564 8.49661 13.4869 8.42828 13.2851 8.42876C13.0827 8.42876 12.913 8.49732 12.7759 8.63444C12.6387 8.77156 12.5704 8.94106 12.5709 9.14293C12.5709 9.34527 12.6395 9.51501 12.7766 9.65213C12.9137 9.78925 13.0832 9.85757 13.2851 9.85709ZM11.8567 14.4992V11.6425H6.1434V14.4992H11.8567ZM13.2851 15.9275H4.71507V13.0708H1.8584V8.78584C1.8584 8.1788 2.0667 7.66984 2.48329 7.25896C2.89989 6.84807 3.40576 6.64287 4.0009 6.64334H13.9992C14.6063 6.64334 15.1152 6.84878 15.5261 7.25967C15.937 7.67055 16.1422 8.17928 16.1417 8.78584V13.0708H13.2851V15.9275Z"
                                    //             fill="#3968ED"
                                    //         />
                                    //     </svg>
                                    // }
                                    onClick={generatePdf}
                                    children={"Print"}
                                />
                                <button className="hideOnPrint" onClick={generatePdf}>
                                    download as pdf
                                </button>
                            </div>



                        </div>
                    </div> */}
                </div>
            </div>

        </div>
    );
};

export default RoomBill;
