import React, { useState } from "react";
import SidebarInnerLists from "./SidebarInnerLists";
import {
  other_select_nav_items_inventory,
  rooms_inner_lists,
} from "../../assets/constant";
import NoDataFound from "./NoDataFound";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  WithoutBgButtonBlue,
} from "../UI/Buttons";
import { Tooltip } from "@shopify/polaris";
import { Link } from "react-router-dom";
import { showErrorToast } from "../../assets/toastUtils";
import Loader from "../UI/Loader/Loader";
import VerticalInputSelectCustomCopy from "./VerticalInputSelectCustomCopy";
import CustomDateRangeSelector from "./CustomDateRangeSelector";
import DateRangePickerNew from "./CreateBookingNewPage/DateRangePickerNew/DateRangePickerNew";
import DateRangePicker from "./SelectDateRange/DateRangePicker";
import SelectDatePickerCustom from "./SelectDateRange/SelectDatePickerCustom";
import SelectDateRange from "./SelectDateRange/SelectDateRange";
import VerticalInputFieldCustom from "./VerticalInputFieldCustom";
import SearchComponent from "./SearchComponent/SearchComponent";
import HoldRoomsAgentsPopup from "./AgentHoldRooms/HoldRoomsAgentsPopup";
import CreateContract from "./AgentHoldRooms/CreateContract";
import ReleaseRoomsManualAgentsPopup from "./AgentHoldRooms/ReleaseRoomsManualAgentsPopup";
import AgentHoldRoomsSliderSideBar from "./AgentHoldRooms/AgentHoldRoomsSliderSideBar";
import AgentHoldRoomsUpliftMenuButton from "./AgentHoldRooms/AgentHoldRoomsUpliftMenuButton/AgentHoldRoomsUpliftMenuButton";
import MoreOptionsDropDown from "./AgentHoldRooms/MoreOptionsDropDown/MoreOptionsDropDown";

const HoldRoomPlansComp = () => {
  const [loader, setLoader] = useState(false);

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const [bookings, setbookings] = useState([]);
  const groupByCategory = bookings?.reduce((group, booking) => {
    const { filteredDate } = booking;
    group[filteredDate] = group[filteredDate] ?? [];
    group[filteredDate].push(booking);
    return group;
  }, {});

  // =====  hold rooms popup ========
  const [holdRoomPopup, setHoldRoomPopup] = useState(false);
  const [checkedCategories, setCheckedCategories] = useState({});
  const handleHoldRoomsButtonClick = () => {
    setHoldRoomPopup(true);
  };
  const [selectedAgent, setSelectedAgent] = useState("contracted");
  const handleRadioAgentChange = (agentType) => {
    setSelectedAgent(agentType);
  };

  const handleDateChange = (form_date, to_date) => {};
  const popupStyles = {
    container: {
      width: "700px",
      borderRadius: "8px",
      background: "#fff",
      border: " 0.5px solid #ccc",
    },
    containerChild: {
      padding: "10px",
    },
    table: {
      width: "70%",
      textAlign: "left",
      borderSpacing: "0px 5px",
      marginTop: "10px",
    },
    tableBig: {
      width: "100%",
      textAlign: "left",
      borderSpacing: "0px 5px",
      marginTop: "10px",
    },
    popupHeadings: {
      color: "#333",
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: "600",
      margin: "10px 0",
    },
    categoryHeading: {
      color: "#333",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "600",
      margin: "10px 0 ",
    },
  };

  const roomCategories = [
    "Deluxe",
    "Super Deluxe",
    "Luxury",
    "Executive",
    "Family Suite",
  ];
  const handleCheckboxChange = (category) => {
    setCheckedCategories((prev) => {
      const newCheckedCategories = { ...prev, [category]: !prev[category] };

      // Call API if checkbox is checked
      if (newCheckedCategories[category]) {
        // Replace the below line with your API call
        console.log(`API called for ${category}`);
        // Assuming the API returns a value for the input, you can set it in the state
        newCheckedCategories[`${category}Input`] = "API response value";
      } else {
        // Remove the input value if unchecked
        delete newCheckedCategories[`${category}Input`];
      }

      return newCheckedCategories;
    });
  };

  const [selectedRelease, setSelectedRelease] = useState("auto_release");

  const handleReleaseRadioChange = (name) => {
    setSelectedRelease(name);
  };

  const autoReleaseOptions = [
    {
      label: "D7",
      value: "100%",
    },
    {
      label: "D5",
      value: "50%",
    },
    {
      label: "D3",
      value: "0",
    },
  ];

  // const HoldRoomsAgentsPopup = () => {
  //   return (
  //     <div style={popupStyles.container}>
  //       <div style={popupStyles.containerChild}>
  //         <h4 style={popupStyles.popupHeadings}>Hold Rooms</h4>
  //         <div className="flex flex_gap_15">
  //           <label className="flex flex_gap_5 align_items_center pointer">
  //             <input
  //               name="agentType"
  //               type="radio"
  //               checked={selectedAgent === "contracted"}
  //               onClick={() => handleRadioAgentChange("contracted")}
  //             />
  //             <span> Contracted Agent</span>
  //           </label>
  //           <label className="flex flex_gap_5 align_items_center pointer">
  //             <input
  //               name="agentType"
  //               type="radio"
  //               checked={selectedAgent === "general"}
  //               onClick={() => handleRadioAgentChange("general")}
  //             />
  //             <span>General Agent</span>
  //           </label>
  //         </div>

  //         <div>
  //           {selectedAgent === "contracted" ? (
  //             <>
  //               <table style={popupStyles.table}>
  //                 <thead>
  //                   <tr>
  //                     <th style={{ paddingRight: "5px" }}>Select Agent</th>
  //                     <th style={{ paddingLeft: "5px" }}>Select Date Range</th>
  //                   </tr>
  //                 </thead>
  //                 <tbody>
  //                   <tr>
  //                     <td style={{ paddingRight: "5px" }}>
  //                       <VerticalInputSelectCustomCopy />
  //                     </td>
  //                     <td style={{ paddingLeft: "5px" }}>
  //                       <SelectDateRange dataFromChild={handleDateChange} />
  //                     </td>
  //                   </tr>
  //                 </tbody>
  //               </table>
  //             </>
  //           ) : (
  //             <>
  //               <table style={popupStyles.tableBig}>
  //                 <thead>
  //                   <tr>
  //                     <th style={{ paddingRight: "5px" }}>Company Name</th>
  //                     <th style={{ padding: "0 5px" }}>Phone (optional)</th>
  //                     <th style={{ paddingLeft: "5px" }}>Select Date Range</th>
  //                   </tr>
  //                 </thead>
  //                 <tbody>
  //                   <tr>
  //                     <td style={{ paddingRight: "5px" }}>
  //                       <SearchComponent />
  //                     </td>
  //                     <td style={{ padding: "0 5px" }}>
  //                       <VerticalInputFieldCustom />
  //                     </td>
  //                     <td style={{ paddingLeft: "5px" }}>
  //                       <SelectDateRange dataFromChild={handleDateChange} />
  //                     </td>
  //                   </tr>
  //                 </tbody>
  //               </table>
  //             </>
  //           )}
  //         </div>
  //         {/* ===========room Categories=========== */}
  //         <div>
  //           <h4 style={popupStyles.categoryHeading}>Select Room Categories</h4>
  //           {roomCategories.map((item, index) => (
  //             <div
  //               key={index}
  //               style={{ height: "40px" }}
  //               className="flex justify_content_between align_items_center"
  //             >
  //               <div>
  //                 <label className="flex flex_gap_10 pointer">
  //                   <input
  //                     type="checkbox"
  //                     checked={!!checkedCategories[item]}
  //                     onChange={() => handleCheckboxChange(item)}
  //                   />{" "}
  //                   <span>{item}</span>
  //                 </label>
  //               </div>
  //               <div>
  //                 {checkedCategories[item] && (
  //                   <div className="flex flex_gap_15">
  //                     <div className="flex flex_gap_5 align_items_center">
  //                       <span>Avl. Rooms</span>
  //                       <VerticalInputFieldCustom
  //                         disabled={true}
  //                         style={{ width: "40px", height: "20px" }}
  //                       />
  //                     </div>

  //                     <div className="flex flex_gap_5 align_items_center">
  //                       <span>Hold Rooms</span>
  //                       <VerticalInputFieldCustom
  //                         style={{ width: "40px", height: "20px" }}
  //                         type="text"
  //                         value={checkedCategories[`${item}Input`] || ""}
  //                         readOnly
  //                       />
  //                     </div>
  //                   </div>
  //                 )}
  //               </div>
  //             </div>
  //           ))}
  //         </div>
  //       </div>
  //       <hr />
  //       <div style={popupStyles.containerChild}>
  //         <div className="flex justify_content_between">
  //           <div className="flex flex_gap_15">
  //             <label className="flex flex_gap_5 align_items_center pointer">
  //               <input
  //                 name="releaseRooms"
  //                 type="radio"
  //                 checked={selectedRelease === "auto_release"}
  //                 onClick={() => handleReleaseRadioChange("auto_release")}
  //               />
  //               <span>Auto Release</span>
  //             </label>
  //             <label className="flex flex_gap_5 align_items_center pointer">
  //               <input
  //                 name="releaseRooms"
  //                 type="radio"
  //                 checked={selectedRelease === "manual_release"}
  //                 onClick={() => handleReleaseRadioChange("manual_release")}
  //               />
  //               <span>Manual Release</span>
  //             </label>
  //           </div>
  //           {selectedRelease === "auto_release" && (
  //             // <div>

  //             <div className="flex flex_gap_10">
  //               {autoReleaseOptions.map((item, index) => (
  //                 <div
  //                   className="flex flex_gap_5 align_items_center"
  //                   key={index}
  //                 >
  //                   <span>{item.label}</span>
  //                   <VerticalInputFieldCustom
  //                     style={{ width: "60px", height: "20px" }}
  //                     type="text"
  //                     value={item?.value}
  //                     disabled={true}
  //                   />
  //                 </div>
  //               ))}
  //             </div>
  //             // </div>
  //           )}
  //         </div>

  //         <div className="flex flex_gap_10" style={{ marginTop: "20px" }}>
  //           <BgThemeButton children={"Add"} />
  //           <BgOutlineThemeButton children={"Cancel"} />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // release popup open when  3 dots in the

  // const ReleaseRoomsManualAgentsPopup = () => {
  //   return (
  //     <div style={popupStyles.container}>
  //       <div style={popupStyles.containerChild}>
  //         <h4 style={popupStyles.popupHeadings}>Release Rooms</h4>

  //         <div>
  //           <table style={popupStyles.tableBig}>
  //             <thead>
  //               <tr>
  //                 <th style={{ paddingRight: "5px" }}>Company Name</th>
  //                 <th style={{ padding: "0 5px" }}>Phone (optional)</th>
  //                 <th style={{ paddingLeft: "5px" }}>Select Date Range</th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               <tr>
  //                 <td style={{ paddingRight: "5px" }}>
  //                   <SearchComponent />
  //                 </td>
  //                 <td style={{ padding: "0 5px" }}>
  //                   <VerticalInputFieldCustom />
  //                 </td>
  //                 <td style={{ paddingLeft: "5px" }}>
  //                   <SelectDateRange dataFromChild={handleDateChange} />
  //                 </td>
  //               </tr>
  //             </tbody>
  //           </table>
  //         </div>
  //         {/* ===========room Categories=========== */}
  //         <div>
  //           <h4 style={popupStyles.categoryHeading}>Select Room Categories</h4>
  //           {roomCategories.map((item, index) => (
  //             <div
  //               key={index}
  //               style={{ height: "40px" }}
  //               className="flex justify_content_between align_items_center"
  //             >
  //               <div>
  //                 <label className="flex flex_gap_10 pointer">
  //                   <input
  //                     type="checkbox"
  //                     checked={!!checkedCategories[item]}
  //                     onChange={() => handleCheckboxChange(item)}
  //                   />{" "}
  //                   <span>{item}</span>
  //                 </label>
  //               </div>
  //               <div>
  //                 {checkedCategories[item] && (
  //                   <div className="flex flex_gap_15">
  //                     <div className="flex flex_gap_5 align_items_center">
  //                       <span>Avl. Rooms</span>
  //                       <VerticalInputFieldCustom
  //                         disabled={true}
  //                         style={{ width: "40px", height: "20px" }}
  //                       />
  //                     </div>

  //                     <div className="flex flex_gap_5 align_items_center">
  //                       <span>Hold Rooms</span>
  //                       <VerticalInputFieldCustom
  //                         style={{ width: "40px", height: "20px" }}
  //                         type="text"
  //                         value={checkedCategories[`${item}Input`] || ""}
  //                         readOnly
  //                       />
  //                     </div>
  //                   </div>
  //                 )}
  //               </div>
  //             </div>
  //           ))}
  //         </div>
  //         <div className="flex flex_gap_10" style={{ marginTop: "20px" }}>
  //           <BgThemeButton children={"Update"} />
  //           <BgOutlineThemeButton children={"Cancel"} />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const releaseOptionsPercent = [
    { label: "select", value: "" },
    { label: "100%", value: "100%" },
    { label: "75%", value: "75%" },
    { label: "50%", value: "50%" },
    { label: "25%", value: "25%" },
    { label: "0%", value: "0%" },
  ];

  // const CreateContract = () => {
  //   return (
  //     <div style={popupStyles.container}>
  //       <div style={popupStyles.containerChild}>
  //         <h4 style={popupStyles.popupHeadings}>Release Rooms</h4>

  //         <div>
  //           <table style={popupStyles.tableBig}>
  //             <thead>
  //               <tr>
  //                 <th style={{ paddingRight: "5px" }}>Select Agent</th>
  //                 <th style={{ padding: "0 5px" }}>Start Date</th>
  //                 <th style={{ paddingLeft: "5px" }}>End Date</th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               <tr>
  //                 <td style={{ paddingRight: "5px" }}>
  //                   <VerticalInputSelectCustomCopy />
  //                 </td>
  //                 <td style={{ padding: "0 5px" }}>
  //                   <VerticalInputFieldCustom type={"date"} />
  //                 </td>
  //                 <td style={{ paddingLeft: "5px" }}>
  //                   <VerticalInputFieldCustom type={"date"} />
  //                 </td>
  //               </tr>
  //             </tbody>
  //           </table>
  //         </div>
  //         {/* ===========room Categories=========== */}
  //         <div>
  //           <h4 style={popupStyles.categoryHeading}>Select Room Categories</h4>
  //           {roomCategories.map((item, index) => (
  //             <div
  //               key={index}
  //               style={{ height: "40px" }}
  //               className="flex justify_content_between align_items_center"
  //             >
  //               <div>
  //                 <label className="flex flex_gap_10 pointer">
  //                   <input
  //                     type="checkbox"
  //                     checked={!!checkedCategories[item]}
  //                     onChange={() => handleCheckboxChange(item)}
  //                   />{" "}
  //                   <span>{item}</span>
  //                 </label>
  //               </div>
  //               <div>
  //                 {checkedCategories[item] && (
  //                   <div className="flex flex_gap_15">
  //                     <div className="flex flex_gap_5 align_items_center">
  //                       <span>Avl. Rooms</span>
  //                       <VerticalInputFieldCustom
  //                         disabled={true}
  //                         style={{ width: "40px", height: "20px" }}
  //                       />
  //                     </div>

  //                     <div className="flex flex_gap_5 align_items_center">
  //                       <span>Hold Rooms</span>
  //                       <VerticalInputFieldCustom
  //                         style={{ width: "40px", height: "20px" }}
  //                         type="text"
  //                         value={checkedCategories[`${item}Input`] || ""}
  //                         readOnly
  //                       />
  //                     </div>
  //                   </div>
  //                 )}
  //               </div>
  //             </div>
  //           ))}
  //         </div>
  //       </div>
  //       <hr />

  //       <div style={popupStyles.containerChild}>
  //         <div className="flex justify_content_between align_items_center">
  //           <div
  //             className="flex flex_gap_10 align_items_center"
  //             style={{ width: "200px" }}
  //           >
  //             <h4 style={popupStyles.categoryHeading}>Rooms/Day</h4>
  //             <VerticalInputFieldCustom />
  //           </div>

  //           <div className="flex flex_gap_10  align_items_center">
  //             <div style={popupStyles.categoryHeading}>
  //               Release Restrictions :
  //             </div>
  //             <div  className="flex flex_gap_5 align_items_center" >
  //               <label>D-7</label>
  //               <VerticalInputSelectCustomCopy
  //                 options={releaseOptionsPercent}
  //               />
  //             </div>

  //             <div  className="flex flex_gap_5 align_items_center">
  //               <label>D-5</label>
  //               <VerticalInputSelectCustomCopy
  //                 options={releaseOptionsPercent}
  //               />
  //             </div>

  //             <div  className="flex flex_gap_5 align_items_center">
  //               <label>D-3</label>
  //               <VerticalInputSelectCustomCopy
  //                 options={releaseOptionsPercent}
  //               />
  //             </div>
  //           </div>
  //         </div>

  //         <div className="flex flex_gap_10" style={{ marginTop: "20px" }}>
  //           <BgThemeButton children={"Add"} />
  //           <BgOutlineThemeButton children={"Cancel"} />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };


  // =============side bar ======== 
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  // uplift menu component
  const options = ["Create Booking", "View Details", "Release Rooms", "Delete"];

  
  const handleUpliftMenuClick = (option) => {
    console.log(option)
  }


  return (
    <>
      <SidebarInnerLists
        innerLists={rooms_inner_lists}
        selectAtLast={true}
        other_select_nav_items_inventory={other_select_nav_items_inventory}
        firstSelected={"Inventory"}
        firstSelectedPermission={"read_inventory"}
        firstSelectedLink={"/inventory"}
        zIndex="100 "
      />
      <div className="room-types-full-container">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Agent Hold Rooms</div>
          <div className="flex flex_gap_20">
            {permissions && permissions.includes("create_room_types") ? (
              <>
                <Link style={{ textDecoration: "none" }} to={`/holdroom/add`}>
                  <BgOutlineThemeButton children={"Contracts"} />
                </Link>
                {/* <Link style={{ textDecoration: "none" }} to={`/roomstype/add`}> */}
                <BgThemeButton
                  children={"Hold Rooms"}
                  onClick={handleHoldRoomsButtonClick}
                />
                {/* </Link> */}
              </>
            ) : (
              <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                <BgThemeButtonDisabled children={"Hold"} />
              </Tooltip>
            )}

            {/* <BgThemeButton
                  onClick={() => setSyncOrderPopup(true)}
                  children={"Sync Order"}
                /> */}
          </div>
        </div>

        <div className="booking-choice-full-parent-container-right">
          <div className="booking-table-full-container" style={{}}>
            <div
              style={{ cursor: "default" }}
              className={`booking-second-row-head-new }`}
            >
              <div
                style={{ cursor: "default" }}
                className="booking-second-row-head-divs"
              >
                Compnay Name
              </div>
              <div
                style={{ cursor: "default" }}
                className="booking-second-row-head-divs"
              >
                Hold Rooms
              </div>
              {/* <div
                  style={{ cursor: "default" }}
                  className="booking-second-row-head-divs"
                >
                  Booking Date
                </div> */}
              <div
                style={{ cursor: "default" }}
                className="booking-second-row-head-divs"
              >
                Room Categories
              </div>
              <div
                style={{ cursor: "default" }}
                className="booking-second-row-head-divs"
              >
                Converted
              </div>

              <div
                style={{ cursor: "default" }}
                className="booking-second-row-head-divs"
              >
                Remaining
              </div>
              <div
                style={{ cursor: "default" }}
                className="booking-second-row-head-divs"
              >
                Hold Start Date
              </div>
              <div
                style={{ cursor: "default" }}
                className="booking-second-row-head-divs"
              >
                Hold End Date
              </div>
              <div
                style={{ cursor: "default" }}
                className="booking-second-row-head-divs"
              >
                Relase Type
              </div>
              <div
                style={{ cursor: "default", width: "3%" }}
                className="booking-second-row-head-divs"
              >
                {/* Booking Status */}
              </div>
            </div>

            <div
              className="booking-third-row-head-container "
              // ref={contentRef}
              style={{
                // maxHeight: "250px",
                minHeight: "273px",
                height: "auto",
                padding: "0 10px",
                // overflow: "auto",
                // overflowX: "auto",
                // overflowY: "visible",
              }}
              // style={scrollStyle}
            >
              {loader ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <>
                  {groupByCategory && bookings.length > 0 ? (
                    <>
                      <div
                      //         style={{
                      //   maxHeight: "500px",
                      //   // minHeight: "273px",
                      //   height: "auto",
                      //   padding: "0 10px",
                      //   overflow: "auto",
                      // }}
                      >
                        {Object.keys(groupByCategory).map((key, index) => {
                          const formattedCheckInDate = new Date(
                            key
                          ).toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "short",
                          });

                          const isLastFive =
                            index >= Object.keys(groupByCategory).length - 1;
                          return (
                            <div key={key}>
                              <div
                                style={{
                                  fontWeight: "600",
                                  margin: "10px 0 2px 0",
                                }}
                              >
                                {formattedCheckInDate}
                              </div>
                              <div>
                                {groupByCategory[key].map((item, index) => {
                                  const formattedCheckInDate = new Date(
                                    item.check_in
                                  ).toLocaleDateString("en-US", {
                                    day: "numeric",
                                    month: "short",
                                  });

                                  const formattedCheckOutDate = new Date(
                                    item.check_out
                                  ).toLocaleDateString("en-US", {
                                    day: "numeric",
                                    month: "short",
                                  });

                                  // calculate last five items

                                  return (
                                    <React.Fragment key={item.id}>
                                      {/* <p>{formattedCheckInDate} | 1 Check-in</p> */}
                                      <div className="booking-third-row-head">
                                        <div className="booking-third-row-one-full-div">
                                          {/* place */}
                                          {/* {showCheckedInFeature &&
                                              uniqueBookingId ===
                                              item.unique_booking_id && ( */}
                                          <div
                                            className="booking-popups-three-dots"
                                            style={{
                                              bottom: isLastFive
                                                ? "35px"
                                                : "-71px",
                                            }}
                                            // ref={clickOutSideRef}
                                          >
                                            <button
                                            // onClick={() =>
                                            //   getSingleBookingDataHandler(
                                            //     item.unique_booking_id
                                            //   )
                                            // }
                                            >
                                              View Details
                                            </button>
                                            {item.assignedrooms.length < 1 ? (
                                              <button
                                                onClick={() =>
                                                  showErrorToast(
                                                    "Please assign the room first...!!"
                                                  )
                                                }
                                              >
                                                View Folio{" "}
                                              </button>
                                            ) : (
                                              <button
                                                onClick={() => [
                                                  // navigate(
                                                  //   `/bookings/folio/${item.id}/${item.folioId}`
                                                  // ),
                                                  localStorage.removeItem(
                                                    "selectedFolioValue",
                                                    ""
                                                  ),
                                                  localStorage.removeItem(
                                                    "selectedFolioLabel",
                                                    ""
                                                  ),
                                                ]}
                                              >
                                                View Folio
                                              </button>
                                            )}

                                            {permissions &&
                                            permissions.includes(
                                              "show_preview_bill"
                                            ) ? (
                                              <button
                                              // onClick={(e) =>
                                              //   handleSubmitPreview(
                                              //     e,
                                              //     "voucher",
                                              //     "preview",
                                              //     "Booking Voucher",
                                              //     item?.id
                                              //   )
                                              // }
                                              // onClick={(e) =>
                                              //   handleSubmitPreviewVoucher(
                                              //     e,
                                              //     "voucher",
                                              //     "preview",
                                              //     "Booking Voucher",
                                              //     item?.unique_booking_id
                                              //   )
                                              // }
                                              >
                                                View Voucher
                                              </button>
                                            ) : (
                                              <Tooltip
                                                content={`Please contact the admin.`}
                                                dismissOnMouseOut
                                              >
                                                <button
                                                  style={{
                                                    cursor: "not-allowed",
                                                    opacity: "0.5",
                                                  }}
                                                >
                                                  View Voucher
                                                </button>
                                              </Tooltip>
                                            )}
                                          </div>
                                          {/* )} */}
                                          {/* {showHoldFeature &&
                                              uniqueBookingId ===
                                              item.unique_booking_id && ( */}
                                          <div
                                            className="booking-popups-three-dots"
                                            style={{
                                              top: isLastFive
                                                ? "-90px"
                                                : "35px",
                                            }}
                                            // ref={clickOutSideRef}
                                          >
                                            <button
                                            // onClick={() =>
                                            //   getSingleBookingDataHandler(
                                            //     item.unique_booking_id
                                            //   )
                                            // }
                                            >
                                              View Details
                                            </button>
                                            {permissions &&
                                            permissions.includes(
                                              "show_preview_bill"
                                            ) ? (
                                              <button
                                              // onClick={(e) =>
                                              //   handleSubmitPreviewVoucher(
                                              //     e,
                                              //     "voucher",
                                              //     "preview",
                                              //     "Booking Voucher",
                                              //     item?.unique_booking_id
                                              //   )
                                              // }
                                              >
                                                View Voucher
                                              </button>
                                            ) : (
                                              <Tooltip
                                                content={`Please contact the admin.`}
                                                dismissOnMouseOut
                                              >
                                                <button
                                                  style={{
                                                    cursor: "not-allowed",
                                                    opacity: "0.5",
                                                  }}
                                                >
                                                  View Voucher
                                                </button>
                                              </Tooltip>
                                            )}
                                            {/* <button
                                                  // onClick={() =>
                                                  //   navigate(
                                                  //     `/bookings/folio/${item.id}`
                                                  //   )
                                                  // }
                                                  >
                                                    Modify Date/Time
                                                  </button> */}
                                            {permissions &&
                                            permissions.includes(
                                              "show_preview_bill"
                                            ) ? (
                                              <button
                                                style={{
                                                  color: "red",
                                                }}
                                                // onClick={() => [
                                                //   setShowModelMenuActive(
                                                //     true
                                                //   ),
                                                //   setCancelBookingId(
                                                //     item.id
                                                //   ),
                                                // ]}
                                              >
                                                Cancel Booking
                                              </button>
                                            ) : (
                                              <Tooltip
                                                content={`Please contact the admin.`}
                                                dismissOnMouseOut
                                              >
                                                <button
                                                  style={{
                                                    cursor: "not-allowed",
                                                    opacity: "0.5",
                                                  }}
                                                >
                                                  Cancel Booking
                                                </button>
                                              </Tooltip>
                                            )}
                                          </div>
                                          {/* )} */}
                                          {/* {showConfirmedFeature &&
                                              uniqueBookingId ===
                                              item.unique_booking_id && ( */}
                                          <div
                                            className="booking-popups-three-dots"
                                            style={{
                                              top: isLastFive
                                                ? "-90px"
                                                : "35px",
                                            }}
                                            // ref={clickOutSideRef}
                                          >
                                            <button
                                            // onClick={() =>
                                            //   getSingleBookingDataHandler(
                                            //     item.unique_booking_id
                                            //   )
                                            // }
                                            >
                                              View Details
                                            </button>
                                            {permissions &&
                                            permissions.includes(
                                              "show_preview_bill"
                                            ) ? (
                                              <button
                                              // onClick={(e) =>
                                              //   handleSubmitPreviewVoucher(
                                              //     e,
                                              //     "voucher",
                                              //     "preview",
                                              //     "Booking Voucher",
                                              //     item?.unique_booking_id
                                              //   )
                                              // }
                                              >
                                                View Voucher
                                              </button>
                                            ) : (
                                              <Tooltip
                                                content={`Please contact the admin.`}
                                                dismissOnMouseOut
                                              >
                                                <button
                                                  style={{
                                                    cursor: "not-allowed",
                                                    opacity: "0.5",
                                                  }}
                                                >
                                                  View Voucher
                                                </button>
                                              </Tooltip>
                                            )}
                                            {permissions &&
                                            permissions.includes(
                                              "show_preview_bill"
                                            ) ? (
                                              <button
                                              // onClick={() => {
                                              //   setDeleteWithReasonPopup(
                                              //     true
                                              //   );
                                              //   setCancelBookingId(
                                              //     item.id
                                              //   );
                                              // }}
                                              >
                                                Void Booking
                                              </button>
                                            ) : (
                                              <Tooltip
                                                content={`Please contact the admin.`}
                                                dismissOnMouseOut
                                              >
                                                <button
                                                  style={{
                                                    cursor: "not-allowed",
                                                    opacity: "0.5",
                                                  }}
                                                >
                                                  Void Booking
                                                </button>
                                              </Tooltip>
                                            )}
                                            {permissions &&
                                            permissions.includes(
                                              "show_preview_bill"
                                            ) ? (
                                              <button
                                                style={{
                                                  color: "red",
                                                }}
                                                // onClick={() => [
                                                //   setShowModelMenuActive(
                                                //     true
                                                //   ),
                                                //   setCancelBookingId(
                                                //     item.id
                                                //   ),
                                                // ]}
                                              >
                                                Cancel Booking
                                              </button>
                                            ) : (
                                              <Tooltip
                                                content={`Please contact the admin.`}
                                                dismissOnMouseOut
                                              >
                                                <button
                                                  style={{
                                                    cursor: "not-allowed",
                                                    opacity: "0.5",
                                                  }}
                                                >
                                                  Cancel Booking
                                                </button>
                                              </Tooltip>
                                            )}
                                          </div>
                                          {/* )} */}
                                          {/* {showCheckedOutFeature &&
                                              uniqueBookingId ===
                                              item.unique_booking_id && ( */}
                                          <div
                                            className="booking-popups-three-dots"
                                            style={{
                                              top: isLastFive
                                                ? "-90px"
                                                : "35px",
                                            }}
                                            // ref={clickOutSideRef}
                                          >
                                            <button
                                            // onClick={() =>
                                            //   getSingleBookingDataHandler(
                                            //     item.unique_booking_id
                                            //   )
                                            // }
                                            >
                                              View Details
                                            </button>

                                            {permissions &&
                                            permissions.includes(
                                              "show_preview_bill"
                                            ) ? (
                                              <button
                                              // onClick={(e) =>
                                              //   handleSubmitPreviewVoucher(
                                              //     e,
                                              //     "voucher",
                                              //     "preview",
                                              //     "Booking Voucher",
                                              //     item?.unique_booking_id
                                              //   )
                                              // }
                                              >
                                                View Voucher
                                              </button>
                                            ) : (
                                              <Tooltip
                                                content={`Please contact the admin.`}
                                                dismissOnMouseOut
                                              >
                                                <button
                                                  style={{
                                                    cursor: "not-allowed",
                                                    opacity: "0.5",
                                                  }}
                                                >
                                                  View Voucher
                                                </button>
                                              </Tooltip>
                                            )}
                                            {permissions &&
                                            permissions.includes(
                                              "show_preview_bill"
                                            ) ? (
                                              // <Link
                                              //   to={`/bookings/previewbill/${item.unique_booking_id}`}
                                              //   target="_blank"
                                              // >
                                              //   <WithoutBgButtonBlue
                                              //     style={{ fontSize: "14px" }}
                                              //     children={"Preview Bill"}
                                              //   />
                                              // </Link>
                                              <button
                                                // onClick={(e) =>
                                                //   handleSubmitPreview(
                                                //     e,
                                                //     "bill",
                                                //     "preview",
                                                //     "Booking Bill",
                                                //     item.folioId
                                                //   )
                                                // }
                                                children={"Preview Bill"}
                                              />
                                            ) : (
                                              <Tooltip
                                                content={`Please contact the admin.`}
                                                dismissOnMouseOut
                                              >
                                                <button
                                                  style={{
                                                    fontSize: "14px",
                                                    opacity: "0.6",
                                                    cursor: "no-drop",
                                                  }}
                                                  children={"Preview Bill"}
                                                />
                                              </Tooltip>
                                            )}
                                          </div>
                                          {/* )} */}
                                          {/* {showCheckedOutFeature &&
                                              uniqueBookingId ===
                                              item.unique_booking_id && ( */}
                                          <div
                                            className="booking-popups-three-dots"
                                            style={{
                                              top: isLastFive
                                                ? "-90px"
                                                : "35px",
                                            }}
                                            // ref={clickOutSideRef}
                                          >
                                            <button
                                            // onClick={() =>
                                            //   getSingleBookingDataHandler(
                                            //     item.unique_booking_id
                                            //   )
                                            // }
                                            >
                                              View Details
                                            </button>
                                            <button
                                            // onClick={() => [
                                            //   navigate(
                                            //     `/bookings/folio/${item.id}/${item.folioId}`
                                            //   ),
                                            //   localStorage.removeItem(
                                            //     "selectedFolioValue",
                                            //     ""
                                            //   ),
                                            //   localStorage.removeItem(
                                            //     "selectedFolioLabel",
                                            //     ""
                                            //   ),
                                            // ]}
                                            >
                                              View Folio
                                            </button>

                                            {permissions &&
                                            permissions.includes(
                                              "show_preview_bill"
                                            ) ? (
                                              <button
                                              // onClick={(e) =>
                                              //   handleSubmitPreviewVoucher(
                                              //     e,
                                              //     "voucher",
                                              //     "preview",
                                              //     "Booking Voucher",
                                              //     item?.unique_booking_id
                                              //   )
                                              // }
                                              >
                                                View Voucher
                                              </button>
                                            ) : (
                                              <Tooltip
                                                content={`Please contact the admin.`}
                                                dismissOnMouseOut
                                              >
                                                <button
                                                  style={{
                                                    cursor: "not-allowed",
                                                    opacity: "0.5",
                                                  }}
                                                >
                                                  View Voucher
                                                </button>
                                              </Tooltip>
                                            )}
                                            {permissions &&
                                            permissions.includes(
                                              "show_preview_bill"
                                            ) ? (
                                              <>
                                                {item.billGenerated ? (
                                                  <button
                                                    // onClick={(e) =>
                                                    //   handleSubmitPreview(
                                                    //     e,
                                                    //     "bill",
                                                    //     "preview",
                                                    //     "Booking Bill",
                                                    //     item.folioId
                                                    //   )
                                                    // }
                                                    children={"Preview Bill "}
                                                  />
                                                ) : (
                                                  <>
                                                    {/* {currentDateSingle ===
                                                          propertyCurrentDate ? ( */}
                                                    <WithoutBgButtonBlue
                                                      children={"Generate Bill"}
                                                      onClick={() =>
                                                        showErrorToast(
                                                          "Please Generate Bill From Folio"
                                                        )
                                                      }
                                                    />
                                                    {/* ) : (
                                                            <Tooltip
                                                              content={`Please close the current day.`}
                                                            >
                                                              <BgThemeButtonDisabled
                                                                children={
                                                                  "Generate Bill"
                                                                }
                                                                onClick={() =>
                                                                  generateBillClickHandler(
                                                                    item.folioId
                                                                  )
                                                                }
                                                              />
                                                            </Tooltip>
                                                          )} */}
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <Tooltip
                                                content={`Please contact the admin.`}
                                                dismissOnMouseOut
                                              >
                                                <button
                                                  style={{
                                                    fontSize: "14px",
                                                    opacity: "0.6",
                                                    cursor: "no-drop",
                                                  }}
                                                  children={"Preview Bill"}
                                                />
                                              </Tooltip>
                                            )}
                                          </div>
                                          {/* )} */}
                                          <div
                                            style={{
                                              // position: "relative",
                                              lineHeight: "16px",
                                            }}
                                            className="booking-third-row-one-full-div-child"
                                          >
                                            <div
                                              className="pointer"
                                              style={{
                                                color: "rgba(57, 104, 237, 1)",
                                              }}
                                              // onClick={() =>
                                              //   getSingleBookingDataHandler(
                                              //     item.unique_booking_id
                                              //   )
                                              // }
                                            >
                                              #{item.unique_booking_id || ""}
                                            </div>
                                            <div>
                                              {item?.guest?.map((guest) => (
                                                <div key={guest.guest_name}>
                                                  {guest.guest_name || ""}
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                          {/* <div className="booking-third-row-one-full-div-child">
                                              {formattedCheckInDate || ""}-
                                              {formattedCheckOutDate || ""}
                                            </div> */}
                                          <div className="booking-third-row-one-full-div-child">
                                            {formattedCheckInDate || ""}-
                                            {formattedCheckOutDate || ""}
                                          </div>

                                          <div className="booking-third-row-one-full-div-child">
                                            <div>
                                              {item.guest.map((guest) => (
                                                <div key={guest.property_id}>
                                                  {guest.guest_phone || ""}
                                                </div>
                                              ))}
                                            </div>
                                          </div>

                                          <div
                                            className="booking-third-row-one-full-div-child"
                                            // style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap:"2px" }}
                                          ></div>
                                          <div className="booking-third-row-one-full-div-child">
                                            <div>
                                              {item.guest.map((guest) => (
                                                <div key={guest.property_id}>
                                                  {guest.guest_phone || "N/A"}
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                          {/* <div className="booking-third-row-one-full-div-child">
                                              <div>
                                                {formatIndianCurrency(
                                                  item.grand_total || ""
                                                )}
                                              </div>
                                            </div>
                                            <div className="booking-third-row-one-full-div-child">
                                              <div
                                                style={{
                                                  color: "rgba(21, 170, 18, 1)",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {formatIndianCurrency(
                                                  item.paid_amount || ""
                                                )}
                                              </div>
                                            </div>
                                            <div className="booking-third-row-one-full-div-child">
                                              <div
                                                style={{
                                                  color:
                                                    "rgba(189, 148, 27, 1)",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {formatIndianCurrency(
                                                  item.balance_amount || ""
                                                )}
                                              </div>
                                            </div>
                                            <div className="booking-third-row-one-full-div-child">
                                              <div>
                                                <BookingStatusUpdate
                                                  status={item.status}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              className="booking-third-row-one-full-div-child"
                                              style={{
                                                width: "3%",
                                                position: "relative",
                                              }}
                                            >
                                              <div
                                                style={{ position: "relative" }}
                                              >
                                                {item.status ===
                                                  "checked_in" && (
                                                    <div
                                                      className="pointer"
                                                      onClick={() => {
                                                        setShowCheckedOutFeature(
                                                          false
                                                        );
                                                        setShowConfirmedFeature(
                                                          false
                                                        );
                                                        setShowHoldFeature(false);
                                                        setShowCheckedInFeature(
                                                          showCheckedInFeature ===
                                                            false
                                                            ? true
                                                            : false
                                                        );
                                                        setUniqueBookingId(
                                                          item.unique_booking_id
                                                        );
                                                      }}
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="4"
                                                        height="12"
                                                        viewBox="0 0 4 12"
                                                        fill="none"
                                                      >
                                                        <path
                                                          d="M2.0001 3.1999C1.228 3.1999 0.600098 2.572 0.600098 1.7999C0.600098 1.0278 1.228 0.399902 2.0001 0.399902C2.7722 0.399902 3.4001 1.0278 3.4001 1.7999C3.4001 2.572 2.7722 3.1999 2.0001 3.1999ZM2.0001 4.5999C2.7722 4.5999 3.4001 5.2278 3.4001 5.9999C3.4001 6.772 2.7722 7.3999 2.0001 7.3999C1.228 7.3999 0.600098 6.772 0.600098 5.9999C0.600098 5.2278 1.228 4.5999 2.0001 4.5999ZM2.0001 8.7999C2.7722 8.7999 3.4001 9.4278 3.4001 10.1999C3.4001 10.972 2.7722 11.5999 2.0001 11.5999C1.228 11.5999 0.600098 10.972 0.600098 10.1999C0.600098 9.4278 1.228 8.7999 2.0001 8.7999Z"
                                                          fill="#666666"
                                                        />
                                                      </svg>
                                                    </div>
                                                  )}
                                                {item.status ===
                                                  "confirmed" && (
                                                    <div
                                                      className="pointer"
                                                      onClick={() => {
                                                        setShowCheckedOutFeature(
                                                          false
                                                        );
                                                        setShowCheckedInFeature(
                                                          false
                                                        );
                                                        setShowHoldFeature(false);
                                                        setShowConfirmedFeature(
                                                          showConfirmedFeature ===
                                                            false
                                                            ? true
                                                            : false
                                                        );
                                                        setUniqueBookingId(
                                                          item.unique_booking_id
                                                        );
                                                      }}
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="4"
                                                        height="12"
                                                        viewBox="0 0 4 12"
                                                        fill="none"
                                                      >
                                                        <path
                                                          d="M2.0001 3.1999C1.228 3.1999 0.600098 2.572 0.600098 1.7999C0.600098 1.0278 1.228 0.399902 2.0001 0.399902C2.7722 0.399902 3.4001 1.0278 3.4001 1.7999C3.4001 2.572 2.7722 3.1999 2.0001 3.1999ZM2.0001 4.5999C2.7722 4.5999 3.4001 5.2278 3.4001 5.9999C3.4001 6.772 2.7722 7.3999 2.0001 7.3999C1.228 7.3999 0.600098 6.772 0.600098 5.9999C0.600098 5.2278 1.228 4.5999 2.0001 4.5999ZM2.0001 8.7999C2.7722 8.7999 3.4001 9.4278 3.4001 10.1999C3.4001 10.972 2.7722 11.5999 2.0001 11.5999C1.228 11.5999 0.600098 10.972 0.600098 10.1999C0.600098 9.4278 1.228 8.7999 2.0001 8.7999Z"
                                                          fill="#666666"
                                                        />
                                                      </svg>
                                                    </div>
                                                  )}
                                                {(item.status === "hold" ||
                                                  item.status ===
                                                  "pending") && (
                                                    <div
                                                      className="pointer"
                                                      onClick={() => {
                                                        setShowCheckedOutFeature(
                                                          false
                                                        );
                                                        setShowConfirmedFeature(
                                                          false
                                                        );
                                                        setShowCheckedInFeature(
                                                          false
                                                        );
                                                        setShowHoldFeature(
                                                          showHoldFeature ===
                                                            false
                                                            ? true
                                                            : false
                                                        );
                                                        setUniqueBookingId(
                                                          item.unique_booking_id
                                                        );
                                                      }}
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="4"
                                                        height="12"
                                                        viewBox="0 0 4 12"
                                                        fill="none"
                                                      >
                                                        <path
                                                          d="M2.0001 3.1999C1.228 3.1999 0.600098 2.572 0.600098 1.7999C0.600098 1.0278 1.228 0.399902 2.0001 0.399902C2.7722 0.399902 3.4001 1.0278 3.4001 1.7999C3.4001 2.572 2.7722 3.1999 2.0001 3.1999ZM2.0001 4.5999C2.7722 4.5999 3.4001 5.2278 3.4001 5.9999C3.4001 6.772 2.7722 7.3999 2.0001 7.3999C1.228 7.3999 0.600098 6.772 0.600098 5.9999C0.600098 5.2278 1.228 4.5999 2.0001 4.5999ZM2.0001 8.7999C2.7722 8.7999 3.4001 9.4278 3.4001 10.1999C3.4001 10.972 2.7722 11.5999 2.0001 11.5999C1.228 11.5999 0.600098 10.972 0.600098 10.1999C0.600098 9.4278 1.228 8.7999 2.0001 8.7999Z"
                                                          fill="#666666"
                                                        />
                                                      </svg>
                                                    </div>
                                                  )}
                                                {item.status ===
                                                  "checked_out" && (
                                                    <div
                                                      className="pointer"
                                                      onClick={() => {
                                                        setShowCheckedOutFeature(
                                                          showCheckedOutFeature ===
                                                            false
                                                            ? true
                                                            : false
                                                        );
                                                        setUniqueBookingId(
                                                          item.unique_booking_id
                                                        );
                                                      }}
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="4"
                                                        height="12"
                                                        viewBox="0 0 4 12"
                                                        fill="none"
                                                      >
                                                        <path
                                                          d="M2.0001 3.1999C1.228 3.1999 0.600098 2.572 0.600098 1.7999C0.600098 1.0278 1.228 0.399902 2.0001 0.399902C2.7722 0.399902 3.4001 1.0278 3.4001 1.7999C3.4001 2.572 2.7722 3.1999 2.0001 3.1999ZM2.0001 4.5999C2.7722 4.5999 3.4001 5.2278 3.4001 5.9999C3.4001 6.772 2.7722 7.3999 2.0001 7.3999C1.228 7.3999 0.600098 6.772 0.600098 5.9999C0.600098 5.2278 1.228 4.5999 2.0001 4.5999ZM2.0001 8.7999C2.7722 8.7999 3.4001 9.4278 3.4001 10.1999C3.4001 10.972 2.7722 11.5999 2.0001 11.5999C1.228 11.5999 0.600098 10.972 0.600098 10.1999C0.600098 9.4278 1.228 8.7999 2.0001 8.7999Z"
                                                          fill="#666666"
                                                        />
                                                      </svg>
                                                    </div>
                                                  )}
                                              </div>
                                            </div> */}
                                        </div>

                                        <hr />
                                      </div>
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          marginTop: "10px",
                        }}
                      >
                        {/* <ApiPagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                          /> */}
                        {/* <CustomPaginationNew
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            apiUrl={""}
                          /> */}
                      </div>

                      {/* {pageNumbers.map((number) => (
                          <li
                            key={number}
                            className={`page-item ${
                              currentPage === number ? "active" : ""
                            }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => onPageChange(number)}
                            >
                              {number}
                            </button>
                          </li>
                        ))} */}
                    </>
                  ) : (
                    <NoDataFound />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <HoldRoomsAgentsPopup />

      <ReleaseRoomsManualAgentsPopup />

      <CreateContract />
      <button className="open-button" onClick={toggleSidebar}>Open Sidebar</button>
     <AgentHoldRoomsSliderSideBar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

     <div style={{width:"100px" , margin:"20px auto"}}>
     <AgentHoldRoomsUpliftMenuButton  menuOptions={options} handleUpliftMenuClick={handleUpliftMenuClick}   />
     </div>
     
     <MoreOptionsDropDown/>

   
    </>
  );
};

export default HoldRoomPlansComp;
