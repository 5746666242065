import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import { useEffect, useState } from "react";

// to calculate the date plus function
export const calculateToDatePlus = (fromDate, days) => {
  const toDate = new Date(fromDate);
  toDate.setDate(toDate.getDate() + days);
  return toDate.toISOString().split("T")[0];
};

//
export const calculateToDateMinus = (fromDate, days) => {
  const toDate = new Date(fromDate);
  toDate.setDate(toDate.getDate() - days);
  return toDate?.toISOString().split("T")[0];
};

// export function formatIndianCurrency(amount) {
//   const currencyFormatter = new Intl.NumberFormat("en-IN", {
//     style: "currency",
//     currency: "INR",
//   });
//   const formattedAmount = currencyFormatter.format(amount);
//   return `${
//     isNaN(formattedAmount) ?formattedAmount  : currencyFormatter.format(0)
//   }`;
// }
export function formatIndianCurrency(amount) {
  const currencyFormatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  // Check if amount is NaN and set it to zero if true
  amount = isNaN(amount) ? 0 : amount;

  const formattedAmount = currencyFormatter.format(amount);
  return formattedAmount;
}

export const formatAMPM = (date) => {
  if (!date) return "";

  let hours = date.getUTCHours();
  let minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const formatDate = (date) => {
  if (!date) return "";

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const day = date.getUTCDate();
  const monthIndex = date.getUTCMonth();
  const year = date.getUTCFullYear();
  return `${monthNames[monthIndex]} ${day}, ${year}`;
};

export function getTimeFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

export const checkFolioSummery = (
  userToken,
  folioId,
  property_id,
  data,
  showMessage
) => {
  try {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/sync/folios/summary/${folioId}/${property_id}`,
      {
        method: "POST",
        body: JSON.stringify({
          grand_total: data?.grandTotalSum,
          paid_amount: data?.paidAmountSum,
          sub_total: data?.subTotalSum,
          discount_amount: data?.discountAmountSum,
          tax_amount: data?.taxAmountSum,
          balance_amount: data?.grandTotalSum - data?.paidAmountSum,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showMessage && showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      });
  } catch (error) {}
};

export const getCurrentPropertyDate = (property_id) => {
  if (
    property_id &&
    property_id?.night_audit_logs &&
    property_id?.night_audit_logs?.length > 0
  ) {
    const nightAuditDate = property_id.night_audit_logs[0].day_start_date;
    if (nightAuditDate) {
      return nightAuditDate;
    } else {
      return new Date()?.toISOString()?.split("T")[0];
    }
  } else {
    return new Date()?.toISOString()?.split("T")[0];
  }
};

export function convertTo12HourFormat(time24) {
  if (!time24) {
    return null;
  }

  const [hours24, minutes, seconds] = time24.split(":").map(Number);

  const meridian = hours24 >= 12 ? "PM" : "AM";

  let hours12 = hours24 % 12;
  hours12 = hours12 === 0 ? 12 : hours12;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  const time12 = `${hours12}:${formattedMinutes} ${meridian}`;

  return time12;
}

// function to get the current propertyDate
export const getCurrentPropertyDateHandler = async (propertyId, userToken) => {
  if (!userToken) return;

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/current/date/${propertyId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch current date from the API");
    }

    const data = await response.json();
    if (data.success) {
      // localStorage.setItem(
      //   "activePropertyDate",
      //   JSON.stringify(data.latest_day_start_date)
      // );
      return data.latest_day_start_date;
    }
  } catch (error) {
    console.error(error);
    return "Error fetching current date";
  }
};

export const currentDateSingle = new Date().toISOString().split("T")[0];

export const useCurrentTime = (timeFormat) => {
  const [currentTime, setCurrentTime] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const getCurrentTime = () => {
        let date = new Date();
        let hours = date.getHours();
        let minutes = date.getMinutes();

        if (timeFormat === "12") {
          let ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? "0" + minutes : minutes;
          return hours + ":" + minutes + " " + ampm;
        } else {
          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          return hours + ":" + minutes;
        }
      };

      setCurrentTime(getCurrentTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeFormat]);

  return currentTime;
};

export const globalErrorHandler = (data, keys) => {
  let errors = [];

  const isArray = Array.isArray(data);

  if (isArray) {
    data.forEach((obj, index) => {
      let objErrors = {};
      keys.forEach((key) => {
        if (!obj[key]) {
          objErrors[key] = `Please enter the ${key}`;
        }
      });
      // if (Object.keys(objErrors).length > 0) {
      errors.push(objErrors);
      // }
    });
  } else {
    let objErrors = {};
    keys.forEach((key) => {
      if (!data[key]) {
        objErrors[key] = `Please enter the ${key}`;
      }
    });
    if (Object.keys(objErrors).length > 0) {
      errors = objErrors;
    }
  }
  console.log(errors.length);

  return isArray
    ? errors.length > 0
      ? { errors, success: true }
      : { success: false, errors }
    : Object.keys(errors).length > 0
    ? { errors, success: true }
    : { success: false, errors };
  return errors;
};




export function calculateTotalWithReduce(array, property) {
  // Check if the input is an array
  if (!Array.isArray(array)) {
    throw new TypeError('First argument must be an array');
  }

  // Check if the property is a string
  if (typeof property !== 'string') {
    throw new TypeError('Second argument must be a string');
  }

  return array.reduce((total, item) => {
    // Check if the item is an object
    // if (typeof item !== 'object' || item === null) {
    //   throw new TypeError('Array elements must be objects');
    // }

    // Check if the property exists in the item
    // if (!(property in item)) {
    //   throw new Error(`Property "${property}" does not exist in some array elements`);
    // }

    // Convert the property value to a number, default to 0 if NaN
    const value = parseFloat(item[property]) || 0;

    // Ensure the value is a finite number
    // if (!isFinite(value)) {
    //   throw new Error(`Property "${property}" must be a finite number`);
    // }

    return total + value;
  }, 0);
}
