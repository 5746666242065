import { Button, Icon, Page } from "@shopify/polaris";
import React from "react";
import { BgOutlineThemeButton, BgThemeButton, PaidButton } from "./UI/Buttons";
import CustomToggleButton from "./UI/CustomToggleButton";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import { useSelector } from "react-redux";
import StatusUpdate from "./CustomComponents/StatusUpdate";

const CustomerSingle = () => {
  const CustomToggleButtonHandler = () => {};

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const navigate = useNavigate();

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);

  let { customerid } = useParams();

  const [singleUserDetails, setSingleUserDetails] = useState(null);

  const fetchInfo = async () => {
    // /api/v1/admin/customers/details?account_id=1
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/view/guest/${property_id?.id}?id=${customerid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setSingleUserDetails(data.guest);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo();
    }
  }, [property_id]);

  // UseState for forms
  const [asignOwnerDate, setAsignOwnerDate] = useState({
    name: "",
    email: "",
    property_id: property_id?.id,
  });

  const setAddItemDataHandleChange = (event) => {
    setAsignOwnerDate({
      ...asignOwnerDate,
      [event.target.name]: event.target.value,
    });
  };

  const roomTypeOptions = singleUserDetails?.properties?.map((property) => {
    return { label: property.property_name, value: property.id };
  });

  const handleAsignOwnerData = (event) => {
    event.preventDefault();
    // /api/v1/admin/property/assign
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/property/assign`, {
      method: "POST",
      body: JSON.stringify({ ...asignOwnerDate }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          fetchInfo();
          showSuccessToast(data.message);

          navigate("/customers");
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  return (
    <div className="agent-full-container">
      <div style={{ paddingLeft: "48px" }}>
        <Page
          backAction={{ content: "Products", url: `/customers` }}
          title={singleUserDetails?.guest_name}
          compactTitle
          subtitle={singleUserDetails?.guest_email}
        ></Page>
      </div>

      <div className="agent-full-container-div">
        <div className="agent-full-container-left">
          {/* <div className="agent-full-container-left-div1">
                        <div style={{ width: "280px", borderRight: "0.5px solid #CCC" }}>
                            <span>Amount Spend</span>
                            <div>
                                3453
                            </div>
                        </div>
                        <div style={{ marginLeft: "20px" }}>
                            <span>Credit Limit</span>
                            <div>32312312</div>
                        </div>
                    </div> */}

          <div className="agent-full-container-left-div2">
            <div>
              <div className="">
                <div className="">
                  <div className="booking_details_child">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <span className="">
                        <strong>Booking Detail</strong>
                      </span>
                      <span>
                        <strong>
                          ID: #
                          {singleUserDetails &&
                            singleUserDetails.booking?.unique_booking_id}
                        </strong>
                      </span>
                    </div>
                    {singleUserDetails &&
                      singleUserDetails.booking &&
                      singleUserDetails.booking !== null && (
                        <>
                          <div
                            style={{
                              border: "0",
                              cursor: "default",
                              padding: "0",
                            }}
                            // onClick={() => toggleAccordion("first")}
                            className="booking_details_child_main"
                          >
                            <div className="booking_details_child_heading">
                              <h5>
                                <strong>General Information</strong>
                              </h5>
                            </div>

                            {/* {openAccordion === "first" && ( */}
                            <div className="booking_details_child_list">
                              <li className="booking_list_view">
                                <h4>Check-In Date</h4>
                                <h5>
                                  {(singleUserDetails.booking &&
                                    singleUserDetails.booking?.check_in) ||
                                    ""}
                                </h5>
                              </li>
                              <li className="booking_list_view">
                                <h4>Booking Source</h4>
                                <h5>
                                  {(
                                    singleUserDetails.booking &&
                                    singleUserDetails.booking?.booking_source
                                  ).replace("_", " ") || ""}
                                </h5>
                              </li>
                              <li className="booking_list_view">
                                <h4>Booking By</h4>
                                <h5>
                                  {(singleUserDetails.booking &&
                                    singleUserDetails.booking?.booked_by) ||
                                    ""}
                                </h5>
                              </li>
                              <li className="booking_list_view">
                                <h4>Booking Status</h4>
                                <h5>
                                  <span>
                                    <StatusUpdate
                                      status={
                                        (singleUserDetails.booking &&
                                          singleUserDetails.booking?.status) ||
                                        ""
                                      }
                                    />
                                  </span>
                                </h5>
                              </li>
                            </div>
                            {/* )} */}
                          </div>

                          <div
                            style={{
                              border: "0",
                              cursor: "default",
                              padding: "0",
                            }}
                            // onClick={() => toggleAccordion("third")}
                            className="booking_details_child_main"
                          >
                            <div className="booking_details_child_heading">
                              <h5>
                                <strong>Price Break Up</strong>
                              </h5>
                            </div>
                            <div className="booking_details_child_list">
                              <li className="booking_list_view">
                                <h4>Tax Amount</h4>
                                <h5>
                                  {singleUserDetails.booking &&
                                    singleUserDetails.booking?.tax_amount}
                                </h5>
                              </li>
                              <li className="booking_list_view">
                                <h4>Tax Applied</h4>
                                <h5>
                                  {singleUserDetails.booking &&
                                    singleUserDetails.booking?.tax_applied}
                                </h5>
                              </li>
                              <li className="booking_list_view">
                                <h4>Pain Amount</h4>
                                <h5>
                                  {singleUserDetails.booking &&
                                    singleUserDetails.booking?.paid_amount}
                                </h5>
                              </li>
                              <li className="booking_list_view">
                                <h4>Gst Type</h4>
                                <h5 style={{ textTransform: "capitalize" }}>
                                  {singleUserDetails.booking &&
                                    singleUserDetails.booking?.gst_type}
                                </h5>
                              </li>
                              <li className="booking_list_view">
                                <h4>Grand Total</h4>
                                <h5>
                                  ₹{" "}
                                  {singleUserDetails.booking &&
                                    singleUserDetails.booking?.grand_total}
                                </h5>
                              </li>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="agent-full-container-right">
          <div className="agent-full-container-right-div1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "18px",
              }}
            >
              <div
                style={{
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Customer
              </div>
              {/* <Link to={`/agent/editprofile/${customerid}`}>Edit</Link> */}
            </div>
            <div
              style={{
                color: "#333",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              <div>Contact Information</div>
              <div
                style={{
                  fontWeight: "500",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <a href={`tel:${singleUserDetails?.guest_phone}`}>
                  <span
                    style={{
                      color: "#3968ED",
                      textDecoration: "underline",
                    }}
                  >
                    {singleUserDetails?.guest_phone}
                  </span>
                </a>
              </div>
              <div style={{ fontWeight: "500", color: "#3968ED" }}>
                <a href={`mailto:${singleUserDetails?.guest_email}`}>
                  <span
                    style={{
                      color: "#3968ED",
                      textDecoration: "underline",
                    }}
                  >
                    {singleUserDetails?.guest_email}{" "}
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="agent-full-container-right-div1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "18px",
              }}
            >
              <div
                style={{
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Customer Details
              </div>
              <div></div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    color: "#888",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginBottom: "8px",
                  }}
                >
                  Guest Address
                </div>
                <div>{singleUserDetails?.guest_address}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    color: "#888",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginBottom: "8px",
                  }}
                >
                  Guest State
                </div>
                <div>{singleUserDetails?.guest_state}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {showAddModelMenuActive && (
          <>
            <div className="bg-backdrop">
              <form
                onSubmit={(e) => handleAsignOwnerData(e)}
                style={{ width: "700px" }}
                className="stock-add-model-container"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <div className="add-ota-model-container-heading">
                    Asign Owner
                  </div>
                  <div
                    onClick={() => setShowAddModelMenuActive(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon source={CancelMajor} color="base" />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Owner Name"
                      name="name"
                      // type="number"
                      value={asignOwnerDate.name}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputSelectCustomCopy
                      options={roomTypeOptions}
                      titleName="Select Property"
                      name="property_id"
                      value={asignOwnerDate.property_id}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Email"
                      name="email"
                      // type="email"
                      value={asignOwnerDate.email}
                      onChange={setAddItemDataHandleChange}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton type="submit" children={"Asign"} />
                    <Button onClick={() => setShowAddModelMenuActive(false)}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerSingle;
