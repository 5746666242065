import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  CheckWithoutBgUnderlineBtn,
} from "./UI/Buttons";
import Loader from "./UI/Loader/Loader";

import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import { useSelector } from "react-redux";
import { Button, Icon } from "@shopify/polaris";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import {
  other_select_nav_items_channel_inventory,
  other_select_nav_items_inventory,
  rooms_inner_lists,
} from "../assets/constant";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import NoDataFound from "./CustomComponents/NoDataFound";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";

const Channels = () => {
  const [purchaseHeadingActive, setPurchaseHeadingActive] = useState("");
  const [uniqueChannelId, setUniqueChannelId] = useState(null);

  const setPurchaseHeading = (idx) => {
    setUniqueChannelId(idx);
    if (purchaseHeadingActive === idx) {
      setPurchaseHeadingActive("active");
    } else {
      setPurchaseHeadingActive("");
    }
  };

  const getChannelData = (idx) => {
    setUniqueChannelId(idx);
    if (purchaseHeadingActive === idx) {
      setPurchaseHeadingActive("active");
    } else {
      setPurchaseHeadingActive("");
    }
  };
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [propertyUniqueId, setPropertyUniqueId] = useState("");
  const selectedPropertyUniqueId = useSelector((state) => state.property.value);

  const property_id = useSelector((state) => state.property.value);
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const [selectedDate, setSelectedDate] = useState(formattedDate);

  const dateClickHandler = (date) => {
    {
      setBulkInventoryData({
        ...bulkInventoryData,
        from_date: date,
      });
    }

    setSelectedDate(date);

    fetchInfoWithDate(property_id.id, date, uniqueChannelId);
  };

  const fetchInfo = async (propertyId, id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/${id}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const fetchChannelInfo = async (channelId, propertyId, index) => {
    setUniqueChannelId(channelId);
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/${channelId}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const fetchInfoWithDate = async (propertyId, date, channelId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/${channelId}/${propertyId}?date=${date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id && uniqueChannelId !== null) {
      fetchInfo(property_id?.id, uniqueChannelId);
    }
  }, [property_id, uniqueChannelId]);

  setTimeout(() => {
    setLoader(false);
  }, 3000);

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const dummyRoomPlansName = [
    {
      name: "Base Rate",
      svg: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 15 14"
            fill="none"
          >
            <path
              d="M7.50005 6.3001C8.65985 6.3001 9.60005 5.3599 9.60005 4.2001C9.60005 3.0403 8.65985 2.1001 7.50005 2.1001C6.34025 2.1001 5.40005 3.0403 5.40005 4.2001C5.40005 5.3599 6.34025 6.3001 7.50005 6.3001Z"
              fill="#888888"
            />
            <path
              d="M5.05004 11.9002C4.60218 11.9002 4.15432 11.7294 3.81261 11.3877C3.12919 10.7042 3.12919 9.59621 3.81261 8.91279C4.22509 8.50856 5.54005 7.7001 7.50005 7.7001C9.46005 7.7001 10.775 8.50856 11.1875 8.91279C11.8709 9.59621 11.8709 10.7042 11.1875 11.3877C10.8458 11.7294 10.3979 11.9002 9.95005 11.9002H5.05004Z"
              fill="#888888"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 15 14"
            fill="none"
          >
            <path
              d="M7.50005 6.3001C8.65985 6.3001 9.60005 5.3599 9.60005 4.2001C9.60005 3.0403 8.65985 2.1001 7.50005 2.1001C6.34025 2.1001 5.40005 3.0403 5.40005 4.2001C5.40005 5.3599 6.34025 6.3001 7.50005 6.3001Z"
              fill="#888888"
            />
            <path
              d="M5.05004 11.9002C4.60218 11.9002 4.15432 11.7294 3.81261 11.3877C3.12919 10.7042 3.12919 9.59621 3.81261 8.91279C4.22509 8.50856 5.54005 7.7001 7.50005 7.7001C9.46005 7.7001 10.775 8.50856 11.1875 8.91279C11.8709 9.59621 11.8709 10.7042 11.1875 11.3877C10.8458 11.7294 10.3979 11.9002 9.95005 11.9002H5.05004Z"
              fill="#888888"
            />
          </svg>
        </>
      ),
    },
    {
      name: "Extra Adult",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M7.50005 6.3001C8.65985 6.3001 9.60005 5.3599 9.60005 4.2001C9.60005 3.0403 8.65985 2.1001 7.50005 2.1001C6.34025 2.1001 5.40005 3.0403 5.40005 4.2001C5.40005 5.3599 6.34025 6.3001 7.50005 6.3001Z"
            fill="#888888"
          />
          <path
            d="M5.05004 11.9002C4.60218 11.9002 4.15432 11.7294 3.81261 11.3877C3.12919 10.7042 3.12919 9.59621 3.81261 8.91279C4.22509 8.50856 5.54005 7.7001 7.50005 7.7001C9.46005 7.7001 10.775 8.50856 11.1875 8.91279C11.8709 9.59621 11.8709 10.7042 11.1875 11.3877C10.8458 11.7294 10.3979 11.9002 9.95005 11.9002H5.05004Z"
            fill="#888888"
          />
        </svg>
      ),
    },
    {
      name: "Extra Child",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M2.25 4.08325V10.4999M2.25 8.16659H12.75M12.75 10.4999V5.83325C12.75 5.52383 12.6271 5.22709 12.4083 5.00829C12.1895 4.7895 11.8928 4.66659 11.5833 4.66659H6.91667V8.16659M4 5.83325C4 5.98796 4.06146 6.13633 4.17085 6.24573C4.28025 6.35513 4.42862 6.41659 4.58333 6.41659C4.73804 6.41659 4.88642 6.35513 4.99581 6.24573C5.10521 6.13633 5.16667 5.98796 5.16667 5.83325C5.16667 5.67854 5.10521 5.53017 4.99581 5.42077C4.88642 5.31138 4.73804 5.24992 4.58333 5.24992C4.42862 5.24992 4.28025 5.31138 4.17085 5.42077C4.06146 5.53017 4 5.67854 4 5.83325Z"
            stroke="#888888"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Extra Child",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 9 14"
          fill="none"
        >
          <path
            d="M2.75005 1.75C2.75005 1.28587 2.93442 0.840752 3.26261 0.512563C3.5908 0.184374 4.03592 0 4.50005 0C4.96417 0 5.40929 0.184374 5.73748 0.512563C6.06567 0.840752 6.25005 1.28587 6.25005 1.75C6.25005 2.21413 6.06567 2.65925 5.73748 2.98744C5.40929 3.31563 4.96417 3.5 4.50005 3.5C4.03592 3.5 3.5908 3.31563 3.26261 2.98744C2.93442 2.65925 2.75005 2.21413 2.75005 1.75ZM4.06255 10.5V13.125C4.06255 13.609 3.67153 14 3.18755 14C2.70356 14 2.31255 13.609 2.31255 13.125V7.86953L1.74106 8.77734C1.48403 9.1875 0.942624 9.30781 0.535202 9.05078C0.12778 8.79375 0.00199857 8.25508 0.25903 7.84766L1.35005 6.1168C2.0309 5.03125 3.22036 4.375 4.50005 4.375C5.77973 4.375 6.96919 5.03125 7.65005 6.11406L8.74106 7.84766C8.99809 8.25781 8.87505 8.79648 8.46762 9.05352C8.0602 9.31055 7.5188 9.1875 7.26176 8.78008L6.68755 7.86953V13.125C6.68755 13.609 6.29653 14 5.81255 14C5.32856 14 4.93755 13.609 4.93755 13.125V10.5H4.06255Z"
            fill="#888888"
          />
        </svg>
      ),
    },
  ];

  const [activeIndexes, setActiveIndexes] = useState([]);

  // const handleAccordionClick = (index) => {
  //   if (activeIndexes.includes(index)) {
  //     setActiveIndexes(activeIndexes.filter((i) => i !== index));
  //   } else {
  //     setActiveIndexes([...activeIndexes, index]);
  //   }
  // };

  const [expandedItems, setExpandedItems] = useState([]);

  // const togglePlanExpand = (index) => {
  //   setIsExpanded(!isExpanded);
  // };
  // const togglePlanExpand = (rateIndex) => {
  //   if (expandedItems.includes(rateIndex)) {
  //     setExpandedItems(expandedItems.filter((i) => i !== rateIndex));
  //   } else {
  //     setExpandedItems([...expandedItems, rateIndex]);
  //   }
  // };

  const [innerInnerClose, setInnerInnerClose] = useState(false);

  const togglePlanExpand = (rateIndex) => {
    if (expandedItems.includes(rateIndex)) {
      setExpandedItems(expandedItems.filter((i) => i !== rateIndex));
      setInnerInnerClose(true);
    } else {
      setExpandedItems([...expandedItems, rateIndex]);
      setInnerInnerClose(false);
    }
  };

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
  const [blockShowAddModelMenuActive, setBlockShowAddModelMenuActive] =
    useState(false);
  const [unblockShowAddModelMenuActive, setUnblockShowAddModelMenuActive] =
    useState(false);

  const [holdShowAddModelMenuActive, setHoldShowAddModelMenuActive] =
    useState(false);

  const [unholdShowAddModelMenuActive, setUnholdShowAddModelMenuActive] =
    useState(false);

  const [
    inventoriesOptionShowAddModelMenuActive,
    setInventoriesOptionShowAddModelMenuActive,
  ] = useState(false);

  const [bulkInventoryData, setBulkInventoryData] = useState({
    property_id: property_id?.id,
    from_date: "",
    to_date: "",
    // inventory: "",
    // rates: "",
    // extra_adult: "",
    // extra_child: "",
    // extra_bed: "",
  });

  const [blockInventoryData, setBlockInventoryData] = useState({
    property_id: property_id?.id,
    from_date: "",
    to_date: "",
  });

  const [unblockInventoryData, setUnblockInventoryData] = useState({
    property_id: property_id?.id,
    from_date: "",
    to_date: "",
    // available_rooms: "",
  });

  const [holdInventoryData, setHoldInventoryData] = useState({
    property_id: property_id?.id,
    from_date: "",
    to_date: "",
  });

  const [selectedValues, setSelectedValues] = useState([]);

  // HOLD
  const [selectedRooms, setSelectedRooms] = useState({});
  const [holdInputValues, setHoldInputValues] = useState([]);
  const [bulkInputValues, setBulkInputValues] = useState([]);
  const [error, setError] = useState("");

  const holdHandleCheckboxChange = async (e, room) => {
    const roomId = room.id;
    const isChecked = e.target.checked;
    if (holdInventoryData.from_date === "") {
      showErrorToast("Please fill the above dates.");
      return;
    }

    if (isChecked) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/edit/${roomId}/${holdInventoryData.from_date}/${uniqueChannelId}/${property_id?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const availableRooms = data.available_rooms;

          setHoldInputValues((prevRoomData) => [
            ...prevRoomData,
            {
              property_id: property_id?.id,
              from_date: selectedDate, // Set the selected date here
              to_date: "",
              available_rooms: availableRooms,
              room_id: roomId,
              hold_room: "",
            },
          ]);
        } else {
          // Handle error if the fetch request is not successful
          console.error("Failed to fetch available rooms data");
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    } else {
      setHoldInputValues((prevRoomData) =>
        prevRoomData.filter((data) => data.room_id !== roomId)
      );
    }
  };

  const bulkHandleCheckboxChange = async (e, room) => {
    const roomId = room.id;
    const isChecked = e.target.checked;

    // if (isChecked) {
    //   // Logic when the checkbox is checked
    //   // You can add your own logic here

    //   setBulkInputValues((prevRoomData) => [
    //     ...prevRoomData,
    //     {
    //       property_id: property_id?.id,
    //       from_date: selectedDate, // Set the selected date here
    //       to_date: "",
    //       room_id: roomId,
    //       rates: "",
    //     },
    //   ]);
    // } else {
    //   // Logic when the checkbox is unchecked
    //   // You can add your own logic here

    //   // Remove the item from the bulkInputValues based on room_id
    //   setBulkInputValues((prevRoomData) =>
    //     prevRoomData.filter((data) => data.room_id !== roomId)
    //   );
    // }

    if (bulkInventoryData.from_date === "") {
      showErrorToast("Please fill the above dates.");
      return;
    }
    if (isChecked) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/edit/${roomId}/${bulkInventoryData.from_date}/${uniqueChannelId}/${property_id?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const availableRooms = data.available_rooms;

          setBulkInputValues((prevRoomData) => [
            ...prevRoomData,
            {
              from_date: selectedDate,
              to_date: "",
              available_rooms: availableRooms,
              room_id: roomId,
              inventory: "",
            },
          ]);
        } else {
          showErrorToast("Failed to fetch available rooms data");
        }
      } catch (error) {
        showErrorToast("An error occurred while fetching data:");
      }
    } else {
      setBulkInputValues((prevRoomData) =>
        prevRoomData.filter((data) => data.room_id !== roomId)
      );
    }
  };

  const handleHoldInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRoomData = [...holdInputValues];

    // Find the index of the current room data
    const roomDataIndex = updatedRoomData.findIndex(
      (data) => data.room_id === holdInputValues[index].room_id
    );

    if (!isNaN(value)) {
      if (
        name === "hold_room" &&
        parseInt(value) >= holdInputValues[index].available_rooms
      ) {
        showErrorToast("Hold Rooms should be less then Available Rooms.");
        return;
      }

      updatedRoomData[roomDataIndex][name] = value;
      setHoldInputValues(updatedRoomData);
    } else {
      showErrorToast("Please enter a valid number.");
    }
  };

  // new handle change
  const handleBulkInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRoomData = [...bulkInputValues];

    const roomDataIndex = updatedRoomData.findIndex(
      (data) => data.room_id === bulkInputValues[index].room_id
    );

    if (!isNaN(value)) {
      if (
        name === "inventory" &&
        parseInt(value) >= bulkInputValues[index].available_rooms
      ) {
        showErrorToast("Inventory Rooms should be less then Available Rooms.");
        return;
      }
      updatedRoomData[roomDataIndex][name] = value;
      setBulkInputValues(updatedRoomData);
    } else {
      showErrorToast("Please enter a valid number.");
    }
  };

  // Function to handle checkbox changes
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Check if the value is not already in the array before adding it
      if (!selectedValues.includes(value)) {
        setSelectedValues([...selectedValues, value]);
        addCheckbox();
      }
    } else {
      // Remove the value from the array if it exists
      setSelectedValues(selectedValues.filter((item) => item !== value));
    }
  };

  const [checkboxOptions, setCheckboxOptions] = useState([]);

  const addCheckbox = () => {
    const newCheckbox = {
      id: Date.now(),
      label: `Option ${checkboxOptions.length + 1}`,
      value: `option${checkboxOptions.length + 1}`,
    };

    setCheckboxOptions([...checkboxOptions, newCheckbox]);
  };

  const setBulkInventoryHandleChange = (event) => {
    setBulkInventoryData({
      ...bulkInventoryData,
      property_id: property_id.id,
      [event.target.name]: event.target.value,
    });
  };

  const setBlockInventoryHandleChange = (event) => {
    setBlockInventoryData({
      ...blockInventoryData,
      property_id: property_id.id,
      [event.target.name]: event.target.value,
    });
  };

  const setUnblockInventoryHandleChange = (event) => {
    setUnblockInventoryData({
      ...unblockInventoryData,
      property_id: property_id.id,
      [event.target.name]: event.target.value,
    });
  };

  const setHoldInventoryHandleChange = (event) => {
    setHoldInventoryData({
      ...holdInventoryData,
      property_id: property_id.id,
      [event.target.name]: event.target.value,
    });
  };

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const blockAddModalButtonClickHandler = () => {
    setBlockShowAddModelMenuActive(!blockShowAddModelMenuActive);
  };

  const unblockAddModalButtonClickHandler = () => {
    setUnblockShowAddModelMenuActive(!unblockShowAddModelMenuActive);
  };

  const holdAddModalButtonClickHandler = () => {
    setHoldShowAddModelMenuActive(!holdShowAddModelMenuActive);
  };

  const unholdAddModalButtonClickHandler = () => {
    setUnholdShowAddModelMenuActive(!unholdShowAddModelMenuActive);
  };

  const bulkInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);

      const response = await fetch(
        // /api/v1/inventories/store/1
        `${process.env.REACT_APP_BASE_URL}/api/v1/inventories/store/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...bulkInventoryData,
            room_categories: selectedValues,
          }),
        }
      );
      if (response.ok) {
        addModalButtonClickHandler();

        // Parse the JSON response
        const jsonResponse = await response.json();

        window.location.reload();
        // Reset the bulkInventoryData state
        setBulkInventoryData({
          property_id: property_id.id,
          from_date: "",
          available_rooms: "",
          update_type: "",
          room_price: "",
        });
        setLoader(false);
        // Reload the page
      } else {
        console.log("Post request failed with status:", response.status);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Ensure the loader is set to false in case of an error
      setLoader(false);
    }
  };
  const bulkInventoryDataUpdateHandleNew = async (e) => {
    e.preventDefault();

    try {
      setLoader(true);

      const response = await fetch(
        // /api/v1/inventories/store/1
        `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/store/${uniqueChannelId}/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...bulkInventoryData,
            room_categories: bulkInputValues,
          }),
        }
      );
      if (response.ok) {
        addModalButtonClickHandler();

        // Parse the JSON response
        const jsonResponse = await response.json();

        showSuccessToast(data.message);
        window.location.reload();
        // fetchInfo(property_id?.id, uniqueChannelId);
        // setInventoriesOptionShowAddModelMenuActive(false)
        // Reset the bulkInventoryData state
        setBulkInventoryData({
          property_id: property_id.id,
          from_date: "",
          available_rooms: "",
          update_type: "",
          room_price: "",
        });
        setLoader(false);
      } else {
        console.log("Post request failed with status:", response.status);
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred");
      // Ensure the loader is set to false in case of an error
      setLoader(false);
    }
  };
  const blockInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();

    setLoader(true);

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/block/${uniqueChannelId}/${property_id?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          ...blockInventoryData,
          room_categories: selectedValues,
        }),
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.message) {
          showSuccessToast(data.message);
          addModalButtonClickHandler();
          setBlockShowAddModelMenuActive(false);
          setShowAddModelMenuActive(false);
          setLoader(false);
          fetchInfo(property_id?.id, uniqueChannelId);
          setInventoriesOptionShowAddModelMenuActive(false);
        } else {
          showErrorToast(data.message);
          console.log("Post request failed with status:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const unblockInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();

    setLoader(true);

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/unblock/${uniqueChannelId}/${property_id?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          ...unblockInventoryData,
          room_categories: selectedValues,
        }),
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.message) {
          showSuccessToast(data.message);
          addModalButtonClickHandler();
          setBlockShowAddModelMenuActive(false);
          setShowAddModelMenuActive(false);
          setUnblockShowAddModelMenuActive(false);
          setLoader(false);
          fetchInfo(property_id?.id, uniqueChannelId);
          setInventoriesOptionShowAddModelMenuActive(false);
        } else {
          showErrorToast(data.message);
          console.log("Post request failed with status:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const holdInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();

    setLoader(true);
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/hold/${uniqueChannelId}/${property_id?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          ...holdInventoryData,
          room_categories: holdInputValues,
        }),
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          addModalButtonClickHandler();
          setBlockShowAddModelMenuActive(false);
          setShowAddModelMenuActive(false);
          setUnblockShowAddModelMenuActive(false);
          setLoader(false);
          fetchInfo(property_id?.id, uniqueChannelId);
          setInventoriesOptionShowAddModelMenuActive(false);
        } else {
          showErrorToast(data.message);
          console.log("Post request failed with status:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const unholdInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();

    setLoader(true);

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/unhold/${uniqueChannelId}/${property_id?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          ...blockInventoryData,
          room_categories: selectedValues,
        }),
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          addModalButtonClickHandler();
          setBlockShowAddModelMenuActive(false);
          setShowAddModelMenuActive(false);
          setUnblockShowAddModelMenuActive(false);
          setLoader(false);
          setInventoriesOptionShowAddModelMenuActive(false);
          fetchInfo(property_id?.id, uniqueChannelId);
        } else {
          showErrorToast(data.message);
          console.log("Post request failed with status:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [selectedDatePickerDate, setSelectedDatePickerDate] =
    useState(formattedDate);

  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const monthMonth = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so we add 1
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${monthMonth}-${day}`;
  };

  const CustomInput = ({ onClick }) => (
    <div onClick={onClick} className="dateRangePicker">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clipPath="url(#clip0_1270_814)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.625 2H11.75V1.25C11.75 0.835786 11.4142 0.5 11 0.5C10.5858 0.5 10.25 0.835786 10.25 1.25V2H5V1.25C5 0.835786 4.66421 0.5 4.25 0.5C3.83579 0.5 3.5 0.835786 3.5 1.25V2H2.375C1.775 2 1.25 2.525 1.25 3.125V14.375C1.25 14.975 1.775 15.5 2.375 15.5H13.625C14.225 15.5 14.75 14.975 14.75 14.375V3.125C14.75 2.525 14.225 2 13.625 2ZM2.75 14H13.25V6.5H2.75V14Z"
            fill="#888888"
          />
        </g>
        <defs>
          <clipPath id="clip0_1270_814">
            <rect
              width="15"
              height="15"
              fill="white"
              transform="translate(0.5 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );

  const handleDateChange = (date) => {
    setSelectedDatePickerDate(formatDate(date));
    fetchInfoWithDate(property_id.id, formatDate(date), uniqueChannelId);
  };

  const handleIncrementDate = () => {
    if (selectedDatePickerDate) {
      const newDate = new Date(selectedDatePickerDate);
      newDate.setDate(newDate.getDate() + 1);
      setSelectedDatePickerDate(formatDate(newDate));
      fetchInfoWithDate(property_id.id, formatDate(newDate), uniqueChannelId);
    }
  };

  const handleDecrementDate = () => {
    if (selectedDatePickerDate) {
      const newDate = new Date(selectedDatePickerDate);
      newDate.setDate(newDate.getDate() - 1);
      setSelectedDatePickerDate(formatDate(newDate));
      fetchInfoWithDate(property_id.id, formatDate(newDate), uniqueChannelId);
    }
  };

  const toggleRoomAccordion = (roomIndex) => {
    if (activeIndexes.includes(roomIndex)) {
      setActiveIndexes(activeIndexes.filter((i) => i !== roomIndex));
    } else {
      // Room is closed, so open it
      setActiveIndexes([...activeIndexes, roomIndex]);
    }
  };

  const [activeInputRateIndex, setActiveInputRateIndex] = useState(null);
  const [activeFeildName, setActiveFeildName] = useState(null);

  const [inputValuesRates, setInputValuesRates] = useState([]);
  const [inputValuesInventory, setInputValuesInventory] = useState([]);

  const [sendInputPostRateData, setSendInputPostRateData] = useState(null);

  const [sendInputPostInventoryData, setSendInputPostInventoryData] =
    useState(null);

  const handleInputRatesOnChange = (
    rateId,
    value,
    fullObject,
    fieldName,
    index
  ) => {
    setActiveFeildName(fieldName);

    setSendInputPostRateData({
      // ...sendInputPostRateData,
      [fieldName]: value,
      date: fullObject.date,
      rate_plan_type: fullObject.rate_plan_type,
      room_id: fullObject.room_id,
      rate_plan_id: fullObject.mealplan_id,
    });

    const updatedInputValues = [...inputValuesRates];

    updatedInputValues[index] = {
      ...updatedInputValues[index],
      [fieldName]: value,
    };
    setInputValuesRates(updatedInputValues);
    setActiveInputRateIndex(rateId);
  };

  const [activeInputInventoryIndex, setActiveInputInventoryIndex] =
    useState(null);
  const [activeInputInventoryRoomId, setActiveInputInventoryRoomId] =
    useState(null);

  const handleInputInventoryOnChange = (event, fullObject, index, room_id) => {
    setActiveInputInventoryRoomId(room_id);
    setSendInputPostInventoryData({
      ...sendInputPostInventoryData,
      available_rooms: event.target.value,
      date: fullObject.date,
      room_id: fullObject.room_id,
    });

    const updatedInputValues = [...inputValuesInventory];

    updatedInputValues[index] = {
      ...updatedInputValues[index],
      available_rooms: event.target.value,
    };

    setInputValuesInventory(updatedInputValues);

    setActiveInputInventoryIndex(index);
  };

  const handleButtonRemove = () => {
    if (activeInputRateIndex !== null) {
      // const newInputData = [...inputData];
      // newInputData[activeInput] = "";
      // setInputData(newInputData);
      setActiveInputRateIndex(null);
    }
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const [{ mont, yea }, setDate] = useState({ month: 1, year: 2018 });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date("Wed Feb 07 2018 00:00:00 GMT-0500 (EST)"),
    end: new Date("Sat Feb 10 2018 00:00:00 GMT-0500 (EST)"),
  });

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  const [activeTab, setActiveTab] = useState(5);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const navigate = useNavigate();

  const handleUpdateRatesSingleSaveHandler = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/set/channel/rates/${uniqueChannelId}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...sendInputPostRateData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          // fetchInfo(property_id?.id, uniqueChannelId);
          window.location.reload();
        } else if (data.error) {
          const firstErrorKey = Object.keys(data.error)[0];
          const firstErrorMessage = data.error[firstErrorKey][0];
          showErrorToast(firstErrorMessage);
          showErrorToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      });
  };

  const handleUpdateInventoryHandler = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/set/channel/inventory/${uniqueChannelId}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...sendInputPostInventoryData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          window.location.reload();
        } else if (data.error) {
          showErrorToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      });
  };

  const [channelsArray, setChannelsArray] = useState([]);

  useEffect(() => {
    if (property_id) {
      fetchChannelArray(property_id?.id);
    }
  }, [property_id]);

  const fetchChannelArray = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/mapped/channels/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setChannelsArray(data.data);
      setUniqueChannelId(data.data[0].id);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  return (
    <>
      <SidebarInnerLists
        innerLists={rooms_inner_lists}
        selectAtLast={true}
        other_select_nav_items_inventory={
          other_select_nav_items_channel_inventory
        }
        firstSelected={"Channel Inventory"}
        firstSelectedPermission={"read_channel_inventory"}
        firstSelectedLink={"/channels"}
      />
      {loader ? (
        <Loader />
      ) : (
        <>
          {data && data.date_range && data.date_range.length > 0 ? (
            <div className="room-types-full-container">
              <div className="room-types-full-container-div">
                <div className="room-type-heading">Channels Inventory</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "auto",
                      }}
                      className="dateRangePickerContainer"
                    >
                      <button
                        className="dateRangePickerButtonone"
                        onClick={handleDecrementDate}
                        disabled={!selectedDatePickerDate}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="10"
                          viewBox="0 0 7 10"
                          fill="none"
                        >
                          <path
                            d="M6 1L2 5L6 9"
                            stroke="#888888"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </button>
                      <DatePicker
                        selected={
                          selectedDatePickerDate
                            ? new Date(selectedDatePickerDate)
                            : null
                        }
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd" // Use the desired date format
                        customInput={<CustomInput />}
                      />
                      <button
                        onClick={handleIncrementDate}
                        disabled={!selectedDatePickerDate}
                        className="dateRangePickerButtontwo"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="10"
                          viewBox="0 0 7 10"
                          fill="none"
                        >
                          <path
                            d="M1 9L5 5L0.999999 1"
                            stroke="#888888"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div>
                    <BgThemeButton
                      onClick={() => setShowModelMenuActive(true)}
                      children={"Bulk Updates"}
                    />
                  </div>
                </div>
              </div>

              <div className="inventory-full-container">
                <div
                  style={{
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="inventory-first-row-head">
                    {channelsArray &&
                      channelsArray.map((channel, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() =>
                              fetchChannelInfo(
                                channel.id,
                                property_id.id,
                                index
                              )
                            }
                            className={`booking-first-row-head-divs ${
                              uniqueChannelId === channel.id ? "active" : ""
                            }`}
                          >
                            {channel.channel_name}
                          </div>
                        );
                      })}

                    {/* <div
                      onClick={() => setPurchaseHeading(1)}
                      className={`booking-first-row-head-divs ${
                        uniqueChannelId === 1 ? "active" : ""
                      }`}
                    >
                      Website
                    </div>
                    <div
                      onClick={() => setPurchaseHeading(2)}
                      className={`booking-first-row-head-divs ${
                        uniqueChannelId === 2 ? "active" : ""
                      }`}
                    >
                      Tripadvisor
                    </div>
                    <div
                      onClick={() => setPurchaseHeading(3)}
                      className={`booking-first-row-head-divs ${
                        uniqueChannelId === 3 ? "active" : ""
                      }`}
                    >
                      Makemytrip
                    </div>
                    <div
                      onClick={() => setPurchaseHeading(4)}
                      className={`booking-first-row-head-divs ${
                        uniqueChannelId === 4 ? "active" : ""
                      }`}
                    >
                      Goibibo
                    </div>
                    <div
                      onClick={() => setPurchaseHeading(5)}
                      className={`booking-first-row-head-divs ${
                        uniqueChannelId === 5 ? "active" : ""
                      }`}
                    >
                      Agoda
                    </div>
                    <div
                      onClick={() => setPurchaseHeading(6)}
                      className={`booking-first-row-head-divs ${
                        uniqueChannelId === 6 ? "active" : ""
                      }`}
                    >
                      OYO
                    </div> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <div>
                      <BgThemeButton
                        onClick={() =>
                          setInventoriesOptionShowAddModelMenuActive(true)
                        }
                        children={"Inventory Options"}
                      />
                    </div> */}
                    {/* <div>
                      <BgOutlineThemeButton
                        onClick={unholdAddModalButtonClickHandler}
                        children={"Unhold Inventory"}
                      />
                    </div>
                    <div>
                      <BgOutlineThemeButton
                        onClick={unblockAddModalButtonClickHandler}
                        children={"Unblock Inventory"}
                      />
                    </div>
                    <div>
                      <BgOutlineThemeButton
                        onClick={blockAddModalButtonClickHandler}
                        children={"Block Inventory"}
                      />
                    </div>
                    <div>
                      <BgThemeButton
                        onClick={addModalButtonClickHandler}
                        children={"Bulk Updates"}
                      />
                    </div> */}
                  </div>
                </div>

                <div className="inventory-second-column-2div">
                  <div
                    style={{
                      borderRadius: "6px",
                    }}
                  >
                    <div
                      style={{
                        background: "#EFF0F2",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        padding: "5px 33px",
                        borderBottom: "0.5px solid #CCC",
                      }}
                    >
                      <div
                        style={{
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Room & Rates
                      </div>

                      <div
                        className="inventory-dates"
                        style={{ display: "flex", gap: "39px" }}
                      >
                        {data?.date_range?.map((date, index) => {
                          return (
                            <div
                              onClick={() => dateClickHandler(date)}
                              key={index}
                              className="inventory-dates-div"
                              style={{
                                padding: "10px 26px",
                                width: "80px",
                                borderRadius: "4px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <div className="inventory-upper-date">
                                {date.slice(8, 10)}
                              </div>

                              <div className="inventory-upper-month">
                                {months[parseInt(date.slice(5, 7), 10) - 1]}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {/* <div
                      style={{
                        borderRadius: "6px",
                        background: "#EFF0F2",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        padding: "5px 33px",
                      }}
                    >
                      <div
                        style={{
                          color: "#888",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: "600",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>Competitor Rates</div>
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12.5999 6.9999C12.5999 3.9073 10.0925 1.3999 6.9999 1.3999C3.9073 1.3999 1.3999 3.9073 1.3999 6.9999C1.3999 10.0925 3.9073 12.5999 6.9999 12.5999C10.0925 12.5999 12.5999 10.0925 12.5999 6.9999ZM6.2999 9.0999C6.2999 9.4865 6.6133 9.7999 6.9999 9.7999C7.3865 9.7999 7.6999 9.4865 7.6999 9.0999V7.6999C7.6999 7.3133 7.3865 6.9999 6.9999 6.9999C6.6133 6.9999 6.2999 7.3133 6.2999 7.6999V9.0999ZM6.2999 4.8999C6.2999 5.2865 6.6133 5.5999 6.9999 5.5999C7.3865 5.5999 7.6999 5.2865 7.6999 4.8999C7.6999 4.5133 7.3865 4.1999 6.9999 4.1999C6.6133 4.1999 6.2999 4.5133 6.2999 4.8999Z"
                              fill="#888888"
                            />
                          </svg>
                        </div>
                      </div>

                      <div style={{ display: "flex", gap: "39px" }}>
                        {data?.date_range?.map((date, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                // padding: "10px 26px",
                                width: "80px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <div className="inventory-INRPrice">
                                INR 3360.00
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div> */}
                  </div>
                </div>

                {/* <div className="inventory-third-column-3div">
                  <div style={{ marginRight: "7px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_1_1380)">
                        <path
                          d="M11.3189 0H2.6811C1.20037 0 0 1.20037 0 2.6811V11.3189C0 12.7996 1.20037 14 2.6811 14H11.3189C12.7996 14 14 12.7996 14 11.3189V2.6811C14 1.20037 12.7996 0 11.3189 0Z"
                          fill="#3968ED"
                        />
                        <path
                          d="M10.5866 7H3.41333"
                          stroke="white"
                          strokeMiterlimit="10"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_1380">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div>Hide Rates</div>
                  <div></div>
                </div> */}

                {data?.rooms?.map((room, roomIndex) => {
                  return (
                    <div
                      className="inventory-fourth-column-4div"
                      key={roomIndex}
                    >
                      <div
                        style={{
                          borderRadius: "6px",
                          border: "0.5px solid #CCC",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: "0 0 0 22px",
                            borderBottom: "0.5px solid #CCC",
                          }}
                        >
                          <div
                            style={{
                              color: "#333",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: "600",
                              width: "58%",
                            }}
                          >
                            <div
                              className="inventory-third-column-3div"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div
                                style={{ display: "flex", cursor: "pointer" }}
                                onClick={() => toggleRoomAccordion(roomIndex)}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    marginRight: "7px",
                                  }}
                                >
                                  {activeIndexes.includes(roomIndex) ? (
                                    <>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                      >
                                        <g clipPath="url(#clip0_1270_876)">
                                          <path
                                            d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                            stroke="#3968ED"
                                          />
                                          <path
                                            d="M10.5866 7H3.41333"
                                            stroke="#3968ED"
                                            strokeMiterlimit="10"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_1270_876">
                                            <rect
                                              width="14"
                                              height="14"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </>
                                  ) : (
                                    <>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                      >
                                        <g clipPath="url(#clip0_1270_1017)">
                                          <path
                                            d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                            stroke="#3968ED"
                                          />
                                          <path
                                            d="M10.5866 7H3.41333"
                                            stroke="#3968ED"
                                            strokeMiterlimit="10"
                                          />
                                          <path
                                            d="M7 3.41344L7 10.5867"
                                            stroke="#3968ED"
                                            strokeMiterlimit="10"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_1270_1017">
                                            <rect
                                              width="14"
                                              height="14"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </>
                                  )}
                                </div>
                                <div className="inventory-roomName">
                                  {room.room_name}
                                </div>
                              </div>

                              {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                <div className="updates-rates-button">
                                  Update Rates
                                </div>
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                  >
                                    <g clipPath="url(#clip0_1_1562)">
                                      <path
                                        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
                                        fill="#DAE3FC"
                                      />
                                      <path
                                        d="M5.54088 4.01685L8.50242 6.97838L5.4978 9.983"
                                        stroke="#3968ED"
                                        strokeMiterlimit="10"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1_1562">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </div>
                              </div> */}
                            </div>
                          </div>

                          <div style={{ display: "flex" }}>
                            {data?.rooms[roomIndex]?.inventory
                              ?.slice(0, 7)
                              .map((data, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      width: "120px",
                                      height: "60px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        gap: "5px",
                                      }}
                                    >
                                      {data.blocked ? (
                                        <input
                                          value={"00"}
                                          disabled={true}
                                          className="inventory-1st-column-inner-input"
                                          style={{
                                            width: "40px",
                                            backgroundColor: "#ffd3d3",
                                          }}
                                          type="text"
                                        />
                                      ) : (
                                        <>
                                          <input
                                            defaultValue={data.available_rooms}
                                            value={
                                              inputValuesInventory.available_rooms
                                            }
                                            className="inventory-1st-column-inner-input"
                                            style={{ width: "40px" }}
                                            type="number"
                                            onChange={(e) =>
                                              handleInputInventoryOnChange(
                                                e,
                                                data,
                                                index,
                                                data.room_id
                                              )
                                            }
                                          />
                                        </>
                                      )}
                                      {/* <input
                                        value={data.available_rooms}
                                        className="inventory-1st-column-inner-input"
                                        style={{ width: "40px" }}
                                        type="text"
                                      /> */}

                                      {activeInputInventoryIndex === index &&
                                      activeInputInventoryRoomId ===
                                        data.room_id ? (
                                        <button
                                          style={{
                                            backgroundColor: "#3968ed",
                                            color: "white",
                                          }}
                                          onClick={() =>
                                            handleUpdateInventoryHandler()
                                          }
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <div className="inventory-1st-column-inner-input-bottom-text">
                                          {data?.sold_rooms} Sold /{" "}
                                          {data?.hold_rooms} Hold
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>

                        {/* inner div */}

                        {activeIndexes.includes(roomIndex) && (
                          <>
                            {Object.keys(room.rates).map(
                              (rateType, rateIndex) => {
                                return (
                                  <div key={rateIndex}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        padding: "0 0 0 18px",
                                        borderBottom: "0.5px solid #CCC",
                                        height: expandedItems.includes(
                                          rateIndex
                                        )
                                          ? "60px"
                                          : "100%",
                                        overflow: "hidden",
                                        transition: "height 0.5s ease-in-out",
                                      }}
                                    >
                                      <div
                                        onClick={() =>
                                          togglePlanExpand(rateIndex)
                                        }
                                        style={{
                                          color: "#333",
                                          fontFamily: "Inter",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          width: "100%",
                                        }}
                                      >
                                        <div style={{}}>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-start",
                                              alignItems: "center",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <div className="inventory-third-column-3div">
                                              <div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    marginRight: "7px",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      marginRight: "10px",
                                                    }}
                                                  >
                                                    {expandedItems.includes(
                                                      rateIndex
                                                    ) ? (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        viewBox="0 0 14 14"
                                                        fill="none"
                                                      >
                                                        <g clipPath="url(#clip0_1270_978)">
                                                          <path
                                                            d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                            stroke="#3968ED"
                                                          />
                                                          <path
                                                            d="M10.5866 7H3.41333"
                                                            stroke="#3968ED"
                                                            strokeMiterlimit="10"
                                                          />
                                                          <path
                                                            d="M7 3.41344L7 10.5867"
                                                            stroke="#3968ED"
                                                            strokeMiterlimit="10"
                                                          />
                                                        </g>
                                                        <defs>
                                                          <clipPath id="clip0_1270_978">
                                                            <rect
                                                              width="14"
                                                              height="14"
                                                              fill="white"
                                                            />
                                                          </clipPath>
                                                        </defs>
                                                      </svg>
                                                    ) : (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        viewBox="0 0 14 14"
                                                        fill="none"
                                                      >
                                                        <path
                                                          d="M10.5866 7H3.41333"
                                                          stroke="#3968ED"
                                                          strokeMiterlimit="10"
                                                        />
                                                      </svg>
                                                    )}

                                                    {/* : <span>+</span> */}
                                                    {/* } */}
                                                  </div>
                                                  <div>{rateType}</div>
                                                </div>
                                              </div>
                                              <div></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div>{room.rate}</div> */}

                                      <div style={{ display: "flex" }}>
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              flexDirection: "column",
                                            }}
                                          >
                                            {dummyRoomPlansName.map(
                                              (roomType, index) => {
                                                return (
                                                  <div
                                                    key={index}
                                                    style={{
                                                      width: "120px",
                                                      height: "60px",
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      border:
                                                        "0.5px solid #CCC",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                      }}
                                                    >
                                                      <div>{roomType.svg}</div>
                                                      <div
                                                        style={{
                                                          color: "#888",
                                                          fontSize: "12px",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        {roomType.name}
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>

                                        {Object.keys(room.rates[rateType]).map(
                                          (date) => {
                                            return (
                                              <>
                                                <div key={date}>
                                                  {room.rates[rateType][
                                                    date
                                                  ].map((rate) => {
                                                    return (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                          flexDirection:
                                                            "column",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            flexDirection:
                                                              "column",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              flexDirection:
                                                                "column",
                                                            }}
                                                          >
                                                            <>
                                                              <div
                                                                style={{
                                                                  width:
                                                                    "120px",
                                                                  height:
                                                                    "60px",
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "center",
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "column",
                                                                  border:
                                                                    "0.5px solid #CCC",
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "center",
                                                                    alignItems:
                                                                      "center",
                                                                    flexDirection:
                                                                      "column",
                                                                    gap: "5px",
                                                                  }}
                                                                >
                                                                  <input
                                                                    value={
                                                                      inputValuesRates.rate_bar_price
                                                                    }
                                                                    defaultValue={
                                                                      rate.rate_bar_price
                                                                    }
                                                                    className="inventory-1st-column-inner-input"
                                                                    style={{
                                                                      width:
                                                                        "55px",
                                                                    }}
                                                                    type="number"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleInputRatesOnChange(
                                                                        rate.id,
                                                                        e.target
                                                                          .value,
                                                                        rate,
                                                                        "rate_bar_price",
                                                                        0
                                                                      )
                                                                    }
                                                                  />
                                                                  {activeInputRateIndex ===
                                                                    rate.id &&
                                                                    activeFeildName ===
                                                                      "rate_bar_price" && (
                                                                      <button
                                                                        style={{
                                                                          backgroundColor:
                                                                            "#3968ed",
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                        onClick={() =>
                                                                          handleUpdateRatesSingleSaveHandler()
                                                                        }
                                                                      >
                                                                        Save
                                                                      </button>
                                                                    )}
                                                                </div>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  width:
                                                                    "120px",
                                                                  height:
                                                                    "60px",
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "center",
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "column",
                                                                  border:
                                                                    "0.5px solid #CCC",
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "center",
                                                                    alignItems:
                                                                      "center",
                                                                    flexDirection:
                                                                      "column",
                                                                    gap: "5px",
                                                                  }}
                                                                >
                                                                  <input
                                                                    defaultValue={
                                                                      rate.extra_adult
                                                                    }
                                                                    value={
                                                                      inputValuesRates.extra_adult
                                                                    }
                                                                    className="inventory-1st-column-inner-input"
                                                                    style={{
                                                                      width:
                                                                        "55px",
                                                                    }}
                                                                    type="text"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleInputRatesOnChange(
                                                                        rate.id,
                                                                        e.target
                                                                          .value,
                                                                        rate,
                                                                        "extra_adult",
                                                                        1
                                                                      )
                                                                    }
                                                                  />
                                                                  {activeInputRateIndex ===
                                                                    rate.id &&
                                                                    activeFeildName ===
                                                                      "extra_adult" && (
                                                                      <button
                                                                        style={{
                                                                          backgroundColor:
                                                                            "#3968ed",
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                        onClick={() =>
                                                                          handleUpdateRatesSingleSaveHandler()
                                                                        }
                                                                      >
                                                                        Save
                                                                      </button>
                                                                    )}
                                                                </div>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  width:
                                                                    "120px",
                                                                  height:
                                                                    "60px",
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "center",
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "column",
                                                                  border:
                                                                    "0.5px solid #CCC",
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "center",
                                                                    alignItems:
                                                                      "center",
                                                                    flexDirection:
                                                                      "column",
                                                                    gap: "5px",
                                                                  }}
                                                                >
                                                                  <input
                                                                    defaultValue={
                                                                      rate.child_with_Bed
                                                                    }
                                                                    value={
                                                                      inputValuesRates.child_with_Bed
                                                                    }
                                                                    className="inventory-1st-column-inner-input"
                                                                    style={{
                                                                      width:
                                                                        "55px",
                                                                    }}
                                                                    type="text"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleInputRatesOnChange(
                                                                        rate.id,
                                                                        e.target
                                                                          .value,
                                                                        rate,
                                                                        "child_with_Bed",
                                                                        2
                                                                      )
                                                                    }
                                                                  />
                                                                  {activeInputRateIndex ===
                                                                    rate.id &&
                                                                    activeFeildName ===
                                                                      "child_with_Bed" && (
                                                                      <button
                                                                        style={{
                                                                          backgroundColor:
                                                                            "#3968ed",
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                        onClick={() =>
                                                                          handleUpdateRatesSingleSaveHandler()
                                                                        }
                                                                      >
                                                                        Save
                                                                      </button>
                                                                    )}
                                                                </div>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  width:
                                                                    "120px",
                                                                  height:
                                                                    "60px",
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "center",
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "column",
                                                                  border:
                                                                    "0.5px solid #CCC",
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "center",
                                                                    alignItems:
                                                                      "center",
                                                                    flexDirection:
                                                                      "column",
                                                                    gap: "5px",
                                                                  }}
                                                                >
                                                                  <input
                                                                    defaultValue={
                                                                      rate.child_without_Bed
                                                                    }
                                                                    value={
                                                                      inputValuesRates.child_without_Bed
                                                                    }
                                                                    className="inventory-1st-column-inner-input"
                                                                    style={{
                                                                      width:
                                                                        "55px",
                                                                    }}
                                                                    type="text"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleInputRatesOnChange(
                                                                        rate.id,
                                                                        e.target
                                                                          .value,
                                                                        rate,
                                                                        "child_without_Bed",
                                                                        3
                                                                      )
                                                                    }
                                                                  />
                                                                  {activeInputRateIndex ===
                                                                    rate.id &&
                                                                    activeFeildName ===
                                                                      "child_without_Bed" && (
                                                                      <button
                                                                        style={{
                                                                          backgroundColor:
                                                                            "#3968ed",
                                                                          color:
                                                                            "white",
                                                                        }}
                                                                        onClick={() =>
                                                                          handleUpdateRatesSingleSaveHandler()
                                                                        }
                                                                      >
                                                                        Save
                                                                      </button>
                                                                    )}
                                                                </div>
                                                              </div>
                                                            </>{" "}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      // <div key={rate.id}>
                                                      //   <p>
                                                      //     Rate Plan Name:{" "}
                                                      //     {rate.rate_plan_name}
                                                      //   </p>
                                                      //   <p>
                                                      //     Rate Plan Type:{" "}
                                                      //     {rate.rate_plan_type}
                                                      //   </p>
                                                      //   <p>
                                                      //     Rate Rack Price:{" "}
                                                      //     {rate.rate_rack_price}
                                                      //   </p>
                                                      // </div>
                                                    );
                                                  })}
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                      </div>

                                      {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <>
                          <div
                            style={{
                              width: "120px",
                              height: "60px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              border: "0.5px solid #CCC",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                            >
                              <input
                                value="500"
                                className="inventory-1st-column-inner-input"
                                style={{ width: "55px" }}
                                type="text"
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              width: "120px",
                              height: "60px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              border: "0.5px solid #CCC",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                            >
                              <input
                                value="500"
                                className="inventory-1st-column-inner-input"
                                style={{ width: "55px" }}
                                type="text"
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              width: "120px",
                              height: "60px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              border: "0.5px solid #CCC",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                            >
                              <input
                                value="500"
                                className="inventory-1st-column-inner-input"
                                style={{ width: "55px" }}
                                type="text"
                              />
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div> */}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <NoDataFound />
          )}
          {/* Bulk inventories */}
          {showAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div className="add-ota-model-container-heading">
                        Bulk Update
                      </div>
                      {/* <div style={{ marginTop: "10px" }}>
                        Bulk changes will be update for only weekdays
                      </div> */}
                    </div>
                    <div
                      onClick={() => setShowAddModelMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <div
                        style={{
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: "600",
                          marginBottom: "10px",
                        }}
                      >
                        Select Room Categories
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "13px",
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {data?.rooms?.map((room) => {
                          return (
                            <>
                              <input
                                type="checkbox"
                                name="update_type"
                                onChange={handleCheckboxChange}
                                value={room.id}
                              />
                              {room.room_name}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type="date"
                        name="from_date"
                        className="textfield"
                        titleName="Start Date"
                        min={currentDate}
                        value={bulkInventoryData.from_date}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type="date"
                        name="to_date"
                        className="textfield"
                        titleName="End Date"
                        focused={false}
                        onChange={setBulkInventoryHandleChange}
                        autoComplete="off"
                        min={bulkInventoryData.from_date || currentDate}
                        value={bulkInventoryData.to_date}
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Inventory"
                        name="inventory"
                        type="number"
                        value={bulkInventoryData.inventory}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Rates"
                        type="number"
                        name="rates"
                        value={bulkInventoryData.rates}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Extra Adult"
                        name="extra_adult"
                        type="number"
                        value={bulkInventoryData.extra_adult}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Extra Child"
                        type="number"
                        name="extra_child"
                        value={bulkInventoryData.extra_child}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Extra Bed"
                        type="number"
                        name="extra_bed"
                        value={bulkInventoryData.extra_bed}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={bulkInventoryDataUpdateHandle}
                        type="submit"
                        children={"Update"}
                      />
                      <Button onClick={() => setShowAddModelMenuActive(false)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {/* Block inventory */}
          {blockShowAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div className="add-ota-model-container-heading">
                        Block Update
                      </div>
                      {/* <div style={{ marginTop: "10px" }}>
                        Bulk changes will be update for only weekdays
                      </div> */}
                    </div>
                    <div
                      onClick={() => setBlockShowAddModelMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <div
                        style={{
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: "600",
                          marginBottom: "10px",
                        }}
                      >
                        Select Room Categories
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "13px",
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {data?.rooms?.map((room) => {
                          return (
                            <>
                              <input
                                type="checkbox"
                                name="update_type"
                                onChange={handleCheckboxChange}
                                value={room.id}
                              />
                              {room.room_name}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type="date"
                        name="from_date"
                        className="textfield"
                        titleName="Start Date"
                        // min={currentDate}
                        value={blockInventoryData.from_date}
                        onChange={setBlockInventoryHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type="date"
                        name="to_date"
                        className="textfield"
                        titleName="End Date"
                        focused={false}
                        onChange={setBlockInventoryHandleChange}
                        autoComplete="off"
                        min={bulkInventoryData.from_date || currentDate}
                        value={blockInventoryData.to_date}
                      />
                    </div>
                  </div>
                  {/* <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Inventory"
                        name="inventory"
                        type="number"
                        value={bulkInventoryData.inventory}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Rates"
                        type="number"
                        name="rates"
                        value={bulkInventoryData.rates}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                  </div> */}
                  {/* <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Extra Adult"
                        name="extra_adult"
                        type="number"
                        value={bulkInventoryData.extra_adult}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Extra Child"
                        type="number"
                        name="extra_child"
                        value={bulkInventoryData.extra_child}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Extra Bed"
                        type="number"
                        name="extra_bed"
                        value={bulkInventoryData.extra_bed}
                        onChange={setBulkInventoryHandleChange}
                      />
                    </div>
                  </div> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={blockInventoryDataUpdateHandle}
                        type="submit"
                        children={"Update"}
                      />
                      <Button
                        onClick={() => setBlockShowAddModelMenuActive(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {/* Unblock Inventory */}
          {unblockShowAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div className="add-ota-model-container-heading">
                        Unblock Inventory
                      </div>
                      <div style={{ marginTop: "10px" }}></div>
                    </div>
                    <div
                      onClick={() => setUnblockShowAddModelMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <div
                        style={{
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: "600",
                          marginBottom: "10px",
                        }}
                      >
                        Select Room Categories
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "13px",
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {data?.rooms?.map((room) => {
                          return (
                            <>
                              <input
                                type="checkbox"
                                name="update_type"
                                onChange={handleCheckboxChange}
                                value={room.id}
                              />
                              {room.room_name}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type="date"
                        name="from_date"
                        className="textfield"
                        titleName="Start Date"
                        // min={currentDate}
                        value={unblockInventoryData.from_date}
                        onChange={setUnblockInventoryHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type="date"
                        name="to_date"
                        className="textfield"
                        titleName="End Date"
                        focused={false}
                        onChange={setUnblockInventoryHandleChange}
                        autoComplete="off"
                        min={unblockInventoryData.from_date || currentDate}
                        value={unblockInventoryData.to_date}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={unblockInventoryDataUpdateHandle}
                        type="submit"
                        children={"Update"}
                      />
                      <Button
                        onClick={() => setUnblockShowAddModelMenuActive(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {/* Hold Inventory */}
          {holdShowAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "500px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div className="add-ota-model-container-heading">
                        Hold Inventory
                      </div>
                      <div style={{ marginTop: "10px" }}></div>
                    </div>
                    <div
                      onClick={() => setHoldShowAddModelMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type="date"
                        name="from_date"
                        className="textfield"
                        titleName="Start Date"
                        // min={currentDate}
                        value={holdInventoryData.from_date}
                        onChange={setHoldInventoryHandleChange}
                      />
                    </div>
                    <div style={{}} className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type="date"
                        name="to_date"
                        className="textfield"
                        titleName="End Date"
                        focused={false}
                        onChange={setHoldInventoryHandleChange}
                        autoComplete="off"
                        min={holdInventoryData.from_date || currentDate}
                        value={holdInventoryData.to_date}
                      />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <div
                        style={{
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: "600",
                          marginBottom: "10px",
                        }}
                      >
                        Select Room Categories
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "13px",
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "400",
                          flexDirection: "column",
                        }}
                      >
                        {data?.rooms?.map((room) => {
                          const roomDataIndex = holdInputValues.findIndex(
                            (data) => data.room_id === room.id
                          );
                          return (
                            <div
                              key={room.id}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "10px",
                                // width:"30px"
                                // height: "60px",
                              }}
                            >
                              <div>
                                <input
                                  style={{ cursor: "pointer" }}
                                  id={room.id}
                                  type="checkbox"
                                  name="update_type"
                                  onChange={(e) =>
                                    holdHandleCheckboxChange(e, room)
                                  }
                                  value={room.id}
                                  checked={roomDataIndex !== -1}
                                />
                                <label
                                  style={{ cursor: "pointer" }}
                                  htmlFor={room.id}
                                >
                                  {" "}
                                  {room.room_name}
                                </label>
                              </div>

                              {roomDataIndex !== -1 && (
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "20px",
                                    // width: "80%",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "fex-start",
                                      alignItems: "center",
                                    }}
                                    className="form-container-div"
                                  >
                                    <div style={{ width: "112px" }}>
                                      Available rooms:
                                    </div>
                                    <div style={{ width: "40px" }}>
                                      <input
                                        onWheel={(e) => e.target.blur()}
                                        style={{
                                          width: "100%",
                                          padding: "2px 2px",
                                        }}
                                        type="number"
                                        name="available_rooms"
                                        className="textfield"
                                        // titleName="Available Rooms:"
                                        value={
                                          holdInputValues[roomDataIndex]
                                            .available_rooms
                                        }
                                        onChange={(e) =>
                                          handleHoldInputChange(
                                            e,
                                            roomDataIndex
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "fex-start",
                                      alignItems: "center",
                                    }}
                                    className="form-container-div"
                                  >
                                    <div style={{ marginRight: "6px" }}>
                                      Hold rooms:
                                    </div>
                                    <div style={{ width: "40px" }}>
                                      <input
                                        onWheel={(e) => e.target.blur()}
                                        style={{
                                          width: "100%",
                                          padding: "2px 2px",
                                        }}
                                        min={1}
                                        // required={true}
                                        type="number"
                                        name="hold_room"
                                        className="textfield"
                                        // titleName="Hold Rooms"
                                        value={
                                          holdInputValues[roomDataIndex]
                                            .hold_room
                                        }
                                        onChange={(e) =>
                                          handleHoldInputChange(
                                            e,
                                            roomDataIndex
                                          )
                                        }
                                      />
                                      {error && (
                                        <div style={{ color: "red" }}>
                                          {error}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={() => holdInventoryDataUpdateHandle()}
                        type="submit"
                        children={"Update"}
                      />
                      <Button
                        onClick={() => setHoldShowAddModelMenuActive(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {/* Unhold inventory */}
          {unholdShowAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div className="add-ota-model-container-heading">
                        Unhold Inventory
                      </div>
                      <div style={{ marginTop: "10px" }}></div>
                    </div>
                    <div
                      onClick={() => setUnholdShowAddModelMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <div
                        style={{
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: "600",
                          marginBottom: "10px",
                        }}
                      >
                        Select Room Categories
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "13px",
                          color: "#333",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {data?.rooms?.map((room) => {
                          return (
                            <>
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor={room.id}
                              >
                                <input
                                  id={room.id}
                                  type="checkbox"
                                  name="update_type"
                                  onChange={handleCheckboxChange}
                                  value={room.id}
                                />
                                {room.room_name}
                              </label>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type="date"
                        name="from_date"
                        className="textfield"
                        titleName="Start Date"
                        // min={currentDate}
                        value={blockInventoryData.from_date}
                        onChange={setBlockInventoryHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        type="date"
                        name="to_date"
                        className="textfield"
                        titleName="End Date"
                        focused={false}
                        onChange={setBlockInventoryHandleChange}
                        autoComplete="off"
                        min={bulkInventoryData.from_date || currentDate}
                        value={blockInventoryData.to_date}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={() => unholdInventoryDataUpdateHandle()}
                        type="submit"
                        children={"Update"}
                      />
                      <Button
                        onClick={() => setUnholdShowAddModelMenuActive(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {/* New UI inventory */}
          {inventoriesOptionShowAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <div
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "900px", padding: "0" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px #DDD solid",
                      padding: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <BgOutlineThemeButton
                          active={activeTab === 5 ? true : false}
                          onClick={() => handleTabClick(5)}
                          children={"Bulk Inventory Updates"}
                        />
                      </div>
                      <div>
                        <BgOutlineThemeButton
                          active={activeTab === 4 ? true : false}
                          onClick={() => handleTabClick(4)}
                          children={"Block Inventory"}
                        />
                      </div>
                      <div>
                        <BgOutlineThemeButton
                          active={activeTab === 3 ? true : false}
                          onClick={() => handleTabClick(3)}
                          children={"Unblock Inventory"}
                        />
                      </div>
                      <div>
                        <BgOutlineThemeButton
                          active={activeTab === 1 ? true : false}
                          onClick={() => handleTabClick(1)}
                          children={"Hold Inventory"}
                        />
                      </div>
                      <div>
                        <BgOutlineThemeButton
                          active={activeTab === 2 ? true : false}
                          onClick={() => handleTabClick(2)}
                          children={"Unhold Inventory"}
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        onClick={() =>
                          setInventoriesOptionShowAddModelMenuActive(false)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>
                  </div>

                  {activeTab === 1 && (
                    <>
                      <form
                        onSubmit={(e) => holdInventoryDataUpdateHandle(e)}
                        className="inventory-options-tabs-menu"
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div className="add-ota-model-container-heading">
                              Hold Inventory
                            </div>
                            <div></div>
                          </div>
                        </div>

                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="from_date"
                              className="textfield"
                              titleName="Start Date"
                              // min={currentDate}
                              value={holdInventoryData.from_date}
                              onChange={setHoldInventoryHandleChange}
                            />
                          </div>
                          <div style={{}} className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="to_date"
                              className="textfield"
                              titleName="End Date"
                              focused={false}
                              onChange={setHoldInventoryHandleChange}
                              autoComplete="off"
                              min={holdInventoryData.from_date || currentDate}
                              value={holdInventoryData.to_date}
                            />
                          </div>
                        </div>

                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <div
                              style={{
                                color: "#333",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: "600",
                                marginBottom: "10px",
                              }}
                            >
                              Select Room Categories
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "13px",
                                color: "#333",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: "400",
                                flexDirection: "column",
                              }}
                            >
                              {data?.rooms?.map((room) => {
                                const roomDataIndex = holdInputValues.findIndex(
                                  (data) => data.room_id === room.id
                                );
                                return (
                                  <div
                                    key={room.id}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      gap: "10px",
                                      // width:"30px"
                                      // height: "60px",
                                    }}
                                  >
                                    <div>
                                      <input
                                        style={{ cursor: "pointer" }}
                                        id={room.id}
                                        type="checkbox"
                                        name="update_type"
                                        onChange={(e) =>
                                          holdHandleCheckboxChange(e, room)
                                        }
                                        value={room.id}
                                        checked={roomDataIndex !== -1}
                                      />
                                      <label
                                        style={{ cursor: "pointer" }}
                                        htmlFor={room.id}
                                      >
                                        {" "}
                                        {room.room_name}
                                      </label>
                                    </div>

                                    {roomDataIndex !== -1 && (
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "20px",
                                          // width: "80%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "fex-start",
                                            alignItems: "center",
                                          }}
                                          className="form-container-div"
                                        >
                                          <div style={{ width: "112px" }}>
                                            Available rooms:
                                          </div>
                                          <div style={{ width: "40px" }}>
                                            <input
                                              onWheel={(e) => e.target.blur()}
                                              style={{
                                                width: "100%",
                                                padding: "2px 2px",
                                              }}
                                              type="number"
                                              name="available_rooms"
                                              className="textfield"
                                              // titleName="Available Rooms:"
                                              value={
                                                holdInputValues[roomDataIndex]
                                                  .available_rooms
                                              }
                                              disabled
                                              onChange={(e) =>
                                                handleHoldInputChange(
                                                  e,
                                                  roomDataIndex
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "fex-start",
                                            alignItems: "center",
                                          }}
                                          className="form-container-div"
                                        >
                                          <div style={{ marginRight: "6px" }}>
                                            Hold rooms:
                                          </div>
                                          <div style={{ width: "40px" }}>
                                            <input
                                              onWheel={(e) => e.target.blur()}
                                              style={{
                                                width: "100%",
                                                padding: "2px 2px",
                                              }}
                                              min={1}
                                              // required={true}
                                              type="number"
                                              name="hold_room"
                                              className="textfield"
                                              // titleName="Hold Rooms"
                                              value={
                                                holdInputValues[roomDataIndex]
                                                  .hold_room
                                              }
                                              onChange={(e) =>
                                                handleHoldInputChange(
                                                  e,
                                                  roomDataIndex
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "start",
                              gap: "10px",
                            }}
                          >
                            <BgThemeButton type="submit" children={"Update"} />
                            <Button
                              onClick={() =>
                                setInventoriesOptionShowAddModelMenuActive(
                                  false
                                )
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                  {activeTab === 2 && (
                    <form
                      onSubmit={(e) => unholdInventoryDataUpdateHandle(e)}
                      style={{ width: "100%" }}
                      className="inventory-options-tabs-menu"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div className="add-ota-model-container-heading">
                            Unhold Inventory
                          </div>
                          <div style={{ marginTop: "10px" }}></div>
                        </div>
                      </div>

                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <div
                            style={{
                              color: "#333",
                              fontFamily: "Inter",
                              fontSize: "16px",
                              fontWeight: "600",
                              marginBottom: "10px",
                            }}
                          >
                            Select Room Categories
                          </div>

                          <div
                            style={{
                              display: "flex",
                              gap: "13px",
                              color: "#333",
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            {data?.rooms?.map((room) => {
                              return (
                                <>
                                  <label
                                    style={{ cursor: "pointer" }}
                                    htmlFor={room.id}
                                  >
                                    {" "}
                                  </label>
                                  <input
                                    id={room.id}
                                    type="checkbox"
                                    name="update_type"
                                    onChange={handleCheckboxChange}
                                    value={room.id}
                                  />
                                  {room.room_name}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            type="date"
                            name="from_date"
                            className="textfield"
                            titleName="Start Date"
                            // min={currentDate}
                            value={blockInventoryData.from_date}
                            onChange={setBlockInventoryHandleChange}
                          />
                        </div>
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            type="date"
                            name="to_date"
                            className="textfield"
                            titleName="End Date"
                            focused={false}
                            onChange={setBlockInventoryHandleChange}
                            autoComplete="off"
                            min={bulkInventoryData.from_date || currentDate}
                            value={blockInventoryData.to_date}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            gap: "10px",
                          }}
                        >
                          <BgThemeButton type="submit" children={"Update"} />
                          <Button
                            onClick={() =>
                              setInventoriesOptionShowAddModelMenuActive(false)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </form>
                  )}
                  {activeTab === 3 && (
                    <>
                      <form
                        onSubmit={(e) => unblockInventoryDataUpdateHandle(e)}
                        style={{ width: "100%" }}
                        className="inventory-options-tabs-menu"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div className="add-ota-model-container-heading">
                              Unblock Inventory
                            </div>
                            <div style={{ marginTop: "10px" }}></div>
                          </div>
                        </div>

                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <div
                              style={{
                                color: "#333",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: "600",
                                marginBottom: "10px",
                              }}
                            >
                              Select Room Categories
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "13px",
                                color: "#333",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {data?.rooms?.map((room) => {
                                return (
                                  <>
                                    <input
                                      type="checkbox"
                                      name="update_type"
                                      onChange={handleCheckboxChange}
                                      value={room.id}
                                    />
                                    {room.room_name}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>

                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="from_date"
                              className="textfield"
                              titleName="Start Date"
                              // min={currentDate}
                              value={unblockInventoryData.from_date}
                              onChange={setUnblockInventoryHandleChange}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="to_date"
                              className="textfield"
                              titleName="End Date"
                              focused={false}
                              onChange={setUnblockInventoryHandleChange}
                              autoComplete="off"
                              min={
                                unblockInventoryData.from_date || currentDate
                              }
                              value={unblockInventoryData.to_date}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "start",
                              gap: "10px",
                            }}
                          >
                            <BgThemeButton type="submit" children={"Update"} />
                            <Button
                              onClick={() =>
                                setInventoriesOptionShowAddModelMenuActive(
                                  false
                                )
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                  {activeTab === 4 && (
                    <>
                      <form
                        onSubmit={(e) => blockInventoryDataUpdateHandle(e)}
                        style={{ width: "100%" }}
                        className="inventory-options-tabs-menu"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div className="add-ota-model-container-heading">
                              Block Update
                            </div>
                            {/* <div style={{ marginTop: "10px" }}>
                              Bulk changes will be update for only weekdays
                            </div> */}
                          </div>
                        </div>

                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <div
                              style={{
                                color: "#333",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: "600",
                                marginBottom: "10px",
                              }}
                            >
                              Select Room Categories
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "13px",
                                color: "#333",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {data?.rooms?.map((room) => {
                                return (
                                  <>
                                    <input
                                      type="checkbox"
                                      name="update_type"
                                      onChange={handleCheckboxChange}
                                      value={room.id}
                                    />
                                    {room.room_name}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>

                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="from_date"
                              className="textfield"
                              titleName="Start Date"
                              // min={currentDate}
                              value={blockInventoryData.from_date}
                              onChange={setBlockInventoryHandleChange}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="to_date"
                              className="textfield"
                              titleName="End Date"
                              focused={false}
                              onChange={setBlockInventoryHandleChange}
                              autoComplete="off"
                              min={bulkInventoryData.from_date || currentDate}
                              value={blockInventoryData.to_date}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "start",
                              gap: "10px",
                            }}
                          >
                            <BgThemeButton type="submit" children={"Update"} />
                            <Button
                              onClick={() =>
                                setInventoriesOptionShowAddModelMenuActive(
                                  false
                                )
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                  {/* {activeTab === 5 && (
                    <>
                      <form
                        onSubmit={(e) => bulkInventoryDataUpdateHandle(e)}
                        style={{ width: "100%" }}
                        className="inventory-options-tabs-menu"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div className="add-ota-model-container-heading">
                              Bulk Update
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              Bulk changes will be update for only weekdays
                            </div>
                          </div>
                        </div>

                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <div
                              style={{
                                color: "#333",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: "600",
                                marginBottom: "10px",
                              }}
                            >
                              Select Room Categories
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "13px",
                                color: "#333",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {data?.rooms?.map((room) => {
                                return (
                                  <>
                                    <input
                                      type="checkbox"
                                      name="update_type"
                                      onChange={handleCheckboxChange}
                                      value={room.id}
                                    />
                                    {room.room_name}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>

                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="from_date"
                              className="textfield"
                              titleName="Start Date"
                              // min={currentDate}
                              value={bulkInventoryData.from_date}
                              onChange={setBulkInventoryHandleChange}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="to_date"
                              className="textfield"
                              titleName="End Date"
                              focused={false}
                              onChange={setBulkInventoryHandleChange}
                              autoComplete="off"
                              min={bulkInventoryData.from_date || currentDate}
                              value={bulkInventoryData.to_date}
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              titleName="Inventory"
                              name="inventory"
                              type="number"
                              value={bulkInventoryData.inventory}
                              onChange={setBulkInventoryHandleChange}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              titleName="Rates"
                              type="number"
                              name="rates"
                              value={bulkInventoryData.rates}
                              onChange={setBulkInventoryHandleChange}
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              titleName="Extra Adult"
                              name="extra_adult"
                              type="number"
                              value={bulkInventoryData.extra_adult}
                              onChange={setBulkInventoryHandleChange}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              titleName="Extra Child"
                              type="number"
                              name="extra_child"
                              value={bulkInventoryData.extra_child}
                              onChange={setBulkInventoryHandleChange}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              titleName="Extra Bed"
                              type="number"
                              name="extra_bed"
                              value={bulkInventoryData.extra_bed}
                              onChange={setBulkInventoryHandleChange}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "start",
                              gap: "10px",
                            }}
                          >
                            <BgThemeButton type="submit" children={"Update"} />
                            <Button
                              onClick={() =>
                                setInventoriesOptionShowAddModelMenuActive(
                                  false
                                )
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </form>
                    </>
                  )} */}

                  {activeTab === 5 && (
                    <>
                      <form
                        onSubmit={(e) => bulkInventoryDataUpdateHandleNew(e)}
                        className="inventory-options-tabs-menu"
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div className="add-ota-model-container-heading">
                              Bulk Inventory Updates
                            </div>
                            <div>
                              Bulk Inventory changes will be update for only
                              weekdays
                            </div>
                          </div>
                        </div>

                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="from_date"
                              className="textfield"
                              titleName="Start Date"
                              // min={currentDate}
                              value={bulkInventoryData.from_date}
                              onChange={setBulkInventoryHandleChange}
                            />
                          </div>
                          <div style={{}} className="form-container-div">
                            <VerticalInputFieldCustom
                              required={true}
                              type="date"
                              name="to_date"
                              className="textfield"
                              titleName="End Date"
                              focused={false}
                              onChange={setBulkInventoryHandleChange}
                              autoComplete="off"
                              min={bulkInventoryData.from_date || currentDate}
                              value={bulkInventoryData.to_date}
                            />
                          </div>
                        </div>

                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <div
                              style={{
                                color: "#333",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: "600",
                                marginBottom: "10px",
                              }}
                            >
                              Select Room Categories
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "13px",
                                color: "#333",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: "400",
                                flexDirection: "column",
                              }}
                            >
                              {data?.rooms?.map((room) => {
                                const roomDataIndex = bulkInputValues.findIndex(
                                  (data) => data.room_id === room.id
                                );
                                return (
                                  <div
                                    key={room.id}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <div>
                                      <input
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "5px",
                                        }}
                                        id={room.id}
                                        type="checkbox"
                                        name="update_type"
                                        onChange={(e) =>
                                          bulkHandleCheckboxChange(e, room)
                                        }
                                        value={room.id}
                                        checked={roomDataIndex !== -1}
                                      />
                                      <label
                                        style={{ cursor: "pointer" }}
                                        htmlFor={room.id}
                                      >
                                        {room.room_name}
                                      </label>
                                    </div>

                                    {roomDataIndex !== -1 && (
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "20px",
                                          // width: "80%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "fex-start",
                                            alignItems: "center",
                                          }}
                                          className="form-container-div"
                                        >
                                          <div style={{ width: "max-content" }}>
                                            {" "}
                                            Available Rooms:
                                          </div>
                                          <div style={{ width: "40px" }}>
                                            <input
                                              disabled
                                              onWheel={(e) => e.target.blur()}
                                              style={{
                                                width: "100%",
                                                padding: "2px 2px",
                                              }}
                                              type="number"
                                              name="available_rooms"
                                              className="textfield"
                                              value={
                                                bulkInputValues[roomDataIndex]
                                                  .available_rooms
                                              }
                                              onChange={(e) =>
                                                handleBulkInputChange(
                                                  e,
                                                  roomDataIndex
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "fex-start",
                                            alignItems: "center",
                                          }}
                                          className="form-container-div"
                                        >
                                          <div style={{ width: "70px" }}>
                                            Inventory:
                                          </div>
                                          <div style={{ width: "40px" }}>
                                            <input
                                              onWheel={(e) => e.target.blur()}
                                              style={{
                                                width: "100%",
                                                padding: "2px 2px",
                                              }}
                                              type="number"
                                              name="inventory"
                                              className="textfield"
                                              // titleName="Available Rooms:"
                                              value={
                                                bulkInputValues[roomDataIndex]
                                                  .inventory
                                              }
                                              onChange={(e) =>
                                                handleBulkInputChange(
                                                  e,
                                                  roomDataIndex
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "start",
                              gap: "10px",
                            }}
                          >
                            <BgThemeButton type="submit" children={"Update"} />
                            <Button
                              onClick={() =>
                                setInventoriesOptionShowAddModelMenuActive(
                                  false
                                )
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          {showModelMenuActive && (
            <div className="bg-backdrop">
              <section
                style={{ width: "600px" }}
                className="room-type-model-container"
              >
                <h4 style={{ textAlign: "center" }}>
                  You want to bulk updates for channel rates or channel
                  inventory?
                </h4>
                {/* <p>You Want To Remove?</p> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div>
                    <BgThemeButton
                      onClick={() => navigate("/ratechannelinventory")}
                      children={"Rate Channel Updates"}
                    />
                  </div>
                  <div>
                    <BgThemeButton
                      onClick={() => {
                        setInventoriesOptionShowAddModelMenuActive(true);
                        setShowModelMenuActive(false);
                      }}
                      children={"Inventory Channel Options"}
                    />
                  </div>
                  <Button
                    onClick={() => {
                      setShowModelMenuActive(false);
                      setShowModelMenuActive(false);
                    }}
                    destructive
                  >
                    Discard
                  </Button>
                </div>
              </section>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Channels;
