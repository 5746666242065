import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import BookingStatusUpdate from "../CustomComponents/BookingStatusUpdate";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import { CustomReportTable, CustomReportTableCell, CustomReportTableRow } from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";

const ReportPaymentInvoice = ({
  departureReport,
  loader,
  settlementOrders,
}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const names = [
    {
      bill_created: "Bill Created",
      booking_id: "Booking ID",
      bill_no: "Bill No",
      booked_on: "Booked On",
      guest_name: "Guest Name",
      check_in: "Check In",
      check_out: "Check Out",
      booking_source: "Booking Source",
      status : "Status",
    },
  ];
  //   bill_created
  // :
  // "01 Jan, 1970"
  // bill_no
  // :
  // null
  // booked_on
  // :
  // "02 Feb, 2024"
  // booking_id
  // :
  // 304723
  // booking_source
  // :
  // "walk_in_bookings"
  // check_in
  // :
  // "2024-02-05"
  // check_out
  // :
  // "2024-02-07"
  // guest_name
  // :
  // null
  // status
  // :
  // null

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>{order.bill_created}</CustomReportTableCell>
        <CustomReportTableCell>{order.booking_id}</CustomReportTableCell>
        <CustomReportTableCell>
          <UnderscoreRemoveCapitalize
            text={order.bill_no ? order.bill_no : "N/A"}
          />{" "}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {" "}
          {order.booked_on ? order.booked_on : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.guest_name ? order.guest_name : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.check_in ? order.check_in : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.check_out ? order.check_out : "N/A"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          <UnderscoreRemoveCapitalize
            text={order.booking_source ? order.booking_source : "N/A"}
          />
        </CustomReportTableCell>
        <CustomReportTableCell>{order.status ? <BookingStatusUpdate status={order.status} />  : "N/A"}</CustomReportTableCell>
      </CustomReportTableRow>
    ));

    // const rowMarkup = departureReport?.map(
    //   ({
    //     id,
    //     bill_created,
    //     booking_id,
    //     bill_no,
    //     booked_on,
    //     check_in,
    //     check_out,
    //     booking_source,
    //     status,
    //   }) => (
    //     <CustomReportTableRow
    //       CustomReportTableRow
    //       id={id}
    //       key={id}
    //       // bodyStyles={{
    //       //   backgroundColor: "red",
    //       //   fontSize: "10px",
    //       // }}
    //     >
    //       <CustomReportTableCell>{item_name}</CustomReportTableCell>
    //       <CustomReportTableCell>
    //         {item_category ? item_category : "N/A"}
    //       </CustomReportTableCell>
    //       <CustomReportTableCell>
    //         {unit_name ? unit_name : "N/A"}
    //       </CustomReportTableCell>
    //       <CustomReportTableCell>{purchased_quantity}</CustomReportTableCell>
    //       <CustomReportTableCell>
    //         {formatIndianCurrency(total_amount)}
    //       </CustomReportTableCell>
    //       <CustomReportTableCell>
    //         {issued_quantity ? issued_quantity : 0}
    //       </CustomReportTableCell>
    //       <CustomReportTableCell>
    //         {formatIndianCurrency(issued_amount)}
    //       </CustomReportTableCell>
    //       <CustomReportTableCell>
    //         {remaining_quantity ? remaining_quantity : 0}
    //       </CustomReportTableCell>
    //       <CustomReportTableCell>
    //         {formatIndianCurrency(remaining_amount)}
    //       </CustomReportTableCell>
    //     </CustomReportTableRow>
    //   )
    // );

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        // <div style={{ display: "flex" }}>

        <div className="room-types-full-container reports-new-background" id="receipt">
          <div className="room-types-full-container-div">
            <div className="room-type-heading">Invoice Report </div>
            {departureReport?.length > 0 && (
              <div className="flex flex_gap_15">
                <DownloadPdfTables
                  tableData={departureReport}
                  tableNames={names}
                  downloadFileName="invoice_report"
                />
                <DownloadCSVButton downloadFileName="invoice_report" itemToDownloadID={"receipt"}/>
              </div>
            )}
          </div>

          {departureReport && departureReport.length > 0 ? (
            <>
              {/* <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={departureReport.length}
                  selectable={false}
                  headings={[
                    { title: "Bill Created" },
                    { title: "Booking ID" },
                    { title: "Bill No" },
                    { title: "Booked On" },
                    { title: "Guest Name" },
                    { title: "Check In" },
                    { title: "Check In" },
                    { title: "Booking Source" },
                    { title: "Status" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
              </LegacyCard> */}
                 <CustomReportTable
                  headings={[
                    "Bill Created",
                    "Booking ID",
                    "Bill No",
                    "Booked On",
                    "Guest Name",
                    "Check In",
                    "Check Out",
                    "Booking Source",
                    "Status",
                  ]}
                  // commonHeadings={[
                  //   "",
                  //   "",
                  //   "",
                  //   "Purchased",
                  //   "Purchased",
                  //   "Issued",
                  //   "Issued",
                  //   "Stock",
                  //   "Stock",
                  // ]}
                  headerStyles={{
                    backgroundColor: "#dddddd",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                  tableStyles={{ textAlign: "left" }}
                >
                  {rowMarkup}
                  {/* {calculateTotals} */}
                </CustomReportTable>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>

        // </div>
      )}
    </>
  );
};
export default ReportPaymentInvoice;
