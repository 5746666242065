import React from "react";
import Dashboard from "../../Dashboard";
import AgentDashboard from "../AgentDashboard/AgentDashboard";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import FrontDeskDashboard from "../FrontDeskDashboard/FrontDeskDashboard";
import HouseKeepingManager from "../HouseKeepingManager/HouseKeepingManager";
import FoodDashboard from "../FoodDashboard/FoodDashboard";
import StoreDashboard from "../StoreDashboard/StoreDashboard";
import AccountFinanceDashboard from "../AccountFinance/AccountFinanceDashboard";
import HouseKeepingStaff from "../HouseKeepingStaff/HouseKeepingStaff";
import ChefDashboard from "../FoodDashboard/ChefDashboards/ChefDashboard";
import WaiterDashBoard from "../FoodDashboard/WaiterDashboard/WaiterDashboard";
import NewHouseKeepingManager from "../HouseKeepingManager/NewHouseKeepingManager";
import NewHouseKeepingStaff from "../HouseKeepingStaff/NewHouseKeepingStaff";
import NewUi from "../FoodDashboard/NewUi/NewUi";
import TableKoT from "../FoodDashboard/FandB-KoT/TableKoT";

import ChefDashBoardNewUi from "../ChefDashBoardNewUi/ChefDashBoardNewUi";
import KotViewChefDashBoard from "../ChefDashBoardNewUi/KotViewChefDashBoard";
import RoomViewChefDashBoard from "../ChefDashBoardNewUi/RoomViewChefDashBoard";
import TableViewChefDashBoard from "../ChefDashBoardNewUi/TableViewChefDashBoard";
import OrderListFAndBDashBoard from "../FoodDashboard/OrderListFAndBDashBoard/OrderListFAndBDashBoard";
import FbKotList from "../../FbKotList";
import KOTTables from "../../UI/KOTTables";
import RestaurantSetupSettingFandbDashboard from "../ChefDashBoardNewUi/RestaurantSetupSettingFandbDashboard";
import CreateKotNew from "../../../Pages/CreateKotNew";
import CreateSegments from "../../CreateSegments";
import Discounts from "../../Discounts";
import BillListingPage from "../../Bills/BillListingPage";
import CreateBookingNewEdit from "../../CustomComponents/CreateBookingNewPageEdit/CreateBookingNewEdit";
import AddPropertyNew from "../../Setup/AddPropertyNew";
import WebCheckIn from "../../WebCheckIn/WebCheckIn";
import BookingVoid from "../../BookingVoid";
import ProfileSettingsNew from "../../Settings/ProfileSettingsNew";
import FolioSummeryBill from "../../Folio/FolioSummeryBill";
import IssueItemsPage from "../../../Pages/IssueItemsPage";
import AddPurchase from "../../../Pages/AddPurchase";
import PurchasedItems from "../../../Pages/PurchasedItems";
import NewInventory from "../../NewInventory";
import NewInventoryDetails from "../../NewInventoryDetails";
import StockStatusPage from "../../../Pages/StockStatusPage";
import GoogleMapSearch from "../../GoogleMapSearch/GoogleMapSearch";
const MainDashboard = () => {
  const role = JSON.parse(localStorage.getItem("role"));
  // const role = "f_and_b";

  return (
    <>
      {/* <StoreDashboard /> */}
      {/* <FrontDeskDashboard /> */}
      {/* <AdminDashboard /> */}
      {/* <FoodDashboard /> */}
      {/* <HouseKeepingManager /> */}
      {/* <NewHouseKeepingManager /> */}
      {/* <NewHouseKeepingStaff /> */}
      {/* <NewUi /> */}
      {/* <TableKoT /> */}
      {/* <ChefDashBoardNewUi /> */}
      {/* <New */}

      {/* <FoodDashboard /> */}
      {/* <ChefDashBoardNewUi /> */}
      {/* <OrderListFAndBDashBoard /> */}

      {/* <ChefDashBoardNewUi /> */}
      {/* <OrderListFAndBDashBoard /> */}
      {/* ........... */}
      {/* <MealPlanNewUi /> */}
      {/* <NewHouseKeepingStaff /> */}
      {/* <TableKoT/> */}
      {/* <IssueItemsPage /> */}
      {/* <NewInventory /> */}
      {/* <NewInventoryDetails /> */}
      {/* <AddPurchase />  */}
      {/* <StockStatusPage /> */}
      {/* <PurchasedItems /> */}
      {/* <RoomViewNewUi /> */}
      {/* <MealPlanNewUi/> */}

      {/* <AddPurchase /> */}
      {/* <GoogleMapSearch /> */}


      {role === "owner" ? (
        <AdminDashboard />
      ) : role === "front_desk" ? (
        <FrontDeskDashboard />
      ) : role === "housekeeping_manager" ? (
        // <HouseKeepingManager />
        <NewHouseKeepingManager />
      ) : role === "housekeeping_staff" ? (
        // <HouseKeepingStaff />
        <NewHouseKeepingStaff />
      ) : role === "f_and_b" ? (
        // <OrderListFAndBDashBoard />
        <TableKoT />
      ) : role === "chef_f_and_b" ? (
        <ChefDashBoardNewUi />
      ) : role === "steward_f_and_b" ? (
        <WaiterDashBoard />
      ) : role === "store" ? (
        <StoreDashboard />
      ) : role === "account_finance" ? (
        <AccountFinanceDashboard />
      ) : (
        <div>
          <AdminDashboard />
        </div>
      )}







     
    </>
  );
};

export default MainDashboard;
