import { useEffect, useState } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  CheckWithoutBgUnderlineBtn,
} from "../Components/UI/Buttons";
import PreviewBill from "../Components/PreviewBill";
import PreviewBillCopy from "../Components/PreviewBillCopy";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const FoodSaleBill = () => {
  const [showShareOnEmail, setShowShareOnEmail] = useState(false);
  const [showShareOnWhatsapp, setShowShareWhatsapp] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [urlToSend, setUrlToSend] = useState("");
  const shareLink = urlToSend;

  const [billEmail, setBillEmail] = useState("");

  const property_id = useSelector((state) => state.property.value);

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const navigate = useNavigate();

  let { foodsaleid } = useParams();

  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${year}-${month}-${date}`;
  }

  const [currentDate, setCurrentDate] = useState(getDate());

  const [editInputBillShow, setEditInputBillShow] = useState(false);

  async function fetchData(propertyId) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/kots/view/bill/${foodsaleid}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setBookingData(data);
    } catch (error) {
      console.error("API request error:", error);
    }
  }

  useEffect(() => {
    if (property_id) {
      fetchData(property_id?.id);
    }
  }, [property_id]);

  const [pdfFile, setPdfFile] = useState(null);

  const handlePrintClick = () => {
    window.print();
  };

  const handleDownload = () => {
    const htmlContent = document.querySelector(
      ".booking-choice-full-parent-container-left"
    );

    const dpi = 500;
    const scale = 10;
    const imageQuality = 1;

    html2canvas(htmlContent, { dpi: dpi, scale: scale }).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = 210;
      const pdfHeight = 297;

      const aspectRatio = canvas.width / canvas.height;
      let imgWidth = pdfWidth;
      let imgHeight = pdfWidth / aspectRatio;

      if (imgHeight > pdfHeight) {
        imgHeight = pdfHeight;
        imgWidth = pdfHeight * aspectRatio;
      }

      const xOffset = (pdfWidth - imgWidth) / 2;
      const yOffset = 0;

      pdf.addImage(
        canvas.toDataURL("image/jpeg", imageQuality),
        "PNG",
        xOffset,
        yOffset,
        imgWidth,
        imgHeight
      );
      const generatedPdfFile = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(generatedPdfFile);

      // Log the PDF URL
      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = pdfUrl;
      downloadLink.download = "downloaded-page.pdf";
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      setPdfFile(downloadLink);
      setUrlToSend(downloadLink);
      // Simulate a click on the download link to trigger the download
      downloadLink.click();

      // Clean up by removing the download link
      document.body.removeChild(downloadLink);
    });
  };

  return (
    <>
      <div className="main_container">
        <div className="goBackBtn">
          <Link to="/hotelier/foodsale" style={{ display: "inline-block" }}>
            <CheckWithoutBgUnderlineBtn children={"Go Back"} />
          </Link>
        </div>

        <div style={{ display: "flex", gap: "15px" }}>
          <div className="booking-choice-full-parent-container-left">
            <div
              style={{
                width: "800px",
                margin: "auto",
                backgroundColor: "#fff",
                padding: "10px",
              }}
            >
              <div style={{ width: "710px" }} className="invoice_container">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <table>
                      <tbody>
                        {bookingData && bookingData.propertyInfo && (
                          <>
                            <tr>
                              <td colSpan="2" className="property_headings">
                                {bookingData?.propertyInfo.property_name}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2" className="property_address">
                                {bookingData?.propertyInfo.property_address}
                              </td>
                            </tr>
                            <tr>
                              <td className="property_address">
                                Ph. {bookingData?.propertyInfo.property_phone}
                              </td>
                            </tr>
                            <tr>
                              <td className="property_address">
                                Email.{" "}
                                {bookingData?.propertyInfo.property_email}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="invoice_gstin">
                    <table>
                      <tbody>
                        {bookingData && bookingData.food_sale && (
                          <>
                            <tr>
                              <td className="property_address Invoice_bills">
                                Bill No :
                              </td>
                              <td className="property_address Invoice_bills">
                                FOOD-{bookingData.food_sale.bill_no}
                              </td>
                            </tr>
                            <tr>
                              <td className="property_address Invoice_bills">
                                Original Bill Date :
                              </td>
                              <td className="property_address Invoice_bills">
                                {bookingData.food_sale.kot_date}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div style={{ width: "710px" }} className="invoice_container">
                <div className="invoice_billDetails">
                  <div className="invoice_details">
                    <table className="invoice-details-table">
                      {bookingData && bookingData.food_sale && (
                        <tbody>
                          <tr>
                            <th className="property_headings property_large ">
                              Bill No.
                            </th>
                            <td className="property_headings property_small">
                              :
                            </td>
                            <td className="property_headings property_medium">
                              FOOD-{bookingData?.food_sale.bill_no}
                            </td>
                          </tr>
                          <tr>
                            <th className="property_paragraph property_large">
                              Tax
                            </th>
                            <td className=" property_paragraph property_small">
                              :
                            </td>
                            <td className="property_paragraph property_medium">
                              {bookingData.food_sale.gst
                                ? bookingData?.food_sale.gst + " %"
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <th className="property_paragraph property_large addressInvoice">
                              Sub Total
                            </th>
                            <td className="property_paragraph property_small addressInvoice">
                              :
                            </td>
                            <td className="property_paragraph property_medium">
                              {bookingData?.food_sale.sub_total}
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="invoice_details">
                    <table className="invoice-Detail-table">
                      <tbody>
                        {bookingData && bookingData.food_sale && (
                          <>
                            <tr>
                              <th className="property_headings property_large">
                                Sale Date
                              </th>
                              <td className="property_headings property_small">
                                :
                              </td>
                              <td className=" property_headings property_medium">
                                {bookingData.food_sale.kot_date}
                              </td>
                            </tr>
                            <tr>
                              <th className="property_paragraph property_large">
                                Discount
                              </th>
                              <td className="property_paragraph property_small">
                                :
                              </td>
                              <td className="property_paragraph property_medium">
                                {bookingData.food_sale.discount
                                  ? bookingData?.food_sale.discount + " %"
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <th className="property_paragraph property_large">
                                Paid Amount
                              </th>
                              <td className="property_paragraph property_small">
                                :
                              </td>
                              <td className="property_paragraph property_medium">
                                {bookingData.food_sale.paid_amount}
                              </td>
                            </tr>
                            <tr>
                              <th className="property_paragraph property_large">
                                Balance Amount
                              </th>
                              <td className="property_paragraph property_small">
                                :
                              </td>
                              <td className="property_paragraph property_medium">
                                {bookingData.food_sale.balance_amount}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div style={{ width: "710px" }} className="invoice_container">
                <div className="transaction-table-container" style={{}}>
                  <table className="transaction-table">
                    <thead>
                      <tr className="table-head-row">
                        <th className="table-header">Item Name</th>
                        <th className="table-header">Item Price</th>
                        <th className="table-header">Quantity</th>
                        <th className="table-header">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookingData.food_sale &&
                        bookingData.food_sale.items.map((item) => {
                          return (
                            <tr>
                              <td className="table-data">
                                {item.purchase_item_name}
                              </td>
                              <td className="table-data">{item.item_price}</td>
                              <td className="table-data">{item.quantity}</td>
                              <td className="table-data">
                                {item.total_amount}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="invoice_container">
                <div className="amount_to_pay">
                  {bookingData && bookingData.food_sale && (
                    <>
                      <div className="invoice_charges">
                        <p className="invoice_total">Total</p>
                        <p className="invoice_money">
                          <b>₹{bookingData.food_sale.grand_total || ""}</b>
                        </p>
                      </div>
                      <div className="invoice_charges">
                        <p className="invoice_total">Balance</p>
                        <p className="invoice_money">
                          <b>₹{bookingData.food_sale.balance_amount}</b>
                        </p>
                      </div>
                      {/* <div className="invoice_charges">
                                        <p className="invoice_total">VAT</p>
                                        <p className="invoice_money">
                                            <b>₹{bookingData.booking.tax_amount || ""}</b>
                                        </p>
                                    </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="booking-choice-full-parent-container-right ">
            <div className="share_preview_bill">
              <div style={{ marginTop: "20px" }} className={"flex flex_gap_10"}>
                <BgOutlineThemeButton
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                    >
                      <path
                        d="M13.2851 5.92918H4.71507V3.07251H13.2851V5.92918ZM13.2851 9.85709C13.4874 9.85709 13.6571 9.78853 13.7943 9.65141C13.9314 9.51429 13.9997 9.3448 13.9992 9.14293C13.9992 8.94058 13.9307 8.77085 13.7936 8.63373C13.6564 8.49661 13.4869 8.42828 13.2851 8.42876C13.0827 8.42876 12.913 8.49732 12.7759 8.63444C12.6387 8.77156 12.5704 8.94106 12.5709 9.14293C12.5709 9.34527 12.6395 9.51501 12.7766 9.65213C12.9137 9.78925 13.0832 9.85757 13.2851 9.85709ZM11.8567 14.4992V11.6425H6.1434V14.4992H11.8567ZM13.2851 15.9275H4.71507V13.0708H1.8584V8.78584C1.8584 8.1788 2.0667 7.66984 2.48329 7.25896C2.89989 6.84807 3.40576 6.64287 4.0009 6.64334H13.9992C14.6063 6.64334 15.1152 6.84878 15.5261 7.25967C15.937 7.67055 16.1422 8.17928 16.1417 8.78584V13.0708H13.2851V15.9275Z"
                        fill="#3968ED"
                      />
                    </svg>
                  }
                  onClick={handlePrintClick}
                  children={"Print"}
                />
                <BgThemeButton
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        d="M9.50008 12.3566L5.92924 8.78579L6.92908 7.75025L8.78591 9.60708V3.78662H10.2142V9.60708L12.0711 7.75025L13.0709 8.78579L9.50008 12.3566ZM5.21508 15.2133C4.82229 15.2133 4.48591 15.0733 4.20596 14.7934C3.92601 14.5134 3.78627 14.1773 3.78674 13.785V11.6425H5.21508V13.785H13.7851V11.6425H15.2134V13.785C15.2134 14.1777 15.0734 14.5141 14.7935 14.7941C14.5135 15.074 14.1774 15.2138 13.7851 15.2133H5.21508Z"
                        fill="white"
                      />
                    </svg>
                  }
                  children={"Download"}
                  onClick={handleDownload}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FoodSaleBill;
