import {
  TextField,
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Button,
  Icon,
  Tooltip,
} from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useEffect, useCallback } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
} from "./UI/Buttons";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import Loader from "./UI/Loader/Loader";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import {
  other_select_nav_items_inventory,
  rooms_inner_lists,
} from "../assets/constant";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import NoDataFound from "./CustomComponents/NoDataFound";
import { useSelector } from "react-redux";
import CustomPagination from "./CustomComponents/CustomPagination/CustomPagination";

import { showErrorToast, showSuccessToast } from "../assets/toastUtils";

const ToggleActiveDisable = (props) => {
  const [isToggled, setIsToggled] = useState(props.status === "enable");
  const [beddingFinalValue, setBeddingFinalValue] = useState("");
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const user = JSON.parse(localStorage.getItem("user"));
  const property_id = useSelector((state) => state.property.value);

  const handleToggle = useCallback(() => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  }, []);

  const handleToggleStatus = async () => {
    if (isToggled) {
      // setBeddingFinalValue("enabled");
      await handleDisable();
    } else {
      await handleEnable();
      // setBeddingFinalValue("enabled");
    }
  };
  const [loader, setLoader] = useState(true);
  const currentDate = new Date().toISOString().split("T")[0];
  const handleDisable = async () => {
    const userId = props.userId;
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/update/active/status/0/${props.userId}/${property_id?.id}`,
        {
          method: "GET",
          // body: JSON.stringify({ user_id: userId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setIsToggled(false);
        window.location.reload();
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const handleEnable = async () => {
    const userId = props.userId;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/update/active/status/1/${props.userId}/${property_id?.id}`,
        {
          method: "GET",
          // body: JSON.stringify({ user_id: userId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setIsToggled(1);
        window.location.reload();
      } else {
        showErrorToast(data.message);
        setIsToggled(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  useEffect(() => {
    if (isToggled === false) {
      setBeddingFinalValue("Disabled");
    } else {
      setBeddingFinalValue("Enabled");
    }
  }, [isToggled]);

  return (
    <div className="flex align_items_center">
      {/* <span className="toggle_text">{beddingFinalValue} Base Category:</span> */}
      <label className="toggle-container">
        <input
          name="extra_bedding"
          type="checkbox"
          checked={isToggled}
          onChange={handleToggle}
          className="toggle-checkbox-custom"
        />
        <span
          className="toggle-slider"
          onClick={() => handleToggleStatus()}
        ></span>
      </label>
    </div>
  );
};

const RoomPlans = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
  const property_id = useSelector((state) => state.property.value);
  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(true);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  // Pagignation
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(orders.length / itemsPerPage);
    setCurrentPage(newPage > totalPages ? totalPages : newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders = orders?.slice(startIndex, endIndex);

  const [itemStrings, setItemStrings] = useState([
    "All",
    "Unpaid",
    "Open",
    "Closed",
    "Local delivery",
    "Local pickup",
  ]);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(currentOrders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const rowMarkup = currentOrders?.map(
    ({ id, meal_plan_type, meal_plan_name, is_active, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={id}
      >
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={meal_plan_type}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>{}</IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={meal_plan_name}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <ToggleActiveDisable
            positive={"enable"}
            negative={"disable"}
            status={is_active === 0 ? "disable" : "enable"}
            userId={id}
          />
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    // setShowLowerMenuSingleSelect(false);
    // setShowLowerMenuMultipleSelect(false);(!showLowerMenuAddMenu);
    clearSelection();
  };

  const removeAddModalContainer = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const fetchInfo = async (propertyId) => {
    try {
      setLoader(true); // Set loading before sending API request
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setOrders(data.plans);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
    }
  }, [property_id]);

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/edit/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        meal_type: data.mealPlan.meal_type,
        meal_name: data.mealPlan.meal_plan_name,
        lunch: data.mealPlan.lunch,
        breakfast: data.mealPlan.breakfast,
        dinner: data.mealPlan.dinner,
      };
      setAddRoomPlans(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  // Plan Type Options
  const planTypeOptions = [
    { label: "Ep", value: "Ep" },
    { label: "CP", value: "CP" },
    { label: "MAP", value: "MAP" },
    { label: "AP", value: "AP" },
  ];

  // breakfast Options
  const mealsOptions = [
    { label: "Excluded", value: "excluded" },
    { label: "Included", value: "included" },
  ];

  const [addRoomPlans, setAddRoomPlans] = useState({
    meal_type: "EP",
    meal_name: "",
    lunch: "Excluded",
    breakfast: "Excluded",
    dinner: "Excluded",
  });

  const setAddItemDataHandleChange = (event) => {
    setAddRoomPlans({
      ...addRoomPlans,
      [event.target.name]: event.target.value,
    });
  };

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/delete/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    );

    const data = await response.json();
    clearSelection();
    fetchInfo(property_id?.id);
    clearSelection();
    setShowLowerMenuMultipleSelect(false);
    setShowModelMenuActive(false);
  };

  const [buttonLoader, setButtonLoader] = useState(false);

  const handleSubmitAddMeals = (event) => {
    setButtonLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/store/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addRoomPlans, property_id: property_id?.id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => fetchInfo(property_id?.id))
      .then((json) => setShowAddModelMenuActive(false))
      .then(
        (json) => setButtonLoader(false),
        setAddRoomPlans({
          meal_type: "EP",
          meal_name: "",
          lunch: "Excluded",
          breakfast: "Excluded",
          dinner: "Excluded",
        })
      );
    setButtonLoader(false);
  };

  const updateFunction = (id) => {
    setButtonLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/mealPlans/update/${id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify(addRoomPlans),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => setButtonLoader(false))
      .then((json) => fetchInfo(property_id?.id))
      .then((json) => setShowLowerMenuSingleSelect(false))
      .then((json) => clearSelection());
  };

  return (
    <>
      <SidebarInnerLists
        innerLists={rooms_inner_lists}
        selectAtLast={true}
        other_select_nav_items_inventory={other_select_nav_items_inventory}
        firstSelected={"Inventory"}
        firstSelectedPermission={"read_inventory"}
        firstSelectedLink={"/inventory"}
      />

      {loader ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Meal Plans</div>
              <div>
                {permissions && permissions.includes("create_meal_plans") ? (
                  <BgThemeButton
                    children={"Add Plans"}
                    onClick={addModalButtonClickHandler}
                  />
                ) : (
                  <Tooltip
                    content="Please Contact Your Admin"
                    dismissOnMouseOut
                  >
                    <BgThemeButtonDisabled
                      children={"Add Plans"}
                      onClick={addModalButtonClickHandler}
                    />
                  </Tooltip>
                )}
              </div>
            </div>

            {orders && orders.length > 0 ? (
              <>
                <LegacyCard>
                  <IndexTable
                    resourceName={resourceName}
                    itemCount={currentOrders?.length}
                    selectedItemsCount={
                      allResourcesSelected ? "All" : selectedResources.length
                    }
                    onSelectionChange={handleSelectionChange}
                    headings={[
                      { title: "Plan Type" },
                      { title: " " },
                      { title: " " },
                      { title: " " },
                      { title: " " },
                      { title: " " },
                      { title: " " },
                      { title: " " },
                      { title: " " },
                      { title: " " },
                      { title: "Plan Name" },
                    ]}
                  >
                    {rowMarkup}
                  </IndexTable>
                </LegacyCard>

                <CustomPagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={orders.length}
                  onPageChange={handlePageChange}
                  clearSelection={clearSelection}
                />
              </>
            ) : (
              <NoDataFound />
            )}
          </div>

          {/* Single select */}
          {showLowerMenuSingleSelect && (
            <div className="room-type-bottom-container">
              <div className="room-type-bottom-container-div">
                <div>
                  <Button
                    plain
                    destructive
                    // onClick={showModelMenu}
                    onClick={() => showModelMenu()}
                  >
                    {permissions &&
                    permissions.includes("delete_meal_plans") ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    ) : (
                      <Tooltip
                        content="Please Contact Your Admin"
                        dismissOnMouseOut
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            opacity: "0.4",
                            cursor: "no-drop",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Tooltip>
                    )}
                  </Button>
                </div>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="room-type-bottom-div">
                    <div>Plan Type</div>
                    <div style={{ width: "70px" }}>
                      <VerticalInputSelectCustomCopy
                        options={planTypeOptions}
                        name="meal_type"
                        type="text"
                        value={addRoomPlans.meal_type}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>
                  <div className="room-type-bottom-div">
                    <div>Plan Name</div>
                    <div style={{ width: "400px" }}>
                      <VerticalInputFieldCustom
                        // required={true}
                        name="meal_name"
                        className="textfield"
                        onChange={(e) => setAddItemDataHandleChange(e)}
                        focused={false}
                        value={addRoomPlans.meal_name}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  {permissions && permissions.includes("update_meal_plans") ? (
                    <BgThemeButton
                      onClick={() => updateFunction(selectedResources[0])}
                      children={"Update"}
                      type="submit"
                      loader={buttonLoader}
                    />
                  ) : (
                    <Tooltip
                      content="Please Contact Your Admin"
                      dismissOnMouseOut
                    >
                      <BgThemeButtonDisabled
                        onClick={() => updateFunction(selectedResources[0])}
                        children={"Update"}
                        type="submit"
                      />
                    </Tooltip>
                  )}
                </form>
              </div>
            </div>
          )}

          {/* Multiple select */}
          {showLowerMenuMultipleSelect && (
            <div className="room-type-bottom-container">
              <div className="room-type-bottom-container-div">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button plain destructive onClick={() => showModelMenu()}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon source={DeleteMajor} color="base" />
                      </span>
                      <span>Remove</span>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          )}

          {/* Add Button Click */}
          {showAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className="room-plan-add-model-container"
                >
                  <h4>Add Plan</h4>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        name="meal_type"
                        options={planTypeOptions}
                        value={addRoomPlans.meal_type}
                        onChange={setAddItemDataHandleChange}
                        className="textfield"
                        focused={false}
                        titleName="Plan Type"
                        autoComplete="off"
                      />
                    </div>

                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        name="meal_name"
                        className="textfield"
                        onChange={setAddItemDataHandleChange}
                        focused={false}
                        value={addRoomPlans.meal_name}
                        titleName="Plan Name"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        name="breakfast"
                        options={mealsOptions}
                        value={addRoomPlans.breakfast}
                        onChange={setAddItemDataHandleChange}
                        className="textfield"
                        focused={false}
                        titleName="Breakfast"
                        autoComplete="off"
                      />
                    </div>

                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        name="lunch"
                        options={mealsOptions}
                        value={addRoomPlans.lunch}
                        onChange={setAddItemDataHandleChange}
                        className="textfield"
                        focused={false}
                        titleName="Lunch"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputSelectCustomCopy
                        name="dinner"
                        options={mealsOptions}
                        value={addRoomPlans.dinner}
                        onChange={setAddItemDataHandleChange}
                        className="textfield"
                        focused={false}
                        titleName="Dinner"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton
                      onClick={(e) => handleSubmitAddMeals(e)}
                      type="submit"
                      children={"Add"}
                      loader={buttonLoader}
                    />
                    <BgOutlineThemeButton
                      children={"Discard"}
                      onClick={removeAddModalContainer}
                    />
                  </div>
                </form>
              </div>
            </>
          )}

          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={() => deleteData()} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default RoomPlans;
