import { Button, Icon, Page } from "@shopify/polaris";
import React from "react";
import { BgOutlineThemeButton, BgThemeButton, PaidButton } from "./UI/Buttons";
import CustomToggleButton from "./UI/CustomToggleButton";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import { useSelector } from "react-redux";
import Loader from "./UI/Loader/Loader";

const Agent2 = () => {
  const CustomToggleButtonHandler = () => {};

  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const navigate = useNavigate();

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);

  let { agentid } = useParams();

  const [singleUserDetails, setSingleUserDetails] = useState(null);

  const [loader, setLoader] = useState(true);

  const fetchInfo = async () => {
    // /api/v1/admin/customers/details?account_id=1
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/edit/${agentid}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setLoader(false);
      const data = await response.json();
      setSingleUserDetails(data.data);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo();
    }
  }, [property_id]);

  // UseState for forms
  const [asignOwnerDate, setAsignOwnerDate] = useState({
    name: "",
    email: "",
    property_id: property_id?.id,
  });

  const setAddItemDataHandleChange = (event) => {
    setAsignOwnerDate({
      ...asignOwnerDate,
      [event.target.name]: event.target.value,
    });
  };

  const roomTypeOptions = singleUserDetails?.properties?.map((property) => {
    return { label: property.property_name, value: property.id };
  });

  const handleAsignOwnerData = (event) => {
    event.preventDefault();
    // /api/v1/admin/property/assign
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/property/assign`, {
      method: "POST",
      body: JSON.stringify({ ...asignOwnerDate }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          fetchInfo();
          showSuccessToast(data.message);

          navigate("/customers");
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  return (
    <div className="agent-full-container">
      {loader ? (
        <Loader />
      ) : (
        <>
          <div style={{ paddingLeft: "48px" }}>
            <Page
              backAction={{ content: "Products", url: `/agents` }}
              title={singleUserDetails?.agentInfo.agent_name}
              compactTitle
              subtitle={singleUserDetails?.agentInfo.agent_email}
            ></Page>
          </div>

          <div className="agent-full-container-div">
            <div className="agent-full-container-left">
              <div className="agent-full-container-left-div1">
                <div
                  style={{ width: "280px", borderRight: "0.5px solid #CCC" }}
                >
                  <span>Amount Spend</span>
                  <div>
                    {singleUserDetails?.credit_limit -
                      singleUserDetails?.balance || 0}
                  </div>
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <span>Credit Limit</span>
                  <div>{singleUserDetails?.credit_limit}</div>
                </div>
              </div>

              <div className="agent-full-container-left-div2">
                <div className="heading">Last Bookings</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "space-between",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {/* {singleUserDetails?.properties?.map((item) => {
                return (
                  <div className="booking-details-div">
                    <div>
                      <div className="booking-details-div-tag">
                        <div style={{ fontSize: "15px" }}>
                          {item.property_name}
                        </div>


                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{ marginLeft: "20px", fontSize: "12px" }}
                          className="booking-details-div-date"
                        >
                          Owner name :
                        </div>
                        <div
                          style={{ marginLeft: "20px" }}
                          className="booking-details-div-date"
                        >
                          Role
                        </div>
                      </div>
                    </div>
                    <div className="booking-details-div-money">
                      
                    </div>
                  </div>
                );
              })} */}
                  No Data Found
                </div>
                {/* <div
              onClick={(e) => setShowAddModelMenuActive(true)}
              style={{
                marginTop: "15px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <BgThemeButton children={"Asign Property"} />
            </div> */}
              </div>
            </div>

            <div className="agent-full-container-right">
              <div className="agent-full-container-right-div1">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "18px",
                  }}
                >
                  <div
                    style={{
                      color: "#333",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Agent
                  </div>
                  {permissions &&
                    permissions.includes("update_agents") &&
                    permissions.includes("delete_agents") && (
                      <Link to={`/agent/editprofile/${agentid}`}>Edit</Link>
                    )}
                </div>
                <div
                  style={{
                    color: "#333",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  <div>Contact Information</div>
                  <div
                    style={{
                      fontWeight: "500",
                      marginTop: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    {singleUserDetails?.agentInfo?.agent_phone}
                  </div>
                  <div style={{ fontWeight: "500", color: "#3968ED" }}>
                    {singleUserDetails?.agentInfo?.agent_email}
                  </div>
                </div>
              </div>
              <div className="agent-full-container-right-div1">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "18px",
                  }}
                >
                  <div
                    style={{
                      color: "#333",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Business Details
                  </div>
                  <div></div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        color: "#888",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "400",
                        marginBottom: "8px",
                      }}
                    >
                      Company Name
                    </div>
                    <div>{singleUserDetails?.company_name}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        color: "#888",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "400",
                        marginBottom: "8px",
                      }}
                    >
                      GST NO.
                    </div>
                    <div>{singleUserDetails?.agent_gst}</div>
                  </div>
                  {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    color: "#888",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginBottom: "8px",
                  }}
                >
                  Plan Type
                </div>
                <div>Travel Agency</div>
              </div> */}
                </div>
              </div>
              <div className="agent-full-container-right-div1">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "18px",
                  }}
                >
                  <div
                    style={{
                      color: "#333",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Agent Category
                  </div>
                  <div></div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        color: "#333",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "600",
                        marginBottom: "10px",
                      }}
                    >
                      {singleUserDetails?.agent_category_name}
                    </div>
                    <div
                      style={{
                        color: "#888",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "400",
                        marginBottom: "8px",
                      }}
                    >
                      {singleUserDetails?.agent_description}
                    </div>
                  </div>
                </div>
              </div>

              <div className="agent-full-container-right-div1">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "18px",
                  }}
                >
                  <div
                    style={{
                      color: "#333",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Assign Hotels
                  </div>
                  <div></div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  {singleUserDetails &&
                    singleUserDetails.assignedProperties &&
                    Object.values(singleUserDetails?.assignedProperties)}
                </div>
              </div>
            </div>
          </div>

          <div>
            {showAddModelMenuActive && (
              <>
                <div className="bg-backdrop">
                  <form
                    onSubmit={(e) => handleAsignOwnerData(e)}
                    style={{ width: "700px" }}
                    className="stock-add-model-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "15px",
                      }}
                    >
                      <div className="add-ota-model-container-heading">
                        Asign Owner
                      </div>
                      <div
                        onClick={() => setShowAddModelMenuActive(false)}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon source={CancelMajor} color="base" />
                      </div>
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          titleName="Owner Name"
                          name="name"
                          // type="number"
                          value={asignOwnerDate.name}
                          onChange={setAddItemDataHandleChange}
                        />
                      </div>
                      <div className="form-container-div">
                        <VerticalInputSelectCustomCopy
                          options={roomTypeOptions}
                          titleName="Select Property"
                          name="property_id"
                          value={asignOwnerDate.property_id}
                          onChange={setAddItemDataHandleChange}
                        />
                      </div>
                    </div>

                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          required={true}
                          titleName="Email"
                          name="email"
                          // type="email"
                          value={asignOwnerDate.email}
                          onChange={setAddItemDataHandleChange}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "start",
                          gap: "10px",
                        }}
                      >
                        <BgThemeButton type="submit" children={"Asign"} />
                        <Button
                          onClick={() => setShowAddModelMenuActive(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Agent2;
