import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "./NoDataFound";
import CustomCssComponentTableText from "./CustomCssComponentTableText";
import DownloadCsvTables from "./DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "./DownloadPdfTables/DownloadPdfTables";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import CustomIndexTable from "../Dashboard/CustomIndexTable/CustomIndexTable";
import CustomIndexTableRow from "../Dashboard/CustomIndexTable/CustomIndexTableRow";
import CustomIndexTableCell from "../Dashboard/CustomIndexTable/CustomIndexTableCell";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "./CustomReportComponent/CustomReportComponent";

import ButtonToDownloadTablePDF from "./DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "./DownloadCsvTables/DownloadCSVButton";
const ReportComplimentaryKot = ({ reportData }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const names = [
    {
      kot_id: "KOT ID",
      kot_date: "Date",
      kot_grand_total: "SubTotal",
      kot_tax_amount: "Tax",
      kot_total_amount: "Grand Total",
    },
  ];
  const rowMarkup =
    reportData &&
    reportData?.map(
      (
        {
          kot_id,
          kot_date,
          kot_grand_total,
          kot_reason,
          kot_tax_amount,
          kot_total_amount,
          rooms_occupancy,
          room_name,
          total_rooms,
          sold_rooms,
        },
        index
      ) => {
        return (
          <IndexTable.Row id={index} key={index}>
            <IndexTable.Cell>{kot_id}</IndexTable.Cell>
            <IndexTable.Cell>{kot_date}</IndexTable.Cell>

            <IndexTable.Cell>
              {formatIndianCurrency(kot_total_amount)}
            </IndexTable.Cell>
            <IndexTable.Cell>
              {formatIndianCurrency(kot_tax_amount)}
            </IndexTable.Cell>
            <IndexTable.Cell>
              {formatIndianCurrency(kot_grand_total)}
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      }
    );

  const calculateTotal = (items, key) => {
    return items.reduce((total, item) => +total + +item[key], 0);
  };

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Total"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>

      <CustomReportTableCell>
        {" "}
        {formatIndianCurrency(
          calculateTotalWithReduce(reportData, "kot_total_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(reportData, "kot_tax_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(reportData, "kot_grand_total")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
    </CustomReportTableRow>
  );

  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Complimentary KOT's</div>
          {reportData?.length > 0 && (
            <div className="flex flex_gap_15 align_items_center" id="pdf">
              <ButtonToDownloadTablePDF
                itemToDownloadID={"receipt"}
                downloadFileName={"complimentary_kot"}
                ignoreFromPrint={"pdf"}
                // pdfStyles={

                // }
              />
             <DownloadCSVButton   itemToDownloadID={"receipt"}
                downloadFileName={"complimentary_kot"}/>
            </div>
          )}
        </div>

        {reportData && reportData.length > 0 ? (
          // <>
          //   <LegacyCard>
          //     <IndexTable
          //       resourceName={resourceName}
          //       itemCount={reportData.length}
          //       selectable={false}
          //       headings={[
          //         { title: "KOT ID" },
          //         { title: "Date" },
          //         { title: "SubTotal" },
          //         { title: "Tax Amount" },
          //         { title: "Grand Total" },
          //       ]}
          //     >
          //       {rowMarkup}
          //     </IndexTable>
          //   </LegacyCard>
          // </>

          <CustomReportTable
            allItems={reportData} // table data
            // selectedItems={selectedArray} //selected items from table
            // setSelectedItems={setSelectedArray} // state from parent component to update table
            showCheckbox={false}
            headings={[
              "KOT ID",
              "Date",
              "SubTotal",
              "Tax Amount",
              "Grand Total",
              "Reason",
            ]}
            headerStyles={{
              backgroundColor: "#dddddd",
              fontSize: "12px",
              textAlign: "center",
            }}
            tableStyles={{ textAlign: "left" }}
          >
            {reportData?.map(
              ({
                id,
                count,
                item,
                sale,
                kot_id,
                kot_date,
                kot_grand_total,
                kot_reason,
                kot_tax_amount,
                kot_total_amount,
                rooms_occupancy,
                room_name,
                total_rooms,
                sold_rooms,
              }) => {
                return (
                  <CustomReportTableRow
                    id={id}
                    key={id}
                    // selectedItems={selectedArray} // send for checking the check box is checked
                    // setSelectedItems={setSelectedArray}
                    showCheckbox={false} // show the check box
                  >
                    <CustomReportTableCell>{kot_id}</CustomReportTableCell>

                    <CustomReportTableCell>{kot_date}</CustomReportTableCell>
                    <CustomReportTableCell>
                      {formatIndianCurrency(kot_total_amount)}
                    </CustomReportTableCell>
                    <CustomReportTableCell>
                      {formatIndianCurrency(kot_tax_amount)}
                    </CustomReportTableCell>
                    <CustomReportTableCell>
                      {formatIndianCurrency(kot_grand_total)}
                    </CustomReportTableCell>
                    <CustomReportTableCell>
                      {kot_reason ? kot_reason : "N/A"}
                    </CustomReportTableCell>
                  </CustomReportTableRow>
                );
              }
            )}

            {calculateTotals}
          </CustomReportTable>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};
export default ReportComplimentaryKot;
