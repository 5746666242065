import {
  ChoiceList,
  Collapsible,
  Icon,
  LegacyCard,
  LegacyStack,
  Link,
  Page,
} from "@shopify/polaris";
import React, { useRef, useState, useEffect } from "react";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import VerticalTextAreaCustom from "../CustomComponents/VerticalTextAreaCustom";
import { websites_inner_lists } from "../../assets/constant";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";

// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import LoaderSmall from "../UI/Loader/LoaderSmall";
import ReactQuill from 'react-quill';
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";

const EditBlogComp = () => {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const [selectedVisibility, setSelectedVisibility] = useState("");
  let { blogid } = useParams();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [editorHtml, setEditorHtml] = useState('');

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['align', 'code-block'],
      ['link', 'image'],
      ['undo', 'redo'], // Add undo and redo options
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    'align', 'code-block',
    'link', 'image'
  ];

  const handleTextChange = (value) => {
    setEditorHtml(value);
  };

  const handleFileUploadChange = (e) => {
    e.stopPropagation();
    setUploadedFile(e.target.files[0]);
  };

  // const handleRemoveLogobtn = (e) => {
  //   e.stopPropagation();
  //   setUploadedFile(null);
  // };

  const navigate = useNavigate();

  const handleChangeVisibility = (value) => {
    setSelectedVisibility(value[0]);
  };

  const [addBlogPostData, setAddBlogPostsData] = useState({
    title: "",
    type: "post",
    content: undefined,
    status: '',
    blog_category: "",
    meta_title: "",
    meta_description: "",
    url_handle: "",
  });

  const handleValueChange = (event) => {
    setAddBlogPostsData({
      ...addBlogPostData,
      [event.target.name]: event.target.value,
    });
  };

  const options = [
    { label: "Travel", value: "Travel" },
    { label: "Adventure", value: "Adventure" },
    { label: "Gk", value: "Gk" },
  ];

  const fetchSingleBlogData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/posts/edit/${property_id?.id}?post_id=${blogid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const fetchedData = data?.data;
      const updatedData = {
        title: fetchedData?.title || "",
        type: fetchedData?.type || "post", // Set a default type if not available
        content: fetchedData?.content !== undefined ? fetchedData.content : undefined,
        status: fetchedData?.status || "",
        blog_category: "",
        meta_title: fetchedData?.meta?.title || "",
        meta_description: fetchedData?.meta?.description || "",
        url_handle: "",
      };
      setSelectedVisibility(fetchedData?.status);
      setAddBlogPostsData(updatedData);
      setEditorHtml(updatedData.content || '');
    } catch (error) {
      console.error(error);
    }
  };


  const handleUpdateBlog = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/store/post/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addBlogPostData,
          content: editorHtml,
          post_id: blogid,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          navigate("/websites/blogs");
          showSuccessToast("Blog Updated Successfully");
        } else {
          showErrorToast("Something Went Wrong.. Please try again...");
        }
      });
    // .then(json => fetchInfo())
    // .then((json) => navigate("/agents"));
  };

  const fetchBlogFeaturedImage = (property_id) => {
    const formData = new FormData();
    formData.append("media_type", "post");
    formData.append("associated_id", blogid);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/media/fetch/${property_id}`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.media?.featured_image === null) {
          setUploadedFile(null)
        } else {
          const url = data.media.featured_image;
          setUploadedFile(url); // Use the URL directly
        }
      });
  };

  const addFeaturedImage = (event) => {
    event.preventDefault();
    setLoader(true);
    const formData = new FormData();
    formData.append("media_type", "post");
    formData.append("associated_id", blogid);
    formData.append("featured_image", uploadedFile);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/media/upload/${property_id?.id}`,
      {
        method: "POST",
        body: formData,
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setLoader(false);
        } else {
          setLoader(false);
          showErrorToast("Something Went Wrong.. Please try again...");
        }
      });
  };

  const deleteFetchedImages = async (blogFeaturedImage) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/media/delete/${blogFeaturedImage}/${property_id?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    const data = await response
      .json()
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          fetchBlogFeaturedImage(property_id?.id);
          // setEditorHtml()
          // window.location.reload();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  useEffect(() => {
    if (property_id) {
      fetchSingleBlogData();
      fetchBlogFeaturedImage(property_id?.id);
    }
  }, [property_id]);


  useEffect(() => {
    if (!addBlogPostData.content && editorHtml !== undefined) {
      setAddBlogPostsData((prevData) => ({
        ...prevData,
        content: editorHtml,
        status: selectedVisibility,
      }));
    }
  }, [selectedVisibility, editorHtml, addBlogPostData.content]);

  return (
    <>
      {/* <SidebarInnerLists innerLists={websites_inner_lists} /> */}
      <form className="flex justify_content_center" onSubmit={handleUpdateBlog}>
        <div>
          <GoBackButtonCustom buttonNextText={"Edit Blog Post"} onClick={() => navigate('/websites/blogs')} />
          <div className="edit-rooms-full-container-div">
            <div className="edit-rooms-full-container-left">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  width: "591px",
                }}
              >
                <div>
                  <LegacyCard sectioned>
                    <LegacyStack vertical>
                      <div
                        aria-controls="basic-collapsible"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      >
                        <div>Room details</div>
                      </div>
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <VerticalInputFieldCustom
                              required={true}
                              focused={false}
                              name="title"
                              titleName="Title"
                              autoComplete="off"
                              onChange={handleValueChange}
                              value={addBlogPostData.title}
                            />
                          </div>
                        </div>
                        <div className="editor_container">
                          <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            value={editorHtml || ''}
                            onChange={handleTextChange}
                            style={{ border: '1px solid #ccc' }}
                          />
                        </div>
                      </div>
                    </LegacyStack>
                  </LegacyCard>
                </div>
                <div className="rooms-edit-left-container-buttons">
                  <div>
                    <BgThemeButton type="submit" children={"Update"} />
                  </div>
                  <div style={{ color: "rgba(0, 122, 92, 1)" }}>
                    <BgOutlineThemeButton
                      onClick={() => navigate("/websites/blogs")}
                      type="button"
                      children={"Discard"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="edit-rooms-full-container-right">
              <div
                style={{ display: "flex", flexDirection: "column", gap: "15px" }}
              >
                <div>
                  <LegacyCard sectioned>
                    <LegacyStack vertical>
                      <div
                        aria_controls="basic-collapsible"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                        }}
                      >
                        <div>Visibility</div>
                      </div>
                      <ChoiceList
                        choices={[
                          { label: "Visible", value: "visible" },
                          { label: "Hidden", value: "hidden" },
                        ]}
                        selected={selectedVisibility}
                        onChange={handleChangeVisibility}
                      />
                    </LegacyStack>
                  </LegacyCard>
                </div>
                <div>
                  <LegacyCard sectioned>
                    <LegacyStack vertical>
                      {loader ? (
                        <LoaderSmall />
                      ) : (
                        <div style={{ position: "relative" }}>
                          <div
                            aria_controls="basic-collapsible"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontWeight: "700",
                              fontSize: "14px",
                              marginBottom: '20px'
                            }}
                          >
                            <div>Blog Featured Image</div>
                          </div>
                          {uploadedFile ? (
                            <>
                              <div className="featureImages_logoPreview_container">
                                <div style={{ width: "100%" }}>
                                  <img
                                    src={
                                      uploadedFile instanceof File
                                        ? URL.createObjectURL(uploadedFile)
                                        : uploadedFile.src
                                    }
                                    alt="Blog Image"
                                    height="200px"
                                    width="100%"
                                  />
                                </div>
                                <div className="featureImages_btnContainer">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      deleteFetchedImages(uploadedFile.id)
                                    }
                                    className="featureImages_removeLogobtn"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 8 8"
                                      fill="none"
                                    >
                                      <path d="M1 6.94752L7 1" stroke="black" />
                                      <path
                                        d="M7 6.94775L1 1.00023"
                                        stroke="black"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              {uploadedFile && (
                                <BgThemeButton
                                  onClick={addFeaturedImage}
                                  style={{ marginTop: "10px" }}
                                >
                                  Upload
                                </BgThemeButton>
                              )}
                            </>
                          ) : (
                            <div className="featureImages_uplaodFile_displaytext_container">
                              <p className="featured_image_text">Add Files</p>
                              <p className="featured_image_text_dragdrop">
                                or drop JPG, PNG images here
                              </p>
                              <input
                                accept="image/*"
                                type="file"
                                id="select-image"
                                onChange={handleFileUploadChange}
                                className="featureImages_upload_file_input"
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </LegacyStack>
                  </LegacyCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditBlogComp;
