import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Loader from "../../UI/Loader/Loader";
import "./ModifyReservationPopup.css";
import { calculateToDatePlus } from "../../../utils/utils";
import ModifyReservationConfirmChangeRoomCategory from "./ModifyReservationConfirmBookingComponents/ModifyReservationConfirmChangeRoomCategory";
import { getCurrentPropertyDateHandler } from "../../../utils/utils";
import ModifyReservationConfirmChangeMealPla from "./ModifyReservationConfirmBookingComponents/ModifyReservationConfirmChangeMealPla";
import ModifyReservationConfirmAmendStay from "./ModifyReservationConfirmBookingComponents/ModifyReservationConfirmAmendStay";
import ModifyReservationChangeGuestDetails from "./ModifyReservationConfirmBookingComponents/ModifyReservationChangeGuestDetails";
import ModifyReservationConfirmPaxChange from "./ModifyReservationConfirmBookingComponents/ModifyReservationConfirmPaxChange";
import {
  calculateTotalAddons,
  calculateNumberOfNights,
} from "./calculationFunctions/CalculateKeyTotalFromArrayOfObjects";
const ModifyReservationPopup = ({
  bookingID,
  setShowModifyPopup,
  getBookingData,
}) => {
  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const property_id = useSelector((state) => state.property.value);
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  const [bookingData, setBookingData] = useState({});

  const fetchBookingDetails = async () => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit-booking/${bookingID}/${property_id.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setBookingData(data.booking);
        setLoader(false);
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch booking details:", data.message);
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  useEffect(() => {
    fetchBookingDetails();
  }, [bookingID, property_id]);

  //----------------------------modify reservation--------------------
  // ============addon costs ==========================

  const [addOnCost, setAddOnCost] = useState({
    taxableAmount: 0,
    discountAmount: 0,
    taxAmount: 0,
    NetTotal: 0,
  });

  useEffect(() => {
    if (bookingData?.addons && bookingData?.addons?.length > 0) {
      setAddOnCost({
        taxableAmount:
          +calculateTotalAddons(bookingData.addons, "price") -
          +calculateTotalAddons(bookingData.addons, "discount_amount"),
        discountAmount: calculateTotalAddons(
          bookingData.addons,
          "discount_amount"
        ),
        taxAmount: calculateTotalAddons(bookingData.addons, "tax_amount"),
        NetTotal: calculateTotalAddons(bookingData.addons, "total_amount"),
      });
    }
  }, [bookingData]);
  // ========== addon cost end =========================

  //----------------------Modify reservation Pop options------------------------------

  const modifyReservationPopupButtonOptions = [
    {
      label: "Change Room Category",
      value: "change_room_category",
    },

    {
      label: "Change Meal Plan",
      value: "change_meal_plan",
    },
    {
      label: "Amend Stay",
      value: "amend_stay",
    },
    {
      label: "Guest Details",
      value: "guest_details",
    },
    {
      label: "Pax Details",
      value: "pax_details",
    },
  ];
  const [selectedOption, setSelectedOption] = useState("change_room_category");

  // ----------------------modify reservation Pop Guest Details------------------------------

  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  function formatDateMMDDYYYY(date) {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  //----------------------------------------------------------------------------

  //--------------------reasons for modification common for all popups-------------
  const [reasons, setReasons] = useState({
    changeRoomCategoryReason: "",
    changeMealPlanReason: "",
    amendStayReason: "",
    paxDetailReason: "",
  });

  const handleReasonChange = (event) => {
    const { name, value } = event.target;
    setReasons((prevReasons) => ({
      ...prevReasons,
      [name]: value,
    }));
  };

  return (
    <>
      <div>
        {/* -----------------------------Modify Reservation Popup-------------------------------- */}

        <div
          className="global_popup_container_background"
          style={{ background: "transparent" }}
        >
          <div className="global_popup_container_background">
            <div
              className="global_popup_container "
              style={{ width: "1000px", backgroundColor: "white" }}
            >
              <div className="flex flex_gap_10">
                {modifyReservationPopupButtonOptions.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {/* BgOutlineThemeButton BgThemeButton */}
                      <button
                        className={
                          selectedOption === item.value
                            ? " BgThemeButton"
                            : "BgOutlineThemeButton"
                        }
                        onClick={() => setSelectedOption(item.value)}
                      >
                        {item.label}
                      </button>
                    </React.Fragment>
                  );
                })}
                <div className="flex " style={{ alignItems: "end" }}>
                  <span> Booking ID: </span> &nbsp;{" "}
                  <b>{bookingData?.unique_booking_id}</b>
                </div>
              </div>
              <hr style={{ margin: "10px 0" }} />
              {loader ? (
                <Loader />
              ) : (
                <>
                  <div className="form-container-div">
                    {/* ===========================change room category=========================== */}
                    {selectedOption === "change_room_category" && (
                      <>
                        <ModifyReservationConfirmChangeRoomCategory
                          bookingData={bookingData}
                          addOnCost={addOnCost}
                          reasons={reasons}
                          setReasons={setReasons}
                          setShowModifyPopup={setShowModifyPopup}
                          handleReasonChange={handleReasonChange}
                          getBookingData={getBookingData}
                          fetchBookingDetails={fetchBookingDetails}
                        />
                      </>
                    )}
                    {/* =========================================================================== */}

                    {/* ==========================change meal plan=========================== */}

                    {selectedOption === "change_meal_plan" && (
                      <>
                        <ModifyReservationConfirmChangeMealPla
                          bookingData={bookingData}
                          addOnCost={addOnCost}
                          reasons={reasons}
                          setReasons={setReasons}
                          setShowModifyPopup={setShowModifyPopup}
                          handleReasonChange={handleReasonChange}
                          getBookingData={getBookingData}
                          fetchBookingDetails={fetchBookingDetails}
                        />
                      </>
                    )}

                    {/*========================================================================*/}

                    {/* ==========================amend stay=========================== */}

                    {selectedOption === "amend_stay" && (
                      <>
                        <ModifyReservationConfirmAmendStay
                          bookingData={bookingData}
                          addOnCost={addOnCost}
                          reasons={reasons}
                          setReasons={setReasons}
                          setShowModifyPopup={setShowModifyPopup}
                          handleReasonChange={handleReasonChange}
                          getBookingData={getBookingData}
                          fetchBookingDetails={fetchBookingDetails}
                          formatDate={formatDate}
                          formatDateMMDDYYYY={formatDateMMDDYYYY}
                          propertyCurrentDate={propertyCurrentDate}
                          calculateNumberOfNights={calculateNumberOfNights}
                          calculateToDatePlus={calculateToDatePlus}
                        />
                      </>
                    )}

                    {/*========================================================================*/}

                    {/*=============================edit guest details=============================*/}

                    {selectedOption === "guest_details" && (
                      <>
                        <ModifyReservationChangeGuestDetails
                          bookingData={bookingData}
                          addOnCost={addOnCost}
                          reasons={reasons}
                          setReasons={setReasons}
                          setShowModifyPopup={setShowModifyPopup}
                          handleReasonChange={handleReasonChange}
                          getBookingData={getBookingData}
                          fetchBookingDetails={fetchBookingDetails}
                          formatDate={formatDate}
                          formatDateMMDDYYYY={formatDateMMDDYYYY}
                          propertyCurrentDate={propertyCurrentDate}
                          calculateNumberOfNights={calculateNumberOfNights}
                          calculateToDatePlus={calculateToDatePlus}
                        />
                      </>
                    )}

                    {/*=============================edit guest details end=============================*/}

                    {/*================================ edit paxes start ========================== */}

                    {selectedOption === "pax_details" && (
                      <>
                        <ModifyReservationConfirmPaxChange
                          bookingData={bookingData}
                          addOnCost={addOnCost}
                          reasons={reasons}
                          setReasons={setReasons}
                          setShowModifyPopup={setShowModifyPopup}
                          handleReasonChange={handleReasonChange}
                          getBookingData={getBookingData}
                          fetchBookingDetails={fetchBookingDetails}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModifyReservationPopup;
