import {
  Button,
  Icon,
  IndexTable,
  LegacyCard,
  Page,
  Select,
  TextField,
  useIndexResourceState,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { DeleteMajor } from "@shopify/polaris-icons";
import { Link, useNavigate } from "react-router-dom";
import { BgThemeButton } from "./Buttons";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader/Loader";
import InnerLoader from "./Loader/innerLoader";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import LoaderSmall from "./Loader/LoaderSmall";

const AddCategoryAgent = ({ setShowAddModelMenuActive }) => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [
    showAddCategoriesLowerMenuActive,
    setShowAddCategoriesLowerMenuActive,
  ] = useState(false);

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);

  const navigate = useNavigate();
  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [agentCategories, setAgentCategories] = useState([]);

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } =
    useIndexResourceState(agentCategories);

  const [addAgentCategories, setAddAgentCategories] = useState({
    catName: "",
    catDescription: "",
    catDiscount: null,
  });

  const setAddAgentCategoriesHandleChange = (event) => {
    setAddAgentCategories({
      ...addAgentCategories,
      [event.target.name]: event.target.value,
    });
  };

  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  // fetch data
  const [btnStatus, setButtonStatus] = useState([]);

  const [loader, setLoader] = useState(false);

  const fetchInfo = async (id) => {
    if (!id) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/categories/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAgentCategories(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
    }
  }, [property_id]);

  const categoriesRowMarkup = agentCategories?.map(
    ({ id, agent_cat_name, agent_cat_discount, agent_cat_description, index }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>{agent_cat_name}</IndexTable.Cell>
        <IndexTable.Cell>{agent_cat_description}</IndexTable.Cell>
        <IndexTable.Cell>{agent_cat_discount}</IndexTable.Cell>

      </IndexTable.Row>
    )
  );

  const handleSubmitAddAgentCategories = (e) => {
    e.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/agent/category/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...addAgentCategories }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setShowLowerMenuSingleSelect(false);
          setShowAddCategoriesLowerMenuActive(false);
          fetchInfo(property_id?.id);
          setAddAgentCategories({
            catName: "",
            catDescription: "",
            catDiscount: "",
          });
          showSuccessToast("Category Created Successfully!");
        } else if (data.error) {
          const firstErrorKey = Object.keys(data.error)[0];
          const firstErrorMessage = data.error[firstErrorKey][0];
          setShowAddCategoriesLowerMenuActive(false);
          showErrorToast(firstErrorMessage)
        }
        else {
          showErrorToast(data.message);
          setShowAddCategoriesLowerMenuActive(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
        setShowAddCategoriesLowerMenuActive(false);
      });
  };

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/agent/category/delete/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          // clearSelection();
          setAddAgentCategories({
            catName: "",
            catDescription: "",
            catDiscount: "",
          });
          fetchInfo(property_id?.id);
          clearSelection();
          setShowLowerMenuMultipleSelect(false);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/category/fetch/${property_id?.id}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      const updatedData = {
        catName: data.data[0].agent_cat_name,
        catDescription: data.data[0].agent_cat_description,
        catDiscount: data.data[0].agent_cat_discount,
      };
      setAddAgentCategories(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const updateFunction = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/category/${property_id?.id}`,
        {
          method: "POST",
          body: JSON.stringify({ ...addAgentCategories }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        setShowLowerMenuSingleSelect(false);
        clearSelection();
        showSuccessToast("Category Updated Successfully ..");
        setLoader(false);
      } else if (data.error) {
        const firstErrorKey = Object.keys(data.error)[0];
        const firstErrorMessage = data.error[firstErrorKey][0];
        showErrorToast(firstErrorMessage);
        fetchInfo(property_id?.id);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowAddCategoriesLowerMenuActive(false);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
      // setShowAddCategoriesLowerMenuActive(false);
    }
  }, [selectedResources]);
  const handleOpenAddCategoryClick = () => {
    clearSelection();
    setShowAddCategoriesLowerMenuActive(!showAddCategoriesLowerMenuActive);
    setShowLowerMenuMultipleSelect(false);
    setShowLowerMenuSingleSelect(false);
    setAddAgentCategories({
      catName: "",
      catDescription: "",
      catDiscount: null,
    });
  };


  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  return (
    <>
      <div className="bg-backdrop">
        <section className="agent-add-categories-model-container">
          <LegacyCard>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px 15px 0 15px",
              }}
            >
              <div>
                <GoBackButtonCustom
                  buttonNextText={`Agent Category`}
                  onClick={() => setShowAddModelMenuActive(false)}
                />
              </div>
              <div>
                <BgThemeButton
                  onClick={() => handleOpenAddCategoryClick()}
                  children={"Add Categories"}
                />
              </div>
            </div>
            <div className="overflow_y_scroll_x_hidden"
              style={{
                padding: "10px",
                maxHeight: "300px",
              }}
            >
              {loader ? (
                <LoaderSmall />
              ) : (
                <>
                  {agentCategories && agentCategories.length > 0 ? (
                    <IndexTable
                      // selectable={false}
                      resourceName={resourceName}
                      itemCount={agentCategories.length}
                      selectedItemsCount={
                        allResourcesSelected ? "All" : selectedResources.length
                      }
                      onSelectionChange={handleSelectionChange}
                      headings={[
                        { title: "Category Name" },
                        { title: "Category Description" },
                        { title: "Discount %" },
                      ]}
                    >
                      {categoriesRowMarkup}
                    </IndexTable>
                  ) : (
                    <NoDataFoundSmall />
                  )}
                </>
              )}
            </div>

            {/* Single select */}
            {showLowerMenuSingleSelect && (
              <div className="room-type-bottom-container">
                <div className="room-type-bottom-container-popup-div">
                  <div>
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        {/* <span>Remove</span> */}
                      </div>
                    </Button>
                  </div>
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="room-type-bottom-div">
                      <div style={{ width: "160px" }}>
                        <VerticalInputFieldCustom
                          required={true}
                          name={"catName"}
                          className="textfield"
                          focused={false}
                          titleName="Name"
                          onChange={setAddAgentCategoriesHandleChange}
                          autoComplete="off"
                          value={addAgentCategories.catName}
                        />
                      </div>
                      <div className="room-type-bottom-div">
                        <div style={{ width: "270px" }}>
                          <VerticalInputFieldCustom
                            required={true}
                            name={"catDescription"}
                            className="textfield"
                            focused={false}
                            titleName="Description"
                            onChange={setAddAgentCategoriesHandleChange}
                            autoComplete="off"
                            value={addAgentCategories.catDescription}
                          />
                        </div>
                      </div>
                      <div className="room-type-bottom-div">
                        <div style={{ width: '83px' }}>
                          <VerticalInputFieldCustom
                            required={true}
                            name={"catDiscount"}
                            className="textfield"
                            focused={false}
                            type={"number"}
                            titleName="Discount"
                            onChange={setAddAgentCategoriesHandleChange}
                            autoComplete="off"
                            value={addAgentCategories.catDiscount}
                          />
                        </div>
                      </div>
                    </div>
                    <BgThemeButton
                      onClick={() => updateFunction(selectedResources[0])}
                      type="submit"
                      children={"Update"}
                    />
                  </form>
                </div>
              </div>
            )}

            {/* Multiple select */}
            {showLowerMenuMultipleSelect && (
              <div className="room-type-bottom-container">
                <div className="room-type-bottom-container-popup-div" style={{
                  padding: '10px 20px'
                }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button plain destructive onClick={showModelMenu}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <Icon source={DeleteMajor} color="base" />
                        </span>
                        <span>Remove</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {/* Add Button Click */}
            {showAddCategoriesLowerMenuActive && (
              <div className="room-type-bottom-container">
                <div className="room-type-bottom-container-popup-div" style={{
                  padding: '10px 20px'
                }}>
                  <form
                    onSubmit={(e) => handleSubmitAddAgentCategories(e)}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="room-type-bottom-div">
                      <div style={{ width: "160px" }}>
                        <VerticalInputFieldCustom
                          required={true}
                          name={"catName"}
                          className="textfield"
                          focused={false}
                          titleName="Category Name"
                          onChange={setAddAgentCategoriesHandleChange}
                          autoComplete="off"
                          value={addAgentCategories.catName}
                        />
                      </div>
                      <div className="room-type-bottom-div">
                        <div style={{ width: "270px" }}>
                          <VerticalInputFieldCustom
                            required={true}
                            name={"catDescription"}
                            className="textfield"
                            focused={false}
                            titleName="Category Description"
                            onChange={setAddAgentCategoriesHandleChange}
                            autoComplete="off"
                            value={addAgentCategories.catDescription}
                          />
                        </div>
                      </div>
                      <div className="room-type-bottom-div">
                        <div>
                          <VerticalInputFieldCustom
                            required={true}
                            name={"catDiscount"}
                            className="textfield"
                            focused={false}
                            type={"number"}
                            titleName="Category Discount"
                            onChange={setAddAgentCategoriesHandleChange}
                            autoComplete="off"
                            value={addAgentCategories.catDiscount}
                            max={100}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        color: "#3968ED",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton children={"Add"} type="submit" />
                    </div>
                  </form>
                </div>
              </div>
            )}
          </LegacyCard>
        </section>
      </div>
      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button onClick={showModelMenu}>Discard</Button>
                <Button onClick={() => deleteData()} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default AddCategoryAgent;
