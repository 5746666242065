import {
  TextField,
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Button,
  Icon,
  Select,
  Page,
  Tooltip,
} from "@shopify/polaris";

import { DeleteMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BgOutlineBlackButton,
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  BillGenerateButton,
  SettleBillButton,
  WithoutBgButtonBlue,
} from "./UI/Buttons";
import { useSelector } from "react-redux";
import FoodSaleStatusCustomComp from "./CustomComponents/FoodSaleStatusCustomComp";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import Loader from "./UI/Loader/Loader";
import NoDataFound from "./CustomComponents/NoDataFound";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import GoBackButtonCustom from "./GoBackButtonCustom/GoBackButtonCustom";
import CustomPagination from "./CustomComponents/CustomPagination/CustomPagination";

const FbFoodSale = () => {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false);
  const [orders, setOrders] = useState([]);
  // Pagignation
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(orders.length / itemsPerPage);
    setCurrentPage(newPage > totalPages ? totalPages : newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders = orders.slice(startIndex, endIndex);
  const navigate = useNavigate();
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddStockModelMenuActive, setShowAddStockModelMenuActive] =
    useState(false);

  const [showAddItemModelMenuActive, setShowAddItemModelMenuActive] =
    useState(false);

  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);
  const [showCheckOutSettlementDetails, setShowCheckOutSettlementDetails] =
    useState(false);

  const [settlementAddData, setSettlementAddData] = useState({
    settlement_name: "",
    payment_mode: "",
    notes: "",
    date: "",
    amount: "",
    property_id: property_id?.id,
  });

  const paymentModeOptions = [
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
  ];

  const setAddItemDataHandleChange = (event) => {
    setSettlementAddData({
      ...settlementAddData,
      [event.target.name]: event.target.value,
    });
  };

  const permission = JSON.parse(localStorage.getItem("permissions"));
  const [permissions, setPermissions] = useState(permission);

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(currentOrders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const handleGenerateBillButton = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/food/generate/bill/${id}/${property_id?.id}`,
        {
          // method: "POST",
          // body: JSON.stringify({ ...kots }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchInfo(property_id?.id);
        clearSelection();
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  // Plan Type Options
  const planTypeOptions = [
    { label: "Ep", value: "Ep" },
    { label: "CP", value: "CP" },
    { label: "MAP", value: "MAP" },
    { label: "AP", value: "AP" },
  ];

  const [planTypeSelected, setPlanTypeSelected] = useState("EP");

  const planTypeHandleSelectChange = useCallback(
    (value) => setPlanTypeSelected(value),
    []
  );

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/food/sale/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.food_sale);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
    }
  }, [property_id]);

  const [kotId, setKotId] = useState("");

  const [billBalance, setBillBalance] = useState("");

  useEffect(() => {
    setKotId(kotId);
  }, [kotId]);

  useEffect(() => {
    setBillBalance(billBalance);
  }, [billBalance]);

  const handleSettleBillButton = async (id, balance_amount, grand_total) => {
    setKotId(id);
    setBillBalance(balance_amount);
    setShowCheckOutSettlementDetails(!showCheckOutSettlementDetails);
    // set
  };

  const handleSubmitSettlement = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/store/food/settlements/${kotId}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...settlementAddData,
          property_id: property_id?.id,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setShowCheckOutSettlementDetails(false);
          fetchInfo(property_id?.id);
          setSettlementAddData({
            settlement_name: "",
            payment_mode: "",
            notes: "",
            date: "",
            amount: "",
            property_id: property_id?.id,
          });
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const rowMarkup = currentOrders.map(
    ({
      id,
      property_id,
      unique_food_sale_id,
      bill_no,
      kot_no,
      room_no,
      kot_date,
      kot_type,
      payment_type,
      status,
      gst,
      gst_type,
      discount,
      sub_total,
      grand_total,
      paid_amount,
      balance_amount,
      kot_generated_by,
      bill_generated_by,

      index,
    }) => {
      let statusButton;
      if (status === "cancelled") {
        // statusButton = <BillGenerateButton children={"Generate Bill"} />
      } else if (status === "unpaid" || status === "parsely_paid") {
        statusButton = (
          <>
            {permissions && permissions.includes("settle_bill_food_sale") ? (
              <SettleBillButton
                onClick={() =>
                  handleSettleBillButton(id, balance_amount, grand_total)
                }
                children={"Settle Bill"}
              />
            ) : (
              <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                <SettleBillButton
                  style={{ opacity: "0.7", cursor: "no-drop" }}
                  // onClick={() => handleCancelStatusButton(id)}
                  children={"Settle Bill"}
                />
              </Tooltip>
            )}
          </>
        );
      } else if (status === "paid" && bill_generated_by != null) {
        statusButton = (
          <>
            {permissions && permissions.includes("view_bill_food_sale") ? (
              <Link to={`/hotelier/foodsale/bill/${id}`} target="_blank">
                <BgOutlineThemeButton children={"View  Bill"} />
              </Link>
            ) : (
              <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                <BgOutlineThemeButton children={"View  Bill"} />
              </Tooltip>
            )}
          </>
        );
      } else if (status === "paid") {
        statusButton = (
          <>
            {permissions && permissions.includes("generate_bill_food_sale") ? (
              <BgOutlineBlackButton
                children={"Generate Bill"}
                onClick={() => handleGenerateBillButton(id)}
              />
            ) : (
              <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                <BgThemeButtonDisabled
                  children={"Generate Bill"}
                  onClick={() => handleGenerateBillButton(id)}
                />
              </Tooltip>
            )}
          </>
        );
      } else {
        statusButton = (
          <Link to={`/hotelier/foodsale/bill/${id}`} target="_blank">
            <BillGenerateButton children={"View  Bill"} />
          </Link>
        );
      }

      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={index}
        >
          <IndexTable.Cell>
            {kot_type.replace("_", " ").toUpperCase()}
          </IndexTable.Cell>
          <IndexTable.Cell>
            {bill_no ? `FOOD-${bill_no}` : `KOT-${kot_no}`}
          </IndexTable.Cell>
          <IndexTable.Cell>{kot_date}</IndexTable.Cell>
          <IndexTable.Cell>{grand_total}</IndexTable.Cell>
          <IndexTable.Cell>{balance_amount}</IndexTable.Cell>
          <IndexTable.Cell>
            <FoodSaleStatusCustomComp
              status={status}
            ></FoodSaleStatusCustomComp>
          </IndexTable.Cell>
          {/* <IndexTable.Cell>{}</IndexTable.Cell> */}
          <IndexTable.Cell>{statusButton}</IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container">
          <GoBackButtonCustom buttonNextText={"Food Sale"} onClick={() => navigate('/hotelier/kotlisting')} />

          {orders && orders.length > 0 ? (

            <>
              <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={currentOrders.length}
                  selectable={false}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: "KOT Type" },
                    { title: "Bill No. / KOT No." },
                    { title: "Date" },
                    { title: "Total Amount" },
                    { title: "Balance" },
                    { title: "Status" },
                    // { title: "" },
                    { title: "Bill" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>

                {/* Single select */}
                {showLowerMenuSingleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          gap: "20px",
                        }}
                      >
                        <Button plain destructive onClick={showModelMenu}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <Icon source={DeleteMajor} color="base" />
                            </span>
                            <span>Remove</span>
                          </div>
                        </Button>

                        <Link to={`/agent/editprofile`}>
                          <BgThemeButton children={"Edit"} />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}

                {/* Multiple select */}
                {showLowerMenuMultipleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Button plain destructive onClick={showModelMenu}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <Icon source={DeleteMajor} color="base" />
                            </span>
                            <span>Remove</span>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </LegacyCard>
              <CustomPagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={orders.length}
                onPageChange={handlePageChange}
                clearSelection={clearSelection}
              />
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      )}

      {/* Add Item Button Click */}
      {showAddItemModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="stock-add-model-container">
              <div style={{ marginLeft: "-200px" }}>
                <Page
                  backAction={{
                    content: "Products",
                    url: `/hotelier/purchase`,
                  }}
                  title="Add Issue Items"
                  compactTitle
                ></Page>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <TextField
                    type="text"
                    className="textfield"
                    focused={false}
                    label="User Type"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    type="text"
                    className="textfield"
                    focused={false}
                    label="Issued To"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    type="date"
                    className="textfield"
                    focused={false}
                    label="Issued Date"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <Select
                    className="textfield"
                    focused={false}
                    label="Item Name"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <Select
                    className="textfield"
                    focused={false}
                    label="Select Category"
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    label="Qty"
                    autoComplete="off"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                    width: "350px",
                  }}
                  className="form-container-div"
                >
                  <WithoutBgButtonBlue children={"Add More"} />
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <Select
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <Select
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>
                <div className="form-container-div">
                  <TextField
                    className="textfield"
                    focused={false}
                    autoComplete="off"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "350px",
                  }}
                  className="form-container-div"
                >
                  <WithoutBgButtonBlue children={"Add More"} />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <BgThemeButton children={"Update"} />
                <Button onClick={() => setShowAddItemModelMenuActive(false)}>
                  Cancel
                </Button>
              </div>
            </section>
          </div>
        </>
      )}

      {/* Edit Button Click */}
      {showEditModelMenuActive && (
        <>
          <div className="bg-backdrop"></div>
          <section className="room-rate-plan-edit-model-container">
            <h4>Edit Rate Plan</h4>
            <div className="edit-rooms-form-container">
              <div className="form-container-div-full">
                <Select
                  options={planTypeOptions}
                  value={planTypeSelected}
                  onChange={planTypeHandleSelectChange}
                  className="textfield"
                  focused={false}
                  label="Plan Type"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Rack Price"
                  autoComplete="off"
                  placeholder="6500.00"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Bar Price"
                  autoComplete="off"
                  placeholder="7000.00"
                />
              </div>
            </div>
            <div className="form-container-div-half">
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Extra Adult"
                  autoComplete="off"
                  placeholder="850.00"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Child With Bed"
                  autoComplete="off"
                  placeholder="500.00"
                />
              </div>
              <div className="form-container-div">
                <TextField
                  className="textfield"
                  focused={false}
                  label="Child Without Bed"
                  autoComplete="off"
                  placeholder="500.00"
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button primary>Update</Button>
              <Button onClick={() => setShowEditModelMenuActive(false)}>
                Discard
              </Button>
            </div>
          </section>
        </>
      )}

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                >
                  Discard
                </Button>
                <Button onClick={removeItemConfirm} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}

      {/* Settlement */}
      {showCheckOutSettlementDetails && (
        <div className="bg-backdrop">
          <div
            style={{ width: "700px" }}
            className="dashboard_checkin_modal_container"
          >
            <div className="booking_details_child">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="settlement_div_main">
                  <Page
                    backAction={{
                      content: "Foodsale",
                      url: `/hotelier/foodsale`,
                    }}
                    title="Settlement"
                    compactTitle
                  ></Page>
                </div>
                <div>Pending Balance : {billBalance && billBalance}</div>
              </div>
            </div>

            <form
              onSubmit={(event) => handleSubmitSettlement(event)}
              className="settlement-add-model-container"
            >
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Name"
                    name="settlement_name"
                    type="text"
                    value={settlementAddData.settlement_name}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputSelectCustomCopy
                    required={true}
                    options={paymentModeOptions}
                    titleName="Payment Mode"
                    name="payment_mode"
                    value={settlementAddData.payment_mode}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
              </div>

              <div className="form-container-div-half">
                <div style={{ width: "100%" }} className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Notes"
                    name="notes"
                    value={settlementAddData.notes}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"date"}
                    titleName="Date"
                    name="date"
                    value={settlementAddData.date}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>

                <div style={{ width: "100%" }} className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    type={"number"}
                    titleName="Amount"
                    name="amount"
                    value={settlementAddData.amount}
                    onChange={setAddItemDataHandleChange}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {parseInt(settlementAddData.amount) ===
                    parseInt(billBalance) ? (
                    <BgThemeButton
                      // onClick={(e) => handleSubmitAddMeals(e)}
                      type="submit"
                      children={"Settle"}
                    />
                  ) : (
                    <BgThemeButtonDisabled
                      // onClick={(e) => handleSubmitAddMeals(e)}
                      type="submit"
                      children={"Settle"}
                      disabled={true}
                    />
                  )}

                  <div>
                    <BgOutlineThemeButton
                      type={"button"}
                      children={"Discard"}
                      onClick={() => {
                        setShowCheckOutSettlementDetails(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
export default FbFoodSale;
