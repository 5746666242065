import React, { useEffect, useState } from "react";
import { BgThemeButton, WithoutBgButtonBlue } from "../UI/Buttons";
import ProfileSetting from "./ProfileSetting";
import LocationSetting from "./LocationSetting";
import RolesPermissions from "./RolesPermissions";
import DomainsSetting from "./DomainsSetting";
import { useSelector } from "react-redux";
import HouseKeepingStaff from "./HouseKeepingStaffCrud";
import RolesCrudComponent from "./RolesCrudComponent";
import ActivityLogs from "./ActivityLogs";
import OtaPriceMatching from "./OtaPriceMatching";
import TaxSettings from "./TaxSettings";
import TaxSettingsCrud from "./TaxSettingsCrud";
import { Link, Outlet, useLocation } from "react-router-dom";

const SettingsContent = () => {
  // const [activeTab, setActiveTab] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const property_id = useSelector((state) => state.property.value);
  // const [editSettingTrue, setEditSettingTrue] = useState(false);

  const location = useLocation();
  const role = "housekeeping_manager";

  useEffect(() => {
    const selectedProperty = JSON.parse(
      localStorage.getItem("selectedProperty")
    );
    setSelectedProperty(selectedProperty);
  }, [property_id?.id]);

  // const handleTabClick = (tab, index) => {
  //   navigate(tab)
  //   setActiveTab(index);
  // };

  const tabs = [
    {
      title: "Profile",
      link: "profile",
    },
    // {
    //   title: "Location",
    //   link: "location",
    // },
    {
      title: "Brand",
      link: "brand",
    },
    {
      title: "Distance",
      link: "distance",
    },
    {
      title: "Tax",
      link: "tax",
    },
    {
      title: "Staffs",
      link: "staffs",
    },
    // {
    //   title: "Roles & Permissions",
    //   link: "roles-and-permission",
    // },
    {
      title: "Activity Logs",
      link: "activity-logs",
    },
    {
      title: "OTA Mapping",
      link: "ota-mapping",
    },
    {
      title: "Folio Series",
      link: "folio-series",
    },
    {
      title: "Payment Gateways",
      link: "folio-gateways",
    },
    {
      title: "Privacy Policy",
      link: "privacy-policy",
    },
    {
      title: "Domains",
      link: "property-domain",
    },
    {
      title: "Add-Ons",
      link: "add-ons",
    },
  ];

  const activeTab = tabs.findIndex((tab) =>
    location.pathname.includes(tab.link)
  );

  return (
    <>
      <div className="settings_sec">
        <div className="">
          <div className="settings_main">
            {/* <div className="settings_sec_left">
              <div className="settings_sec_left_image">
                <p style={{ fontWeight: "700" }}>
                  {selectedProperty?.property_name.charAt(0)}
                </p>
                <div className="image_overlay">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_1623_3753)">
                      <path
                        d="M18.557 1.75389L23.2468 6.44464L24.0289 5.66233C25.3237 4.36722 25.3237 2.26773 24.0289 0.972615C22.7337 -0.322903 20.6334 -0.322903 19.3382 0.972615L18.557 1.75389Z"
                        fill="white"
                      />
                      <path
                        d="M21.0935 8.59342L16.4038 3.90267L1.5223 18.7849L0 24.9983L6.21202 23.4757L21.0935 8.59342Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1623_3753">
                        <rect width="25" height="25" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <h3>{selectedProperty?.property_name}</h3>
              <p className="settings_sec_left_address">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1646_4130)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.0999 6.1C13.0999 3.006 10.5939 0.5 7.4999 0.5C4.4059 0.5 1.8999 3.006 1.8999 6.1C1.8999 6.205 1.8999 6.303 1.9069 6.408C1.9979 8.893 3.2999 11.742 6.8979 14.311C7.2549 14.563 7.7449 14.563 8.1019 14.311C11.6999 11.742 13.0019 8.893 13.0929 6.408C13.0999 6.30302 13.0999 6.20498 13.0999 6.1ZM8.9846 7.5847C9.3787 7.1913 9.5999 6.6572 9.5999 6.1C9.5999 5.5428 9.378 5.0094 8.9846 4.6153C8.5912 4.2212 8.0571 4 7.4999 4C6.9427 4 6.4093 4.2219 6.0152 4.6153C5.6211 5.0087 5.3999 5.5428 5.3999 6.1C5.3999 6.6572 5.6211 7.1913 6.0152 7.5847C6.4086 7.9788 6.9427 8.2 7.4999 8.2C8.0564 8.2 8.5905 7.9788 8.9846 7.5847Z"
                      fill="#888888"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1646_4130">
                      <rect
                        width="14"
                        height="14"
                        fill="white"
                        transform="translate(0.5 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                {selectedProperty?.property_city}
              </p>
              <div className="flex justify_content_end">
                
              </div>
            </div> */}
            <div className="settings_sec_right" style={{ width: "100%" }}>
              <div className="custom_tab_container">
                <div className="custom_tab_header">
                  {tabs.map((tab, index) => (
                    // <h3
                    //   key={index}
                    //   className={`tab_item ${index === activeTab ? "active" : ""
                    //     }`}
                    //   onClick={() => handleTabClick(tab.link, index)}
                    // >
                    //   {tab.title}
                    // </h3>
                    <Link
                      to={`/settings/${tab.link}`}
                      className={`tab_item ${
                        index === activeTab ? "active" : ""
                      }`}
                      key={index}
                    >
                      {tab.title}
                    </Link>
                  ))}
                </div>
                <div
                  className="custom-tab-content"
                  style={{ width: "70%", margin: "auto" }}
                >
                  <Outlet />
                  {/* {tabs[activeTab].content} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsContent;
