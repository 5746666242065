import React from "react";
import "./WaiterDashboard.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const WaiterDashBoard = () => {
  const [kotData, setKotData] = useState([]);

  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const property_id = useSelector((state) => state.property.value);

  useEffect(() => {
    const fetchData = async (propertyId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/kitchen/kots/${propertyId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        setKotData(result);
      } catch (error) {}
    };
    if (property_id) {
      fetchData(property_id?.id);
    }
  }, [property_id]);

  const kot = kotData && kotData.kot ? kotData.kot : [];

  const kotDates = kot?.reduce((acc, order) => {
    const existingDate = acc.find((date) => date.date === order.date);

    if (existingDate) {
      existingDate.orders.push({
        kot_type: order.kot_type,
        time: order.time,
        status: order.status,
        items: order.items,
      });
    } else {
      acc.push({
        date: order.date,
        orders: [
          {
            kot_type: order.kot_type,
            time: order.time,
            status: order.status,
            items: order.items,
          },
        ],
      });
    }

    return acc;
  }, []);

  return (
    <div className="waiterDashBoardContainer">
      <h4 className="waiterDashBoardHeading">Your KOTs</h4>
      {kotDates &&
        kotDates.length > 0 &&
        kotDates.map((item, index) => (
          <div key={index} className="waiterDashBoardDatesContainer">
            <p className="waiterDashBoardDate">{item.date}</p>
            <div className="waiterDashBoardOrdersContainer">
              {item.orders.map((order, index) => (
                <div key={index} className="waiterDashBoardOrderCard">
                  <div className="waiterDashBoardOrderCardTop">
                    <p className="waiterDashBoardOrderCardHeading">
                      {order.kot_type ? (
                        <span> {`KOT-${order.kot_type}`} </span>
                      ) : (
                        <span>KOT-N/A</span>
                      )}
                    </p>
                    <p className="waiterDashBoardOrderCardTime">{order.time}</p>
                  </div>
                  <div className="waiterDashBoardOrderCardHr"></div>

                  {order?.items?.map((category, index) => (
                    <div key={index} className="waiterDashBoardOrderCardItems">
                      {category.items.map((item, index) => (
                        <p key={index} className="waiterDashBoardOrderCardItem">
                          {item.name}
                        </p>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default WaiterDashBoard;
