import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
  Checkbox,
  Page,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../UI/Loader/Loader";
import NoDataFound from "../CustomComponents/NoDataFound";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalTextAreaCustom from "../CustomComponents/VerticalTextAreaCustom";
import SelectDateRange from "../CustomComponents/SelectDateRange/SelectDateRange";
import { BgThemeButton } from "../UI/Buttons";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";

const OtaPriceMatching = () => {

  const [loader, setLoader] =useState(false)
  const currentDate = new Date().toISOString().split("T")[0];

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);

  const [addPropertyData, setAddPropertyData] = useState({
    newSetPrice: "0",
    percentage: "",
  });

  // Onchange Function
  const setAddItemDataHandleChange = (event) => {
    setAddPropertyData({
      ...addPropertyData,
      [event.target.name]: event.target.value,
    });
  };

  const calculateToDate = (fromDate) => {
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() - 30);
    return toDate.toISOString().split("T")[0];
  };

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    // from_date: calculateToDate(currentDate),
    // to_date: currentDate,
    from_date: "",
    to_date: "",
  });

  const dataFromChild = (from_date, to_date) => {
    setSelectedDatesRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  useEffect(() => {
    if (
      selectedDatesRange.from_date !== "" &&
      selectedDatesRange.to_date !== ""
    ) {
      fetchMmtRate(selectedDatesRange.from_date, selectedDatesRange.to_date);
    }
  }, [selectedDatesRange.from_date, selectedDatesRange.to_date]);

  const [propertyRate, setPropertyRate] = useState("");
  const [mmtRate, setMmtRate] = useState("");

  //   To calculate the rate percentage
  const [percentage, setPercentage] = useState("");
  const [amount, setNewAmount] = useState("");

  useEffect(() => {
    const calculatedPercentage =
      ((propertyRate - addPropertyData.newSetPrice) / propertyRate) * 100;

    setAddPropertyData({
      ...addPropertyData,
      percentage: Math.round(calculatedPercentage),
    });
  }, [addPropertyData.newSetPrice, propertyRate]);

  const fetchMmtRate = async (from_date, end_date) => {
    fetch(
      // housekeeping/update/room/no/{status}/{room_no}/{property_id}
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/property/mmt/rates/${property_id?.id}?start_date=${from_date}&end_date=${end_date}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setPropertyRate(data.property_rate);
          setMmtRate(data.mmtrate);
        } else {
          // showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  // ‘property_id’       => $property_id,
  //           ‘discount’          => $request->input(‘discount’),
  //           ‘start_date’        => $request->input(‘start_date’),
  //           ‘end_date’          => $request->input(‘end_date’),

  const onSyncSubmit = (event) => {
    event.preventDefault();
    setLoader(true);

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/store/property/mmt/rates/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          discount: addPropertyData.percentage,
          property_id: property_id?.id,
          start_date: selectedDatesRange.from_date,
          end_date: selectedDatesRange.to_date,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddPropertyData({
            newSetPrice: "0",
            percentage: "",
          });
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      })
      .finally(() => {
        setLoader(false);
      });




     
  };

  return (
    <form onSubmit={onSyncSubmit}>
      <div
        className="profile_tab_form"
        style={{ width: "40%", margin: "auto" }}
      >
        <h3>OTA Price Matching</h3>
        <div className="edit-rooms-form-wrapper-container">
          <div className="form-container-div-half">
            <div className="form-container-div">
              <div>
                <div style={{ marginBottom: "5px" }}>Date Range</div>
                <SelectDateRange
                  dataFromChild={dataFromChild}
                  //   defaultDate={`${calculateToDate(currentDate)}:${currentDate}`}
                  //   minDate={new Date(currentDate)}
                />
              </div>
            </div>
            <div className="form-container-div"></div>
          </div>
          <div className="form-container-div-half">
            <div className="form-container-div">
              Property Rate: ₹{propertyRate || 0} <br />
              MMT Rate: ₹{mmtRate || 0} <br />
            </div>
          </div>
          <div className="form-container-div-half">
            <div className="form-container-div">
              <VerticalInputFieldCustom
                required={true}
                onChange={setAddItemDataHandleChange}
                type={"number"}
                name="newSetPrice"
                focused={false}
                titleName="Price you want to set."
                autoComplete="off"
                value={addPropertyData.newSetPrice}
              />
            </div>
            <div className="form-container-div">
              <VerticalInputFieldCustom
                disabled={true}
                required={true}
                onChange={setAddItemDataHandleChange}
                type={"number"}
                name="percentage"
                focused={false}
                titleName="Discounted Percentage"
                autoComplete="off"
                value={addPropertyData.percentage}
              />
            </div>
          </div>
        </div>

        <BgThemeButton style={{ width: "100%" }} type={"submit"} loader={loader} >
          Sync
        </BgThemeButton>
      </div>
    </form>
  );
};
export default OtaPriceMatching;
