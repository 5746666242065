import React from "react";
import WebsitesBlogComp from "../../Components/Websites/WebsitesBlogComp";

const WebsitesBlogs = () => {
  return (
    <>
      <div className="main_container">
        <WebsitesBlogComp />
      </div>
    </>
  );
};

export default WebsitesBlogs;
